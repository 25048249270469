import axios, { AxiosInstance } from "axios";
import Storage from '@/helpers/storage';

const storage = new Storage()
const authToken = storage.get("access_token");

const apiClient: AxiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    "X-Requested-With": "XMLHttpRequest",
    "App-Key": process.env.VUE_APP_API_URL,
    "Authorization": authToken ?`Bearer ${authToken}` : ''
  },
});

export default apiClient;