import http from "@/axios";

class RegionsService {

	async index(payload?: object): Promise<any> {
		return await http.get("settings/regions", { params: payload });
	}

	async store(payload: object, ): Promise<any> {
		return await http.post(`settings/regions/store`, payload);
	}

	async edit(region: string, payload?: object): Promise<any> {
		return await http.get(`settings/regions/${region}/edit`, payload);
	}
	
	async update(region: string, payload?: object): Promise<any> {
		return await http.put(`settings/regions/${region}/update`, payload);
	}

	async delete(region: string, payload?: object): Promise<any> {
		return await http.delete(`settings/regions/${region}/delete`, payload);
	}

	async restore(region: string): Promise<any> {
		return await http.patch(`settings/regions/${region}/restore`);
	}
}
export default new RegionsService();
