<template>
    <div>
        <text-input
            type="text"
            :label="label"
            :placeholder="placeholder"
            :id="id"
            :name="name"
            :disabled="disabled"
        />
    </div>
</template>
<script setup lang="ts">
import { ref } from "vue";
import TextInput from "@/components/inputs/TextInput.vue";

const props = defineProps({
	form: {
		type: Object,
	},
    value: {
        default: null,
        required: true,
    },
    label: {
        type: String,
        default: 'Field Label',
    },
    placeholder: {
        type: String,
        default: 'Field Label',
    },
    id: {
        type: String,
        default: 'field_label',
    },
    name: {
        type: String,
        default: 'field_label',
    },
    error: {
        type: String,
        default: null,
    },
    disabled: {
        type: Boolean,
        default: false,
    },
});
const form = ref<object>(props.form);

</script>
