import { EnumsInterface } from "@/interfaces/EnumsInterface";

const RadiologicalExamSubType:EnumsInterface[] = [
    {id: 0, value: 0, label: "Radiological exam - CT-Scan of (Specify in Remarks Box)"},
    {id: 1, value: 1, label: "Radiological exam - Skeletal survey"},
    {id: 2, value: 2, label: "Radiological exam - Ultrasound of (Specify in Remarks Box)"},
    {id: 3, value: 3, label: "Radiological exam - MRI of (Specify in Remarks Box)"},
    {id: 4, value: 4, label: "Radiological exam - Others (Specify in Remarks Box)"},
]

export default RadiologicalExamSubType