import Storage from "@/helpers/storage";
import SexAtBirth from "@/enums/SexAtBirth";
import Behavior from "@/enums/Behavior";
import Gender from "@/enums/Gender";
import HighSuicidalRisk from "@/enums/HighSuicidalRisk";
import IncidentTime from "@/enums/IncidentTime";
import CaseConferenceSubType from "@/enums/CaseConferenceSubType";
import CoordinationTestimonySubType from "@/enums/CoordinationTestimonySubType";
import MentalHealthCoordinationSubType from "@/enums/MentalHealthCoordinationSubType";
import PtaqScoring from "@/enums/PtaqScoring";
import ItemOrPart from "@/enums/ItemOrPart";
import Response from "@/enums/Response";
import ReferTo from "@/enums/ReferTo";
import TeleconsultSubType from "@/enums/TeleconsultSubType";
import TestimonySubType from "@/enums/TestimonySubType";
import FollowUpSubType from "@/enums/FollowUpSubType";
import AssessmentOption from "@/enums/AssessmentOption";
import PsychiatricEvaluation from "@/enums/PsychiatricEvaluation";
import TestAdminister from "@/enums/TestAdminister";
import InterventionType from "@/enums/InterventionType";
import SpecificIntervation from "@/enums/SpecificIntervation";
import UseMode from "@/enums/UseMode";
import UsePlatform from "@/enums/UsePlatform";
import TeleconsultationStatus from "@/enums/TeleconsultationStatus";
import Disposition from "@/enums/Disposition";
import Result from "@/enums/Result";
import MedicalCertificateSubType from "@/enums/MedicalCertificateSubType";
import MedicationSubType from "@/enums/MedicationSubType";
import RadiologicalExamSubType from "@/enums/RadiologicalExamSubType";
import TelemedicineSubType from "@/enums/TelemedicineSubType";
import PsychScreeningSubtType from "@/enums/PsychScreeningSubtType";
import Therapy from "@/enums/Therapy";
import AffidavitSubType from "@/enums/AffidavitSubType";
import CaseFollowUpSubType from "@/enums/CaseFollowUpSubType";
import ProtectionOrder from "@/enums/ProtectionOrder";
import ChildType from "@/enums/ChildType";
import Pwd from "@/enums/Pwd";
import PwdSpecific from "@/enums/PwdSpecific";
import DB from "../Databases/Crud";
import { BirthdayClassification } from "@/views/enums/BirthdayClassification";
import { items } from "@/interfaces/pages/FilterPageInterface";

const storage = new Storage();
const db = new DB('dropdown_options');
const table = 'dropdown_options';
export default class FormOptions {

    intervention_type_id: any;
	patient_id: any;

	constructor(intervention_type_id: any = null, patient_id: any = null) {
		// console.log('Intervention Type: '+intervention_type_id)
		// console.log('Patient ID : '+patient_id)
		this.intervention_type_id = intervention_type_id;
		this.patient_id = patient_id;
	}

	async civilStatusOptions() {
        const response = await db.select(`SELECT * FROM ${table} WHERE option="civilStatuses"`);
        if (response.length > 0) {
            return JSON.parse(response[0].content)?.map((item: any) => {
                return { id: item.id, value: item.id, label: item.name, name: item.name };
			}) || [];
        } else {
            return [];
        }
	}

	async indigeneous() {
        const response = await db.select(`SELECT * FROM ${table} WHERE option="indigeneousPeoples"`);
        if (response.length > 0) {
            const items = JSON.parse(response[0].content)?.map((item: any) => {
                return { id: item.id, value: item.id, label: item.name, name: item.name };
			}) || [];

            items.push({id: 'others', value: 'others', label: 'Others', name: 'Others'});
            return items;
        } else {
            return [];
        }
	}

	async indigeneousMultiSelect() {
        const response = await db.select(`SELECT * FROM ${table} WHERE option="indigeneousPeoples"`);
        if (response.length > 0) {
            const items = JSON.parse(response[0].content)?.map((item: any) => {
				return { id: item.id, value: item.id, label: item.name, name: item.name };
			}) || [];
            
            items.unshift(
                {id: 'none', value: 'none', label: 'None', name: 'None'}
            );

            items.push(
                {id: 'others', value: 'others', label: 'Others', name: 'Others'},
              
            );
            return items;
        } else {
            return [];
        }
	}

	async educationAttainments() {
        const response = await db.select(`SELECT * FROM ${table} WHERE option="educationAttainments"`);
        if (response.length > 0) {
            return JSON.parse(response[0].content)?.map((item: any) => {
				return { id: item.id, value: item.id, label: item.name, name: item.name };
			}) || [];
        } else {
            return [];
        }
	}

	async riskCodes() {
        const response = await db.select(`SELECT * FROM ${table} WHERE option="riskCodes"`);
        if (response.length > 0) {
            return JSON.parse(response[0].content)?.map((item: any) => {
				return { id: item.id, value: item.id, label: item.description, description: item.description };
			}) || [];
        } else {
            return [];
        }
	}

	async employmentStatus() {
        const response = await db.select(`SELECT * FROM ${table} WHERE option="employmentStatus"`);
        if (response.length > 0) {
            return JSON.parse(response[0].content)?.map((item: any) => {
				return { id: item.id, value: item.id, label: item.name, name: item.name };
			}) || [];
        } else {
            return [];
        }
	}

	referralSubTypes() {
		let res = JSON.parse(storage.get("referralSubTypes")) ?? []
		res = this.intervention_type_id
			? res.filter((x: any) => x.intervention_type == this.intervention_type_id)
			: res

		return res.map((item: any) => ({ id: item.id, value: item.id, label: item.name, name: item.name }))
	}

	async referralSources() {
        const response = await db.select(`SELECT * FROM ${table} WHERE option="referralSources"`);
        if (response.length > 0) {
            return JSON.parse(response[0].content)?.map((item: any) => {
				return { id: item.id, value: item.id, label: item.name, name: item.name };
			}) || [];
        } else {
            return [];
        }
	}

	async comorbidities() {
        const response = await db.select(`SELECT * FROM ${table} WHERE option="comorbidities"`);
        if (response.length > 0) {
            return JSON.parse(response[0].content)?.map((item: any) => {
				return { id: item.id, value: item.name, label: item.name, name: item.name, comorbidity_id: item.id };
			}) || [];
        } else {
            return [];
        }
	}

	async departments() {
        const response = await db.select(`SELECT * FROM ${table} WHERE option="departments"`);
        if (response.length > 0) {
            return JSON.parse(response[0].content)?.map((item: any) => {
				return { id: item.id, value: item.id, label: item.name, name: item.name, comorbidity_id: item.id };
			}) || [];
        } else {
            return [];
        }
	}

	async guardianRelationships() {
        const response = await db.select(`SELECT * FROM ${table} WHERE option="guardianRelationships"`);
        if (response.length > 0) {
            return JSON.parse(response[0].content)?.map((item: any) => {
				return { id: item.id, value: item.id, label: item.name, name: item.name };
			}) || [];
        } else {
            return [];
        }
	}

	async safetyAssessmentQuestions() {
        const response = await db.select(`SELECT * FROM ${table} WHERE option="safetyAssessmentQuestions"`);
        if (response.length > 0) {
            return JSON.parse(response[0].content)?.map((item: any) => {
				return { id: item.id, value: item.id, label: item.description, description: item.description };
			}) || [];
        } else {
            return [];
        }
	}

	async relationships() {
        const response = await db.select(`SELECT * FROM ${table} WHERE option="relationships"`);
        if (response.length > 0) {
            return JSON.parse(response[0].content)?.map((item: any) => {
				return { id: item.id, value: item.id, label: item.name, name: item.name };
			}) || [];
        } else {
            return [];
        }
	}

	async hospitalAreas() {
        const response = await db.select(`SELECT * FROM ${table} WHERE option="hospitalAreas"`);
        if (response.length > 0) {
            return JSON.parse(response[0].content)?.map((item: any) => {
				return { id: item.id, value: item.id, label: item.name, name: item.name };
			}) || [];
        } else {
            return [];
        }
	}

	async barangays(id: any = null) {
        const response = await db.select(`SELECT * FROM ${table} WHERE option="barangays"`);
        if (response.length > 0) {
            const content = JSON.parse(response[0].content);
            return content?.filter((item: any) => {
                if (id && id == item.city_id) {
                    return item;
                }
            })?.map((item: any) => {
				return { id: item.id, value: item.id, label: item.name, name: item.name, city_id: item.city_id, code: item.code };
			}) || [];
        } else {
            return [];
        }
	}

	async religions() {
        const response = await db.select(`SELECT * FROM ${table} WHERE option="religions"`);
        if (response.length > 0) {
            const items = JSON.parse(response[0].content)?.map((item: any) => {
				return { id: item.id, value: item.id, label: item.name, name: item.name };
			}) || [];

            items.push({id: 'others', value: 'others', label: 'Others', name: 'Others'});
            return items;
        } else {
            return [];
        }
	}

    async regions() {
        const response = await db.select(`SELECT * FROM ${table} WHERE option="regions"`);
        if (response.length > 0) {
            return JSON.parse(response[0].content)?.map((item: any) => {
				return { id: item.id, value: item.id, label: item.name, name: item.name, code: item.code };
			}) || [];
        } else {
            return [];
        }
	}

	async cities(id: any = null) {
        const response = await db.select(`SELECT * FROM ${table} WHERE option="cities"`);
        if (response.length > 0) {
            const content = JSON.parse(response[0].content);
            return content?.filter((item: any) => {
                if (id && id == item.province_id) {
                    return item;
                }
            })?.map((item: any) => {
				return { id: item.id, value: item.id, label: item.name, name: item.name, province_id: item.province_id };
			}) || [];
        } else {
            return [];
        }
	}

	async provinces(id: any = null) {
        const response = await db.select(`SELECT * FROM ${table} WHERE option="provinces"`);
        if (response.length > 0) {
            const content = JSON.parse(response[0].content);
            return content?.filter((item: any) => {
                if (id && id == item.region_id) {
                    return item;
                }
            })?.map((item: any) => {
				return { id: item.id, value: item.id, label: item.name, name: item.name, region_id: item.region_id };
			}) || [];
        } else {
            return [];
        }
	}

	async perpetratorRelationships() {
        const response = await db.select(`SELECT * FROM ${table} WHERE option="perpetratorRelationships"`);
        if (response.length > 0) {
            return JSON.parse(response[0].content)?.map((item: any) => {
				return { id: item.id, value: item.id, label: item.name, name: item.name };
			}) || [];
        } else {
            return [];
        }
	}

	async companionRelationships() {
        const response = await db.select(`SELECT * FROM ${table} WHERE option="companionRelationships"`);
        if (response.length > 0) {
            return JSON.parse(response[0].content)?.map((item: any) => {
				return { id: item.id, value: item.id, label: item.name, name: item.name };
			}) || [];
        } else {
            return [];
        }
	}

	async soughtServices() {
        const response = await db.select(`SELECT * FROM ${table} WHERE option="soughtServices"`);
        if (response.length > 0) {
            return JSON.parse(response[0].content)?.map((item: any) => {
				return { id: item.id, value: item.id, label: item.name, name: item.name };
			}) || [];
        } else {
            return [];
        }
	}

	async behaviorTypes() {
        const response = await db.select(`SELECT * FROM ${table} WHERE option="behaviorTypes"`);
        if (response.length > 0) {
            return JSON.parse(response[0].content)?.map((item: any) => {
				return { id: item.id, value: item.id, label: item.name, type: item.type, name: item.name };
			}) || [];
        } else {
            return [];
        }
	}

	async socioEconomicStatuses() {
        const response = await db.select(`SELECT * FROM ${table} WHERE option="socioEconomicStatuses"`);
        if (response.length > 0) {
            return JSON.parse(response[0].content)?.map((item: any) => {
				return { id: item.id, value: item.id, label: item.name, name: item.name };
			}) || [];
        } else {
            return [];
        }
	}

	async housingClassifications() {
        const response = await db.select(`SELECT * FROM ${table} WHERE option="housingClassifications"`);
        if (response.length > 0) {
            return JSON.parse(response[0].content)?.map((item: any) => {
				return { id: item.id, value: item.id, label: item.name, name: item.name };
			}) || [];
        } else {
            return [];
        }
	}

    birthdayClassification() {
        return [
            { id: BirthdayClassification.BIRTHDATE, value: BirthdayClassification.BIRTHDATE, label: "Birthday" },
            { id: BirthdayClassification.APPROXIMATE_AGE, value: BirthdayClassification.APPROXIMATE_AGE, label: "Approximate Age" },
            { id: BirthdayClassification.UNKNOWN, value: BirthdayClassification.UNKNOWN, label: "Unknown" },
        ];
    }

    personRelatedTypes() {
        return [
            { id: 0, value: 0, label: 'Lives with child?' },
            { id: 1, value: 1, label: 'Is present caretaker?' },
            { id: 3, value: 3, label: 'Deceased?' },
            { id: 4, value: 4, label: 'Is child?' },
            { id: 5, value: 5, label: 'Is contact person?' },
        ];
    }

    livingArrangements() {
        return [
            { id: 0, value: 0, label: "Street Child"},
            { id: 1, value: 1, label: "Street Family"},
            { id: 2, value: 2, label: "NGO Shelter"},
            { id: 3, value: 3, label: "Govt Agency"},
            { id: 4, value: 4, label: "Unknown"},
            { id: 5, value: 5, label: "Single Parent"},
            { id: 6, value: 6, label: "Immediate Family"},
            { id: 7, value: 7, label: "Extended Family"},
            { id: 8, value: 8, label: "Relatives"},
            { id: 9, value: 9, label: "None-Relatives"},
        ];
    }

    sleepingArrangements() {
        return [
            { id: 0, value: 0, label: 'At home' },
            { id: 1, value: 1, label: 'On the street' },
            { id: 2, value: 2, label: 'In a shelter' },
            { id: 3, value: 3, label: 'Others' },
        ];
    }

    sleepipngArrangmentWith() {
        return [
            { id: 0, value: 0, label: 'Adult Male' },
            { id: 1, value: 1, label: 'Adult Female' },
            { id: 2, value: 2, label: 'Male Child(ren)' },
            { id: 3, value: 3, label: 'Female Child(ren)' },
            { id: 4, value: 4, label: 'Alone' },
        ];
    }

    safetyAssessmentStatus() {
        return [
            { id: 0, value: 0, label: 'Yes' },
            { id: 1, value: 1, label: 'No' },
            { id: 2, value: 2, label: 'Unknown' },
        ];
    }

	async disabilities() {
        const response = await db.select(`SELECT * FROM ${table} WHERE option="disabilities"`);
        if (response.length > 0) {
            return JSON.parse(response[0].content)?.map((item: any) => {
				return { id: item.id, value: item.id, label: item.name, name: item.name };
			}) || [];
        } else {
            return [];
        }
	}

	async nationalities() {
        const response = await db.select(`SELECT * FROM ${table} WHERE option="nationalities"`);
        if (response.length > 0) {
            const items = JSON.parse(response[0].content)?.map((item: any) => {
				return { id: item.id, value: item.id, label: item.name, name: item.name };
			}) || [];
            items.push({id: 'others', value: 'others', label: 'Others', name: 'Others'});
            return items;
        } else {
            return [];
        }
	}

	async labolatoryTypes() {
        const response = await db.select(`SELECT * FROM ${table} WHERE option="labolatoryTypes"`);
        if (response.length > 0) {
            return JSON.parse(response[0].content)?.map((item: any) => {
				return { id: item.id, value: item.id, label: item.name, name: item.name };
			}) || [];
        } else {
            return [];
        }
	}

	async offices() {
        const response = await db.select(`SELECT * FROM ${table} WHERE option="offices"`);
        if (response.length > 0) {
            return JSON.parse(response[0].content)?.map((item: any) => {
				return { id: item.id, value: item.id, label: item.name, name: item.name };
			}) || [];
        } else {
            return [];
        }
	}

	async telCpuLocations() {
        const response = await db.select(`SELECT * FROM ${table} WHERE option="telCpuLocations"`);
        if (response.length > 0) {
            return JSON.parse(response[0].content)?.map((item: any) => {
				return { id: item.id, value: item.id, label: item.name, name: item.name };
			}) || [];
        } else {
            return [];
        }
	}

	async telCpuOffices() {
        const response = await db.select(`SELECT * FROM ${table} WHERE option="telCpuOffices"`);
        if (response.length > 0) {
            return JSON.parse(response[0].content)?.map((item: any) => {
				return { id: item.id, value: item.id, label: item.name, name: item.name };
			}) || [];
        } else {
            return [];
        }
	}

	async personnels(position?: number) {
        const response = await db.select(`SELECT * FROM ${table} WHERE option="personnels"`);
		
        if (response.length > 0) {
            return JSON.parse(response[0].content)?.map((item: any) => {
				return {
					id: item.id,
					value: item.id,
					label: item.name,
					name: item.name,
					office_id: item.office_id,
					position_id: item.position_id,
					department_id: item.department_id,
				};
			}) || [];
        } else {
            return [];
        }
	}

	async socialWorkers() {
        const response = await db.select(`SELECT * FROM ${table} WHERE option="socialWorkers"`);
        if (response.length > 0) {
            return JSON.parse(response[0].content)?.map((item: any) => {
				return { id: item.id, value: item.id, label: item.name, name: item.name };
			}) || [];
        } else {
            return [];
        }
	}

	async physicians() {
        const response = await db.select(`SELECT * FROM ${table} WHERE option="physicians"`);
        if (response.length > 0) {
            return JSON.parse(response[0].content)?.map((item: any) => {
				return { id: item.id, value: item.id, label: item.name, name: item.name };
			}) || [];
        } else {
            return [];
        }
	}

	async psychiatrists() {
        const response = await db.select(`SELECT * FROM ${table} WHERE option="psychiatrists"`);
        if (response.length > 0) {
            return JSON.parse(response[0].content)?.map((item: any) => {
				return { id: item.id, value: item.id, label: item.name, name: item.name };
			}) || [];
        } else {
            return [];
        }
	}

	async mms() {
        const response = await db.select(`SELECT * FROM ${table} WHERE option="mms"`);
        if (response.length > 0) {
            return JSON.parse(response[0].content)?.map((item: any) => {
				return { id: item.id, value: item.id, label: item.name, name: item.name };
			}) || [];
        } else {
            return [];
        }
	}

	async trainees() {
        const response = await db.select(`SELECT * FROM ${table} WHERE option="trainees"`);
        if (response.length > 0) {
            return JSON.parse(response[0].content)?.map((item: any) => {
				return { id: item.id, value: item.id, label: item.name, name: item.name };
			}) || [];
        } else {
            return [];
        }
	}

	async primaryDiagnoses() {
        const response = await db.select(`SELECT * FROM ${table} WHERE option="primaryDiagnoses"`);
        if (response.length > 0) {
            return JSON.parse(response[0].content)?.map((item: any) => {
				return { id: item.id, value: item.id, label: item.name, name: item.name };
			}) || [];
        } else {
            return [];
        }
	}

	async mentalHealthDiagnoses() {
        const response = await db.select(`SELECT * FROM ${table} WHERE option="mentalHealthDiagnoses"`);
        if (response.length > 0) {
            return JSON.parse(response[0].content)?.map((item: any) => {
				return { id: item.id, value: item.id, label: item.name, name: item.name };
			}) || [];
        } else {
            return [];
        }
	}

	async mentalHealthStaticRadioButtonId() {
        const response = await db.select(`SELECT * FROM ${table} WHERE option="mentalHealthStaticRadioButtonId"`);
        if (response.length > 0) {
            return JSON.parse(response[0].content);
        } else {
            return null;
        }
		// return storage.get("mentalHealthStaticRadioButtonId");
	}

	async assignedGroups() {
        const response = await db.select(`SELECT * FROM ${table} WHERE option="assignedGroups"`);
        if (response.length > 0) {
            return JSON.parse(response[0].content)?.map((item: any) => {
				return { id: item.id, value: item.id, label: item.name };
			}) || [];
        } else {
            return [];
        }
	}

	interventionTypeLists() {
		let res = JSON.parse(storage.get("interventionTypeLists")) ?? []
		res = this.intervention_type_id
			? res.filter((x: any) => x.intervention_type == this.intervention_type_id)
			: res

		return res.map((item: any) => ({ id: item.id, value: item.id, label: item.name, name: item.name }))
	}

	caseNumbers() {
		return (
			JSON.parse(storage.get("cases"))?.map((item: any) => {
				return { id: item.id, value: item.id, label: item.case_number };
			}) || []
		);
	}

	cases() {
		return (
			JSON.parse(storage.get("cases")) || []
		);
	}

	async educationalAttainments() {
        const response = await db.select(`SELECT * FROM ${table} WHERE option="educationalAttainments"`);
        if (response.length > 0) {
            return JSON.parse(response[0].content)?.map((item: any) => {
				return { id: item.id, value: item.id, label: item.name };
			}) || [];
        } else {
            return [];
        }
	}

	async teleCpuLocations() {
        const response = await db.select(`SELECT * FROM ${table} WHERE option="teleCpuLocations"`);
        if (response.length > 0) {
            return JSON.parse(response[0].content)?.map((item: any) => {
				return { id: item.id, value: item.id, label: item.name };
			}) || [];
        } else {
            return [];
        }
	}

	guardian() {
		return; 
	}

	office() {
		return JSON.parse(storage.get("user")).office || null;
	}

	patient() {
		return;
	}

	async interventionTypes() {
		const response = await db.select(`SELECT * FROM ${table} WHERE option="interventionTypes"`);
		
        if (response.length > 0) {
            return JSON.parse(response[0].content)?.map((item: any) => {
				return { id: item.id, value: item.id, label: item.label };
			}) || [];
        } else {
            return [];
        }
	}

    async wcpuPersonnels() {
        const response = await db.select(`SELECT * FROM ${table} WHERE option="wcpuPersonnel"`);
        if (response.length > 0) {
            return JSON.parse(response[0].content)?.map((item: any) => {
				return { id: item.id, value: item.id, label: item.name, name: item.name };
			}) || [];
        } else {
            return [];
        }
	}

    async agencies() {
        const response = await db.select(`SELECT * FROM ${table} WHERE option="agencies"`);
        if (response.length > 0) {
            return JSON.parse(response[0].content)?.map((item: any) => {
				return { id: item.id, value: item.id, label: item.name, name: item.name };
			}) || [];
        } else {
            return [];
        }
	}

    async aggravatingFactors() {
        const response = await db.select(`SELECT * FROM ${table} WHERE option="aggravating_factors"`);
        if (response.length > 0) {
            return JSON.parse(response[0].content)?.map((item: any) => {
				return { id: item.id, value: item.id, label: item.name, name: item.name };
			}) || [];
        } else {
            return [];
        }
	}

    async mitigatingFactors() {
        const response = await db.select(`SELECT * FROM ${table} WHERE option="mitigating_factors"`);
        if (response.length > 0) {
            return JSON.parse(response[0].content)?.map((item: any) => {
				return { id: item.id, value: item.id, label: item.name, name: item.name };
			}) || [];
        } else {
            return [];
        }
	}

    async recommendationOptions() {
        const response = await db.select(`SELECT * FROM ${table} WHERE option="recommendationOptions"`);
        if (response.length > 0) {
            return JSON.parse(response[0].content)?.map((item: any) => {
				return { id: item.id, value: item.id, label: item.label, name: item.label };
			}) || [];
        } else {
            return [];
        }
	}

	async createPatientOptions() {
		return {
			behaviorTypes: await this.behaviorTypes(),
			behaviors: Behavior,
			civilStatuses: await this.civilStatusOptions(),
			comorbidities: await this.comorbidities(),
			disabilities: await this.disabilities(),
			genders: Gender,
			highSuicidalRisks: HighSuicidalRisk,
			incidentTimes: IncidentTime,
			indigeneous: await this.indigeneousMultiSelect(),
			mentalHealthDiagnoses: await this.mentalHealthDiagnoses(),
			mms: await this.mms(),
			nationalities: await this.nationalities(),
			physicians: await this.physicians(),
			primaryDiagnoses: await this.primaryDiagnoses(),
			psychiatrists: await this.psychiatrists(),
			referralSources: await this.referralSources(),
			religions: await this.religions(),
			sexAtBirth: SexAtBirth,
			socialWorkers: await this.socialWorkers(),
			soughtServices: await this.soughtServices(),
			trainees: await this.trainees(),
			mentalHealthStaticRadioButtonId: await this.mentalHealthStaticRadioButtonId(),
			childTypes: ChildType,
			educationalAttainments: await this.educationAttainments(),
			employmentStatuses: await this.employmentStatus(),
			hospitalAreas: await this.hospitalAreas(),
			pwds: Pwd,
			pwdSpecific: PwdSpecific,
		};
	}

	async createInterventionOptions() {
		return {
			// General
			assignedGroups: await this.assignedGroups(),
			personnels: await this.personnels(),
			interventionTypeLists: this.interventionTypeLists(),
			caseNumbers: this.caseNumbers(),
			
			// Social Work
			caseConferences: CaseConferenceSubType,
			coordinations: this.intervention_type_id == 0 ? CoordinationTestimonySubType : MentalHealthCoordinationSubType,
			ptaqs: PtaqScoring,
			itemParts: ItemOrPart,
			responses: Response,
			levels: HighSuicidalRisk,
			referTos: ReferTo,
			referralSubTypes: this.referralSubTypes(),
			teleconsultSubtypes: TeleconsultSubType,
			testimonyInCourts: TestimonySubType,
			followUps: FollowUpSubType,

			// Medical
            labolatoryTypes: await this.labolatoryTypes(),
            results: Result,
            medicalCertificateSubTypes: MedicalCertificateSubType,
            medicationSubTypes: MedicationSubType,
            radiologicalExamSubTypes: RadiologicalExamSubType,
            telemedicinesSubTypes: TelemedicineSubType,

			// Mental Health
            psychScreenings: PsychScreeningSubtType,
            therapies: Therapy,

			// Development Pedia 

			// Legal
			affidavits: AffidavitSubType,
            caseFollowUps: CaseFollowUpSubType,
            protectionOrders: ProtectionOrder,
            patient: this.patient(),

			// Teleconsult
			teleCpuLocations: await this.teleCpuLocations(),
			telCpuOffices: await this.telCpuOffices(),
			interventionTypes: InterventionType, 
			specificInterventions: SpecificIntervation,
			useModes:  UseMode,
			usePlatforms:  UsePlatform,
			statuses: TeleconsultationStatus,
			dispositions: Disposition,

			// Telepsychiatry
			assessments: AssessmentOption,
			psychiatricEvaluations: PsychiatricEvaluation,
			testAdministered: TestAdminister,
			educationalAttainments: this.educationalAttainments(),
			guardian_id: this.guardian(),
			guardian: this.guardian(),
			birthday: this.patient(),
			age: this.patient(),
			office_id: this.office()?.id,
			office: this.office(),

		}
	}
}
