import http from "@/axios";
import DB from "@/classes/Databases/Crud";

const dbPatient = new DB("patients");
const dbCases = new DB("cases");
const dbInterventions = new DB("intervention_data");
export default class SyncPatients {
    
    resetItemData(item: any) {
        item.synching_remarks = null;
        item.is_sync_failed = false;
        item.is_sync = true;
        item.is_created_offline = false;
        item.is_updated_offline = false;
        item.is_deleted_offline = false;

        return item;
    }

    async rollback(item: any, response: any) {
        const error = response?.response?.data;
        item.synching_remarks = error.message || 'System Error';
        item.is_sync_failed = true;
        await dbPatient.insert(item, item.id, true, 'patients');

        return true;
    }

    async processForm(item: any, form: any) {
        let hasError = false;
        if (item.is_updated_offline) {
            const response = await http.post('/attemp-synching-patient-update', form).catch(error => error);
            if (response?.status === 200) {
                item = this.resetItemData(item);
                await dbPatient.insert(item, item.id, true, 'patients');
                hasError = false;
            } else {
                hasError = await this.rollback(item, response);
            }
        }

        if (item.is_created_offline && !item.is_updated_offline) {
            const response = await http.post('/attemp-synching-patient-create', form).catch(error => error);
            if (response?.status === 200) {
                const newPatientId = response.data.data.id;
                const oldPatientId = item.id;

                const cases = await dbCases.select(`SELECT * FROM cases WHERE patient_id=${item.id}`);
                for (const caseIndex in cases) {
                    const caseItem = cases[caseIndex];
                    caseItem.patient_id = newPatientId;
                    await dbCases.insert(caseItem, caseItem.id, true, 'cases');
                }

                const conferences = await dbCases.select(`SELECT * FROM case_conferences WHERE patient_id=${item.id}`);
                for (const conferenceIndex in conferences) {
                    const conferenceItem = conferences[conferenceIndex];
                    conferenceItem.patient_id = newPatientId;
                    await dbCases.insert(conferenceItem, conferenceItem.id, true, 'case_conferences');
                }

                const interventions = await dbInterventions.select(`SELECT * FROM intervention_data WHERE patient_id=${item.id}`, 'intervention_data');
                for (const ii in interventions) {
                    const interventionItem = interventions[ii];
                    interventionItem.patient_id = newPatientId;
                    await dbInterventions.insert(interventionItem, interventionItem.id, true, 'intervention_data');
                }

                item = this.resetItemData(item);
                item.id = newPatientId;
                await dbPatient.delete({id: newPatientId}, 'patients');
                await dbPatient.insert(item, oldPatientId, true, 'patients');
                hasError = false;
            } else {                
                hasError = await this.rollback(item, response);
            }
        }

        if (item.is_deleted_offline) {
            const response = await http.post('/attemp-synching-patient-delete', form).catch(error => error);
            if (response?.status === 200) {
                await dbPatient.delete({id: item.id}, 'patients');
                hasError = false;
            } else {
                hasError = await this.rollback(item, response);
            }
        }

        return hasError;
    }

    async processSynching(selectedForm: any): Promise<any> {
        let hasError = false;
        const patient = await dbPatient.select(`SELECT * FROM patients WHERE id=${selectedForm.id} AND is_sync=false AND (is_created_offline=true OR is_updated_offline=true OR is_deleted_offline=true)`);
        if (patient.length > 0) {
            let item = patient[0];
            selectedForm.id = selectedForm.newId;
            const response = await http.post('/attemp-synching-patient-update', selectedForm).catch(error => error);
            if (response?.status === 200) {
                const newId = selectedForm.newId;
                const oldId = item.id;
                const cases = await dbCases.select(`SELECT * FROM cases WHERE patient_id=${item.id}`);
                for (const caseIndex in cases) {
                    const caseItem = cases[caseIndex];
                    caseItem.patient_id = newId;
                    await dbCases.insert(caseItem, caseItem.id, true, 'cases');
                }
                item = this.resetItemData(item);
                item.id = newId;
                await dbPatient.delete({id: newId}, 'patients');
                await dbPatient.insert(item, oldId, true, 'patients');
                hasError = false;
            } else {
                hasError = await this.rollback(item, response);
            }
        }

        return hasError;
    }

    async processCreateSynching(selectedForm: any): Promise<any> {
        let hasError = false;
        const patient = await dbPatient.select(`SELECT * FROM patients WHERE id=${selectedForm.id} AND is_sync=false AND (is_created_offline=true OR is_updated_offline=true OR is_deleted_offline=true)`);
        if (patient.length > 0) {
            let item = patient[0];
            selectedForm.id = selectedForm.newId;
            selectedForm.force_create_new_record = true;
            const response = await http.post('/attemp-synching-patient-create', selectedForm).catch(error => error);
            if (response?.status === 200) {
                const newPatientId = response.data.data.id;
                const oldPatientId = item.id;
                const cases = await dbCases.select(`SELECT * FROM cases WHERE patient_id=${item.id}`);
                for (const caseIndex in cases) {
                    const caseItem = cases[caseIndex];
                    caseItem.patient_id = newPatientId;
                    await dbCases.insert(caseItem, caseItem.id, true, 'cases');
                }

                const interventions = await dbInterventions.select(`SELECT * FROM intervention_data WHERE patient_id=${item.id}`, 'intervention_data');
                for (const ii in interventions) {
                    const interventionItem = interventions[ii];
                    interventionItem.patient_id = newPatientId;
                    await dbInterventions.insert(interventionItem, interventionItem.id, true, 'intervention_data');
                }

                item = this.resetItemData(item);
                item.id = response.data.data.id;
                await dbPatient.delete({id: newPatientId}, 'patients');
                await dbPatient.insert(item, oldPatientId, true, 'patients');
                hasError = false;
            } else {
                hasError = await this.rollback(item, response);
            }
        }
        return hasError;
    }
}