<template>
	<div :href="routeLink" class="flex flex-col md:p-6 p-4 space-y-2 bg-white border border-gray-200 rounded-2xl">
		<div class="flex items-center justify-between w-full">
			<date-detail
				:customColor="status == 'Scheduled' ? '#16a34a' : status == 'Past Due' ? '#dc2626' : '#0071fa'"
				:textColor="
					status == 'Scheduled'
						? 'text-green-600'
						: status == 'Past Due'
						? 'text-red-600'
						: 'text-primary-600'
				"
				:date="item.scheduled_date" />
			<div class="flex items-center">
				<span
					class="text-xs font-semibold px-2 py-1 leading-[18px] rounded-lg text-white mr-2"
					:class="
						status == 'Scheduled' ? 'bg-green-600' : status == 'Past Due' ? 'bg-red-600' : 'bg-primary-600'
					">
					{{ status }}
				</span>
				<Menu class="relative flex" as="div">
					<MenuButton>
						<!-- <img
							src="/assets/icon/ic-three-dots.svg"
							alt="menu_icon"
							class="object-contain w-5 h-5 cursor-pointer" /> -->
						<three-dots-icon class="object-contain w-5 h-5 cursor-pointer" />
					</MenuButton>
					<transition
						enter-active-class="transition duration-100 ease-out"
						enter-from-class="transform scale-95 opacity-0"
						enter-to-class="transform scale-100 opacity-100"
						leave-active-class="transition duration-75 ease-in"
						leave-from-class="transform scale-100 opacity-100"
						leave-to-class="transform scale-95 opacity-0">
						<MenuItems
							class="absolute right-0 z-10 mt-7 min-w-[180px] origin-top-right rounded bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
							<div class="p-4">
								<template v-if="route.query.tab !== 'archived'">
									<MenuItem>
										<a 
											@click="editRoute(item)"
											class="flex items-center mb-2 text-sm cursor-pointer">
											<!-- <img
												v-if="statusIsNotDone"
												src="/assets/icon/ic-pencil.svg"
												alt="pencil_icon"
												class="w-6 h-6 object-contain mr-1.5 invert" /> -->
											<pencil-icon v-if="statusIsNotDone" class="w-6 h-6 object-contain mr-1.5 invert" />
											{{ status === 'Done' ? "View" : "Edit"}}
										</a>
									</MenuItem>
									<MenuItem v-if="statusIsNotDone">
										<p
											class="flex items-center text-sm cursor-pointer"
											@click="showConfirmation = true">
											<TrashIcon class="w-6 h-6 mr-1.5" />
											Remove
										</p>
									</MenuItem>
								</template>
								<template v-else>
									<MenuItem>
										<p
											class="flex items-center text-sm cursor-pointer"
											@click="showRestoreConfirmation = true">
											<TrashIcon class="w-6 h-6 mr-1.5" />
											Restore
										</p>
									</MenuItem>
								</template>
							</div>
						</MenuItems>
					</transition>
				</Menu>
			</div>
		</div>
		<p class="text-sm font-bold">{{ interventionType || "-" }}</p>
		<div>
			<span class="px-2 py-1 mr-1 text-xs italic bg-neutral-200 text-neutral-600 rounded-2xl" v-if="intervention">
				{{ intervention }}
			</span>
		</div>
		<div>
			<p class="text-neutral-500 text-[0.625rem] mb-1">Case Number</p>
			<span class="p-2 font-bold text-xs rounded-[6px] bg-primary-400 text-primary-600 leading-[18px]">
				{{ caseNumber || "-" }}
			</span>
		</div>
		<div v-if="!typeTwo">
			<p class="text-neutral-500 text-[0.625rem]">To be Done By</p>
			<span class="px-2 py-1 mt-1 mr-1 text-xs italic bg-neutral-200 text-neutral-600 rounded-2xl">{{
				doneBy || "-"
			}}</span>
		</div>
		<div v-if="!typeTwo">
			<p class="text-neutral-500 text-[0.625rem]">Done On</p>
			<div class="flex flex-wrap items-center">
				<span class="px-2 py-1 mt-1 mr-1 text-xs italic bg-neutral-200 text-neutral-600 rounded-2xl">
					{{ doneOn || "-" }}
				</span>
			</div>
		</div>
		<div>
			<p
				v-if="errors?.message"
				class="text-[10px] mt-1"
				:class="errors?.message ? 'text-red-600' : 'text-gray-800'"
				id="email-error">
				{{ errors?.message }}
			</p>
		</div>
		<confirmation-modal
			:typeTwo="true"
			:show="showConfirmation"
			@cancel="showConfirmation = false"
			@confirm="remove()"
			cancel-text="Cancel"
			action-text="Confirm"
			title="Delete Record?"
			content="Are you sure you wish to delete this record?" />
		<success-modal
			:show="showSuccess"
			@confirm="redirectSuccess()"
			action-text="Close"
			title="Record Deleted!"
			:content="deleteSuccessMessage" />
		<confirmation-modal
			:typeTwo="true"
			:show="showRestoreConfirmation"
			@cancel="showRestoreConfirmation = false"
			@confirm="restore()"
			cancel-text="Cancel"
			action-text="Confirm"
			title="Restore Record?"
			content="Are you sure you wish to restore this record?" />
		<success-modal
			:show="showRestoreSuccess"
			@confirm="redirectRestoreSuccess()"
			action-text="Close"
			title="Record Restored!"
			:content="restoreSuccessMessage" />
	</div>
</template>
<script setup lang="ts">
import { ref, type PropType, computed } from "vue";
import { Menu, MenuButton, MenuItems, MenuItem } from "@headlessui/vue";
import DateDetail from "@/components/partials/DateDetail.vue";
import ConfirmationModal from "@/components/modals/ConfirmationModal.vue";
import SuccessModal from "@/components/modals/SuccessModal.vue";
import { TrashIcon } from "@heroicons/vue/24/solid";
import { useRouter, useRoute } from "vue-router";
import { InterventionItems } from "@/interfaces/Cases/Interevention";
import InterventionService from "@/classes/Cases/InterventionService";
import { errorResponse } from "@/helpers/errorMessage";
import { InterventionType } from "@/views/enums/InterventionType";
import ThreeDotsIcon from "@/components/icons/threeDotsIcon.vue";
import PencilIcon from "@/components/icons/pencilIcon.vue";
import pickBy from "lodash/pickBy";
import { useNetworkStore } from "@/store/network";

const router = useRouter();
const route = useRoute();
const patientId = ref<number | null>(route.params.patient);
const caseId = ref<number | null>(route.params.case);
const deleteSuccessMessage = ref<string>("Selected record has been successfully deleted.");
const restoreSuccessMessage = ref<string>("Selected record has been successfully restored.");

const props = defineProps({
	typeTwo: {
		type: Boolean,
		default: false,
	},
	interventionType: {
		type: String,
		default: null,
	},
	intervention: {
		type: String,
		default: null,
	},
	caseNumber: {
		type: String,
		default: null,
	},
	doneBy: {
		type: String,
		default: null,
	},
	doneOn: {
		type: String,
		default: null,
	},
	status: {
		type: String,
		default: "Scheduled", // Scheduled | Past Due | Done
	},
	item: {
		type: Object as PropType<InterventionItems>,
		required: true,
	},
});

const statusIsNotDone = computed<boolean>(() => props.status !== 'Done');
const showConfirmation = ref<boolean>(false);
const showSuccess = ref<boolean>(false);
const showRestoreConfirmation = ref<boolean>(false);
const showRestoreSuccess = ref<boolean>(false);
const errors = ref<object | null>(null);

const networkStore = useNetworkStore();
const isNetworkAvailable = computed(() => networkStore.isNetworkAvailable);
const offlineParams = ref<string>(route.query.offline);
const selectedItemId = computed(() => props.item.id ?? props.item?.offlineData?.offline_id);

const editRoute = (item:any): string => {

	let url = `/patient/${item.patient_id}/intervention/${item.intervention_type}/edit/${item.id}`;

    if(caseId?.value){
        url = `/patient/${item.patient_id}/cases/${item.cases_id}/intervention/${item.intervention_type}/edit/${item.id}`;
    } 
	
	router.push(url);
}


const remove = (): void => {
	showConfirmation.value = false;
	InterventionService.delete(
		patientId.value,
		caseId.value,
		selectedItemId.value,
		pickBy({ isOfflineData: offlineParams.value ? true : false }),
		isNetworkAvailable.value
	)
	.then((response: object) => {
		showSuccess.value = true;
		deleteSuccessMessage.value = response.data.message;
	})
	.catch((error: object) => {
		console.log(error)
		errors.value = errorResponse(error.response?.data?.errors);
	});
};

const restore = (): void => {
	showRestoreConfirmation.value = false;
	InterventionService.restore(patientId.value, caseId.value, props.item.id, {})
		.then((response: object) => {
			showRestoreSuccess.value = true;
			restoreSuccessMessage.value = response.data.message;
		})
		.catch((error: object) => {
			errors.value = errorResponse(error.response?.data?.errors);
		});
};

const redirectSuccess = (): void => {
	showSuccess.value = false;
	window.location.reload();
};

const redirectRestoreSuccess = (): void => {
	showSuccess.value = false;
	window.location.href = caseId.value
		? `/patient/${patientId.value}/cases/${caseId.value}/interventions`
		: `/intervention/${patientId.value}/view`;
};
</script>
