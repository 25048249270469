const CaseConference = {
	name: "case_conferences",
	schema: [
		{ column: "id", value: "INTEGER  PRIMARY KEY AUTOINCREMENT" },
		{ column: "patient_id", value: "INTEGER UNSIGNED" },
		{ column: "cases_id", value: "INTEGER UNSIGNED" },
		{ column: "social_worker_id", value: "INTEGER UNSIGNED" },
		{ column: "case_conference_number", value: "INTEGER UNSIGNED" },
		
		{ column: "date_time", value: "TIMESTAMP" },

		{ column: "other_aggravating", value: "LONGTEXT NULL" },
		{ column: "other_mitigating", value: "LONGTEXT NULL" },
		{ column: "meeting_minutes", value: "LONGTEXT NULL" },
		{ column: "invitees", value: "LONGTEXT NULL" },
		{ column: "agencies", value: "LONGTEXT NULL" },
		{ column: "recommendations", value: "LONGTEXT NULL" },

		{ column: "encoded_by", value: "INTEGER UNSIGNED" },

		//formatted date
		{ column: "social_worker", value: "TEXT" },
		{ column: "patient_name", value: "TEXT NULL" },

		{ column: "action", value: "INT UNSIGNED" },

		{ column: "is_sync", value: "BOOLEAN DEFAULT FALSE" },
        { column: "is_sync_failed", value: "BOOLEAN DEFAULT FALSE"},
        { column: "is_created_offline", value: "BOOLEAN DEFAULT FALSE" },
        { column: "is_updated_offline", value: "BOOLEAN DEFAULT FALSE" },
        { column: "is_deleted_offline", value: "BOOLEAN DEFAULT FALSE" },

        { column: "created_at", value: "TIMESTAMP NULL" },
		{ column: "updated_at", value: "TIMESTAMP NULL" },
		{ column: "deleted_at", value: "TIMESTAMP NULL" },
        { column: "synching_remarks", value: "TEXT DEFAULT NULL" },
	],
};

export default CaseConference;
