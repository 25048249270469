const assignValues = (source: any, data: any): any => {
    Object.entries(data).forEach(([key, value]) => {
        if (typeof source[key] != 'undefined') {
            source[key] = value;
        }
    });

    return source;
}

export default assignValues;



