import http from "@/axios";
import { Perpetrator } from "@/interfaces/PerpetratorInterface";
import DB from "@/classes/Databases/Crud";
import SqliteRecordChecker from "./Forms/SqliteRecordChecker";
import CasePerpetratorOfflineForm from "./Forms/Cases/CasePerpetratorOfflineForm";
import PaginationCase from "@/classes/Databases/PaginationCases";

const db = new DB("perpetrators");
const dbCasePerpetrators = new DB("case_perpetrators");
const dbCases = new DB("cases", new PaginationCase("cases"));
const checkRecord = new SqliteRecordChecker();
class PerpetratorService implements Perpetrator {
	async index(payload: object, networkStatus = true): Promise<any> {
		if (networkStatus) {
			return await http.get("perpetrators", { params: payload }).catch(error => error);
		}

		return await db.index(payload, '', true, 'perpetrators');
	}

	async show(perpetrator: number, payload: any, networkStatus = true): Promise<any> {
		if (networkStatus) {
			return await http.get(`perpetrators/${perpetrator}/show`, { params: payload }).catch(error => error);
		}

        const response = await db.edit(perpetrator, false, 'perpetrators');
		return response;
	}

	async cases(perpetrator: number, payload: any, networkStatus = true): Promise<any> {
		if (networkStatus) {
			return await http.get(`perpetrators/${perpetrator}/cases`, { params: payload }).catch(error => error);
		}

        const fetch = await checkRecord.init(db, perpetrator, false, 'perpetrators');

		return fetch.result;
	}

	async edit(perpetrator: number, payload: any, networkStatus = true): Promise<any> {
		if (networkStatus) {
			return await http.get(`perpetrators/${perpetrator}/edit`, { params: payload }).catch(error => error);
		}

		const fetch = await checkRecord.init(db, perpetrator, false, 'perpetrators');
		return fetch.result;
	}

	async update(perpetrator: number, payload: any, networkStatus = true): Promise<any> {
		if (networkStatus) {
			return await http.put(`perpetrators/${perpetrator}/update`, payload).catch(error => error);
		}

		const form = new CasePerpetratorOfflineForm({}, {});
		const createForm = await form.createPerpetratorForm(payload, false);
        const response = await db.insert(createForm, perpetrator, false, 'perpetrators');

        return {
            data: {
                item: response,
            }
        }
	}
}

export default new PerpetratorService();
