import { EnumsInterface } from "@/interfaces/EnumsInterface";

const name:EnumsInterface[] = [
    {id: 0, value: "Medical", label: "Medical"},
    {id: 1, value: "Social Work", label: "Social Work"},
    {id: 2, value: "Mental Health", label: "Mental Health"},
    {id: 3, value: "Police", label: "Police"},
    {id: 4, value: "NBI", label: "NBI"},
]

export default name