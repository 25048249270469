<template>
	<user-layout
		hasBack
		backTitle="Edit Intake"
		:backLink="`/patient/${patientId}/cases/${caseId}/edit${offlineParams ? '?offline=true' : ''}`">
		<template #lowerRight>
			<div class="flex items-center space-x-3">
				<a
					@click="
						router.replace(
							`/patient/${patientId}/cases/${caseId}/edit${offlineParams ? '?offline=true' : ''}`
						)
					">
					<button-component btnWidth="quinary">Cancel</button-component>
				</a>
				<button-component @click="save()">
					<span>Save Changes</span>
				</button-component>
			</div>
		</template>
		<p class="mt-6 text-2xl font-medium text-neutral-600">Patient's Name:
			<span class="font-semibold text-neutral-900">Sample Name</span>
		</p>
		<div class="w-full px-6 py-6 mx-auto my-6 border border-gray-200 rounded-md">
			<div class="flex items-center justify-between">
				<section-title title="Intake" />
			</div>
			<form class="grid grid-cols-6 gap-6 mt-6" v-if="loaded">
				<div class="col-span-6"></div>
				<div class="col-span-2">
					<text-input
						type="text"
						label="Last Name"
						placeholder="Last name"
						name="last_name"
						id="last_name"
						required
						v-model="form.last_name"
						:error="errors?.last_name" />
				</div>
				<div class="col-span-2">
					<text-input
						type="text"
						label="First Name"
						placeholder="First name"
						name="first_name"
						id="first_name"
						required
						v-model="form.first_name"
						:error="errors?.first_name" />
				</div>
				<div class="col-span-2">
					<text-input
						type="text"
						label="Middle Name"
						placeholder="Middle name"
						name="middle_name"
						id="middle_name"
						v-model="form.middle_name"
						:error="errors?.middle_name" />
				</div>
				<div class="col-span-2">
					<label class="block text-xs font-semibold text-neutral-600"
						>Gender <span class="text-red-600">*</span></label
					>
					<div class="flex items-center pt-5 space-x-6">
						<radio-group :options="genders" name="gender" required v-model="form.gender" />
					</div>
					<p
						v-if="errors?.gender"
						class="text-[10px] mt-1"
						:class="errors?.gender ? 'text-red-600' : 'text-gray-800'"
						id="email-error">
						{{ errors?.gender }}
					</p>
				</div>
				<div class="col-span-2">
					<date-picker
						id="birthdate"
						name="birthdate"
						label="Birthdate"
						:disabled="disabledBirthdate"
						v-model="form.birthdate"
						:error="errors?.birthdate" />
				</div>
				<div class="col-span-2">
					<text-input
						type="number"
						label="Age"
						placeholder="age"
						name="approximate_age"
						id="approximate_age"
						:disabled="disabledAge"
						v-model="form.approximate_age"
						:error="errors?.approximate_age" />
				</div>
				<div class="col-span-2">
					<multi-select searchable
						:options="legalStatuses || []"
						id="legal_status"
						name="legal_status"
						label="Legal Status"
						placeholder="Select.."
						v-model="form.legal_status"
						:error="errors?.legal_status" />
				</div>
				<div class="col-span-2">
					<multi-select searchable
						:options="relationships || []"
						id="relationship"
						name="relationship"
						label="Relationship"
						placeholder="Select.."
						v-model="form.relationship_id"
						:error="errors?.relationship_id" />
				</div>
				<div class="col-span-2">
					<text-input
						type="text"
						label="Other relationship"
						placeholder="Other relationship"
						name="other_relationship"
						id="other_relationship"
						v-model="form.other_relationship"
						:error="errors?.other_relationship" />
				</div>
				<div class="col-span-2">
					<text-input
						type="text"
						label="Contact Number"
						placeholder="Contact Number"
						name="contacts"
						id="contacts"
						:add_on_left="true"
						add_on_text="+63"
						:maxlength="10"
						@keypress="numberonly"
						v-model="form.contacts"
						:error="errors?.contacts" />
				</div>
				<div class="col-span-2">
					<label class="block text-xs font-semibold text-neutral-600">Lives with child</label>
					<div class="flex items-center pt-5 space-x-6">
						<radio-group
							:options="yesNoOption"
							name="livesWithChild"
							required
							v-model="form.is_living_with_child" />
					</div>
					<p
						v-if="errors?.is_living_with_child"
						class="text-[10px] mt-1"
						:class="errors?.is_living_with_child ? 'text-red-600' : 'text-gray-800'"
						id="email-error">
						{{ errors?.is_living_with_child }}
					</p>
				</div>
				<div class="col-span-2">
					<multi-select searchable
						:options="civilStatuses"
						id="civil_status"
						name="civil_status"
						label="Civil Status"
						placeholder="Select.."
						v-model="form.civil_status_id"
						:error="errors?.civil_status_id" />
				</div>
				<div class="col-span-2">
					<label class="block text-xs font-semibold text-neutral-600">Employed</label>
					<div class="flex items-center pt-5 space-x-6">
						<radio-group :options="yesNoOption" name="employed" required v-model="form.is_employed" />
					</div>
					<p
						v-if="errors?.is_employed"
						class="text-[10px] mt-1"
						:class="errors?.is_employed ? 'text-red-600' : 'text-gray-800'"
						id="email-error">
						{{ errors?.is_employed }}
					</p>
				</div>
				<div class="col-span-2">
					<label class="block text-xs font-semibold text-neutral-600">OFW</label>
					<div class="flex items-center pt-5 space-x-6">
						<radio-group :options="yesNoOption" name="ofw" required v-model="form.is_ofw" />
					</div>
					<p
						v-if="errors?.is_ofw"
						class="text-[10px] mt-1"
						:class="errors?.is_ofw ? 'text-red-600' : 'text-gray-800'"
						id="email-error">
						{{ errors?.is_ofw }}
					</p>
				</div>
				<div class="col-span-2">
					<text-input
						type="text"
						label="Occupation"
						placeholder="Occupation"
						name="occupation"
						id="occupation"
						v-model="form.occupation"
						:error="errors?.occupation" />
				</div>
				<div class="col-span-2">
					<text-input
						type="text"
						label="Weekly Income"
						placeholder="Weekly Income"
						name="weekly_income"
						id="weekly_income"
						v-model="form.weekly_income"
						:error="errors?.weekly_income" />
				</div>
				<div class="col-span-2">
					<multi-select searchable
						:options="housingClassifications"
						id="housing_classification"
						name="housing_classification"
						label="Housing Classification"
						placeholder="Select.."
						v-model="form.housing_classification_id"
						:error="errors?.housing_classification_id" />
				</div>
				<div class="col-span-2">
					<multi-select searchable
						:options="socioEconomics"
						id="socio_economic_status"
						name="socio_economic_status"
						label="Socio Economic Status"
						placeholder="Select.."
						v-model="form.socio_economic_status_id"
						:error="errors?.socio_economic_status_id" />
				</div>
				<div class="col-span-2">
					<text-input
						type="text"
						label="Number of Children in the Family"
						placeholder="Number of Children in the Family"
						name="number_children"
						id="number_children"
						v-model="form.family_children_count"
						:error="errors?.family_children_count" />
				</div>
				<div class="col-span-2">
					<text-input
						type="text"
						label="Number of Household Members"
						placeholder="Number of Household Members"
						name="number_household_members"
						id="number_household_members"
						v-model="form.house_member_count"
						:error="errors?.house_member_count" />
				</div>
				<div class="col-span-2">
					<text-input
						type="text"
						label="Number of Families in Household"
						placeholder="Number of Families in Household"
						name="number_families"
						id="number_families"
						v-model="form.house_family_count"
						:error="errors?.house_family_count" />
				</div>
				<div class="col-span-6">
					<text-input
						textarea
						label="Brief Family Background"
						placeholder="Brief Family Background"
						name="brief_family_background"
						id="brief_family_background"
						:textAreaRows="1"
						v-model="form.family_background"
						:error="errors?.family_background" />
				</div>
			</form>
		</div>
		<success-modal
			:show="showSuccess"
			@confirm="redirectSuccess()"
			action-text="Close"
			title="Record updated!"
			:content="successMessage" />
	</user-layout>
</template>
<script setup lang="ts">
import { onMounted, ref, computed, watch } from "vue";
import UserLayout from "@/layouts/UserLayout.vue";
import ButtonComponent from "@/components/buttons/ButtonComponent.vue";
import SectionTitle from "@/components/partials/SectionTitle.vue";
import SuccessModal from "@/components/modals/SuccessModal.vue";
import TextInput from "@/components/inputs/TextInput.vue";
import MultiSelect from "@/components/inputs/MultiSelect.vue";
import RadioGroup from "@/components/inputs/RadioGroup.vue";
import DatePicker from "@/components/inputs/DatePicker.vue";
import { useRouter, useRoute } from "vue-router";
import IntakeService from "@/classes/Cases/IntakeService";
import { renderSelect, renderSelectV2 } from "@/interfaces/DropdownInterface";
import { IntakeCreate } from "@/interfaces/Cases/Intake";
import IntakeForm from "@/classes/Forms/Cases/IntakeForm";
import { errorResponse } from "@/helpers/errorMessage";
import { numberonly } from "@/components/composables/useNumberOnly";
import { useNetworkStore } from "@/store/network";
import DB from "@/classes/Databases/Crud";
import pickBy from "lodash/pickBy";
import FormOptions from "@/classes/Forms/FormOptions";
import Gender from "@/enums/Gender";
import LegalStatus from "@/enums/LegalStatus";
const networkStore = useNetworkStore();
const isNetworkAvailable = computed(() => networkStore.isNetworkAvailable);
const db = new DB("case_intakes");
const dbCase = new DB("cases");

const yesNoOption = [
	{ id: 1, label: "Yes", value: 1 },
	{ id: 0, label: "No", value: 0 },
];

const router = useRouter();
const route = useRoute();
const showSuccess = ref<boolean>(false);
const patientId = ref<number | null>(route.params.patient);
const caseId = ref<number | null>(route.params.case);
const intakeId = ref<number | null>(route.params.intake);
const genders = ref<renderSelectV2[]>([]);
const civilStatuses = ref<renderSelect[]>([]);
const housingClassifications = ref<renderSelect[]>([]);
const socioEconomics = ref<renderSelect[]>([]);
const legalStatuses = ref<renderSelect[]>([]);
const relationships = ref<renderSelect[]>([]);
const form = ref<IntakeCreate>(new IntakeForm());
const errors = ref<object>({});
const successMessage = ref<string>("Intake has been updated!");
const loaded = ref<boolean>(false);
const offlineParams = ref<string>(route.query.offline);
const options = new FormOptions();
const disabledAge = computed(() => {
	if (form.value.birthdate) {
		return true;
	}

	if (form.value.birthdate?.length > 0) {
		return true;
	}

	return false;
});
const disabledBirthdate = computed(() => {
	if (disabledAge.value) {
		return false;
	}

	if (form.value.approximate_age == "0") {
		return false;
	}

	if (form.value.approximate_age) {
		return true;
	}

	if (form.value.approximate_age?.toString()?.length > 0) {
		return true;
	}

	return false;
});

const save = (): void => {
	errors.value = {};
	if (!isNetworkAvailable.value) {
		const validate = form.value.offlineCreateValidation(form.value);
		errors.value = validate?.errors;

		if (validate.hasOfflineError) {
			showSuccess.value = false;
			return;
		}
	}

	IntakeService.update(
		patientId.value,
		caseId.value,
		intakeId.value,
		{ ...form.value, ...{ isOfflineData: offlineParams.value } },
		isNetworkAvailable.value
	)
		.then((response: object) => {
			showSuccess.value = true;
			successMessage.value = response.data.message;
		})
		.catch((error: object) => {
			errors.value = errorResponse(error.response?.data?.errors);
		});
};

const redirectSuccess = async (): void => {
	showSuccess.value = false;
	const checkFromOnlineRecord = await dbCase.edit(caseId.value, false, 'cases');
	if (checkFromOnlineRecord.data.item.length != undefined || !checkFromOnlineRecord.data.item.patient_id) {
		const checkFromOfflineRecord = await dbCase.edit(caseId.value, false, 'cases');
		router.replace(
			`/patient/${patientId.value}/cases/${caseId.value}/edit${
				checkFromOfflineRecord.data.item.patient_id ? "" : "?offline=true"
			}`
		);
	} else {
		router.replace(`/patient/${patientId.value}/cases/${caseId.value}/edit`);
	}
};

const getData = (): void => {
	IntakeService.edit(
		patientId.value,
		caseId.value,
		intakeId.value,
		pickBy({ isOfflineData: offlineParams.value ? true : false }),
		isNetworkAvailable.value
	)
		.then((response: object) => {
			if (isNetworkAvailable.value) {
				genders.value = response.data.genders;
				civilStatuses.value = response.data.civilStatuses;
				housingClassifications.value = response.data.housingClassifications;
				socioEconomics.value = response.data.socioEconomics;
				legalStatuses.value = response.data.legalStatuses;
				relationships.value = response.data.relationships;
				form.value.updateIntake(response.data.item);
			} else {
				genders.value = Gender;
				civilStatuses.value = options.civilStatusOptions();
				housingClassifications.value = options.housingClassifications();
				socioEconomics.value = options.socioEconomicStatuses();
				legalStatuses.value = LegalStatus;
				relationships.value = options.relationships();
				form.value.updateIntake(JSON.parse(response.data.item?.content || null));
			}

			loaded.value = true;
		})
		.catch((error: object) => console.log(error));
};

// watch(
// 	() => isNetworkAvailable.value,
// 	(val) => {
// 		console.log("Watcher - Network: " + val);
// 		getData();
// 	}
// );

onMounted(() => getData());
</script>
