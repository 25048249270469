import { CaseConferenceInformationCreate } from "@/interfaces/Cases/CaseConference/CaseConference";
export default class CaseConferenceInfoForm implements CaseConferenceInformationCreate {
	social_worker_id: number | null;
	date_time: string | null;
	invitees: [];
	agencies: [];
	constructor(item: CaseConferenceInformationCreate) {
		this.social_worker_id = item?.social_worker_id;
		this.date_time = item?.date_time;
		this.invitees = item?.invitees;
		this.agencies = item?.agencies;
	}

	createCaseConference(item: CaseConferenceInformationCreate) {
		this.social_worker_id = item?.social_worker_id;
		this.date_time = item?.date_time;
		this.invitees = item?.invitees;
		this.agencies = item?.agencies;
	}

    offlineCreateValidation(form: any) {
		const errors = <any>{};
		let hasOfflineError = false;

		if (!form.social_worker_id) {
			hasOfflineError = true;
			Object.assign(errors, { social_worker_id: "The social worker field is required." });
		}

		if (!form.date_time) {
			hasOfflineError = true;
			Object.assign(errors, { date_time: "The date and time field is required." });
		}

		if (form.invitees == null || form.invitees?.length === 0) {
			hasOfflineError = true;
			Object.assign(errors, { invitees: "At least 1 invitee is required." });
		}

		if (form.agencies == null || form.agencies?.length === 0) {
			hasOfflineError = true;
			Object.assign(errors, { agencies: "At least 1 agency is required." });
		}

		return {
			hasOfflineError: hasOfflineError,
			errors: errors,
		};
	}
}
