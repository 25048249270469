<template>
    <base-modal
        :customClass="customClass"
        :show="show"
        @close="show = false"
    >
        <div class="w-12 h-12 mx-auto mb-4 rounded-full bg-red-100 flex justify-center items-center flex-shrink-0">
            <ExclamationCircleIcon class="w-5 h-5 text-red-600 mx-auto"/>
        </div>
        <DialogTitle
            as="p"
            class="text-xl font-bold text-neutral-900"
        >
            {{ title }}
        </DialogTitle>
        <div class="text-gray-500 text-sm leading-5">
            <p v-if="content">{{ content }}</p>
            <slot name="content" />
        </div>

        <div v-if="!noBtn" class="mt-6">
            <button-component
            size="large"
            btnWidth="primary rounded-lg"
            customClass="primary mx-auto"
            @click="close"
            >{{ actionText }}</button-component>
        </div>
    </base-modal>
</template>

<script setup lang="ts">
import {
  DialogTitle,
} from '@headlessui/vue';
import BaseModal from '@/components/modals/BaseModal.vue';
import ButtonComponent from '@/components/buttons/ButtonComponent.vue';
import { ExclamationCircleIcon } from '@heroicons/vue/24/outline';
import { computed, ref, watch } from 'vue';

const emit = defineEmits(['close'])

const props = defineProps({
    show: {
        type: Boolean,
        default: false
    },
    title: {
        type: String,
        default: "Error"
    },
    content: {
        type: String,
    },
    actionText: {
        type: String,
        default: "close"
    },
    cancelText: {
        type: String,
    },
    customClass: {
        type: String,
        default: 'sm:w-[480px] w-full sm:min-w-[480px] py-6 px-10'
    },
    checkIcon: {
        type: Boolean,
        default: false
    },
    noBtn : {
        type: Boolean,
        default: false
    }
});

const show = ref<boolean>(props.show)

const close = () => {
    emit('close')
    show.value = false
}

watch(props, (val) => {
    show.value = val.show;
})

</script>