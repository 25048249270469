import DatabaseInitialization from "@/database/DatabaseInitialization";
import OfflineIndexFilters from "@/classes/General/OfflineIndexFilters";

import { OffilinePageInterface } from "@/interfaces/offline/OffilinePageInterface";

import { ref } from 'vue';

const db = new DatabaseInitialization;

export default class Pagination {

    table: string

     constructor(table: string) {
        this.table = table;
    }

    async init(param: any, whereCondition = '', query = ''): Promise<any>{
        
        const filters = ref<OffilinePageInterface>(new OfflineIndexFilters(param));

        const paginate = await this.paginate(param, whereCondition, query);

        filters.value.allItemsCount = paginate.total;
        filters.value.items = paginate;

        return { data: filters.value};
    }

    async paginate(params: any, whereCondition = '', customQuery = ''): Promise<any>{

        const rows = params.rows ?? 5;

        if (customQuery) {
            return await this.customPaginate(params, customQuery);
        } 

        const search = this.search(params, whereCondition);

        let query = `SELECT * FROM ${this.table}`;

        if (whereCondition) {
            query += whereCondition;
        }

        await db.connect();
        const total = await db.prx().select(query);

        query += search;
        if (query.toLowerCase().includes('where')) {
            query += ' AND (deleted_at="" OR deleted_at IS NULL)'
        } else {
            query += ' WHERE (deleted_at="" OR deleted_at IS NULL)'
        }
        
        query += ` ORDER BY id DESC`;
        
        if (params.rows) {
            query += ` LIMIT ${params.rows}`
        }

        if (params.page && params.page > 1) {
            query += ` OFFSET ${params.page - 1}`
        }

        await db.connect();
        const results = await db.prx().select(query);

        return {
            current_page: params.page,
            data: results,
            from: params.page,
            last_page: Math.ceil(total.length / rows),
            per_page: rows,
            to: params.page,
            total: total.length,
        };
    }

    async customPaginate(params: any, query = ''): Promise<any>{
        const rows = params.rows ?? 5;
        await db.connect();
        const total = await db.prx().select(query);
        const results = await db.prx().select(query);

        return {
            current_page: params.page,
            data: results,
            from: params.page,
            last_page: Math.ceil(total.length / rows),
            per_page: rows,
            to: params.page,
            total: total.length,
        };
    }

    search(params: any, whereCondition = ''): string {
        let query = '';

        if (whereCondition) {
            if (params.query) {
                query += ` AND name LIKE '%${params.query}%'`
            }
        } else {
            if (params.query) {
                query += ` WHERE name LIKE '%${params.query}%'`
            }
        }
        
        return query;
    }

}