import { OfflineAction } from "@/views/enums/OfflineAction";
import Storage from "@/helpers/storage";
import Relationships from "../Relationships";

const storage = new Storage();
const relationship = new Relationships();

const setUpdateForm = async (item: any, content: any) => {
    const birthdate = item.birthdate ? relationship.dateFormatFromIso(item.birthdate) : null;
	content.about_perpetrator = item.about_perpetrator || content.about_perpetrator;
	content.age = birthdate ? relationship.computeAge(birthdate) : relationship.computeApproximateAge(item.approximate_age_year, item.approximate_age_month);
    content.computed_age = birthdate ? relationship.computeAge(birthdate) : relationship.computeApproximateAge(item.approximate_age_year, item.approximate_age_month);
	content.birthdate = relationship.dateFormatFromIso(birthdate);
    content.birthdate_formatted = birthdate ? relationship.dateFormat(birthdate) : null;
	content.contacts = item.contacts || content.contacts;
	content.current_location = item.current_location;
	content.current_location_meta = relationship.currentLocation(item?.current_location ? item : content);
	content.details = item.details || content.details;
	content.first_name = item.first_name || content.first_name;
	content.last_name = item.last_name || content.last_name;
	content.middle_name = item.last_name || content.middle_name;
	content.name = `${item.first_name || content.first_name} ${item.last_name || content.last_name}`;
	content.gender = item.gender;
	content.gender_meta = relationship.gender(item.gender ? item : content);
	content.identity = item.identity || content.identity;
	content.identity_meta = relationship.perpetratorIdentity(item?.identity ? item : content);
	content.is_minor = item.is_minor || content.is_minor;
	content.nationality_id = item.nationality_id;
	content.nationality = await relationship.nationality(item.nationality_id);
    content.other_nationality = item.other_nationality;
	content.nickname = item.nickname || content.nickname;
	content.occupation = item.occupation || content.occupation;
	content.other_relationship = item.other_relationship || content.other_relationship;
	content.parent_contact_number = item.parent_contact_number || content.parent_contact_number;
	content.parent_name = item.parent_name || content.parent_name;
	content.parent_relationship_id = item.parent_relationship_id;
	content.parent_relationship = await relationship.perpetratorRelationship(
		item?.parent_relationship_id || content.parent_relationship_id
	);
	content.perpetrator_id = item.perpetrator_id || content.perpetrator_id;
	content.perpetrator_information = item.perpetrator_information || content.perpetrator_information;
	content.perpetrator_location = item.perpetrator_location || content.perpetrator_location;
	content.perpetrator_relationship_id = item.perpetrator_relationship_id || content.perpetrator_relationship_id;
	content.relationship = await relationship.perpetratorRelationship(
		item?.perpetrator_relationship_id || content.perpetrator_relationship_id
	);
	content.religion_id = item.religion_id || content.religion_id;
	content.religion = relationship.religion(item?.religion_id ? item : content);
    content.other_religion = item.other_religion;
	content.tag = item.tag || content.tag;
    content.encoded_by = storage.get("user");
    content.approximate_age_month = item.approximate_age_month;
    content.approximate_age_year = item.approximate_age_year;
    content.birthday_classification = item.birthday_classification || content.birthday_classification;
    content.name = item.identity === 1 ? item.tag : `${item.first_name} ${item.last_name}`;
    const date = new Date().toJSON();
    content.updated_at = relationship.dateFormatFromIso(date);
    content.updated_at_formatted = relationship.dateFormat(date);
	return JSON.stringify(content);
};

const setNewForm = async (item: any, content: any) => {
	const data = <any>{};
	data.about_perpetrator = item.about_perpetrator || null;
	data.age = item.birthdate ? relationship.computeAge(item.birthdate) : relationship.computeApproximateAge(item.approximate_age_year, item.approximate_age_month);
    data.computed_age = item.birthdate ? relationship.computeAge(item.birthdate) : relationship.computeApproximateAge(item.approximate_age_year, item.approximate_age_month);
	data.birthdate = item.birthdate ? relationship.dateFormatFromIso(item.birthdate) : null;
    data.birthdate_formatted = item.birthdate ? relationship.dateFormat(item.birthdate) : null;
	data.contacts = item.contacts || null;
	data.current_location = item.current_location;
	data.current_location_meta = relationship.currentLocation(item?.current_location ? item : content) || null;
	data.details = item.details || null;
	data.first_name = item.first_name || null;
	data.last_name = item.last_name || null;
	data.middle_name = item.middle_name || null;
	data.name = `${item.first_name || ""} ${item.last_name || ""}`;
	data.gender = item.gender;
	data.gender_meta = relationship.gender(item.gender ? item : content) || null;
	data.identity = item.identity;
	data.identity_meta = relationship.perpetratorIdentity(item?.identity ? item : content) || null;
	data.is_minor = item.is_minor;
	data.nationality_id = item.nationality_id || null;
	data.nationality = await relationship.nationality(item.nationality_id) || null;
    data.other_nationality = item.other_nationality;
	data.nickname = item.nickname || null;
	data.occupation = item.occupation || null;
	data.other_relationship = item.other_relationship || null;
	data.parent_contact_number = item.parent_contact_number || null;
	data.parent_name = item.parent_name || null;
	data.parent_relationship_id = item.parent_relationship_id || null;
	data.parent_relationship =
		await relationship.perpetratorRelationship(item?.parent_relationship_id || content.parent_relationship_id) || null;
	data.perpetrator_id = item.perpetrator_id || null;
    data.offline_perpetrator_id = item.offline_perpetrator_id || null;
	data.perpetrator_information = item.perpetrator_information || null;
	data.perpetrator_location = item.perpetrator_location || null;
	data.perpetrator_relationship_id = item.perpetrator_relationship_id || null;
	data.relationship =
		await relationship.perpetratorRelationship(
			item?.perpetrator_relationship_id || content.perpetrator_relationship_id
		) || null;
	data.religion_id = item.religion_id || null;
	data.religion = await relationship.religion(item?.religion_id ? item : content) || null;
    data.other_religion = item.other_religion;
	data.tag = item.tag || null;
    data.encoded_by = storage.get("user");
    data.approximate_age_year = item.approximate_age_year;
    data.approximate_age_month = item.approximate_age_month;
    data.birthday_classification = item.birthday_classification;
    data.name = item.identity === 1 ? item.tag : `${item.first_name} ${item.last_name}`;
    const date = new Date().toJSON();
    data.created_at = relationship.dateFormatFromIso(date);
    data.created_at_formatted = relationship.dateFormat(date);
	return JSON.stringify(data);
};

export default class CasePerpetratorOfflineForm {
	content: string | null;
	encoded_by: number | null;
	action: number | null;
	is_sync: boolean;
	created_at: string | null;
	updated_at: string | null;
	deleted_at: string | null;
	id: string | null;
	cases_id: string | null;
	offline_cases_id: string | null;
    perpetrator_id: string | null;
    offline_perpetrator_id: string | null;
	constructor(item: any, res: any, caseId = null) {
		this.id = item.id;
		this.cases_id = caseId;
		this.offline_cases_id = "";
        this.perpetrator_id = item.id;
		this.offline_perpetrator_id = "";
		this.content = JSON.stringify(item);
		this.encoded_by = res.encoded_by;
		this.action = OfflineAction.DEFAULT;
		this.is_sync = false;
		this.created_at = "";
		this.updated_at = "";
		this.deleted_at = "";
	}

	async updateForm(item: any, res: any, caseId = null): Promise<any> {
		const data = <any>{};
		data.id = res.id;
		data.cases_id = caseId || "";
		data.offline_cases_id = null;
        data.perpetrator_id = res.id || "";
		data.offline_perpetrator_id = null;
		data.content = await setUpdateForm(item, JSON.parse(res.content));
		data.encoded_by = 0;
		data.action = OfflineAction.UPDATE_RECORD;
		data.is_sync = false;
		data.created_at = res.created_at;
		data.updated_at = relationship.dateFormatFromIso(new Date());
		data.deleted_at = "";
        data.is_updated_offline = res.is_created_offline ? false : true;
        data.is_created_offline = res.is_created_offline;

		return data;
	}

	async createForm(item: any, res: any, caseId = null): Promise<any> {
		const data = <any>{};
		// data.id = "";
		data.cases_id = caseId || "";
		data.offline_cases_id = null;
		data.perpetrator_id = null;
		data.offline_perpetrator_id = null;
		data.content = await setNewForm(item, JSON.parse(res.content));
		data.encoded_by = 0;
		data.action = OfflineAction.NEW_RECORD;
		data.is_sync = false;
		data.created_at = relationship.dateFormatFromIso(new Date());
		data.updated_at = "";
		data.deleted_at = "";
        data.is_created_offline = true;
		return data;
	}
    
    async createPerpetratorForm(item: any, isOnline = true): Promise<any> {
		const data = <any>{};
        if (item.id) {
            data.id = item.id;
        }
        
		data.content = await setNewForm(item, item);
        data.first_name = item.identity == 1 ? item.tag : (item.first_name || "");
        data.last_name = item.last_name || "";
        data.middle_name = item.middle_name || "";
        data.birthdate = item.birthdate ? relationship.dateFormatFromIso(item.birthdate) : "";
		data.encoded_by = 0;
		data.action = isOnline ? OfflineAction.DEFAULT : OfflineAction.NEW_RECORD;
		data.is_sync = false;
		data.created_at = isOnline ? "" : relationship.dateFormatFromIso(new Date());
		data.updated_at = isOnline ? "" : relationship.dateFormatFromIso(new Date());
		data.deleted_at = "";
        data.is_created_offline = isOnline ? false : true;
		return data;
	}

    async updatePerpetratorForm(item: any, res: any): Promise<any> {
		const data = <any>{};
		data.id = res.id;
        data.first_name = item.first_name || "";
        data.last_name = item.last_name || "";
        data.middle_name = item.middle_name || "";
        data.birthdate = item.birthdate ? relationship.dateFormatFromIso(item.birthdate) : "";
		data.content = await setUpdateForm(item, JSON.parse(res.content));
		data.encoded_by = 0;
		data.action = OfflineAction.UPDATE_RECORD;
		data.is_sync = false;
		data.created_at = res.created_at;
		data.updated_at = relationship.dateFormatFromIso(new Date());
		data.deleted_at = "";
        data.is_updated_offline = res.is_created_offline ? false : true;
        data.is_created_offline = res.is_created_offline;
		return data;
	}    

    // async linkPerpetratorForm(item: any, res: any, caseId = null): Promise<any> {
    async linkPerpetratorForm(perpetratorId = null, caseId = null, isOnline = true): Promise<any> {
		const data = <any>{};
		data.cases_id = caseId ? parseInt(caseId) : "";
		data.offline_cases_id = null;
		data.perpetrator_id = perpetratorId;
		data.offline_perpetrator_id = null;
		data.content = "";
		data.encoded_by = 0;
		data.action = isOnline ? OfflineAction.DEFAULT : OfflineAction.NEW_RECORD;
		data.is_sync = false;
		data.created_at = isOnline ? "" : relationship.dateFormatFromIso(new Date());
		data.updated_at = isOnline ? "" : relationship.dateFormatFromIso(new Date());
		data.deleted_at = "";
        data.is_created_offline = isOnline ? false : true;
		return data;
	}
    

	async deleteForm(item: any, res: any, caseId = null): Promise<any> {
		const data = <any>{};
		data.id = res.id;
		data.cases_id = caseId || null;
		data.offline_cases_id = null;
		data.perpetrator_id = res.perpetrator_id;
		data.offline_perpetrator_id = null;
		data.content = "";
		data.encoded_by = 0;
		data.action = OfflineAction.DELETE_RECORD;
		data.is_sync = false;
        data.created_at = res.created_at;
		data.updated_at = res.updated_at;
		data.deleted_at = relationship.dateFormatFromIso(new Date());
        data.is_created_offline = res.is_created_offline;
        data.is_updated_offline = res.is_created_offline ? false : res.is_updated_offline;
        data.is_deleted_offline = true;
		return data;
	}
}
