import DB from "@/classes/Databases/Crud";
import { OfflineAction } from "@/views/enums/OfflineAction";
import Relationships from "../../Relationships";
import FormOptions from "../../FormOptions";


const db = new DB("intake_incest_cases");
const dbCases = new DB("cases");
const dbPatient = new DB("patients");
const relationship = new Relationships();
const options = new FormOptions();
class OfflineSyncIncestCase {
    async syncToOffline(payload: any, caseId: any) {
        await db.insert(
            this.contentFormat(payload, caseId),
            payload.id,
            true,
            'intake_incest_cases'
        );
    }

    async save(payload: any, patientId: any, caseId: any) {
        if (payload.id) {
            await this.update(payload, caseId);
        } else {
            await this.create(payload, caseId);
        }
    }

    async create(payload: any, caseId: any) {
        await db.delete({cases_id: caseId}, 'intake_incest_cases')
        await db.insert({
            cases_id: caseId,
            content: JSON.stringify(payload),
            encoded_by: 0,
            action: OfflineAction.NEW_RECORD,
            is_sync: false,
            created_at: relationship.dateFormatFromIso(new Date()),
            updated_at: relationship.dateFormatFromIso(new Date()),
            deleted_at: "",
            is_created_offline: true,
        }, null, false, 'intake_incest_cases');
    }

    async update(payload: any, caseId: any) {
        const response = await db.select(`SELECT * FROM intake_incest_cases WHERE id=${payload.id} LIMIT 1`);
        if (response.length > 0 ) {
            const item = response[0];
            await db.delete({id: item.id}, 'intake_incest_cases')
            await db.insert({
                id: payload.id,
                cases_id: caseId,
                content: JSON.stringify(payload),
                encoded_by: 0,
                action: OfflineAction.UPDATE_RECORD,
                is_sync: false,
                created_at: item.created_at,
                updated_at: relationship.dateFormatFromIso(new Date()),
                deleted_at: "",
                is_created_offline: item.is_created_offline,
                is_updated_offline: item.is_created_offline ? false : true,
            }, null, false, 'intake_incest_cases');
        }
    }

    async show(patientId: any, caseId: any) {
        const response = await db.select(`SELECT * FROM intake_incest_cases WHERE cases_id=${caseId}`);
        let item = null;
        if (response.length > 0) {
            let content = JSON.parse(response[0].content);
            const cases = await dbCases.edit(caseId);
            if (cases.data.item.length === undefined) {
                content = {...content, ...{cases_information: JSON.parse(cases.data.item.content), id: content.id}};
            }            
            item = content;
        }

        const patient = await dbPatient.edit(patientId);
        let patientName = null;
        let patientNameWithBirthday = null;
        if (patient.data.item.length === undefined) {
            const patientItem = JSON.parse(patient.data.item.content);
            patientName = `${patientItem.last_name}, ${patientItem.first_name}`;
            patientNameWithBirthday = patientItem.name + ' ' + patientItem.birthdate;
        }

        return {
            sleepingArrangements: options.sleepingArrangements(),
            item: item,
            sleepingArrangementWith: options.sleepipngArrangmentWith(),
            livingArrangements: options.livingArrangements(),
            patient: patientName,
            patient_with_birthday: patientNameWithBirthday
        }
    }

    transformContent(payload: any, item: any) {
        payload.id = item.id;
        payload.updated_at_formatted = relationship.dateFormat(item.updated_at);
        return payload;
    }

    contentFormat(payload: any, caseId: any) {
        return {
            id: payload.id,
            cases_id: caseId,
            content: JSON.stringify(payload),
            encoded_by: 0,
            action: OfflineAction.DEFAULT,
            is_sync: false,
            created_at: "",
            updated_at: "",
            deleted_at: "",
        }
    }
}

export default new OfflineSyncIncestCase();