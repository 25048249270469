<template>
	<user-layout mainCustomClass="!pb-0">
		<div class="-mx-6 w-[calc(100%+48px)] relative">
			<div class="absolute -mx-4 w-full bg-gray-100 -bottom-16 md:right-2 right-3 h-16 pointer-events-none"></div>
			<div class="min-h-screen p-8 bg-gray-100">
				<div class="lg:w-[90%] w-full mx-auto">
					<div class="grid grid-cols-3 lg:gap-8 gap-3">
						<div class="lg:col-span-2 col-span-3">
							<h1 class="text-2xl font-bold lg:mb-7 lg:mt-2.5">Dashboard</h1>
						</div>
						<div class="lg:col-span-1 col-span-3">
							<div class="flex items-center lg:justify-end mb-5 space-x-4">
								<div class="w-fit">
									<date-picker
										id="filter_from"
										name="filter_from"
										:range="true"
										multiCalendars
										v-model="dates"
										@update:modelValue="(value) => (dates = value)"
										class="filter-range filter-dashboard"
									/>
								</div>
							</div>
						</div>
					</div>
					<div class="grid grid-cols-3 md:gap-8 gap-4">
						<div class="lg:col-span-2 col-span-3">
							<div class="grid grid-cols-2 md:gap-8 gap-4">
								<div class="md:col-span-1 col-span-2 md:overflow-visible overflow-auto side-scroll">
									<div class="bg-white rounded-[10px] relative min-h-[180px] sm:min-w-[unset] min-w-[400px] h-full">
										<div class="relative w-full h-full">
											<div class="absolute top-0 left-0 z-0 w-full">
												<div class="flex items-start justify-between pl-[50px] pr-5 pt-5">
													<div class="flex items-center justify-between space-x-4">
														<div
															class="relative flex items-center justify-center w-16 h-16 mx-auto overflow-hidden rounded-full">
															<!-- <img
																src="/assets/icon/ic-office.svg"
																alt="office_icon"
																class="w-5 h-5 object-contain relative z-[1px]" /> -->
															<office-icon class="w-5 h-5 object-contain relative z-[1px]" />
															<div class="absolute w-full h-full bg-primary-500 opacity-5"></div>
														</div>
			
														<div>
															<p class="text-sm mb-3 text-gray-700 leading-[14px]">
																Total Offices
															</p>
															<p class="font-bold text-2xl text-gray-700 leading-[26px]">
																{{officeTotal.total}}
															</p>
														</div>
													</div>
													<p class="text-green-600 text-[0.688rem] font-semibold" v-if="officeTotal.new > 0">+{{ officeTotal.new }} New Added</p>
															
												</div>
											</div>
											<Line
												v-if="grafLoading"
												:options="lineChartOptions"
												:data="officeGraphData"
												:height="cartHeight"
											/>
										</div>
										<div class="md:block hidden absolute h-[110%] w-px bg-gray-500 -top-2 -right-4"></div>
									</div>
								</div>
								<div class="md:col-span-1 col-span-2 md:overflow-visible overflow-auto side-scroll">
									<div class="bg-white rounded-[10px] overflow-hidden relative min-h-[180px] sm:min-w-[unset] min-w-[400px] h-full">
										<div class="absolute top-0 left-0 z-0 w-full">
											<div class="flex items-start justify-between pl-[50px] pr-5 pt-5">
												<div class="flex items-center justify-between space-x-4">
													<div
														class="relative flex items-center justify-center w-16 h-16 mx-auto overflow-hidden rounded-full">
														<!-- <img
															src="/assets/icon/ic-personnel.svg"
															alt="office_icon"
															class="w-5 h-5 object-contain relative z-[1px]" /> -->
														<personnel-icon class="w-5 h-5 object-contain relative z-[1px]" />
														<div
															class="absolute w-full h-full bg-secondary-400 opacity-5"></div>
													</div>
													<div>
														<p class="text-sm mb-3 text-gray-700 leading-[14px]">
															Total Personnel
														</p>
														<p class="font-bold text-2xl text-gray-700 leading-[26px]">
																{{ personnelTotal.total }}
															</p>
														</div>
													</div>
												<p class="text-green-600 text-[0.688rem] font-semibold" v-if="personnelTotal.new > 0">+{{ personnelTotal.new }} New Added</p>
											</div>
										</div>
										<!-- <Line :data="data" :options="options" /> -->
										
										<Line
											v-if="grafLoading"
											:options="lineChartOptions"
											:data="personnelGraphData"
											:height="cartHeight"
										/>
									</div>
								</div>
								<div class="col-span-2 bg-white rounded-[10px] overflow-hidden p-[30px]">
									<div class="flex items-center justify-between">
										<p class="text-lg font-bold text-gray-700">Newly Added Personnel</p>
										<a
											href="/personnel/index"
											class="text-xs font-semibold transition text-primary-600 hover:text-primary-800"
											>See All</a
										>
									</div>
									<div class="mt-8">
										<data-table
											:headers="headers"
											:no-action="true"
											:count="newPersonnels.length"
											tableClass="header-frm-table dashboard__data-table"
											dataTableClass="border-0">
											<template v-slot:body>
												<template v-for="item in newPersonnels" :key="item">
													<tr>
														<td class="px-6 py-2 text-xs text-gray-900 whitespace-nowrap">
															{{ item.id }}
														</td>
														<td
															class="flex items-center px-6 py-2 text-xs text-gray-900 whitespace-nowrap">
															<div >
																<img class="flex items-center justify-center rounded-full w-9 h-9 bg-secondary-600" :src="item.profile_photo_url" />
															</div>
															<a
																@click="router.replace(`/personnel/edit/${item.id}`)"
																class="ml-2.5 text-primary-600 hover:text-primary-800 transition"
																>{{ item.name }}</a
															>
														</td>
														<td class="px-6 py-2 text-xs text-gray-900 whitespace-nowrap">
															{{ item.office_name }}
														</td>
														<td class="px-6 py-2 text-xs text-gray-900 whitespace-nowrap">
															{{ item.created_at_formatted }}
														</td>
													</tr>
												</template>
											</template>
										</data-table>
									</div>
								</div>
							</div>
						</div>
						<div class="lg:col-span-1 col-span-3">
							<div class="grid grid-cols-2 md:gap-8 gap-4">

								<div class="col-span-2 bg-white rounded-[10px] overflow-hidden p-[30px]">
									<p class="mb-8 text-lg font-bold text-gray-700">Personnel Status</p>
									<div class="flex flex-col space-y-5 dashboard_bargraph">
										<div class="grid grid-cols-[44px_1fr] gap-x-2">
											<div class="col-span-1">
												<p class="text-[0.688rem]">Verified</p>
											</div>
											<div class="flex items-center col-span-1 space-x-4">
												<div class="h-3 rounded-r-lg dashboard_bar" :style="{ 'width': (accountStatus.verified / accountStatus.total) * 100 + '%' }"></div>
												<span class="text-xs">{{ accountStatus.verified }}</span>
											</div>
										</div>
										<div class="grid grid-cols-[58px_1fr] gap-x-2">
											<div class="col-span-1">
												<p class="text-[0.688rem]">Unverified</p>
											</div>
											<div class="flex items-center col-span-1 space-x-4">
												<div class="h-3 rounded-r-lg dashboard_bar" :style="{ 'width': (accountStatus.unverified / accountStatus.total) * 100 + '%' }"></div>
												<span class="text-xs">{{ accountStatus.unverified }}</span>
											</div>
										</div>
									</div>
								</div>

								<div class="col-span-2 bg-white rounded-[10px] overflow-hidden p-[30px]">
									<p class="mb-8 text-lg font-bold text-gray-700">Personnel Added by Month</p>
									<div class="flex flex-col space-y-5 dashboard_bargraph">
										<div 
											v-for="item in statusPerMonth" :key="item"
											class="grid grid-cols-[24px_1fr] gap-x-2"
										>
											<div class="col-span-1">
												<p class="text-[0.688rem]">{{item.month}}</p>
											</div>
											<div class="flex items-center col-span-1 space-x-4">
												<div class="h-3 rounded-r-lg dashboard_bar" :style="{ 'width': item.percentage + '%'}" ></div>
												<span class="text-xs">{{ item.personnels }}</span>
											</div>
										</div>
									</div>
								</div>
								
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</user-layout>
</template>
<script setup lang="ts">
import { ref, onMounted, watch, computed } from "vue";
import UserLayout from "@/layouts/UserLayout.vue";
import DatePicker from "@/components/inputs/DatePicker.vue";
import DataTable from "@/components/partials/DataTable.vue";

import Dashboard from "@/classes/Dashboard/Dashboard";
import { useNetworkStore } from "@/store/network";
import PersonnelIcon from "@/components/icons/personnelIcon.vue";
import OfficeIcon from "@/components/icons/officeIcon.vue";
import DB from "@/classes/Databases/Crud";
import { useRouter } from "vue-router";
const router = useRouter();
const db = new DB('patient');

import { Line } from "vue-chartjs";
import {
	Chart as ChartJS,
	Title,
	Tooltip,
	Legend,
	LineElement,
	BarElement,
	LinearScale,
	PointElement,
	CategoryScale,
	Filler,
	ArcElement,
} from "chart.js";

ChartJS.register(
	Title,
	Tooltip,
	Legend,
	LineElement,
	BarElement,
	LinearScale,
	PointElement,
	CategoryScale,
	Filler,
	ArcElement
);

const grafLoading = ref<boolean>(false);

const dates = ref<Array<any>>([
	new Date(),
	new Date(new Date().setDate(new Date().getDate() + 7))
])

const cartHeight = 100;

const lineChartOptions = {
	responsive: true,
	maintainAspectRatio: false,
	plugins: {
		legend: {
			// position: "bottom",
			// align: "end",
			// reverse: true,

			display: false
		
		},
	},
	scales: {
		x: {
			offset: true,
			grid: {
				display: false,
			},
			ticks: {
				autoSkip: true,
				maxRotation: 0,
				minRotation: 0,
			},
		},
		y: {
			grid: {
				display: false,
			},
			beginAtZero: true,
		},
	},
};

const headers: { text: string; haveSort?: boolean }[] = [
	{ text: "ID", haveSort: true },
	{ text: "Name", haveSort: true },
	{ text: "Office", haveSort: true },
	{ text: "Date Added", haveSort: true },
];

const networkStore = useNetworkStore();
const isNetworkAvailable = computed(() => networkStore.isNetworkAvailable);

interface GraphInterface {
	labels: string,
	datasets: number[],
}

interface TotalInterface {
	total: number,
	new: number,
}

interface StatusPerMonthInterface {
	month: string,
	personnels: number,
	percentage: number,
}

const personnelGraphData = ref<GraphInterface>({
	labels: '',
	datasets: [],
});

const officeGraphData = ref<GraphInterface>({
	labels: '',
	datasets: [],
});

const personnelTotal = ref<TotalInterface>({
	total: 0,
	new: 0,
})

const officeTotal = ref<TotalInterface>({
	total: 0,
	new: 0,
})

const newPersonnels = ref<any>([])

const accountStatus = ref<{
	verified: number
	unverified: number
	total: number
}>({
	verified: 0,
	unverified: 0,
	total: 0,
});


const statusPerMonth = ref<StatusPerMonthInterface[]>([]);

const init = (): void => {
	grafLoading.value = false

	Dashboard.index({dates: dates.value})
		.then(({ data }) => {
			personnelGraphData.value.labels = data.graphs.personnel_graph[0]
			personnelGraphData.value.datasets = data.graphs.personnel_graph[1]
			
			officeGraphData.value.labels = data.graphs.office_graph[0]
			officeGraphData.value.datasets = data.graphs.office_graph[1]

			personnelTotal.value =  data.total.personnel_total
			officeTotal.value = data.total.office_total
			
			newPersonnels.value = data.newPersonnels
			
			accountStatus.value = data.personnelAccountStatus
			
			statusPerMonth.value =  data.personnelStatusPerMonth
			
			grafLoading.value = true
		})
}

const preloadSQLITE = async () => {
    await db.select(`SELECT * FROM patients LIMIT 1`);
}

watch(
	() => dates.value,
	() => { 
		init();
	}
)

watch(
    () => isNetworkAvailable.value,
    (val) => {
        if (!val) {
            router.push('/patient/search');
        }
    }
);

onMounted(async() => {
    await preloadSQLITE();
    if (!isNetworkAvailable.value) {
        router.push('/patient/search');
        return;
    }

	init()	
})




</script>
@/database/DataDictionaryMigration