<template>
    <user-layout hasBack backTitle="Workload Report by personnel" backLink="/reports">

        <template #lowerRight>
            <export-button @click="exporting()" />
        </template>

        <div class="-mx-6 w-[calc(100%+32px)] pb-6" v-if="!loading">
            <div class="mx-6 mt-8 border rounded-lg border-neutral-100">
                <div class="">
                    <data-table 
                        :hasSearch="true"
                        searchPlaceholder="Search Office, Personnel"
                        :headers="headers" 
                        :no-action="true"
                        :count="items?.data?.length"
                        tableClass="header-frm-table"
                        dataTableClass="border-0"
                        :search="filters.query"
                        @update:searchText="(value:string) => (filters.query = value)"
                    >
                        <template #topContent>
                            <div class="flex space-x-2">
                                <div class="flex px-4 py-3 space-x-2 transition bg-white border rounded cursor-pointer border-neutral-100 hover:bg-primary-100"
                                @click="showFilter = true">
                                    <FunnelIcon class="inline-block w-5 h-5 text-neutral-900" />
                                    <p class="text-sm font-semibold text-neutral-900">Filter</p>
                                </div>
                                
                                <Popover class="relative">
                                    <PopoverButton>
                                        <div class="flex px-4 py-3 space-x-2 transition bg-white border rounded cursor-pointer border-neutral-100 hover:bg-primary-100">
                                            <ArrowsUpDownIcon class="inline-block w-5 h-5 text-neutral-900" />
                                            <p class="text-sm font-semibold text-neutral-900">Sort</p>
                                        </div>
                                    </PopoverButton>

                                    <transition
                                        enter-active-class="transition duration-200 ease-out"
                                        enter-from-class="translate-y-1 opacity-0"
                                        enter-to-class="translate-y-0 opacity-100"
                                        leave-active-class="transition duration-150 ease-in"
                                        leave-from-class="translate-y-0 opacity-100"
                                        leave-to-class="translate-y-1 opacity-0"
                                    >
                                        <PopoverPanel class="absolute right-0 z-10 mt-3 w-fit">
                                        <div class="p-6 overflow-hidden bg-white rounded-lg shadow-md ring-1 ring-black/5 sort">
                                            <div class="flex flex-col w-auto space-y-2 bg-white">
                                                <radio-group
                                                    :options="sortOptions"
                                                    v-model="filters.column"
                                                    name="sampleoptions"
                                                    @update:modelValue="getData()"
                                                />
                                            </div>
                                            <hr class="my-4">
                                            <div class="flex flex-col w-auto space-y-2 bg-white">
                                                <radio-group
                                                    :options="orderOptions"
                                                    v-model="filters.order"
                                                    name="defaultoptions"
                                                    @update:modelValue="getData()"
                                                />
                                            </div>
                                        </div>
                                        </PopoverPanel>
                                    </transition>
                                </Popover>

                                <FilterPanel
                                    :show="showFilter"
                                    @close="showFilter = false"
                                    @apply:reset="applyReset()"
                                    @apply:filters="applyFilters()"
                                    customClass="!overflow-visible overflow-x-visible"
                                    >
                                    <template #fields>
                                        <div class="space-y-5">
                                            <date-picker
                                                id="created_at"
                                                name="created_at"
                                                label="Date Encoded"
                                                v-model="filters.created_at"
                                                range
                                                multiCalendars
                                                class="filter-range"
                                            />
                                
                                            <multi-select
                                                :options="interventionTypeOptions"
                                                v-model="filters.intervention_type"
                                                id="intervention"
                                                name="intervention"
                                                label="Intervention"
                                                placeholder="Select.." />
                                        </div>
                                    </template>
                                </FilterPanel>
                            </div>
                        </template>
                        <template #body>
                            <template 
                                v-for="item in items.data" 
                                :key="item"
                            >
                                <tr v-if="item">
                                    <td class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
                                        {{ item?.office }}
                                    </td>
                                    <td class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
                                        {{ item?.intervention }}
                                    </td>
                                    <td class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
                                        {{ item?.done_by }}
                                    </td>
                                    <td class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
                                        {{ item?.total }}
                                    </td>
                                </tr>
                            </template>
                        </template>
                    </data-table>
                    <div class="w-full px-6 border-t py-7"> 
						<simple-pagination 
							:numRows="filters?.rows ?? 10" 
							:currentPage="items?.current_page" 
							:last-page="items?.last_page"
                            @update="(value: any) => paginate(value.page, value.rows)" 
						/>
					</div>
                </div>
            </div>
        </div>

        <loading-modal
            :show="loading"
            noBtn
            title="Please wait.."
            content="System is currently loading the report based on the selected filter."
        />
    </user-layout>
</template>
<script setup lang="ts">
import { ref, watch, onMounted } from 'vue';
import UserLayout from "@/layouts/UserLayout.vue";
import DataTable from '@/components/partials/DataTable.vue';
import ExportButton from '@/components/buttons/ExportButton.vue';
import SimplePagination from "@/components/partials/SimplePagination.vue";
import DatePicker from "@/components/inputs/DatePicker.vue";
import RadioGroup from "@/components/inputs/RadioGroup.vue";
import MultiSelect from "@/components/inputs/MultiSelect.vue";
import FilterPanel from "@/components/partials/FilterPanel.vue";
import LoadingModal from '@/components/modals/LoadingModal.vue';
import { AdjustmentsVerticalIcon, UserGroupIcon, FunnelIcon, ArrowsUpDownIcon } from "@heroicons/vue/24/outline";
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue';
import ReportService from '@/classes/Reports/ReportService';


import isNil from "lodash/isNil";
import omitBy from "lodash/omitBy";
import debounce from "lodash/debounce";

const showFilter = ref<boolean>(false); 
const loading = ref<boolean>(false);
const items = ref<any>([]);

const sortOptions = [
    { id: '1', label: 'Created', value: 'created_at'}, 
    { id: '2', label: 'Count', value: 'total'}, 

]
const orderOptions = [
    { id: '3', label: 'Ascending', value: 'asc'},
    { id: '4', label: 'Descending', value: 'desc'},
]

const headers: { text: string }[] = [
    { text: 'Office' },
    { text: 'Intervention' },
    { text: 'Done by' },
    { text: 'Total' },
];
const interventionTypeOptions = [
    {id: 0, value: 0, label: "Social Work", description: "Social Work"},
    {id: 1, value: 1, label: "Medical", description: "Medical"},
    {id: 2, value: 2, label: "Mental", description: "Mental"},
    {id: 3, value: 3, label: "Development", description: "Development"},
    {id: 4, value: 4, label: "Legal", description: "Legal"},
    {id: 5, value: 5, label: "Teleconsultation", description: "Teleconsultation"},
    {id: 6, value: 6, label: "Telepsychiatry", description: "Telepsychiatry"},
    {id: 7, value: 7, label: "Admin", description: "Admin"},
];
 
const filters = ref({
    page: 1,
    rows: 10,
    query: null,  
    intervention_type: null,
    order: 'asc',
    column: 'created_at'
});

const paginate = ( page:number, rows:number )=>{
    ReportService.paginate(page, rows,filters.value, getData)
}

const applyFilters = () => {
	showFilter.value = false;  
    paginate( 1, filters.value.rows);
};

const applyReset = () => {
    showFilter.value = false; 
    filters.value.intervention_type = null;
    paginate( 1, filters.value.rows);
};

const getData =  (): void =>{
    loading.value = true; 
    ReportService.index('work-load',omitBy(filters.value, isNil))
        .then(({data})=>{ 
            items.value = data.items; 
        })
        .catch((error: any)=>{
            console.log(error)
        })
        .finally(() => loading.value = false)
} 

const exporting = (): void => {
    loading.value = true;
    ReportService.export('work-load',omitBy(filters.value, isNil))
        .then(({data})=>{
             ReportService.downloadExport("work-load-export.csv",data);  
        })
        .finally(() => loading.value = false)
}

watch(
    () => filters.value.query,
    debounce(() => {
        paginate( 1, filters.value.rows);
        applyReset();
    }, 500)
);
 
onMounted(()=>{
    getData();
})

</script>