import http from "@/axios";
import { PrimaryDiagnosis } from "@/interfaces/Settings/PrimaryDiagnosis";

class PrimaryDiagnosisService implements PrimaryDiagnosis {
	async index(params: any = {}): Promise<any> {
		return await http.get("settings/primary-diagnosis", { params: params });
	}

	async delete(scopeBinding: any): Promise<any> {
		return await http.delete(`settings/primary-diagnosis/${scopeBinding.primaryDiagnosis}/delete`);
	}

	async restore(scopeBinding: any): Promise<any> {
		return await http.patch(`settings/primary-diagnosis/${scopeBinding.primaryDiagnosis}/restore`);
	}

	async edit(scopeBinding: any, params: any = {}): Promise<any> {
		return await http.get(`settings/primary-diagnosis/${scopeBinding.primaryDiagnosis}/edit`, { params: params });
	}

	async update(scopeBinding: any, params: any = {}): Promise<any> {
		return await http.put(`settings/primary-diagnosis/${scopeBinding.primaryDiagnosis}/update`, params);
	}

	async store(params: any = {}): Promise<any> {
		return await http.post(`settings/primary-diagnosis`, params);
	}
}

export default new PrimaryDiagnosisService();
