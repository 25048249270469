import { EnumsInterface } from "@/interfaces/EnumsInterface";

const name:EnumsInterface[] = [
    { id: 0, label: "Attention-Deficit", value: "attention_deficit" },
	{ id: 1, label: "Hyperactivity Disorder (ADHD)", value: "hyperactivity_disorder" },
	{ id: 2, label: "Down Syndrome", value: "down_syndrome" },
	{ id: 3, label: "Bipolar Disorder", value: "bipolar_disorder" },
	{ id: 4, label: "Long-term Recurring Depression", value: "long-term_recurring_depression" },
	{ id: 5, label: "Schizophrenia", value: "schizophrenia" },
]

export default name