import http from "@/axios";

class CityService {

	async index(payload?: object): Promise<any> {
		return await http.get("settings/cities", { params: payload });
	}

	async store(payload: object, ): Promise<any> {
		return await http.post(`settings/cities/store`, payload);
	}

	async edit(city: string, payload?: object): Promise<any> {
		return await http.get(`settings/cities/${city}/edit`, payload);
	}
	
	async update(city: string, payload?: object): Promise<any> {
		return await http.put(`settings/cities/${city}/update`, payload);
	}

	async delete(city: string, payload?: object): Promise<any> {
		return await http.delete(`settings/cities/${city}/delete`, payload);
	}

	async restore(city: string): Promise<any> {
		return await http.patch(`settings/cities/${city}/restore`);
	}
}
export default new CityService();
