<template>
	<user-layout hasBack backLink="/data-dictionary/index">
		<div class="md:max-w-[500px] w-full mx-auto py-6">
			<form-section :form="form" :isReadOnly="true" />
		</div>
	</user-layout>
</template>
<script setup lang="ts">
import UserLayout from "@/layouts/UserLayout.vue";
import FormSection from "./FormSection.vue";
import { computed, onMounted, ref } from "vue";
import DataDictionaryService from "@/classes/Settings/DataDictionaryService";
import { useRoute } from "vue-router";

import { prx } from '@/classes/Databases/Database'

import DB from "@/classes/Databases/Crud"
import { onIonViewWillEnter } from "@ionic/vue";
import { useNetworkStore } from "@/store/network";

const db = new DB('data_dictionaries');

const route = useRoute();
const networkStore = useNetworkStore();
const isNetworkAvailable = computed(() => networkStore.isNetworkAvailable);

/**
 * VARIABLES
 */
const form = ref<{ name: string; content: string }>({
	name: "",
	content: "",
});

onIonViewWillEnter(async () => {
    const response = await DataDictionaryService.show({
		dataDictionary: route.params.dataDictionary,
	}, {}, isNetworkAvailable.value);
    if (response?.status === 200 || response?.data != undefined) {
        const data = response.data;
        if (isNetworkAvailable.value) {
            const check = await db.edit(data.item.id, false, 'data_dictionaries');
            const offlineData = check.data.item;
            if (offlineData.length === undefined) {
                await db.delete({id: data.item.id}, 'data_dictionaries');
            }
            await db.insert({
				id:  data.item.id,
				name:  data.item.name,
				content:  data.item.content,
				encoded_by:  data.item.encoded_by,
				created_at:  data.item.created_at,
				updated_at:  data.item.updated_at,
				deleted_at:  data.item.deleted_at,
			}, false, false, 'data_dictionaries');
        }

        form.value.name = data.item.name;
        form.value.content = data.item.content;
    } else {
        console.log(response);
    }
});
</script>
@/classes/Databases/SqlLite@/classes/Databases/SqlLite