import http from "@/axios";

class Profile {
	
	async view(payload?: object): Promise<any> {
		return await http.get("profile/view", payload );
	}
	
	async edit(payload?: object): Promise<any> {
		return await http.post("profile/edit", payload );
	}

	async editPassword(payload?: object): Promise<any> {
		return await http.post("profile/editPassword", payload );
	}

	async editPins(payload?: object): Promise<any> {
		return await http.post("profile/editPin", payload );
	}
	
	async validatePassword(password: string): Promise<any> {
		return await http.post("profile/validatePassword", { current_password: password } );
	}
}
export default new Profile();
