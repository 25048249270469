<template>
    <user-layout>
        <div class="fixed lg:top-[72px] top-16 bg-white z-10 mb-6 shadow-[0_1px_0px_0px_rgba(0,0,0,0.08)] -mx-4 w-full">
            <a @click="router.back()">
                <button-component
                    customClass="tertiary text-black hover:text-primary-600 transition py-4"
                >
                    <!-- <img src="assets/icon/ic-arrow-left.svg" alt="back-arrow" class="object-contain w-6 h-6 mr-2"> -->
                    <arrow-left-icon class="object-contain w-6 h-6 mr-2" />
                    <span class="text-sm font-semibold">Back</span>
                </button-component>
            </a>
        </div>
        <div class="grid lg:grid-cols-[210px_auto] grid-cols-1 mt-16">
            <div class="lg:sticky top-8 col-span-1 lg:my-11 my-4">
                <div class="flex flex-col space-y-2">
                    <tab-component
                        :tabs="tabs"
                        typeTwo
                    />
                </div>
            </div>
            <div class="lg:my-11 lg:max-w-[950px] lg:pl-[60px]">
                <div v-if="route.query.tab != 'password' && route.query.tab != 'pin'">
                    <section-title 
                        title="Profile"
                        description="View the relevant data and other details"
                    />
                    <div class="py-6">
                        <profile-form-section/>
                    </div>
                </div>
                <div v-if="route.query.tab == 'password'">
                    <section-title 
                        title="Password"
                        description="View the relevant data and other details"
                    />
                    <div class="py-6">
                        <password-form-section/>
    
                    </div>
                </div>
                <div v-if="route.query.tab == 'pin'">
                    <section-title 
                        title="PIN"
                        description="View the relevant data and other details"
                    />
                    <div class="py-6">
                        <pin-form-section />
                    </div>
                </div>
            </div>
        </div>
    </user-layout>
</template>
<script setup lang="ts">
import { ref, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import UserLayout from '@/layouts/UserLayout.vue';
import SectionTitle from '@/components/partials/SectionTitle.vue';
import ButtonComponent from '@/components/buttons/ButtonComponent.vue';
import TabComponent from '@/components/partials/TabComponent.vue';
import PasswordInput from '@/components/inputs/PasswordInput.vue';

import ProfileFormSection from './profiles/FormSection.vue';
import PasswordFormSection from './passwords/FormSection.vue';
import PinFormSection from './pins/FormSection.vue';
import ConfirmationModal from '@/components/modals/ConfirmationModal.vue';
import SuccessModal from '@/components/modals/SuccessModal.vue';

import Profile from "@/classes/Profile";

import assignValues from "@/helpers/assignValues"
import ArrowLeftIcon from "@/components/icons/arrowLeftIcon.vue";

const router = useRouter();
const route = useRoute();

const showConfirmation = ref<boolean>(false);
const showSuccess = ref<boolean>(false);

const tabs: { name: string, count: number, href: string, allowed: boolean }[] = [
    {
        name: 'Profile',
        count: 0,
        href: "/profile",
        allowed: true
    },
    {
        name: 'Password',
        count: 0,
        href: "/profile?tab=password",
        allowed: true
    },
    {
        name: 'PIN',
        count: 0,
        href: "/profile?tab=pin",
        allowed: true
    }
];
</script>