<template>
	<user-layout
		hasBack
		:backLink="`/perpetrator/${perpetratorId}/view${offlineParams ? '?offline=true' : ''}`"
		hasBackClass="flex md:justify-between md:items-center md:flex-row flex-col"
	>
		<template #lowerRight>
			<div class="flex-col items-center space-y-3 md:flex-row md:space-x-3 md:space-y-0">
				<a @click="router.replace(`/perpetrator/${perpetratorId}/view${offlineParams ? '?offline=true' : ''}`)">
					<button-component
					btnWidth="quinary"
					customClass="quinary md:w-auto w-full md:mt-0 mt-6">
						Cancel
					</button-component>
				</a>
				<button-component
					form="form"
					@click="save()"
					customClass="primary md:w-auto w-full md:mt-0 mt-6"
				>
					<span>Save Changes</span>
				</button-component>
			</div>
		</template>
		<div class="w-full p-4 mx-auto my-6 border border-gray-200 rounded lg:p-6">
			<div class="flex items-center justify-between">
				<section-title title="Perpetrator Details" />
			</div>
			<form-section
				v-if="loaded"
				:form="form"
				:errors="errors"
				:genders="genders"
				:relationships="relationships"
				:nationalities="nationalities"
				:religions="religions"
				:currentLocations="currentLocations"
				:identities="identities"
				:item="item" 
			/>
		</div>
		<success-modal
			:show="showSuccess"
			@confirm="redirectSuccess()"
			action-text="Close"
			title="Record Updated!"
			:content="successMessage" />
	</user-layout>
</template>
<script setup lang="ts">
import { onMounted, ref, computed, watch } from "vue";
import UserLayout from "@/layouts/UserLayout.vue";
import SectionTitle from "@/components/partials/SectionTitle.vue";
import ButtonComponent from "@/components/buttons/ButtonComponent.vue";
import SuccessModal from "@/components/modals/SuccessModal.vue";
import FormSection from "@/views/patients/otherInformations/perpetrators/FormSection.vue";
import { useRouter, useRoute } from "vue-router";
import PerpetratorService from "@/classes/PerpetratorService";
import { renderSelect, renderSelectV2 } from "@/interfaces/DropdownInterface";
import { PerpetratorCreate } from "@/interfaces/Cases/Perpetrator";
import PerpetratorForm from "@/classes/Forms/Cases/PerpetratorForm";
import { errorResponse } from "@/helpers/errorMessage";
import { useNetworkStore } from "@/store/network";
import DB from "@/classes/Databases/Crud";
import pickBy from "lodash/pickBy";
import FormOptions from "@/classes/Forms/FormOptions";
import Gender from "@/enums/Gender";
import PerpetratorIdentity from "@/enums/PerpetratorIdentity";
import CurrentLocation from "@/enums/CurrentLocation";
const networkStore = useNetworkStore();
const isNetworkAvailable = computed(() => networkStore.isNetworkAvailable);
const db = new DB("perpetrators");
const router = useRouter();
const route = useRoute();
const showSuccess = ref<boolean>(false);
const perpetratorId = ref<number | null>(route.params.perpetrator);
const genders = ref<renderSelectV2[]>([]);
const identities = ref<renderSelectV2[]>([]);
const nationalities = ref<renderSelect[]>([]);
const religions = ref<renderSelect[]>([]);
const relationships = ref<renderSelect[]>([]);
const currentLocations = ref<renderSelect[]>([]);
const form = ref<PerpetratorCreate>(new PerpetratorForm());
const errors = ref<object>({});
const successMessage = ref<string>("Perpetrator has beed updated!");
const loaded = ref<boolean>(false);
const item = ref<PerpetratorCreate>(new PerpetratorForm());
const offlineParams = ref<string>(route.query.offline);
const options = new FormOptions();
import CasePerpetratorOfflineForm from "@/classes/Forms/Cases/CasePerpetratorOfflineForm";
import { onIonViewWillEnter } from "@ionic/vue";

const save = async (): Promise<any> => {
	errors.value = {};
	if (!isNetworkAvailable.value) {
		const validate = form.value.offlineCreateValidation(form.value);
		errors.value = validate?.errors;

		if (validate.hasOfflineError) {
			showSuccess.value = false;
			return;
		}
	}

    const response = await PerpetratorService.update(
		perpetratorId.value,
		{ ...form.value, },
		isNetworkAvailable.value
	);

    if (response?.status === 200 || response?.data != undefined) {
        await saveToOffline(response.data.data.item);
        showSuccess.value = true;
        successMessage.value = response.data.message;
    } else {
        const error = response;
        errors.value = errorResponse(error.response?.data?.errors);
    }

};

const saveToOffline = async (perpetrator: any) => {
    if (!isNetworkAvailable.value) {
        return;
    }

    const checkIfRecordIsEditedOffline = await db.select(`SELECT * FROM perpetrators WHERE id=${perpetrator.id} AND (is_created_offline=true OR is_updated_offline=true OR is_deleted_offline=true)`);
    
	if (checkIfRecordIsEditedOffline.length > 0) {
        return;
    }

    let form = new CasePerpetratorOfflineForm({}, {});
    form = await form.createPerpetratorForm(perpetrator);
    await db.insert(form, perpetrator.id, true, 'perpetrators');
}

const redirectSuccess = (): void => {
	showSuccess.value = false;
	router.replace(`/perpetrator/${perpetratorId.value}/view${offlineParams.value ? "?offline=true" : ""}`);
};

const getData = async (): Promise<any> => {
    const response = await PerpetratorService.edit(
		perpetratorId.value,
		pickBy({ }),
		isNetworkAvailable.value
	);

    if (response?.status === 200 || response?.data != undefined) {
        if (isNetworkAvailable.value) {
            genders.value = response.data.genders;
            identities.value = response.data.identities;
            nationalities.value = response.data.nationalities;
            religions.value = response.data.religions;
            relationships.value = response.data.perpetratorRelationships;
            currentLocations.value = response.data.currentLocations;
            form.value.updatePerpetrator(response.data.item);
            item.value = response.data.item;
        } else {
            genders.value = Gender;
            identities.value = PerpetratorIdentity;
            nationalities.value = await options.nationalities();
            religions.value = await options.religions();
            relationships.value = await options.perpetratorRelationships();
            currentLocations.value = CurrentLocation;

            const data = JSON.parse(response.data.item.content);
            form.value.updatePerpetrator(data);
            form.value.id = response.data.item.id;
            item.value = data;
        }

        loaded.value = true;
    } else {
        console.log(response);
    }
};

// watch(
// 	() => isNetworkAvailable.value,
// 	(val) => {
// 		console.log("Watcher - Network: " + val);
// 		getData();
// 	}
// );

onIonViewWillEnter(async () => {
    await getData();
});

// onMounted(async () => {
//     await getData();
// });
</script>
