<template>
	<div class="w-full">
		<form id="form">
			<div
				v-if="newRecord"
				class="grid grid-cols-6 gap-3 p-4 mt-6 border rounded md:gap-6 border-neutral-100"
			>
				<div class="col-span-6">
					<section-title fontSmall title="Patient Information" />
				</div>
				<div class="col-span-6 pb-3 border-t border-gray-200 md:pb-0"></div>
				<div class="col-span-6 sm:col-span-3">
					<date-picker
						id="registered_at"
						name="patient_registered"
						label="Date & Time Registered"
						required
						v-model="form.registered_at"
						:error="errors?.registered_at"
						:enableTimePicker="true"
						:timePickerInline="true"
						:maxDate="maxDate" 
						:max-time="{
							hours: maxDate().getHours(),
							minutes: maxDate().getMinutes(),
							seconds: maxDate().getSeconds()
						}"
					/>
				</div>
				<div class="col-span-6">
					<div class="grid grid-cols-2 gap-3 md:flex md:space-x-6">
						<text-input
							class="col-span-2 sm:col-span-1"
							type="text"
							label="Last Name"
							placeholder="Last name"
							name="last_name"
							id="last_name"
							required
							v-model="form.last_name"
							:error="errors?.last_name" />
						<text-input
							class="col-span-2 sm:col-span-1"
							type="text"
							label="First Name"
							placeholder="First name"
							name="first_name"
							id="first_name"
							required
							v-model="form.first_name"
							:error="errors?.first_name" />
						<text-input
							class="col-span-2 sm:col-span-1"
							type="text"
							label="Middle Name"
							placeholder="Middle name"
							name="middle_name"
							id="middle_name"
							v-model="form.middle_name"
							:error="errors?.middle_name" />
						<div class="shrink-0 sm:pt-[26px] pt-3 sm:col-span-1 col-span-2">
							<button-component
								size="large"
								@click="checkPatient()"
								:disabled="!form.last_name && !form.first_name"
								customClass="primary sm:w-auto w-full"
							>
								<span>Check</span>
								<!-- <img
									src="/assets/icon/ic-search-2.svg"
									alt="search_icon"
									class="object-contain w-5 h-5 ml-1 icon" /> -->
								<search-two-icon class="object-contain w-5 h-5 ml-1 icon" />
							</button-component>
						</div>
					</div>
				</div>
				<!-- <div class="col-span-6 sm:col-span-3">
					<text-input
						type="text"
						label="Nickname"
						placeholder="Nickname"
						name="nickname"
						id="nickname"
						v-model="form.nickname"
						:error="errors?.nickname" />
				</div> -->
				<div class="col-span-6 sm:col-span-3">
					<label 
						class="block text-xs font-semibold text-neutral-600"
					>
						Sex <span class="text-red-600">*</span>
					</label
					>
					<div class="flex items-center pt-2 space-x-6 sm:pt-5">
						<radio-group
							id="sex_at_birth"
							:options="biologicalGenderOptions"
							name="bioGender"
							required
							v-model="form.sex_at_birth"
							:error="errors?.sex_at_birth" 
						/>
					</div>
					<p
						v-if="errors?.sex_at_birth"
						class="text-[10px] mt-1"
						:class="errors?.sex_at_birth ? 'text-red-600' : 'text-gray-800'"
						id="email-error">
						{{ errors?.sex_at_birth }}
					</p>
				</div>
				<div class="col-span-6 sm:col-span-3">
					<label class="block text-xs font-semibold text-neutral-600">Gender</label>
					<div class="flex items-center pt-3 space-x-6 sm:pt-5">
						<radio-group
							:options="genderOptions"
							name="gender"
							id="gender"
							v-model="form.gender"
							:error="errors?.gender" 
						/>
					</div>
					<p
						v-if="errors?.gender"
						class="text-[10px] mt-1"
						:class="errors?.gender ? 'text-red-600' : 'text-gray-800'"
						id="email-error">
						{{ errors?.gender }}
					</p>
				</div>

				<UserBirthdateAge 
					:form="form"
					:errors="errors"
					customClass="sm:col-span-3 col-span-6"
				/>

				<div class="col-span-6 sm:col-span-3">
					<multi-select
						:options="pwdOptions"
						label="Is PWD?"
						id="pwd"
						name="pwd_status"
						placeholder="Select.."
						v-model="form.pwd"
						:error="errors?.pwd" 
						searchable
					/>
				</div>

				<div class="col-span-6 sm:col-span-3">
					<text-input
						type="text"
						placeholder="Specify" 
						id="pwd_specify"
						name="pwdMentalDisability"
						label="Specify"
						v-model="form.pwd_specify"
						:error="errors?.pwd_specify"
						:disabled="form.pwd == isNone || form.pwd == null"
					/> 
				</div>

				<div class="col-span-6 sm:col-span-3">
					<multi-select
						:options="indigeneousOptions"
						searchable
						id="indigeneous_people_id"
						name="ethnicity"
						label="Indigenous Group"
						placeholder="Select.."
						v-model="form.indigeneous_people_id"
						:error="errors?.indigeneous_people_id" 
					/>
				</div>
				<div class="col-span-6 sm:col-span-3">
					<text-input
						type="text"
						label="Specify"
						placeholder="Specify"
						name="ethnicity_others"
						id="other_indigeneous_people"
						:disabled="!isOtherIndigeneous" 
						v-model="form.other_indigeneous_people"
						:error="errors?.other_indigeneous_people" 
					/>
				</div>
			</div>

			<div v-if="newRecord" class="grid grid-cols-6 gap-3 p-4 mt-6 border rounded md:gap-6 border-neutral-100">
				<div class="col-span-6">
					<section-title fontSmall title="Case Details" />
				</div>
				<div class="col-span-6 pb-3 border-t border-gray-200 md:pb-0"></div>
				<div class="col-span-6 sm:col-span-3">
					<text-input
						type="text"
						label="Case Number"
						placeholder="Case Number(system generated)"
						name="case_number"
						id="case_number"
						:disabled="true"
						v-model="form.case_number" 
					/>
				</div>
				<div class="col-span-6 sm:col-span-3">
					<date-picker
						id="initiated_at"
						name="date_initiated"
						label="Date Initiated"
						v-model="form.initiated_at"
						:error="errors?.initiated_at"
						:maxDate="maxDate" 
					/>
				</div>
				<div class="col-span-6 sm:col-span-3">
					<div class="flex flex-col space-y-3 sm:flex-row sm:space-x-6 sm:space-y-0 space-between">
						<div class="w-full sm:py-6">
							<checkbox-input
								:checked="form.is_followup"
								id="is_followup"
								name="follow_up_case"
								label="Old Patient"
								v-model="form.is_followup"
								:error="errors?.is_followup" 
							/>
							<p
								v-if="errors?.is_followup"
								class="text-[10px] mt-1"
								:class="errors?.is_followup ? 'text-red-600' : 'text-gray-800'"
								id="email-error">
								{{ errors?.is_followup }}
							</p>
						</div>
						<div class="w-full">
							<label class="block text-xs font-semibold text-neutral-600">Reabuse?</label>
							<div class="flex items-center pt-3 space-x-6 sm:pt-5">
								<radio-group
									:options="reabuseOptions"
									name="reabuse"
									id="is_reabuse"
									v-model="form.is_reabuse"
									:error="errors?.is_reabuse" 
								/>
							</div>
							<p
								v-if="errors?.is_reabuse"
								class="text-[10px] mt-1"
								:class="errors?.is_reabuse ? 'text-red-600' : 'text-gray-800'"
								id="email-error">
								{{ errors?.is_reabuse }}
							</p>
						</div>
					</div>

				</div>
				<div class="col-span-6 sm:col-span-3">  
					<label class="block text-xs font-semibold text-neutral-600">
						Timing of Incident 
					</label> 
					<div class="flex items-center pt-3 space-x-6 sm:pt-5">
						<radio-group
							:options="timeIncidentOptions"
							name="timeIncident"
							id="incident_time"
							v-model="form.incident_time"
							:error="errors?.incident_time" 
							required
						/>
					</div>
					<p
						v-if="errors?.incident_time"
						class="text-[10px] mt-1"
						:class="errors?.incident_time ? 'text-red-600' : 'text-gray-800'"
						id="email-error">
						{{ errors?.incident_time }}
					</p>
				</div>
				<div class="block col-span-6 my-3 border-t border-gray-100 sm:hidden"></div>
				<div class="col-span-6 pb-3 sm:pb-0">
					<p class="font-bold">Diagnosis</p>
				</div>
				<div class="col-span-6 sm:col-span-3">
					<multi-select
						:options="primaryDiagnosisOptions"
						label="Primary Diagnosis"
						id="primary_diagnosis"
						name="primary_diagnosis"
						placeholder="Select.."
						searchable
						v-model="form.primary_diagnoses_id"
						:error="errors?.primary_diagnoses_id" 
					/>
				</div>
				<div class="col-span-6 sm:col-span-3">
					<text-input
						type="text"
						label="Other Diagnosis"
						placeholder="Other Diagnosis"
						name="other_diagnosis"
						id="other_diagnoses"
						v-model="form.other_diagnoses"
						:error="errors?.other_diagnoses" 
					/>
				</div>
				<div class="col-span-6 sm:col-span-3">
					<div class="flex flex-col space-y-3">
						<label class="block text-xs font-semibold sm:mb-5 text-neutral-600">Comorbid VAC/VAW </label>
						<template v-for="(comorbidity, index) in comorbiditiesOptions" :key="comorbidity">
							<checkbox-input
								:checked="form.comorbidities[index]['checked']"
								:id="'comorbidity' + index"
								:name="'comorbidity' + index"
								:label="comorbidity.value"
								@update:modelValue="(value) => updateFormComorbidity(index, value, comorbidity)" />
						</template>
					</div>
				</div>
				<div class="col-span-6 sm:col-span-3">
					<div class="flex flex-col space-y-3 sm:space-y-6">
						<div class="flex flex-col space-y-3">
							<label class="block mt-3 text-xs font-semibold sm:mb-5 sm:mt-0 text-neutral-600"
								>Mental Health Diagnosis
							</label>
							<template v-for="(mentalHealth, index) in mentalHealthOptions" :key="mentalHealth">
								<div>
									<checkbox-input
										:checked="inMetalHealth(mentalHealth.id)"
										:id="`mental_health.${getMetalHealthIndex(mentalHealth.id)}.specify` "
										:name="'mentalHealth' + index"
										:label="mentalHealth.label"
										@update:modelValue="
											(value) => updateMentalHealth(value, mentalHealth)
										" 
									/>
									<div
										class="mt-3"
										v-if="inMetalHealth(mentalHealth.id)"
									>
										<text-input
											:id="`mental_health.${getMetalHealthIndex(mentalHealth.id)}.specify`"
											type="text"
											placeholder="Specify"
											name="neurodevelopmental_disorders_specific"
											v-model="form.mental_health[getMetalHealthIndex(mentalHealth.id)]['specify']" 
											:error="errors[`mental_health.${getMetalHealthIndex(mentalHealth.id)}.specify`]"
										/>

										<template v-if="mentalHealth.id == hasRadioButtonId">
											<div class="flex items-center my-6 space-x-6">
												<radio-group
													:id="`mental_health.${getMetalHealthIndex(mentalHealth.id)}.type`"
													:options="conductDisorderOptions"
													name="conductDisorder"
													v-model="form.mental_health[getMetalHealthIndex(mentalHealth.id)]['type']"
												/>
											</div>

											<p
												v-if="errors[`mental_health.${getMetalHealthIndex(mentalHealth.id)}.type`]"
												class="text-[10px] mt-1 text-red-600"
												id="email-error"
											>
												{{ errors[`mental_health.${getMetalHealthIndex(mentalHealth.id)}.type`] }}
											</p>

											<multi-select
												:options="behaviorTypeOptions"
												:id="`mental_health.${getMetalHealthIndex(mentalHealth.id)}.behavior_type_id`"
												name="suicidalBehavior"
												placeholder="Select.."
												searchable
												v-model="form.mental_health[getMetalHealthIndex(mentalHealth.id)]['behavior_type_id']"
												:error="errors[`mental_health.${getMetalHealthIndex(mentalHealth.id)}.behavior_type_id`]"
											/>

										</template>
									</div>
								</div>
							</template>
						</div>
						<div class="col-span-6 sm:col-span-3">
							<multi-select
								:options="highSuicidalOptions"
								label="Suicidal Risk"
								id="high_suicidal_risk"
								name="suicidal_risk"
								placeholder="Select.."
								v-model="form.high_suicidal_risk"
								:error="errors?.high_suicidal_risk" 
								searchable
							/>
						</div>
						<div>
							<checkbox-input
								id="is_hama"
								name="hama"
								label="Home Against Medical Advice (HAMA)"
								:checked="form.is_hama"
								v-model="form.is_hama"
								:error="errors?.is_hama" 
							/>
						</div>
					</div>
				</div>
				<div class="block col-span-6 my-3 border-t border-gray-100 sm:hidden"></div>
				<div class="col-span-6 pb-3 sm:pb-0">
					<p class="font-bold">Referral & Services</p>
				</div>
				<div class="col-span-6 md:col-span-2 sm:col-span-3">
					<multi-select
						:options="referralSourceOptions"
						id="referral_source_id"
						name="referral_source"
						label="Referral Source"
						placeholder="Select.."
						searchable
						required
						v-model="form.referral_source_id"
						:error="errors?.referral_source_id"
					/>
				</div>
				<div class="col-span-6 md:col-span-2 sm:col-span-3">
					<text-input
						type="text"
						label="Specify"
						placeholder="Specify"
						name="referral_source_others"
						id="referral_source_id"
						v-model="form.referral_specify"
						:disabled="form.referral_source_id != referralSourceOptions.length"
					/>
				</div>
				<div class="col-span-6 md:col-span-2 sm:col-span-3">

					<multi-select
						:options="referralSourceOptions"
						id="second_referral_source_id"
						name="second_referral_source"
						label="Second Referral Source"
						placeholder="Select.."
						searchable
						v-model="form.second_referral_source_id"
						:error="errors?.second_referral_source_id"
					/>
				</div>
				<div class="col-span-6 md:col-span-2 sm:col-span-3">

					<multi-select
						:options="serviceSoughtOptions"
						id="sought_service_id"
						name="service_sought"
						label="Services Sought"
						placeholder="Select.."
						searchable
						required
						v-model="form.sought_service_id"
						:error="errors?.sought_service_id"
					/>

				</div>
				<div class="col-span-6 md:col-span-2 sm:col-span-3">

					<multi-select
						:options="socialWorkerOptions"
						id="intake_social_worker_id"
						name="intake_social_worker"
						label="Intake Social Worker"
						placeholder="Select.."
						searchable
						v-model="form.intake_social_worker_id"
						:error="errors?.intake_social_worker_id"
					/>

				</div>
				<div class="col-span-6 md:col-span-2 sm:col-span-3">
					<multi-select
						:options="socialWorkerOptions"
						id="social_worker_id"
						name="assigned_social_worker"
						label="Assigned Social Worker"
						placeholder="Select.."
						v-model="form.social_worker_id"
						:error="errors?.social_worker_id"
						searchable
					/>
				</div>
				<div class="col-span-6 md:col-span-2 sm:col-span-3">
					<text-input
						label="Assigned Medical Social Service (MSS)"
						placeholder="Assigned Medical Social Service (MSS)"
						name="assigned_mss"
						id="assigned_mss"
						v-model="form.assigned_mss"
						:error="errors?.assigned_mss" />
				</div>
				<div class="col-span-6 md:col-span-2 sm:col-span-3">
					<multi-select
						:options="assignedPhysicianOptions"
						id="physician_id"
						name="assigned_physician"
						label="Assigned Physician"
						placeholder="Select.."
						v-model="form.physician_id"
						:error="errors?.physician_id" 	
						searchable
					/>
				</div>
				<div class="col-span-6 md:col-span-2 sm:col-span-3">
					<multi-select
						:options="assignedPsychiatristOptions"
						id="mental_health_personnel_id"
						name="assigned_mental_health_personnel"
						label="Assigned Mental Health Personnel"
						placeholder="Select.."
						v-model="form.mental_health_personnel_id"
						:error="errors?.mental_health_personnel_id" 
						searchable	
					/>
				</div>
				<!-- <div class="col-span-6 md:col-span-2 sm:col-span-3">
					<select-options
						:options="mmsOptions"
						id="mms"
						name="mms"
						label="MSS"
						placeholder="Select.."
						required
						v-model="form.mms_id"
						:error="errors?.mms_id" />
				</div> -->
				<div v-if="!newRecord" class="col-span-6 md:col-span-2 sm:col-span-3">
					<multi-select
						:options="mmsOptions"
						id="mms_id"
						name="assigned_mms"
						label="Assigned MMS"
						placeholder="Select.."
						v-model="form.mms_id"
						:error="errors?.mms_id" 
						searchable
					/>
				</div>
				<div class="col-span-6 md:col-span-2 sm:col-span-3">
					<multi-select
						:options="traineeOptions"
						id="trainee_id"
						name="trainee"
						label="Trainee"
						placeholder="Select.."
						v-model="form.trainee_id"
						:error="errors?.trainee_id" 
						searchable
					/>
				</div>
				<div class="col-span-6 md:col-span-2 sm:col-span-3">
					<multi-select
						:options="endorsedToOptions"
						id="endorsed_to_id"
						name="endorsed_to"
						label="Endorsed to"
						placeholder="Select.." 
						v-model="form.endorsed_to_id"
						:error="errors?.endorsed_to_id" 
						searchable
					/>
				</div>
			</div>

			<div v-if="!newRecord" class="grid items-start grid-cols-6 p-4 mt-6 border rounded md:gap-6 border-neutral-100">
				<div class="col-span-6 mb-6 md:mb-0">
					<section-title title="Patient Details" />
				</div>
				<div class="grid grid-cols-3 col-span-6 md:col-span-3 gap-y-3">
					<div class="col-span-1 pb-2 border-b border-gray-100">
						<p class="font-medium">Date Registered</p>
					</div>
					<div class="col-span-2 pb-2 pl-3 border-b border-gray-100">
						<p>{{ additionalData?.patient?.registered_at_formatted || item?.registered_at_formatted }}</p>
					</div>
					<div class="col-span-1 pb-2 border-b border-gray-100">
						<p class="font-medium">Full Name</p>
					</div>
					<div class="col-span-2 pb-2 pl-3 border-b border-gray-100">
						<p>{{ item?.name }}</p>
					</div>
					<div class="col-span-1 pb-2 border-b border-gray-100">
						<p class="font-medium">Sex</p>
					</div>
					<div class="col-span-2 pb-2 pl-3 border-b border-gray-100">
						<p>
							{{
								additionalData?.patient?.sex_at_birth_meta?.description ||
								item?.sex_at_birth_meta?.description
							}}
						</p>
					</div>
					<div class="col-span-1 pb-2 border-b border-gray-100 md:pb-0 md:border-b-0">
						<p class="font-medium">Gender</p>
					</div>
					<div class="col-span-2 pb-2 pl-3 border-b border-gray-100 md:pb-0 md:border-b-0">
						<p>{{ item?.gender_meta?.description }}</p>
					</div>
				</div>
				<div class="grid grid-cols-3 col-span-6 mt-3 md:col-span-3 gap-y-3 md:mt-0">
					<div class="col-span-1 pb-2 border-b border-gray-100">
						<p class="font-medium">Birthdate</p>
					</div>
					<div class="col-span-2 pb-2 pl-3 border-b border-gray-100">
						<p>{{ item?.patient?.birthdate_formatted || item?.birthdate || "Unknown" }}</p>
					</div>
					<div class="col-span-1 pb-2 border-b border-gray-100">
						<p v-if="BirthdayClassification.APPROXIMATE_AGE == item?.birthday_classification"> Approximate Age </p>
						<p v-else> Age </p>
					</div>
					<div class="col-span-2 pb-2 pl-3 border-b border-gray-100">
						<p >{{ item?.computed_age }} &nbsp;</p>
					</div>
					<div class="col-span-1 pb-2 border-b border-gray-100">
						<p class="font-medium">Indigeneous People?</p>
					</div>
					<div class="col-span-2 pb-2 pl-3 border-b border-gray-100">
						<p>{{ item?.indigeneous?.name || 'None' }}</p>
					</div>
					<div class="col-span-1 pb-2 border-b border-gray-100">
						<p class="font-medium">PWD?</p>
					</div>
					<div class="col-span-2 pb-2 pl-3 border-b border-gray-100">
						<p>{{ item?.pwd_meta?.description }}</p>
					</div>
					<div v-if="item?.patient?.pwd_specify" class="col-span-1">
						<p class="font-medium">Specify</p>
					</div>
					<div class="col-span-2 pl-3">
						<p>{{ item?.patient?.pwd_specify }}</p>
					</div>
				</div>
			</div>

			<div v-if="!newRecord" class="grid items-start grid-cols-6 p-4 mt-6 border rounded md:gap-6 border-neutral-100">
				<div class="col-span-6 mb-6 md:mb-0">
					<section-title title="Case Details" />
				</div>
				<div class="grid grid-cols-3 col-span-6 md:col-span-3 gap-y-3">
					<div class="col-span-1 pb-2 border-b border-gray-100">
						<p class="font-medium">Case Number</p>
					</div>
					<div class="col-span-2 pb-2 pl-3 border-b border-gray-100">
						<p>{{ item?.case_number || "Offline(No Case Number)" }}</p>
					</div>
					<div class="col-span-1 pb-2 border-b border-gray-100">
						<p class="font-medium">Date Initiated</p>
					</div>
					<div class="col-span-2 pb-2 pl-3 border-b border-gray-100">
						<p>{{ item?.initiated_at_formatted }}</p>
					</div>
					<div class="col-span-1 pb-2 border-b border-gray-100 md:pb-0 md:border-b-0">
						<p class="font-medium">Reabused</p>
					</div>
					<div class="col-span-2 pb-2 pl-3 border-b border-gray-100 md:pb-0 md:border-b-0">
						<p>{{ item?.is_reabuse ? "Yes" : "No" }}</p>
					</div>
				</div>
				<div class="grid grid-cols-3 col-span-6 md:col-span-3 gap-y-3">
					<div class="col-span-1 pb-2 border-b border-gray-100">
						<p class="font-medium">Old Patient</p>
					</div>
					<div class="col-span-2 pb-2 pl-3 border-b border-gray-100">
						<p>{{ item?.is_followup ? "Yes" : "No" }}</p>
					</div>
					<div class="col-span-1 pb-2 border-b border-gray-100 md:pb-0 md:border-b-0">
						<p class="font-medium">Timing of Incident</p>
					</div>
					<div class="col-span-2 pb-2 pl-3 border-b border-gray-100 md:pb-0 md:border-b-0">
						<p>{{ item?.incident_time_meta?.description }}</p>
					</div>
				</div>
				<div class="col-span-6 my-6 md:my-0">
					<p class="font-bold">Diagnosis</p>
				</div>
				<div class="grid grid-cols-3 col-span-6 md:col-span-3 gap-y-3">
					<div class="col-span-1 pb-2 border-b border-gray-100">
						<p class="font-medium">Primary Diagnosis</p>
					</div>
					<div class="col-span-2 pb-2 pl-3 border-b border-gray-100">
						<p>{{ item?.diagnoses?.primary_diagnoses?.name }}</p>
					</div>
					<div class="col-span-1 pb-2 border-b border-gray-100">
						<p class="font-medium">Comorbid VAC/VAW</p>
					</div>
					<div class="col-span-2 pb-2 pl-3 border-b border-gray-100">
						<p>
							{{
								item?.comorbidities
									?.filter((item) => {
										if (item.id) {
											return item;
										}
									})
									?.map((item) => item?.name)
									?.toString()
							}}
						</p>
					</div>
					<div class="col-span-1 pb-2 border-b border-gray-100">
						<p class="font-medium">Other Diagnosis</p>
					</div>
					<div class="col-span-2 pb-2 pl-3 border-b border-gray-100"> 
						<p>{{ item?.diagnoses?.other_diagnoses }}</p>
					</div>
					<div class="col-span-1 pb-2 border-b border-gray-100">
						<p class="font-medium">Mental Health Diagnosis</p>
					</div>
					<div class="col-span-2 pb-2 pl-3 border-b border-gray-100">
						<p>{{ item?.mental_health_diagnoses?.map((item) => item?.mental_health?.name)?.toString() }}</p>
					</div>
					<div class="col-span-1 pb-2 border-b border-gray-100 md:pb-0 md:border-b-0">
						<p class="font-medium">Specify</p>
					</div>
					<div class="col-span-2 pb-2 pl-3 border-b border-gray-100 md:pb-0 md:border-b-0">
						<p>{{ item?.mental_health_diagnoses?.map((item) => item?.specify)?.toString() }}</p>
					</div>
				</div>
				<div class="grid grid-cols-3 col-span-6 mt-3 md:col-span-3 gap-y-3 md:mt-0">
					<div class="col-span-1 pb-2 border-b border-gray-100">
						<p class="font-medium">Suicidal Risk</p>
					</div>
					<div class="col-span-2 pb-2 pl-3 border-b border-gray-100">
						<p>{{ item?.diagnoses?.high_suicidal_risk_meta?.description }}</p>
					</div>
					<div class="col-span-1 pb-2 border-b border-gray-100">
						<p class="font-medium">Subcategory</p>
					</div>
					<div class="col-span-2 pb-2 pl-3 border-b border-gray-100">
						<p>
							{{
								item?.mental_health_diagnoses
									?.filter((item) => {
										if (typeof item.type_meta?.description == 'string') {
											return item;
										}
									})
									.map((item) => item?.type_meta?.description)
									.toString()
							}}
						</p>
					</div>
					<div class="col-span-1 pb-2 border-b border-gray-100">
						<p class="font-medium">Subcategory 2</p>
					</div>
					<div class="col-span-2 pb-2 pl-3 border-b border-gray-100">
						<p>
							{{
								item?.mental_health_diagnoses
									?.filter((item) => {
										if (typeof item?.behavior_type?.name == 'string') {
											return item;
										}
									})
									.map((item) => item?.behavior_type?.name)
									.toString()
							}}
						</p>
					</div>
					<div class="col-span-1 pb-2 md:pb-0">
						<p class="font-medium">HAMA</p>
					</div>
					<div class="col-span-2 pb-2 pl-3 md:pb-0">
						<p v-if="item?.diagnoses?.is_hama">
							{{ item?.diagnoses?.is_hama ? 'Yes' : 'No' }}
						</p>
						<p v-else>
							-
						</p>
					</div>
				</div>
				<div class="col-span-6 border-t border-gray-100"></div>
				<div class="col-span-6 my-6 md:my-0">
					<p class="font-bold">Referral & Services</p>
				</div>
				<div class="grid grid-cols-3 col-span-6 md:col-span-3 gap-y-3">
					<div class="col-span-1 pb-2 border-b border-gray-100">
						<p class="font-medium">Referral Source</p>
					</div>
					<div class="col-span-2 pb-2 pl-3 border-b border-gray-100">
						<p>{{ item?.referral_service?.referral_source?.name }}</p>
					</div>
					<div class="col-span-1 pb-2 border-b border-gray-100">
						<p class="font-medium">Specify</p>
					</div>
					<div class="col-span-2 pb-2 pl-3 border-b border-gray-100">
						<p>{{ item?.referral_service?.referral_specify ?? '-' }}</p>
					</div>
					<div class="col-span-1 pb-2 border-b border-gray-100">
						<p class="font-medium">Second Referral Source</p>
					</div>
					<div class="col-span-2 pb-2 pl-3 border-b border-gray-100">
						<p>{{ item?.referral_service?.second_referral_source?.name }}</p>
					</div>
					<div class="col-span-1 pb-2 border-b border-gray-100">
						<p class="font-medium">Services Sought</p>
					</div>
					<div class="col-span-2 pb-2 pl-3 border-b border-gray-100">
						<p>{{ item?.referral_service?.sought_service?.name }}</p>
					</div>
					<div class="col-span-1 pb-2 border-b border-gray-100">
						<p class="font-medium">Assigned Physician</p>
					</div>
					<div class="col-span-2 pb-2 pl-3 border-b border-gray-100">
						<p>{{ item?.referral_service?.physician?.name }}</p>
					</div>
					<div class="col-span-1 pb-2 border-b border-gray-100 md:pb-0 md:border-b-0">
						<p class="font-medium">Assigned Mental Health Personnel</p>
					</div>
					<div class="col-span-2 pb-2 pl-3 border-b border-gray-100 md:pb-0 md:border-b-0">
						<p>{{ item?.referral_service?.mental_health_personnel?.name }}</p>
					</div>
				</div>
				<div class="grid grid-cols-3 col-span-6 mt-3 md:col-span-3 gap-y-3 md:mt-0">
					<div class="col-span-1 pb-2 border-b border-gray-100">
						<p class="font-medium">Intake Social Worker</p>
					</div>
					<div class="col-span-2 pb-2 pl-3 border-b border-gray-100">
						<p>{{ item?.referral_service?.intake_social_worker?.name }}</p>
					</div>
					<div class="col-span-1 pb-2 border-b border-gray-100">
						<p class="font-medium">Assigned Social Worker</p>
					</div>
					<div class="col-span-2 pb-2 pl-3 border-b border-gray-100">
						<p>{{ item?.referral_service?.social_worker?.name }}</p>
					</div>
					<div class="col-span-1 pb-2 border-b border-gray-100">
						<p class="font-medium">Assigned Medical Social Service (MSS)</p>
					</div>
					<div class="col-span-2 pb-2 pl-3 border-b border-gray-100">
						<p>{{ item?.assigned_mss }}</p>
					</div>
					<div class="col-span-1 pb-2 border-b border-gray-100">
						<p class="font-medium">Trainee</p>
					</div>
					<div class="col-span-2 pb-2 pl-3 border-b border-gray-100">
						<p>{{ item?.referral_service?.trainee?.name }}</p>
					</div>
					<div class="col-span-1">
						<p class="font-medium">Endorsed to</p>
					</div>
					<div class="col-span-2 pl-3">
						<p>{{ item?.endorsed_to?.name || '' }}</p>
					</div>
					<!-- <div class="col-span-1">
						<p class="font-medium">MSS</p>
					</div>
					<div class="col-span-2">
						<p>{{ item?.referral_service?.mms?.name }}</p>
					</div> -->
				</div>
			</div>

			<div v-if="!newRecord" class="grid items-start grid-cols-6 p-4 mt-6 border rounded md:gap-6 border-neutral-100">
				<div class="col-span-6 mb-6 md:mb-0">
					<section-title title="Other Details" />
				</div>
				<div class="grid grid-cols-3 col-span-6 md:col-span-3 gap-y-3">
					<div class="col-span-1 pb-2 border-b border-gray-100">
						<p class="font-medium">Inpatient?</p>
					</div>
					<div class="col-span-2 pb-2 pl-3 border-b border-gray-100">
						<p>{{ item?.hospital_area ? "Yes" : "No" }}</p>
					</div>
					<div class="col-span-1 pb-2 border-b border-gray-100">
						<p class="font-medium">Hospital Area</p>
					</div>
					<div class="col-span-2 pb-2 pl-3 border-b border-gray-100">
						<p>{{ item?.hospital_area?.name }}</p>
					</div>
					<div class="col-span-1 pb-2 border-b border-gray-100">
						<p class="font-medium">Hospital Number</p>
					</div>
					<div class="col-span-2 pb-2 pl-3 border-b border-gray-100">
						<p>{{ item?.hospital_number }}</p>
					</div>
					<div class="col-span-1 pb-2 border-b border-gray-100">
						<p class="font-medium">If Applicable, indicate child is</p>
					</div>
					<div class="col-span-2 pb-2 pl-3 border-b border-gray-100">
						<p>{{ item?.child_type_meta?.description }}</p>
					</div>
					<div class="col-span-1 pb-2 border-b border-gray-100">
						<p class="font-medium">Educational Attainment</p>
					</div>
					<div class="col-span-2 pb-2 pl-3 border-b border-gray-100">
						<p>{{ item?.educational_attainment?.name }}</p>
					</div>
					<div class="col-span-1 pb-2 border-b border-gray-100">
						<p class="font-medium">Nationality</p>
					</div>
					<div class="col-span-2 pb-2 pl-3 border-b border-gray-100">
						<p>{{ item?.nationality?.name }}</p>
					</div>
					<div class="col-span-1 pb-2 border-b border-gray-100 md:pb-0 md:border-b-0">
						<p class="font-medium">Internally displaced?</p>
					</div>
					<div class="col-span-2 pb-2 pl-3 border-b border-gray-100 md:pb-0 md:border-b-0">
						<p v-if="item?.is_internally_displaced">
							{{ item?.is_internally_displaced ? "Yes" : "No" }}
						</p>
						<p v-else>
							-
						</p>
					</div>
				</div>
				<div class="grid grid-cols-3 col-span-6 mt-3 md:col-span-3 gap-y-3 md:mt-0">
					<div class="col-span-1 pb-2 border-b border-gray-100">
						<p class="font-medium">Civil Status</p>
					</div>
					<div class="col-span-2 pb-2 pl-3 border-b border-gray-100">
						<p>{{ item?.civil_status?.name }}</p>
					</div>
					<div class="col-span-1 pb-2 border-b border-gray-100">
						<p class="font-medium">Religion</p>
					</div>
					<div class="col-span-2 pb-2 pl-3 border-b border-gray-100">
						<p v-if="item?.religion?.name">{{ item?.religion?.name }}</p>
						<p v-else-if="item?.other_religion">{{ item?.other_religion }}</p>
						<p v-else>-</p>
					</div>
					<div class="col-span-1 pb-2 border-b border-gray-100">
						<p class="font-medium">Employment Status</p>
					</div>
					<div class="col-span-2 pb-2 pl-3 border-b border-gray-100">
						<p>{{ item?.employment_status?.name }}</p>
					</div>
					<div class="col-span-1 pb-2 border-b border-gray-100">
						<p class="font-medium">If Employed, Company/School Name</p>
					</div>
					<div class="col-span-2 pb-2 pl-3 border-b border-gray-100">
						<p>{{ item?.company_name }}</p>
					</div>
					<div class="col-span-1 pb-2 border-b border-gray-100">
						<p class="font-medium">Company/School Contact Number</p>
					</div>
					<div class="col-span-2 pb-2 pl-3 border-b border-gray-100">
						<p>{{ item?.company_contact_number }}</p>
					</div>
					<div class="col-span-1 pb-2 border-b border-gray-100">
						<p class="font-medium">Company/School Address</p>
					</div>
					<div class="col-span-2 pb-2 pl-3 border-b border-gray-100">
						<p>{{ item?.company_address }}</p>
					</div>
					<div class="col-span-1">
						<p class="font-medium">Remarks</p>
					</div>
					<div class="col-span-2 pl-3">
						<p>{{ item?.remarks?.map((item) => item?.remarks)?.toString() }}</p>
					</div>
				</div>
			</div>
			
			<div
				v-if="newRecord && !createNewPatient"
				class="grid grid-cols-6 gap-3 p-4 mt-6 border rounded md:gap-6 border-neutral-100"
			>
				<div class="col-span-6" id="other_details">
					<section-title fontSmall title="Other Details" />
				</div>
				<div class="col-span-6 lg:col-span-3">
					<label class="block text-xs font-semibold text-neutral-600">Inpatient?</label>
					<div class="flex items-center pt-3 space-x-6 sm:pt-5">
						<radio-group
							:options="inpatientOptions"
							name="inpatientStatus"
							id="is_inpatient"
							v-model="form.is_inpatient"
							:error="errors?.is_inpatient"
						/>
					</div>
					<div class="flex flex-col mt-3 space-y-3 sm:space-x-6 sm:space-y-0 sm:flex-row">
						<div class="w-full sm:w-1/2">
							<multi-select
								:options="hospitalAreaOptions"
								id="hospital_area_id"
								name="inpatient_status"
								placeholder="Select Hospital Area"
								v-model="form.hospital_area_id"
								:error="errors?.hospital_area_id"
								:disabled="!form.is_inpatient"
								searchable
							/>
						</div>
						<div class="w-full sm:w-1/2">
							<text-input
								type="text"
								placeholder="Hospital Number"
								name="company_name"
								id="hospital_number"
								v-model="form.hospital_number"
								:error="errors?.hospital_number"
								:disabled="!form.is_inpatient"
							/>
						</div>        
					</div>
				</div>
				<div class="self-end col-span-6 lg:col-span-3 sm:pb-3">
					<label class="block text-xs font-semibold text-neutral-600">Is Internally displaced?</label>
					<div class="flex items-center pt-3 space-x-6 sm:pt-5">
						<radio-group
							:options="internalDisplacedOptions"
							name="internalDisplaced"
							id="is_internally_displaced"
							v-model="form.is_internally_displaced"
							:error="errors?.is_internally_displaced" 
						/>
					</div>
				</div>
				<div class="col-span-6 sm:col-span-3">
					<multi-select
						:options="childTypeOptions"
						id="child_status"
						name="child_status"
						label="If Applicable, indicate child is"
						placeholder="Select.."
						v-model="form.child_type"
						:error="errors?.child_type" 
						searchable
					/>
				</div>
				<div class="col-span-6 sm:col-span-3">
					<multi-select
						:options="civilStatusOptions"
						id="civil_status_id"
						name="civil_status"
						label="Civil Status"
						placeholder="Select.."
						v-model="form.civil_status_id"
						:error="errors?.civil_status_id" 
						searchable
					/>
				</div>
				<div class="col-span-6 sm:col-span-3">
					<multi-select
						:options="nationalityOptions"
						id="nationality_id"
						name="nationality"
						label="Nationality"
						placeholder="Select.."
						v-model="form.nationality_id"
						:error="errors?.nationality_id" 
						searchable
					/>
				</div>
				<div class="col-span-6 sm:col-span-3">
					<text-input
						type="text"
						label="Other nationality"
						placeholder="Other nationality"
						name="other_ethnicity"
						id="other_nationality"
						v-model="form.other_nationality"
						:disabled="form.nationality_id == 'others' ? false : true"
						:error="errors?.other_nationality"
					/>
				</div>
				<div class="col-span-6 sm:col-span-3">
					<multi-select
						:options="religionOptions"
						id="religion"
						name="religion"
						label="Religion"
						placeholder="Select.."
						v-model="form.religion_id"
						:error="errors?.religion_id" 
						searchable
					/>
				</div>
				<div class="col-span-6 sm:col-span-3">
					<text-input
						type="text"
						label="Other religion"
						placeholder="Other religion"
						name="other_religion"
						id="other_religion"
						:disabled="form.religion_id == 'others' ? false : true"
						v-model="form.other_religion"
						:error="errors?.other_religion"
					/>
				</div>
				<div class="col-span-6 sm:col-span-3">
					<multi-select
						:options="educationAttainmentOptions"
						id="educational_attainment_id"
						name="educational_attainment"
						label="Educational Attainment"
						placeholder="Select.."
						v-model="form.educational_attainment_id"
						:error="errors?.educational_attainment_id" 
						searchable
					/>
				</div>
				<div class="col-span-6 sm:col-span-3">
					<multi-select
						:options="employmentStatusOptions"
						id="employment_status_id"
						name="employment_status"
						label="Employment Status"
						placeholder="Select.."
						v-model="form.employment_status_id"
						:error="errors?.employment_status_id" 
						searchable
					/>
				</div>
				<div class="col-span-6 sm:col-span-3">
					<text-input
						type="text"
						label="Company/School Name"
						placeholder="Company/School name"
						name="company_name"
						id="company_name"
						v-model="form.company_name"
						:error="errors?.company_name" />
				</div>
				<div class="col-span-6 sm:col-span-3">
					<text-input
						type="tel"
						label="Company/School Contact Number"
						placeholder="Company/School contact number"
						name="company_contact_number"
						id="company_contact_number"
						:add_on_left="true"
						add_on_text="+63"
						:maxlength="10"
						v-model="form.company_contact_number"
						:error="errors.company_contact_number"
						@keypress="numberonly"
					/>
				</div>
				<div class="col-span-6">
					<text-input
						textarea
						label="Company/School Address"
						placeholder="Company/School address"
						name="company_address"
						id="company_address"
						v-model="form.company_address"
						:error="errors?.company_address" />
				</div>
				<div class="col-span-6">
					<label class="block mb-2 text-xs font-semibold text-neutral-600">Remarks</label>
					<div class="flex flex-col mb-6 space-y-3">
						<div class="relative" v-for="(remark, index) in form.remarks" :key="remark">
							<text-input
								textarea
								placeholder="Remarks"
								name="remarks"
								id="remarks"
								v-model="form.remarks[index]['remarks']" 
							/>
							<button
								@click="removeRemarks(index)"
								type="button"
								class="absolute top-0 right-0 z-10 p-1 cursor-pointer"
								title="Delete this remark">
								<TrashIcon class="w-5 h-5 text-red-600 hover:text-red-700" />
							</button>
						</div>
					</div>

					<button-component customClass="secondary md:w-auto w-full" @click="addRemarks()">
						<PlusIcon class="w-4 h-4 text-primary-600 stroke-[3px] -ml-2 mr-1 icon" />
						<span>Add Remarks</span>
					</button-component>
				</div>
			</div>
			
		</form>
		<slot></slot>
		<check-modal
			:show="showCheck"
			@confirm="showCheck = false"
			action-text="Cancel"
			:title=" items.length > 0 ? 'Name Matched!' : 'No Record Found'"
			description="Relevant data and other details">
			<template #content>
				<div>
					<data-table :headers="headers" :no-action="false" :count="items.length">
						<template #body>
							<template v-for="item in items" :key="item">
								<tr>
									<td class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
										{{ item.first_name }} {{ item.last_name }}
									</td>
									<td class="px-6 py-6 text-sm text-center text-gray-900 whitespace-nowrap">
										{{ item.latest_case_number }}
									</td>
									<td class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
										<div class="flex items-center justify-center mx-auto">
											<go-to-button :routeLink="`/patient/${item.patient_id || item.offline_id}/view`"></go-to-button>
										</div>
									</td>
								</tr>
							</template>
						</template>
					</data-table>
				</div>
			</template>
		</check-modal>
	</div>
</template>
<script setup lang="ts">
import { ref, computed, onMounted, type PropType, watch} from "vue";
import SectionTitle from "@/components/partials/SectionTitle.vue";
import TextInput from "@/components/inputs/TextInput.vue";
import CheckboxInput from "@/components/inputs/CheckboxInput.vue";
import RadioGroup from "@/components/inputs/RadioGroup.vue";
import DatePicker from "@/components/inputs/DatePicker.vue";
import ButtonComponent from "@/components/buttons/ButtonComponent.vue";
import CheckModal from "./Components/CheckModal.vue";
import { PlusIcon, TrashIcon } from "@heroicons/vue/24/outline";
import DataTable from "@/components/partials/DataTable.vue";
import GoToButton from "@/components/buttons/GoToButton.vue";
import { renderSelect, renderSelectV2 } from "@/interfaces/DropdownInterface";
import { useRoute } from "vue-router";
import MultiSelect from "@/components/inputs/MultiSelect.vue";
import PatientService from "@/classes/PatientService";
import { useNetworkStore } from "@/store/network";
import { CreatePatient } from "@/interfaces/PatientInterface";

import UserBirthdateAge from '@/components/forms/UserBirthdateAge.vue'

import { BirthdayClassification } from "@/views/enums/BirthdayClassification";
import { numberonly } from "@/components/composables/useNumberOnly";
import SearchTwoIcon from "@/components/icons/searchTwoIcon.vue";

const networkStore = useNetworkStore();
const isNetworkAvailable = computed(() => networkStore.isNetworkAvailable);
/**
 * VARIABLES
 */
interface itemsInterface {
	id: number;
	last_name: string;
	first_name: string;
	middle_name: string;
	nickname: string;
	birthdate: string;
	created_at_formatted: string;
	abbr: string;
	gender_meta: object;
}

const route = useRoute();
const props = defineProps({
	newRecord: {
		type: Boolean,
		default: true,
	},
	form: {
		type: Object as PropType<CreatePatient>,
		required: true,
	},
	additionalData: {
		type: Object,
		required: true,
	},
	errors: {
		type: Object,
		required: true,
	},
	item: {
		type: Object,
	},
});

const form = ref<CreatePatient>(props.form);
const showCheck = ref<boolean>(false);
const createNewPatient = ref<boolean>(route.params.patient ? false : true);
const headers: { text: string }[] = [{ text: "Patient Name" }, { text: "Latest Case No." }];


const items = ref<itemsInterface[]>([]);
const biologicalGenderOptions = computed<renderSelectV2[]>(() => props.additionalData?.sexAtBirth || []);
const genderOptions = computed<renderSelectV2[]>(() => props.additionalData?.genders || []);
const reabuseOptions = [
	{ id: "1", label: "Yes", value: 1 },
	{ id: "0", label: "No", value: 0 },
];
const timeIncidentOptions = computed<renderSelectV2[]>(() => props.additionalData?.incidentTimes || []);
const primaryDiagnosisOptions = computed<renderSelect[]>(() => props.additionalData?.primaryDiagnoses || []);
const conductDisorderOptions = computed<renderSelectV2[]>(() => props.additionalData?.behaviors || []);
const highSuicidalOptions = computed<renderSelect[]>(() => props.additionalData?.highSuicidalRisks || []);
const endorsedToOptions = computed<renderSelect[]>(() => props.additionalData?.endorsedToOptions || []);


const inpatientOptions = [
	{ id: "1", label: "Yes", value: 1 },
	{ id: "0", label: "No", value: 0 },
];

const internalDisplacedOptions = [
	{ id: "1", label: "Yes", value: 1 },
	{ id: "0", label: "No", value: 0 },
];
const pwdOptions = computed<renderSelect[]>(() => props.additionalData?.pwds || []);
const pwdSpecificOptions = computed<renderSelect[]>(() => props.additionalData?.pwdSpecific || []);
const referralSourceOptions = computed<renderSelect[]>(() => props.additionalData?.referralSources || []);
const serviceSoughtOptions = computed<renderSelect[]>(() => props.additionalData?.soughtServices || []);
const socialWorkerOptions = computed<renderSelect[]>(() => props.additionalData?.socialWorkers || []);
const assignedPhysicianOptions = computed<renderSelect[]>(() => props.additionalData?.physicians || []);
const assignedPsychiatristOptions = computed<renderSelect[]>(() => props.additionalData?.psychiatrists || []);
const mmsOptions = computed<renderSelect[]>(() => props.additionalData?.mms || []);
const traineeOptions = computed<renderSelect[]>(() => props.additionalData?.trainees || []);
const comorbiditiesOptions = computed<renderSelect[]>(() => props.additionalData?.comorbidities || []);
const mentalHealthOptions = computed<renderSelect[]>(() => props.additionalData?.mentalHealthDiagnoses || []);
const behaviorTypeOptions = computed<renderSelect[]>(
	() =>
		props.additionalData?.behaviorTypes.filter((item) => {
			if (item.type == getSelectedBehavior.value) {
				return item;
			}
		}) || []
);
const civilStatusOptions = computed<renderSelect[]>(() => props.additionalData?.civilStatuses || []);
const nationalityOptions = computed<renderSelect[]>(() => props.additionalData?.nationalities || []);
const religionOptions = computed<renderSelect[]>(() => props.additionalData?.religions || []);
const childTypeOptions = computed<renderSelect[]>(() => props.additionalData?.childTypes || []);
const hospitalAreaOptions = computed<renderSelect[]>(() => props.additionalData?.hospitalAreas || []);
const educationAttainmentOptions = computed<renderSelect[]>(() => props.additionalData?.educationalAttainments || []);
const employmentStatusOptions = computed<renderSelect[]>(() => props.additionalData?.employmentStatuses || []);
const indigeneousOptions = computed<renderSelect[]>(() => props.additionalData?.indigeneous || []);
const hasRadioButtonId = computed<number>(() => props.additionalData?.mentalHealthStaticRadioButtonId);
const getSelectedBehavior = computed<number | null>(
	() =>
		form.value.mental_health.filter((item) => {
			if (item.type?.toString()) {
				return item;
			}
		})[0]?.type
);

const isNone = ref(7) // if Is PWD = None
const isPwd = ref<boolean>(false) // if Is PWD = None
const isOtherIndigeneous = ref<boolean>(false) // if Is PWD = None

const updateFormComorbidity = (index: number, isChecked: boolean, data: object): void => {
	if (isChecked) {
		form.value.comorbidities[index]["comorbidity_id"] = data.id;
	}
	if (!isChecked) {
		form.value.comorbidities[index] = {
			comorbidity_id: null,
			checked: false,
		};
	}
};


const inMetalHealth = (id:number):boolean =>{
	const index = getMetalHealthIndex(id)
	return index > -1;
}

const updateMentalHealth = (isChecked: boolean, data: object): void => {
	const index = getMetalHealthIndex(data.id)

	if (index == -1) {
		form.value.mental_health.push({
			'specify': '',
			'type': '',
			'behavior_type_id': '',
			'mental_health_diagnoses_id': data.id,
		});
	} else {
		form.value.mental_health.splice(index, 1);
	}
};

const getMetalHealthIndex = (id:number):number => {
	return form.value.mental_health.map((item) => item?.mental_health_diagnoses_id).indexOf(id);
}

const addRemarks = () => {
	form.value.remarks.push({ remarks: null });
};

const removeRemarks = (index: number) => {
	form.value.remarks = form.value.remarks.slice(0, index);
};

const maxDate = () => {
	return new Date();
};

const checkPatient = () => {
	PatientService.patients(
		{
			last_name: form.value.last_name,
			first_name: form.value.first_name,
			birthdate: form.value.birthdate,
			mode: 'patient',
			rows: 20,
		},
		isNetworkAvailable.value
	)
		.then((response: any) => {
			showCheck.value = true;
			if (isNetworkAvailable.value) {
				items.value = response.data.items.data;
			} else {
				items.value = response.data.items.data.map((item) => {
					return {
						...JSON.parse(item.content),
						...{
							offline_data: {
								id: item.id,
								offline_id: item.offline_id,
							},
						},
					};
				});
			}
		})
		.catch((error) => {
			showCheck.value = false;
			console.log(error);
		});
};

const scrollToOtherDetails = ():void => {
	if(route?.query?.other_details){
		const element = document.getElementById('other_details')
			
		element.scrollIntoView({
			behavior: "smooth",
			inline: "start",
		});
	}
}

const enablePWDSpecify = () => {
    if (form.value.pwd && form.value.pwd != isNone.value) {
        isPwd.value = true;
    } else {
        isPwd.value = false;
    }
}


watch(
	() => form.value.pwd,
	(val) => { 
		if(val != isNone.value){
			isPwd.value = true; 
		} else {
			isPwd.value = false;
			form.value.pwd_specify = null
		} 
	}
); 

watch(
	() => form.value.indigeneous_people_id,
	(val: any) => {
		if(val != 'others' || val == 'None'){
			isOtherIndigeneous.value = false;
			form.value.other_indigeneous_people = null
		} else {
			isOtherIndigeneous.value = true;
		}
	}
); 
 
onMounted(() => {
	if( route.params.case && form.value.religion_id == false && form.value.other_religion){
		form.value.religion_id = 'others'
	}

    if (form.value.indigeneous_people_id == 'others') {
        isOtherIndigeneous.value = true;
    }
    enablePWDSpecify();
	scrollToOtherDetails();  

})
</script>
