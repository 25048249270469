import OnlineService from "./Classes/OnlineService";
import OfflineService from "./Classes/OfflineService";

const online = OnlineService;
const offline = OfflineService;

class InterventionService {

	async getFormStructure(): Promise<any> {
		return await offline.getFormStructure();
	}

	async patientSearch(payload: object, networkAvailable = true): Promise<any> {
		if(networkAvailable){
			return await online.patientSearch(payload)
		} else {
			return await offline.patientSearch(payload)
		}
	}

	async index(patient: number, case_id: number|null = null, payload: object, networkAvailable = true): Promise<any> {
		if(networkAvailable){
			return await online.index( patient, case_id, payload )
		} else {
			return await offline.index( patient, case_id, payload )
		}
	}

	async create(patient: number, form: number, case_id: number|null = null, networkAvailable:boolean,  payload: object|null = null): Promise<any> {
		if(networkAvailable){
			return await online.create( patient,form,case_id,payload )
		} else {
			return await offline.create( patient,form,case_id,payload )
		}
	}

	async store(patient: number, form: number, payload: object,networkAvailable:boolean ): Promise<any> {
		if(networkAvailable){
			return await online.store( patient,form, payload)
		} else {
			return await offline.store( patient,form, payload)
		}
	}

	async edit(interventionType:number, intervention:number, patient: number, networkAvailable:boolean, payload: object|null = null): Promise<any> {
		if(networkAvailable){
			return await online.edit(interventionType, intervention, patient, payload)
		} else {
			return await offline.edit(interventionType, intervention, patient, payload)
		}	
	}
	
	async update(intervention:number, patient: number, payload: object, networkAvailable:boolean): Promise<any> {

		if(networkAvailable){
			return await online.update(intervention, patient, payload)
		} else {
			return await offline.update(intervention, patient, payload)
		}	
	}

	public async generatePDFAccess(intervention: number|string, networkAvailable:boolean): Promise<JSON|null> {
		if(networkAvailable){
			return await online.generatePDFAccess(intervention)
		}

		return null;
	}

    public async downloadCourtHearing(intervention:number|string, token:string, networkAvailable:boolean): Promise<void> {		
		if(networkAvailable){
			return await online.downloadCourtHearing(intervention, token)
		}
	}

	async storeOffline(items: object): Promise<void> {
		await offline.storeOffline(items)
	}
}
export default new InterventionService();
