import { CompanionCreate } from "@/interfaces/Cases/Companion";
import Relationships from "../Relationships";
const relationship = new Relationships()
export default class CompanionForm implements CompanionCreate {
	first_name: string | null;
	middle_name: string | null;
	last_name: string | null;
	gender: number | null;
	birthday_classification: number | null;
	birthdate: string | null;
	approximate_age_year: number | null;
	approximate_age_month: number | null;
	companion_relationship_id: number | null;
	other_relationship: string | null;
	contacts: string | null;
	constructor(item: CompanionCreate) {
		this.first_name = item?.first_name;
		this.middle_name = item?.middle_name;
		this.last_name = item?.last_name;
		this.gender = item?.gender;
		this.birthday_classification = item?.birthday_classification;
		this.birthdate = item?.birthdate == "0000-00-00" ? "" : item?.birthdate;
		this.approximate_age_year = item?.approximate_age_year;
		this.approximate_age_month = item?.approximate_age_month;
		this.companion_relationship_id = item?.companion_relationship_id;
		this.other_relationship = item?.other_relationship;
		this.contacts = item?.contacts;
	}

	updateCompanion(item: CompanionCreate) {
		this.first_name = item?.first_name;
		this.middle_name = item?.middle_name;
		this.last_name = item?.last_name;
		this.gender = item?.gender;
		this.birthday_classification = item?.birthday_classification;
		this.birthdate = item?.birthdate == "0000-00-00" ? "" : item?.birthdate;
		this.approximate_age_year = item?.approximate_age_year;
		this.approximate_age_month = item?.approximate_age_month;
		this.companion_relationship_id = item?.companion_relationship_id;
		this.other_relationship = item?.other_relationship;
		this.contacts = item?.contacts;
	}

	offlineCreateValidation(form: any) {
		const errors = <any>{};
		let hasOfflineError = false;

		if (!form.first_name) {
			hasOfflineError = true;
			Object.assign(errors, { first_name: "The first name field is required." });
		}

		if (!form.last_name) {
			hasOfflineError = true;
			Object.assign(errors, { last_name: "The last name field is required." });
		}

		if (form.gender == undefined) {
			hasOfflineError = true;
			Object.assign(errors, { gender: "The gender field is required." });
		}

		if (form.gender) {
            const genderExists: any = relationship.gender({gender: form.gender});
            if (!genderExists?.description) {
                hasOfflineError = true;
                Object.assign(errors, { gender: "The gender field is does not exists." });
            }
        }

		if (!form.companion_relationship_id) {
			hasOfflineError = true;
			Object.assign(errors, { companion_relationship_id: "The guardian relationship field is required." });
		}

		if (!form.birthdate && !form.approximate_age_month) {
			hasOfflineError = true;
			Object.assign(errors, { approximate_age_month: "The approximate age month field is required when birthdate is not present." });
		}

		if (!form.birthdate && !form.approximate_age_year) {
			hasOfflineError = true;
			Object.assign(errors, { approximate_age_year: "The approximate age year field is required when birthdate is not present." });
		}

		if (!form.approximate_age_month && !form.birthdate) {
			hasOfflineError = true;
			Object.assign(errors, { birthdate: "The birthdate field is required when approximate age month is not present." });
		}

		if (!form.approximate_age_year && !form.birthdate) {
			hasOfflineError = true;
			Object.assign(errors, { birthdate: "The birthdate field is required when approximate age year is not present." });
		}

        if (form.contacts && form.contacts.length < 10) {
            hasOfflineError = true;
            Object.assign(errors, { contacts: "The contacts field must have at least 10 digits." });
        }

		return {
			hasOfflineError: hasOfflineError,
			errors: errors,
		};
	}
}
