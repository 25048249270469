<template>
	<form id="form" class="grid grid-cols-6 gap-6 mt-6">
		<div class="col-span-6 border-t border-gray-200"></div>

		<div class="lg:col-span-2 md:col-span-3 col-span-6">
			<date-picker
				id="patient_registered"
				name="patient_registered"
				label="Date Registered"
				required
				:model-value="form?.registered_at"
				@update:modelValue="(value) => (form.registered_at = value)"
				:error="errors?.registered_at"
				:maxDate="maxDate" 
				:disabled="disabled"
			/>
		</div>
		<div class="lg:col-span-2 md:col-span-3 col-span-6">
			<label class="block text-xs font-semibold text-neutral-600"
				>Sex Assigned at Birth <span class="text-red-600">*</span></label
			>
			<div class="flex items-center pt-5 space-x-6">
				<radio-group 
					:options="sexAtBirth"
					name="gender" 
					required 
					v-model="form.sex_at_birth" 
					:disabled="disabled"
				/>
				<p
					v-if="errors?.sex_at_birth"
					class="text-[10px] mt-1"
					:class="errors?.sex_at_birth ? 'text-red-600' : 'text-gray-800'"
					id="email-error">
					{{ errors?.sex_at_birth }}
				</p>
			</div>
		</div>
		<div class="lg:col-span-2 md:col-span-3 col-span-6">
			<multi-select
				:options="indigeneous"
				id="ethnicity"
				name="ethnicity"
				label="Indigenous Group"
				placeholder="Select.."
				searchable
				v-model="form.indigeneous_people_id"
				:error="errors?.indigeneous_people_id" 
				:disabled="disabled"
			/>
		</div>
		<div class="lg:col-span-2 md:col-span-3 col-span-6">
			<text-input
				type="text"
				label="Last Name"
				placeholder="Last name"
				name="last_name"
				id="last_name"
				required
				v-model="form.last_name"
				:error="errors?.last_name" 
				:disabled="disabled"
			/>
		</div>
		<div class="lg:col-span-2 md:col-span-3 col-span-6">
			<text-input
				type="text"
				label="First Name"
				placeholder="First name"
				name="first_name"
				id="first_name"
				required
				v-model="form.first_name"
				:error="errors?.first_name" 
				:disabled="disabled"
			/>
		</div>
		<div class="lg:col-span-2 md:col-span-3 col-span-6">
			<text-input
				type="text"
				label="Middle Name"
				placeholder="Middle name"
				name="middle_name"
				id="middle_name"
				v-model="form.middle_name"
				:error="errors?.middle_name" 
				:disabled="disabled"
			/>
		</div>
		<!-- <UserBirthdateAge 
			:form="form"
			:errors="errors"
			disabled
		/> -->
		<div class="lg:col-span-2 md:col-span-3 col-span-6">
			<multi-select searchable
				:options="civilStatuses"
				id="civil_status"
				name="civil_status"
				label="Civil Status"
				placeholder="Select.."
				required
				v-model="form.civil_status_id"
				:error="errors?.civil_status_id" 
				:disabled="disabled"
			/>
		</div>
	</form>
</template>
<script setup lang="ts">
import TextInput from "@/components/inputs/TextInput.vue";
import RadioGroup from "@/components/inputs/RadioGroup.vue";
import DatePicker from "@/components/inputs/DatePicker.vue";
import { renderSelectV2, renderSelect } from "@/interfaces/DropdownInterface";
import { type PropType, computed } from "vue";
import { ref } from "vue";
import { EditPatient } from "@/interfaces/PatientInterface";
import MultiSelect from "@/components/inputs/MultiSelect.vue";

import UserBirthdateAge from '@/components/forms/UserBirthdateAge.vue'

const props = defineProps({
	form: {
		type: Object as PropType<EditPatient>,
		required: true,
	},
	civilStatuses: {
		type: [] as PropType<renderSelect>,
		default: [],
	},
	nationalities: {
		type: [] as PropType<renderSelect>,
		default: [],
	},
	religions: {
		type: [] as PropType<renderSelect>,
		default: [],
	},
	sexAtBirth: {
		type: [] as PropType<renderSelectV2>,
		default: [],
	},
	indigeneous: {
		type: [] as PropType<renderSelect>,
		default: [],
	},
	errors: {
		type: Object,
		required: true,
	},
	disabled: {
		type: Boolean,
		required: false,
	},
});

const form = ref<object>(props.form);
const maxDate = () => {
	return new Date().toISOString().split("T")[0];
};
</script>
