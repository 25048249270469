<template>
	<form id="form" class="grid grid-cols-6 gap-6 mt-6">
		<div class="col-span-6 border-t border-gray-200"></div>

		<div class="col-span-6">
			<label class="block text-xs font-semibold text-neutral-600"
				>Residence Type <span class="text-red-600">*</span></label
			>
			<div class="flex flex-wrap items-center md:pt-5 pt-2 gap-x-6 gap-y-3">
				<radio-group
					:options="residenceTypes"
					name="residence_type"
					required
					v-model="form.residence_type"
					:error="errors?.residence_type" />
			</div>
			<p
				v-if="errors?.residence_type"
				class="text-[10px] mt-1"
				:class="errors?.residence_type ? 'text-red-600' : 'text-gray-800'"
				id="email-error">
				{{ errors?.residence_type }}
			</p>
		</div>
		<div class="col-span-6">
			<text-input
				type="text"
				label="Address"
				placeholder="Address"
				name="address"
				id="address"
				required
				v-model="form.address"
				:error="errors?.address" />
		</div>
		<div class="md:col-span-3 col-span-6">
			<multi-select
				:options="regions"
				id="region"
				name="region"
				label="Region"
				placeholder="Select.."
				searchable
				required
				v-model="form.region_id"
				:error="errors?.region_id" 
				@update:modelValue="regionChange()"
			/>
		</div>
		<div class="md:col-span-3 col-span-6">
			<multi-select
				:options="provinces"
				id="province"
				name="province"
				label="Province"
				placeholder="Select.."
				searchable
				required
				v-model="form.province_id"
				:error="errors?.province_id"
				@update:modelValue="provinceChange()" 
			/>
		</div>
		<div class="md:col-span-3 col-span-6">
			<multi-select
				:options="cities"
				id="city"
				name="city"
				label="City/Municipality/District"
				placeholder="Select.."
				searchable
				required
				v-model="form.city_id"
				:error="errors?.city_id" 
				@update:modelValue="cityChange()"
			/>
		</div>
		<div class="md:col-span-3 col-span-6">
			<multi-select
				:options="barangays"
				id="barangay"
				name="barangay"
				label="Barangay"
				placeholder="Select.."
				searchable
				v-model="form.barangay_id"
				:error="errors?.barangay_id" />
		</div>
		<div class="col-span-6">
			<text-input
				textarea
				label="Direction"
				placeholder="Direction"
				name="direction"
				id="direction"
				v-model="form.direction"
				:error="errors?.direction" />
		</div>
	</form>
</template>
<script setup lang="ts">
import { ref, type PropType, computed, onMounted } from "vue";

import TextInput from "@/components/inputs/TextInput.vue";
import RadioGroup from "@/components/inputs/RadioGroup.vue";
import { renderSelect } from "@/interfaces/DropdownInterface";
import { PatientAddressCreate } from "@/interfaces/Cases/PatientAddress";
import MultiSelect from "@/components/inputs/MultiSelect.vue";
import DropdownService from "@/classes/Dropdowns/DropdownService";
import { useNetworkStore } from "@/store/network";
import FormOptions from "@/classes/Forms/FormOptions";

const props = defineProps({
	form: {
		type: Object as PropType<PatientAddressCreate>,
		required: true,
	},
	errors: {
		type: Object,
		required: true,
	},
	residenceTypes: {
		type: [] as PropType<renderSelect>,
		default: [],
	},
});

const regions = ref<renderSelect>([]);
const provinces = ref<renderSelect>([]);
const cities = ref<renderSelect>([]);
const barangays = ref<renderSelect>([]);

const form = ref<PatientAddressCreate>(props.form);
const networkStore = useNetworkStore();
const isNetworkAvailable = computed(() => networkStore.isNetworkAvailable);
const options = new FormOptions();

const getRegions = async (): Promise<any> => {
    if (isNetworkAvailable.value) {
        DropdownService
        .regions()
        .then(({data}) => {
            regions.value = data;
        })
    } else {
        regions.value = await options.regions()
    }
}

const getProvince = async (): Promise<any> => {
    if(form.value.region_id){
        if (isNetworkAvailable.value) {
            DropdownService
            .provinces(form.value.region_id)
            .then(({data}) => {
                provinces.value = data;
            })
        } else {
            provinces.value = await options.provinces(form.value.region_id)
        }
    }
}

const getCity = async (): Promise<any> => {
    if(form.value.province_id){
        if (isNetworkAvailable.value) {
            DropdownService
            .cities(form.value.province_id)
            .then(({data}) => {
                cities.value = data;
            })
        } else {
            cities.value = await options.cities(form.value.province_id);
        }
    }
}

const getBrangay = async (): Promise<any> => {
    if(form.value.city_id){
        if (isNetworkAvailable.value) {
            DropdownService
            .barangays(form.value.city_id)
            .then(({data}) => {
                barangays.value = data;
            })
        } else {
            barangays.value = await options.barangays(form.value.city_id);
        }
    }
}

const regionChange = ():void => {
    form.value.city_id = null
    form.value.barangay_id = null
    form.value.province_id = null
    getProvince()
}

const provinceChange = ():void => {
    form.value.city_id = null
    form.value.barangay_id = null
    getCity()
}

const cityChange = ():void => { 
    form.value.barangay_id = null
    getBrangay()
}

onMounted(() => {
    getRegions()

    if(form.value.id){
        getProvince();
        getCity();
        getBrangay();
    }
})

</script>
