import { EnumsInterface } from "@/interfaces/EnumsInterface";

const TeleconsultSubType:EnumsInterface[] = [
    {id: 0, value: 0, label: "Barangay CPU Station"},
    {id: 1, value: 1, label: "Call"},
    {id: 2, value: 2, label: "Canceled"},
    {id: 3, value: 3, label: "No Show"},
    {id: 4, value: 4, label: "SMS"},
    {id: 5, value: 5, label: "Video Call"},
    {id: 6, value: 6, label: "Own logistics"},
]

export default TeleconsultSubType