interface IStorage {
	getItem(key: string): string | null;
	setItem(key: string, value: string): void;
	removeItem(key: string): void;
}

export default class Storage<T extends string> {
	private readonly storage: IStorage;

	public constructor(getStorage = (): IStorage => window.localStorage) {
		this.storage = getStorage();
	}

	public get(key: T): any {
		return this.storage.getItem(key);
	}

	public set(key: T, value: any): void {
		this.storage.setItem(key, value);
	}

	public clearItem(key: T): void {
		this.storage.removeItem(key);
	}

	public clearItems(keys: T[]): void {
		keys.forEach((key) => this.clearItem(key));
	}
}
