import DB from "@/classes/Databases/Crud";
import { OfflineAction } from "@/views/enums/OfflineAction";
import Relationships from "../../Relationships";

// const db = new DB("intake_person_related_to_cases");
const db = new DB("case_guardians");
const relationship = new Relationships();
class OfflineSyncPersonRelatedToCase {
    async syncToOffline(payload: any, caseId: any): Promise<any> {
        for (const index in payload.data) {
            const item = payload.data[index];
            await db.insert(
                this.contentFormat(item, caseId),
                item.id,
                true,
                'case_guardians'
            );
        }
    }

    async index(payload: any, caseId: any, isPaginate = true) {
        return await db.index(payload, ` WHERE cases_id=${caseId}`, isPaginate, 'case_guardians')
    }

    async save(payload: any, patientId: any, caseId: any) {
        if (payload.id) {
            await this.update(payload, caseId);
        } else {
            await this.create(payload, caseId);
        }
    }

    renderPayload(payload: any, caseId: any) {
        payload.name = `${payload.first_name} ${payload.last_name}`;
        payload.age = payload.birthdate ? relationship.computeAge(payload.birthdate) : relationship.computeApproximateAge(payload.approximate_age_year, payload.approximate_age_month);
        payload.computed_age = payload.birthdate ? relationship.computeAge(payload.birthdate) : relationship.computeApproximateAge(payload.approximate_age_year, payload.approximate_age_month);
        payload.birthdate = payload.birthdate ? relationship.dateFormatFromIso(payload.birthdate) : null;
        payload.birthdate_formatted = payload.birthdate ? relationship.dateFormat(payload.birthdate) : null;
        payload.gender_meta = relationship.gender(payload) || null;
        return payload;
    }

    async create(payload: any, caseId: any) {
        payload = this.renderPayload(payload, caseId);
        await db.insert({
            cases_id: caseId,
            content: JSON.stringify(payload),
            encoded_by: 0,
            action: OfflineAction.NEW_RECORD,
            is_sync: false,
            created_at: relationship.dateFormatFromIso(new Date()),
            updated_at: relationship.dateFormatFromIso(new Date()),
            deleted_at: "",
            is_created_offline: true,
        }, null, false, 'case_guardians');
    }

    async update(payload: any, caseId: any) {
        payload = this.renderPayload(payload, caseId);
        const response = await db.select(`SELECT * FROM case_guardians WHERE id=${payload.id} LIMIT 1`);
        if (response.length > 0 ) {
            const item = response[0];
            await db.delete({id: item.id}, 'case_guardians')
            await db.insert({
                id: payload.id,
                cases_id: caseId,
                content: JSON.stringify(payload),
                encoded_by: 0,
                action: OfflineAction.UPDATE_RECORD,
                is_sync: false,
                created_at: item.created_at,
                updated_at: relationship.dateFormatFromIso(new Date()),
                deleted_at: "",
                is_created_offline: item.is_created_offline,
                is_updated_offline: item.is_created_offline ? false : true,
            }, null, false, 'case_guardians');
        }
    }

    async delete(id: any, caseId: any) {
        const response = await db.edit(id, false, 'case_guardians');
        if (response.data.item.length === undefined) {
            const item = response.data.item;
            if (item.is_created_offline) {
                await db.delete({id: id}, 'case_guardians');
            } else {
                await db.insert({
                    id: item.id,
                    cases_id: caseId,
                    content: item.content,
                    encoded_by: item.encoded_by,
                    action: OfflineAction.DELETE_RECORD,
                    is_sync: false,
                    deleted_at: relationship.dateFormatFromIso(new Date()),
                    is_created_offline: item.is_created_offline,
                    is_updated_offline: item.is_updated_offline,
                    is_deleted_offline: true,
                }, item.id, false, 'case_guardians');
            }
        }
    }

    async transformContent(payload: any, item: any) {
        const companionRelationship:any = await relationship.companionRelationship(payload);
        const gender:any = relationship.gender(payload);
        payload.id = item.id;
        payload.gender_formatted = gender?.description;
        payload.companion_relationship = companionRelationship?.label;
        payload.computed_age = payload.birthdate ? relationship.computeAge(payload.birthdate) : relationship.computeApproximateAge(payload.approximate_age_year, payload.approximate_age_month);
        payload.name = `${payload.first_name}, ${payload.last_name}`

        return payload;
    }

    contentFormat(payload: any, caseId: any) {
        return {
            id: payload.id,
            cases_id: caseId,
            content: JSON.stringify(payload),
            encoded_by: 0,
            action: OfflineAction.DEFAULT,
            is_sync: false,
            created_at: "",
            updated_at: "",
            deleted_at: "",
        }
    }
}

export default new OfflineSyncPersonRelatedToCase();