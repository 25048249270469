<template>
    <div class="w-full">
        <section-title 
            fontSmall
            title="Baranagay Information"
        >
            <p class="text-sm font-medium text-neutral-600">Please fill out all the required fields with the red asterisk (<span class="text-red-600">*</span>)</p>
        </section-title>
        <form class="grid grid-cols-2 gap-6 mt-6">
            <div class="col-span-2 border-t border-gray-200"></div>

            <div class="col-span-2">
                <text-input
                    type="text"
                    label="Code"
                    placeholder="Code"
                    name="code"
                    id="code"
                    v-model="form.code"
                    :error="errors?.code"
                    required
                />
            </div>

            <div class="col-span-2">
                <multi-select 
                    searchable
                    :options="regionOptions"
                    id="region"
                    name="region"
                    label="Region"
                    placeholder="Select.."
                    v-model="form.region_id"
                    :error="errors?.region_id"
                    @update:modelValue="regionChange()"
                    required
                />
            </div>

            <div class="col-span-2">
                <multi-select 
                    searchable
                    :options="provinceOptions"
                    id="Province"
                    name="Province"
                    label="Province"
                    placeholder="Select.."
                    v-model="form.province_id"
                    :error="errors?.province_id"
                    @update:modelValue="provinceChange()"
                    required
                />
            </div>

            <div class="col-span-2">
                <multi-select 
                    searchable
                    :options="cityOptions"
                    id="City"
                    name="City"
                    label="City"
                    placeholder="Select.."
                    v-model="form.city_id"
                    :error="errors?.city_id"
                    required
                />
            </div>
            
            <div class="col-span-2">
                <text-input
                    type="text"
                    label="Name"
                    placeholder="Name"
                    name="name"
                    id="name"
                    v-model="form.name"
                    :error="errors?.name"
                    required
                />
            </div>            
            
            <slot></slot>
        </form>
    </div>
</template>
<script setup lang="ts">
import { ref, onMounted } from 'vue';
import SectionTitle from '@/components/partials/SectionTitle.vue';
import TextInput from '@/components/inputs/TextInput.vue';
import MultiSelect from "@/components/inputs/MultiSelect.vue";

import {
    LocationDataInterface,
    SelectInterface
} from "@/interfaces/Settings/LocationInterface"

import DropdownService from "@/classes/Dropdowns/DropdownService";

const props = defineProps({
    form: {
        type: Object as () => LocationDataInterface,
        defualt: [],
        required: true,
    },
    errors: {
        type: Object,
        defualt: [],
        required: true,
    },
});


const form = ref<LocationDataInterface>(props.form);
const regionOptions = ref<SelectInterface>([])
const provinceOptions = ref<SelectInterface>([])
const cityOptions = ref<SelectInterface>([])

const getRegions = ():void => {
    DropdownService
        .regions()
        .then(({data}) => {
            regionOptions.value = data;
        })
}

const getProvince = ():void => {
    if(form.value.region_id){
        DropdownService
            .provinces(form.value.region_id)
            .then(({data}) => {
                provinceOptions.value = data;
            })
    }
}

const getCity = ():void => {
    if(form.value.province_id){
        DropdownService
            .cities(form.value.province_id)
            .then(({data}) => {
                cityOptions.value = data;
            })
    }
}

const regionChange = ():void => {
    form.value.city_id = null
    form.value.barangay_id = null
    form.value.province_id = null
    getProvince()
}

const provinceChange = ():void => {
    form.value.city_id = null
    form.value.barangay_id = null
    getCity()
}

onMounted(() => {
    getRegions()

    if(form.value.id){
        getProvince();
        getCity();
    }
})
</script>