import { PatientAddressCreate } from "@/interfaces/Cases/PatientAddress";
export default class PatientAddressForm implements PatientAddressCreate {
	id?: number | null;
	region_id: number | null;
	province_id: number | null;
	city_id: number | null;
	barangay_id: number | null;
	residence_type: number | null;
	age: number | null;
	address: string | null;
	direction: string | null;
	constructor(item: PatientAddressCreate) {
		this.region_id = item?.region_id;
		this.province_id = item?.province_id;
		this.city_id = item?.city_id;
		this.barangay_id = item?.barangay_id;
		this.residence_type = item?.residence_type;
		this.age = item?.age;
		this.address = item?.address;
		this.direction = item?.direction;
	}

	updatePatientAddress(item: PatientAddressCreate) {
		this.id = item?.id;
		this.region_id = item?.region_id;
		this.province_id = item?.province_id;
		this.city_id = item?.city_id;
		this.barangay_id = item?.barangay_id;
		this.residence_type = item?.residence_type;
		this.age = item?.age;
		this.address = item?.address;
		this.direction = item?.direction;
	}

    offlineCreateValidation(form: any) {
		const errors = <any>{};
		let hasOfflineError = false;

		if (form.residence_type == undefined) {
			hasOfflineError = true;
			Object.assign(errors, { residence_type: "The residence type field is required." });
		}

		if (!form.address) {
			hasOfflineError = true;
			Object.assign(errors, { address: "The address field is required." });
		}

		if (!form.region_id) {
			hasOfflineError = true;
			Object.assign(errors, { region_id: "The region field is required." });
		}

		if (!form.city_id) {
			hasOfflineError = true;
			Object.assign(errors, { city_id: "The city field is required." });
		}

        if (!form.province_id) {
			hasOfflineError = true;
			Object.assign(errors, { province_id: "The province field is required." });
		}

		return {
			hasOfflineError: hasOfflineError,
			errors: errors,
		};
	}
}
