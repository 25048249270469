const DataDictionaries = {
    name: 'data_dictionaries',
    schema: [
        { column: 'id', value: 'INTEGER PRIMARY KEY AUTOINCREMENT' },
        { column: 'name', value: 'VARCHAR(255) NOT NULL' },
        { column: 'content', value: 'TEXT NOT NULL' },
        { column: 'encoded_by', value: 'INTEGER UNSIGNED' },
        { column: 'created_at', value: 'TIMESTAMP' },
        { column: 'updated_at', value: 'TIMESTAMP' },
        { column: 'deleted_at', value: 'TIMESTAMP' },
    ]
}

export default DataDictionaries