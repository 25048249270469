import { createApp } from "vue";
import { createPinia } from "pinia";
import App from "./App.vue";
import vue_router from "./router";
import VueCryptojs from 'vue-cryptojs'

import { IonicVue } from "@ionic/vue";

/* Core CSS required for Ionic components to work properly */
import "@ionic/vue/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/vue/css/normalize.css";
import "@ionic/vue/css/structure.css";
import "@ionic/vue/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/vue/css/padding.css";
import "@ionic/vue/css/float-elements.css";
import "@ionic/vue/css/text-alignment.css";
import "@ionic/vue/css/text-transformation.css";
import "@ionic/vue/css/flex-utils.css";
import "@ionic/vue/css/display.css";

/* Custom Font */
import "./assets/fonts/inter/stylesheet.scss";

/* Theme variables */
import "./theme/variables.css";
import "./theme/tailwind.css";

/* Custom CSS */
import "./assets/scss/app.scss";

import Storage from '@/helpers/storage';
import { Network } from '@capacitor/network';
import { useNetworkStore } from '@/store/network';

// import { initDatabase } from "@/database/DatabaseMigration";

/* Axios Set up CSS */
// import axios from "@/plugins/axios";

declare global {
	interface Window {
		// axios: any;
		$isNetworkAvailable: boolean;
	}
}

// window.axios = require("axios");

/* Entity holding state */
const pinia = createPinia();
const storage = new Storage();

const app = createApp(App)
.use(IonicVue, {
	mode: "ios",
})
.use(pinia)
.use(VueCryptojs)
.use(vue_router);


Promise.allSettled([vue_router.isReady()]).then(() => {
	logCurrentNetworkStatus();
	addNetworkListener();
    // initializeServiceWorker();
	app.mount("#app");
});

const networkStore = useNetworkStore();

const logCurrentNetworkStatus = async () => {
  const status = await Network.getStatus();
  //   console.log('Network status:', status);
  storage.set('isNetworkAvailable', JSON.stringify(status.connected))
  // storage.set('isNetworkAvailable', JSON.stringify(false))
  networkStore.isNetworkAvailable = storage.get('isNetworkAvailable') == 'true' ? true : false;
};

const addNetworkListener = () => {
  Network.addListener('networkStatusChange', status => {
    console.log('Network status changed', status);
    storage.set('isNetworkAvailable', JSON.stringify(status.connected))
    networkStore.isNetworkAvailable = storage.get('isNetworkAvailable') == 'true' ? true : false;
  });
}

const initializeServiceWorker = () => {
    if ("serviceWorker" in navigator) {
        navigator.serviceWorker.register('/sw.js')
        .then((reg) => console.log('service worker registered', reg))
        .catch((err) => console.log('service worker not registered', err));
    } else {
        console.log('service worker no supported')
    }
}