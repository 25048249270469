import { EnumsInterface } from "@/interfaces/EnumsInterface";

const Therapy:EnumsInterface[] = [
    {id: 0, value: 0, label: "Therapy - Individual"},
    {id: 1, value: 1, label: "Therapy - Behavioral"},
    {id: 2, value: 2, label: "Therapy - Family"},
    {id: 3, value: 3, label: "Therapy - Group"},
    {id: 4, value: 4, label: "Therapy - Trauma-informed Psychosocial Processing(TIPP)"},
]

export default Therapy