import { CaseConference } from "@/interfaces/Cases/CaseConference/CaseConference";
import OnlineService from "./Classes/OnlineService";
import OfflineService from "./Classes/OfflineService";
import http from "@/axios";

class CaseConferenceService implements CaseConference {
	async index(patient: number, patientCase: number, networkAvailable: boolean): Promise<any> {
		if(networkAvailable){
			return await OnlineService.index(patient, patientCase);
		} else {
			return await OfflineService.index(patient, patientCase);
		}
	}

	async storeIndexOffline(items:any): Promise<any> {
		return await OfflineService.storeIndexOffline(items);
	}

	async create(patient: number, patientCase: number, networkAvailable: boolean): Promise<any> {
		if(networkAvailable){
			return await OnlineService.create(patient, patientCase);
		} else {
			return await OfflineService.create(patient, patientCase);
		}
	}

	async store(patient: number, patientCase: number, payload: object, networkAvailable: boolean): Promise<any> {
		if(networkAvailable){
			return await OnlineService.store(patient, patientCase, payload);
		} else {
			return await OfflineService.store(patient, patientCase, payload);
		}
	}

	async edit(patient: number, patientCase: number, caseConference: number, networkAvailable: boolean): Promise<any> {
		if(networkAvailable){
			return await OnlineService.edit(patient, patientCase, caseConference);
		} else {
			return await OfflineService.edit(patient, patientCase, caseConference);
		}
	}

	async update(patient: number, patientCase: number, caseConference: number, payload: any, networkAvailable: boolean): Promise<any> {
		if(networkAvailable){
			return await OnlineService.update(patient, patientCase, caseConference, payload);
		} else {
			return await OfflineService.update(patient, patientCase, caseConference, payload);
		}
	}

	async delete(patient: number, patientCase: number, caseConference: number, networkAvailable: boolean): Promise<any> {
		if(networkAvailable){
			return await OnlineService.delete(patient, patientCase, caseConference);
		} else {
			return await OfflineService.delete(patient, patientCase, caseConference);
		}
	}

	async indexFactors(patient: number, patientCase: number, caseConference: number, networkAvailable: boolean): Promise<any> {
		if(networkAvailable){
			return await OnlineService.indexFactors(patient, patientCase, caseConference);
		} else {
			return await OfflineService.indexFactors(patient, patientCase, caseConference);
		}
	}

	async storeFactorsOffline(item:any): Promise<any> {
		return await OfflineService.storeFactorsOffline(item);
	}

	async storeFactors(patient: number, patientCase: number, caseConference: number, payload: any, networkAvailable: boolean): Promise<any> {
		if(networkAvailable){
			return await OnlineService.storeFactors(patient, patientCase, caseConference, payload);
		} else {
			return await OfflineService.storeFactors(patient, patientCase, caseConference, payload);
		}
	}

	async indexRecommendations(patient: number, patientCase: number, caseConference: number, networkAvailable: boolean): Promise<any> {
		if(networkAvailable){
			return await OnlineService.indexRecommendations(patient, patientCase, caseConference);
		} else {
			return await OfflineService.indexRecommendations(patient, patientCase, caseConference);
		}
	}

	async storeRecommendationsOffline(item:any): Promise<any> {
		return await OfflineService.storeRecommendationsOffline(item);
	}

	async storeRecommendations(patient: number, patientCase: number, caseConference: number, payload: any, networkAvailable: boolean): Promise<any> {
		if(networkAvailable){
			return await OnlineService.storeRecommendations(patient, patientCase, caseConference, payload);
		} else {
			return await OfflineService.storeRecommendations(patient, patientCase, caseConference, payload);
		}
	
	}

	async indexMinutes(patient: number, patientCase: number, caseConference: number, networkAvailable: boolean): Promise<any> {
		if(networkAvailable){
			return await OnlineService.indexMinutes(patient, patientCase, caseConference);
		} else {
			return await OfflineService.edit(patient, patientCase, caseConference);
		}
	}

	async storeMinutes(patient: number, patientCase: number, caseConference: number, payload: any, networkAvailable: boolean): Promise<any> {
		if(networkAvailable){
			return await OnlineService.storeMinutes(patient, patientCase, caseConference, payload);
		} else {
			return await OfflineService.storeMinutes(patient, patientCase, caseConference, payload);
		}
	}

	public async generatePDFAccess(patient:string, cases:string, caseConference: number,  networkAvailable: boolean) : Promise<any> {
		if(networkAvailable){
			return await OnlineService.generatePDFAccess(patient, cases, caseConference);
		} 

		return {};
    }

    public async downloadPDF(caseConference:number, token:string, networkAvailable: boolean): Promise<void> {	
		if(networkAvailable){
			return await OnlineService.downloadPDF(caseConference, token);
		} 	
	}
}

export default new CaseConferenceService();
