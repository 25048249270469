export enum FormFieldType {
    MAIN = 0,
    OPTION = 10,
    DROPDOWN = 20,
    DROPDOWN_EXISTING = 21,
    RADIO = 30,
    TOGGLE = 40,
    INPUT = 50,
    MULTI_SELECT = 60,
    CHECKBOX_SINGLE = 70,
    CHECKBOX_OPTION = 80,
    LINK = 90,
    DATE_TIME = 100,
    DATE = 110,
    TIME = 120,
    TEXT = 130,
}
