import http from "@/axios";
import Personnelnterface from "@/interfaces/classes/Personnelnterface";

class Personnels implements Personnelnterface {
	async index(payload?: object): Promise<any> {
		return await http.get("personnels/index", { params: payload });
	}

	async create(payload?: object): Promise<any> {
		return await http.get("personnels/create", { params: payload });
	}

	async store(payload: object): Promise<any> {
		return await http.post("personnels/store", payload);
	}

	async edit(personnel: number, payload?: object): Promise<any> {
		return await http.get(`personnels/edit/${personnel}`, payload);
	}
	
	async update(personnel: number, payload?: object): Promise<any> {
		return await http.post(`personnels/update/${personnel}`, payload);
	}

	async delete(personnel: number, payload?: object): Promise<any> {
		return await http.post(`personnels/delete/${personnel}`, payload);
	}

	async restore(personnel: number): Promise<any> {
		return await http.post(`personnels/restore/${personnel}`);
	}

	async export(payload?: object): Promise<any> {
		return await http.get(`personnels/export`, payload);
	}
}
export default new Personnels();
