<template>
    <div class="items-center lg:w-1/2 w-full lg:h-screen flex bg-primary-600 lg:py-0 py-4 lg:px-0 px-4">
        <div class="flex flex-col items-center justify-center w-full">
            <div class="p-4 bg-white rounded-lg sm:w-max w-full lg:scale-100 sm:scale-75 scale-50 transition-all">
                <img src="/assets/cpn-logo-hd.png" alt="logo" class="w-[330px] h-[145px] object-contain mx-auto"/>
                <div class="flex items-center mt-5 space-x-4">
                    <p class="text-sm font-semibold">With support from:</p>
                    <div class="p-4">
                        <img src="/assets/login-logo-sub.png" alt="logosub" class="w-[196px] h-[40px] object-contain mx-auto"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>