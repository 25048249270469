const Interventions = {
	name: "intervention_data",
	schema: [
		{ column: "id", value: "INTEGER  PRIMARY KEY AUTOINCREMENT" },
		{ column: "offline_id", value: "INTEGER KEY" },
		
		{ column: "patient_id", value: "INTEGER UNSIGNED" },
		{ column: "cases_id", value: "INTEGER UNSIGNED" },
		{ column: "case_number", value: "TEXT NULL" },
		{ column: "intervention_type", value: "INTEGER UNSIGNED" },
		{ column: "intervention_type_name", value: "TEXT NULL" },
		{ column: "type", value: "TEXT NULL" },
		{ column: "type_type", value: "INTEGER UNSIGNED" },
		{ column: "type_id", value: "INTEGER UNSIGNED" },
		{ column: "subtype", value: "INTEGER NULL" },
		{ column: "subtype_type", value: "INTEGER NULL" },
		{ column: "subtype_id", value: "INTEGER NULL" },
		{ column: "schedule_date", value: "TIMESTAMP" },
		{ column: "done_on", value: "TIMESTAMP NULL" },
		{ column: "position_id", value: "INTEGER NULL" },
		{ column: "done_by_id", value: "INTEGER NULL" },
		{ column: "done_by", value: "TEXT NULL" },
		{ column: "data", value: "TEXT" },
		{ column: "action", value: "INT UNSIGNED" },
		{ column: "patient_name", value: "TEXT NULL" },
		{ column: "is_sync", value: "BOOLEAN DEFAULT FALSE" },

		{ column: "created_at", value: "TIMESTAMP NULL" },
		{ column: "updated_at", value: "TIMESTAMP NULL" },
		{ column: "deleted_at", value: "TIMESTAMP NULL" },

		{ column: "is_sync_failed", value: "BOOLEAN DEFAULT FALSE"},
        { column: "is_created_offline", value: "BOOLEAN DEFAULT FALSE" },
        { column: "is_updated_offline", value: "BOOLEAN DEFAULT FALSE" },
        { column: "is_deleted_offline", value: "BOOLEAN DEFAULT FALSE" },
		{ column: "synching_remarks", value: "TEXT DEFAULT NULL" },
		{ column: "status", value: "TEXT NULL" },
	],
};

export default Interventions;
