import DataDictionaries from './DataDictionaryMigration'
import Regions from './RegionMigration'
import Provinces from './ProvinceMigration'
import Cities from './CityMigration'
import BarangayMigration from './BarangayMigration'
import Patients from './PatientMigration'
import Cases from './CaseMigration'
import Interventions from './InterventionMigration'
import InterventionsFormStructure from './InterventionFormMigration'
import CivilStatuses from './FormOptions/CivilStatusMigration'
import Religions from './FormOptions/ReligionMigration'
import Nationalities from './StaticTables/NationalityMigration'
import IndigeneousPeoples from './FormOptions/IndigeneousPeopleMigration'
import CaseCompanions from './CaseCompanions'
import CaseGuardians from './CaseGuardians'
import CaseImmediateSafetyAssessments from './CaseImmediateSafetyAssessments'
import CaseIntakes from './CaseIntakes'
import CasePatientAddresses from './CasePatientAddresses'
import CasePerpetrators from './CasePerpetrators'
import PerpetratorMigration from './PerpetratorMigration';
import DraftMigration from './DraftMigration'
import DropdownOptions from './DropdownOptions';
import PersonnalInformation from './Intake/PersonnalInformation';
import PersonRelationToCase from './Intake/PersonRelationToCase';
import IncestCases from './Intake/IncestCases';
import EmailAddress from './Intake/EmailAddress';
import ContactNumber from './Intake/ContactNumber';
import CaseConference from './CaseConference/CaseConference'
import AggravatingFactors from './CaseConference/AggravatingFactors'
import Minutes from './CaseConference/MInutes'
import CaseConferenceRecommendation from './CaseConference/CaseConferenceRecommendation'

const Migrations = [
    DataDictionaries,
    Regions,
    Provinces,
    Cities,
    BarangayMigration,
    Patients,
    Cases,
    Interventions,
    InterventionsFormStructure,
    CivilStatuses,
    Religions,
    Nationalities,
    IndigeneousPeoples,
    CaseCompanions,
    CaseGuardians,
    CaseImmediateSafetyAssessments,
    CaseIntakes,
    CasePatientAddresses,
    CasePerpetrators,
    PerpetratorMigration,
    DraftMigration,
    DropdownOptions,
    PersonnalInformation,
    PersonRelationToCase,
    IncestCases,
    EmailAddress,
    ContactNumber,
    CaseConference,
    AggravatingFactors,
    Minutes,
    CaseConferenceRecommendation,
];

export default Migrations;
