import http from "@/axios";


class OnlineService {


    async patientSearch(payload: object): Promise<any> {
        return await http.get("patients", { params: payload }).catch(error => error);
	}

	async index(patient: number, case_id: number|null = null, payload: object|null = null): Promise<any> {
        let url = `interventions/${patient}`;

        if( case_id != null){
            url = `patients/${patient}/cases/${case_id}/interventions`;
        }

        return await http.get(url, { params: payload })
	}

	async create(patient: number, form: number, case_id: number|null = null, payload: object|null = null): Promise<any> {
        return await http.get(`interventions/${patient}/create/${form}`, { 
            params: {
                case_id: case_id,
                payload: payload,
            } 
        });
	}

	async store(patient: number, form: number, payload: object ): Promise<any> {
        return await http.post(`interventions/${patient}/store/${form}`, payload);
	}

	async edit(interventionType:number, intervention:number, patient: number, payload: object|null = null): Promise<any> {
        return await http.get(`interventions/${patient}/edit/${intervention}`, { 
            params: {
                payload: payload,
            } 
        });
	}
	
	async update(intervention:number, patient: number, payload: object): Promise<any> {
		return await http.put(`interventions/${patient}/update/${intervention}`, payload);
	}

	public async generatePDFAccess(intervention: number|string) : Promise<JSON> {
		return await http.post(`/interventions/${intervention}/generatePDFAccess`);
	}

    public async downloadCourtHearing(intervention:number|string, token:string): Promise<void> {		
        window.open(`${process.env.VUE_APP_URL}pdf/${intervention}/downloadCourtHearing/${token}`);
		
	}
}
export default new OnlineService();
