import http from "@/axios";
import DB from "@/classes/Databases/Crud";

const dbPatient = new DB("patients");
const dbCases = new DB("cases");
const dbCasePerpetrators = new DB("case_perpetrators");
const dbPatientAddress = new DB("case_patient_addresses");
const dbGuardians = new DB("case_guardians");
const dbCompanions = new DB("case_companions");
const dbInterventions = new DB("intervention_data");
const dbIntakePersonnalInformation = new DB('intake_personnal_informations');
const dbIntakePersonToCase = new DB('intake_person_related_to_cases');
const dbIntakeContactNumber = new DB('intake_contact_numbers');
const dbIntakeEmail = new DB('intake_email_address');
const dbIntakeIncest = new DB('intake_incest_cases');
const dbSafetyAssessment = new DB('case_immediate_safety_assessments');
const dbCaseConferenceInformation = new DB('case_conferences');
const dbCaseConferenceFactor = new DB('aggravating_factors');
const dbCaseConferenceRecommendation = new DB('case_conference_recommendations');
const dbCaseConferenceMinutes = new DB('minutes');
export default class SyncCases {
	resetItemData(item: any) {
		item.synching_remarks = null;
		item.is_sync_failed = false;
		item.is_sync = true;
		item.is_created_offline = false;
		item.is_updated_offline = false;
		item.is_deleted_offline = false;

		return item;
	}

	async rollback(item: any, response: any) {
		const error = response?.response?.data;
		item.synching_remarks = error.message || "System Error";
		item.is_sync_failed = true;
		await dbPatient.insert(item, item.id, true, "cases");

		return true;
	}

	async processForm(item: any, form: any) {
		let hasError = false;

		if (item.is_updated_offline) {
			const response = await http.post("/attemp-synching-cases-update", form).catch((error) => error);
			if (response?.status === 200) {
				item = this.resetItemData(item);
				await dbCases.insert(item, item.id, true, "cases");
				hasError = false;
			} else {
				hasError = await this.rollback(item, response);
			}
		}

		if (item.is_created_offline && !item.is_updated_offline) {
			const response = await http.post("/attemp-synching-cases-create", form).catch((error) => error);
			if (response?.status === 200) {
				const newId = response.data.data.case_details.id;
				const oldPatientId = item.id;
				const casesPerpetrators = await dbCasePerpetrators.select(
					`SELECT * FROM case_perpetrators WHERE cases_id=${item.id}`
				);
				for (const caseIndex in casesPerpetrators) {
					const caseItem = casesPerpetrators[caseIndex];
					caseItem.cases_id = newId;
					await dbCasePerpetrators.insert(caseItem, caseItem.id, true, "case_perpetrators");
				}
				const guardians = await dbGuardians.select(`SELECT * FROM case_guardians WHERE cases_id=${item.id}`);
				for (const itemIndex in guardians) {
					const guardianItem = guardians[itemIndex];
					guardianItem.cases_id = newId;
					await dbGuardians.insert(guardianItem, guardianItem.id, true, "case_guardians");
				}
				const informants = await dbCompanions.select(`SELECT * FROM case_companions WHERE cases_id=${item.id}`);
				for (const itemIndex in informants) {
					const informantItem = informants[itemIndex];
					informantItem.cases_id = newId;
					await dbCompanions.insert(informantItem, informantItem.id, true, "case_companions");
				}
				const patientAddress = await dbPatientAddress.select(
					`SELECT * FROM case_patient_addresses WHERE cases_id=${item.id}`
				);
				for (const itemIndex in patientAddress) {
					const informantItem = patientAddress[itemIndex];
					informantItem.cases_id = newId;
					await dbPatientAddress.insert(informantItem, informantItem.id, true, "case_patient_addresses");
				}

				const interventions = await dbInterventions.select(`SELECT * FROM intervention_data WHERE cases_id=${item.id}`, 'intervention_data');
                for (const ii in interventions) {
                    const interventionItem = interventions[ii];
                    interventionItem.cases_id = newId;
                    await dbInterventions.insert(interventionItem, interventionItem.id, true, 'intervention_data');
                }

                const intakePersonalInformation = await dbIntakePersonnalInformation.select(`SELECT * FROM intake_personnal_informations WHERE cases_id=${item.id}`);
				for (const itemIndex in intakePersonalInformation) {
					const setItem = intakePersonalInformation[itemIndex];
					setItem.cases_id = newId;
					await dbIntakePersonnalInformation.insert(setItem, setItem.id, true, "intake_personnal_informations");
				}

                const intakePersonToCase = await dbIntakePersonToCase.select(`SELECT * FROM intake_person_related_to_cases WHERE cases_id=${item.id}`);
				for (const itemIndex in intakePersonToCase) {
					const setItem = intakePersonToCase[itemIndex];
					setItem.cases_id = newId;
					await dbIntakePersonToCase.insert(setItem, setItem.id, true, "intake_person_related_to_cases");
				}

                const intakeContactNumber = await dbIntakeContactNumber.select(`SELECT * FROM intake_contact_numbers WHERE cases_id=${item.id}`);
				for (const itemIndex in intakeContactNumber) {
					const setItem = intakeContactNumber[itemIndex];
					setItem.cases_id = newId;
					await dbIntakeContactNumber.insert(setItem, setItem.id, true, "intake_contact_numbers");
				}

                const intakeEmailAddress = await dbIntakeEmail.select(`SELECT * FROM intake_email_address WHERE cases_id=${item.id}`);
				for (const itemIndex in intakeEmailAddress) {
					const setItem = intakeEmailAddress[itemIndex];
					setItem.cases_id = newId;
					await dbIntakeEmail.insert(setItem, setItem.id, true, "intake_email_address");
				}

                const intakeIncesetToCase = await dbIntakeIncest.select(`SELECT * FROM intake_incest_cases WHERE cases_id=${item.id}`);
				for (const itemIndex in intakeIncesetToCase) {
					const setItem = intakeIncesetToCase[itemIndex];
					setItem.cases_id = newId;
					await dbIntakeIncest.insert(setItem, setItem.id, true, "intake_incest_cases");
				}

                const caseSafetyAssessment = await dbSafetyAssessment.select(`SELECT * FROM case_immediate_safety_assessments WHERE cases_id=${item.id}`);
				for (const itemIndex in caseSafetyAssessment) {
					const setItem = caseSafetyAssessment[itemIndex];
					setItem.cases_id = newId;
					await dbSafetyAssessment.insert(setItem, setItem.id, true, "case_immediate_safety_assessments");
				}

                const caseConferenceInformation = await dbCaseConferenceInformation.select(`SELECT * FROM case_conferences WHERE cases_id=${item.id}`);
				for (const itemIndex in caseConferenceInformation) {
					const setItem = caseConferenceInformation[itemIndex];
					setItem.cases_id = newId;
					await dbCaseConferenceInformation.insert(setItem, setItem.id, true, "case_conferences");
				}

				item = this.resetItemData(item);
				item.id = newId;
				await dbCases.delete({ id: newId }, "cases");
				await dbCases.insert(item, oldPatientId, true, "cases");
				hasError = false;
			} else {
				hasError = await this.rollback(item, response);
			}
		}

		if (item.is_deleted_offline) {
			const response = await http.post("/attemp-synching-cases-delete", form).catch((error) => error);
			if (response?.status === 200) {
				await dbCasePerpetrators.delete({ cases_id: item.id }, "case_perpetrators");
				await dbCases.delete({ id: item.id }, "cases");
				hasError = false;
			} else {
				hasError = await this.rollback(item, response);
			}
		}

		return hasError;
	}
}
