const ProvinceSeeder = {
    table: 'provinces',
    data: [
        {
            "id" : 1,
            "region_id" : 1,
            "name" : "ILOCOS NORTE"
        },
        {
            "id" : 2,
            "region_id" : 1,
            "name" : "ILOCOS SUR"
        },
        {
            "id" : 3,
            "region_id" : 1,
            "name" : "LA UNION"
        },
        {
            "id" : 4,
            "region_id" : 1,
            "name" : "PANGASINAN"
        },
        {
            "id" : 5,
            "region_id" : 2,
            "name" : "BATANES"
        },
        {
            "id" : 6,
            "region_id" : 2,
            "name" : "CAGAYAN"
        },
        {
            "id" : 7,
            "region_id" : 2,
            "name" : "ISABELA"
        },
        {
            "id" : 8,
            "region_id" : 2,
            "name" : "NUEVA VIZCAYA"
        },
        {
            "id" : 9,
            "region_id" : 2,
            "name" : "QUIRINO"
        },
        {
            "id" : 10,
            "region_id" : 3,
            "name" : "BATAAN"
        },
        {
            "id" : 11,
            "region_id" : 3,
            "name" : "BULACAN"
        },
        {
            "id" : 12,
            "region_id" : 3,
            "name" : "NUEVA ECIJA"
        },
        {
            "id" : 13,
            "region_id" : 3,
            "name" : "PAMPANGA"
        },
        {
            "id" : 14,
            "region_id" : 3,
            "name" : "TARLAC"
        },
        {
            "id" : 15,
            "region_id" : 3,
            "name" : "ZAMBALES"
        },
        {
            "id" : 16,
            "region_id" : 3,
            "name" : "AURORA"
        },
        {
            "id" : 17,
            "region_id" : 4,
            "name" : "BATANGAS"
        },
        {
            "id" : 18,
            "region_id" : 4,
            "name" : "CAVITE"
        },
        {
            "id" : 19,
            "region_id" : 4,
            "name" : "LAGUNA"
        },
        {
            "id" : 20,
            "region_id" : 4,
            "name" : "QUEZON"
        },
        {
            "id" : 21,
            "region_id" : 4,
            "name" : "RIZAL"
        },
        {
            "id" : 22,
            "region_id" : 5,
            "name" : "MARINDUQUE"
        },
        {
            "id" : 23,
            "region_id" : 5,
            "name" : "OCCIDENTAL MINDORO"
        },
        {
            "id" : 24,
            "region_id" : 5,
            "name" : "ORIENTAL MINDORO"
        },
        {
            "id" : 25,
            "region_id" : 5,
            "name" : "PALAWAN"
        },
        {
            "id" : 26,
            "region_id" : 5,
            "name" : "ROMBLON"
        },
        {
            "id" : 27,
            "region_id" : 6,
            "name" : "ALBAY"
        },
        {
            "id" : 28,
            "region_id" : 6,
            "name" : "CAMARINES NORTE"
        },
        {
            "id" : 29,
            "region_id" : 6,
            "name" : "CAMARINES SUR"
        },
        {
            "id" : 30,
            "region_id" : 6,
            "name" : "CATANDUANES"
        },
        {
            "id" : 31,
            "region_id" : 6,
            "name" : "MASBATE"
        },
        {
            "id" : 32,
            "region_id" : 6,
            "name" : "SORSOGON"
        },
        {
            "id" : 33,
            "region_id" : 7,
            "name" : "AKLAN"
        },
        {
            "id" : 34,
            "region_id" : 7,
            "name" : "ANTIQUE"
        },
        {
            "id" : 35,
            "region_id" : 7,
            "name" : "CAPIZ"
        },
        {
            "id" : 36,
            "region_id" : 7,
            "name" : "ILOILO"
        },
        {
            "id" : 37,
            "region_id" : 7,
            "name" : "NEGROS OCCIDENTAL"
        },
        {
            "id" : 38,
            "region_id" : 7,
            "name" : "GUIMARAS"
        },
        {
            "id" : 39,
            "region_id" : 8,
            "name" : "BOHOL"
        },
        {
            "id" : 40,
            "region_id" : 8,
            "name" : "CEBU"
        },
        {
            "id" : 41,
            "region_id" : 8,
            "name" : "NEGROS ORIENTAL"
        },
        {
            "id" : 42,
            "region_id" : 8,
            "name" : "SIQUIJOR"
        },
        {
            "id" : 43,
            "region_id" : 9,
            "name" : "EASTERN SAMAR"
        },
        {
            "id" : 44,
            "region_id" : 9,
            "name" : "LEYTE"
        },
        {
            "id" : 45,
            "region_id" : 9,
            "name" : "NORTHERN SAMAR"
        },
        {
            "id" : 46,
            "region_id" : 9,
            "name" : "SAMAR (WESTERN SAMAR)"
        },
        {
            "id" : 47,
            "region_id" : 9,
            "name" : "SOUTHERN LEYTE"
        },
        {
            "id" : 48,
            "region_id" : 9,
            "name" : "BILIRAN"
        },
        {
            "id" : 49,
            "region_id" : 10,
            "name" : "ZAMBOANGA DEL NORTE"
        },
        {
            "id" : 50,
            "region_id" : 10,
            "name" : "ZAMBOANGA DEL SUR"
        },
        {
            "id" : 51,
            "region_id" : 10,
            "name" : "ZAMBOANGA SIBUGAY"
        },
        {
            "id" : 52,
            "region_id" : 10,
            "name" : "CITY OF ISABELA"
        },
        {
            "id" : 53,
            "region_id" : 11,
            "name" : "BUKIDNON"
        },
        {
            "id" : 54,
            "region_id" : 11,
            "name" : "CAMIGUIN"
        },
        {
            "id" : 55,
            "region_id" : 11,
            "name" : "LANAO DEL NORTE"
        },
        {
            "id" : 56,
            "region_id" : 11,
            "name" : "MISAMIS OCCIDENTAL"
        },
        {
            "id" : 57,
            "region_id" : 11,
            "name" : "MISAMIS ORIENTAL"
        },
        {
            "id" : 58,
            "region_id" : 12,
            "name" : "DAVAO DEL NORTE"
        },
        {
            "id" : 59,
            "region_id" : 12,
            "name" : "DAVAO DEL SUR"
        },
        {
            "id" : 60,
            "region_id" : 12,
            "name" : "DAVAO ORIENTAL"
        },
        {
            "id" : 61,
            "region_id" : 12,
            "name" : "COMPOSTELA VALLEY"
        },
        {
            "id" : 62,
            "region_id" : 12,
            "name" : "DAVAO OCCIDENTAL"
        },
        {
            "id" : 63,
            "region_id" : 13,
            "name" : "COTABATO (NORTH COTABATO)"
        },
        {
            "id" : 64,
            "region_id" : 13,
            "name" : "SOUTH COTABATO"
        },
        {
            "id" : 65,
            "region_id" : 13,
            "name" : "SULTAN KUDARAT"
        },
        {
            "id" : 66,
            "region_id" : 13,
            "name" : "SARANGANI"
        },
        {
            "id" : 67,
            "region_id" : 13,
            "name" : "COTABATO CITY"
        },
        {
            "id" : 68,
            "region_id" : 14,
            "name" : "NCR, CITY OF MANILA, FIRST DISTRICT"
        },
        {
            "id" : 69,
            "region_id" : 14,
            "name" : "CITY OF MANILA"
        },
        {
            "id" : 70,
            "region_id" : 14,
            "name" : "NCR, SECOND DISTRICT"
        },
        {
            "id" : 71,
            "region_id" : 14,
            "name" : "NCR, THIRD DISTRICT"
        },
        {
            "id" : 72,
            "region_id" : 14,
            "name" : "NCR, FOURTH DISTRICT"
        },
        {
            "id" : 73,
            "region_id" : 15,
            "name" : "ABRA"
        },
        {
            "id" : 74,
            "region_id" : 15,
            "name" : "BENGUET"
        },
        {
            "id" : 75,
            "region_id" : 15,
            "name" : "IFUGAO"
        },
        {
            "id" : 76,
            "region_id" : 15,
            "name" : "KALINGA"
        },
        {
            "id" : 77,
            "region_id" : 15,
            "name" : "MOUNTAIN PROVINCE"
        },
        {
            "id" : 78,
            "region_id" : 15,
            "name" : "APAYAO"
        },
        {
            "id" : 79,
            "region_id" : 16,
            "name" : "BASILAN"
        },
        {
            "id" : 80,
            "region_id" : 16,
            "name" : "LANAO DEL SUR"
        },
        {
            "id" : 81,
            "region_id" : 16,
            "name" : "MAGUINDANAO"
        },
        {
            "id" : 82,
            "region_id" : 16,
            "name" : "SULU"
        },
        {
            "id" : 83,
            "region_id" : 16,
            "name" : "TAWI-TAWI"
        },
        {
            "id" : 84,
            "region_id" : 17,
            "name" : "AGUSAN DEL NORTE"
        },
        {
            "id" : 85,
            "region_id" : 17,
            "name" : "AGUSAN DEL SUR"
        },
        {
            "id" : 86,
            "region_id" : 17,
            "name" : "SURIGAO DEL NORTE"
        },
        {
            "id" : 87,
            "region_id" : 17,
            "name" : "SURIGAO DEL SUR"
        },
        {
            "id" : 88,
            "region_id" : 17,
            "name" : "DINAGAT ISLANDS"
        }
    ]
}

export default ProvinceSeeder