<template>
    <div class="border border-neutral-100 rounded-lg w-[320px]">
        <div class="flex justify-between p-6 align-middle border-b border-neutral-100">
            <div class="flex-1 overflow-hidden whitespace-nowrap text-ellipsis">
                <p class="overflow-hidden text-sm text-neutral-400 whitespace-nowrap text-ellipsis">{{ form.label }}</p>
            </div>
            <div class="flex items-center space-x-2">
                <p 
                    class="text-sm font-semibold transition cursor-pointer text-primary-500 hover:text-primary-700"
                    @click="showConfirmation = true"
                >
                    Save
                </p>
                <!-- <a 
                    @click="router.replace(`/settings/intervention-types/edit/${form.id}`)"
                    class="text-sm font-semibold transition cursor-pointer text-primary-500 hover:text-primary-700"
                >
                    Add
                </a> -->
            </div>
        </div>

        <template v-if="!loading">
            <template v-for="(subForm, index) in subForms" :key="subForm.id">
                <div 
                    class="relative flex items-center py-4 pl-6 pr-4 border-b last:border-0"
                    :class="[
                        activeForm?.id == subForm?.id ? 'bg-primary-300 ' : '',
                    ]"
                >
                    <div class="mr-3">
                        <input
                            type="number"
                            class="px-1 py-2 text-sm text-center bg-transparent border rounded-lg w-9 h-9"
                            :id="'sort '+ subForm.label" 
                            :name="'sort '+ subForm.label"
                            v-model="subForm.order"
                            :class="activeForm?.id == subForm?.id ? 'text-primary-600 border-primary-500' : 'text-neutral-900 border-neutral-100'"
                        >
                    </div>
                    <div class="flex-1 pr-6 overflow-hidden whitespace-nowrap text-ellipsis group">
                        <p 
                            class="overflow-hidden text-sm whitespace-nowrap text-ellipsis"
                            :class="activeForm?.id == subForm?.id ? 'text-primary-600' : 'text-neutral-900'"
                            :title="subForm.label"
                        >
                            {{ subForm.label }}
                        </p>
                        <div
                            v-if="errors[`children.${index}.order`]"
                            class="text-[10px] mt-1 text-red-600"
                            id="email-error"
                        >
                            {{ errors[`children.${index}.order`][0] }}
    
                        </div>
                        <p class="absolute hidden px-2 py-1 text-sm text-white bg-gray-500 rounded pointer-events-none top-5 left-18 group-hover:block">
                            {{ subForm.label }}
                        </p>
                    </div>
                    <div 
                        class="flex items-center space-x-2 shrink-0"
                        :class="activeForm?.id == subForm?.id ? 'text-primary-600' : 'text-gray-500'"
                    >
                        <EyeIcon 
                            @click="router.replace(`/settings/intervention-types/edit/${subForm.id}`)" 
                            class="w-5 h-5 transition cursor-pointer hover:text-secondary-500"
                            aria-hidden="true"
                            :class="activeForm?.id == subForm?.id ? 'text-primary-600' : 'text-gray-500'"
                        />
                        

                        <DeleteButton
                            :form="subForm"
                            @delete:success="updateForm"
                        />
<!-- 
                        <TrashIcon 
                            class="w-5 h-5 transition cursor-pointer hover:text-red-700"
                            aria-hidden="true"
                            :class="activeForm?.id == subForm?.id ? 'text-primary-600' : 'text-gray-500'"
                        /> -->
                        
                        <ChevronRightIcon 
                            class="w-5 h-5 transition cursor-pointer hover:text-primary-500"
                            aria-hidden="true"
                            @click="getSubForm(subForm)"
                        />
                    </div>
                </div>
            </template>
        </template>

    </div>

    <FormsCard
        v-if="loaded && childForms.length > 0"
        :form="activeForm"
        :subForms="childForms"
    />

    <page-loader :show="loading"/>
    
    <confirmation-modal
        :typeTwo="true"
        :show="showConfirmation"
        @cancel="showConfirmation = false"
        @confirm="save()"
        cancel-text="Cancel"
        action-text="Confirm"
        title="Save Record"
        content="Are you sure you wish to save form order?"
    />
    <success-modal
        :show="showSuccess"
        @confirm="showSuccess = false"
        action-text="Close"
        title="Success!"
        :content="successMessage"
    />

    <error-modal
        :show="showError"
        @confirm="showError = false"
        action-text="Close"
        title="Error!"
        :content="errorMessage"
    />


</template>

<script setup lang="ts">
import { ref, type PropType, onMounted, watch, computed } from "vue";
import { useRoute, useRouter } from "vue-router";

import { EyeIcon, TrashIcon, ChevronRightIcon } from "@heroicons/vue/24/outline";

import ConfirmationModal from "@/components/modals/ConfirmationModal.vue";
import SuccessModal from "@/components/modals/SuccessModal.vue";
import ErrorModal from "@/components/modals/ErrorModal.vue";

import PageLoader from "@/components/loaders/PageLoader.vue"

import FormsCard from "@/views/settings/InterventionTypes/Components/Index/FormsCard.vue";
import DeleteButton from "@/views/settings/InterventionTypes/Components/Buttons/FormCard/DeleteButton.vue";

import InterventionTypeService from "@/classes/Settings/InterventionTypeService.ts"

import { FormInterface } from "@/interfaces/pages/Settings/InterventionFormInterface.ts";

const route = useRoute();
const router = useRouter();

const props = defineProps({
    form: {
        type: Object as PropType<FormInterface>,
        required: true,
    },

    subForms: {
        type: Object as PropType<FormInterface[]>,
        required: true,
    }
});

const subForms = ref<FormInterface[]>(props.subForms);

const loaded = ref<boolean>(false);
const loading = ref<boolean>(false);

const showConfirmation = ref<boolean>(false);
const showSuccess = ref<boolean>(false);
const successMessage = ref<string>('');

const showError = ref<boolean>(false);
const errorMessage = ref<string>('');
const errors = ref<any>({});

const activeForm = ref<FormInterface>({
	id: 0,
	label: '',
	order: 0,
});

const childForms = ref<FormInterface[]>([]);


const getSubForm = (form: FormInterface):void => {

    activeForm.value = form
    loading.value = true;
    loaded.value = true

    InterventionTypeService
        .getChildForms(form.id)
        .then(({data}) => {
            childForms.value = data.children;
            loaded.value = true
        })
        .catch((error: any) => {
            console.log(error);
        })
        .finally(() => loading.value = false)
}



const save = ():void => {
    loading.value = true;
    showConfirmation.value = false;

    InterventionTypeService
        .updateChildrenOrder(
            props.form.id, 
            { children: subForms.value }
        )
        .then(({data}) => {
            showSuccess.value = true
            successMessage.value = data.message
            
            updateForm(data.data);
        })
        .catch((error: any) => {
            showError.value = true;
            errors.value = error.response.data.errors;
            errorMessage.value = error.response.data.message
        })
        .finally(() => loading.value = false)
}

const updateForm = (data: FormInterface[]): void => {
    loading.value = true;
    
    subForms.value = data;
    setTimeout(() => {
        loading.value = false;
    }, 300);
}

</script>