<template>
	<user-layout
		hasBack
		:backLink="`/patient/${patientId}/cases/${caseId}/edit`"
		hasBackClass="flex md:justify-between md:items-center md:flex-row flex-col">
		<template #lowerRight>
			<div class="flex md:flex-row flex-col items-center md:space-x-3 md:space-y-0 space-y-3">
				<a
					@click="
						router.replace(
							`/patient/${patientId}/cases/${caseId}/safety-assessment/${assessmentId}/create`
						)
					"
					class="md:w-auto w-full md:mt-0 mt-6">
					<button-component
					customClass="primary md:w-auto w-full">
						<!-- <img
							src="/assets/icon/ic-pencil-underline.svg"
							alt="edit_icon"
							class="object-contain w-4 h-4 mr-1 icon" /> -->
						<pencil-underline-icon class="object-contain w-4 h-4 mr-1 icon" />
						<span>Edit</span>
					</button-component>
				</a>

				<button-component
                    v-if="isNetworkAvailable"
					@click="generatePDFAccess()"
					size="large"
					customClass="bg-green-600 hover:bg-green-700 text-white transition border border-green-600 hover:border-green-700 md:w-auto w-full md:mt-0 mt-6"
				>
					<EyeIcon class="w-5 h-5 mr-2 -ml-1 icon"/>
					<span>View PDF</span>
				</button-component>
			
			</div>
		</template>
		<p class="mt-6 md:text-2xl text-lg font-medium text-neutral-600">Patient's Name:
			<span class="md:inline-block block font-semibold text-neutral-900">{{ item?.name }}</span>
		</p>
		<div class="w-full lg:p-6 p-4 mx-auto my-6 border border-gray-200 rounded" v-if="loaded">
			<div class="flex items-center justify-between">
				<section-title title="Immediate Safety Assessment" />
			</div>
			<div class="grid grid-cols-6 md:gap-6 gap-8 mt-6">
				<div class="col-span-6 border-t border-gray-200"></div>

				<div class="grid grid-cols-6 col-span-6 md:gap-6 gap-3 px-4 md:py-6 py-4 border border-gray-200 rounded-lg">
					<div class="grid grid-cols-3 md:col-span-3 col-span-6 gap-3">
						<div class="col-span-1">
							<p class="font-bold">Case Number:</p>
						</div>
						<div class="col-span-2">
							<p>{{ item?.case_number }}</p>
						</div>
						<div class="col-span-1">
							<p class="font-bold">Full Name:</p>
						</div>
						<div class="col-span-2">
							<p>{{ item?.name }}</p>
						</div>
					</div>
					<div class="grid grid-cols-3 md:col-span-3 col-span-6 gap-3">
						<div class="col-span-1">
							<p class="font-bold">Date Initiated:</p>
						</div>
						<div class="col-span-2">
							<p>{{ item?.initiated_at }}</p>
						</div>
						<div class="col-span-1">
							<p class="font-bold">Assessment is for patient's sibling:</p>
						</div>
						<div class="col-span-2">
							<p>{{ item?.is_assessment_patient_sibling ? "Yes" : "No" }}</p>
						</div>
					</div>
					<div class="grid grid-cols-6 col-span-6 gap-3">
						<div class="col-span-1">
							<p class="font-bold">Appointments:</p>
						</div>
						<div class="col-span-5">
							<p>{{ item?.appointment }}</p>
						</div>
					</div>
				</div>

				<div class="grid grid-cols-6 col-span-6 px-4 py-6 border border-gray-200 rounded-lg md:gap-9 gap-8">
					<div class="col-span-6 -mb-2">
						<p class="text-sm font-bold">Safety Factor</p>
					</div>
					<div
						class="grid grid-cols-6 col-span-6 gap-6"
						v-for="(question, index) in questions"
						:key="question">
						<div class="md:col-span-3 col-span-6">
							<p class="font-medium text-neutral-600">
								{{ question.description }}
							</p>
						</div>
						<div class="flex items-center md:col-span-3 col-span-6">
							<div class="flex items-center space-x-6">
								<radio-group
									:options="safetyFactorOptions"
									:name="`caretaker_${index}`"
									readonly
									v-model="item.safety_assessment_questions[index]['status']" />
							</div>
						</div>
						<div class="col-span-6">
							<p class="block mb-3 font-semibold text-neutral-600">
								Information supporting saftey factor:
							</p>
							<p>
								{{ item.safety_assessment_questions[index]["supporting_information"] }}
							</p>
						</div>
					</div>
				</div>
				<div class="col-span-6">
					<p class="block mb-3 font-semibold text-neutral-600">Brief Family Background</p>
					<p>{{ item?.brief_family_background }}</p>
				</div>
				<div class="col-span-6">
					<p class="block mb-3 font-semibold text-neutral-600">Family Background</p>
					<p>{{ item?.family_background }}</p>
				</div>
				<div class="col-span-6 md:mb-0 -mb-5">
					<p class="text-sm font-bold">Safety Decision Plan</p>
				</div>
				<div class="col-span-6">
					<p class="text-sm font-bold">Risk Codes</p>
				</div>
				<div class="md:col-span-2 col-span-6 md:mb-0 -mb-5">
					<text-input
						:disabled="true"
						type="number"
						label="Total number of “Yes”"
						placeholder="#"
						name="number_yes"
						id="number_yes"
						v-model="item.yes_count" />
				</div>
				<div class="md:col-span-2 col-span-6 md:mb-0 -mb-5">
					<text-input
						:disabled="true"
						type="number"
						label="Total number of “No”"
						placeholder="#"
						name="number_no"
						v-model="item.no_count" />
				</div>
				<div class="md:col-span-2 col-span-6">
					<text-input
						:disabled="true"
						type="number"
						label="Total number of “Unknown”"
						placeholder="#"
						name="number_unknown"
						v-model="item.unknown_count" />
				</div>
				<div class="md:col-span-2 col-span-6" v-for="(riskCode, index) in riskCodes" :key="riskCode">
					<label
						for="action_plan-1"
						class="relative flex items-center h-full py-3 pl-3 pr-12 border rounded cursor-pointer border-neutral-100">
						<p class="text-xs font-medium text-neutral-600">
							{{ riskCode.description }}
						</p>
						<input
							:checked="item.risk_code_id == riskCode.id ? true : false"
							disabled
							type="radio"
							:name="`action_plan_${index}`"
							:id="`action_plan_${index}`"
							class="absolute z-10 w-6 h-6 border-gray-300 cursor-pointer top-3 right-3 text-primary-600 focus:ring-0 focus:ring-offset-0 focus:outline-0 focus:outline-offset-0 checked:border-primary-600" />
					</label>
				</div>
				<div class="col-span-6">
					<p class="block mb-3 font-semibold text-neutral-600">Immediate Safety Plan</p>
					<p>
						{{ item.immediate_safety_plan }}
					</p>
				</div>
				<div class="col-span-6">
					<p class="block mb-3 font-semibold text-neutral-600">
						Rationale of Overall Immediate Safety Assessment
					</p>
					<div  v-html="item.overall_risk_assessment" class="frm-description"></div>
				</div>
			</div>
		</div>
	</user-layout>
</template>
<script setup lang="ts">
import { ref, onMounted, watch, computed } from "vue";
import UserLayout from "@/layouts/UserLayout.vue";
import SectionTitle from "@/components/partials/SectionTitle.vue";
import ButtonComponent from "@/components/buttons/ButtonComponent.vue";
import TextInput from "@/components/inputs/TextInput.vue";
import RadioGroup from "@/components/inputs/RadioGroup.vue";
import { EyeIcon } from "@heroicons/vue/24/solid";
import { useRouter, useRoute } from "vue-router";
import SafetyAssessment from "@/classes/Cases/SafetyAssessment";
import { renderSelect, renderSelectV2 } from "@/interfaces/DropdownInterface";
import { SafetyAssessmentEdit } from "@/interfaces/Cases/SafetyAssessment";
import { SafetyAssessmentStatus } from "@/views/enums/SafetyAssessmentStatus";
import { useNetworkStore } from "@/store/network";
import DB from "@/classes/Databases/Crud";
import pickBy from "lodash/pickBy";
import CaseSafetyAssessmentForm from "@/classes/Forms/Cases/CaseSafetyAssessmentForm";
import CaseAssessmentStatus from "@/enums/CaseAssessmentStatus";
import FormOptions from "@/classes/Forms/FormOptions";
import { onIonViewWillEnter } from "@ionic/vue";
import PencilUnderlineIcon from "@/components/icons/pencilUnderlineIcon.vue";

const networkStore = useNetworkStore();
const isNetworkAvailable = computed(() => networkStore.isNetworkAvailable);
const db = new DB("case_immediate_safety_assessments");
const dbCase = new DB("cases");
const router = useRouter();
const route = useRoute();
const loaded = ref<boolean>(false);
const patientId = ref<number | null>(route.params.patient);
const caseId = ref<number | null>(route.params.case);
const assessmentId = ref<number | null>(route.params.assessment);
const questions = ref<renderSelect[]>([]);
const riskCodes = ref<renderSelect[]>([]);
const safetyFactorOptions = ref<renderSelectV2[]>([]);
const item = ref<SafetyAssessmentEdit>();
const offlineParams = ref<string>(route.query.offline);
const options = new FormOptions();

const template = (items: object): void => {
	item.value.safety_assessment_questions = questions.value?.map((item) => {
		const newForm = items?.safety_assessment_questions?.filter((question) => {
			if (question.question_id == item.id) {
				return {
					question_id: question.question_id,
					supporting_information: question.supporting_information,
					status: question.status,
				};
			}
		});
		return newForm.length > 0
			? newForm[0]
			: { question_id: item.id, supporting_information: null, status: SafetyAssessmentStatus.UNKNOWN };
	});
};

const getData = async (): void => {
	await SafetyAssessment.edit(
		patientId.value,
		caseId.value,
		assessmentId.value,
		pickBy({ ...{ isOfflineData: offlineParams.value ? true : false }, ...{ rows: 10 } }),
		isNetworkAvailable.value
	)
		.then(async (response: object) => {
			if (isNetworkAvailable.value) {
				safetyFactorOptions.value = response.data.status;
				questions.value = response.data.questions;
				riskCodes.value = response.data.riskCodes;
				item.value = response.data.item;
				template(response.data.item);
				storeAssessmentOffline(item.value);
			} else {
                if (!response.data.item.id) {
                    router.push(`/patient/${patientId.value}/cases/${caseId.value}/safety-assessment/create`);
                    return;
                }
				safetyFactorOptions.value = CaseAssessmentStatus;
				questions.value = await options.safetyAssessmentQuestions();
				riskCodes.value = await options.riskCodes();
				await retrieveAssessmentOffline(response);
			}
			loaded.value = true;
		})
		.catch((error: object) => console.log(error));
};

const storeAssessmentOffline = async (response: object): Promise<object> => {
	const result = await dbCase.edit(caseId.value, false, 'cases');

	if (result.data.item.length == undefined) {
		const assessmentForm = new CaseSafetyAssessmentForm(item.value, result.data.item);
        await db.delete({id: item.value.id}, 'case_immediate_safety_assessments');
        await db.insert(assessmentForm, null, true, 'case_immediate_safety_assessments')
	}
};

const retrieveAssessmentOffline = async (response: object) => {
    if (response.data.item.length === undefined) {
        const content = JSON.parse(response.data.item.content);
        item.value = {
            ...content,
            ...{id: response.data.item.id, cases_id: response.data.item.cases_id, name: `${content.last_name}, ${content.first_name}` }
        };
    }

	template(item.value);
};

const generatePDFAccess = ():void => {
	SafetyAssessment
		.generatePDFAccess(
			patientId.value,
			caseId.value,
			assessmentId.value,
			isNetworkAvailable.value
		)
		.then(({data}) => {
			if(data.token){
				downloadPDF(data.token)
			}
		}).catch((errors) => {
			console.log(errors);
		})

}
const downloadPDF = (token:string):void => {
	SafetyAssessment
		.downloadPDF(
			assessmentId.value,
			token,
			isNetworkAvailable.value
		)
		.then(({data}) => {
			console.log(data);
		}).catch((errors) => {
			console.log(errors);
		})

}


// watch(
// 	() => isNetworkAvailable.value,
// 	(val) => {
// 		console.log("Watcher - Network: " + val);
// 		getData();
// 	}
// );

onIonViewWillEnter(() => getData());
// onMounted(() => getData());
</script>
