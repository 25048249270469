<template>
	<div class="w-full">
		<section-title fontSmall title="Personnel Information">
			<p class="text-sm font-medium text-neutral-600">
				Please fill out all the required fields with the red asterisk (<span class="text-red-600">*</span>)
			</p>
		</section-title>
		<form class="grid grid-cols-2 gap-6 mt-6">
			<div class="col-span-2 border-t border-gray-200"></div>

			<div class="col-span-2">
				<text-input
					type="email"
					label="Email Address"
					placeholder="Email address"
					name="email_address"
					id="email"
					:disabled="disabled"
					required
					v-model="form.email"
					:error="errors?.email" />
			</div>
			<div class="col-span-2">
				<text-input
					type="text"
					label="Last Name"
					placeholder="Last name"
					name="last_name"
					id="last_name"
					:disabled="disabled"
					required
					v-model="form.last_name"
					:error="errors?.last_name" />
			</div>
			<div class="col-span-2">
				<text-input
					type="text"
					label="First Name"
					placeholder="First name"
					name="first_name"
					id="first_name"
					:disabled="disabled"
					required
					v-model="form.first_name"
					:error="errors?.first_name" />
			</div>
			<div class="col-span-2">
				<text-input
					type="text"
					label="Middle Name"
					placeholder="Middle name"
					name="middle_name"
					id="middle_name"
					:disabled="disabled"
					v-model="form.middle_name"
					:error="errors?.middle_name" />
			</div>
			<div class="col-span-2">
				<text-input
					type="tel"
					label="Contact Number"
					placeholder="Contact number"
					name="contact_number"
					id="mobile_number"
					:add_on_left="true"
					add_on_text="+63"
					:maxlength="10"
					:disabled="disabled"
					required
					@keypress="numberonly"
					v-model="form.mobile_number"
					:error="errors?.mobile_number" />
			</div>
			<div class="col-span-2">
				<text-input
					type="text"
					label="Home Address"
					placeholder="Home address"
					name="home_address"
					id="home_address"
					:disabled="disabled"
					v-model="form.home_address"
					:error="errors?.home_address" />
			</div>
			<div class="col-span-2">
				<label for="temporary_staff_trainee" class="block mb-5 text-xs font-semibold text-neutral-600"
					>Temporary Staff/Trainee?</label
				>
				<checkbox-input
					id="is_temporary"
					name="temporary_staff_trainee"
					label="Yes"
					:disabled="disabled"
					:readonly="disabled"
					:checked="form.is_temporary"
					v-model="form.is_temporary"
					:error="errors?.is_temporary" />
			</div>
			<div class="col-span-2" v-if="form.is_temporary">
				<multi-select searchable
					:options="departments"
					id="department_id"
					name="department"
					label="Department (if temporary staff/trainee)"
					placeholder="Select.."
					:disabled="disabled"
					:readonly="disabled"
					required
					v-model="form.department_id"
					:error="errors?.department_id" />
			</div>
			<div class="col-span-2">
				<multi-select searchable
					:options="offices"
					id="office_id"
					name="office"
					label="Office"
					placeholder="Select.."
					:disabled="disabled"
					:readonly="disabled"
					required
					v-model="form.office_id"
					:error="errors?.office_id" />
			</div>
			<div class="col-span-1">
				<multi-select searchable
					v-if="canChangeRole"
					:options="userGroups"
					id="role_id"
					name="user_group"
					label="User Group (Role)"
					placeholder="Select.."
					:disabled="disabled" 
					:readonly="disabled"
					required
					v-model="form.role_id"
					:error="errors?.role_id" />
			</div>
			<div :class="[
				canChangeRole ? 'col-span-1' : 'col-span-2'
			]">
				<multi-select searchable
					:options="positions"
					id="position_id"
					name="position"
					label="Position"
					placeholder="Select.."
					:disabled="disabled"
					required
					v-model="form.position_id"
					:error="errors?.position_id" />
			</div>
			<slot></slot>
		</form>
	</div>
</template>
<script setup lang="ts">
import SectionTitle from "@/components/partials/SectionTitle.vue";
import TextInput from "@/components/inputs/TextInput.vue";
import MultiSelect from "@/components/inputs/MultiSelect.vue";
import CheckboxInput from "@/components/inputs/CheckboxInput.vue";
import { numberonly } from "@/components/composables/useNumberOnly";
import { ref, onMounted } from "vue";

const props = defineProps({
	form: {
		type: Object,
		required: true,
	},
	errors: {
		type: Object,
		required: true,
	},
	departments: {
		type: Array,
		required: true,
	},
	positions: {
		type: Array,
		required: true,
	},
	userGroups: {
		type: Array,
		required: true,
	},
	offices: {
		type: Array,
		required: true,
	},
	disabled: {
		type: Boolean,
		default: false,
	},
	canChangeRole: {
		type: Boolean,
		default: true,
	},
});

const form = ref<object>(props.form);

const userOffice = ref();
userOffice.value =  JSON.parse(localStorage.getItem('user')); 

onMounted(()=> {
	form.value.office_id = userOffice.value.office_id;
})

</script>
