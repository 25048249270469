<template>
	<user-layout mainCustomClass="intervention-generator">
		<div class="-mx-6 w-[calc(100%+48px)]">
			<div
				class="fixed top-[72px] left-0 z-10 bg-white px-6 py-4 shadow-[0_1px_0px_0px_rgba(0,0,0,0.08)] flex justify-between items-center w-full">
				<a @click="router.replace('/settings/intervention-types/index')">
					<button-component customClass="tertiary text-black hover:text-primary-600 transition">
						<!-- <img src="assets/icon/ic-arrow-left.svg" alt="back-arrow" class="object-contain w-6 h-6 mr-2" /> -->
						<arrow-left-icon class="object-contain w-6 h-6 mr-2" />
						<span class="text-sm font-semibold">Back</span>
					</button-component>
				</a>

				<tab-component :tabs="tabs" />

				<div class="w-[220px] flex justify-end space-x-2">
					<button-component
						v-if="newForm"
						customClass="secondary"
					>
						<span>Save & Display</span>
					</button-component>
					<button-component
						v-if="!newForm"
						:disabled="formOpen"
						@click="save()"
						customClass="secondary"
					>
						<span>Save Form</span>
					</button-component>
				</div>

			</div>
			<div 
				v-if="!loading"
				class="mt-[72px]" 
			>
				<div class="bg-primary-100">
					<FormSection
						:form="form"
						:formFieldTypes="formFieldTypes"
						:existingDropdowns="existingDropdowns"
						@open="formOpen = true"
						@close="formOpen = false"
					/>
				</div>
			</div>
		</div>

		<error-modal
			:show="showError"
			@confirm="showError = false"
			action-text="Close"
			title="Error!"
			:content="errorMessage"
		/>

		<page-loader :show="loading"/>
		<page-loader :show="saving"/>

	</user-layout>
</template>
<script setup lang="ts">
import { computed, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import UserLayout from "@/layouts/UserLayout.vue";
import ButtonComponent from "@/components/buttons/ButtonComponent.vue";
import TabComponent from "@/components/partials/TabComponent.vue";
import FormSection from "./FormSection.vue";
import ErrorModal from "@/components/modals/ErrorModal.vue";

import { errorResponse } from "@/helpers/errorMessage";
import throttle from "lodash/throttle";
import pickBy from "lodash/pickBy";

import PageLoader from "@/components/loaders/PageLoader.vue"

import { filters } from "@/interfaces/pages/FilterPageInterface";

import InterventionTypeService from "@/classes/Settings/InterventionTypeService.ts"
import ArrowLeftIcon from "@/components/icons/arrowLeftIcon.vue";

const route = useRoute();
const router = useRouter();


const parentForm = ref<number>(route?.params?.form);

const form = ref<any>({});

const formFieldTypes = ref<any>({});
const existingDropdowns = ref<any>({});

const showFilter = ref<boolean>(false);
const loading = ref<boolean>(false);
const saving = ref<boolean>(false);
const successMessage = ref<string>("Data has been successfully updated!");

const errors = ref<any>();
const filterSearch = ref<any>(route.query.query);
const showConfirmation = ref<boolean>(false);
const showSuccess = ref<boolean>(false);

const newForm = ref<boolean>(false);
const formOpen = ref<boolean>(false);
	
const errorMessage = ref<string>('');
const showError = ref<boolean>(false);
/**
 * METHODS
 */
const tabs = ref<{ name: string; count: number; tab: string; href: string; allowed: boolean }[]>([
	{
		name: "Form Builder",
		count: 0,
		tab: "",
		href: `/settings/intervention-types/view`,
		allowed: true,
	}
]);

const save = (): void => {

	saving.value = true;
	
	InterventionTypeService
		.store(
			parentForm.value,
			{ form: form.value }
		)
		.then((response: any) => {
			redirectSuccess()
		})
		.catch((error: any) => {
			showError.value = true;
            errors.value = error.response.data.errors;
			errorMessage.value = Object.values(errors.value)[0]
		})
		.finally(() => saving.value = false);
};

const redirectSuccess = (): void => {
	showSuccess.value = false;
	router.replace("/settings/intervention-types/index");
};

/**
 * METHODS
 */
const getData = (): void => {

	loading.value = true;
	
	InterventionTypeService
		.create(parentForm.value)
		.then((response: any) => {
			form.value = response.data.form;
			formFieldTypes.value = response.data.formFieldTypes;
			existingDropdowns.value = response.data.existingDropdowns
		})
		.catch((error: any) => {
			console.log(error);
		})
		.finally(() => loading.value = false);
};

onMounted(() => {
	getData();
});
</script>
