import { CapacitorSQLite, SQLiteConnection, SQLiteDBConnection, capSQLiteSet } from "@capacitor-community/sqlite"
import { Capacitor } from "@capacitor/core"
import { SchemaOptions } from "./Interfaces/SchemaInterfaces"
import StorageInterface from "./Interfaces/StorageInterface"
import { applyPolyfills, defineCustomElements } from "jeep-sqlite/loader"
export default class PrxSqlite implements StorageInterface {
    
    /*
    |--------------------------------------------------------------------------
    | @Properties
    |--------------------------------------------------------------------------
    */

    platform:string
    sqlite:SQLiteConnection
    database:SQLiteDBConnection

    /*
    |--------------------------------------------------------------------------
    | @Construct
    |--------------------------------------------------------------------------
    */

    constructor() {
        this.platform = Capacitor.getPlatform()
        this.sqlite = new SQLiteConnection(CapacitorSQLite)
        this.database = new SQLiteDBConnection('', true, {})
    }

    /*
    |--------------------------------------------------------------------------
    | @Getters
    |--------------------------------------------------------------------------
    */

    get onBrowser():boolean { return this.platform === 'web' }
    get databaseName():string { return this.database.getConnectionDBName() }

    /*
    |--------------------------------------------------------------------------
    | @Public
    |--------------------------------------------------------------------------
    */
    
    /**
     * Initialize sqlite.
     * 
     * @param   SchemaOptions options
     * @return  Promise<void>
     */
    async connect(database:string):Promise<void> {
        await this.#initJeepSQLite()
        await this.#initDatabase(database)
    }

    /**
     * Create database.
     * 
     * @param   SchemaOptions options
     * @return  Promise<void>
     */
    async create(options:SchemaOptions):Promise<void> {
        await this.#initMigration(options)
    }

    /**
     * Close database connection.
     * 
     * @param   string database
     * @return  Promise<void>
     */
    async close(database:string):Promise<void> {
        await this.sqlite.closeConnection(database, false)
    }

    /**
     * Check if table exists.
     * 
     * @param   string key
     * @return  Promise<boolean>
     */
    async keyExists(key:string):Promise<boolean> {
        return (await this.database.isTable(key)).result || false
    }

    /**
     * Select ALL from the table.
     * 
     * @param   string table
     * @return  Promise<Array<any>>
     */
    async get(table:string):Promise<Array<any>> {
        return await this.select(`SELECT * FROM ${table}`)
    }

    /**
     * Select.
     * 
     * @param   string statement
     * @return  Promise<Array<any>>
     */
    async select(statement:string):Promise<Array<any>> {
        const result = await this.database.query(statement)
        return result.values || []
    }

    /**
     * Insert one record.
     * 
     * @param   string table
     * @param   object params
     * 
     * @return  Promise<void>
     */
    async insert(table:string, params:object):Promise<void> {
        const { statement, values } = this.#buildInsertStatement(table, params)
        await this.database.run(statement || '', values, false)
        await this.persist(this.databaseName)
    }

    /**
     * Update one record.
     * 
     * @param   string table
     * @param   object params
     * 
     * @return  Promise<void>
     */
    async update(table:string, params:object, condition:object):Promise<void> {
        const statement = this.#buildUpdateStatement(table, params, condition)

        await this.database.run(statement)
        await this.persist(this.databaseName)
    }

     /**
     * Delete one record.
     * 
     * @param   string table
     * @param   object params
     * 
     * @return  Promise<void>
     */
    async delete(table:string, condition:object):Promise<void> {
        const statement = this.#buildDeleteStatement(table, condition)

        await this.database.execute(statement, false)
        await this.persist(this.databaseName)
    }

    /**
     * Insert multiple records.
     * 
     * @param   string table
     * @param   Array<object> inserts
     * 
     * @return  Promise<void>
     */
    async set(table:string, inserts:Array<object>):Promise<void> {
        await this.database.executeSet(
            inserts.map(params => this.#buildInsertStatement(table, params)), false
        )
        await this.persist(this.databaseName)
    }

    /**
     * Clear database.
     * 
     * @param   Array<object> tables
     * @return  Promise<void>
     */
    async drop(tables:Array<string>):Promise<void> {
        await this.database.execute(
            tables.map(table => this.#buildDropStatement(table)).join(' '), false
        )
        await this.persist(this.databaseName)
    }

    /**
     * Clear table.
     * 
     * @param   string table
     * @return  Promise<void>
     */
    async clear(table:string):Promise<void> {
        await this.database.execute(this.#buildDropStatement(table), false)
        await this.persist(this.databaseName)
    }

    /**
     * Execute any query.
     * 
     * @param   string table
     * @return  Promise<void>
     */
    async execute(query: string): Promise<void> {
        await this.database.execute(query, true)
        await this.persist(this.databaseName)
    }

    /**
     * Persist database changes.
     * 
     * @param   string database
     * @return  Promise<void>
     */
    async persist(database:string):Promise<void> {
        if(this.onBrowser) {
            await this.sqlite.saveToStore(database)
        }
    }

    /*
    |--------------------------------------------------------------------------
    | @Private
    |--------------------------------------------------------------------------
    */

    /**
     * Initialize jeep-sqlite wrapper.
     * 
     * @return  Promise<void>
     */
    async #initJeepSQLite():Promise<void> {
        await applyPolyfills()
        await defineCustomElements(window)
    }

    /**
     * Initialize SQLiteDBConnection.
     * 
     * @param   string name
     * @return  Promise<void>
     */
    async #initDatabase(name:string):Promise<void> {
        
        if(this.onBrowser) {
            const el = document.createElement('jeep-sqlite')
            document.body.appendChild(el)
            await customElements.whenDefined('jeep-sqlite')
            await this.sqlite.initWebStore()
        }

        const ret = await this.sqlite.checkConnectionsConsistency()
        const isConn = await this.sqlite.isConnection(name, false)
        const db:SQLiteDBConnection = ret.result && isConn.result
            ? await this.sqlite.retrieveConnection(name, false)
            : await this.sqlite.createConnection(name, true, 'encryption', 1, false)

        await db.open()

        this.database = db
    }

    /**
     * Migrate database schema.
     * 
     * @param   SchemaOptions options
     * @return  Promise<void>
     */
    async #initMigration(options:SchemaOptions):Promise<void> {
        if(await this.#validateSchema(options)) {
            await this.#importSchema(options)
        }
    }

    /**
     * Validate schema.
     * 
     * @param   SchemaOptions options
     * @return  Promise<boolean>
     */
    #validateSchema(options:SchemaOptions):Promise<boolean> {
        return this.sqlite
            .isJsonValid(JSON.stringify(options))
            .then(value => {
                const result = value.result || false
                if(!result) {
                    console.error(`isJsonValid: "${options.database} is not valid"`)
                }
                return result
            })
    }

    /**
     * Import schema.
     * 
     * @param   SchemaOptions options
     * @return  Promise<void>
     */
    #importSchema(options:SchemaOptions):Promise<void> {
        return this.sqlite
            .importFromJson(JSON.stringify(options))
            .then(result => {
                console.log(result.changes);
                if(result.changes && result.changes.changes && result.changes.changes < 0) {
                    console.error(`importFromJson: "${options.mode}" failed`)
                }
            })
    }

    /**
     * Build insert SQL statement.
     * 
     * @param   string table
     * @param   object params
     * 
     * @return  capSQLiteSet
     */
    #buildInsertStatement(table:string, params:object):capSQLiteSet {
        const statement = `INSERT INTO ${table} (${this.#buildColumns(params)}) VALUES (${this.#buildBlindValues(params)});`
        const values = this.#buildValues(params)
        return { statement, values }
    }

    /**
     * Build update SQL statement.
     * 
     * @param   string table
     * @param   object params
     * 
     * @return  capSQLiteSet
     */
    #buildUpdateStatement(table: string, params: object, condition:object):string {
        return `UPDATE ${table} SET ${this.#buildUpdateVars(params)} WHERE (${this.buildUpdateCondition(condition)});`
    }

    /**
     * Build delete SQL statement.
     * 
     * @param   string table
     * @param   object params
     * 
     * @return  capSQLiteSet
     */
    #buildDeleteStatement(table: string, condition:object):string {
        return `DELETE FROM ${table} WHERE ${this.buildUpdateCondition(condition)};`
    }

    /**
     * Build update variables.
     * 
     * @param   object params
     * @return  string
     */
    #buildUpdateVars(params: object): string {
        return Object.entries(params).map(([key, value]) => key + ' = ' + value).join()
    }

    /**
     * Build condition statement.
     * 
     * @param   object params
     * @return  string
     */
    buildUpdateCondition(params: object): string {
        return Object.entries(params).map(([key, value]) => key + ' = ' + value).join(' AND ')
    }

    /**
     * Build columns string.
     * 
     * @param   object params
     * @return  string
     */
    #buildColumns(params:object):string {
        return Object.keys(params).map(key => key).join()
    }

    /**
     * Build blind values string.
     * 
     * @param   object params
     * @return  string
     */
    #buildBlindValues(params:object):string {
        return Object.keys(params).map(() => '?').join()
    }

    /**
     * Build values array.
     * 
     * @param   any params
     * @return  Array<any>
     */
    #buildValues(params:any):Array<any> {
        return Object.keys(params).map((key:string) => params[key])
    }

    /**
     * Build drop string.
     * 
     * @param   string table
     * @return  string
     */
    #buildDropStatement(table:string):string {
        return `DROP TABLE IF EXISTS ${table};`
    }

}