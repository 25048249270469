<template>
	<user-layout>
		<div class="-mx-6 w-[calc(100%+48px)]">
			<div
				class="fixed top-20 left-0 z-10 bg-white px-6 py-4 shadow-[0_1px_0px_0px_rgba(0,0,0,0.08)] flex justify-between items-center w-full">
				<a @click="router.replace('/settings/telecpu-locations/index')">
					<button-component customClass="tertiary text-black hover:text-primary-600 transition">
						<!-- <img src="assets/icon/ic-arrow-left.svg" alt="back-arrow" class="object-contain w-6 h-6 mr-2" /> -->
						<arrow-left-icon class="object-contain w-6 h-6 mr-2" />
						<span class="text-sm font-semibold">Back</span>
					</button-component>
				</a>

				<tab-component :tabs="tabs" />

				<div class="w-[92px]">
					<a
						v-if="route.query.tab === 'offices'"
						@click="router.replace(`/settings/telecpu-locations/${locationId}/office/create`)">
						<button-component>
							<PlusIcon class="w-4 h-4 text-primary-600 stroke-[3px] -ml-2 mr-1 icon" />
							<span>Create</span>
						</button-component>
					</a>
				</div>
			</div>
			<div class="mx-6 mt-20 mb-6">
				<div v-if="route.query.tab != 'offices'" class="max-w-[500px] mx-auto py-6">
					<information-form-section :form="form" :errors="errors">
						<div class="col-span-2">
							<div class="flex items-center justify-end w-full space-x-3">
								<a @click="router.replace(`/settings/telecpu-locations/index`)">
									<button-component btnWidth="quinary">Cancel</button-component>
								</a>

								<button-component btnWidth="primary" @click="save()">Save Changes</button-component>
							</div>
							<confirmation-modal
								:typeTwo="true"
								:show="showConfirmation"
								@cancel="showConfirmation = false"
								@confirm="
									showSuccess = true;
									showConfirmation = false;
								"
								cancel-text="Cancel"
								action-text="Confirm"
								title="Update Record"
								content="Are you sure you wish to update this record?" />
							<success-modal
								:show="showSuccess"
								@confirm="redirectSuccess()"
								action-text="Close"
								title="Record Updated!"
								:content="successMessage" />
						</div>
					</information-form-section>
				</div>
				<div class="py-6">
					<div v-if="route.query.tab === 'offices'" class="border rounded-lg border-neutral-100">
						<div class="flex items-center justify-between p-6 border-b tabs border-neutral-100">
							<div class="flex items-center space-x-4">
								<tab-component
									:tabs="dataTabletabs"
									:return-object="true"
									@update:tab="(value: string) => updateTab(value.datatab)" />
							</div>
						</div>
						<div v-if="filters.tab != 'activity_logs'">
							<data-table
								:hasSearch="true"
								searchPlaceholder="Search center"
								dataTableClass="border-0"
								:headers="headers"
								:no-action="false"
								:count="items.total"
								@update:searchText="(value: string) => (filterSearch = value)"
								tableClass="header-frm-table">
								<template #topContent>
									<div>
										<button
											type="button"
											class="flex items-center px-3 py-2 space-x-1 border border-solid rounded border-neutral-100 hover:bg-primary-100 transition w-max"
											@click="showFilter = true">
											<span class="text-xs font-medium text-neutral-600">Showing:</span>
											<span class="text-xs font-semibold">All Records</span>
										</button>
										<FilterPanel
											:show="showFilter"
											@close="showFilter = false"
											@apply:reset="applyReset()"
											@apply:filters="applyFilters()">
											<template #fields>
												<div class="space-y-5">
													<date-picker
														id="date_created"
														name="date_created"
														label="Date Created"
														:range="true"
														v-model="filterDate" />
												</div>
											</template>
										</FilterPanel>
									</div>
								</template>
								<template v-slot:body>
									<template v-for="item in items.data" :key="item">
										<tr>
											<td class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
												{{ item.id }}
											</td>
											<td class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
												{{ item.name }}
											</td>
											<td class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
												{{ item.created_at_formatted }}
											</td>
											<td class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
												<div class="flex items-center justify-center mx-auto">
													<template v-if="!filters.tab">
														<edit-button
															@click="
																router.replace(
																	`/settings/telecpu-locations/${locationId}/office/${item.id}/edit`
																)
															"></edit-button>
														<delete-button @click="showDeleteModal(item)" />
													</template>
													<template v-if="filters.tab == 'archived'" >
														<restore-button @click="showRestoreModal(item)" />
													</template>
												</div>
											</td>
										</tr>
									</template>
								</template>
								<template #tfoot>
									<!-- <div class="w-full px-6 border-t py-7">
										<minify-pagination
											:numRows="numRows"
											@update:rows="(value: any) => changeRows(value)"
											@update:page="(value: number) => paginate(value)"
											:current-page="page"
											:last-page="lastPage"></minify-pagination>
									</div> -->
									<!-- DELETE MODAL -->
									<confirmation-modal
										:typeTwo="true"
										:show="showConfirmation"
										@cancel="showConfirmation = false"
										@confirm="deleteRecord()"
										cancel-text="Cancel"
										action-text="Confirm"
										title="Delete Record"
										content="Are you sure you wish to delete this record?" />
									<success-modal
										:show="showSuccess"
										@confirm="closeDeleteModal()"
										action-text="Close"
										title="Record Deleted!"
										content="Selected record has been successfully deleted!" />
									<!-- RESTORE MODAL -->
									<confirmation-modal
										:typeTwo="true"
										:show="showRestoreConfirmation"
										@cancel="showRestoreConfirmation = false"
										@confirm="restoreRecord()"
										cancel-text="Cancel"
										action-text="Confirm"
										title="Restore Record"
										content="Are you sure you wish to restore this record?" />
									<success-modal
										:show="showRestoreSuccess"
										@confirm="closeRestoreModal()"
										action-text="Close"
										title="Record Restored!"
										content="Selected record has been successfully restored!" />
								</template>
							</data-table>
						</div>

						<ActivityLogsTable
							v-if="filters.tab == 'activity_logs'"
							:items="items"
						/>

						<div class="w-full px-6 border-t py-7">
							<simple-pagination 
								:numRows="filters.rows" 
								:currentPage="items.current_page" 
								:last-page="items.last_page" 
								@update="(value: any) => paginate(value)"
							/>
						</div>
					</div>
				</div>
			</div>
		</div>

		<page-loader :show="loading"/>

	</user-layout>
</template>
<script setup lang="ts">
import { computed, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import UserLayout from "@/layouts/UserLayout.vue";
import EditButton from "@/components/buttons/EditButton.vue";
import RestoreButton from "@/components/buttons/RestoreButton.vue";
import DeleteButton from "@/components/buttons/DeleteButton.vue";
import DataTable from "@/components/partials/DataTable.vue";
import FilterPanel from "@/components/partials/FilterPanel.vue";
// import MinifyPagination from "@/components/partials/MinifyPagination.vue";
import ButtonComponent from "@/components/buttons/ButtonComponent.vue";
import DatePicker from "@/components/inputs/DatePicker.vue";
import TabComponent from "@/components/partials/TabComponent.vue";
import { PlusIcon } from "@heroicons/vue/24/outline";
import InformationFormSection from "./Informations/FormSection.vue";
import ConfirmationModal from "@/components/modals/ConfirmationModal.vue";
import SuccessModal from "@/components/modals/SuccessModal.vue";
import TeleCpuLocationService from "@/classes/Settings/TeleCpuLocationService";
import TeleCpuOfficeService from "@/classes/Settings/TeleCpuOfficeService";
import { errorResponse } from "@/helpers/errorMessage";
import throttle from "lodash/throttle";
import pickBy from "lodash/pickBy";

import PageLoader from "@/components/loaders/PageLoader.vue"
import SimplePagination from "@/components/partials/SimplePagination.vue";
import ActivityLogsTable from "@/views/activity-logs/ActivityLogsTable.vue"

import { filters, items } from "@/interfaces/pages/FilterPageInterface";
import assignValues from "@/helpers/assignValues"
import ArrowLeftIcon from "@/components/icons/arrowLeftIcon.vue";

const route = useRoute();
const router = useRouter();
const dataroute = useRoute();
const showFilter = ref<boolean>(false);
const loading = ref<boolean>(false);
const offices = ref(null);
const successMessage = ref<string>("Data has been successfully updated!");
const form = ref<{ name: string }>({
	name: "",
});
const tab = ref<any>(route.query.tab);
const datatab = ref<any>(route.query.datatab);
const errors = ref<any>();
const locationId = computed<any>(() => route.params.location);
const filterSearch = ref<any>(route.query.query);
const page = ref<any>(route.query.page);
const lastPage = ref<any>(null);
const rows = ref<any>(route.query.rows || 5);
const numRows = computed(() => (rows.value ? rows.value + " Rows" : "All"));
const showConfirmation = ref<boolean>(false);
const showSuccess = ref<boolean>(false);
const showRestoreConfirmation = ref<boolean>(false);
const showRestoreSuccess = ref<boolean>(false);
const officeId = ref<number>(0);
const filterDate = ref<any>(route.query.date);
const allCount = ref(0)

const tabs = ref<{ name: string; count: number; tab: string; href: string; allowed: boolean }[]>([
	{
		name: "Information",
		count: 0,
		tab: "",
		href: `/settings/telecpu-locations/${locationId.value}/view`,
		allowed: true,
	},
	{
		name: "Offices",
		count: 0,
		tab: "offices",
		href: `/settings/telecpu-locations/${locationId.value}/view?tab=offices`,
		allowed: true,
	},
]);

const dataTabletabs = computed<
	{ name: string; count: number; tab: string; datatab: string | null; allowed: boolean }[]
>(() => [
	{
		name: "All",
		count: offices.value?.allItemsCount,
		tab: "offices",
		datatab: null,
		allowed: true,
	},
	{
		name: "Archived",
		count: offices.value?.archivedItemsCount,
		tab: "offices",
		datatab: "archived",
		
		allowed: true,
	},
	{
		name: "Activity logs",
		count: offices.value?.activityLogsCount,
		tab: "offices",
		datatab: "activity_logs",
		allowed: true,
	},
]);

const headers: { text: string; haveSort?: boolean }[] = [
	{ text: "" },
	{ text: "Offices" },
	{ text: "Date Created", haveSort: true },
];

const save = (): void => {
	TeleCpuLocationService.update(
		{
			location: route.params.location,
		},
		form.value
	)
		.then((response: any) => {
			showConfirmation.value = true;
			successMessage.value = response.data.message;
		})
		.catch((error: any) => (errors.value = errorResponse(error.response?.data?.errors)));
};

const redirectSuccess = (): void => {
	showSuccess.value = false;
	router.replace("/settings/telecpu-locations/index");
};

/**
 * METHODS
 */
const getData = (): void => {
	loading.value = true

	TeleCpuLocationService.edit({location: route.params.location}, pickBy(filters.value))
		.then(({ data }) => {

			form.value.name = data.item.name;
			offices.value = data.offices;
			
			items.value = data.offices.items;
			filters.value = assignValues(filters.value, data.offices);
			loading.value = false
			// dataTabletabs.value[1].count = response.data.archivedItemsCount;
			// dataTabletabs.value[2].count = response.data.activityLogsCount;
		})
		.catch((error: any) => console.log(error));
};

const reloadPage = async () => {
	return await router.push({
		path: `/settings/telecpu-locations/${locationId.value}/view`,
		query: pickBy({
			query: filterSearch.value,
			page: page.value,
			rows: rows.value,
			tab: tab.value,
			datatab: datatab.value,
			date: filterDate.value,
		}),
	});
};

const applyFilters = (search: any = null) => {
	filters.value.query = search;
	showFilter.value = false;

	getData();
};

const applyReset = () => {
	filterDate.value = "";
	reloadPage().then(() => {
		getData();
		showFilter.value = false;
	});
};

const paginate = ({ page, rows }): void => {
	filters.value = Object.assign(filters.value, {
		page, rows
	})
	getData();
};

const updateTab = (currentTab: any) => {

	// const identify = typeof currentTab === "object";
	// tab.value = identify ? currentTab.tab : currentTab;
	filters.value.tab = currentTab;
	filters.value.page = 1;
	// filterSearch.value = "";
	// datatab.value = identify ? currentTab.datatab : "";
	applyFilters();
};

/**
 * MODALS
 */
const showDeleteModal = (item: any): void => {
	showConfirmation.value = true;
	officeId.value = item.id;
};

const closeDeleteModal = (): void => {
	showSuccess.value = false;
};

const deleteRecord = (): void => {
	TeleCpuOfficeService.delete({
		location: locationId.value,
		telcpu: officeId.value,
	})
		.then(() => {
			showSuccess.value = true;
			showConfirmation.value = false;
			applyFilters();
		})
		.catch((error) => console.log(error));
};

const showRestoreModal = (item: any): void => {
	showRestoreConfirmation.value = true;
	officeId.value = item.id;
};

const closeRestoreModal = (): void => {
	showRestoreSuccess.value = false;
};

const restoreRecord = (): void => {
	TeleCpuOfficeService.restore({
		location: locationId.value,
		telcpu: officeId.value,
	})
		.then(() => {
			showRestoreSuccess.value = true;
			showRestoreConfirmation.value = false;
			applyFilters();
		})
		.catch((error) => console.log(error));
};

/**
 * WATCHERS
 */
watch(
	filterSearch,
	throttle((val: string) => {
		applyFilters(val);
	}, 1000)
);

onMounted(() => {
	getData();
});
</script>
