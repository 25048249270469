import http from "@/axios";
import { Intake } from "@/interfaces/Cases/Intake";
import CaseIntakeForm from "../Forms/Cases/CaseIntakeForm";
import DB from "@/classes/Databases/Crud";
import SqliteRecordChecker from "../Forms/SqliteRecordChecker";

const db = new DB("case_intakes");
const dbCase = new DB("cases");
const checkRecord = new SqliteRecordChecker();
class IntakeService implements Intake {
	async show(patient: number, patientCase: number, intake: number, payload: any, networkStatus = true): Promise<any> {
		if (networkStatus) {
			return await http.get(`patients/${patient}/cases/${patientCase}/intake`, { params: payload });
		}

        const fetch = await checkRecord.init(db, intake, false, 'case_intakes');

		return fetch.result;
	}

	async create(patient: number, patientCase: number, payload: object, networkStatus = true): Promise<any> {
		if (networkStatus) {
			return await http.get(`patients/${patient}/cases/${patientCase}/intake/create`, { params: payload });
		}
	}

	async store(patient: number, patientCase: number, payload: any, networkStatus = true): Promise<any> {
		if (networkStatus) {
			return await http.post(`patients/${patient}/cases/${patientCase}/intake`, payload);
		}

        const fetch = await checkRecord.init(dbCase, patientCase, false, 'cases');
		const result = fetch.result;
        payload.isOfflineData = fetch.isOfflineData;
        
		if (result.data.item.length == undefined) {
			const form = new CaseIntakeForm({}, {});
			const createForm = form.createForm(payload, result.data.item);
			return db.store(createForm, true, false, 'case_intakes');
		}
	}

	async edit(patient: number, patientCase: number, intake: number, payload: any, networkStatus = true): Promise<any> {
		if (networkStatus) {
			return await http.get(`patients/${patient}/cases/${patientCase}/intake/${intake}/edit`, {
				params: payload,
			});
		}

		const fetch = await checkRecord.init(db, intake, false, 'case_intakes');

		return fetch.result;
	}

	async update(
		patient: number,
		patientCase: number,
		intake: number,
		payload: any,
		networkStatus = true
	): Promise<any> {
		if (networkStatus) {
			return await http.put(`patients/${patient}/cases/${patientCase}/intake/${intake}/update`, payload);
		}
        
        const fetch = await checkRecord.init(db, intake, false, 'case_intakes');
		const result = fetch.result;
        payload.isOfflineData = fetch.isOfflineData;

		if (result.data.item.length == undefined) {
			const form = new CaseIntakeForm({}, {});
			const updateForm = form.updateForm(payload, result.data.item);
			return db.store(updateForm, true, false, 'case_intakes');
		}
	}
}

export default new IntakeService();
