import DatabaseInitialization from "@/database/DatabaseInitialization";
import Pagination from "./Pagination";
import { OfflineAction } from "@/views/enums/OfflineAction";
import { useRoute } from "vue-router";
import SqliteRecordChecker from "../Forms/SqliteRecordChecker";

const db = new DatabaseInitialization;
const checkRecord = new SqliteRecordChecker();

export default class DB { 
    
    table: string
    pagination: any

    constructor(table: string, pagination?:any) {
        this.table = table;
        
        if (pagination) {
            this.pagination = pagination
        } else {
            this.pagination = new Pagination(table);
        }

    }

    async paginateIndex(params: any, query = ''): Promise<any>{
        return await this.pagination.init(params, null, query);
    }

    async index(params: any,  where = '', paginate = true, table = ''): Promise<any>{
        if (table == '') {
            table = this.table;
        }
        await db.connect();
        const tableExists = await db.prx().keyExists(table);
        if (!tableExists) {
            console.log(`Table: ${table} does not exists`);
            return null;
        }

        if (paginate) {
            await db.connect();
            return await this.pagination.init(params, where);
        }

        if (where.toLowerCase().includes('where')) {
            where += ' AND deleted_at=""'
        } else {
            where = ' WHERE deleted_at=""'
        }

        const query = `SELECT * FROM ${table} ${where} ORDER BY id DESC`;

        await db.connect();
        const results = await db.prx().select(query);
        return {
            'data': {
                'items': results.length > 0 ? results : []
            },
        };
    }

    async edit(id: number, isOfflineData = false, table = ''): Promise<any> {
        if (table == '') {
            table = this.table;
        }
        let query = `SELECT * FROM ${table}`;
        query += isOfflineData ? ` WHERE offline_id = ${id || 0};` : ` WHERE id = ${id || 0};`;

        await db.connect();
        const tableExists = await db.prx().keyExists(table);
        if (!tableExists) {
            console.log(`Table: ${table} does not exists`);
            return null;
        }
        await db.connect();
        const results  = await db.prx().select(query);

        return {
            'data': {
                'item': results.length > 0 ? results[0] : []
            }
        };
    }

    async select(query: string, table = ''): Promise<any> {
        if (table == '') {
            table = this.table;
        }
        await db.connect();
        // console.log('running tables', query);
        return await db.prx().select(query);
    }

    async selectOfflineLatest(id = null, table = ''): Promise<any> {   
        if (table == '') {
            table = this.table;
        }     
        const where = id ? `WHERE id=${id}` : '';
        return await this.select(`SELECT * FROM ${table} ${where} ORDER BY id DESC LIMIT 1`);
    }

    async selectOnlineLatest(id = null, table = ''): Promise<any> {
        if (table == '') {
            table = this.table;
        }
        const where = id ? `WHERE id=${id}` : ''   
        return await this.select(`SELECT * FROM ${table} ${where} ORDER BY id DESC LIMIT 1`);
    }

    async insert(params: any, id: any = null, isDefault = false, table = ''): Promise<any> {
        await db.connect();
        
        table = table == '' ?  this.table : table;

        const tableExists = await db.prx().keyExists(table);

        if (!tableExists) {
            console.log(`Table: ${table} does not exists`);
            return null;
        }

        if (id) {
            // await db.prx().select(`DELETE FROM drafts WHERE table_source="${table}" AND table_id=${id}`);
            // await db.connect();
            // await db.prx().select(`DELETE FROM ${table`} WHERE id=${id}`);
            await db.prx().delete(table, {id: id});
        }        

        await db.prx().insert(table, params).catch((error:any) => console.log(error));

        const where = id ? ` WHERE id=${id}` : ``;

        const results  =  await db.prx().select(`SELECT * FROM ${table} ${where} ORDER BY id DESC LIMIT 1`);

        const data = results.length > 0 ? results[0] : null

        if (data) {
            const draftTables = [
                'patients', 'cases', 'case_companions', 'case_guardians', 'case_immediate_safety_assessments',
                'case_intakes', 'case_patient_addresses', 'case_perpetrators', 'perpetrators', 'intervention_data',
                'intake_personnal_informations', 'intake_person_related_to_cases', 'intake_contact_numbers', 'intake_email_address',
                'intake_incest_cases', 'case_immediate_safety_assessments', 'case_conferences', 'aggravating_factors', 'case_conference_recommendations', 
                'minutes', 'intervention_data'
            ];
    
            if (!isDefault) {
                for (const index in draftTables) {
                    if (draftTables[index] === table) {
                        await checkRecord.saveToDraft(table, data);
                    }
                }
            }
        }

        // console.log('running tables: INSERT METHOD', table, params);
        return data;
    }

    async store(params: any, savedRecord = false, isOfflineData = false, table = ''): Promise<void>{
        if (table == '') {
            table = this.table;
        }
        await db.connect();
        const tableExists = await db.prx().keyExists(table);
        if (!tableExists) {
            console.log(`Table: ${table} does not exists`);
            return;
        }
        // console.log('running tables: STORE METHOD', this.table, params);
        return await db.connect()
            .then(() => {
                let query = `SELECT * FROM ${table}`;
                query += isOfflineData ? ` WHERE offline_id = ${params.offline_id || 0};` : ` WHERE ID = ${params.id || 0};`;
                let deleteQuery = `DELETE FROM ${table}`;
                deleteQuery += isOfflineData ? ` WHERE offline_id = ${params.offline_id || 0};` : ` WHERE ID = ${params.id || 0};`;
                
                return db.prx().select(query).then((res:any) => {
                    if (res.length == 0) {
                        return db.prx().insert(table, params).then(async () => {
                            if (savedRecord) {
                                const latestRecord = await this.selectOfflineLatest();
                                if (latestRecord.length) {
                                    params.offline_id = latestRecord[0].offline_id;
                                    checkRecord.saveToDraft(table, params);
                                }
                            }
                        })
                    } else {
                        const item = res[0];
                        if (savedRecord) {

                            // save record triggered by actions of create, update & delete
                            return db.prx().select(deleteQuery).then((res:any) => {
                                return db.prx().insert(table, params).then(() => {
                                    checkRecord.saveToDraft(table, params);
                                })
                            });
                        } else {
                            // update record except drafts or not sync
                            if (item.action == OfflineAction.DEFAULT && item.is_sync == 0) {
                                return db.prx().select(deleteQuery).then((res:any) => {
                                    return db.prx().insert(table, params);
                                });                                
                            }                            
                        }                        
                    }
                })
            })
    }

    async set(params: any, savedRecord = false, table = ''): Promise<void>{
        await db.connect();
        
        table = table == '' ?  this.table : table;

        const tableExists = await db.prx().keyExists(table);
        
        if (!tableExists) {
            console.log(`Table: ${table} does not exists`);
            return;
        }
        // console.log('running tables: SET METHOD', this.table);
        return await db.connect()
            .then(() => {      
                params.map(async (item: any) => {

                    if( item?.id ) {
                        const deleteQuery = `DELETE FROM ${table} WHERE ID=${item.id}`;
                        const query = await db.prx().select(`SELECT * FROM ${table} WHERE ID=${item.id} AND is_sync=0 AND action=${OfflineAction.DEFAULT}`);
                        
                        if (query.length > 0) {
                            await db.prx().select(deleteQuery);
                            await db.prx().insert(table, item);
                        } else {
                            const checkIfExists = await db.prx().select(`SELECT * FROM ${table} WHERE ID=${item.id}`);
                            if (checkIfExists.length > 0) {
                                await db.prx().select(deleteQuery);
                            }
                            await db.prx().insert(table, item);                        
                        }
                    } else {
                        await db.prx().insert(table, item);          
                    }
                });
            })
    }

    async delete(condition:object, table = ''): Promise<any> {
        // if (table == '') {
        //     table = this.table;
        // }
        await db.connect();
        const tableExists = await db.prx().keyExists(table);
        if (!tableExists) {
            console.log(`Table: ${table} does not exists`);
            return null;
        }
        // console.log('running tables: DELETE METHOD', this.table, condition);
        await db.connect();
        const response = await db.prx().delete(table, condition);
        await checkRecord.saveToDraft(table, {}, true);

        return response;
    }


    async update(params: object, condition: object, table = ''): Promise<any> {
        table = table == '' ?  this.table : table;

        await db.connect();

        const tableExists = await db.prx().keyExists(table);

        if (!tableExists) {
            console.log(`Table: ${table} does not exists`);
            return [];
        }

        const response = await db.prx().update(table, params, condition);

        // await checkRecord.saveToDraft(table, {});

        return response;
    }

    async updateOrCreate(params: object, condition: object, table = ''): Promise<any> {
        table = table == '' ?  this.table : table;

        await db.connect();

        const tableExists = await db.prx().keyExists(table);

        if (!tableExists) {
            console.log(`Table: ${table} does not exists`);
            return [];
        }

        const results = await db.prx().select(`SELECT * FROM ${table} WHERE ${await db.prx().buildUpdateCondition(condition)} LIMIT 1`);

        if(results.length == 0){
            return await this.insert(params, null, false, table)
        } else {
            return await this.update(params, condition, table)
        }
    }


    async getDrafts(table = ''): Promise<any> {
        if (table == '') {
            table = this.table;
        }
        const query = `SELECT * FROM ${table} WHERE action != ${OfflineAction.DEFAULT} AND action != ${OfflineAction.DELETE_RECORD} ORDER BY id DESC`;
        return await db.connect().then(() => (db.prx().select(query)))
    }
}