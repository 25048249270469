import { EnumsInterface } from "@/interfaces/EnumsInterface";

const AssessmentOption:EnumsInterface[] = [
    {id: 0, value: "Intellectual", label: "Intellectual"},
    {id: 1, value: "Personality", label: "Personality"},
    {id: 2, value: "Socio Emotional Functioning", label: "Socio Emotional Functioning"},
    {id: 3, value: "Adaptive Functioning", label: "Adaptive Functioning"},
    {id: 4, value: "Others", label: "Others"},
]

export default AssessmentOption