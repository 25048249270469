<template>
    <user-layout hasBack backTitle="Comorbid Report" backLink="/reports">

        <template #lowerRight>
            <export-button @click="exporting()" />
        </template>

        <div class="-mx-6 w-[calc(100%+32px)] pb-6" v-if="!loading">
            <div class="mx-6 mt-8 border rounded-lg border-neutral-100">
                <div class="">
                    <data-table 
                        :hasSearch="true"
                        searchPlaceholder="Search Report type name"
                        :headers="filters.report_type == 1 ? headers : adultHeaders" 
                        :no-action="true"
                        :count="items?.data?.length"
                        tableClass="header-frm-table"
                        dataTableClass="border-0"
                        :search="filters.query"
                        @update:searchText="(value:string) => (filters.query = value)"
                    >
                        <template #topContent>
                            <div class="flex space-x-2"> 
                                
                                <Popover class="relative">
                                    <PopoverButton>
                                        <div class="flex px-4 py-3 space-x-2 transition bg-white border rounded cursor-pointer border-neutral-100 hover:bg-primary-100">
                                            <AdjustmentsVerticalIcon class="inline-block w-5 h-5 text-neutral-900" />
                                            <p class="text-sm font-semibold text-neutral-900">Report Type</p>
                                        </div>
                                    </PopoverButton>

                                    <transition
                                        enter-active-class="transition duration-200 ease-out"
                                        enter-from-class="translate-y-1 opacity-0"
                                        enter-to-class="translate-y-0 opacity-100"
                                        leave-active-class="transition duration-150 ease-in"
                                        leave-from-class="translate-y-0 opacity-100"
                                        leave-to-class="translate-y-1 opacity-0"
                                    >
                                        <PopoverPanel class="absolute right-0 z-10 mt-3 w-fit">
                                        <div class="p-6 overflow-hidden bg-white rounded-lg shadow-md ring-1 ring-black/5 sort">
                                            <div class="flex flex-col w-auto space-y-2 bg-white">
                                                <radio-group
                                                    :options="reportTypeOptions"
                                                    name="reportTypeOptions"
                                                    v-model="filters.report_type"
                                                    customLabelClass="whitespace-nowrap"
                                                    @update:modelValue="paginate(1, filters.rows)"
                                                />
                                            </div>
                                        </div>
                                        </PopoverPanel>
                                    </transition>
                                </Popover>
                                
                                <Popover class="relative" v-if="filters.report_type === 1" >
                                    <PopoverButton>
                                        <div class="flex px-4 py-3 space-x-2 transition bg-white border rounded cursor-pointer border-neutral-100 hover:bg-primary-100">
                                            <UserGroupIcon class="inline-block w-5 h-5 text-neutral-900" />
                                            <p class="text-sm font-semibold text-neutral-900">Group</p>
                                        </div>
                                    </PopoverButton>

                                    <transition
                                        enter-active-class="transition duration-200 ease-out"
                                        enter-from-class="translate-y-1 opacity-0"
                                        enter-to-class="translate-y-0 opacity-100"
                                        leave-active-class="transition duration-150 ease-in"
                                        leave-from-class="translate-y-0 opacity-100"
                                        leave-to-class="translate-y-1 opacity-0"
                                    >
                                        <PopoverPanel class="absolute right-0 z-10 mt-3 w-fit">
                                        <div class="p-6 overflow-hidden bg-white rounded-lg shadow-md ring-1 ring-black/5 sort">
                                            <div class="flex flex-col w-auto space-y-2 bg-white">
                                                <p class="text-xs font-semibold text-neutral-600">SEX</p>
                                                <radio-group
                                                    :options="sexOptions"
                                                    name="sexOptions"
                                                    v-model="filters.sex_at_birth"
                                                    @update:modelValue="paginate( 1, 10);"
                                                />
                                            </div>
                                        </div>
                                        </PopoverPanel>
                                    </transition>
                                </Popover>


                                <div class="flex px-4 py-3 space-x-2 transition bg-white border rounded cursor-pointer border-neutral-100 hover:bg-primary-100"
                                @click="showFilter = true">
                                    <FunnelIcon class="inline-block w-5 h-5 text-neutral-900" />
                                    <p class="text-sm font-semibold text-neutral-900">Filter</p>
                                </div>

                                <Popover class="relative">
                                    <PopoverButton>
                                        <div class="flex px-4 py-3 space-x-2 transition bg-white border rounded cursor-pointer border-neutral-100 hover:bg-primary-100">
                                            <ArrowsUpDownIcon class="inline-block w-5 h-5 text-neutral-900" />
                                            <p class="text-sm font-semibold text-neutral-900">Sort</p>
                                        </div>
                                    </PopoverButton>

                                    <transition
                                        enter-active-class="transition duration-200 ease-out"
                                        enter-from-class="translate-y-1 opacity-0"
                                        enter-to-class="translate-y-0 opacity-100"
                                        leave-active-class="transition duration-150 ease-in"
                                        leave-from-class="translate-y-0 opacity-100"
                                        leave-to-class="translate-y-1 opacity-0"
                                    >
                                        <PopoverPanel class="absolute right-0 z-10 mt-3 w-fit">
                                        <div class="p-6 overflow-hidden bg-white rounded-lg shadow-md ring-1 ring-black/5 sort">
                                            <div class="flex flex-col w-auto space-y-2 bg-white">
                                                <radio-group
                                                    :options="sortOptions"
                                                    v-model="filters.column"
                                                    name="sortOptions"
                                                    @update:modelValue="fetch()"
                                                />
                                            </div>
                                            <hr class="my-4">
                                            <div class="flex flex-col w-auto space-y-2 bg-white">
                                                <radio-group
                                                    :options="orderOptions"
                                                    v-model="filters.order"
                                                    name="orderOptions"
                                                    @update:modelValue="fetch()"
                                                />
                                            </div>
                                        </div>
                                        </PopoverPanel>
                                    </transition>
                                </Popover>

                                <FilterPanel
                                    :show="showFilter"
                                    @close="showFilter = false"
                                    @apply:reset="applyReset()"
                                    @apply:filters="applyFilters()"
                                    customClass="!overflow-visible overflow-x-visible"
                                    >
                                    <template #fields>
                                        <div class="space-y-5">
                                            <date-picker
                                                id="date_created"
                                                name="date_created"
                                                label="Date Created"
                                                v-model="filters.created_at"
                                                range
                                                multiCalendars
                                                class="filter-range"
                                            />
                                        </div>
                                    </template>
                                </FilterPanel>
                            </div>
                        </template>  
                        <template #body>
                            <template v-if="filters.report_type !== 1">
                                <template 
                                    v-for="item in items?.data" 
                                    :key="item"
                                >
                                    <tr>
                                        <td class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
                                            {{ item.name }}
                                        </td>
                                        <td class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
                                            {{ item.set1 }}
                                        </td>
                                        <td class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
                                            {{ item.set2 }}
                                        </td>
                                        <td class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
                                            {{ item.set3 }}
                                        </td>
                                        <td class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
                                            {{ item.set4 }}
                                        </td>
                                        <td class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
                                            {{ item.total }}
                                        </td>
                                    </tr>
                                </template>
                                <tr class="bg-gray-50">
                                    <td class="px-6 py-4 text-sm text-gray-900 whitespace-nowrap">
                                        Total
                                    </td>
                                    <template v-for="(total, index) in totals" :key="index">
                                        <td class="px-6 py-4 text-sm text-gray-900 whitespace-nowrap">
                                            {{ total }}
                                        </td>
                                    </template>
                                </tr>
                            </template>
                            <template v-else>
                                <template 
                                    v-for="item in items?.data" 
                                    :key="item"
                                >
                                    <tr>
                                        <td class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
                                            {{ item.name }}
                                        </td>
                                        <td class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
                                            {{ item.set1 }}
                                        </td>
                                        <td class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
                                            {{ item.set2 }}
                                        </td>
                                        <td class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
                                            {{ item.set3 }}
                                        </td>
                                        <td class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
                                            {{ item.set4 }}
                                        </td>
                                        <td class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
                                            {{ item.set5 }}
                                        </td>
                                        <td class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
                                            {{ item.set6 }}
                                        </td>
                                        <td class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
                                            {{ item.total }}
                                        </td>
                                    </tr>
                                </template>
                                <tr class="bg-gray-50">
                                    <td class="px-6 py-4 text-sm text-gray-900 whitespace-nowrap">
                                        Total
                                    </td>
                                    <template v-for="(total, index) in totals" :key="index">
                                        <td class="px-6 py-4 text-sm text-gray-900 whitespace-nowrap">
                                            {{ total }}
                                        </td>
                                    </template>
                                </tr>
                            </template>
                        </template>
                    </data-table>
                    <div class="w-full px-6 border-t py-7">
						<simple-pagination 
							:numRows="filters.rows ?? 10" 
                            :currentPage="items?.current_page" 
                            :last-page="items?.last_page"
                            @update="(value: any) => paginate(value.page, value.rows)" 
						/>
					</div>
                </div>
            </div>
        </div>

        <loading-modal
            :show="loading"
            noBtn
            title="Please wait.."
            content="System is currently loading the report based on the selected filter."
        />
    </user-layout>
</template>
<script setup lang="ts">
import { ref, watch, onMounted } from 'vue';
import UserLayout from "@/layouts/UserLayout.vue";
import ButtonComponent from "@/components/buttons/ButtonComponent.vue";
import DataTable from '@/components/partials/DataTable.vue';
import ExportButton from '@/components/buttons/ExportButton.vue';
import SimplePagination from "@/components/partials/SimplePagination.vue";
import DatePicker from "@/components/inputs/DatePicker.vue";
import RadioGroup from "@/components/inputs/RadioGroup.vue";
import FilterPanel from "@/components/partials/FilterPanel.vue";
import LoadingModal from '@/components/modals/LoadingModal.vue';
import { AdjustmentsVerticalIcon, UserGroupIcon, FunnelIcon, ArrowsUpDownIcon } from "@heroicons/vue/24/outline";
import { Popover, PopoverButton, PopoverPanel, Switch, SwitchGroup, SwitchLabel } from '@headlessui/vue';
import ReportService from '@/classes/Reports/ReportService'; 
 
import isNil from "lodash/isNil";
import omitBy from "lodash/omitBy";
import debounce from "lodash/debounce";

const showFilter = ref<boolean>(false); 
const loading = ref<boolean>(false);
 
const reportTypeOptions = [
    { id: "1", label: "Comorbid VAC", value: 1 },
    { id: "2", label: "Comorbid VAW", value: 2 },
];

const sortOptions = [
    { id: '1', label: 'Name', value: 'name'}, 
    { id: '2', label: 'Count', value: 'total'},
]
const orderOptions = [
    { id: '3', label: 'Ascending', value: 'asc'},
    { id: '4', label: 'Descending', value: 'desc'},
]

const sexOptions = [
    { id: "1", label: "All", value: 2 },
    { id: "2", label: "Male", value: 0 },
    { id: "3", label: "Female", value: 1 },
];
 
const headers = ref([
    { text: 'Comorbid Diagnosis' },
    { text: '0-3' },
    { text: '4-6' },
    { text: '7-9' },
    { text: '10-12' },
    { text: '13-15' },
    { text: '16-18' },
    { text: 'Total' },
]);

const adultHeaders = ref([
{ text: 'Comorbid Diagnosis' },
    { text: '18-24' },
    { text: '25-44' },
    { text: '45-49' },
    { text: '60-100+' },
    { text: 'Total' },
]);
const filters = ref({
    page: 1,
    query: null,
    rows: 10,
    created_at: null,
    order: 'asc',
    column: 'name',
    report_type: 1,
    group_category: 3,
    sex_at_birth: 2
})


const paginate = ( page:number, rows:number )=>{
    ReportService.paginate(page, rows,filters.value, fetch)
}

const applyFilters = () => {
	showFilter.value = false;
    paginate( 1, filters.value.rows);
};

const applyReset = () => {
    showFilter.value = false;
    filters.value.created_at = null; 
    paginate( 1, filters.value.rows);
};

const items = ref()
const totals = ref()

const fetch = () => { 
    loading.value = true;
    filters.value.report_type  == 1 ? filters.value.group_category = 1 : filters.value.group_category = 3;
    ReportService.index('comorbid-report', omitBy(filters.value, isNil))
        .then(({ data }) => { 
            items.value = data.data
            totals.value = data.totals  
            console.log(data);
            console.log(items.value.data);
            console.log(totals.value);
        })
        .catch(errors => { 
            console.log(errors);
        })
        .finally(() => loading.value = false)
}

const exporting = (): void => {
    loading.value = true;
    ReportService.export('comorbid-report',omitBy(filters.value, isNil))
        .then(({data})=>{ 
             ReportService.downloadExport("comorbid-age-group-export.csv",data);  
        })
        .finally(() => loading.value = false)
}

watch(
    () => filters.value.query,
    debounce(() => {
        paginate( 1, filters.value.rows);
        applyReset();
    }, 500)
);

onMounted(()=> { 
    fetch();
})

</script>