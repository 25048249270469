<template>
	<user-layout>
		<div class="-mx-6 w-[calc(100%+48px)]">
			<div
				class="fixed top-20 left-0 z-10 bg-white px-6 py-4 shadow-[0_1px_0px_0px_rgba(0,0,0,0.08)] flex justify-between items-center w-full">
				<a @click="$router.replace('/settings/telecpu-locations/index')">
					<button-component customClass="tertiary text-black hover:text-primary-600 transition">
						<!-- <img src="assets/icon/ic-arrow-left.svg" alt="back-arrow" class="w-6 h-6 object-contain mr-2" /> -->
						<arrow-left-icon class="object-contain w-6 h-6 mr-2" />
						<span class="text-sm font-semibold">Back</span>
					</button-component>
				</a>
			</div>
			<div class="mt-20 mb-6 mx-6">
				<div class="max-w-[500px] mx-auto py-6">
					<information-form-section :form="form" :errors="errors">
						<div class="col-span-2">
							<div class="w-full flex items-center justify-end space-x-3">
								<a @click="router.replace(`/settings/telecpu-locations/index`)">
									<button-component btnWidth="quinary">Cancel</button-component>
								</a>

								<button-component btnWidth="primary" @click="save()">Save Changes</button-component>
							</div>
							<confirmation-modal
								:typeTwo="true"
								:show="showConfirmation"
								@cancel="showConfirmation = false"
								@confirm="
									showSuccess = true;
									showConfirmation = false;
								"
								cancel-text="Cancel"
								action-text="Confirm"
								title="Save Record"
								content="Are you sure you wish to save this record?" />
							<success-modal
								:show="showSuccess"
								@confirm="redirectSuccess()"
								action-text="Close"
								title="Record Saved!"
								:content="successMessage" />
						</div>
					</information-form-section>
				</div>
			</div>
		</div>
	</user-layout>
</template>
<script setup lang="ts">
import { ref } from "vue";
import InformationFormSection from "./Informations/FormSection.vue";
import UserLayout from "@/layouts/UserLayout.vue";
import ButtonComponent from "@/components/buttons/ButtonComponent.vue";
import ConfirmationModal from "@/components/modals/ConfirmationModal.vue";
import SuccessModal from "@/components/modals/SuccessModal.vue";
import { useRouter } from "vue-router";
import TeleCpuLocationService from "@/classes/Settings/TeleCpuLocationService";
import { errorResponse } from "@/helpers/errorMessage";
import ArrowLeftIcon from "@/components/icons/arrowLeftIcon.vue";

const router = useRouter();

const showConfirmation = ref<boolean>(false);
const showSuccess = ref<boolean>(false);
const successMessage = ref<string>("Data has been successfully saved!");
const form = ref<{ name: string }>({
	name: "",
});
const errors = ref<any>();

const save = (): void => {
	TeleCpuLocationService.store(form.value)
		.then((response: any) => {
			showConfirmation.value = true;
			successMessage.value = response.data.message;
		})
		.catch((error: any) => (errors.value = errorResponse(error.response?.data?.errors)));
};

const redirectSuccess = (): void => {
	showSuccess.value = false;
	router.replace("/settings/telecpu-locations/index");
};
</script>
