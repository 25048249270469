<template>
    <user-layout>
        <div class="w-full py-6 mx-auto">
            <div class="flex items-center justify-between">
                <section-title 
                    title="Settings"
                    description="View the relevant data and other details"
                />
            </div>
            <div class="mt-6">
                <div class="w-full border rounded-lg border-neutral-100">
                    <div class="relative overflow-auto">
						<div class="absolute left-0 top-0 h-full whitespace-nowrap position w-[181px] shadow-[2px_0_16px_0px_rgb(0,0,0,0.08)]">
                            <div class="px-6 py-[26px] bg-primary-100 text-sm font-semibold relative">
                                Settings
                            </div>
                            <side-tab rolesSelected />
                        </div>
                        <div class="pl-[181px] min-h-[500px]">
                            <div class="flex items-center justify-between p-6 border-b border-neutral-100">
                                <div class="flex items-center space-x-4">
                                    <tab-component
                                        :tabs="tabs"
                                    />
                                </div>
                            </div>
                            <div v-if="route.query.tab != 'activity_logs'">
                                <data-table 
                                    :headers="headers" 
                                    :no-action="false"
                                    :count="items.total"
                                    tableClass="header-frm-table"
                                >
                                    <template v-slot:body>
                                        <template 
                                            v-for="item in items.data" 
                                            :key="item"
                                        >
                                            <tr>
                                                <td class="px-6 py-[18px] whitespace-nowrap text-sm text-gray-900">
                                                    {{ item.name }}
                                                </td>
                                                <td class="px-6 py-[18px] whitespace-nowrap text-sm text-gray-900">
                                                    {{ item.no_assigned_personels }} Personnel
                                                </td>
                                                <td 
                                                    class="
                                                        px-6
                                                        py-[18px]
                                                        whitespace-nowrap
                                                        text-sm 
                                                        text-gray-900
                                                    "
                                                >
                                                    <div 
                                                        class="flex items-center justify-center mx-auto"
                                                    >
                                                        <view-button @click="router.replace(`/settings/roles/view/${item.id}`)"/>
                                                    </div>
                                                </td>
                                            </tr>
                                        </template>
                                    </template>
                                </data-table>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="w-full px-6 border-t py-7">
                        <minify-pagination num-rows="All"></minify-pagination>
                    </div> -->
                    <ActivityLogsTable
                       v-if="filters.tab == 'activity_logs'"
                       :items="items"
                   />
    
                   <div class="w-full px-6 border-t py-7">
                       <simple-pagination 
                           :numRows="filters.rows" 
                           :currentPage="items.current_page" 
                           :last-page="items.last_page" 
                           @update="(value: any) => paginate(value)"
                       />
                   </div> 
                </div>

            </div>
        </div>

        <page-loader :show="loading"/>
    </user-layout>
</template>
<script setup lang="ts">
import { ref, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import UserLayout from '@/layouts/UserLayout.vue';
import SectionTitle from '@/components/partials/SectionTitle.vue';
import ViewButton from '@/components/buttons/ViewButton.vue';
import DataTable from '../Components/DataTable.vue';
import SideTab from '../Components/SideTab.vue';
import TabComponent from '@/components/partials/TabComponent.vue';

import pickBy from "lodash/pickBy";

import Roles from "@/classes/Roles";

import SimplePagination from "@/components/partials/SimplePagination.vue";

import assignValues from "@/helpers/assignValues"
import PageLoader from "@/components/loaders/PageLoader.vue"

import ActivityLogsTable from "@/views/activity-logs/ActivityLogsTable.vue"

import {filters, items} from "@/interfaces/pages/FilterPageInterface";

const loading = ref<boolean>(false);

const route = useRoute();
const router = useRouter();

const tabs: { name: string, count: number, tab: string|null, allowed: boolean }[] = [
    {
        name: 'All',
        count: filters.value.allItemsCount,
        tab: null,
        allowed: true
    }
];

const headers: { text: string, haveSort?: boolean }[] = [
    { text: 'Role' },
    { text: 'No. of Assigned Personnel' },
];

const init = () => {
    Roles.index(pickBy(filters.value))
        .then(({ data }) => {
            items.value = data.items;
   
            filters.value = assignValues(filters.value, data);
           
            loading.value = false
        })
        .catch((error: any) => {
            console.log(error);
        });
}

const paginate = ({ page, rows }): void => {
    filters.value = Object.assign(filters.value, {
        page, rows
    })
    init();
};

onMounted(() => {
    init()
})
</script>