class ErrorValidation {

    public getErrors(validationErrors:any):any  {
        const errors:any = []
        validationErrors.$errors.forEach((error:any) => {
            console.log(error);
            if(error.$response){
                error.$response.$errors.forEach((error:any, index:number) => {
                    Object.values(error).forEach((error:any) => {
                        if(error[0]?.$property != undefined){
                            errors[`${index}`] = error[0].$message
                        }
                    });   
                });
            } else {
                errors[error.$propertyPath] = error.$message
            }
        });
        console.log(errors);
        return errors;
    }

}
export default new ErrorValidation();