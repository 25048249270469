const RegionSeeder = {
    table: 'regions',
    data: [
        {
            "id" : 1,
            "name" : "REGION I (ILOCOS REGION)",
            "created_at" : null,
            "updated_at" : null,
            "deleted_at" : null
        },
        {
            "id" : 2,
            "name" : "REGION II (CAGAYAN VALLEY)",
            "created_at" : null,
            "updated_at" : null,
            "deleted_at" : null
        },
        {
            "id" : 3,
            "name" : "REGION III (CENTRAL LUZON)",
            "created_at" : null,
            "updated_at" : null,
            "deleted_at" : null
        },
        {
            "id" : 4,
            "name" : "REGION IV-A (CALABARZON)",
            "created_at" : null,
            "updated_at" : null,
            "deleted_at" : null
        },
        {
            "id" : 5,
            "name" : "REGION IV-B (MIMAROPA)",
            "created_at" : null,
            "updated_at" : null,
            "deleted_at" : null
        },
        {
            "id" : 6,
            "name" : "REGION V (BICOL REGION)",
            "created_at" : null,
            "updated_at" : null,
            "deleted_at" : null
        },
        {
            "id" : 7,
            "name" : "REGION VI (WESTERN VISAYAS)",
            "created_at" : null,
            "updated_at" : null,
            "deleted_at" : null
        },
        {
            "id" : 8,
            "name" : "REGION VII (CENTRAL VISAYAS)",
            "created_at" : null,
            "updated_at" : null,
            "deleted_at" : null
        },
        {
            "id" : 9,
            "name" : "REGION VIII (EASTERN VISAYAS)",
            "created_at" : null,
            "updated_at" : null,
            "deleted_at" : null
        },
        {
            "id" : 10,
            "name" : "REGION IX (ZAMBOANGA PENINSULA)",
            "created_at" : null,
            "updated_at" : null,
            "deleted_at" : null
        },
        {
            "id" : 11,
            "name" : "REGION X (NORTHERN MINDANAO)",
            "created_at" : null,
            "updated_at" : null,
            "deleted_at" : null
        },
        {
            "id" : 12,
            "name" : "REGION XI (DAVAO REGION)",
            "created_at" : null,
            "updated_at" : null,
            "deleted_at" : null
        },
        {
            "id" : 13,
            "name" : "REGION XII (SOCCSKSARGEN)",
            "created_at" : null,
            "updated_at" : null,
            "deleted_at" : null
        },
        {
            "id" : 14,
            "name" : "NATIONAL CAPITAL REGION (NCR)",
            "created_at" : null,
            "updated_at" : null,
            "deleted_at" : null
        },
        {
            "id" : 15,
            "name" : "CORDILLERA ADMINISTRATIVE REGION (CAR)",
            "created_at" : null,
            "updated_at" : null,
            "deleted_at" : null
        },
        {
            "id" : 16,
            "name" : "AUTONOMOUS REGION IN MUSLIM MINDANAO (ARMM)",
            "created_at" : null,
            "updated_at" : null,
            "deleted_at" : null
        },
        {
            "id" : 17,
            "name" : "REGION XIII (Caraga)",
            "created_at" : null,
            "updated_at" : null,
            "deleted_at" : null
        }
    ]
}

export default RegionSeeder