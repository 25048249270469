<template>
    <div class="w-full">
        <section-title 
            fontSmall
            title="Notification Information"
            description="Relevant data and other details"
        />
        <form class="grid grid-cols-2 gap-6 mt-6">
            <div class="col-span-2 border-t border-gray-200"></div>

            <div class="col-span-2">
                <text-input
                    type="text"
                    label="Module"
                    placeholder="Module"
                    name="module"
                    id="module"
                />
            </div>
            <div class="col-span-2">
                <text-input
                    type="text"
                    label="Subject"
                    placeholder="Subject"
                    name="subject"
                    id="subject"
                />
            </div>
            <div class="col-span-2">
                <ck-editor
                    label="Content"
                ></ck-editor>
            </div>
            <div class="col-span-2 flex items-center">
                <ExclamationCircleIcon class="w-6 h-6 text-red-600 stroke-2 mr-1"/>
                <div class="font-bold text-sm text-red-600" v-html="text"></div>
            </div>
            <slot></slot>
        </form>
    </div>
</template>
<script setup lang="ts">
import { ref } from 'vue';
import SectionTitle from '@/components/partials/SectionTitle.vue';
import TextInput from '@/components/inputs/TextInput.vue';
import CkEditor from '@/components/inputs/CKEditor.vue';
import { ExclamationCircleIcon } from '@heroicons/vue/24/outline';

const text = ref<string>('Liquid Variables: {{ personnel_name }} {{ case_number }}');
</script>