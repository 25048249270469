const DraftMigration = {
	name: "drafts",
	schema: [
		{ column: "id", value: "INTEGER  PRIMARY KEY AUTOINCREMENT" },
        { column: 'table_source', value: 'VARCHAR(255) NOT NULL' },
		{ column: "table_id", value: "INTEGER UNSIGNED" },
        { column: "table_offline_id", value: "INTEGER UNSIGNED" },
		{ column: "action", value: "INT UNSIGNED" },
		{ column: "is_sync", value: "BOOLEAN DEFAULT FALSE" },
        { column: "is_created_offline", value: "BOOLEAN DEFAULT FALSE" },
        { column: "is_updated_offline", value: "BOOLEAN DEFAULT FALSE" },
        { column: "is_deleted_offline", value: "BOOLEAN DEFAULT FALSE" },
        { column: "created_at", value: "TIMESTAMP" },
		{ column: "updated_at", value: "TIMESTAMP" },
		{ column: "deleted_at", value: "TIMESTAMP" },
	],
};

export default DraftMigration;
