import { DateTime } from "luxon";
import FormOptions from "./FormOptions";
import SexAtBirth from "@/enums/SexAtBirth";
import Gender from "@/enums/Gender";
import IncidentTime from "@/enums/IncidentTime";
import ChildType from "@/enums/ChildType";
import HighSuicidalRisk from "@/enums/HighSuicidalRisk";
import Pwd from "@/enums/Pwd";
import Behavior from "@/enums/Behavior";
import InterventionType from "@/enums/InterventionType";
import ResidenceType from "@/enums/ResidenceType";
import LegalStatus from "@/enums/LegalStatus";
import { SafetyAssessmentStatus } from "@/views/enums/SafetyAssessmentStatus";
import CaseAssessmentStatus from "@/enums/CaseAssessmentStatus";
import CurrentLocation from "@/enums/CurrentLocation";
import PerpetratorIdentity from "@/enums/PerpetratorIdentity";

const options = new FormOptions();

export default class Relationships {
	dateFormat(date: any, format = "yyyy-LL-dd"): string {
		const isValidDate = Date.parse(date);

        if (!date || isNaN(isValidDate)) {
            return '';
        }

		let item = DateTime.fromJSDate(date).toFormat(format);

        if (item == 'Invalid DateTime') {
            item = DateTime.fromISO(date).toFormat(format);
            if (item == 'Invalid DateTime') {
                return DateTime.fromSQL(date).toFormat(format);
            }
            return item;            
        }

        return item;
	}

    dateFormatFromIso(date: any = null): any{
        const isValidDate = Date.parse(date);

        if (!date || isNaN(isValidDate)) {
            return '';
        }

        if (date) {
            return new Date(date).toISOString();
        }

        return new Date().toISOString();
    }

	fullname(item: any): string {
		return `${this.capitalFirstLetter(item?.first_name)} ${this.capitalFirstLetter(item?.last_name)}`;
	}

	fullnameV2(item: any): string {
		return `${this.capitalFirstLetter(item?.last_name)} ${this.capitalFirstLetter(item?.first_name)}`;
	}

    fullnameAbbr(item: any): string {
        return this.capitalFirstLetter(item.first_name)[0].toUpperCase() + this.capitalFirstLetter(item.last_name)[0].toUpperCase();
    }

    capitalFirstLetter(item: any): string{
        return item ? (item[0].toUpperCase() + item.substring(1)) : '';
    }

	sexAtBirth(item: any): object {
		const gender = SexAtBirth.filter((gender: any) => {
			if (item.sex_at_birth == gender.id) {
				return gender;
			}
		}).map((gender: any) => {
			return { description: gender.label };
		});

		if (gender.length > 0) {
			return gender[0];
		}

		return { description: "" };
	}

    computeAge(birthdate: any = null, age: any = null): any {
        const addOn = ' Years old';
        if (birthdate) {
            return Math.floor((new Date().getTime() - new Date(birthdate).getTime()) / 3.15576e+10) + addOn;
        }

        if (age) {
            return age + addOn;
        }        
    }

    computeApproximateAge(year: any = null, month: any = null) {
        if (year == null && month == null) {
            return null;
        }
        return `${year} Years old, ${month} Months old`;
    }

    birthdateDetails(content: any) {
        if (content.birthdate) {
            return `${this.dateFormat(content.birthdate, 'DDD')} ${this.computeAge(content.birthdate)}`;
        }

        if (content.approximate_age_year && content.approximate_age_month) {
            return `Approximate age ${content.approximate_age_year} years and ${content.approximate_age_month} months old`;
        }

        return "Unknown";
    }

	gender(item: any): object {
		const data = Gender.filter((gender: any) => {
			if (item.gender == gender.id) {
				return gender;
			}
		}).map((gender: any) => {
			return { description: gender.label };
		});

		if (data.length > 0) {
			return data[0];
		}

		return { description: "" };
	}

	async civil_status(item: any): Promise<any> {
		let data = await options
			.civilStatusOptions();
		data = data?.filter((civil_status: any) => {
            if (item.civil_status_id == civil_status.id) {
                return civil_status;
            }
        })
        .map((civil_status: object) => {
            return civil_status;
        });

		if (data.length > 0) {
			return data[0];
		}

		return null;
	}

	incident_time(id: number): object {
		const data = IncidentTime.filter((time: any) => {
			if (id == time.id) {
				return time;
			}
		}).map((time: any) => {
			return { description: time.label };
		});

		if (data.length > 0) {
			return data[0];
		}

		return { description: "" };
	}

	child_type(id: number): object {
		const data = ChildType.filter((type: any) => {
			if (id == type.id) {
				return type;
			}
		}).map((type: any) => {
			return { description: type.label };
		});

		if (data.length > 0) {
			return data[0];
		}

		return { description: "" };
	}

	async comorbidities(id: number):  Promise<any> {
		let data = await options
			.comorbidities();
        
		data = data?.filter((comorbidity: any) => {
            if (id == comorbidity.id) {
                return comorbidity;
            }
        })
        .map((comorbidity: object) => {
            return comorbidity;
        });

		if (data.length > 0) {
			return data[0];
		}

		return null;
	}

	async diagnoses(item: any):  Promise<any> {
		return {
			case_id: item.id,
			high_suicidal_risk: item.high_suicidal_risk,
			high_suicidal_risk_meta: this.high_suicidal_risk(item),
			is_hama: item.is_hama,
			other_diagnoses: item.other_diagnoses,
			primary_diagnoses_id: item.primary_diagnoses_id,
			primary_diagnoses: await this.primary_diagnoses(item),
		};
	}

	high_suicidal_risk(id: number): object {
		const data = HighSuicidalRisk.filter((risk: any) => {
			if (id == risk.id) {
				return risk;
			}
		}).map((risk: any) => {
			return { description: risk.label };
		});

		if (data.length > 0) {
			return data[0];
		}

		return { description: "" };
	}

	async primary_diagnoses(item: any):  Promise<any> {
		let data = await options
			.primaryDiagnoses();

		data = data?.filter((diagnoses: any) => {
            if (item?.primary_diagnoses_id == diagnoses.id) {
                return diagnoses;
            }
        })
        .map((diagnoses: object) => {
            return diagnoses;
        });

		if (data.length > 0) {
			return data[0];
		}

		return null;
	}

	async disability(item: any):  Promise<any> {
		let data = await options
			.disabilities();

		data = data?.filter((disability: any) => {
            if (item.disability_id == disability.id) {
                return disability;
            }
        })
        .map((disability: object) => {
            return disability;
        });

		if (data.length > 0) {
			return data[0];
		}

		return null;
	}

	async educational_attainment(item: any):  Promise<any> {
		let data = await options
			.educationAttainments();

		data = data?.filter((education: any) => {
            if (item?.educational_attainment_id == education.id) {
                return education;
            }
        })
        .map((education: object) => {
            return education;
        });

		if (data.length > 0) {
			return data[0];
		}

		return null;
	}

	async employment_status(item: any):  Promise<any> {
		let data = await options
			.employmentStatus();
        
		data = data?.filter((employment: any) => {
            if (item?.employment_status_id == employment.id) {
                return employment;
            }
        })
        .map((employment: object) => {
            return employment;
        });

		if (data.length > 0) {
			return data[0];
		}

		return null;
	}

	async indigeneous(item: any):  Promise<any> {
		let data = await options
			.indigeneous();

		data = data?.filter((indigeneous: any) => {
            if (item.indigeneous_people_id == indigeneous.id) {
                return indigeneous;
            }
        })
        .map((indigeneous: object) => {
            return indigeneous;
        });

		if (data.length > 0) {
			return data[0];
		}

		return null;
	}

	async mental_health_diagnoses(id: null):  Promise<any> {
		let data = await options
			.mentalHealthDiagnoses();

		data = data?.filter((mentalHealth: any) => {
            if (id == mentalHealth.id) {
                return mentalHealth;
            }
        })
        .map((mentalHealth: object) => {
            return mentalHealth;
        });

		if (data.length > 0) {
			return data[0];
		}

		return null;
	}

	async nationality(id: any):  Promise<any> {
		let data = await options
			.nationalities();

		data = data?.filter((nationality: any) => {
            if (id == nationality.id) {
                return nationality;
            }
        })
        .map((nationality: object) => {
            return nationality;
        });

		if (data.length > 0) {
			return data[0];
		}

		return null;
	}

	async referral_service(item: any):  Promise<any> {
		return {
			cases_id: item.id,
			intake_social_worker_id: item.intake_social_worker_id,
			intake_social_worker: await this.social_worker(item.intake_social_worker_id),
			mental_health_personnel_id: item.mental_health_personnel_id,
			mental_health_personnel: await this.mental_health_personnel(item),
			mms_id: item.mms_id,
			mms: await this.mms(item),
			physician_id: item.physician_id,
			physician: await this.physician(item),
			referral_source_id: item.referral_source_id,
			referral_source: await this.referral_source(item),
			second_referral_source_id: item.second_referral_source_id,
			second_referral_source: await this.referral_source(item),
			social_worker_id: item.social_worker_id,
			social_worker: await this.social_worker(item.social_worker_id),
			sought_service_id: item.sought_service_id,
			sought_service: await this.sought_service(item),
			trainee_id: item.trainee_id,
			trainee: await this.trainee(item),
		};
	}

	async social_worker(id: number):  Promise<any> {
		let data = await options
			.socialWorkers();

		data = data?.filter((socialWorker: any) => {
            if (id == socialWorker.id) {
                return socialWorker;
            }
        })
        .map((socialWorker: object) => {
            return socialWorker;
        });

		if (data.length > 0) {
			return data[0];
		}

		return null;
	}

    async wcpuPersonnel(id = []):  Promise<any> {
		let data = await options
			.wcpuPersonnels();

		data = data?.filter((item: any) => {
            if (id.find((value) => value == item.id)) {
                return item;
            }
        })
        .map((item: object) => {
            return item;
        });

		if (data.length > 0) {
			return data;
		}

		return null;
	}

	async mental_health_personnel(item: any):  Promise<any> {
		let data = await options
			.psychiatrists();

		data = data?.filter((mentalHealth: any) => {
            if (item.mental_health_personnel_id == mentalHealth.id) {
                return mentalHealth;
            }
        })
        .map((mentalHealth: object) => {
            return mentalHealth;
        });

		if (data.length > 0) {
			return data[0];
		}

		return null;
	}

	async mms(item: any):  Promise<any> {
		let data = await options
			.mms();

		data = data.filter((mms: any) => {
            if (item.mms_id == mms.id) {
                return mms;
            }
        })
        .map((mms: object) => {
            return mms;
        });

		if (data.length > 0) {
			return data[0];
		}

		return null;
	}

	async physician(item: any):  Promise<any> {
		let data = await options
			.physicians();

		data = data?.filter((physician: any) => {
            if (item.physician_id == physician.id) {
                return physician;
            }
        })
        .map((physician: object) => {
            return physician;
        });

		if (data.length > 0) {
			return data[0];
		}

		return null;
	}

	async referral_source(id: number):  Promise<any> {
		let data = await options
			.referralSources();

		data = data?.filter((referralSource: any) => {
            if (id == referralSource.id) {
                return referralSource;
            }
        })
        .map((referralSource: object) => {
            return referralSource;
        });

		if (data.length > 0) {
			return data[0];
		}

		return null;
	}

	async sought_service(item: any):  Promise<any> {
		let data = await options
			.soughtServices();

		data = data?.filter((soughtService: any) => {
            if (item.sought_service_id == soughtService.id) {
                return soughtService;
            }
        })
        .map((soughtService: object) => {
            return soughtService;
        });

		if (data.length > 0) {
			return data[0];
		}

		return null;
	}

	async trainee(item: any):  Promise<any> {
		let data = await options
			.trainees();

		data = data.filter((trainee: any) => {
            if (item.trainee_id == trainee.id) {
                return trainee;
            }
        })
        .map((trainee: object) => {
            return trainee;
        });

		if (data.length > 0) {
			return data[0];
		}

		return null;
	}

	async religion(item: any):  Promise<any> {
		let data = await options
			.religions();

		data = data?.filter((religion: any) => {
            if (item?.religion_id == religion.id) {
                return religion;
            }
        })
        .map((religion: object) => {
            return religion;
        });

		if (data.length > 0) {
			return data[0];
		}

		return null;
	}

	pwd(item: any): object {
		const data = Pwd.filter((type: any) => {
			if (item?.pwd == type.id) {
				return type;
			}
		}).map((type: any) => {
			return { description: type.label };
		});

		if (data.length > 0) {
			return data[0];
		}

		return { description: "" };
	}

	async behavior_types(item: any):  Promise<any> {
		let data = await options
			.behaviorTypes();

		data = data?.filter((trainee: any) => {
            if (item.behavior_type_id == trainee.id) {
                return trainee;
            }
        })
        .map((trainee: object) => {
            return trainee;
        });

		if (data.length > 0) {
			return data[0];
		}

		return null;
	}

	behavior(id: number): object {
		const data = Behavior.filter((type: any) => {
			if (id == type.id) {
				return type;
			}
		}).map((type: any) => {
			return { description: type.label };
		});

		if (data.length > 0) {
			return data[0];
		}

		return { description: "" };
	}

	async guardianRelationship(item: any):  Promise<any> {
		let data = await options
			.guardianRelationships();

		data = data?.filter((relationship: any) => {
            if (item.guardian_relationship_id == relationship.id) {
                return relationship;
            }
        })
        .map((relationship: object) => {
            return relationship;
        });

		if (data.length > 0) {
			return data[0];
		}

		return null;
	}

	async companionRelationship(item: any):  Promise<any> {
		let data = await options
			.companionRelationships();

		data = data?.filter((relationship: any) => {
            if (item.companion_relationship_id == relationship.id) {
                return relationship;
            }
        })
        .map((relationship: object) => {
            return relationship;
        });

		if (data.length > 0) {
			return data[0];
		}

		return null;
    }

	async personnels(id: number):  Promise<any> {
		const data =  await options.personnels();
        return data?.find((x: any) => x.id == id);
	}

	cases(id: number): any {
		return options.cases().find((x: any) => x.id == id);
	}

	interventionTypeLists(id: number): any {
		return options.interventionTypeLists().find((x: any) => x.id == id);
	}

	interventionTypes(id: number): any {
		return InterventionType.find((x: any) => x.id == id);
	}

	async barangays(item: any):  Promise<any> {
		let data = await options
			.barangays(item.city_id);
		data = data?.filter((barangay: any) => {
            if (item.barangay_id == barangay.id) {
                return barangay;
            }
        })
        .map((barangay: object) => {
            return barangay;
        });

		if (data.length > 0) {
			return data[0];
		}

		return null;
	}

	async cities(item: any):  Promise<any> {
		let data = await options
			.cities(item.province_id);

		data = data?.filter((city: any) => {
            if (item.city_id == city.id) {
                return city;
            }
        })
        .map((city: object) => {
            return city;
        });

		if (data.length > 0) {
			return data[0];
		}

		return null;
	}

	async regions(item: any):  Promise<any> {
		let data = await options
			.regions();

		data = data?.filter((region: any) => {
            if (item.region_id == region.id) {
                return region;
            }
        })
        .map((region: object) => {
            return region;
        });

		if (data.length > 0) {
			return data[0];
		}

		return null;
	}

	async provinces(item: any):  Promise<any> {
		let data = await options
			.provinces(item.region_id);

		data = data?.filter((province: any) => {
            if (item.province_id == province.id) {
                return province;
            }
        })
        .map((province: object) => {
            return province;
        });

		if (data.length > 0) {
			return data[0];
		}

		return null;
	}

	residenceTypes(item: any): object {
		const data = ResidenceType.filter((residence: any) => {
			if (item.residence_type == residence.id) {
				return residence;
			}
		}).map((residence: any) => {
			return { description: residence.label };
		});

		if (data.length > 0) {
			return data[0];
		}

		return { description: "" };
	}

	async housingClassification(item: any):  Promise<any> {
		let data = await options
			.housingClassifications();

		data = data?.filter((housing: any) => {
            if (item.housing_classification_id == housing.id) {
                return housing;
            }
        })
        .map((housing: object) => {
            return housing;
        });

		if (data.length > 0) {
			return data[0];
		}

		return null;
	}

	legalStatus(item: any): object {
		const data = LegalStatus.filter((status: any) => {
			if (item.legal_status == status.id) {
				return status;
			}
		}).map((status: any) => {
			return { description: status.label };
		});

		if (data.length > 0) {
			return data[0];
		}

		return { description: "" };
	}

	async relationship(item: any):  Promise<any> {
		let data = await options
			.relationships();

		data = data.filter((relationship: any) => {
            if (item.relationship_id == relationship.id) {
                return relationship;
            }
        })
        .map((relationship: object) => {
            return relationship;
        });

		if (data.length > 0) {
			return data[0];
		}

		return null;
	}

	async socioEconomic(item: any):  Promise<any> {
        let data = await options
			.socioEconomicStatuses();

		data = data?.filter((status: any) => {
            if (item.socio_economic_status_id == status.id) {
                return status;
            }
        })
        .map((status: object) => {
            return status;
        });

		if (data.length > 0) {
			return data[0];
		}

		return null;
	}

	async safetyQuestion(item: any):  Promise<any> {
		let data = await options
			.safetyAssessmentQuestions();

		data = data?.filter((question: any) => {
				if (item.question_id == question.id) {
					return question;
				}
			})
			.map((question: object) => {
				return question;
			});

		if (data.length > 0) {
			return data[0];
		}

		return null;
	}

	safetyAssessmentStatus(item: any): object {
		const data = CaseAssessmentStatus.filter((status: any) => {
			if (item.status == status.id) {
				return status;
			}
		}).map((status: any) => {
			return { description: status.label };
		});

		if (data.length > 0) {
			return data[0];
		}

		return { description: "" };
	}

	currentLocation(item: any): object {
		const data = CurrentLocation.filter((location: any) => {
			if (item?.current_location == location.id) {
				return location;
			}
		}).map((location: any) => {
			return { description: location.label };
		});

		if (data.length > 0) {
			return data[0];
		}

		return { description: "" };
	}

	perpetratorIdentity(item: any): object {
		const data = PerpetratorIdentity.filter((identity: any) => {
			if (item?.identity == identity.id) {
				return identity;
			}
		}).map((identity: any) => {
			return { description: identity.label };
		});

		if (data.length > 0) {
			return data[0];
		}

		return { description: "" };
	}

	async perpetratorRelationship(id: number):  Promise<any> {
		let data = await options
			.perpetratorRelationships();

		data = data?.filter((relationship: any) => {
				if (id == relationship.id) {
					return relationship;
				}
			})
			.map((relationship: object) => {
				return relationship;
			});

		if (data.length > 0) {
			return data[0];
		}

		return null;
	}

    async hospitalAreas(id: number): Promise<any> {
        if (!id) {
            return null;
        }
        
        let data = await options
			.hospitalAreas();

		data = data?.filter((hospitalArea: any) => {
				if (id == hospitalArea.id) {
					return hospitalArea;
				}
			})
			.map((hospitalArea: object) => {
				return hospitalArea;
			});

		if (data.length > 0) {
			return data[0];
		}

		return null;
    }

    sleepingArrangement(id: any): object {
		const data = options.sleepingArrangements().filter((item: any) => {
			if (id == item.id) {
				return item;
			}
		}).map((item: any) => {
			return { description: item.label };
		});

		if (data.length > 0) {
			return data[0];
		}

		return { description: "" };
	}

    sleepingArrangementWith(id: any): object {
		const data = options.sleepipngArrangmentWith().filter((item: any) => {
			if (id == item.id) {
				return item;
			}
		}).map((item: any) => {
			return { description: item.label };
		});

		if (data.length > 0) {
			return data[0];
		}

		return { description: "" };
	}

    livingArrangement(id: any): object {
		const data = options.livingArrangements().filter((item: any) => {
			if (id == item.id) {
				return item;
			}
		}).map((item: any) => {
			return { description: item.label };
		});

		if (data.length > 0) {
			return data[0];
		}

		return { description: "" };
	}
}
