<template>
	<user-layout mainCustomClass="intervention-generator">
		<div class="-mx-6 w-[calc(100%+48px)]">
			<div
				class="fixed top-[72px] left-0 z-10 bg-white px-6 py-4 shadow-[0_1px_0px_0px_rgba(0,0,0,0.08)] flex justify-between items-center w-full">
				<a @click="router.replace('/settings/intervention-types/index')">
					<button-component customClass="tertiary text-black hover:text-primary-600 transition">
						<!-- <img src="assets/icon/ic-arrow-left.svg" alt="back-arrow" class="object-contain w-6 h-6 mr-2" /> -->
						<arrow-left-icon class="object-contain w-6 h-6 mr-2" />
						<span class="text-sm font-semibold">Back</span>
					</button-component>
				</a>

				<tab-component :tabs="tabs" />

				<div class="w-[220px] justify-end flex space-x-2">
					<button-component
					v-if="newForm"
					customClass="secondary">
						<span>Save & Display</span>
					</button-component>
					<button-component
					v-if="!newForm"
					customClass="secondary">
						<span>Save</span>
					</button-component>
					<button-component
					v-if="!newForm"
					@click="showSubfromModal = true">
						<PlusIcon class="w-4 h-4 text-primary-600 stroke-[3px] -ml-2 mr-1 icon" />
						<span>Add Sub-Form</span>
					</button-component>
				</div>

			</div>
			<div class="mt-[72px]">
				<div v-if="route.query.tab != 'activity_logs'" class="bg-primary-100">
					<div class="flex items-start">
						<div class="h-[calc(100vh-146px)] flex-1 flex justify-center space-y-6 overflow-auto">
							<form-section
								:list="list"
								@add="add"
								@removeAt="removeAt"
							/>
						</div>
						<div class="h-[calc(100vh-146px)] w-[400px] p-6 bg-white flex flex-col relative">
							<div class="pb-2">
								<div class="text-neutral-900 text-sm">
									<p>Field Types</p>
								</div>
								<div class="flex rounded border border-neutral-100 mt-5 overflow-hidden">
									<div class="text-center py-3 flex-1 cursor-pointer"
										@click="selected = false"
										:class="selected ? 'text-gray-500' : 'bg-primary-100 text-primary-500'">
										<p class="text-xs">All Fields</p>
									</div>
									<div class="text-center py-3 flex-1 cursor-pointer"
										@click="selected = true"
										:class="selected ? 'bg-primary-100 text-primary-500' : 'text-gray-500'">
										<p class="text-xs">Fields w/ Sub-form</p>
									</div>
								</div>
							</div>
							<div class="flex-1 overflow-auto">
								<div class="w-full grid grid-cols-2 gap-5">
									<template v-for="(item) in formList" :key="item.id">
										<div
											v-if="!selected"
											class="col-span-1 rounded bg-gray-50 hover:bg-primary-300 flex flex-col items-center justify-center group p-6 h-[100px] cursor-pointer"
											@click="openModal(item)"
										>
											<p class="text-sm text-neutral-900 group-hover:text-primary-600 mb-2">{{ item.title }}</p>
											<img :src="item.image" class="object-contain h-6 grayscale opacity-50 group-hover:grayscale-0 group-hover:opacity-100" alt="icon">
										</div>
										<div
											v-if="selected && item.subform"
											class="col-span-1 rounded bg-gray-50 hover:bg-primary-300 flex flex-col items-center justify-center group p-6 h-[100px] cursor-pointer"
											@click="openModal(item)"
										>
											<p class="text-sm text-neutral-900 group-hover:text-primary-600 mb-2">{{ item.title }}</p>
											<img :src="item.image" class="object-contain h-6 grayscale opacity-50 group-hover:grayscale-0 group-hover:opacity-100" alt="icon">
										</div>
									</template>
								</div>
							</div>

							<input-modal
								:show="showInputModal"
								@close="showInputModal = false"
								@confirm="showInputModal = false"
								title="Input Field" />

							<textarea-modal
								:show="showTextareaModal"
								@close="showTextareaModal = false"
								@confirm="showTextareaModal = false"
								title="Text Area" />

							<dropdown-modal
								:show="showDropdownModal"
								@close="showDropdownModal = false"
								@confirm="showDropdownModal = false"
								title="Dropdown" />

							<multiselect-modal
								:show="showMultiselectModal"
								@close="showMultiselectModal = false"
								@confirm="showMultiselectModal = false"
								title="Multi-select" />

							<radio-modal
								:show="showRadioModal"
								@close="showRadioModal = false"
								@confirm="showRadioModal = false"
								title="Radio Button" />

							<toggle-modal
								:show="showToggleModal"
								@close="showToggleModal = false"
								@confirm="showToggleModal = false"
								title="Toggle" />

							<checkbox-modal
								:show="showCheckboxModal"
								@close="showCheckboxModal = false"
								@confirm="showCheckboxModal = false"
								title="Checkbox" />

							<link-modal
								:show="showLinkModal"
								@close="showLinkModal = false"
								@confirm="showLinkModal = false"
								title="Link" />

							<date-time-modal
								:show="showDateTimeModal"
								@close="showDateTimeModal = false"
								@confirm="showDateTimeModal = false"
								title="Date & Time" />
						</div>
					</div>
				</div>
				<div v-if="route.query.tab === 'activity_logs'" class="p-6">
					<div class="border rounded-lg border-neutral-100">
						<div v-if="filters.tab != 'activity_logs'">
							<ActivityLogsTable
							v-if="filters.tab == 'activity_logs'"
							:items="items"
						/>
						</div>

						<div class="w-full px-6 border-t py-7">
							<simple-pagination 
								:numRows="filters.rows" 
								:currentPage="items.current_page" 
								:last-page="items.last_page" 
								@update="(value: any) => paginate(value)"
							/>
						</div>
					</div>
				</div>
			</div>
		</div>

		<page-loader :show="loading"/>

		<!-- Add Sub Form -->
		<content-modal
			:show="showSubfromModal" 
			title="Add Conditional Form Trigger"
		>
			<div class="flex space-x-6">
				<div class="w-1/2">
					<multi-select
						:options="changeMeOptions"
						label="Parent Field"
						id="parent_field"
						name="parent_field"
						placeholder="Select.."
						searchable
					/>
				</div>
				<div class="w-1/2">
					<multi-select
						:options="changeMeOptions"
						label="Option Value"
						id="option_value"
						name="option_value"
						placeholder="Select.."
						searchable
					/>
				</div>
			</div>

			<template #button>
				<button-component  
					btnWidth="quinary"
					@click="showSubfromModal = false"
				>
					Cancel
				</button-component >
				<button-component 
					customClass="primary" 
				>
					Submit
				</button-component>
			</template>
		</content-modal>


	</user-layout>
</template>
<script setup lang="ts">
import { computed, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import UserLayout from "@/layouts/UserLayout.vue";
import ButtonComponent from "@/components/buttons/ButtonComponent.vue";
import MultiSelect from "@/components/inputs/MultiSelect.vue";
import TabComponent from "@/components/partials/TabComponent.vue";
import { PlusIcon } from "@heroicons/vue/24/outline";
import FormSection from "./FormSection.vue";
import ConfirmationModal from "@/components/modals/ConfirmationModal.vue";
import SuccessModal from "@/components/modals/SuccessModal.vue";
import TeleCpuLocationService from "@/classes/Settings/TeleCpuLocationService";
import TeleCpuOfficeService from "@/classes/Settings/TeleCpuOfficeService";
import { errorResponse } from "@/helpers/errorMessage";
import throttle from "lodash/throttle";
import pickBy from "lodash/pickBy";

import PageLoader from "@/components/loaders/PageLoader.vue"
import SimplePagination from "@/components/partials/SimplePagination.vue";
import ActivityLogsTable from "@/views/activity-logs/ActivityLogsTable.vue"

import { filters, items } from "@/interfaces/pages/FilterPageInterface";
import assignValues from "@/helpers/assignValues";

import InputModal from "./Modals/InputModal.vue";
import TextareaModal from "./Modals/TextareaModal.vue";
import DropdownModal from "./Modals/DropdownModal.vue";
import MultiselectModal from "./Modals/MultiselectModal.vue";
import RadioModal from "./Modals/RadioModal.vue";
import ToggleModal from "./Modals/ToggleModal.vue";
import CheckboxModal from "./Modals/CheckboxModal.vue";
import LinkModal from "./Modals/LinkModal.vue";
import DateTimeModal from "./Modals/DateTimeModal.vue";

import ContentModal from "./Components/ContentModal.vue";
import ArrowLeftIcon from "@/components/icons/arrowLeftIcon.vue";

const route = useRoute();
const router = useRouter();
const dataroute = useRoute();
const showFilter = ref<boolean>(false);
const loading = ref<boolean>(false);
const offices = ref(null);
const successMessage = ref<string>("Data has been successfully updated!");
const form = ref<{ name: string }>({
	name: "",
});
const tab = ref<any>(route.query.tab);
const datatab = ref<any>(route.query.datatab);
const errors = ref<any>();
const locationId = computed<any>(() => route.params.location);
const filterSearch = ref<any>(route.query.query);
const page = ref<any>(route.query.page);
const lastPage = ref<any>(null);
const rows = ref<any>(route.query.rows || 5);
const numRows = computed(() => (rows.value ? rows.value + " Rows" : "All"));
const showConfirmation = ref<boolean>(false);
const showSuccess = ref<boolean>(false);
const showRestoreConfirmation = ref<boolean>(false);
const showRestoreSuccess = ref<boolean>(false);
const officeId = ref<number>(0);
const filterDate = ref<any>(route.query.date);
const allCount = ref(0)

const newForm = ref<boolean>(false);
const selected = ref<boolean>(false);
const showInputModal = ref<boolean>(false);
const showTextareaModal = ref<boolean>(false);
const showDropdownModal = ref<boolean>(false);
const showMultiselectModal = ref<boolean>(false);
const showRadioModal = ref<boolean>(false);
const showToggleModal = ref<boolean>(false);
const showCheckboxModal = ref<boolean>(false);
const showLinkModal = ref<boolean>(false);
const showDateTimeModal = ref<boolean>(false);

const showSubfromModal = ref<boolean>(false);

const list = ref([
	{ fieldLabel: "Name of Personnel", fieldType: "input-field", width: "half"},
	{ fieldLabel: "Remarks", fieldType: "text-area", width: "whole"},
	{ fieldLabel: "Assigned Group", fieldType: "dropdown", width: "half" },
	{ fieldLabel: "To Be Done By", fieldType: "multi-select", width: "whole" },
	{ fieldLabel: "Is currently in rehabilitation?", fieldType: "radio-button", width: "whole" },
	{ fieldLabel: "Is currently in rehabilitation?", fieldType: "toggle", width: "whole" },
	{ fieldLabel: "Comorbid VAC/VAW", fieldType: "checkbox", width: "whole" },
	{ fieldLabel: "Attachment Link", fieldType: "link", width: "whole" },
	{ fieldLabel: "Schedule Date & Time", fieldType: "date-time", withTime: true, width: "whole" },
]);

const id = ref(3);

const add = () => {
  list.value.push({ fieldLabel: "Juan " + id.value, id: id.value++, fieldType: "input-field" });
};

const removeAt = (idx: number) => {
	list.value.splice(idx, 1);
};

const changeMeOptions = [
	{ id: '1', label: 'Change', value: 'Change'},
	{ id: '2', label: 'Me', value: 'Me'},
]

const formList = ref<{ title: string; modal: any; subform?: boolean; image: string; }[]>([
	{
		title: 'Input Field',
		modal: 'showInputModal',
		image: '/assets/icon/intervention/ic-text.svg',
	},
	{
		title: 'Text Area',
		modal: 'showTextareaModal',
		image: '/assets/icon/intervention/ic-textarea.svg',
	},
	{
		title: 'Dropdown',
		modal: 'showDropdownModal',
		subform: true,
		image: '/assets/icon/intervention/ic-dropdown.svg',
	},
	{
		title: 'Multi-Select',
		modal: 'showMultiselectModal',
		image: '/assets/icon/intervention/ic-multiselect.svg',
	},
	{
		title: 'Radio Button',
		modal: 'showRadioModal',
		subform: true,
		image: '/assets/icon/intervention/ic-radio-button.svg',
	},
	{
		title: 'Toggle',
		modal: 'showToggleModal',
		subform: true,
		image: '/assets/icon/intervention/ic-toggle.svg',
	},
	{
		title: 'Checkbox',
		modal: 'showCheckboxModal',
		subform: true,
		image: '/assets/icon/intervention/ic-checkbox.svg',
	},
	{
		title: 'Link',
		modal: 'showLinkModal',
		image: '/assets/icon/intervention/ic-link.svg',
	},
	{
		title: 'Date & Time',
		modal: 'showDateTimeModal',
		image: '/assets/icon/intervention/ic-datepicker.svg',
	},
]);

const openModal = (item: any) => {
	if (item.modal == 'showInputModal') {
		showInputModal.value = true;
	} else if (item.modal == 'showTextareaModal') {
		showTextareaModal.value = true;
	} else if (item.modal == 'showDropdownModal') {
		showDropdownModal.value = true;
	} else if (item.modal == 'showMultiselectModal') {
		showMultiselectModal.value = true;
	} else if (item.modal == 'showRadioModal') {
		showRadioModal.value = true;
	} else if (item.modal == 'showToggleModal') {
		showToggleModal.value = true;
	} else if (item.modal == 'showCheckboxModal') {
		showCheckboxModal.value = true;
	} else if (item.modal == 'showLinkModal') {
		showLinkModal.value = true;
	} else if (item.modal == 'showDateTimeModal') {
		showDateTimeModal.value = true;
	}
};

const tabs = ref<{ name: string; count: number; tab: string; href: string; allowed: boolean }[]>([
	{
		name: "Form Builder",
		count: 0,
		tab: "",
		href: `/settings/intervention-types/view`,
		allowed: true,
	},
	{
		name: "Activity Logs",
		count: 0,
		tab: "activity_logs",
		href: `/settings/intervention-types/view?tab=activity_logs`,
		allowed: true,
	},
]);

const save = (): void => {
	TeleCpuLocationService.update(
		{
			location: route.params.location,
		},
		form.value
	)
		.then((response: any) => {
			showConfirmation.value = true;
			successMessage.value = response.data.message;
		})
		.catch((error: any) => (errors.value = errorResponse(error.response?.data?.errors)));
};

const redirectSuccess = (): void => {
	showSuccess.value = false;
	router.replace("/settings/intervention-types/index");
};

/**
 * METHODS
 */
const getData = (): void => {
	loading.value = false

	TeleCpuLocationService.edit({location: route.params.location}, pickBy(filters.value))
		.then(({ data }) => {

			form.value.name = data.item.name;
			offices.value = data.offices;
			
			items.value = data.offices.items;
			filters.value = assignValues(filters.value, data.offices);
			loading.value = false
			// dataTabletabs.value[1].count = response.data.archivedItemsCount;
			// dataTabletabs.value[2].count = response.data.activityLogsCount;
		})
		.catch((error: any) => console.log(error));
};

const reloadPage = async () => {
	return await router.push({
		path: `/settings/intervention-types/view`,
		query: pickBy({
			query: filterSearch.value,
			page: page.value,
			rows: rows.value,
			tab: tab.value,
			datatab: datatab.value,
			date: filterDate.value,
		}),
	});
};

const applyFilters = (search: any = null) => {
	filters.value.query = search;
	showFilter.value = false;

	getData();
};

const applyReset = () => {
	filterDate.value = "";
	reloadPage().then(() => {
		getData();
		showFilter.value = false;
	});
};

const paginate = ({ page, rows }): void => {
	filters.value = Object.assign(filters.value, {
		page, rows
	})
	getData();
};

const updateTab = (currentTab: any) => {

	// const identify = typeof currentTab === "object";
	// tab.value = identify ? currentTab.tab : currentTab;
	filters.value.tab = currentTab;
	filters.value.page = 1;
	// filterSearch.value = "";
	// datatab.value = identify ? currentTab.datatab : "";
	applyFilters();
};

/**
 * MODALS
 */
const showDeleteModal = (item: any): void => {
	showConfirmation.value = true;
	officeId.value = item.id;
};

const closeDeleteModal = (): void => {
	showSuccess.value = false;
};

const deleteRecord = (): void => {
	TeleCpuOfficeService.delete({
		location: locationId.value,
		telcpu: officeId.value,
	})
		.then(() => {
			showSuccess.value = true;
			showConfirmation.value = false;
			applyFilters();
		})
		.catch((error) => console.log(error));
};

const showRestoreModal = (item: any): void => {
	showRestoreConfirmation.value = true;
	officeId.value = item.id;
};

const closeRestoreModal = (): void => {
	showRestoreSuccess.value = false;
};

const restoreRecord = (): void => {
	TeleCpuOfficeService.restore({
		location: locationId.value,
		telcpu: officeId.value,
	})
		.then(() => {
			showRestoreSuccess.value = true;
			showRestoreConfirmation.value = false;
			applyFilters();
		})
		.catch((error) => console.log(error));
};

/**
 * WATCHERS
 */
watch(
	filterSearch,
	throttle((val: string) => {
		applyFilters(val);
	}, 1000)
);

onMounted(() => {
	getData();
});
</script>
