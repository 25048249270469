<template>
    <ion-page>
        <ion-content :fullscreen="true">
            <div class="flex flex-wrap lg:flex-row flex-col lg:items-center h-screen lg:justify-between">
                <auth-logo />

                <div class="w-full px-4 lg:w-1/2 sm:px-20 lg:py-0 py-6">
                    <div class="sm:max-w-[360px] mx-auto">
                        <auth-title
                            title="Forgot Password"
                            description="Kindly provide your email address" 
                        />
                        <form class="" @submit.prevent="submit">
                            <div class="mb-6">
                                <text-input
                                    type="email"
                                    label="Email Address"
                                    placeholder="Email Address"
                                    name="email"
                                    id="email"
                                    v-model="email"
                                    :error="errors?.email"
                                />
                            </div>

                            <button-component
                                type="submit"
                                size="large"
                                btnWidth="w-full rounded-lg"
                            >
                                Submit
                            </button-component>

                            <div class="mt-6 text-center">
                                <a href="/login" class="m-0 text-sm transition text-primary-600 hover:text-primary-800">Return to Login</a>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <success-modal
                :show="showSuccess"
                @confirm="confirm"
                action-text="Close"
                title="Success!"
                :content="successMessage"
            />

            <page-loader :show="loading"/>
        </ion-content>
    </ion-page>
</template>

<script setup lang="ts">

import { ref } from "vue";

import {
    IonPage,
    IonContent 
} from '@ionic/vue';
import ButtonComponent from '@/components/buttons/ButtonComponent.vue';
import TextInput from '@/components/inputs/TextInput.vue';
import PageLoader from "@/components/loaders/PageLoader.vue"
import SuccessModal from '@/components/modals/SuccessModal.vue';

import ForgotPassword from '@/classes/ForgotPassword';

import { errorResponse } from "@/helpers/errorMessage";

import { useRoute, useRouter } from 'vue-router';

import AuthLogo from "../Components/AuthLogo.vue";
import AuthTitle from "../Components/AuthTitle.vue";

const router = useRouter();
const route = useRoute();

const loading = ref<boolean>(false);
const showSuccess = ref<boolean>(false);
const email = ref<string>('');
const successMessage = ref<string>('');
const errors = ref<any>();

const submit = (): void => { 
    loading.value = true;
    ForgotPassword.instantiate({ email: email.value })
        .then(({ data }) => {
            loading.value = false;
            showSuccess.value = true;
            successMessage.value = data.success;
            // redirect
            // href = "/verification-code"
        }).catch((error) => { 
            loading.value = false;

            errors.value = errorResponse(error.response?.data?.errors)
        });
}

const confirm = (): void => {
    router.replace({ path: 'verification-code', query: { email: email.value } })
}

</script>