<template>
    <ion-page>
        <ion-content :fullscreen="true">
            <div class="flex flex-wrap lg:flex-row flex-col lg:items-center h-screen lg:justify-between">
                <auth-logo />

                <div class="w-full px-4 lg:w-1/2 sm:px-20 lg:py-0 py-6" v-if="showForm">
                    <div class="sm:max-w-[360px] mx-auto">
                        <auth-title
                            title="New Password"
                            description="Kindly provide your new password" 
                        />
                        <form class="" @submit.prevent="checkPassword">
        
                            <div class="mb-6">
                                <password-input
                                    label="New Password"
                                    placeholder="New Password"
                                    name="new-password"
                                    id="new-password"
                                    v-model="form.password"
                                    :error="errors?.password"
                                />
                            </div>
    
                            <div class="mb-6">
                                <password-input
                                    label="Confirm Password"
                                    placeholder="Confirm Password"
                                    name="confirm-password"
                                    id="confirm-password"
                                    v-model="form.password_confirmation"
                                    :error="errors?.password_confirmation"

                                />
                            </div>
                            <button-component
                                type="button"
                                size="large"
                                btnWidth="w-full rounded-lg"
                                @click="submit"
                            >
                                Set New Password
                            </button-component>
                            <div class="mt-6 text-center">
                                <a href="/login" class="m-0 text-sm transition text-primary-600 hover:text-primary-800">Return to Login</a>
                            </div>
                        </form>
                    </div>

                    <!-- <div class="max-w-[360px] mx-auto" v-if="step == 2">

                        <div class="mb-6">
                            <p class="text-lg font-bold text-neutral-900">This is your 6-digit pincode</p>
                            <p class="text-sm text-gray-500">Please save your 6-digit pincode</p>
                        </div>
                        
                        <p v-if="errors?.pin_code" class="text-[10px] mt-1" :class="errors?.pin_code ? 'text-red-600' : 'text-gray-800'" id="email-error">
                            {{ errors.pin_code }}
                        </p>
                        
                        <otp
                            :showResend='false'
                            bottonName="Submit"
                            hasSubmit
                            random      
                            @update:submit="(value:string) => verify(value)"
                        />
                    </div> -->
                    
                </div>
            </div>

            <success-modal
                :show="showSuccess"
                @confirm="router.replace('login'); showSuccess = false"
                action-text="Confirm"
                title="Verification Completed!"
                content="You have successfully set up your account."
            />

             <general-modal
                :show="showAccountVerified"
                @confirm="router.replace('login')"
                action-text="Go to Login"
                title="Error!"
                content="This account is aleardy verified. Please proceed to login"
            />
        </ion-content>
    </ion-page>
</template>

<script setup lang="ts">
import { ref, onMounted, inject } from 'vue';
import {
    IonPage,
    IonContent 
} from '@ionic/vue';

import Otp from '@/components/forms/Otp.vue';

import ButtonComponent from '@/components/buttons/ButtonComponent.vue';
import PasswordInput from '@/components/inputs/PasswordInput.vue';
import SuccessModal from '@/components/modals/SuccessModal.vue';
import GeneralModal from '@/components/modals/GeneralModal.vue';

import Verification from '@/classes/Auth/Verification';

import { errorResponse } from "@/helpers/errorMessage";

import { useRoute, useRouter } from 'vue-router';

import AuthLogo from "../Components/AuthLogo.vue";
import AuthTitle from "../Components/AuthTitle.vue";

const router = useRouter();
const route = useRoute();

const showSuccess = ref<boolean>(false);
const showAccountVerified = ref<boolean>(false);
const showForm = ref<boolean>(true);
const errors = ref<any>([]);

const step = ref<number>(1);

const form = ref<{
    password: string|null,
    password_confirmation: string|null,
    pin_code: string|null,
    email: string,
    token: string,
}>({
    password: null,
    password_confirmation: null,
    pin_code: null,
    email: '', 
    token: '', 
})

const submit = (): void => {
    Verification.verify(form.value)
        .then(({ data }) => {
            showSuccess.value = true;
        })
        .catch((error: any) => (errors.value = errorResponse(error.response?.data?.errors)));

}

const init = (): void => {
    if (typeof route.query.email == 'undefined' && typeof route.query.token == 'undefined' ) {
        router.replace('login')
    } else {
        console.log(route.query.email);
        form.value.email = (route.query.email).toString()
        form.value.token = (route.query.token).toString()

        Verification.isVerified(form.value).catch(() => {
            showAccountVerified.value = true
            showForm.value = false
        });
    }
}

onMounted(() => {
    init();
})

</script>