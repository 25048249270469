<template>
    <tr>
        <td class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
            <!-- :class="[
                item.status == 'Past Due' ? 'bg-red-600' : '',
                item.status == 'Scheduled' ? 'bg-green-600' : '',
                item.status == 'Done' ? 'bg-primary-600' : '',
            ]" -->
            <div
                class="px-3 py-2 text-sm text-white rounded w-max bg-primary-600"
            >
                {{ DateFormatter.customDateFormat(item.schedule_date, "MMM dd, yyy - hh:mm a ") }}
            </div>
        </td>
    
        <td class="px-6 py-6 text-sm text-center text-gray-900 whitespace-nowrap">
            <div
                    class="w-max flex rounded py-[7px] px-4 font-semibold text-xs text-white transition"
                    :class="item?.done_on ? 'bg-neutral-400' : 'bg-[#82c0cf]'"
                >
                <!-- <img
                    v-if="fetchInterventionIcon(item.intervention_type)"
                    :src="fetchInterventionIcon(item.intervention_type)"
                    alt="social_work_icon"
                    class="object-contain w-4 h-4 mr-1 -ml-2 icon" 
                /> -->
                <component
                    v-if="fetchInterventionIcon(item.intervention_type)"
                    :is="fetchInterventionIcon(item.intervention_type)"
                    class="object-contain w-4 h-4 mr-1 -ml-2 icon"
                />
                <PlusIcon v-else class="w-4 h-4 stroke-[3px] -ml-2 mr-1 icon" />
                <span>{{ item.intervention_type_name }}</span>
            </div>
        </td>
        <td  class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
            {{ item.type }}
        </td>
        <td  class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
            {{ item?.subtype ?? '-' }}
        </td>
        <td class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
            {{ item.case_number }}
        </td>
        <td class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
            {{ item.done_by }}
        </td>
        <td class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
            
            {{ item?.done_on ? DateFormatter.customDateFormat(item.done_on, "MMM dd, yyy - hh:mm a ") : '-' }}
        </td>
        <td class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
            <span
                class="text-xs font-semibold px-2 py-1 leading-[18px] rounded-lg text-white mr-2"
                :class="
                    item.status == 'Scheduled' ? 'bg-green-600' : item.status == 'Past Due' ? 'bg-red-600' : 'bg-primary-600'
                ">
                {{ item.status }}
            </span>
        </td>
        <td class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
            <div 
                class="flex items-center justify-center mx-auto" 
                v-if="route.query.tab !== 'archived'"
            >
                <edit-button
                    class="shrink-0"
                    :routeLink="editRoute(item)"
                />
                <delete-button
                    v-if="item.status !== 'Done'"
                    @click="removeConfirmation(item)"
                    class="shrink-0"
                />
            </div>
            <div 
                class="flex items-center justify-center mx-auto shrink-0"
                v-else
            >
                <RestoreButton
                    @click="showRestoreModal(item)" 
                />
            </div>
        </td>
    </tr>

    <confirmation-modal
        :typeTwo="true"
        :show="showConfirmation"
        @cancel="showConfirmation = false"
        @confirm="remove()"
        cancel-text="Cancel"
        action-text="Confirm"
        title="Delete Record?"
        content="Are you sure you wish to delete this record?" 
    />

    <success-modal
        :show="showSuccess"
        @confirm="redirectSuccess()"
        action-text="Close"
        title="Record Deleted!"
        :content="deleteSuccessMessage" 
    />
    
    <confirmation-modal
        :typeTwo="true"
        :show="showRestoreConfirmation"
        @cancel="showRestoreConfirmation = false"
        @confirm="restore()"
        cancel-text="Cancel"
        action-text="Confirm"
        title="Restore Record?"
        content="Are you sure you wish to restore this record?" 
    />
    <success-modal
        :show="showRestoreSuccess"
        @confirm="redirectRestoreSuccess()"
        action-text="Close"
        title="Record Restored!"
        :content="restoreSuccessMessage" 
    />
</template>


<script setup lang="ts">
import { ref, type PropType, computed, Component } from "vue";
import { Menu, MenuButton, MenuItems, MenuItem } from "@headlessui/vue";
import DateDetail from "@/components/partials/DateDetail.vue";
import ConfirmationModal from "@/components/modals/ConfirmationModal.vue";
import SuccessModal from "@/components/modals/SuccessModal.vue";
import { TrashIcon } from "@heroicons/vue/24/solid";
import { useRouter, useRoute } from "vue-router";
import { InterventionItems } from "@/interfaces/Cases/Interevention";
import InterventionService from "@/classes/Cases/InterventionService";
import { errorResponse } from "@/helpers/errorMessage";
import { InterventionType } from "@/views/enums/InterventionType";
import pickBy from "lodash/pickBy";
import { useNetworkStore } from "@/store/network";
import { PlusIcon } from "@heroicons/vue/24/outline";

import EditButton from "@/components/buttons/EditButton.vue";
import ViewButton from "@/components/buttons/ViewButton.vue";
import DeleteButton from "@/components/buttons/DeleteButton.vue";
import RestoreButton from "@/components/buttons/RestoreButton.vue";

import SocialWorkIcon from "@/components/icons/socialWorkIcon.vue";
import MentalHealthIcon from "@/components/icons/mentalHealthIcon.vue";
import DevelopmentalPediaIcon from "@/components/icons/developmentalPediaIcon.vue";
import LegalIcon from "@/components/icons/legalIcon.vue";

import DateFormatter from "@/helpers/DateFormatter";

const router = useRouter();
const route = useRoute();
const patientId = ref<number>(route.params.patient);
const caseId = ref<number | null>(route.params.case);
const selectedId = ref<number|null>(null);
const deleteSuccessMessage = ref<string>("Selected record has been successfully deleted.");
const restoreSuccessMessage = ref<string>("Selected record has been successfully restored.");

const props = defineProps({
	typeTwo: {
		type: Boolean,
		default: false,
	},
	interventionType: {
		type: String,
		default: null,
	},
	caseNumber: {
		type: String,
		default: null,
	},
	doneBy: {
		type: String,
		default: null,
	},
	doneOn: {
		type: String,
		default: null,
	},
	status: {
		type: String,
		default: "Scheduled", // Scheduled | Past Due | Done
	},
	item: {
		type: Object as PropType<InterventionItems>,
		required: true,
	},
});

const showConfirmation = ref<boolean>(false);
const showSuccess = ref<boolean>(false);
const showRestoreConfirmation = ref<boolean>(false);
const showRestoreSuccess = ref<boolean>(false);
const errors = ref<object | null>(null);

const networkStore = useNetworkStore();
const isNetworkAvailable = computed(() => networkStore.isNetworkAvailable);
const offlineParams = ref<string>(route.query.offline);
const selectedItemId = computed(() => props.item.id ?? props.item?.offlineData?.offline_id);

const editRoute = (item:any): string => {
    if(caseId?.value){
        return `/patient/${item.patient_id}/cases/${item.cases_id}/intervention/${item.intervention_type}/edit/${item.id}`;
    } else {
        return `/patient/${item.patient_id}/intervention/${item.intervention_type}/edit/${item.id}`;
    }
}

const removeConfirmation = (item: number): void => {
    selectedItemId.value = item,
    showConfirmation.value = true
}

const remove = (): void => {
	showConfirmation.value = false;
	InterventionService.delete(
		patientId.value,
		caseId.value,
		selectedItemId.value,
		pickBy({ isOfflineData: offlineParams.value ? true : false }),
		isNetworkAvailable.value
	)
	.then((response: object) => {
		showSuccess.value = true;
		deleteSuccessMessage.value = response.data.message;
	})
	.catch((error: object) => {
		console.log(error)
		errors.value = errorResponse(error.response?.data?.errors);
	});
};

const showRestoreModal = (item: any): void => {
    showRestoreConfirmation.value = true;
    selectedId.value = item.id;
}

const restore = (): void => {
	showRestoreConfirmation.value = false;
	InterventionService.restore(patientId.value, caseId.value, selectedId.value, {})
		.then((response: object) => {
			showRestoreSuccess.value = true;
			restoreSuccessMessage.value = response.data.message;
		})
		.catch((error: object) => {
			errors.value = errorResponse(error.response?.data?.errors);
		});
};

const redirectSuccess = (): void => {
	showSuccess.value = false;
	window.location.reload();
};

const redirectRestoreSuccess = (): void => {
	showSuccess.value = false;
	showRestoreSuccess.value = false;
        
    let url = '';
    
    if(caseId?.value){
        url = `/patient/${patientId.value}/cases/${caseId.value}/interventions`;
    } else {
        url = `/intervention/${patientId.value}/view`;
    }

    window.location.replace(url);
};

const fetchInterventionIcon = (intervention:number): Component|null => {
    switch (intervention) {
        case InterventionType.SOCIAL_WORK:
            // return  "/assets/icon/intervention/ic-social-work.svg"
            return  SocialWorkIcon;
        case InterventionType.MENTAL_HEALTH:
            // return  "/assets/icon/intervention/ic-mental-health.svg"
            return  MentalHealthIcon
        case InterventionType.DEVELOPMENT_PEDIA:
            // return  "/assets/icon/intervention/ic-developmental-pedia.svg"
            return  DevelopmentalPediaIcon
        case InterventionType.LEGAL:
            // return  "/assets/icon/intervention/ic-legal.svg"
            return  LegalIcon
        default:
            return null;
    }
}
</script>