const CaseConferenceRecommendation = {
	name: "case_conference_recommendations",
	schema: [
		{ column: "id", value: "INTEGER PRIMARY KEY AUTOINCREMENT" },

		{ column: "case_conference_id", value: "INTEGER UNSIGNED" },
		{ column: "recommendation_option_id", value: "INTEGER UNSIGNED" },
        { column: "remarks", value: "LONGTEXT NULL" },

		{ column: "action", value: "INT NULL" },
		{ column: "is_sync", value: "BOOLEAN DEFAULT FALSE" },
        { column: "is_sync_failed", value: "BOOLEAN DEFAULT FALSE"},

        { column: "is_created_offline", value: "BOOLEAN DEFAULT FALSE" },
        { column: "is_updated_offline", value: "BOOLEAN DEFAULT FALSE" },
        { column: "is_deleted_offline", value: "BOOLEAN DEFAULT FALSE" },

        { column: "created_at", value: "TIMESTAMP NULL" },
		{ column: "updated_at", value: "TIMESTAMP NULL" },
		{ column: "deleted_at", value: "TIMESTAMP NULL" },

        { column: "synching_remarks", value: "TEXT DEFAULT NULL" },
	],
};

export default CaseConferenceRecommendation;
