const InterventionsFormStructure = {
	name: "intervention_form_structure",
	schema: [
		{ column: "id", value: "BIGINT UNSIGNED" },
		{ column: "offline_id", value: "INTEGER PRIMARY KEY AUTOINCREMENT" },
		{ column: "intervention_type", value: "BIGINT UNSIGNED" },
		{ column: "form", value: "TEXT" },
		{ column: "is_sync", value: "BOOLEAN DEFAULT FALSE" },
		{ column: "action", value: "INT UNSIGNED" },
	],
};

export default InterventionsFormStructure;
