<template>
	<user-layout hasBack backLink="/personnel/index">
		<div class="max-w-[500px] mx-auto py-6">
			<form-section
				:disabled="true"
				:form="form"
				:errors="errors"
				:departments="departmentOptions"
				:positions="positionOptions"
				:userGroups="userGroupOptions"
				:offices="officeOptions"></form-section>
		</div>
	</user-layout>
</template>
<script setup lang="ts">
import { onMounted, ref } from "vue";
import UserLayout from "@/layouts/UserLayout.vue";
import FormSection from "./FormSection.vue";
import { useRoute } from "vue-router";
import Personnels from "@/classes/Personnels";

const route = useRoute();

const showConfirmation = ref<boolean>(false);
const showSuccess = ref<boolean>(false);
const successMessage = ref<string>("Data has been successfully updated!");
const form = ref<{
	email: string;
	last_name: string;
	first_name: string;
	middle_name: string;
	mobile_number: string;
	home_address: string;
	is_temporary: boolean;
	department_id: any;
	office_id: any;
	role_id: any;
	position_id: any;
}>({
	email: "",
	last_name: "",
	first_name: "",
	middle_name: "",
	mobile_number: "",
	home_address: "",
	is_temporary: false,
	department_id: "",
	office_id: "",
	position_id: "",
	role_id: "",
});
const errors = ref<any>();
const officeOptions = ref<[]>([]);
const userGroupOptions = ref<[]>([]);
const positionOptions = ref<[]>([]);
const departmentOptions = ref<[]>([]);

onMounted(() => {
	Personnels.edit(route.params.personnel)
		.then((response: any) => {
			form.value.email = response.data.item.email;
			form.value.first_name = response.data.item.first_name;
			form.value.last_name = response.data.item.last_name;
			form.value.middle_name = response.data.item.middle_name;
			form.value.mobile_number = response.data.item.mobile_number;
			form.value.home_address = response.data.item.home_address;
			form.value.is_temporary = response.data.item.is_temporary;
			form.value.department_id = response.data.item.department_id;
			form.value.office_id = response.data.item.office_id;
			form.value.position_id = response.data.item.position_id;
			form.value.role_id = response.data.item.user_group;

			officeOptions.value = response.data.offices;
			positionOptions.value = response.data.positions;
			userGroupOptions.value = response.data.userGroups;
			departmentOptions.value = response.data.departments;
		})
		.catch((error: any) => console.log(error));
});
</script>
