import DB from "@/classes/Databases/Crud";
import PaginationPerpetrator from "@/classes/Databases/PaginationPerpetrator";
import CasePerpetratorOfflineForm from "./CasePerpetratorOfflineForm";
import SqliteRecordChecker from "../SqliteRecordChecker";

const dbPatient = new DB('patients');
const dbCases = new DB('cases');
const dbCasePerpetrator = new DB('case_perpetrators');
const dbPerpetrator = new DB('perpetrators', new PaginationPerpetrator("perpetrators"));
const checkRecord = new SqliteRecordChecker();
class OfflineCasePerpetrator {
    async storeCasePerpetratorIndexOffline(response: any, caseId: any) {
        const result = await dbCases.edit(caseId, false, 'cases');

        if (result.data.item.length == undefined) {
            const items = response.data;
            for (const index in items) {
                await dbCasePerpetrator.delete({ cases_id: caseId, perpetrator_id: items[index].id}, 'case_perpetrators');
                const form = new CasePerpetratorOfflineForm(items[index], result.data.item, caseId);
                await dbCasePerpetrator.insert(form, null, true, 'case_perpetrators');
                const perpetratorForm = new CasePerpetratorOfflineForm({}, {});
                const createForm = await perpetratorForm.createPerpetratorForm(items[index], true);
                await dbPerpetrator.insert(createForm, items[index].id, false, 'perpetrators');
            }
        }
    }

    async linkPerpetratorToCase(patient: any, patientCase: any, payload: any) {
        let link = new CasePerpetratorOfflineForm({}, {});
        link = await link.linkPerpetratorForm(payload.perpetrator, patientCase, false);
        await dbCasePerpetrator.delete({
            cases_id: patientCase,
            perpetrator_id: payload.perpetrator,
        }, 'case_perpetrators')
        await dbCasePerpetrator.insert(link, null, false, 'case_perpetrators');
        const response = await dbPerpetrator.edit(payload.perpetrator, false, 'perpetrators');
        return {
            data: {
                items: response
            }
        }
    }

    async onlinePerpetratorSaveToOffline(response: any, caseId: any) {
        const data = response.data.data;
        const checkIfRecordIsEditedOffline = await dbCasePerpetrator.select(`SELECT * FROM case_perpetrators WHERE perpetrator_id=${data.item.id} AND cases_id=${caseId} AND (is_created_offline=true OR is_updated_offline=true OR is_deleted_offline=true)`);
        if (checkIfRecordIsEditedOffline.length > 0) {
            return;
        }

        const checkIfRecordIsEditedOfflinePerpetrator = await dbPerpetrator.select(`SELECT * FROM perpetrators WHERE id=${data.item.id} AND (is_created_offline=true OR is_updated_offline=true OR is_deleted_offline=true)`);
        if (checkIfRecordIsEditedOfflinePerpetrator.length > 0) {
            return;
        }
        let offlineForm = new CasePerpetratorOfflineForm({}, {});
        offlineForm = await offlineForm.linkPerpetratorForm(data.item.id, caseId);
        await dbCasePerpetrator.delete({
            cases_id: caseId,
            perpetrator_id: data.item.id,
        }, 'case_perpetrators')
        await dbCasePerpetrator.insert(offlineForm, null, true, 'case_perpetrators');
        let offlinePerpetratorForm = new CasePerpetratorOfflineForm({}, {});
        offlinePerpetratorForm = await offlinePerpetratorForm.createPerpetratorForm(data.item);
        await dbPerpetrator.insert(offlinePerpetratorForm, null, true, 'perpetrators');
    }

    async createCasePerpetrator(patient: any, patientCase: any, payload: any) {
        let fetch = await checkRecord.init(dbCases, patientCase, false, 'cases');
		fetch = fetch.result.data.item;
		const form = new CasePerpetratorOfflineForm({}, {});
		const createForm = await form.createPerpetratorForm(payload, false)
        const perpetrator = await dbPerpetrator.insert(createForm, null, false, 'perpetrators');
        let link = new CasePerpetratorOfflineForm({}, {});
        link = await link.linkPerpetratorForm(perpetrator.id, patientCase, false);
        await dbCasePerpetrator.delete({
            cases_id: patientCase,
            perpetrator_id: perpetrator.id,
        }, 'case_perpetrators')
        await dbCasePerpetrator.insert(link, null, false, 'case_perpetrators');

        return {
            data: {
                item: perpetrator,
            }
        }
    }

    async updateCasePerpetrator(patient: any, patientCase: any, perpetrator: any, payload: any) {
        const result = await dbPerpetrator.edit(perpetrator, false, 'perpetrators');
		const form = new CasePerpetratorOfflineForm({}, {});
		const createForm = await form.createPerpetratorForm(payload, false);
        if (result.data.item) {
            const item = result.data.item;
            createForm.id = perpetrator;
            createForm.is_created_offline = item.is_created_offline || false;
            createForm.is_updated_offline = item.is_created_offline ? false : true;
        }
        const response = await dbPerpetrator.insert(createForm, perpetrator, false, 'perpetrators');
        let link = new CasePerpetratorOfflineForm({}, {});
        link = await link.linkPerpetratorForm(response.id, patientCase, false);
        await dbCasePerpetrator.delete({
            cases_id: patientCase,
            perpetrator_id: perpetrator,
        }, 'case_perpetrators')
        await dbCasePerpetrator.insert(link, null, false, 'case_perpetrators');

        return {
            data: {
                item: response,
            }
        }
    }

    async deleteCasePerpetrator(patient: any, patientCase: any, perpetrator: any, payload: any) {
        const result = await dbCasePerpetrator.select(`SELECT * FROM case_perpetrators WHERE perpetrator_id=${[perpetrator]} AND cases_id=${patientCase}`);
        if (result.length > 0) {
            const item = result[0];
            if (item.is_created_offline) {
                await dbCasePerpetrator.delete({id: item.id}, 'case_perpetrators');
                return {
                    data: {
                        item: null,
                    }
                }
            } else {
                const form = new CasePerpetratorOfflineForm({}, {});
                const deleteForm = await form.deleteForm(payload, item);
                const response = await dbCasePerpetrator.insert(deleteForm, item.id, false, 'case_perpetrators');

                return {
                    data: {
                        item: response,
                    }
                }
            }
        }
    }
}

export default new OfflineCasePerpetrator();