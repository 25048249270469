import http from "@/axios";
import OfflineService from "@/classes/Dropdowns/Classes/OfflineService";

class DropdownService {

	async onlineStatus(): Promise<any> {
		return await http.get("dropdowns/online-status");
	}
	async patients(payload?: object): Promise<any> {
		return await http.get("dropdowns/patients", { params: payload });
	}

	async regions(payload?: object): Promise<any> {
		return await http.get("dropdowns/regions", { params: payload });
	}

	async provinces(region: number): Promise<any> {
		return await http.get(`dropdowns/provinces/${region}`);
	}

	async cities(province: number): Promise<any> {
		return await http.get(`dropdowns/cities/${province}`);
	}

	async barangays(city: number): Promise<any> {
		return await http.get(`dropdowns/barangays/${city}`);
	}

	async notifiablePersonnel(position: number): Promise<any> {
		return await http.get(`dropdowns/notifiable-personnel/${position}`);
	}

	async personnels(): Promise<any> {
		return await http.get(`dropdowns/personnels`);
	}

	async interventionFieldOptions(networkAvailable: boolean, option_class: string, option_column?: string, parent_value?: number,): Promise<any> {
		if(networkAvailable){
			return await http.get("dropdowns/intervention-field-options", {
				params: {
					option_class: option_class,
					option_column: option_column,
					parent_value: parent_value,
				}
			});
		} else {
			return await OfflineService.interventionFieldOptions(option_class, option_column, parent_value)
		}
	}
}
export default new DropdownService();
