<template>
    <user-layout
        :hasLeftCon="true"
    >
        <template #leftContent>
            <div class="sticky top-8">
                <div class="my-8">
                    <a href="/settings/roles/index/">
                        <button-component
                            customClass="tertiary text-black hover:text-primary-600 transition"
                        >
                            <!-- <img src="assets/icon/ic-arrow-left.svg" alt="back-arrow" class="object-contain w-6 h-6 mr-2"> -->
                            <arrow-left-icon class="object-contain w-6 h-6 mr-2" />
                            <span class="text-sm font-semibold">Back</span>
                        </button-component>
                    </a>
                </div>
            </div>
        </template>
        <div class="my-11">
            <div>
                <div class="max-w-[925px] mx-auto my-16 flex items-start justify-between space-x-6">
                    <table class="overflow-hidden rounded-t-lg">
                        <thead class="border-b border-gray-200 bg-primary-100">
                            <tr>
                                <th class="px-6 py-6 font-semibold text-left text-neutral-900 whitespace-nowrap">
                                    <span>Permissions</span>
                                </th>
                                <th class="px-6 py-6 font-semibold center text-neutral-900 whitespace-nowrap">
                                    <span>Yes</span>
                                </th>
                                <th class="px-6 py-6 font-semibold center text-neutral-900 whitespace-nowrap">
                                    <span>No</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody class="bg-white">
                            <tr
                                v-for="item in permissionChecklist" 
                                :key="item.name"
                                class="border-b border-gray-200"
                            >
                                <td class="px-6 py-4 text-sm text-gray-900 whitespace-nowrap">
                                    <p class="text-sm font-semibold">{{ item.label }}</p>
                                    <p class="text-sm text-neutral-600">{{ item.description }}</p>
                                </td>
                                <td class="px-6 py-4 text-sm text-center text-gray-900 whitespace-nowrap">
                                    <CheckIcon v-if="item.has_permission" class="w-4 h-4 text-primary-600 mx-auto stroke-[3px]"/>
                                </td>
                                <td class="px-6 py-4 text-sm text-center text-gray-900 whitespace-nowrap">
                                    <CheckIcon v-if="!item.has_permission" class="w-4 h-4 text-primary-600 mx-auto stroke-[3px]"/>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table class="rounded-t-lg overflow-hidden min-w-[320px]">
                        <thead class="border-b border-gray-200 bg-primary-100">
                            <tr>
                                <th class="px-6 py-6 font-semibold text-left text-neutral-900 whitespace-nowrap">
                                    <span>Personnel with this role</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody class="bg-white" v-if="personnelList.length > 0">
                            <tr
                                v-for="personnel in personnelList" 
                                :key="personnel.name"
                                class="border-b border-gray-200"
                            >
                                <td class="px-6 py-4 text-sm text-gray-900 whitespace-nowrap">
                                    <p class="text-sm font-semibold">{{ personnel.name }}</p>
                                    <p class="text-sm text-neutral-600">{{ personnel.office_name }}</p>
                                </td>
                            </tr>
                        </tbody>

                        <tbody class="bg-white" v-else>
                            <tr class="border-b border-gray-200">
                                <td class="px-6 py-4 text-sm text-gray-900 whitespace-nowrap">
                                    <p class="text-sm font-semibold">No Record Found</p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </user-layout>
</template>
<script setup lang="ts">
import { ref, onMounted } from 'vue';
import UserLayout from '@/layouts/UserLayout.vue';
import ButtonComponent from '@/components/buttons/ButtonComponent.vue';
import TabComponent from '@/components/partials/TabComponent.vue';
import { CheckIcon } from '@heroicons/vue/24/outline';

import Roles from "@/classes/Roles";

import { useRoute, useRouter } from "vue-router";
import ArrowLeftIcon from "@/components/icons/arrowLeftIcon.vue";


interface PermissionChecklistInterface {
    description: string,
    has_permission: boolean,
    label: string,
    name: string,
}

interface PersonnelsInterface {
    name: string,
    office_name: string,
}

const permissionChecklist = ref<PermissionChecklistInterface[]>([]);
const personnelList = ref<PersonnelsInterface[]>([]);

const route = useRoute();


const init = ():void => {
    
    Roles.show(parseInt(route.params.role))
        .then(({data}) => {
            personnelList.value = data.item.assigned_personels;
            permissionChecklist.value = data.permission_checklist
        })
        .catch((error) => {
            console.log(error);
        })

}

onMounted(() => {
    init()
})


interface sampleDataInfo {
    module: string,
    description: string,
    status: boolean,
}

const sampleData = ref<sampleDataInfo[]> ([
    { 
        module: 'Manage Dashboard',
        description: 'Allow the Admin to access the dashboard',
        status: true
    },
    { 
        module: 'Manage Patient',
        description: 'Allow the Admin to access the patient',
        status: false
    },
    { 
        module: 'Manage Intervention',
        description: 'Allow the Admin to access the intervention',
        status: false
    },
    { 
        module: 'Manage Office',
        description: 'Allow the Admin to access the office',
        status: true
    },
    { 
        module: 'Manage Personnel',
        description: 'Allow the Admin to access the personnel',
        status: true
    },
]);

interface sampleRoleInfo {
    name: string,
    office: string,
}

const sampleRoleData = ref<sampleRoleInfo[]> ([
    { 
        name: 'John Doe',
        office: 'Child Protection Office 1'
    },
    { 
        name: 'James Doe',
        office: 'Child Protection Office 1'
    },
    { 
        name: 'Jackson Doe',
        office: 'Child Protection Office 2'
    },
    { 
        name: 'Elizabeth Cruz',
        office: 'Child Protection Office 3'
    },
    { 
        name: 'Kira Cruz',
        office: 'Child Protection Office 3'
    },
]);
</script>