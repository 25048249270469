<template>
    <ion-page>
        <ion-content :fullscreen="true" class="">
            <div class="flex flex-wrap lg:flex-row flex-col lg:items-center h-screen lg:justify-between">
                <auth-logo />

                <div class="w-full px-4 lg:w-1/2 sm:px-20 lg:py-0 py-6">
                    <div class="sm:max-w-[360px] mx-auto">
                        <auth-title
                            title="Enter your account 6-digit OTP"
                            description="Please enter the numeric SIX digit code sent to your email" 
                        />
                        <p
                            v-if="errors"
                            class="text-[10px] mt-1"
                            :class="errors ? 'text-red-600' : 'text-gray-800'"
                            id="email-error"
                        >
                            {{ Object.values(errors)[0] }}
                        </p>
                        <otp
                            bottonName="Submit"
                            @update:submit="(value:string) => submit(value)"
                            @update:resendOtp="resendOtp"
                            hasSubmit
                            showResend
                        />

                    </div>
                </div>
            </div>

            <success-modal
                :show="showSuccess"
                @confirm="confirm"
                action-text="Close"
                title="Success!"
                :content="successMessage"
            />
            
            <success-modal
                :show="showResentSuccess"
                @confirm="showResentSuccess = false"
                action-text="Close"
                title="Success!"
                :content="successMessage"
            />

            <page-loader :show="loading"/>
        </ion-content>
    </ion-page>
</template>

<script setup lang="ts">
import {
    IonPage,
    IonContent 
} from '@ionic/vue';
import Otp from '@/components/forms/Otp.vue';

import { ref, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import PageLoader from "@/components/loaders/PageLoader.vue"
import SuccessModal from '@/components/modals/SuccessModal.vue';

import ForgotPassword from '@/classes/ForgotPassword';

import { errorResponse } from "@/helpers/errorMessage";

import AuthLogo from "../Components/AuthLogo.vue";
import AuthTitle from "../Components/AuthTitle.vue";

const router = useRouter();
const route = useRoute();


const loading = ref<boolean>(false);
const showSuccess = ref<boolean>(false);
const showResentSuccess = ref<boolean>(false);
const successMessage = ref<string>('');
const errors = ref<any>();

const email = ref<string>('');

const form = ref<{
    email: string
    code: string | null
    token: string | null
}>({
    email: route.query.email,
    code: null,
    token: null
})

const submit = (otp:string):void =>{
    loading.value = true;

    form.value.code = otp;
    
    ForgotPassword.verifyOtp(form.value)
        .then(({ data }) => {
            console.log(data);
            loading.value = false;
            showSuccess.value = true;
            successMessage.value = 'OTP has been verified!';
            form.value.token = data.success.token;
        }).catch((error) => {
            loading.value = false;
            errors.value = errorResponse(error.response?.data?.errors)
        });
}
const resendOtp = ():void =>{
    loading.value = true;
    ForgotPassword.instantiate(form.value)
        .then(({ data }) => {
            loading.value = false;
            showResentSuccess.value = true;

            successMessage.value = 'New OTP has been set to your email!';
        }).catch((error) => {
            loading.value = false;

            errors.value = errorResponse(error.response?.data?.errors)
        });
}

const confirm = (): void => {
    router.replace({ path: '/reset-password', query: form.value })
}

onMounted(() => {
    if (typeof route.query.email == 'undefined' )
    { 
        router.replace({ path: '/login' })
    }

})

</script>