import http from '@/axios'

class PersonalInformationService {

    protected base_url = 'patients/cases/intakeForm/personalInformation';

    public async show(patient:string, cases:string) : Promise<JSON> {
        return await http.get(`patients/${patient}/cases/${cases}/intakeForm/personalInformation/show`);
    }

    public async update(patient:string, cases:string, payload: any) : Promise<JSON> {
        return await http.post(`patients/${patient}/cases/${cases}/intakeForm/personalInformation/update/${payload?.id}`, payload);
    }

}
export default new PersonalInformationService();
