<template>
	<ion-page>
		<ion-content :fullscreen="true" class="">
			<div class="flex flex-col flex-wrap h-screen lg:flex-row lg:items-center lg:justify-between">
				<auth-logo />

				<div class="w-full px-4 py-6 lg:w-1/2 sm:px-20 lg:py-0">
					<div class="sm:max-w-[360px] mx-auto">
						<auth-title
							title="Admin"
							description="Kindly input your credentials to continue"
						/>
						<div>
							<p
								v-if="errors?.error"
								class="mb-3 text-xs"
								:class="errors?.error ? 'text-red-600' : 'text-gray-800'"
								id="email-error"
							>
								{{ errors?.error }}
							</p>
						</div>

						<form class="" @submit.prevent="processLogin">
							<div class="mb-6">
								<text-input
									type="email"
									label="Email Address"
									placeholder="Email Address"
									name="email"
									id="email"
									v-model="form.email" 
									:error="errors?.email" />
							</div>
							<div class="mb-2">
								<password-input
									name="password"
									id="password"
									label="Password"
									placeholder="Password"
									v-model="form.password" 
									:error="errors?.password" />
							</div>
							<div class="flex items-center justify-end mb-6">
								<a
									href="/forgot-password"
									class="m-0 text-xs transition text-primary-600 hover:text-primary-800"
									>Forgot Password?</a
								>
							</div>
							<a href="/patient/search">
								<button-component type="submit" size="large" btnWidth="w-full rounded-lg">
									Login
								</button-component>
							</a>
						</form>
					</div>
				</div>
			</div>
			<page-loader :show="loading"/>
		</ion-content>
	</ion-page>
</template>

<script setup lang="ts">
import { IonPage, IonContent } from "@ionic/vue";

import { ref } from "vue";

import ButtonComponent from "@/components/buttons/ButtonComponent.vue";
import TextInput from "@/components/inputs/TextInput.vue";
import PasswordInput from "@/components/inputs/PasswordInput.vue";

import { login, downloadResources } from "@/classes/AuthService";

import { errorResponse } from "@/helpers/errorMessage";

import PageLoader from "@/components/loaders/PageLoader.vue";

import AuthLogo from "./Components/AuthLogo.vue";
import AuthTitle from "./Components/AuthTitle.vue";

const loading = ref<boolean>(false);

const errors = ref<any>();

const form = ref<{ email: string; password: string }>({
	email: "",
	password: "",
});

const processLogin = (): void => {
	loading.value = true;
	login(form.value.email, form.value.password).then(async (response) => {
        if (response.status === 200) {
            await downloadResources(response);
            loading.value = false;
        } else {
            const error = response;
            errors.value = errorResponse(error.response?.data?.errors)
            loading.value = false;
        }
	}).catch((error: any) => {
		loading.value = false;
		errors.value = errorResponse(error.response?.data?.errors)
	});
};
</script>
