import http from "@/axios";
import { Department } from "@/interfaces/Settings/Department";

class DepartmentService implements Department {
	async index(params: any = {}): Promise<any> {
		return await http.get("settings/departments", { params: params });
	}

	async delete(scopeBinding: any): Promise<any> {
		return await http.delete(`settings/departments/${scopeBinding.department}/delete`);
	}

	async restore(scopeBinding: any): Promise<any> {
		return await http.patch(`settings/departments/${scopeBinding.department}/restore`);
	}

	async edit(scopeBinding: any, params: any = {}): Promise<any> {
		return await http.get(`settings/departments/${scopeBinding.department}/edit`, { params: params });
	}

	async update(scopeBinding: any, params: any = {}): Promise<any> {
		return await http.put(`settings/departments/${scopeBinding.department}/update`, params);
	}

	async store(params: any = {}): Promise<any> {
		return await http.post(`settings/departments`, params);
	}
}

export default new DepartmentService();
