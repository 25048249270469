<template>
	<div class="grid grid-cols-12 gap-6">
		<div class="col-span-12 xl:col-span-3 lg:col-span-4 md:col-span-6">
			<text-input
				label="Last Name"
				placeholder="Last Name"
				name="last_name"
				id="last_name"
				required
				v-model="formData.last_name"
				:error="errors?.last_name"
			/>
		</div>

		<div class="col-span-12 xl:col-span-3 lg:col-span-4 md:col-span-6">
			<text-input
				label="First Name"
				placeholder="First Name"
				name="first_name"
				id="first_name"
				required
				v-model="formData.first_name"
				:error="errors?.first_name"
			/>
		</div>

		<div class="col-span-12 xl:col-span-3 lg:col-span-4 md:col-span-6">
			<text-input
				label="Middle Name"
				placeholder="Middle Name"
				name="middle_name"
				id="middle_name"
				v-model="formData.middle_name"
				:error="errors?.middle_name"
			/>
		</div>

		<div class="col-span-12 xl:col-span-3 lg:col-span-4 md:col-span-6">
			<multi-select searchable
				:options="optionGenders"
				label="Gender"
				id="gender"
				name="gender"
				placeholder="Select.."
				v-model="formData.gender" 
				:error="errors?.gender" 
			/>
		</div>

		<div class="col-span-12 lg:col-span-4 md:col-span-6">
			<multi-select searchable
				:options="optionRelationChild"
				label="Relationship to the child"
				id="companion_relationship_id"
				name="relation_child"
				placeholder="Select.."
				v-model="formData.companion_relationship_id" 
				:error="errors?.companion_relationship_id" 
				required
			/>
		</div>

		<div 
			v-if="formData.relationship_child === 2"
			class="col-span-12 lg:col-span-4 md:col-span-6"
		>
			<text-input
				label="Other, please specify"
				placeholder="Other, please specify"
				name="other_specify"
				id="specify_relationship_child"
				v-model="formData.specify_relationship_child"
				:error="errors?.specify_relationship_child"
			/>
		</div>

		<UserBirthdateAge 
			:form="formData"
			:errors="errors"
			customClass="md:col-span-6 col-span-12"
		/>
		
		<div class="flex flex-col flex-wrap justify-between col-span-12 gap-3 lg:col-span-4 md:col-span-6">
			<checkbox-input
				v-for="(option, index) in optionSelected"
				:key="index"
				:id="option.id"
				:name="option.name"
				:label="option.label"
				:checked="formData.person_related_types.indexOf(option.id) > -1"
				@click="removeAddRelatedTypes(option.id)"
			/>
			<p
				v-if="errors?.person_related_types"
				class="text-[10px] mt-1 text-red-600"
				id="email-error"
			>
				{{ errors?.person_related_types }}
			</p>
		</div>

		<div class="col-span-12 lg:col-span-4 md:col-span-6">
			<multi-select searchable
				:options="optionCivilStatus"
				label="Civil Status"
				id="civil_status_id"
				name="civil_status"
				placeholder="Select.."
				v-model="formData.civil_status_id" 
				:error="errors?.civil_status_id" 
			/>
		</div>

		<div class="col-span-12 lg:col-span-4 md:col-span-6">
			<text-input
				type="tel"
				label="Contact Number"
				placeholder="Contact number"
				name="contact_number"
				id="contacts"
				:add_on_left="true"
				add_on_text="+63"
				:maxlength="10"
				@keypress="numberonly"
				v-model="formData.contacts"
				:error="errors?.contacts"
			/>
		</div>

		<div class="col-span-12 lg:col-span-4 md:col-span-6">
			<p class="text-xs font-semibold text-neutral-600">Employed?</p>

			<div class="flex flex-wrap mt-5 space-x-5">
				<radio-group 	
					id="is_employed"
					:options="optionYerOrNo" 
					name="employed"
					v-model="formData.is_employed" 
					:error="errors?.is_employed" 
				/>
			</div>
		</div>

		<div class="col-span-12 lg:col-span-4 md:col-span-6">
			<p class="text-xs font-semibold text-neutral-600">OFW?</p>

			<div class="flex flex-wrap mt-5 space-x-5">
				<radio-group 
					id="is_ofw"
					:options="optionYerOrNo" 
					name="ofw"
					v-model="formData.is_ofw" 
					:error="errors?.is_ofw" 
					@update:modelValue="value => (formData.is_ofw = value)"
				/>
			</div>
		</div>
		<div class="col-span-12 lg:col-span-4 md:col-span-6">
			<text-input
				label="Occupation"
				placeholder="Occupation"
				name="occupation"
				id="occupation"
				v-model="formData.occupation"
				:error="errors?.occupation"
			/>
		</div>


		<div class="col-span-12 lg:col-span-4 md:col-span-6">
			<text-input
				label="Company"
				placeholder="Company"
				name="company"
				id="company"
				v-model="formData.company"
				:error="errors?.company"
			/>
		</div>

		<div class="col-span-12 lg:col-span-4 md:col-span-6">
			<text-input
				label="Weekly Income"
				placeholder="Weekly Income"
				name="weekly_income"
				id="weekly_income"
				v-model="formData.weekly_income"
				:error="errors?.weekly_income"
			/>
		</div>

		<div class="col-span-12 lg:col-span-4 md:col-span-6">
			<text-input
				label="Other source of income"
				placeholder="Other source of income"
				name="other_source_income"
				id="other_source_of_income"
				v-model="formData.other_source_of_income"
				:error="errors?.other_source_of_income"
			/>
		</div>

		<div class="col-span-12">
			<checkbox-input
				id="is_enrolled_in_school"
				name="enrolled_school"
				label="Enrolled in school"
				:checked="formData.is_enrolled_in_school"
				@update:modelValue="(value) => (formData.is_enrolled_in_school = value)"
			/>
		</div>

		<div class="col-span-12 md:col-span-6">
			<multi-select searchable
				:options="optionEducationAttain"
				label="Educational Attainment"
				id="educational_attainment_id"
				name="education_attainment"
				placeholder="Select.."
				v-model="formData.educational_attainment_id" 
				:error="errors?.educational_attainment_id" 
			/>
		</div>

		<div class="col-span-12 md:col-span-6">
			<text-input
				label="School"
				placeholder="School"
				name="school"
				id="school"
				v-model="formData.school"
				:error="errors?.school"
			/>
		</div>
<!-- 
		<div class="col-span-12 xl:col-span-4 md:col-span-6">
			<multi-select searchable
				:options="optionHousingClass"
				label="Housing Classification"
				id="housing_classification_id"
				name="housing_classification"
				placeholder="Select.."
				v-model="formData.housing_classification_id" 
				:error="errors?.housing_classification_id" 
			/>
		</div> -->

		<!-- <div class="col-span-12 xl:col-span-4 md:col-span-6">
			<text-input
				type="number"
				label="Number of Children in the Family"
				placeholder="Number of Children in the Family"
				name="number_children"
				id="no_children_in_family"
				v-model="formData.no_children_in_family"
				:error="errors?.no_children_in_family"
			/>
		</div>

		<div class="col-span-12 xl:col-span-4 md:col-span-6">
			<text-input
				type="number"
				label="Number of Household Members"
				placeholder="Number of Household Members"
				name="number_household"
				id="no_household_members"
				v-model="formData.no_household_members"
				:error="errors?.no_household_members"
			/>
		</div>

		<div class="col-span-12 xl:col-span-4 md:col-span-6">
			<text-input
				type="number"
				label="Number of Families in Household"
				placeholder="Number of Families in Household"
				name="number_families"
				id="no_family_in_household"
				v-model="formData.no_family_in_household"
				:error="errors?.no_family_in_household"
			/>
		</div> -->

		<div class="col-span-12">
			<text-input
				textarea
				label="Other information"
				placeholder="Other information"
				name="other_information"
				id="other_information"
				v-model="formData.other_information"
				:error="errors?.other_information"
		/>
		</div> 
	</div>
</template>

<script setup lang="ts">
import TextInput from "@/components/inputs/TextInput.vue";
import RadioGroup from "@/components/inputs/RadioGroup.vue";
import DatePicker from "@/components/inputs/DatePicker.vue";
import MultiSelect from "@/components/inputs/MultiSelect.vue";
import CheckboxInput from "@/components/inputs/CheckboxInput.vue";
import { ref, watch } from 'vue';
import { BirthdayClassification } from "@/views/enums/BirthdayClassification";
import UserBirthdateAge from '@/components/forms/UserBirthdateAge.vue'
import { numberonly } from "@/components/composables/useNumberOnly";

const props = defineProps({
	form: {
		type: Object,
		required:true,
	},
	errors: {
		type: Object,
		required:true,
	},
	optionRelationChild: {
		type: Object,
		required:true,
	},
	optionBirthday: {
		type: Object,
		required:true,
	},
	optionSelected: {
		type: Object,
		required:true,
	},
	optionCivilStatus: {
		type: Object,
		required:true,
	},
	optionYerOrNo: {
		type: Object,
		required:true,
	},
	optionEducationAttain: {
		type: Object,
		required:true,
	},
	optionHousingClass: {
		type: Object,
		required:true,
	},
	optionGenders: {
		type: Object,
		required:true,
	}
})

const formData = ref({ ...props.form });

const removeAddRelatedTypes = (item:number): void => {
	const index = formData.value.person_related_types.indexOf(item)

	if(index > -1){
		formData.value.person_related_types.splice(index, 1);
	} else {
		formData.value.person_related_types.push(item);
	}
}

// watch(
// 	() => formData.value.birthday_classification,
// 	(val) => {
// 		formData.value.birthdate = null;
// 		formData.value.age = null;
// 	}
// );


defineExpose({
	formData
})
</script>