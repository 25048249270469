import DB from "@/classes/Databases/Crud";
import Pagination from "@/classes/Databases/Pagination";
// import PaginationPatient from "@/classes/Databases/PaginationPatient";

import FormOptions from "@/classes/Forms/FormOptions";

import DateFormatter from "@/helpers/DateFormatter";
import { OfflineAction } from "@/views/enums/OfflineAction";
import FactorsService from "./OfflineClasses/FactorsService";
import RecommendationsService from "./OfflineClasses/RecommendationsService";

const db = new DB('case_conferences', new Pagination('case_conferences'));
const dbRecommendation = new DB('case_conference_recommendations', new Pagination('case_conference_recommendations'));

const options  = new FormOptions();

class OfflineService {
	async index(patient: number, patientCase: number): Promise<any> {
		const condition = ` WHERE patient_id = ${patient} AND cases_id = ${patientCase}`;
		const data = await db.index({}, condition);

		return {
			data: {
				...data.data,
			}
		};
	}

	async storeIndexOffline(items: object): Promise<void> {
		items = Object.values(items).map((item:any) => {

			delete item.agencies_formatted ;
			delete item.invitees_formatted ;
			// delete item.recommendations ;

			item.agencies = JSON.stringify(item.agencies);
			item.invitees = JSON.stringify(item.invitees);
			item.recommendations = JSON.stringify(item.recommendations);
			return item;
		});

		await db.set(items, true, 'case_conferences');
	}

	async create(patient: number, patientCase: number): Promise<any> {
		const patientData = await db.select(`SELECT * FROM patients where id = ${patient}`, 'patients');
		
		return {
			data : {
				invitees: await options.wcpuPersonnels(),
				agencies: await options.agencies(),
				socialWorkers: await options.socialWorkers(),
				patient_name: `${patientData[0].first_name} ${patientData[0].first_name}` ,
				recommendations: [],
			}
		}
	}

	async store(patient: any, patientCase: any, payload: any): Promise<any> {
		const data = {
			...payload,
			social_worker: await this.getSocialWorkerName(payload), 
			case_conference_number: 'offline',
			patient_id: parseInt(patient),
			cases_id: parseInt(patientCase),
			is_created_offline: true, 
			date_time: DateFormatter.customDateFormat(payload.date_time, 'yyyy-MM-dd hh:mm:ss'),
			created_at: DateFormatter.customDateFormat(new Date().toDateString(), 'yyyy-MM-dd hh:mm:ss'),
			updated_at: DateFormatter.customDateFormat(new Date().toDateString(), 'yyyy-MM-dd hh:mm:ss'),
			agencies: JSON.stringify(payload.agencies),
			invitees: JSON.stringify(payload.invitees),
		}

		const conference = await db.insert(data, null, false, 'case_conferences');

		return {
			data: {
				data: {
					item: conference
				}
			}
		}
	}

	async edit(patient: number, patientCase: number, caseConference: any): Promise<any> {
		const result = await db.edit(parseInt(caseConference), false, 'case_conferences');

		const item = await this.setFormParamsData(result.data.item);

		return {
			data : {
				item: {
					...item,
					recommendations: await this.recommendationFormatter(caseConference),
				},
				invitees: await options.wcpuPersonnels(),
				agencies: await options.agencies(),
				socialWorkers: await options.socialWorkers(),
			}
		}
	}

	async update(patient: number, patientCase: number, caseConference: number, payload: any): Promise<any> {
		const item = await db.select(`SELECT * FROM case_conferences where id = ${caseConference}`, 'case_conferences');

		const data = Object.assign(
			item[0],
			{	
				...payload,    
				social_worker: await this.getSocialWorkerName(payload), 
				is_updated_offline: payload.is_created_offline ? false : true, 
				updated_at:  DateFormatter.customDateFormat(new Date().toDateString(), 'yyyy-MM-dd hh:mm:ss'),
				agencies: JSON.stringify(payload.agencies),
				invitees: JSON.stringify(payload.invitees),
			}
		);

		return db.insert(data, caseConference, false, 'case_conferences');
	}

	async storeMinutes(patient: number, patientCase: number, caseConference: any, payload: any): Promise<any> {
		const case_conferences = await db.select(`SELECT * FROM case_conferences`, 'case_conferences');

		const item = await db.select(`SELECT * FROM case_conferences where id = ${ parseInt(caseConference) }`, 'case_conferences');

		const data = Object.assign(
			item[0],
			{	
				meeting_minutes: payload.meeting_minutes
			}
		);

		return db.insert(data, caseConference, false, 'case_conferences');
	}

	async delete(patient: number, patientCase: number, caseConference: number): Promise<any> {

		const item = await db.select(`SELECT * FROM case_conferences where id = ${caseConference}`, 'case_conferences');

		const data = Object.assign(
			item[0],
			{	
				action: OfflineAction.DELETE_RECORD,
				deleted_at: DateFormatter.customDateFormat(new Date().toDateString(), 'yyyy-MM-dd hh:mm:ss'),
				is_deleted_offline: true,
			}
		);

		return db.insert(data, caseConference, false, 'case_conferences');
	}


	//Sub Pages
	async indexFactors(patient: number, patientCase: number, caseConference: number): Promise<any> {
		return await FactorsService.index(patient, patientCase, caseConference);
	}

	async storeFactorsOffline(item: any): Promise<void> {
		return await FactorsService.storeOffline(item);
	}

	async storeFactors(patient: number, patientCase: number, caseConference: number, payload: any): Promise<any> {
		return await FactorsService.store(patient, patientCase, caseConference, payload);
	}

	async indexRecommendations(patient: number, patientCase: number, caseConference: number): Promise<any> {
		return await RecommendationsService.index(patient, patientCase, caseConference);
	}

	async storeRecommendations(patient: number, patientCase: number, caseConference: any, payload: any): Promise<any> {
		return await RecommendationsService.store(patient, patientCase, caseConference, payload);
	}
	
	async storeRecommendationsOffline(items: any): Promise<any> {
		return await RecommendationsService.storeRecommendationsOffline(items)
	}

	async indexMinutes(patient: number, patientCase: number, caseConference: number): Promise<any> {
		const result = await db.edit(caseConference, false, 'case_conferences');

		return {
			data : {
				item: {
					case_conference_id: caseConference,
					meeting_minutes: result.data.item.meeting_minutes,
				},
			}
		}
	}

	//Formatters
	async recommendationFormatter(caseConference: number): Promise<string[]|null> {
		const items:any = []
		const recommendations = await dbRecommendation.select(`SELECT * FROM case_conference_recommendations where case_conference_id = ${caseConference}`, 'cases');
		const recommendationOptions = await options.recommendationOptions();


		if(recommendations.length == 0) {
			return null;
		}

		return recommendations.map((item:any) => {
			const index = recommendationOptions.map((item:any) => item.id).indexOf(item.recommendation_option_id);
			const recommendations = recommendationOptions[index];

			return `${recommendations.name}: ${item?.remarks ?? ''}`;
		});

		return items;
	}

	private async setFormParamsData(data: any): Promise<any>{	
		const patientData = await db.select(`SELECT * FROM patients WHERE id = ${data.patient_id}`, 'patients');

		data.agencies = JSON.parse(data.agencies);
		data.agencies_formatted = await this.agenciesFormatter(data.agencies);

		data.invitees = JSON.parse(data.invitees);
		data.invitees_formatted = await this.inviteesFormatter(data.invitees);

		data.recommendations = JSON.parse(data.recommendations);
		data.patient_name = `${patientData[0].first_name} ${patientData[0].last_name}`;
		return data;
	}

	private async agenciesFormatter(data:any): Promise<any> {
		const agenciesOptions = await options.agencies();

		const items:any = [];

		data.forEach((item:any) => {
			const index = Object.values(agenciesOptions).map((item:any) => item.id).indexOf(item.agency_id);

			if(index != -1){
				items.push({	
					name: `${item.name} - ${agenciesOptions[index].name}`
				});
			}
		});	

		return items;
	}

	private async inviteesFormatter(data:any): Promise<any> {
		const inviteesOption = await options.wcpuPersonnels();

		const items:any = [];

		data.forEach((item:any) => {
			const index = Object.values(inviteesOption).map((item:any) => item.id).indexOf(item);

			if(index != -1){
				items.push({	
					name: inviteesOption[index].name
				});
			}
		});	

		return items;
	}

	private async getSocialWorkerName(data:any): Promise<string> {
		const workers = await options.socialWorkers();

		const index = Object.values(workers).map((item:any) => item.id).indexOf(data.social_worker_id);

		if(index != -1){
			return workers[index].name;
		}


		return "";
	}
}

export default new OfflineService();
