<template>
	<intake-layout 
		:isShowNav="isShowNav"
		:isWarningMessage="true"
		:patient="patient"
	>
		<template #header>
			<p class="text-xl font-semibold">Edit Address</p>
		</template>

		<!-- <template #actionButtons>
			<template v-if="isShowNav">
				<button-component btnWidth="quinary">Cancel</button-component>
				<button-component 
					customClass="primary" 
					@click="save"
				>
					Save Changes
				</button-component>
			</template> -->

			<!-- Edit -->
			<!-- <template v-else>
				<button-component 
					btnWidth="quinary"
					fill="outline"
					@click="isShowNav = true"
				>
					Cancel
				</button-component>
				<button-component>Save Changes</button-component>
			</template>
		</template> -->

		<!-- Address Information -->
		<template v-if="isShowNav">
			<div class="col-span-12 space-y-3">
				<div
					v-if="!hasContent"
					class="flex border border-gray-200 rounded-2xl items-center p-4 h-[calc(100vh-140px)] justify-center">
					<p class="text-xs italic text-center">No data yet</p>
				</div>
				<template v-if="hasContent">
					<div 
						v-for="(item, index) in items.data"
						class="relative w-full p-4 pr-8 border border-gray-200 rounded-2xl"
						:key="index"
					>
						<table class="w-full">
							<tbody>
								<tr class="border-b border-solid border-gray-100">
									<td class="w-[200px] py-2">
										<p class="font-medium">Address Type:</p>
									</td>
									<td class="py-2">
										<p class="font-normal">{{ item?.residence_type_meta?.description }}</p>
									</td>
								</tr>
								<tr class="border-b border-solid border-gray-100">
									<td class="w-[200px] py-2">
										<p class="font-medium">Address:</p>
									</td>
									<td class="py-2">
										<p class="font-normal">{{ item.address }}</p>
									</td>
								</tr>
								<tr class="border-b border-solid border-gray-100">
									<td class="w-[200px] py-2">
										<p class="font-medium">Barangay:</p>
									</td>
									<td class="py-2">
										<p class="font-normal">{{ item.barangay?.name }}</p>
									</td>
								</tr>
								<tr class="border-b border-solid border-gray-100">
									<td class="w-[200px] py-2">
										<p class="font-medium">City/Municipality/District:</p>
									</td>
									<td class="py-2">
										<p class="font-normal">{{ item.city?.name }}</p>
									</td>
								</tr>
								<tr class="border-b border-solid border-gray-100">
									<td class="w-[200px] py-2">
										<p class="font-medium">Province:</p>
									</td>
									<td class="py-2">
										<p class="font-normal">{{ item.province?.name }}</p>
									</td>
								</tr>
								<tr class="border-b border-solid border-gray-100">
									<td class="w-[200px] py-2">
										<p class="font-medium">Region:</p>
									</td>
									<td class="py-2">
										<p class="font-normal">{{ item.region?.name }}</p>
									</td>
								</tr>
								<tr>
									<td class="w-[200px] py-2">
										<p class="font-medium">Direction:</p>
									</td>
									<td class="py-2">
										<p class="font-normal">{{ item.direction }}</p>
									</td>
								</tr>
							</tbody>
						</table>
						<div class="absolute z-10 top-2 right-2">
							<Menu>
								<MenuButton>
									<EllipsisVerticalIcon class="w-5 h-5 cursor-pointer" />
								</MenuButton>
								<transition
									enter-active-class="transition duration-100 ease-out"
									enter-from-class="transform scale-95 opacity-0"
									enter-to-class="transform scale-100 opacity-100"
									leave-active-class="transition duration-75 ease-in"
									leave-from-class="transform scale-100 opacity-100"
									leave-to-class="transform scale-95 opacity-0">
									<MenuItems
										class="absolute right-2 w-[178px] rounded z-10 p-4 space-y-2 bg-white shadow-[0_2px_2px_0_rgba(0,0,0,.15)]">
										<MenuItem>
											<a 
												@click="
													router.replace(
														`/patient/${patientId}/cases/${caseId}/patient-addresses/${
															item.id ||
															item?.offlineData
																?.offline_id
														}/edit${
															offlineParams
																? '?offline=true'
																: ''
														}?intake=true`
													)
												"
												class="flex items-center space-x-2.5 cursor-pointer"
											>
												<!-- <img 
													src="/assets/icon/ic-pencil-black.svg" 
													alt="ic"
													class="w-6 h-6" 
												/> -->
												<pencil-black-icon class="w-6 h-6" />
												<p class="text-sm text-[#333333]">Edit</p>
											</a>
										</MenuItem>
										<MenuItem>
											<div 
												@click="removeConfirmation(item)"
												class="flex items-center space-x-2.5 cursor-pointer"
											>
												<!-- <img 
													src="/assets/icon/ic-trash.svg" 
													alt="ic"
													class="w-6 h-6" 
												/> -->
												<trash-icon class="object-contain w-6 h-6 cursor-pointer" />
												<p class="text-sm text-[#333333]">Remove</p>
											</div>
										</MenuItem>
									</MenuItems>
								</transition>
							</Menu>
						</div>
					</div>
				</template>
			</div>

			<div class="flex justify-end col-span-12">
				<a
					@click="
						router.replace(
							`/patient/${patientId}/cases/${caseId}/patient-addresses/create${
								offlineParams ? '?offline=true' : ''
							}?intake=true`
						)
					"
					class="sm:w-auto w-full">
					<button-component customClass="primary sm:w-auto w-full">
						<PlusIcon class="w-3.5 h-3.5 "/>
						<p>Add New</p>
					</button-component>
				</a>
			</div>
		</template>

		<!-- Edit Address -->
		<template v-else>
			<div class="col-span-12">
				<edit-page 
					:form="form" 
					:optionAdressType="optionAdressType"
					:optionRegion="optionRegion"
					:optionProvince="optionProvince"
					:optionCityMunicipality="optionCityMunicipality"
					:optionBarangay="optionBarangay	"
				/>
			</div>
		</template>

		<!-- MODAL -->
		<confirmation-modal
			:typeTwo="true"
			:show="showConfirmation"
			@cancel="showConfirmation = false"
			@confirm="deleteItem()"
			cancel-text="Cancel"
			action-text="Confirm"
			title="Delete Record?"
			content="Are you sure you wish to delete this record?" 
		/>

		<!-- Delete Record Success -->
		<success-modal	
			noBtn
			:show="showSuccess"
			@confirm="redirectSuccess()"
			action-text="Close"
			title="Record Deleted!"
			content="Selected record has been successfully deleted." 
		/>

		<!-- Record Save -->
		<success-modal
			noBtn
			:show="showSuccessSave"
			action-text="Close"
			title="Record Save"
			content="Intake has been updated" 
		/>
	</intake-layout>
</template>

<script setup lang="ts">
import IntakeLayout from '../../Component/IntakeLayout.vue';
import { ref, onMounted, watch, computed } from 'vue';
import { EllipsisVerticalIcon } from "@heroicons/vue/24/outline";
import { PlusIcon } from "@heroicons/vue/24/solid";
import EditPage from './EditPage.vue';
import ConfirmationModal from "@/components/modals/ConfirmationModal.vue";
import ButtonComponent from "@/components/buttons/ButtonComponent.vue";
import SuccessModal from "@/components/modals/SuccessModal.vue";
import { useRouter, useRoute } from "vue-router";
import { Menu, MenuButton, MenuItems, MenuItem, } from "@headlessui/vue";
import PatientAddressService from "@/classes/Cases/PatientAddressService";
import pickBy from "lodash/pickBy";
import { useNetworkStore } from "@/store/network";
import { PatientAddressIndex } from "@/interfaces/Cases/PatientAddress";
import Relationships from '@/classes/Forms/Relationships';
import { onIonViewWillEnter } from '@ionic/vue';
import CasePatientAddressForm from "@/classes/Forms/Cases/CasePatientAddressForm";
import DB from "@/classes/Databases/Crud";
import TrashIcon from "@/components/icons/trashIcon.vue";
import PencilBlackIcon from "@/components/icons/pencilBlackIcon.vue";

const db = new DB('cases');
const dbPatient = new DB("patients");
const dbCasePatientAddress = new DB("case_patient_addresses");
const networkStore = useNetworkStore();
const isShowNav = ref(true);
const showConfirmation = ref(false);
const showSuccess = ref(false);
const showSuccessSave = ref(false);
const router = useRouter();
const route = useRoute();
const hasContent = ref<boolean>(false);
const patient = ref<string>('');
const selectedItemId = ref<number | null>(null);
const patientId = ref<number | null>(route.params.patient);
const caseId = ref<number | null>(route.params.case);
const offlineParams = ref<string>(route.query.offline);
const isNetworkAvailable = computed(() => networkStore.isNetworkAvailable);
const items = ref<PatientAddressIndex[]>([]);
const relationship = new Relationships();
const isIonWillEnter = ref<boolean>(false);

// const save = () => {
// 	showSuccessSave.value = true;

// 	setTimeout(() => {
// 		window.location.reload();
// 	}, 1000)
// }

const removeConfirmation = (item) => {
	showConfirmation.value = true;
	selectedItemId.value = item.id || item?.offlineData?.offline_id;
};

const deleteItem = () => {
	PatientAddressService.delete(
		patientId.value,
		caseId.value,
		selectedItemId.value,
		pickBy({ isOfflineData: offlineParams.value ? true : false }),
		isNetworkAvailable.value
	)
		.then(() => {
			showSuccess.value = true;
			showConfirmation.value = false;
		})
		.catch((error: object) => console.log(error));
};

const redirectSuccess = (): void => {
	showSuccess.value = false;
	setTimeout(() => {
		window.location.reload();
	}, 300);
};

const getData = async () => {
	if (caseId.value) {
		await PatientAddressService.index(
			patientId.value,
			caseId.value,
			pickBy({ ...{ isOfflineData: offlineParams.value ? true : false }, ...{ rows: 10 } }),
			isNetworkAvailable.value
		)
			.then(async (response: object) => {
				if (isNetworkAvailable.value) {
					items.value = response.data.items;
					patient.value = response.data.patient;
					hasContent.value = response.data.items.total > 0 ? true : false;
                    await storePatientAddressOffline(items.value);

				}  else {
                    await retrievePatientAddressOffline(response);
                }
			})
			.catch((error: object) => console.log(error));
	}
};

const storePatientAddressOffline = async (response: object): Promise<any> => {
	const result = await db.edit(caseId.value, false, 'cases');
	if (result.data.item.length == undefined) {
        const items = response.data;
        for (const index in items) {
            const addressForm = new CasePatientAddressForm(items[index], result.data.item);
            await dbCasePatientAddress.insert(addressForm, items[index].id, true, 'case_patient_addresses');
        }
	}
};

const retrievePatientAddressOffline = async (response: object): any => {
	hasContent.value = response.data.items.total > 0 ? true : false;
    const arr = [];
    for (const index in response.data.items.data) {
        const item = response.data.items.data[index];
        const setItem = JSON.parse(item.content);
        const newItem  = {
			...setItem,
            ...{ 
                id: item.id, 
                cases_id: item.cases_id,
                barangay: await relationship.barangays(setItem),
                city: await relationship.cities(setItem),
                province: await relationship.provinces(setItem),
            }
        };
        arr.push(newItem);
    }
    response.data.items.data = arr;
	items.value = response.data.items;
    await renderPatient();
};

const renderPatient = async (): Promise<any> => {
    if (!isNetworkAvailable.value) {
        const response = await dbPatient.edit(patientId.value, false, 'patients');
        const item = response.data.item;
        if (item.length === undefined) {
            patient.value = `${relationship.capitalFirstLetter(item.last_name)}, ${relationship.capitalFirstLetter(item.first_name)}`;
        }
    }
}

// watch(
// 	() => isNetworkAvailable.value,
// 	(val) => {
// 		console.log("Watcher - Network: " + val);
// 		getData();
// 	}
// );

onIonViewWillEnter(async () => {
    if (isIonWillEnter.value) {
        await getData();
    }
});
onMounted(async () => {
    await getData();
    isIonWillEnter.value = true;
});
</script>