import http from "@/axios";

class Roles {
	async index(payload?: object): Promise<any> {
		return await http.get("settings/role/index", { params: payload });
	}

	async show(id: number): Promise<any> {
		return await http.get(`settings/role/show/${id}`);
	}
}
export default new Roles();
