import http from "@/axios";
import { DataDictionary } from "@/interfaces/Settings/DataDictionary";

import DB from "@/classes/Databases/Crud"
import Profile from "@/classes/Profile";

import PaginationDataDictionary from '@/classes/Databases/PaginationDataDictionary';

import { Network } from '@capacitor/network';

const db = new DB('data_dictionaries', new PaginationDataDictionary('data_dictionaries'));

class DataDictionaryService implements DataDictionary {

	async index(params: any = {}, fromSettings: boolean, networkStatus = true): Promise<any> {
		if (networkStatus) {
			return await http.get(fromSettings ? "settings/data-dictionaries" : "data-dictionaries", { params: params });
		} else {
			return await db.index(params, '', true, 'data_dictionaries');
		}
	}

	async show(scopeBinding: any, params: any = {}, networkStatus = true): Promise<any> {
        if (networkStatus) {
            return await http.get(`data-dictionaries/${scopeBinding.dataDictionary}`, { params: params });
        } else {
            return await db.edit(scopeBinding.dataDictionary, false, 'data_dictionaries');
        }
		// return await Profile.view().then(() => {
		// 	return http.get(`data-dictionaries/${scopeBinding.dataDictionary}`, { params: params });
		// }).catch(() => { 
		// 	return await db.edit(scopeBinding.dataDictionary, false, 'data_dictionaries');
		// })
	}

	async delete(scopeBinding: any): Promise<any> {
		return await http.delete(`settings/data-dictionaries/${scopeBinding.dataDictionary}/delete`);
	}

	async restore(scopeBinding: any): Promise<any> {
		return await http.patch(`settings/data-dictionaries/${scopeBinding.dataDictionary}/restore`);
	}

	async edit(scopeBinding: any, params: any = {}): Promise<any> {
		return await http.get(`settings/data-dictionaries/${scopeBinding.dataDictionary}/edit`, { params: params });
	}

	async update(scopeBinding: any, params: any = {}): Promise<any> {
		return await http.put(`settings/data-dictionaries/${scopeBinding.dataDictionary}/update`, params);
	}

	async store(params: any = {}): Promise<any> {
		return await http.post(`settings/data-dictionaries`, params);
	}
}

export default new DataDictionaryService();
