import { EnumsInterface } from "@/interfaces/EnumsInterface";

const PsychScreeningSubtType:EnumsInterface[] = [
    {id: 0, value: 0, label: "Psych Screening Interview"},
    {id: 0, value: 0, label: "Psych Screening Measure: Columbia Suicide Screening Rating Scale"},
    {id: 0, value: 0, label: "Psych Screening Measure: Others(Specify in Remarks Box)"},
    {id: 0, value: 0, label: "Patient Health Questionaire 9 (PHQ-9)"},
    {id: 0, value: 0, label: "Post Trauma Assessment Questionaire(PTAQ)"},
]

export default PsychScreeningSubtType