import { SafetyAssessmentCreate } from "@/interfaces/Cases/SafetyAssessment";
export default class SafetyAssessmentForm implements SafetyAssessmentCreate {
	last_name: string | null;
	first_name: string | null;
	middle_name: string | null;
	assessment_date: string | null;
	is_assessment_patient_sibling: boolean;
	appointment: string | null;
	brief_family_background: string | null;
	family_background: string | null;
	yes_count: number | 0;
	no_count: number | 0;
	unknown_count: number | 0;
	risk_code_id: number | null;
	immediate_safety_plan: string | null;
	overall_risk_assessment: string | null;
	questions: [];
	case_number: string | null;
	constructor(item: SafetyAssessmentCreate) {
		this.last_name = item?.last_name;
		this.first_name = item?.first_name;
		this.middle_name = item?.middle_name;
		this.assessment_date = item?.assessment_date;
		this.is_assessment_patient_sibling = item?.is_assessment_patient_sibling || false;
		this.appointment = item?.appointment;
		this.brief_family_background = item?.brief_family_background;
		this.family_background = item?.family_background;
		this.yes_count = item?.yes_count;
		this.no_count = item?.no_count;
		this.unknown_count = item?.unknown_count;
		this.risk_code_id = item?.risk_code_id;
		this.immediate_safety_plan = item?.immediate_safety_plan;
		this.overall_risk_assessment = item?.overall_risk_assessment;
		this.questions = [];
		this.case_number = item?.case_number;
	}

	updateSafetyAssessment(item: any) {
		this.last_name = item?.last_name;
		this.first_name = item?.first_name;
		this.middle_name = item?.middle_name;
		this.assessment_date = item?.assessment_date;
		this.is_assessment_patient_sibling = item?.is_assessment_patient_sibling || false;
		this.appointment = item?.appointment;
		this.brief_family_background = item?.brief_family_background;
		this.family_background = item?.family_background;
		this.yes_count = item?.yes_count;
		this.no_count = item?.no_count;
		this.unknown_count = item?.unknown_count;
		this.risk_code_id = item?.risk_code_id;
		this.immediate_safety_plan = item?.immediate_safety_plan;
		this.overall_risk_assessment = item?.overall_risk_assessment;
		this.case_number = item?.case_number;
		this.questions = [];
	}

    updateAutoFill(item: any) {
        this.last_name = item?.last_name;
        this.first_name = item?.first_name;
        this.middle_name = item?.middle_name;
        this.case_number = item?.case_number;
        this.assessment_date = item?.assessment_date;
    }

    offlineCreateValidation(form: any) {
		const errors = <any>{};
		let hasOfflineError = false;

		if (!form.first_name) {
			hasOfflineError = true;
			Object.assign(errors, { first_name: "The first name field is required." });
		}

		if (!form.last_name) {
			hasOfflineError = true;
			Object.assign(errors, { last_name: "The last name field is required." });
		}

        if (!form.assessment_date) {
			hasOfflineError = true;
			Object.assign(errors, { assessment_date: "The Date and Time field is required." });
		}

        // if (!form.is_assessment_patient_sibling) {
		// 	hasOfflineError = true;
		// 	Object.assign(errors, { is_assessment_patient_sibling: "The Assessment is for patient's sibling field is required." });
		// }

        if (!form.risk_code_id) {
			hasOfflineError = true;
			Object.assign(errors, { risk_code_id: "The Risk Code field is required." });
		}

		return {
			hasOfflineError: hasOfflineError,
			errors: errors,
		};
	}
}
