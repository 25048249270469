import http from "@/axios";
import { Companion } from "@/interfaces/Cases/Companion";
import CaseCompaionOfflineForm from "../Forms/Cases/CaseCompaionOfflineForm";
import DB from "@/classes/Databases/Crud";
import OfflineCaseInformant from "../Forms/Cases/OfflineCaseInformant";

const db = new DB("case_companions");
const dbCase = new DB("cases");
const dbDraft = new DB("drafts");

class CompanionService implements Companion {
	async index(patient: number, patientCase: number, payload: any, networkStatus = true): Promise<any> {
		if (networkStatus) {
			return await http.get(`patients/${patient}/cases/${patientCase}/companions`, { params: payload }).catch(error => error);
		}

		return await db.index(payload, ` WHERE cases_id=${patientCase}`, true, 'case_companions');
	}

	async create(patient: number, patientCase: number, payload: object, networkStatus = true): Promise<any> {
		if (networkStatus) {
			return await http.get(`patients/${patient}/cases/${patientCase}/companions/create`, { params: payload }).catch(error => error);
		}
	}

	async store(patient: number, patientCase: number, payload: any, networkStatus = true): Promise<any> {
		if (networkStatus) {
			return await http.post(`patients/${patient}/cases/${patientCase}/companions`, payload).catch(error => error);
		}

        return await OfflineCaseInformant.createCaseInformant(patient, patientCase, payload);
	}

	async edit(
		patient: number,
		patientCase: number,
		companion: number,
		payload: any,
		networkStatus = true
	): Promise<any> {
		if (networkStatus) {
			return await http.get(`patients/${patient}/cases/${patientCase}/companions/${companion}/edit`, {
				params: payload,
			}).catch(error => error);
		}

        return await db.edit(companion, false, 'case_companions');
	}

	async update(
		patient: number,
		patientCase: number,
		companion: number,
		payload: any,
		networkStatus = true
	): Promise<any> {
		if (networkStatus) {
			return await http.put(`patients/${patient}/cases/${patientCase}/companions/${companion}/update`, payload).catch(error => error);
		}

        return await OfflineCaseInformant.updateCaseInformant(patient, patientCase, companion, payload);
	}

	async delete(
		patient: number,
		patientCase: number,
		companion: number,
		payload: any,
		networkStatus = true
	): Promise<any> {
		if (networkStatus) {
			return await http.delete(
				`patients/${patient}/cases/${patientCase}/companions/${companion}/delete`,
				payload
			).catch(error => error);
		}

        return await OfflineCaseInformant.deleteCaseInformant(patient, patientCase, companion, payload);
	}
}

export default new CompanionService();
