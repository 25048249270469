import http from "@/axios";
import Storage from "@/helpers/storage";
import router from "@/router";
import CryptoJS from 'crypto-js'
import DatabaseInitialization from "@/database/DatabaseInitialization";
import { RoleTypes } from "@/views/enums/RoleTypes"
import DB from "./Databases/Crud";
import InterventionService from "./Interventions/InterventionService";

const storage = new Storage();
const databaseInitialization = new DatabaseInitialization();
const db = new DB('dropdown_options');
const dbDraft = new DB('drafts');

const login = async (email: string, password: string) => {
	return await http
		.post("login", {
			email,
			password,
		})
		.then(async (result: any) => {
            return result;
		});
};

const downloadResources = async (response: any) => {
    await databaseInitialization.initialize();
    await InterventionService.getFormStructure();
    await migrateBackEndOptions(response.data.user.office_id);
    gotoDashboard(response.data.user.role_id, response);
    
}

const logout = async () => {
	return await http
		.post("logout")
		.then(async () => {
            await removeCacheWhenLoggingOut();
		})
		.catch(async (err: any) => {
            await removeCacheWhenLoggingOut();
			console.log(err);
		});
};

const removeCacheWhenLoggingOut = async () => {
    try {
        await dbDraft.select('DELETE FROM drafts');
        // await databaseInitialization.clearTables();
        caches.delete('child-protection-v1');
        caches.delete('child-protection-dynamic');
        window.location.reload();
    } catch (error) {
        console.log(error)
    }
    
    storage.clearItems(["access_token", "permissions", "user", "isSessionLock", "search"]);
    router.push("/login");
}

const hasPermission = (permission: string): boolean => {
	const checker = storage.get("permissions");

	if (checker) {
		const decryptedPermissions = CryptoJS.AES.decrypt(storage.get("permissions"), process.env.VUE_APP_SECRET_KEY).toString(CryptoJS.enc.Utf8);
		const permissions = JSON.parse(decryptedPermissions);

		if (permissions) {
			return permissions.includes(permission);
		}
	}

	return false;
};

const migrateBackEndOptions = async (office_id?:number): Promise<void> => {

    const response = await http.get(`fetch-options/${office_id}`,).catch(error => error)
    const table = 'dropdown_options';
	
    if (response.status === 200) {
        for (const item in response.data) {
            await db.select(`DELETE FROM ${table} WHERE option="${item}"`);
            if (response?.data[item]) {
                await db.insert({
                    option: item,
                    content: JSON.stringify(response?.data[item]),
                }, null, false, 'dropdown_options');
            }
        }
    } else {
        await logout();
    }
}

const gotoDashboard = (role:number, result: any):void =>{
    const wcpuRoles = [
        RoleTypes.OFFICE_ADMIN,
        RoleTypes.OFFICE_NORMAL,
        RoleTypes.NURSE,
        RoleTypes.WCPU,
        RoleTypes.OFFICE_TRAINEE,
    ]
    const tokens = new Storage();
            
    tokens.set("access_token", result.data?.token);
    tokens.set("user", JSON.stringify(result.data?.user));
    tokens.set("permissions", CryptoJS.AES.encrypt(JSON.stringify(result.data.permissions), process.env.VUE_APP_SECRET_KEY).toString());
    
    localStorage.removeItem('cpn-offline-draft-count');
    localStorage.removeItem('cpn-online-sync-attemp');
    
    if(wcpuRoles.includes(role)){
        window.location.href = "/dashboard/wcpu";
    } else {
        window.location.href = "/dashboard";
    }
}

export { login, logout, hasPermission, downloadResources, removeCacheWhenLoggingOut };
