<template>
	<user-layout :hasBack="true" backLink="/settings/data-dictionary/index">
		<div class="max-w-[500px] mx-auto py-6">
			<form-section :form="form" :errors="errors">
				<div class="col-span-2">
					<div class="w-full flex items-center justify-end space-x-3">
						<a @click="router.replace('/settings/data-dictionary/index')">
							<button-component btnWidth="quinary">Cancel</button-component>
						</a>

						<button-component btnWidth="primary" @click="save()">Save Changes</button-component>
					</div>
					<confirmation-modal
						:typeTwo="true"
						:show="showConfirmation"
						@cancel="showConfirmation = false"
						@confirm="
							showSuccess = true;
							showConfirmation = false;
						"
						cancel-text="Cancel"
						action-text="Confirm"
						title="Update Record"
						content="Are you sure you wish to update this record?" />
					<success-modal
						:show="showSuccess"
						@confirm="redirectSuccess()"
						action-text="Close"
						title="Record Updated!"
						:content="successMessage" />
				</div>
			</form-section>
		</div>
	</user-layout>
</template>
<script setup lang="ts">
import { onMounted, ref } from "vue";
import UserLayout from "@/layouts/UserLayout.vue";
import FormSection from "./FormSection.vue";
import ButtonComponent from "@/components/buttons/ButtonComponent.vue";
import ConfirmationModal from "@/components/modals/ConfirmationModal.vue";
import SuccessModal from "@/components/modals/SuccessModal.vue";
import { useRoute, useRouter } from "vue-router";
import DataDictionaryService from "@/classes/Settings/DataDictionaryService";
import { errorResponse } from "@/helpers/errorMessage";

const route = useRoute();
const router = useRouter();

const showConfirmation = ref<boolean>(false);
const showSuccess = ref<boolean>(false);
const successMessage = ref<string>("Data has been successfully updated!");
const form = ref<{ name: string; content: string }>({
	name: "",
	content: "",
});
const errors = ref<any>();
const dataDictionaryId = ref<any>(route.params.dataDictionary);

const save = (): void => {
	DataDictionaryService.update(
		{
			dataDictionary: dataDictionaryId.value,
		},
		form.value
	)
		.then((response: any) => {
			showConfirmation.value = true;
			successMessage.value = response.data.message;
		})
		.catch((error: any) => (errors.value = errorResponse(error.response?.data?.errors)));
};

const redirectSuccess = (): void => {
	showSuccess.value = false;
	router.replace("/settings/data-dictionary/index");
};

onMounted(() => {
	DataDictionaryService.edit({
		dataDictionary: dataDictionaryId.value,
	})
		.then((response: any) => {
			form.value.name = response.data.item.name;
			form.value.content = response.data.item.content;
		})
		.catch((error: any) => console.log(error));
});
</script>
