<template>
	<user-layout>
		<div class="-mx-6 w-[calc(100%+48px)]">
			<div
				class="fixed top-20 left-0 z-10 bg-white px-6 py-4 shadow-[0_1px_0px_0px_rgba(0,0,0,0.08)] flex justify-between items-center w-full">
				<a @click="router.replace(`/settings/telecpu-locations/${locationId.value}/view?tab=offices`)">
					<button-component customClass="tertiary text-black hover:text-primary-600 transition">
						<!-- <img src="assets/icon/ic-arrow-left.svg" alt="back-arrow" class="w-6 h-6 object-contain mr-2" /> -->
						<arrow-left-icon class="object-contain w-6 h-6 mr-2" />
						<span class="text-sm font-semibold">Back</span>
					</button-component>
				</a>

				<tab-component :tabs="tabs" />

				<div class="w-[92px]"></div>
			</div>
			<div class="mt-[104px] max-w-[500px] mx-auto mb-6">
				<form-section :form="form" :errors="errors">
					<div class="col-span-2">
						<div class="w-full flex items-center justify-end space-x-3">
							<button-component btnWidth="primary" @click="save()">Save Changes</button-component>
						</div>
						<confirmation-modal
							:typeTwo="true"
							:show="showConfirmation"
							@cancel="showConfirmation = false"
							@confirm="
								showSuccess = true;
								showConfirmation = false;
							"
							cancel-text="Cancel"
							action-text="Confirm"
							title="Save Record"
							content="Are you sure you wish to update this record?" />
						<success-modal
							:show="showSuccess"
							@confirm="redirectSuccess()"
							action-text="Close"
							title="Record Updated!"
							:content="successMessage" />
					</div>
				</form-section>
			</div>
		</div>
	</user-layout>
</template>
<script setup lang="ts">
import { onMounted, ref, computed } from "vue";
import UserLayout from "@/layouts/UserLayout.vue";
import FormSection from "./FormSection.vue";
import ButtonComponent from "@/components/buttons/ButtonComponent.vue";
import TabComponent from "@/components/partials/TabComponent.vue";
import ConfirmationModal from "@/components/modals/ConfirmationModal.vue";
import SuccessModal from "@/components/modals/SuccessModal.vue";
import TeleCpuLocationService from "@/classes/Settings/TeleCpuLocationService";
import TeleCpuOfficeService from "@/classes/Settings/TeleCpuOfficeService";
import { errorResponse } from "@/helpers/errorMessage";
import { useRoute, useRouter } from "vue-router";
import ArrowLeftIcon from "@/components/icons/arrowLeftIcon.vue";

const route = useRoute();
const router = useRouter();

const showConfirmation = ref<boolean>(false);
const showSuccess = ref<boolean>(false);
const successMessage = ref<string>("Data has been successfully updated!");
const form = ref<{ name: string }>({
	name: "",
});
const errors = ref<any>();
const locationId = computed<any>(() => route.params.location);
const telcpuId = computed<any>(() => route.params.telcpu);

const tabs = ref<{ name: string; count: number; tab: string; href: string; allowed: boolean }[]>([
	{
		name: "Information",
		count: 0,
		tab: "",
		href: `/settings/telecpu-locations/${locationId.value}/view`,
		allowed: true,
	},
	{
		name: "Offices",
		count: 0,
		tab: "offices",
		href: `/settings/telecpu-locations/${locationId.value}/view?tab=offices`,
		allowed: true,
	},
]);

const save = (): void => {
	TeleCpuOfficeService.update(
		{
			location: locationId.value,
			telcpu: telcpuId.value,
		},
		form.value
	)
		.then((response: any) => {
			showConfirmation.value = true;
			successMessage.value = response.data.message;
		})
		.catch((error: any) => (errors.value = errorResponse(error.response?.data?.errors)));
};

const redirectSuccess = (): void => {
	showSuccess.value = false;
	router.replace(`/settings/telecpu-locations/${locationId.value}/view?tab=offices`);
};

onMounted(() => {
	TeleCpuLocationService.edit({
		location: locationId.value,
	})
		.then((response: any) => {
			tabs.value[1].count = response.data.item.telcpus_count;
		})
		.catch((error: any) => console.log(error));

	TeleCpuOfficeService.edit({
		location: locationId.value,
		telcpu: telcpuId.value,
	})
		.then((response: any) => {
			form.value.name = response.data.item?.name;
		})
		.catch((error: any) => console.log(error));
});
</script>
