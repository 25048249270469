import { EnumsInterface } from "@/interfaces/EnumsInterface";

const ChildType:EnumsInterface[] = [
    {id: 0, value: 0, label: "An orphan"},
    {id: 1, value: 1, label: "Foundling"},
    {id: 2, value: 2, label: "Unaccompanied Minor"},
    {id: 3, value: 3, label: "Street Involved Child"},
    {id: 4, value: 4, label: "Child with Disability"},
]

export default ChildType