import http from "@/axios";
import { Email } from "@/interfaces/Cases/IntakeForm/Email";

class EmailService implements Email {
	async index(patient: number, patientCase: number, payload: any, networkStatus = true): Promise<any> {
		if (networkStatus) {
			return await http.get(`patients/${patient}/cases/${patientCase}/intakeForm/emailAddress`, { params: payload });
		}
	}

	async store(patient: number, patientCase: number, payload: any, networkStatus = true): Promise<any> {
		if (networkStatus) {
			return await http.post(`patients/${patient}/cases/${patientCase}/intakeForm/emailAddress`, payload);
		}
	}

	async update(
		patient: number,
		patientCase: number,
		patientEmail: number,
		payload: any,
		networkStatus = true
	): Promise<any> {
		if (networkStatus) {
			return await http.put(
				`patients/${patient}/cases/${patientCase}/intakeForm/emailAddress/${patientEmail}/update`,
				payload
			);
		}
	}

	async delete(
		patient: number,
		patientCase: number,
		patientEmail: number,
		payload: any,
	): Promise<any> {
        return await http.delete(
            `patients/${patient}/cases/${patientCase}/intakeForm/emailAddress/${patientEmail}/delete`,
            payload
        );
	}
}

export default new EmailService();
