export enum ReportTypes {
    PRIMARY_DIAGNOSIS = 1, 
    COMORBIDITY_VAC_VAW = 2, 
    VAC = 3, 
    VAW = 4, 
    PWD = 5, 
    MENTAL_HEALTH = 6, 
    SUICIDAL_RISK = 7, 
    PERPETRATOR_RELATION = 8, 

    BARANGAY = 9,
    CITY = 10, 
    PROVINCE = 11, 
    REGION = 12, 

    OFFICE = 13,
    NATIONALITY = 14,
    RELIGION = 15, 
    INDIGENOUS_GROUP = 16, 
    
}
