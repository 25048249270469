import DB from "@/classes/Databases/Crud";
import { OfflineAction } from "@/views/enums/OfflineAction";
import PaginationCase from "@/classes/Databases/PaginationCases";
import UpdateCaseOfflineForm from "./UpdateCaseOfflineForm";
import CreateCaseOfflineForm from "./CreateCaseOfflineForm";
import Relationships from "../Relationships";
import CreatePatientCaseOfflineForm from "./CreatePatientCaseOfflineForm";

const dbPatient = new DB('patients');
const dbCases = new DB('cases' , new PaginationCase("cases"));
const relationship = new Relationships();
class OfflinePatientCases {
    async renderPatientCasesIndex(response: any) {
        const data = response.data.items.data;
        const items = [];
        for (const index in data) {
            let patient = await dbPatient.select(`SELECT * FROM patients WHERE id="${data[index].patient_id}"`);
            patient = patient.length > 0 ? JSON.parse(patient[0].content) : null;
            const content = JSON.parse(data[index].content);
            items.push({
                ...content,
                ...{ 
                    id: data[index].id, 
                    patient_id: data[index].patient_id,
                    primary_diagnosis: content.diagnoses?.primary_diagnoses?.name,
                },
                ...{ patient:
                    { 
                        abbr: patient?.abbr,
                        profile_photo_url: patient?.profile_photo_url, 
                        first_name: patient?.first_name, 
                        last_name: patient?.last_name, 
                        gender: patient?.gender_meta?.description, 
                        birthdate: patient?.birthdate,
                        computed_age: patient?.computed_age,
                        registered_at: patient?.registered_at,
                    }
                }
            });
        }

        return items;
    }

    async storeOfflinePatientDetails(patientInfo: any) {
        const checkIfRecordIsEditedOffline = await dbPatient.select(`SELECT * FROM patients WHERE id=${patientInfo.id} AND (is_created_offline=true OR is_updated_offline=true OR is_deleted_offline=true)`);
        if (checkIfRecordIsEditedOffline.length > 0) {
            return;
        }

        await dbPatient.delete({id: patientInfo.id}, 'patients');
        const response =  await dbPatient.insert({
            id: patientInfo.id,
            last_name: patientInfo.last_name,
            first_name: patientInfo.first_name,
            middle_name: patientInfo.middle_name,
            birthdate: patientInfo.birthdate,
            content: JSON.stringify(patientInfo),
            encoded_by: patientInfo.encoded_by?.id,
            action: OfflineAction.DEFAULT,
            is_sync: 0,
            updated_at: "",
            deleted_at: "",
        }, patientInfo.id, true, 'patients');

        return response;
    }

    async storeOfflineCasesDetails(patientInfo: any, items: any) {
        for (const index in items.data) {
            const item = items.data[index];
            const checkIfRecordIsEditedOffline = await dbCases.select(`SELECT * FROM cases WHERE id=${item.id} AND (is_created_offline=true OR is_updated_offline=true OR is_deleted_offline=true)`);
            if (checkIfRecordIsEditedOffline.length > 0) {
                return;
            }

            await dbCases.delete({id: item.id}, 'cases');
            await dbCases.insert({
                id: item.id,
                patient_id: patientInfo.id,
                case_number: item.case_number,
                initiated_at: item.initiated_at,
                content: JSON.stringify(item),
                encoded_by: patientInfo.encoded_by?.id,
                action: OfflineAction.DEFAULT,
                is_sync: 0,
                created_at: item.created_at,
                updated_at: "",
                deleted_at: "",
            }, item.id, true, 'cases')
        }
    }

    async renderCasesDetails(patientId: any, filters: any) {
        const where = ` WHERE patient_id=${patientId}`;
        const response = await dbCases.index(filters, where, true, 'cases');
        let total = 0;
        let data:[] = [];
        let page = null;
        let lastPage = null;
        if (response.data.items.data.length > 0) {
            total = response.data.items.total;
            data = response.data.items.data?.map((item: any) => {
                return {...JSON.parse(item.content), ...{ id: item.id, patient_id: patientId }};
            });

            page = response.data.items.current_page;
            lastPage = response.data.items.last_page;
        } else {
            total = 0;
            data = [];
            page = 1;
            lastPage = 1;
        }

        return {
            total: total,
            data: data,
            page: page,
            lastPage: lastPage,
        }
    }

    async fetchLatestPatientCases(patientId: any) {
        const patient = await dbPatient.selectOfflineLatest(patientId, 'patients');
        const case_details = await dbCases.selectOfflineLatest(null, 'cases');
        return  {
            patient: { id: patient.length > 0 ? patient[0].id : undefined },
            case_details: { id: case_details.length > 0 ? case_details[0].id : undefined },
        };
    }

    async storeNewPatientCase(patient: any, payload: any) {
        const result = await dbPatient.edit(patient, false, 'patients');        
		if (result.data.item.length == undefined) {
			payload.patient_id = patient;
			const caseForm = new UpdateCaseOfflineForm({}, {});
            const contactedForm = await caseForm.createNewForm(payload, result.data.item);
			const createForm = new CreateCaseOfflineForm(
				payload,
				result.data.item,
				contactedForm,
			);

            Object.assign(createForm, {
                is_updated_offline: false, 
                patient_id: patient,
                created_at: relationship.dateFormatFromIso(new Date()),
                updated_at: relationship.dateFormatFromIso(new Date()),
            });

            const responseCase = await dbCases.insert(createForm, null, false, 'cases');
            return {
                data: {
                    item: responseCase,
                }
            }
		}
    }

    renderEditPatientCase(response: any) {
        return {
            id: response.patient_id,
            patient_id: response.patient_id,
            approximate_age: response.approximate_age,
            computed_age: response.computed_age,
            approximate_age_month: response.approximate_age_month,
            approximate_age_year: response.approximate_age_year,
            birthdate: response.birthdate,
            birthdate_formatted: response.birthdate_formatted || relationship.dateFormat(response.birthdate,  'MM/dd/yyyy'),
            birthday_classification: response.birthday_classification,
            civil_status: response.civil_status,
            civil_status_id: response.civil_status_id,
            gender: response.gender,
            gender_meta: response.gender_meta,
            first_name: response.first_name,
            indigeneous: response.indigeneous,
            indigeneous_people_id: response.indigeneous_people_id,
            initiated_at: response.initiated_at,
            initiated_at_formatted: response.initiated_at_formatted,
            last_name: response.last_name,
            middle_name: response.middle_name,
            name: response.name,
            nationality: response.nationality,
            nationality_id: response.nationality_id,
            nickname: response.nickname,
            other_indigeneous_people: response.other_indigeneous_people,
            pwd: response.pwd,
            pwd_meta: response.pwd_meta,
            pwd_specify: response.pwd_specify,
            religion: response.religion,
            religion_id: response.religion_id,
            sex_at_birth: response.sex_at_birth,
            sex_at_birth_meta: response.sex_at_birth_meta,
            registered_at: response.registered_at ? response.registered_at : response.patient.registered_at,
            registered_at_formatted: response.registered_at_formatted ? response.registered_at_formatted : response.patient.registered_at_formatted,
        };
    }

    async updatePatientCase(patient: any, patientCase: any, payload: any) {
        const result = await dbCases.edit(patientCase, false, 'cases');
        const patientResult = await dbPatient.edit(patient, false, 'patients');
		if (result.data.item.length == undefined) {
            let form = new CreatePatientCaseOfflineForm(payload);
            form = await form.renderCreatePatientForm(payload, form);
            Object.assign(form, {
                is_updated_offline: patientResult.data.item.is_created_offline ? false : true,
                is_created_offline: patientResult.data.item.is_created_offline,
                id: patient,
                updated_at: relationship.dateFormatFromIso(new Date()),
            });

            const response = await dbPatient.insert(form, patient, false, 'patients');
            let caseForm = new UpdateCaseOfflineForm({}, result.data.item);
            caseForm = await caseForm.renderCreateCaseForm(caseForm, {}, {});
            const setForm = await caseForm.createNewForm(payload, response, result.data.item)
            const updateForm = new CreateCaseOfflineForm(
                caseForm,
                response,
                setForm,
            );
            Object.assign(updateForm, {
                is_updated_offline: result.data.item.is_created_offline ? false : true, 
                is_created_offline: result.data.item.is_created_offline,
                id: patientCase,
                patient_id: patient,
                updated_at: relationship.dateFormatFromIso(new Date()),
            });
            const responseCase = await dbCases.insert(updateForm, patientCase, false, 'cases');

            return {
                data: {
                    item: responseCase,
                }
            }
		}
    }

    async createNewPatientWithCase(payload: any) {
        let form = new CreatePatientCaseOfflineForm(payload);
        form = await form.renderCreatePatientForm(payload, form);
        const response = await dbPatient.insert(form, null, false, 'patients');
        if (response) {
            const caseForm = new UpdateCaseOfflineForm({}, {});
            const setForm = await caseForm.createNewForm(payload, response);
            const createForm = new CreateCaseOfflineForm(
                payload,
                response,
                setForm
            );
            Object.assign(createForm, {
                is_created_offline: true,
                action: OfflineAction.NEW_RECORD,
                created_at: relationship.dateFormatFromIso(new Date()),
                updated_at: relationship.dateFormatFromIso(new Date()),
            });
            await dbCases.insert(createForm, null, false, 'cases');
        }

        return {
            data: {
                item: response,
            }
        };
    }
}

export default new OfflinePatientCases();