import http from "@/axios";

class FormOptions {

	async index(payload?: object): Promise<any> {
		return await http.get("settings/formOptions/index", { params: payload });
	}

	async show(option: string, payload?: object): Promise<any> {
		return await http.get(`settings/formOptions/show/${option}`, { params: payload });
	}

	async create(option: string, payload: object): Promise<any> {
		return await http.post(`settings/formOptions/show/${option}/create`, payload);
    }
    
	async store(option: string, payload: object, ): Promise<any> {
		return await http.post(`settings/formOptions/show/${option}/store`, payload);
	}

	async edit(option: string, id: number, payload?: object): Promise<any> {
		return await http.get(`settings/formOptions/show/${option}/edit/${id}`, payload);
	}
	
	async update(option: string, id: number, payload?: object): Promise<any> {
		return await http.post(`settings/formOptions/show/${option}/update/${id}`, payload);
	}

	async delete(option: string, id: number, payload?: object): Promise<any> {
		return await http.post(`settings/formOptions/show/${option}/delete/${id}`, payload);
	}

	async restore(option: string, id: number): Promise<any> {
		return await http.post(`settings/formOptions/show/${option}/restore/${id}`);
	}
}
export default new FormOptions();
