<template>
    <form class="grid grid-cols-6 gap-6">
        <div class="lg:col-span-2 md:col-span-3 col-span-6">
            <text-input
                type="text"
                label="Last Name"
                placeholder="Last name"
                name="last_name"
                id="last_name"
                v-model="form.last_name"
                :error="errors?.last_name"
                required
            />
        </div>
        <div class="lg:col-span-2 md:col-span-3 col-span-6">
            <text-input
                type="text"
                label="First Name"
                placeholder="First name"
                name="first_name"
                id="first_name"
                v-model="form.first_name"
                :error="errors?.first_name"
                required
            />
        </div>
        <div class="lg:col-span-2 md:col-span-3 col-span-6">
            <text-input
                type="text"
                label="Middle Name"
                placeholder="Middle name"
                name="middle_name"
                id="middle_name"
                v-model="form.middle_name"
                :error="errors?.middle_name"
            />
        </div>
        <div class="md:col-span-3 col-span-6">
            <text-input
                type="email"
                label="Email Address"
                placeholder="Email address"
                name="email_address"
                id="email_address"
                v-model="form.email"
                :error="errors?.email"
                required
            />
        </div>
        <div class="md:col-span-3 col-span-6">
            <text-input
                type="tel"
                label="Phone Number"
                placeholder="Phone number"
                name="phone_number"
                id="phone_number"
                :add_on_left="true"
                add_on_text="+63"
                :maxlength="10"
                @keypress="numberonly"
                v-model="form.mobile_number"
                :error="errors?.mobile_number"
                required
            />
        </div>
        <div class="col-span-6">
            <text-input
                label="Address"
                placeholder="Address"
                name="address"
                id="address"
                v-model="form.home_address"
                :error="errors?.home_address"
                required
            />
        </div>
        <div class="col-span-6 border-t border-gray-200"></div>
        <div class="col-span-6">
            <div class="flex items-center justify-end w-full space-x-3">
                <a href="/personnel/index/">
                    <button-component
                        btnWidth="quinary"
                    >Cancel</button-component>
                </a>

                <button-component
                    btnWidth="primary"
                    @click="showConfirmation = true"
                >Save Changes</button-component>
            </div>
            <confirmation-modal
                :typeTwo="true"
                :show="showConfirmation"
                @cancel="showConfirmation = false"
                @confirm="submit()"
                cancel-text="Cancel"
                action-text="Confirm"
                title="Save Details"
                content="Are you sure you wish to save this detail?"
            />
            <success-modal
                :show="showSuccess"
                @confirm="showSuccess = false"
                action-text="Close"
                title="Profile Updated!"
                content="Profile has been successfully updated"
            />
        </div>
    </form>

    <page-loader :show="loading"/>
</template>
<script setup lang="ts">

import { ref, onMounted } from 'vue';

import ConfirmationModal from '@/components/modals/ConfirmationModal.vue';
import SuccessModal from '@/components/modals/SuccessModal.vue';

import TextInput from '@/components/inputs/TextInput.vue';

import Profile from "@/classes/Profile";
import assignValues from "@/helpers/assignValues"
import ButtonComponent from '@/components/buttons/ButtonComponent.vue'

import { errorResponse } from "@/helpers/errorMessage";

import Storage from "@/helpers/storage";
import { numberonly } from "@/components/composables/useNumberOnly";

import PageLoader from "@/components/loaders/PageLoader.vue";


const showConfirmation = ref<boolean>(false);
const showSuccess = ref<boolean>(false);
const loading = ref<boolean>(false);

const errors = ref<any>();

const storage = new Storage();

const form = ref<{
    email: string;
    last_name: string;
    first_name: string;
    middle_name: string;
    mobile_number: string;
    home_address: string;
    is_temporary: boolean;
    department_id: any;
    office_id: any;
    role_id: any;
    position_id: any;
}>({
    email: "",
    last_name: "",
    first_name: "",
    middle_name: "",
    mobile_number: "",
    home_address: "",
    is_temporary: false,
    department_id: "",
    office_id: "",
    position_id: "",
    role_id: "",
});

const init = (): void => {
    Profile.view()
        .then(({ data }) => {
            form.value = assignValues(form.value, data.item);
        })
        .catch((error: any) => {
            console.log(error)
        })
}

const submit = (): void => {
    showConfirmation.value = false
    Profile.edit(form.value).then(() => {
        showSuccess.value = true;
        updateProfile();
    })
    .catch((error: any) => {
        errors.value = errorResponse(error.response?.data?.errors)
    })
}

const updateProfile = (): void => {
    let user = JSON.parse(storage.get('user'));
    user = assignValues(user, form.value);

    storage.set('user', JSON.stringify(user));
    console.log(storage.get('user'));
}

onMounted(() => {
    init()
})

</script>