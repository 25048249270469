<template>
    <div class="w-full">
        <section-title 
            fontSmall
            title="Field Option Information"
            description="Relevant data and other details"
        />
        <form class="grid grid-cols-2 gap-6 mt-6">
            <div class="col-span-2 border-t border-gray-200"></div>

            <div class="col-span-2">
                <text-input
                    type="text"
                    label="Option Name"
                    placeholder="Option Name"
                    name="option_name"
                    id="option_name"
                    v-model="form.name"
                    :error="errors?.name"
                />
            </div>
            <slot></slot>
        </form>
    </div>
</template>
<script setup lang="ts">
import { ref } from 'vue';

import SectionTitle from '@/components/partials/SectionTitle.vue';
import TextInput from '@/components/inputs/TextInput.vue';

const props = defineProps({
    form: {
        type: Array,
        required: true,
    },
    errors: {
        type: Array,
        required: true,
    }
})

const form = ref<any>(props.form);
</script>