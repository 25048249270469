import DB from "@/classes/Databases/Crud";
import { OfflineAction } from "@/views/enums/OfflineAction";
import Relationships from "../../Relationships";


const db = new DB("intake_contact_numbers");
const relationship = new Relationships();
class OfflineSyncContactNumber {
    async syncToOffline(payload: any, caseId: any) {
        for (const index in payload.data) {
            const item = payload.data[index];
            await db.insert(
                this.contentFormat(item, caseId),
                item.id,
                true,
                'intake_contact_numbers'
            );
        }
    }

    async index(payload: any, caseId: any, isPaginate = true) {
        return await db.index(payload, ` WHERE cases_id=${caseId}`, isPaginate, 'intake_contact_numbers')
    }

    async save(payload: any, patientId: any, caseId: any) {
        if (payload.id) {
            await this.update(payload, caseId);
        } else {
            await this.create(payload, caseId);
        }
    }

    async create(payload: any, caseId: any) {
        await db.insert({
            cases_id: caseId,
            content: JSON.stringify(payload),
            encoded_by: 0,
            action: OfflineAction.NEW_RECORD,
            is_sync: false,
            created_at: relationship.dateFormatFromIso(new Date()),
            updated_at: relationship.dateFormatFromIso(new Date()),
            deleted_at: "",
            is_created_offline: true,
        }, null, false, 'intake_contact_numbers');
    }

    async update(payload: any, caseId: any) {
        const response = await db.select(`SELECT * FROM intake_contact_numbers WHERE id=${payload.id} LIMIT 1`);
        if (response.length > 0 ) {
            const item = response[0];
            await db.delete({id: item.id}, 'intake_contact_numbers')
            await db.insert({
                id: payload.id,
                cases_id: caseId,
                content: JSON.stringify(payload),
                encoded_by: 0,
                action: OfflineAction.UPDATE_RECORD,
                is_sync: false,
                created_at: item.created_at,
                updated_at: relationship.dateFormatFromIso(new Date()),
                deleted_at: "",
                is_created_offline: item.is_created_offline,
                is_updated_offline: item.is_created_offline ? false : true,
            }, null, false, 'intake_contact_numbers');
        }
    }

    async delete(id: any, caseId: any) {
        const response = await db.edit(id, false, 'intake_contact_numbers');
        if (response.data.item.length === undefined) {
            const item = response.data.item;
            if (item.is_created_offline) {
                await db.delete({id: id}, 'intake_contact_numbers');
            } else {
                await db.insert({
                    id: item.id,
                    cases_id: caseId,
                    content: item.content,
                    encoded_by: item.encoded_by,
                    action: OfflineAction.DELETE_RECORD,
                    is_sync: false,
                    deleted_at: relationship.dateFormatFromIso(new Date()),
                    is_created_offline: item.is_created_offline,
                    is_updated_offline: item.is_updated_offline,
                    is_deleted_offline: true,
                }, item.id, false, 'intake_contact_numbers');
            }
        }
    }

    transformContent(payload: any, item: any) {
        payload.id = item.id;
        payload.updated_at_formatted = relationship.dateFormat(item.updated_at);
        return payload;
    }

    contentFormat(payload: any, caseId: any) {
        return {
            id: payload.id,
            cases_id: caseId,
            content: JSON.stringify(payload),
            encoded_by: 0,
            action: OfflineAction.DEFAULT,
            is_sync: false,
            created_at: "",
            updated_at: "",
            deleted_at: "",
        }
    }
}

export default new OfflineSyncContactNumber();