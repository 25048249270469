
import DB from "@/classes/Databases/Crud";

import Pagination from "@/classes/Databases/Pagination";
import FormOptions from "@/classes/Forms/FormOptions";
import DateFormatter from "@/helpers/DateFormatter";

import { OfflineAction } from "@/views/enums/OfflineAction";

const options  = new FormOptions();
const db = new DB('case_conferences', new Pagination('case_conferences'));

class FactorsService {
	async index(patient: number, patientCase: number, caseConference: number): Promise<any> {
		
		let aggravatingFactors:any = [];
		let mitigatingFactors:any = [];

		const conference = await db.select(`SELECT * FROM case_conferences where id = ${caseConference}`, 'case_conferences');
		const factors = await db.select(`SELECT * FROM aggravating_factors where case_conference_id = ${caseConference}`, 'aggravating_factors');

		if(factors.length > 0){
			aggravatingFactors = JSON.parse(factors[0].aggravatingFactors);
			mitigatingFactors = JSON.parse(factors[0].mitigatingFactors);
		}		
		
		const item = {
			"case_conference_id": patientCase,
			"aggravatingFactors": aggravatingFactors,
			"mitigatingFactors": mitigatingFactors,
			// "other_aggravating": conference[0].other_aggravating,
			// "other_mitigating": conference[0].other_mitigating
			"other_aggravating": factors[0]?.other_aggravating,
			"other_mitigating": factors[0]?.other_mitigating
		};

		return {
			data: {
				aggravatingFactors: await options.aggravatingFactors(),
				mitigatingFactors: await options.mitigatingFactors(),
				item: item,
			}
		}
	}

	async storeOffline(item: any): Promise<void> {
		item = this.buildFactorVariables(item)

		await db.set([ item ], true, 'aggravating_factors');
	}

	async store(patient: number, patientCase: number, caseConference: any, payload: any): Promise<any> {

		const factor = await db.select(`SELECT * FROM aggravating_factors where case_conference_id = ${caseConference}`, 'aggravating_factors');

		if(factor.length > 0){
			this.processUpdate(factor[0], caseConference, payload);
		} else {
			this.processStore(caseConference, payload);
		}

		
	}

	private processStore(caseConference: any, payload: any): Promise<any>{
		const data =  {
			...this.buildFactorVariables(payload), 
			case_conference_id: parseInt(caseConference), 
			is_created_offline: true, 
			created_at:  DateFormatter.customDateFormat(new Date().toDateString(), 'yyyy-MM-dd hh:mm:ss'),
			updated_at:  DateFormatter.customDateFormat(new Date().toDateString(), 'yyyy-MM-dd hh:mm:ss'),
		};

		return db.insert(data, null, false, 'aggravating_factors');
	}

	private processUpdate(factor: any, caseConference: any, payload: any): Promise<any>{
		const data =  Object.assign( factor,
			{
				...this.buildFactorVariables(payload), 
				case_conference_id: parseInt(caseConference), 
				is_updated_offline: factor.is_created_offline ? false : true, 
				updated_at:  DateFormatter.customDateFormat(new Date().toDateString(), 'yyyy-MM-dd hh:mm:ss'),
			}
		);

		return db.insert(data, factor.id, false, 'aggravating_factors');
	}

	private buildFactorVariables(payload:any): any 
	{
		// delete payload.other_aggravating;
		// delete payload.other_mitigating;
		
		payload.id = payload.case_conference_id;
		payload.aggravatingFactors = JSON.stringify(payload.aggravatingFactors);
		payload.mitigatingFactors = JSON.stringify(payload.mitigatingFactors);

		return payload;
	}
}

export default new FactorsService();