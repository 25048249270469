const PerpetratorMigration = {
	name: "perpetrators",
	schema: [
		{ column: "id", value: "INTEGER  PRIMARY KEY AUTOINCREMENT" },
		{ column: "offline_id", value: "INTEGER UNSIGNED" },
        { column: "first_name", value: "VARCHAR(255) NOT NULL" },
		{ column: "last_name", value: "VARCHAR(255) NOT NULL" },
		{ column: "middle_name", value: "VARCHAR(255) DEFAULT NULL" },
		{ column: "birthdate", value: "VARCHAR(255) DEFAULT NULL" },
        { column: "content", value: "LONGTEXT NOT NULL" },
		{ column: "encoded_by", value: "INTEGER UNSIGNED" },
		{ column: "action", value: "INT UNSIGNED" },
		{ column: "is_sync", value: "BOOLEAN DEFAULT FALSE" },
        { column: "is_sync_failed", value: "BOOLEAN DEFAULT FALSE"},
        { column: "is_created_offline", value: "BOOLEAN DEFAULT FALSE" },
        { column: "is_updated_offline", value: "BOOLEAN DEFAULT FALSE" },
        { column: "is_deleted_offline", value: "BOOLEAN DEFAULT FALSE" },
        { column: "created_at", value: "TIMESTAMP" },
		{ column: "updated_at", value: "TIMESTAMP" },
		{ column: "deleted_at", value: "TIMESTAMP" },
        { column: "synching_remarks", value: "TEXT DEFAULT NULL" },
	],
};

export default PerpetratorMigration;
