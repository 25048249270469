<template>
	<form id="form" class="grid grid-cols-6 gap-3 mt-5 md:gap-6 lg:mt-6">
		<div class="col-span-6 pb-3 border-t border-gray-200 lg:pb-0">
			<p
				v-if="errors?.message"
				class="text-[10px] mt-1"
				:class="errors?.message ? 'text-red-600' : 'text-gray-800'"
				id="email-error">
				{{ errors?.message }}
			</p>
		</div>
		<div class="col-span-6 pb-3 md:pb-0">
			<label class="block text-xs font-semibold text-neutral-600">Perpetrator Type</label>
			<div class="flex items-center pt-5 space-x-6">
				<radio-group 
					id="identity"
					:options="identities" 
					name="type" 
					v-model="form.identity" 
					:error="errors?.identity" 
					:disabled="isEdit"
				/>
			</div>
			<p
				v-if="errors?.identity"
				class="text-[10px] mt-1"
				:class="errors?.identity ? 'text-red-600' : 'text-gray-800'"
				id="email-error">
				{{ errors?.identity }}
			</p>
		</div>
		<template v-if="form.identity >= 0">
			<div class="col-span-6 pb-3 md:pb-0">
				<checkbox-input
					:checked="form.is_minor"
					id="is_minor"
					name="is_minor"
					label="Is Minor?"
					v-model="form.is_minor"
					:error="errors?.is_minor" 
					:disabled="isEdit"
				/>
			</div>
			<div v-if="unknown" class="col-span-6">
				<text-input
					type="text"
					label="Perpetrator Tag"
					placeholder="Perpetrator Tag"
					name="perpetrator_tag"
					id="tag"
					required
					:disabled="true"
					v-model="form.tag"
					:error="errors?.tag" 
				/>
			</div>
			<div v-if="unknown" class="col-span-6">
				<text-input
					textarea
					label="Details"
					placeholder="Describe perpetrator"
					name="details"
					id="details"
					v-model="form.details"
					:error="errors?.details" 
					:disabled="isEdit"
				/>
			</div>
			<div v-if="!unknown" class="col-span-6 lg:col-span-2 md:col-span-3">
				<text-input
					type="text"
					label="Last Name"
					placeholder="Last name"
					name="last_name"
					id="last_name"
					required
					v-model="form.last_name"
					:error="errors?.last_name" 
					:disabled="isEdit"
				/>
			</div>
			<div v-if="!unknown" class="col-span-6 lg:col-span-2 md:col-span-3">
				<text-input
					type="text"
					label="First Name"
					placeholder="First name"
					name="first_name"
					id="first_name"
					required
					v-model="form.first_name"
					:error="errors?.first_name"
					:disabled="isEdit"
				/>
			</div>
			<div v-if="!unknown" class="col-span-6 lg:col-span-2 md:col-span-3">
				<text-input
					type="text"
					label="Middle Name"
					placeholder="Middle name"
					name="middle_name"
					id="middle_name"
					v-model="form.middle_name"
					:error="errors?.middle_name" 
					:disabled="isEdit"
				/>
			</div>
			<div class="col-span-6 lg:col-span-2 md:col-span-3">
				<label class="block text-xs font-semibold text-neutral-600"
					>Sex <span class="text-red-600">*</span></label
				>
				<div class="flex items-center pt-2 space-x-6 md:pt-5">
					<radio-group
						id="genders"
						name="gender"
						:options="genders"
						v-model="form.gender"
						:error="errors?.gender" 
						required
						:disabled="isEdit"
					/>
				</div>
				<p
					v-if="errors?.gender"
					class="text-[10px] mt-1"
					:class="errors?.gender ? 'text-red-600' : 'text-gray-800'"
					id="email-error">
					{{ errors?.gender }}
				</p>
			</div>

			<UserBirthdateAge 
				ref="birthdateForm"
				:form="form"
				:errors="errors"
				customClass="lg:col-span-2 md:col-span-3 col-span-6"
			/>
			
			<div class="col-span-6 lg:col-span-2 md:col-span-3">
				<multi-select searchable
					:options="relationships"
					id="perpetrator_relationship_id"
					name="relationship"
					label="Relationship"
					placeholder="Select.."
					required
					v-model="form.perpetrator_relationship_id"
					:error="errors?.perpetrator_relationship_id" 
					:disabled="isEdit"
				/>
			</div>
			<div class="col-span-6 lg:col-span-2 md:col-span-3">
				<text-input
					type="text"
					label="Other Relationship"
					placeholder="Other Relationship"
					name="other_relationship"
					id="other_relationship"
					v-model="form.other_relationship"
					:error="errors?.other_relationship" 
				/>
			</div>
			<div v-if="!unknown" class="col-span-6 lg:col-span-2 md:col-span-3">
				<text-input
					type="text"
					label="Nickname"
					placeholder="Nickname"
					name="nickname"
					id="nickname"
					v-model="form.nickname"
					:error="errors?.nickname" 
					:disabled="isEdit"
				/>
			</div>
			<div v-if="!unknown" class="col-span-6 lg:col-span-2 md:col-span-3">
				<text-input
					type="text"
					label="Occupation"
					placeholder="Occupation"
					name="occupation"
					id="occupation"
					v-model="form.occupation"
					:error="errors?.occupation" 
					:disabled="isEdit"
				/>
			</div>
			<!-- <div v-if="!unknown" class="col-span-6 lg:col-span-2 md:col-span-3">
			<label class="block text-xs font-semibold text-neutral-600">Minor</label>
			<div class="flex items-center pt-5 space-x-6">
				<radio-group :options="yesNoOptions" name="minor" />
			</div>
		</div> -->
			<div v-if="!unknown" class="col-span-6 lg:col-span-2 md:col-span-3">	
				<multi-select 
					searchable
					:options="nationalities"
					id="nationality_id"
					name="nationality"
					label="Nationality"
					placeholder="Select.."
					v-model="form.nationality_id"
					:error="errors?.nationality_id" 
					:disabled="isEdit"
				/>
			</div>
			<div v-if="!unknown" class="col-span-6 lg:col-span-2 md:col-span-3">
				<text-input
					id="other_nationality"
					name="nationality_others"
					label="Specify"
					placeholder="Select.."
					v-model="form.other_nationality"
					:error="errors?.other_nationality" 
					:disabled="form.nationality_id != 'others' || isEdit"
				/>
			</div>
			<div v-if="!unknown" class="col-span-6 lg:col-span-2 md:col-span-3">
				<multi-select searchable
					:options="currentLocations"
					id="current_location"
					name="current_location"
					label="Current Location"
					placeholder="Select.."
					v-model="form.current_location"
					:error="errors?.current_location"
				/>
			</div>
			<div v-if="!unknown" class="col-span-6 lg:col-span-2 md:col-span-3">
				<multi-select searchable
					:options="religions"
					id="religion_id"
					name="religion"
					label="Religion"
					placeholder="Select.."
					v-model="form.religion_id"
					:error="errors?.religion_id" 
					:disabled="isEdit"
				/>
			</div>
			<div v-if="!unknown" class="col-span-6 lg:col-span-2 md:col-span-3">
				<text-input
					id="other_religion"
					name="religion_others"
					label="Specify"
					placeholder="Select.."
					:disabled="form.religion_id != 'others' || isEdit"
					v-model="form.other_religion"
					:error="errors?.other_religion" 
				/>
			</div>
			<div v-if="!unknown" class="col-span-6">
				<text-input
					textarea
					label="Perpetrator's Address/Location"
					placeholder="Perpetrator's Address/Location"
					name="perpetrator_address"
					id="perpetrator_address"
					:textAreaRows="1"
					v-model="form.perpetrator_location"
					:error="errors?.perpetrator_location" />
			</div>
			<div v-if="!unknown" class="col-span-6">
				<text-input
					textarea
					label="Other Information About The Perpetrator"
					placeholder="Other Information About The Perpetrator"
					name="info_about_perpetrator"
					id="about_perpetrator"
					:textAreaRows="1"
					v-model="form.about_perpetrator"
					:error="errors?.about_perpetrator" 
				/>
			</div>
			<div v-if="known || unknown" class="col-span-6 lg:col-span-2 md:col-span-3">
				<text-input
					type="text"
					label="Contact Number"
					placeholder="Contact Number"
					name="contact_number"
					id="contacts"
					:add_on_left="true"
					add_on_text="+63"
					:maxlength="10"
					@keypress="numberonly"
					v-model="form.contacts"
					:error="errors?.contacts" 
					:disabled="isEdit"
				/>
			</div>
		</template>
	</form>
</template>
<script setup lang="ts">
import TextInput from "@/components/inputs/TextInput.vue";
import MultiSelect from "@/components/inputs/MultiSelect.vue";
import RadioGroup from "@/components/inputs/RadioGroup.vue";
import CheckboxInput from "@/components/inputs/CheckboxInput.vue";
import DatePicker from "@/components/inputs/DatePicker.vue";
import { ref, type PropType, watch, onMounted, computed } from "vue";
import { renderSelectV2, renderSelect } from "@/interfaces/DropdownInterface";
import { PerpetratorCreate } from "@/interfaces/Cases/Perpetrator";
import { PerpetratorIdentity } from "@/views/enums/PerpetratorIdentity";
import { numberonly } from "@/components/composables/useNumberOnly";
import { useRoute } from "vue-router";

import UserBirthdateAge from '@/components/forms/UserBirthdateAge.vue'

const verified = ref<boolean>(false);
const known = ref<boolean>(false);
const unknown = ref<boolean>(false);
const route = useRoute();
const birthdateForm = ref<any>(null);

const props = defineProps({
	form: {
		type: Object as PropType<PerpetratorCreate>,
		required: true,
	},
	errors: {
		type: Object,
		required: true,
	},
	genders: {
		type: [] as PropType<renderSelectV2>,
		default: [],
	},
	identities: {
		type: [] as PropType<renderSelectV2>,
		default: [],
	},
	relationships: {
		type: [] as PropType<renderSelect>,
		default: [],
	},
	nationalities: {
		type: [] as PropType<renderSelect>,
		default: [],
	},
	religions: {
		type: [] as PropType<renderSelect>,
		default: [],
	},
	currentLocations: {
		type: [] as PropType<renderSelect>,
		default: [],
	},
	item: {
		type: Object as PropType<PerpetratorCreate>,
		required: true,
	},
	isEdit: {
		type: Boolean,
		default: false,
	},
});

const form = ref<PerpetratorCreate>(props.form);
const setStaticIdentity = form.value.identity;
const identities = computed<renderSelect[]>(() =>
	props.identities.filter((item) => {
		if (route.params.perpetrator && setStaticIdentity != PerpetratorIdentity.UNKNOWN) {
			if (item.id != PerpetratorIdentity.UNKNOWN) {
				return item;
			}
		} else {
			return item;
		}
	})
);

const resetUnkownForm = () => {
	form.value.tag = props.item?.tag;
	form.value.details = props.item?.details;
	form.value.contacts = null;
};

watch(
	() => form.value.identity, 
	(value) => {

	if (value == PerpetratorIdentity.KNOWN) {
		known.value = true;
		unknown.value = false;
		resetUnkownForm();
	
	}

	if (value == PerpetratorIdentity.UNKNOWN) {
		known.value = false;
		unknown.value = true;
		resetUnkownForm();
	}
});

const identifyIdentity = () => {
	if (form.value.identity == PerpetratorIdentity.KNOWN) {
		known.value = true;
	}

	if (form.value.identity == PerpetratorIdentity.UNKNOWN) {
		unknown.value = true;
	}
};

defineExpose({
	form,
	birthdateForm,
})

onMounted(() => {
	identifyIdentity();
});
</script>
