import PrxSqlite from "@/plugins/prx-http/PrxSqlite";
import Migrations from './migrations/TableMigrations'
import Seeders from './seeders/Seeders'
import Storage from "@/helpers/storage";

const prx = new PrxSqlite;
const dbName = 'cpn';

const storage = new Storage();

export default class DatabaseInitialization { 
    
    get db(): PrxSqlite
    {
        return prx;
    }

    async connect(): Promise<any> {
        // return await prx.connect(process.env.VUE_APP_DB_NAME);
        return await prx.connect(dbName);
    }

    async initialize(): Promise<any> {
        await this.connect();
        await prx.drop(Migrations.map((table) => table.name));
        
        const status = await prx.keyExists('drafts');

        if (status) {
            await this.clearTables();
        } else {
            await this.migrate();
        }
    }

    async migrate():  Promise<any> { 
        await this.connect();

        await prx.create({
            database: dbName,
            version: 2,
            encrypted: false,
            mode: 'full',
            tables: Migrations
        });

        storage.set('db_initialize', true)
    }

    async seed():  Promise<any> { 
        return await Seeders.forEach((seeder:any) => { 
            prx.set(seeder.table, seeder.data)
        });
    }

    prx(): any {
        return prx
    }

    truncate(): void { 
        this.connect().then(() => ( prx.drop(Migrations.map((tables: any) => tables.name))))
    }

    async clearTables():Promise<void> {
        await prx.clear('drafts');
        await prx.clear('perpetrators');
        await prx.clear('case_perpetrators');
        await prx.clear('case_patient_addresses');
        await prx.clear('case_intakes');
        await prx.clear('case_immediate_safety_assessments');
        await prx.clear('case_guardians');
        await prx.clear('case_companions');
        await prx.clear('indigeneous_people');
        await prx.clear('nationalities');
        await prx.clear('religions');
        await prx.clear('civil_statuses');
        await prx.clear('intervention_data');
        await prx.clear('cases');
        await prx.clear('patients');
        await prx.clear('barangays');
        await prx.clear('cities');
        await prx.clear('provinces');
        await prx.clear('regions');
        await prx.clear('data_dictionaries');
        await prx.clear('dropdown_options');
        await prx.clear('intervention_data');
    }
}
