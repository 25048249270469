<template>
    <user-layout
        hasBack
        backTitle="Case Conference Details"
        :backLink="`/patient/${patientId}/cases/${caseId}/edit`">

        <p class="mt-6 lg:mb-6 mb-3 md:text-2xl text-lg font-medium text-neutral-600">Patient's Name:
            <span class="md:inline-block block font-semibold text-neutral-900">{{ patient }}</span>
        </p>
        <div class="w-full lg:p-6 p-4 border border-gray-200 rounded">
            
            <div class="flex lg:flex-row flex-col lg:justify-between lg:items-center lg:space-x-[76px] lg:gap-0 gap-6">

                <!-- Navigation -->
                <div 
                    class="flex pt-4 pb-2 space-x-8 overflow-x-scroll side-scroll"
                >
                    <div class="flex-shrink-0">
                        <p 
                            @click.prevent="router.replace(navLink('information'))"
                            class="cursor-pointer text-sm font-semibold"
                            :class="[ routeNav('information') ? 'text-primary-600': 'text-neutral-400']"
                        >
                            Information
                        </p>
                    </div>

                    <div class="flex-shrink-0">
                        <p 
                            @click.prevent="caseConferenceId ? router.replace(navLink('factors')) : showModal = true"
                            class="cursor-pointer text-sm font-semibold"
                            :class="[ routeNav('factors') ? 'text-primary-600': 'text-neutral-400']"
                        >
                            Factors
                        </p>
                    </div>

                    <div class="flex-shrink-0">
                        <p 
                            @click.prevent="caseConferenceId ? router.replace(navLink('recommendation')) : showModal = true"
                            class="cursor-pointer text-sm font-semibold"
                            :class="[ routeNav('recommendation') ? 'text-primary-600': 'text-neutral-400']"
                        >
                            Recommendation
                        </p>
                    </div>

                    <div class="flex-shrink-0">
                        <p 
                            @click.prevent="caseConferenceId ? router.replace(navLink('minutes-of-the-meeting')) : showModal = true"
                            class="cursor-pointer text-sm font-semibold"
                            :class="[ routeNav('minutes-of-the-meeting') ? 'text-primary-600': 'text-neutral-400']"
                        >
                            Minutes of the Meeting
                        </p>
                    </div>
                    
                </div>

                <!-- Action Buttons -->
                <div class="flex sm:flex-row flex-col flex-shrink-0 sm:space-x-3 sm:space-y-0 space-y-3">
                    <slot name="actionButtons"  />
                </div>
            </div>

            <!-- Message when changes inputs -->
            <p 
                v-if="isWarningMessage"
                class="text-xs font-medium text-red-600"
            >
                Kindly save your changes first before browsing through other pages / tabs*
            </p>

            <div class="w-full my-6 border border-neutral-100"></div>

            <!-- BODY -->
            <div class="w-full">
                <slot />
            </div>
        </div>

        <error-modal @close="closeError" :show="showModal" content="Prior to proceeding, please save the details in the information tab." />
        
    </user-layout>
</template>
<script setup lang="ts">
import { ref, onMounted, computed, watch } from "vue";
import UserLayout from "@/layouts/UserLayout.vue";
import { useRouter, useRoute } from "vue-router";
import ErrorModal from "@/components/modals/ErrorModal.vue";

const props = defineProps({
    isWarningMessage: {
        type: Boolean,
        default: false
    },
    isShowNav: {
        type: Boolean,
        default: true
    },
    patient: {
        type: String,
        default: ''
    }
})

const router = useRouter();
const route = useRoute();
const patientId = ref<number | null>(route.params.patient);
const caseId = ref<number | null>(route.params.case);
const caseConferenceId = ref<number | null>(route.params.caseConference);
const showModal = ref<boolean>(false)

const navLink = (name:string):string => {
    if (caseConferenceId.value) {
        return `/patient/${patientId.value}/cases/${caseId.value}/case-conference/${name}/${caseConferenceId.value}/edit`
    } else {
        return `/patient/${patientId.value}/cases/${caseId.value}/case-conference/${name}`
    }
};
const routeNav = (name:string) => useRoute().path.split('/').includes(name);

const closeError = () => {
    showModal.value = false
}

</script>
