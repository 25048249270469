<template>
    <div class="w-full">
        <section-title 
            fontSmall
            title="Region Information"
        >
            <p class="text-sm font-medium text-neutral-600">Please fill out all the required fields with the red asterisk (<span class="text-red-600">*</span>)</p>
        </section-title>
        <form class="grid grid-cols-2 gap-6 mt-6">
            <div class="col-span-2 border-t border-gray-200"></div>

            <div class="col-span-2">
                <text-input
                    type="text"
                    label="Region code"
                    placeholder="Region code"
                    name="code"
                    id="code"
                    v-model="form.code"
                    :error="errors?.code"
                    required
                />
            </div>
            
            <div class="col-span-2">
                <text-input
                    type="text"
                    label="Region name"
                    placeholder="Region name"
                    name="name"
                    id="name"
                    v-model="form.name"
                    :error="errors?.name"
                    required
                />
            </div>
            
            <slot></slot>
        </form>
    </div>
</template>
<script setup lang="ts">
import { ref } from 'vue';
import SectionTitle from '@/components/partials/SectionTitle.vue';
import TextInput from '@/components/inputs/TextInput.vue';

import { LocationDataInterface } from "@/interfaces/Settings/LocationInterface"

const props = defineProps({
    form: {
        type: Object as () => LocationDataInterface,
        defualt: [],
        required: true,
    },
    errors: {
        type: Object,
        defualt: [],
        required: true,
    },
});

const form = ref<LocationDataInterface>(props.form);

</script>