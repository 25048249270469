<template>
    <form class="grid grid-cols-6 gap-6">
        <!-- <div v-if="!enableEdit" class="flex items-center col-span-3 space-x-1">
            <text-input
                label="PIN"
                placeholder="******"
                name="pin"
                id="pin"
                readonly
            />
            <button-component
                type="button"
                size="large"
                btnWidth="rounded mt-6 py-[13px]"
                @click="enableEdit = true"
            >
                Edit
            </button-component>
        </div> -->
        
        <!-- <div v-if="enableEdit" class="col-span-6"> -->
        <div class="col-span-6">
            <div class="grid grid-cols-6 gap-6">
                <div class="md:col-span-3 col-span-6">
                    <password-input
                        label="Current PIN"
                        placeholder="Current PIN"
                        name="current-pin"
                        id="current-pin"
                        v-model="form.current_pin_code"
                        :error="errors?.current_pin_code"
                        :maxlength="6"
                        @keypress="numberonly"
                    />
                </div>

                <div class="col-span-3 md:block hidden" />
                <div class="md:col-span-3 col-span-6">
                    <password-input
                        label="New PIN"
                        placeholder="New PIN"
                        name="new-pin"
                        id="new-pin"
                        v-model="form.new_pin_code"
                        :error="errors?.new_pin_code"
                        :maxlength="6"
                        @keypress="numberonly"
                    />
                    <!-- <p class="mt-1 text-xs text-neutral-600">
                        Password Strength: 
                        <span class="font-semibold" 
                            :class="[
                                pinStrength == 1 
                                ? 'text-green-600'
                                : pinStrength == 2
                                ? 'text-red-600' : '', 
                            ]"
                        >Strong</span>
                    </p> -->
                </div>
                <div class="col-span-3 md:block hidden" />
                <div class="md:col-span-3 col-span-6">
                    <password-input
                        label="Retype New PIN"
                        placeholder="Retype New PIN"
                        name="confirm-pin"
                        id="confirm-pin"
                        v-model="form.new_pin_code_confirmation"
                        :error="errors?.new_pin_code_confirmation"
                        :maxlength="6"
                        @keypress="numberonly"
                    />
                    <!-- <p class="mt-1 text-xs font-semibold"
                        :class="[
                            pinMatch == 1 
                            ? 'text-green-600'
                            : pinMatch == 2
                            ? 'text-red-600' : '', 
                        ]"
                    >
                        Password Match
                    </p> -->
                    <a @click="showForgotPin=true" class="inline-block mt-3 text-xs transition text-primary-600 hover:text-primary-800" >
                        Forgotten your PIN?
                    </a>
                </div>
        
                <div class="col-span-3 md:block hidden" />
                <div class="md:col-span-3 col-span-6 border-t border-gray-200"></div>
                

                <div class="col-span-3 md:block hidden" />
                <div class="md:col-span-3 col-span-6">
                    <div class="flex items-center justify-end w-full space-x-3">
                        <a href="/personnel/index/">
                            <button-component
                                btnWidth="quinary"
                            >Cancel</button-component>
                        </a>

                        <button-component
                            btnWidth="primary"
                            @click="validatePin"
                        >Save Changes</button-component>
                    </div>   
                </div>
            </div>
        </div>

        <confirmation-modal
            :typeTwo="true"
            :show="showConfirmation"
            @cancel="showConfirmation = false"
            @confirm="validatePassword('update');"
            cancel-text="Cancel"
            action-text="Proceed"
            title="Confirmation"
            content="Are you sure you wish to change your pin?"
        >
            <template #fields>
                <password-input
                    placeholder="Current Password"
                    name="current-password"
                    id="current-password"
                    v-model="current_password"
                    :error="errors?.current_password"
                />
            </template>
        </confirmation-modal>

        <success-modal
            :show="showSuccess"
            @confirm="reload"
            action-text="Close"
            title="Success!"
            content="Your pin has been successfully changed"
        />


        <confirmation-modal
            :typeTwo="true"
            :show="showForgotPin"
            @cancel="showForgotPin = false"
            @confirm="validatePassword('forgot_pin');"
            cancel-text="Cancel"
            action-text="Proceed"
            title="Forgot your pin?"
            content="Please enter your password to continue."
        >
            <template #fields>
                <password-input
                    placeholder="Current Password"
                    name="current-password"
                    id="current-password"
                    v-model="current_password"
                    :error="errors?.current_password"
                />
            </template>
        </confirmation-modal>
        
        <page-loader :show="loading"/>
    </form>
</template>
<script setup lang="ts">
import { ref } from 'vue';
import { numberonly } from "@/components/composables/useNumberOnly";
import TextInput from '@/components/inputs/TextInput.vue';
import PasswordInput from '@/components/inputs/PasswordInput.vue';
import ButtonComponent from '@/components/buttons/ButtonComponent.vue';

import ConfirmationModal from '@/components/modals/ConfirmationModal.vue';
import SuccessModal from '@/components/modals/SuccessModal.vue';
import GeneralModal from '@/components/modals/GeneralModal.vue';

import Profile from "@/classes/Profile";
import { errorResponse } from "@/helpers/errorMessage";

import PageLoader from "@/components/loaders/PageLoader.vue"

import PinCodeProcessor from '@/helpers/hashPin'

import Storage from "@/helpers/storage";

import { useRouter } from 'vue-router';

const router = useRouter();

const storage = new Storage();

const showConfirmation = ref<boolean>(false);
const showSuccess = ref<boolean>(false);

const showForgotPin = ref<boolean>(false);
const showForgotPinSuccess = ref<boolean>(false);

const loading = ref<boolean>(false);

const errors = ref<any>();
const current_password = ref<string | null>(null);

const user = JSON.parse(storage.get('user'));

const form = ref<{
    current_pin_code: string | null;
    new_pin_code: string | null;
    new_pin_code_confirmation: string | null;
}>({
    current_pin_code: null,
    new_pin_code: null,
    new_pin_code_confirmation: null,
});


const validatePin = (): void => {
    if (PinCodeProcessor.validate(form.value.current_pin_code)) {
        showConfirmation.value = true
    } else {
        errors.value = { 'current_pin_code': 'Pin code does not match' };
    }
}

const validatePassword = (process: string): void => {
    loading.value = true
    Profile.validatePassword(current_password.value)
        .then(() => {
            loading.value = false
            showForgotPin.value = false
            showConfirmation.value = false

            if (process == 'update'){
                validate();
            } else if (process == 'forgot_pin') { 
                resetPin()
            }
        })
        .catch((error: any) => {
            loading.value = false
            
            errors.value = errorResponse(error.response?.data?.errors)
        })
}

const reload = (): void => {
    location.reload()
}

const validate = (): void => {
    showConfirmation.value = false
    if (form.value.new_pin_code != form.value.new_pin_code_confirmation) {
        errors.value = {
            new_pin_code: "New pin code did not match",
            new_pin_code_confirmation: "New pin code did not match"
        }
    } else if (form.value.new_pin_code.length != 6) {
        errors.value = {
            new_pin_code: "New pin code must be 6 digits",
            new_pin_code_confirmation: "New pin code must be 6 digits"
        }
    } else {
        submit()
    }
}

const submit = (): void => {
    showConfirmation.value = false
    loading.value = true

     PinCodeProcessor.setPin(form.value.new_pin_code).then(() => {
        showSuccess.value = true;
        loading.value = false;
    })
    .catch((error: any) => {
        loading.value = false;

        errors.value = errorResponse(error.response?.data?.errors)
    })
}

const resetPin = (): void => {
    user.pin_code = null;

    storage.set('user', JSON.stringify(user));

    loading.value = true;
    router.go(0);
}

</script>