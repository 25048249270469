<template>
	<user-layout
		hasBack
		hasRightCon
		:backLink="patientId ? `/patient/${patientId}/view` : `/patient/index`"
		hasBackClass="flex md:justify-between md:items-center md:flex-row flex-col">
		<template #lowerRight>
			<button-component
				form="form"
				@click="showConfirmation = true"
				customClass="primary md:w-auto w-full md:mt-0 mt-6"
			>
				<!-- <img src="/assets/icon/ic-save.svg" alt="save_icon" class="object-contain w-5 h-5 mr-1 icon" /> -->
				<save-icon class="object-contain w-5 h-5 mr-1 icon" />
				<span>Save Record</span>
			</button-component>

			<div v-if="!newRecord" class="flex md:items-center md:space-x-3 md:space-y-0 space-y-3 md:w-auto w-full md:flex-row flex-col md:mt-0 mt-6">
				<a v-if="hasPermission('can-create-patient-incident')" href="#">
					<button-component @click="newRecord = true">
						<!-- <img
							src="/assets/icon/ic-pencil-underline.svg"
							alt="edit_icon"
							class="object-contain w-4 h-4 mr-1 icon" /> -->
						<pencil-underline-icon class="object-contain w-4 h-4 mr-1 icon" />
						<span>Edit</span>
					</button-component>
				</a>
				<!-- <a
					v-if="hasPermission('can-view-patient-case-inpdf')"
					href="https://www.africau.edu/images/default/sample.pdf"
					target="_blank">
					<button-component
						size="large"
						customClass="bg-green-600 hover:bg-green-700 text-white transition border border-green-600 hover:border-green-700">
						<EyeIcon class="w-5 h-5 mr-2 -ml-1 icon" />
						<span>View PDF</span>
					</button-component>
				</a> -->
			</div>
		</template>
		<div class="mt-6" v-if="loaded">
			<form-section :newRecord="newRecord" :form="form" :errors="errors" :additional-data="additionalData">
				<confirmation-modal
					:typeTwo="true"
					:show="showConfirmation"
					@cancel="showConfirmation = false"
					@confirm="save()"
					cancel-text="Cancel"
					action-text="Confirm"
					title="Save Record"
					content="Are you sure you wish to save this record?" />
				<success-modal
					:show="showSuccess"
					@confirm="redirectSuccess()"
					action-text="Close"
					title="Record Updated!"
					:content="successMessage" />
				<error-modal :show="errors?.message ? true : false" :content="errors?.message" />
			</form-section>
		</div>
		<template #rightContent>
			<div class="lg:sticky static lg:my-10 top-10 lg:h-[70dvh] lg:pb-10 overflow-auto lg:mt-0 mt-6">
				<side-nav :hasRecord="newRecord" :other-details-count="otherDetailsCount" />
			</div>
		</template>
		<page-loader :show="loading" />
	</user-layout>
</template>
<script setup lang="ts">
import { onMounted, ref, watch, computed } from "vue";
import UserLayout from "@/layouts/UserLayout.vue";
import ButtonComponent from "@/components/buttons/ButtonComponent.vue";
import FormSection from "./FormSection.vue";
import SideNav from "./Components/SideNav.vue";
import ConfirmationModal from "@/components/modals/ConfirmationModal.vue";
import SuccessModal from "@/components/modals/SuccessModal.vue";
import ErrorModal from "@/components/modals/ErrorModal.vue";
import { useRouter, useRoute } from "vue-router";
import PatientService from "@/classes/PatientService";
import { CreatePatient } from "@/interfaces/PatientInterface";
import CaseService from "@/classes/Cases/CaseService";
import { errorResponse } from "@/helpers/errorMessage";
import CreatePatientForm from "@/classes/Forms/Patients/CreatePatientForm";
import { hasPermission } from "@/classes/AuthService";
import PageLoader from "@/components/loaders/PageLoader.vue";
import FormOptions from "@/classes/Forms/FormOptions";
import { useNetworkStore } from "@/store/network";
import DB from "@/classes/Databases/Crud";
import pickBy from "lodash/pickBy";
import { onIonViewWillEnter } from "@ionic/vue";
import SaveIcon from "@/components/icons/saveIcon.vue";
import PencilUnderlineIcon from "@/components/icons/pencilUnderlineIcon.vue";
import OfflinePatientCases from "@/classes/Forms/Patients/OfflinePatientCases";

const networkStore = useNetworkStore();
const isNetworkAvailable = computed(() => networkStore.isNetworkAvailable);

const router = useRouter();
const route = useRoute();
const options = new FormOptions();
const db = new DB("patients");
const dbCase = new DB("cases");

// Set to False to enable create other details
const newRecord = ref<boolean>(true);
const showConfirmation = ref<boolean>(false);
const showSuccess = ref<boolean>(false);
const successMessage = ref<string>("Data has been successfully saved!");
const additionalData = ref<object>({});
const createNewPatient = ref<boolean>(route.params.patient ? false : true);
const patientId = ref<number | null>(route.params.patient);
const form = ref<CreatePatient>(new CreatePatientForm());
const errors = ref<object>({});
const loading = ref<boolean>(false);
const loaded = ref<boolean>(false);
const returnData = ref<object>({});
const isCreatedViaOffline = ref<boolean>(false);
const offlineParams = ref<string>(route.query.offline);
const otherDetailsCount = ref<{
	guardians: number;
	companions: number;
	patientAddresses: number;
	perpetrators: number;
	interventions: number;
	caseConferences: number;
}>({
    guardians: 0,
    companions: 0,
    patientAddresses: 0,
    perpetrators: 0,
    interventions: 0,
    caseConferences: 0,
});

const save = async (): void => {
	loading.value = true;
	errors.value = {};

	if (!isNetworkAvailable.value) {
		const validate = form.value.offlineCreateValidation(form.value, patientId.value);
		errors.value = validate?.errors;
		if (validate.hasOfflineError) {
			showSuccess.value = false;
            showConfirmation.value = false;
            loading.value = false;
			return;
		}
	}

	if (createNewPatient.value) {
        const response = await PatientService.store(form.value, isNetworkAvailable.value);

        if (response?.status === 200 || response?.data != undefined) {
            if (!isNetworkAvailable.value) {
                fetchLatestPatientCase();
            } else {
                if (response.data.data.downloadables.patient) {
                    await PatientService.saveOnlineToOfflinePatient(response.data.data.downloadables.patient);
                }

                if (response.data.data.downloadables.cases) {
                    await PatientService.saveOnlineToOfflineCase(response.data.data.downloadables.cases);
                }
                returnData.value = response?.data?.data;
            }
            loading.value = false;
            showSuccess.value = true;
            showConfirmation.value = false;
            successMessage.value = response.data.message;            
        } else {
            const error = response;
            errors.value = errorResponse(error.response?.data?.errors);
            showConfirmation.value = false;
            loading.value = false;
        }
	} else {
        const response = await CaseService.store(
			patientId.value,
			{ ...form.value },
			isNetworkAvailable.value
		);

        if (response?.status === 200 || response?.data != undefined) {
            loading.value = false;
            showSuccess.value = true;
            showConfirmation.value = false;
            successMessage.value = response.data.message;

            if (!isNetworkAvailable.value) {
                fetchLatestPatientCase();
            } else {
                if (response.data.data.downloadables.patient) {
                    await PatientService.saveOnlineToOfflinePatient(response.data.data.downloadables.patient);
                }

                if (response.data.data.downloadables.cases) {
                    await PatientService.saveOnlineToOfflineCase(response.data.data.downloadables.cases);
                }

                returnData.value = response?.data?.data;
            }
        } else {
            const error = response;
            errors.value = errorResponse(error.response?.data?.errors);
            showConfirmation.value = false;
            loading.value = false;
        }
	}
};

const fetchLatestPatientCase = async (): Promise<any> => {
    const response = await OfflinePatientCases.fetchLatestPatientCases(patientId.value);
    returnData.value = response;
	// isCreatedViaOffline.value = true;
};

const redirectSuccess = (): void => {
	showSuccess.value = false;
	newRecord.value = false;
	router.replace(getRedirectURL());
};

const getRedirectURL = ():string => {
	const url = `/patient/${returnData.value?.patient?.id}/cases/${returnData.value?.case_details?.id}/edit`;

	// if(isCreatedViaOffline.value){
	// 	url = url + '?offline=true&viewForm=true'
	// } else {
	// 	url = url + '?viewForm=true'
	// }

	return url + '?viewForm=true&other_details=true';
	// return createNewPatient.value
	// 		? `/patient/${returnData.value?.patient?.id}/cases/${returnData.value?.case_details?.id}/edit${isCreatedViaOffline.value ? "?offline=true" : ""}`
	// 		: `/patient/${patientId.value}/cases/${offlineParams.value ? "?offline=true" : ""}` 
}

const template = (): void => {
	form.value.mental_health = additionalData.value.mentalHealthDiagnoses.map(() => {
		return {
			mental_health_diagnoses_id: "",
			type: "",
			specify: "",
			behavior_type_id: "",
			checked: false,
		};
	});
	form.value.comorbidities = additionalData.value.comorbidities.map(() => {
		return {
			comorbidity_id: "",
			checked: false,
		};
	});
};

const defaultPatientValue = (): void => {
	form.value.is_followup = 0;
	form.value.is_hama = null;
	form.value.is_reabuse = 0;
	form.value.is_inpatient = null;
	form.value.is_internally_displaced = null;
};

const getData = async () => {
	loading.value = true;
	loaded.value = false;
	if (createNewPatient.value) {
        const response = await PatientService.create({}, isNetworkAvailable.value);
        if (response?.status === 200 || response == undefined) {
            if (isNetworkAvailable.value) {
                additionalData.value = response.data;
            } else {
                additionalData.value = await options.createPatientOptions();
            }
            defaultPatientValue();
            template();
            loading.value = false;
            loaded.value = true;
        } else {
            const error = response;
            loading.value = false;
            console.log(error);
        }
	} else {
        const response = await CaseService.create(patientId.value, pickBy({}), isNetworkAvailable.value);

        if (response?.status === 200 || response?.data != undefined) {
            if (isNetworkAvailable.value) {
                additionalData.value = response.data;
                form.value.createCaseService(response.data.item);
            } else {
                additionalData.value = await options.createPatientOptions();
                if (response.data.item.length == undefined) {
                    form.value.createCaseService(JSON.parse(response.data.item.content));
                }
            }

            defaultPatientValue();
            template();
            loading.value = false;
            loaded.value = true;
        } else {
            loading.value = false;
            console.log(error);
        }
	}
};

// watch(
// 	() => isNetworkAvailable.value,
// 	async (val) => {
// 		console.log("Watcher - Network: " + val);
// 		await getData();
// 	}
// );

onIonViewWillEnter(async () => await getData());
// onMounted(async () => {
//     const test = await db.select('SELECT * FROM patients');
//     console.log(test);
//     await getData()
// });
</script>
