<template>

	<div
		class="col-span-1 rounded bg-gray-50 hover:bg-primary-300 flex flex-col items-center justify-center text-center group p-6 h-[100px] cursor-pointer"
		@click="openModal()"
	>
		<p class="mb-2 text-sm text-neutral-900 group-hover:text-primary-600">{{ item.label }}</p>
		<img :src="getFieldIcon(item.value)" class="object-contain h-6 opacity-50 grayscale group-hover:grayscale-0 group-hover:opacity-100" alt="icon">
	</div>

	<div>
		<TransitionRoot as="template" :show="show">
			<div class="absolute right-0 inset-y-0 w-full overflow-hidden h-[calc(100vh-148px)]" @close="$emit('close')">
				<TransitionChild
					as="template"
					enter="transform transition ease-in-out duration-300"
					enter-from="translate-x-full"
					enter-to="translate-x-0"
					leave="transform transition ease-in-out duration-300"
					leave-from="translate-x-0"
					leave-to="translate-x-full">
					<div class="flex flex-col h-full overflow-y-scroll bg-white rounded-l-lg shadow-xl">
						<div class="relative flex flex-col h-full">
							<div
								class="flex items-center px-6 pt-6 pb-2 space-x-2 shrink-0">
								<ArrowLeftIcon
									class="w-6 h-6 transition cursor-pointer text-neutral-500 hover:text-primary-600" 
									@click="$emit('close')"
								/>
								<p class="text-sm text-neutral-900">{{title}}</p>
								<div class="relative group">
									<InformationCircleIcon class="w-5 h-5 cursor-pointer text-primary-600" />
									<div class="absolute top-6 z-10 rounded bg-gray-500 p-3 text-white text-xs min-w-[220px] group-hover:block hidden">
										<p>This type allows a response of one or a few words.</p>
										<br>
										<p><b>Tip:</b> If you wish to add another input field beside this one, simply set the width to half.</p>
									</div>
								</div>
							</div>
							<div class="flex flex-col flex-1 px-6 pt-5 pb-6">
								<div class="flex flex-col flex-1 h-full space-y-5">

									<div>
										<text-input
											type="text"
											label="Field Label"
											placeholder="Field Label"
											id="field_label"
											name="field_label"
											v-model="form.label"
										/>
									</div>
									
									<div class="flex space-x-2">
										<toggle-switch
											:value="form.is_required"
											noPadding
											@change="form.is_required = !form.is_required"
										/>
										<p class="pt-1 text-xs font-semibold text-neutral-600">Is field required?</p>
									</div>
									
									<div>
										<label class="block mb-3 text-xs font-semibold text-neutral-600">Choose field width</label>
										<div class="flex flex-col space-y-2">
											<radio-group
												:options="field_width_options"
												name="field_width"
											/>
										</div>
									</div>
								</div>
								<div class="self-end mt-6 shrink-0">
									<button-component @click="add()">
										<span>Add Field</span>
									</button-component>
								</div>
							</div>
						</div>
					</div>
				</TransitionChild>
			</div>
		</TransitionRoot>
	</div>
</template>
<script setup lang="ts">
import { ref, onMounted, watch, computed } from "vue";

import ButtonComponent from "@/components/buttons/ButtonComponent.vue";
import FieldLabelText from "../Components/Inputs/FieldLabelText.vue";
import FieldWidthRadio from "../Components/Inputs/FieldWidthRadio.vue";
import RequiredSwitch from "../Components/Inputs/RequiredSwitch.vue";

import {
	TransitionChild,
	TransitionRoot,
} from "@headlessui/vue";
import { InformationCircleIcon, ArrowLeftIcon } from "@heroicons/vue/24/outline";

import TextInput from "@/components/inputs/TextInput.vue";
import ToggleSwitch from "@/components/inputs/ToggleSwitch.vue";
import RadioGroup from "@/components/inputs/RadioGroup.vue";
import { FormFieldType } from "@/views/enums/FormFieldType";

const props = defineProps({
	item: {
		type: Object,
		required: true,
	},
	fieldType: {
		type: Number,
		required: true,
	},
	title: {
		type: String,
		default: '',
	},
});

const show = ref<boolean>(false);

const form = ref<{
	label: string|null,
	input_type: number,
	is_half_width: boolean,
	is_required: boolean,
	options?: any,
}>({
	label: null,
	input_type: props.fieldType,
	is_half_width: false,
	is_required: false,
	options: null,
})

const field_width_options = [
    { id: false, label: 'Whole', value: false, radioSelected: null},
    { id: true, label: 'Half', value: true, radioSelected: null},
]

const getFieldIcon = (field: number): string => {
    switch (field) {
        case FormFieldType.INPUT:
            return '/assets/icon/intervention/ic-text.svg';
        case FormFieldType.TEXT:
            return'/assets/icon/intervention/ic-textarea.svg';
        case FormFieldType.DROPDOWN:
            return'/assets/icon/intervention/ic-dropdown.svg';
        case FormFieldType.MULTI_SELECT:
            return'/assets/icon/intervention/ic-multiselect.svg';
        case FormFieldType.RADIO:
            return'/assets/icon/intervention/ic-radio-button.svg';
        case FormFieldType.TOGGLE:
            return'/assets/icon/intervention/ic-toggle.svg';
        case FormFieldType.CHECKBOX_SINGLE:
        case FormFieldType.CHECKBOX_OPTION:
            return'/assets/icon/intervention/ic-checkbox.svg';
        case FormFieldType.LINK:
            return'/assets/icon/intervention/ic-link.svg';
        case FormFieldType.TIME:
        case FormFieldType.DATE:
        case FormFieldType.DATE_TIME:
            return'/assets/icon/intervention/ic-datepicker.svg';
        default:
            return '';
    }
}

const openModal = ():void => {
	show.value = true;
}

const add = ():void => {
	console.log(form.value);
	// const result = v$.value.$validate().then((data) => {
	// 	console.log(data);
	// })
	// console.log(result);
}

</script>
