import { PerpetratorCreate } from "@/interfaces/Cases/Perpetrator";
import { PerpetratorIdentity } from "@/views/enums/PerpetratorIdentity";
export default class PerpetratorForm implements PerpetratorCreate {
	tag: string | null;
	details: string | null;
	identity: number | null;
	is_minor: boolean;
	last_name: string | null;
	first_name: string | null;
	middle_name: string | null;
	gender: boolean;
	birthday_classification: number | null;
	birthdate: string | null;
	approximate_age_year: number | null;
	approximate_age_month: number | null;
	perpetrator_relationship_id: number | null;
	other_relationship: string | null;
	nickname: string | null;
	occupation: string | null;
	nationality_id: number | null;
	other_nationality: string | null;
	religion_id: number | null;
	other_religion: string | null;
	current_location: string | null;
	perpetrator_location: string | null;
	about_perpetrator: string | null;
	contacts: string | null;
	constructor(item: PerpetratorCreate) {
		this.tag = item?.tag;
		this.details = item?.details;
		this.identity = item?.identity;
		this.is_minor = item?.is_minor;
		this.last_name = item?.last_name;
		this.first_name = item?.first_name;
		this.middle_name = item?.middle_name;
		this.gender = item?.gender;
		this.birthday_classification = item?.birthday_classification;
		this.birthdate = item?.birthdate == "0000-00-00" ? "" : item?.birthdate;
		this.approximate_age_year = item?.approximate_age_year;
		this.approximate_age_month = item?.approximate_age_month;
		this.perpetrator_relationship_id = item?.perpetrator_relationship_id;
		this.other_relationship = item?.other_relationship;
		this.nickname = item?.nickname;
		this.occupation = item?.occupation;
		this.nationality_id = item?.nationality_id || null;
		this.other_nationality = item?.other_nationality;
		this.religion_id = item?.religion_id || null;
		this.other_religion = item?.other_religion;
		this.current_location = item?.current_location;
		this.perpetrator_location = item?.perpetrator_location;
		this.about_perpetrator = item?.about_perpetrator;
		this.contacts = item?.contacts;
	}

	updatePerpetrator(item: PerpetratorCreate) {
		this.tag = item?.tag;
		this.details = item?.details;
		this.identity = item?.identity;
		this.is_minor = item?.is_minor;
		this.last_name = item?.last_name;
		this.first_name = item?.first_name;
		this.middle_name = item?.middle_name;
		this.gender = item?.gender;
		this.birthday_classification = item?.birthday_classification;
		this.birthdate = item?.birthdate == "0000-00-00" ? "" : item?.birthdate;
		this.approximate_age_year = item?.approximate_age_year;
		this.approximate_age_month = item?.approximate_age_month;
		this.perpetrator_relationship_id = item?.perpetrator_relationship_id;
		this.other_relationship = item?.other_relationship;
		this.nickname = item?.nickname;
		this.occupation = item?.occupation;
		this.nationality_id = item?.nationality_id || null;
		this.other_nationality = item?.other_nationality;
		this.religion_id = item?.religion_id || null;
		this.other_religion = item?.other_religion;
		this.current_location = item?.current_location;
		this.perpetrator_location = item?.perpetrator_location;
		this.about_perpetrator = item?.about_perpetrator;
		this.contacts = item?.contacts;
	}

	searchPerpetrator(item: PerpetratorCreate) {
		this.first_name = item?.first_name;
		this.last_name = item?.last_name;
		this.birthdate = item?.birthdate == "0000-00-00" ? "" : item?.birthdate;
	}

    offlineCreateValidation(form: any) {
		const errors = <any>{};
		let hasOfflineError = false;

		

		if (!form.perpetrator_relationship_id) {
			hasOfflineError = true;
			Object.assign(errors, { perpetrator_relationship_id: "The relationship field is required." });
		}

        if (form.gender == undefined) {
            hasOfflineError = true;
            Object.assign(errors, { gender: "The gender field is required." });
        }

        if (form.identity != PerpetratorIdentity.UNKNOWN) {
            if (!form.first_name) {
                hasOfflineError = true;
                Object.assign(errors, { first_name: "The first name field is required." });
            }
    
            if (!form.last_name) {
                hasOfflineError = true;
                Object.assign(errors, { last_name: "The last name field is required." });
            }
        }

        if (form.identity == PerpetratorIdentity.UNKNOWN) {
            if (!form.tag) {
                hasOfflineError = true;
                Object.assign(errors, { tag: "The tag field is required." });
            }
        }

		return {
			hasOfflineError: hasOfflineError,
			errors: errors,
		};
	}

    offlineSearch(form: any) {
		const errors = <any>{};
		let hasOfflineError = false;

        if (!form.last_name && !form.first_name) {
            hasOfflineError = true;
            Object.assign(errors, { first_name: "The first name field is required when last name is empty." });
            Object.assign(errors, { last_name: "The last name field is required when last name is empty." });
		}

		return {
			hasOfflineError: hasOfflineError,
			errors: errors,
		};
	}
}
