import Pagination from "./Pagination";

export default class PaginationCases extends Pagination {
	search(params: any, whereCondition = ""): string {
		let query = "";

		if (whereCondition) {
			if (params?.query || params?.case_number) {
				query += ` AND case_number LIKE "%${params.query || params?.case_number}%"`;
			}
			if (params.cases_id) {
				query += ` AND id IN (${params.cases_id}) OR offline_id IN (${params.cases_id})`;
			}
		} else {
			if (params?.query || params?.case_number) {
				query += ` WHERE case_number LIKE "%${params.query || params?.case_number}%"`;
			}

			if (params.cases_id) {
				query += ` ${query.toLowerCase().includes('where') ? 'AND' : 'WHERE' } id IN (${params.cases_id}) OR offline_id IN (${params.cases_id})`;
			}
		}

		return query;
	}
}
