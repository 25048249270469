<template>
    <ion-page>
        <ion-content :fullscreen="true">
            <div class="flex flex-wrap lg:flex-row flex-col lg:items-center h-screen lg:justify-between">
                <auth-logo />

                <div class="w-full px-4 lg:w-1/2 sm:px-20 lg:py-0 py-6">
                    <div class="sm:max-w-[360px] mx-auto">
                        <auth-title
                            title="New PIN"
                            description="Kindly provide your new PIN"
                        />
                        <form class="">
        
                            <div class="mb-6">
                                <password-input
                                    label="New PIN"
                                    placeholder="New PIN"
                                    name="new-pin"
                                    id="new-pin"
                                    :maxlength="6"
                                    @keypress="numberonly"
                                />
                            </div>
                            <div class="mb-6">
                                <password-input
                                    label="Confirm PIN"
                                    placeholder="Confirm PIN"
                                    name="confirm-pin"
                                    id="confirm-pin"
                                    :maxlength="6"
                                    @keypress="numberonly"
                                />
                            </div>
                            <button-component
                                type="button"
                                size="large"
                                btnWidth="w-full rounded-lg"
                                @click="showConfirmation = true"
                            >
                                Reset PIN
                            </button-component>
                            <div class="mt-6 text-center">
                                <a href="/login" class="text-sm m-0 text-primary-600 hover:text-primary-800 transition">Return to Login</a>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <confirmation-modal
                :show="showConfirmation"
                @cancel="showConfirmation = false"
                @confirm="showSuccess = true; showConfirmation = false"
                cancel-text="Cancel"
                action-text="Reset"
                title="Reset PIN"
                content="Are you sure you wish to reset your PIN?"
            />

            <success-modal
                :show="showSuccess"
                @confirm="showSuccess = false"
                action-text="Confirm"
                title="PIN Updated!"
                content="Your PIN has been successfully updated"
            />
        </ion-content>
    </ion-page>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { numberonly } from "@/components/composables/useNumberOnly";
import {
    IonPage,
    IonContent 
} from '@ionic/vue';
import ButtonComponent from '@/components/buttons/ButtonComponent.vue';
import PasswordInput from '@/components/inputs/PasswordInput.vue';
import ConfirmationModal from '@/components/modals/ConfirmationModal.vue';
import SuccessModal from '@/components/modals/SuccessModal.vue';

import AuthLogo from "../Components/AuthLogo.vue";
import AuthTitle from "../Components/AuthTitle.vue";

const showConfirmation = ref<boolean>(false);
const showSuccess = ref<boolean>(false);
</script>