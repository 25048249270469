import http from "@/axios";

class BarangayService {

	async index(payload?: object): Promise<any> {
		return await http.get("settings/barangays", { params: payload });
	}

	async store(payload: object, ): Promise<any> {
		return await http.post(`settings/barangays/store`, payload);
	}

	async edit(barangay: string, payload?: object): Promise<any> {
		return await http.get(`settings/barangays/${barangay}/edit`, payload);
	}
	
	async update(barangay: string, payload?: object): Promise<any> {
		return await http.put(`settings/barangays/${barangay}/update`, payload);
	}

	async delete(barangay: string, payload?: object): Promise<any> {
		return await http.delete(`settings/barangays/${barangay}/delete`, payload);
	}

	async restore(barangay: string): Promise<any> {
		return await http.patch(`settings/barangays/${barangay}/restore`);
	}
}
export default new BarangayService();
