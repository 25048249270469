<template>
    <TrashIcon 
        @click="showConfirmation = true"
        class="w-5 h-5 transition cursor-pointer hover:text-red-700"
        aria-hidden="true"
    />
        <!-- :class="activeForm?.id == subForm?.id ? 'text-primary-600' : 'text-gray-500'" -->

    <page-loader :show="loading"/>
    
    <confirmation-modal
        :typeTwo="true"
        :show="showConfirmation"
        @cancel="showConfirmation = false"
        @confirm="deleteForm()"
        cancel-text="Cancel"
        action-text="Confirm"
        :title="`Removal Warning of ${form.label}`"
        content="This field is connected to a sub-form. Removing this field will remove the child forms as well.?"
    />
    
    <success-modal
        :show="showSuccess"
        @confirm="showSuccess = false"
        action-text="Close"
        title="Success!"
        :content="successMessage"
    />

    <error-modal
        :show="showError"
        @confirm="showError = false"
        action-text="Close"
        title="Error!"
        :content="errorMessage"
    />

</template>


<script setup lang="ts">
import { ref, type PropType, onMounted, watch, computed } from "vue";

import { TrashIcon } from "@heroicons/vue/24/outline";

import ConfirmationModal from "@/components/modals/ConfirmationModal.vue";
import SuccessModal from "@/components/modals/SuccessModal.vue";
import ErrorModal from "@/components/modals/ErrorModal.vue";
import PageLoader from "@/components/loaders/PageLoader.vue"

import InterventionTypeService from "@/classes/Settings/InterventionTypeService.ts"

import { FormInterface } from "@/interfaces/pages/Settings/InterventionFormInterface.ts";

const props = defineProps({ 
    form: {
        type: Object as PropType<FormInterface>,
        required: true,
    },
});

const emit = defineEmits(['delete:success']);


const loading = ref<boolean>(false);
const showConfirmation = ref<boolean>(false);

const showSuccess = ref<boolean>(false);
const successMessage = ref<string>('');

const showError = ref<boolean>(false);
const errorMessage = ref<string>('');


const deleteForm = ():void => {
    loading.value = true;
    showConfirmation.value = false;
    
    InterventionTypeService
        .delete(props.form.id)
        .then(({data}) => {
            showSuccess.value = true
            successMessage.value = data.message
            
            emit('delete:success', data.data)
        })
        .catch((error: any) => {
            console.log(error);
            showError.value = true;
            errorMessage.value = error.response.data.message
        })
        .finally(() => loading.value = false)
}

</script>