const AggravatingFactors = {
	name: "aggravating_factors",
	schema: [
		{ column: "id", value: "INTEGER  PRIMARY KEY AUTOINCREMENT" },

		{ column: "case_conference_id", value: "INTEGER UNSIGNED" },
        { column: "aggravatingFactors", value: "LONGTEXT NOT NULL" },
        { column: "mitigatingFactors", value: "LONGTEXT NOT NULL" },

		{ column: "other_aggravating", value: "LONGTEXT NULL" },
		{ column: "other_mitigating", value: "LONGTEXT NULL" },

		{ column: "encoded_by", value: "INTEGER UNSIGNED" },

		{ column: "action", value: "INT UNSIGNED" },

		{ column: "is_sync", value: "BOOLEAN DEFAULT FALSE" },
        { column: "is_sync_failed", value: "BOOLEAN DEFAULT FALSE"},
        { column: "is_created_offline", value: "BOOLEAN DEFAULT FALSE" },
        { column: "is_updated_offline", value: "BOOLEAN DEFAULT FALSE" },
        { column: "is_deleted_offline", value: "BOOLEAN DEFAULT FALSE" },

        { column: "created_at", value: "TIMESTAMP NULL" },
		{ column: "updated_at", value: "TIMESTAMP NULL" },
		{ column: "deleted_at", value: "TIMESTAMP NULL" },

        { column: "synching_remarks", value: "TEXT DEFAULT NULL" },
	],
};

export default AggravatingFactors;
