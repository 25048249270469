<template>
	<user-layout>
		<div class="w-full py-6 mx-auto">
			<div class="flex items-center justify-between">
				<section-title title="Settings" description="View the relevant data and other details" />
				<a @click="router.replace('/settings/data-dictionary/create')">
					<button-component>
						<PlusIcon class="w-4 h-4 text-primary-600 stroke-[3px] -ml-2 mr-1 icon" />
						<span>Create</span>
					</button-component>
				</a>
			</div>
			<div class="mt-6">
				<div class="w-full border rounded-lg border-neutral-100">
					<div class="relative overflow-auto">
						<div class="absolute left-0 top-0 h-full whitespace-nowrap position w-[181px] shadow-[2px_0_16px_0px_rgb(0,0,0,0.08)]">
							<div class="px-6 py-[26px] bg-primary-100 text-sm font-semibold relative">Settings</div>
							<side-tab dataDictionarySelected />
						</div>
						<div class="pl-[181px] min-h-[500px]">
							<div class="flex items-center justify-between p-6 border-b border-neutral-100">
								<div class="flex items-center space-x-4">
									<tab-component :tabs="tabs" @update:tab="(value: string) => updateTab(value)" />
								</div>
							</div>
							<div v-if="route.query.tab != 'activity_logs'">
								<data-table
									:headers="headers"
									:no-action="false"
									:count="items?.length"
									@update:searchText="(value: string) => (filterSearch = value)"
									tableClass="header-frm-table">
									<template v-slot:body>
										<template v-for="item in items" :key="item">
											<tr>
												<td class="px-6 py-[18px] whitespace-nowrap text-sm text-gray-900">
													{{ item.name }}
												</td>
												<td
													class="px-6 py-6 max-w-[400px] text-ellipsis overflow-hidden line-clamp-1 h-5 text-sm text-gray-900">
													{{ item.content }}
												</td>
												<td class="px-6 py-[18px] whitespace-nowrap text-sm text-gray-900">
													{{ item.updated_at_formatted }}
												</td>
												<td class="px-6 py-[18px] whitespace-nowrap text-sm text-gray-900">
													<div class="flex items-center space-x-2 justify-center mx-auto">
														<template v-if="!tab">
															<view-button
																@click="
																	router.replace(
																		`/settings/data-dictionary/${item.id}/view`
																	)
																"></view-button>
															<delete-button @click="showDeleteModal(item)" />
														</template>
														<template v-if="tab === 'archived'">
															<restore-button
																@click="showRestoreModal(item)"
																v-if="tab === 'archived'" />
														</template>
													</div>
												</td>
											</tr>
										</template>
									</template>
								</data-table>
							</div>
							<ActivityLogsTable
								v-if="route.query.tab == 'activity_logs'"
								:items="activityLogs"
							/>
						</div>
					</div>
					<div class="w-full px-6 border-t py-7">
						<minify-pagination
							:numRows="numRows"
							@update:rows="(value: any) => changeRows(value)"
							@update:page="(value: number) => paginate(value)"
							:current-page="page"
							:last-page="lastPage"></minify-pagination>
					</div>

					<!-- DELETE MODAL -->
					<confirmation-modal
						:typeTwo="true"
						:show="showConfirmation"
						@cancel="showConfirmation = false"
						@confirm="deleteRecord()"
						cancel-text="Cancel"
						action-text="Confirm"
						title="Delete Record"
						content="Are you sure you wish to delete this record?" />
					<success-modal
						:show="showSuccess"
						@confirm="closeDeleteModal()"
						action-text="Close"
						title="Record Deleted!"
						content="Selected record has been successfully deleted!" />
					<!-- RESTORE MODAL -->
					<confirmation-modal
						:typeTwo="true"
						:show="showRestoreConfirmation"
						@cancel="showRestoreConfirmation = false"
						@confirm="restoreRecord()"
						cancel-text="Cancel"
						action-text="Confirm"
						title="Restore Record"
						content="Are you sure you wish to restore this record?" />
					<success-modal
						:show="showRestoreSuccess"
						@confirm="closeRestoreModal()"
						action-text="Close"
						title="Record Restored!"
						content="Selected record has been successfully restored!" />
				</div>
			</div>
		</div>
	</user-layout>
</template>
<script setup lang="ts">
import { ref, computed, watch, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import UserLayout from "@/layouts/UserLayout.vue";
import SectionTitle from "@/components/partials/SectionTitle.vue";
import ViewButton from "@/components/buttons/ViewButton.vue";
import DeleteButton from "@/components/buttons/DeleteButton.vue";
import RestoreButton from "@/components/buttons/RestoreButton.vue";
import DataTable from "../Components/DataTable.vue";
import SideTab from "../Components/SideTab.vue";
import MinifyPagination from "@/components/partials/MinifyPagination.vue";
import TabComponent from "@/components/partials/TabComponent.vue";
import ButtonComponent from "@/components/buttons/ButtonComponent.vue";
import { PlusIcon } from "@heroicons/vue/24/outline";
import throttle from "lodash/throttle";
import pickBy from "lodash/pickBy";
import DataDictionaryService from "@/classes/Settings/DataDictionaryService";
import ConfirmationModal from "@/components/modals/ConfirmationModal.vue";
import SuccessModal from "@/components/modals/SuccessModal.vue";
import ActivityLogsTable from "@/views/activity-logs/ActivityLogsTable.vue"


import { useNetworkStore } from "@/store/network";

const networkStore = useNetworkStore();
const isNetworkAvailable = computed(() => networkStore.isNetworkAvailable);

const route = useRoute();
const router = useRouter();

/**
 * VARIABLES
 */
const tab = ref<any>(route.query.tab);
const items = ref<any>(null);
const filterSearch = ref<any>(route.query.query);
const page = ref<any>(route.query.page);
const lastPage = ref<any>(null);
const rows = ref<any>(route.query.rows || 5);
const numRows = computed(() => (rows.value ? rows.value + " Rows" : "All"));
const showConfirmation = ref<boolean>(false);
const showSuccess = ref<boolean>(false);
const showRestoreConfirmation = ref<boolean>(false);
const showRestoreSuccess = ref<boolean>(false);
const dataDictionaryId = ref<number>(0);
const activityLogs = ref<any>({});

const tabs = ref<{ name: string; count: number; tab: string; href: string; allowed: boolean }[]>([
	{
		name: "All",
		count: 0,
		tab: "",
		href: "/settings/data-dictionary/index",
		allowed: true,
	},
	{
		name: "Archived",
		count: 0,
		tab: "archived",
		href: "/settings/data-dictionary/index?tab=archived",
		allowed: true,
	},
	{
		name: "Activity logs",
		count: 0,
		tab: "activity_logs",
		href: "/settings/data-dictionary/index?tab=activity_logs",
		allowed: true,
	},
]);

const headers: { text: string; haveSort?: boolean }[] = [
	{ text: "Name" },
	{ text: "Content" },
	{ text: "Date Updated" },
];

/**
 * METHODS
 */
const getData = (search: any = null): void => {
	DataDictionaryService.index(
		pickBy({
			query: search ?? filterSearch.value,
			page: page.value,
			rows: rows.value,
			tab: tab.value,
		}),
		true,
		isNetworkAvailable.value
	)
		.then((response: any) => {
			items.value = response.data.items.data;
			activityLogs.value = response.data.items;
			page.value = response.data.items.current_page;
			lastPage.value = response.data.items.last_page;
			tabs.value[1].count = response.data.archivedItemsCount;
			tabs.value[2].count = response.data.activityLogsCount;
		})
		.catch((error: any) => {
			console.error(error);
		});
};

const reloadPage = async () => {
	return await router.push({
		path: "/settings/data-dictionary/index",
		query: pickBy({
			query: filterSearch.value,
			page: page.value,
			rows: rows.value,
			tab: tab.value,
		}),
	});
};

const applyFilters = (search: any = null) => {
	reloadPage().then(() => {
		getData(search);
	});
};

const changeRows = (row: any): void => {
	rows.value = row;
	applyFilters();
};

const paginate = (data: number): void => {
	page.value = data;
	applyFilters();
};

const updateTab = (currentTab: string) => {
	tab.value = currentTab;
	page.value = 1;
	applyFilters();
};

/**
 * MODALS
 */
const showDeleteModal = (item: any): void => {
	showConfirmation.value = true;
	dataDictionaryId.value = item.id;
};

const closeDeleteModal = (): void => {
	showSuccess.value = false;
};

const deleteRecord = (): void => {
	DataDictionaryService.delete({
		dataDictionary: dataDictionaryId.value,
	})
		.then(() => {
			showSuccess.value = true;
			showConfirmation.value = false;
			applyFilters();
		})
		.catch((error) => console.log(error));
};

const showRestoreModal = (item: any): void => {
	showRestoreConfirmation.value = true;
	dataDictionaryId.value = item.id;
};

const closeRestoreModal = (): void => {
	showRestoreSuccess.value = false;
};

const restoreRecord = (): void => {
	DataDictionaryService.restore({
		dataDictionary: dataDictionaryId.value,
	})
		.then(() => {
			showRestoreSuccess.value = true;
			showRestoreConfirmation.value = false;
			applyFilters();
		})
		.catch((error) => console.log(error));
};

/**
 * WATCHERS
 */
watch(
	filterSearch,
	throttle((val: string) => {
		applyFilters(val);
	}, 1000)
);

onMounted(() => {
	getData();
});
</script>
