import DB from "@/classes/Databases/Crud"
import Relationships from "../../Relationships";
import FormOptions from "../../FormOptions";
import { OfflineAction } from "@/views/enums/OfflineAction";

const db = new DB("case_conferences");
const dbCases = new DB('cases');
const dbPatient = new DB('patients');
const dbDropdowns = new DB('dropdown_options');
const dbIntervention = new DB('intervention_data');
const relationship = new Relationships();
const options = new FormOptions();
class OfflineSyncCaseConference {
    async syncToOffline(payload: any, caseId: any): Promise<any>{
        await db.insert(
            this.contentFormat(payload, caseId),
            payload.id,
            true,
            'case_conferences'
        );
    }

    async save(payload: any, patientId: any, caseId: any) {
        if (payload.id) {
            await this.update(payload, caseId);
        } else {
            await this.create(payload, caseId);
        }
    }

    async create(payload: any, caseId: any) {
        await db.delete({cases_id: caseId}, 'case_conferences')
        await db.insert({
            cases_id: caseId,
            content: JSON.stringify(payload),
            encoded_by: 0,
            action: OfflineAction.NEW_RECORD,
            is_sync: false,
            created_at: relationship.dateFormatFromIso(new Date()),
            updated_at: relationship.dateFormatFromIso(new Date()),
            deleted_at: "",
            is_created_offline: true,
        }, null, false, 'case_conferences');
    }

    async view(caseId: any) {
        const response = await db.select(`SELECT * FROM case_conferences WHERE cases_id=${caseId} AND (deleted_at = "" OR deleted_at IS NULL) LIMIT 1`);
        let item = null;
        if (response.length > 0) {
            const content = JSON.parse(response[0].content);
            const cases = await dbCases.edit(caseId);
            if (cases.data.item.length === undefined) {
                item = content;
                item.id = response[0].id;
            }            
        }
        
        return await this.transformContent(item, caseId);
    }

    async renderCaseConferences(caseId: any) {
        const interventionFieldResponse = await dbDropdowns.select('SELECT * FROM dropdown_options WHERE option="interventionCaseConferences"');
        let getConferenceCode = [];
        if (interventionFieldResponse.length > 0) {
            getConferenceCode = JSON.parse(interventionFieldResponse[0].content);
        }

        const interventions = await dbIntervention.select(`SELECT * FROM intervention_data WHERE cases_id=${caseId} AND type_id IN (${getConferenceCode})`);
        if (interventions.length > 0) {
            const arr = [];
            for (const index in interventions) {
                const item = interventions[index];
                const label = 'Case Conference Date:';
                switch (item.status) {
                    case 'Done':
                        arr.push(`${label} ${relationship.dateFormat(item.schedule_date, 'MM/dd/yyyy hh:mm')} met with ${item.done_by}`);
                        break;
                    case 'Scheduled':
                        arr.push(`${label} ${relationship.dateFormat(item.schedule_date, 'MM/dd/yyyy hh:mm')}`);
                        break;
                    default:
                        arr.push(`${label} ${relationship.dateFormat(item.schedule_date, 'MM/dd/yyyy hh:mm')} met with ${item.done_by}`);
                        break;
                }
            }

            return arr;
        }

        return [];
    }

    async transformContent(content: any, caseId: any) {
        if (content) {
            const socialWorker = await relationship.social_worker(content?.social_worker_id);
            const invitees = await relationship.wcpuPersonnel(content?.invitees);
            content.patient_name = await this.renderPatientName(caseId, true);
            content.date_time_formatted = relationship.dateFormat(content.date_time, 'DDD hh:mm a');
            content.social_worker = socialWorker?.label;
            content.invitees_formatted = invitees;
        }

        return content;
    }

    async update(payload: any, caseId: any) {
        const response = await db.select(`SELECT * FROM case_conferences WHERE cases_id=${caseId} LIMIT 1`);
        if (response.length > 0 ) {
            const item = response[0];
            await db.delete({id: item.id}, 'case_conferences')
            await db.insert({
                id: payload.id,
                cases_id: caseId,
                content: JSON.stringify(await this.transformContent(payload, caseId)),
                encoded_by: 0,
                action: OfflineAction.UPDATE_RECORD,
                is_sync: false,
                created_at: item.created_at,
                updated_at: relationship.dateFormatFromIso(new Date()),
                deleted_at: "",
                is_created_offline: item.is_created_offline,
                is_updated_offline: item.is_created_offline ? false : true,
            }, null, false, 'case_conferences');
        }
    }

    contentFormat(payload: any, caseId: any) {
        return {
            id: payload.id,
            cases_id: caseId,
            content: JSON.stringify(payload),
            encoded_by: 0,
            action: OfflineAction.DEFAULT,
            is_sync: false,
            created_at: "",
            updated_at: "",
            deleted_at: "",
        }
    }

    async renderPatientName(caseId: any, withoutBirthday = false) {
        const response = await dbCases.edit(caseId, false, 'cases');
        if (response.data.item.length === undefined) {
            const patient = await dbPatient.edit(response.data.item.patient_id, false, 'patients')
            if (patient.data.item) {
                const item = JSON.parse(patient.data.item.content);

                return withoutBirthday ? `${item.first_name} ${item.last_name}` : `${item.first_name} ${item.last_name} ${relationship.dateFormat(item.birthdate, 'MM-dd-yyyy')}`;
            }
        }

        return null;
    }
}

export default new OfflineSyncCaseConference()