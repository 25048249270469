import http from "@/axios";
import { Guardian } from "@/interfaces/Cases/Guardian";
import CaseGuardianOfflineForm from "../Forms/Cases/CaseGuardianOfflineForm";
import DB from "@/classes/Databases/Crud";
import OfflineCaseGuardian from "../Forms/Cases/OfflineCaseGuardian";

const db = new DB("case_guardians");
const dbCase = new DB("cases");
const dbDraft = new DB("drafts");

class GuardianService implements Guardian {
	async index(patient: number, patientCase: number, payload: any, networkStatus = true): Promise<any> {
		if (networkStatus) {
			return await http.get(`patients/${patient}/cases/${patientCase}/guardians`, { params: payload }).catch(error => error);
		}

		return await db.index(payload, ` WHERE cases_id=${patientCase}`, true, 'case_guardians');
	}

	async create(patient: number, patientCase: number, payload: object, networkStatus = true): Promise<any> {
		if (networkStatus) {
			return await http.get(`patients/${patient}/cases/${patientCase}/guardians/create`, { params: payload }).catch(error => error);
		}
	}

	async store(patient: number, patientCase: number, payload: any, networkStatus = true): Promise<any> {
		if (networkStatus) {
			return await http.post(`patients/${patient}/cases/${patientCase}/guardians`, payload).catch(error => error);
		}

        return await OfflineCaseGuardian.createCaseGuardian(patient, patientCase, payload);
	}

	async edit(
		patient: number,
		patientCase: number,
		guardian: number,
		payload: any,
		networkStatus = true
	): Promise<any> {
		if (networkStatus) {
			return await http.get(`patients/${patient}/cases/${patientCase}/guardians/${guardian}/edit`, {
				params: payload,
			}).catch(error => error);
		}

		return await db.edit(guardian, false, 'case_guardians');
	}

	async update(
		patient: number,
		patientCase: number,
		guardian: number,
		payload: any,
		networkStatus = true
	): Promise<any> {
		if (networkStatus) {
			return await http.put(`patients/${patient}/cases/${patientCase}/guardians/${guardian}/update`, payload).catch(error => error);
		}

		return await OfflineCaseGuardian.updateCaseGuardian(patient, patientCase, guardian, payload);
	}

	async delete(
		patient: number,
		patientCase: number,
		guardian: number,
		payload: any,
		networkStatus = true
	): Promise<any> {
		if (networkStatus) {
			return await http.delete(`patients/${patient}/cases/${patientCase}/guardians/${guardian}/delete`, payload).catch(error => error);
		}

		return await OfflineCaseGuardian.deleteCaseGuardian(patient, patientCase, guardian, payload);
	}
}

export default new GuardianService();
