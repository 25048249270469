<template>
	<div class="grid grid-cols-12 gap-6">
		<div class="col-span-12">
			<p class="text-xs font-semibold text-neutral-600">Address Type</p>

			<div class="flex mt-5 space-x-3">
				<radio-group 
					:options="optionAdressType.data" 
					name="option_selected"
					v-model="formData.address_type" 
				/>
			</div>
		</div>
		
		<div class="col-span-12">
			<text-input
				label="Permanent Address"
				placeholder="Permanent Address"
				name="permanent_address"
				id="permanent_address"
				v-model="formData.permanent_address"
			/>
		</div>

		<div class="col-span-6">
			<multi-select searchable
				:options="optionRegion.data"
				label="Region"
				id="region"
				name="region"
				placeholder="Select.."
				v-model="formData.region" 
			/>
		</div>

		<div class="col-span-6">
			<multi-select searchable
				:options="optionProvince.data"
				label="Province"
				id="province"
				name="province"
				placeholder="Select.."
				v-model="formData.province" 
			/>
		</div>

		<div class="col-span-6">
			<multi-select searchable
				:options="optionCityMunicipality.data"
				label="City/Municipality/District"
				id="city_municipality"
				name="city_municipality"
				placeholder="Select.."
				v-model="formData.city_municipality" 
			/>
		</div>

		<div class="col-span-6">
			<multi-select searchable
				:options="optionBarangay.data"
				label="Barangay"
				id="barangay"
				name="barangay"
				placeholder="Select.."
				v-model="formData.barangay" 
			/>
		</div>

		<div class="col-span-12">
			<text-input
				label="Direction"
				placeholder="Direction"
				name="direction"
				id="direction"
				v-model="formData.directions"
			/>
		</div>

		<div class="col-span-6">
			<text-input
				type="number"
				label="Present Telephone"
				placeholder="Present Telephone"
				name="present_telephone"
				id="present_telephone"
				v-model="formData.present_telephone"
			/>
		</div>
	</div>
</template>

<script setup lang="ts">
import RadioGroup from "@/components/inputs/RadioGroup.vue";
import TextInput from "@/components/inputs/TextInput.vue";
import MultiSelect from "@/components/inputs/MultiSelect.vue";
import { ref } from "vue";

const props = defineProps({
	form: {
		type: Object
	},
	optionAdressType: {
		type: Object
	},
	optionRegion: {
		type: Object
	},
	optionProvince: {
		type: Object
	},
	optionCityMunicipality: {
		type: Object
	},
	optionBarangay: {
		type: Object
	}
})

const formData = ref({ ...props.form })

</script>