import http from "@/axios";
import UrlQueryBuilder from "../../helpers/UrlQueryBuilder"

class ReportService {
    async index(type: string, query: any): Promise<any> {
		return await http.get(`/reports/${type}`, {
			params: query
		});
	}


    async export(type: string, query: any): Promise<any> { 
		return await http.get(`/reports/${type}/export`, {
			params: query
		});
	}	

	async downloadExport(filename: string, data: any){
		filename = decodeURI(filename);
		const url = window.URL.createObjectURL(new Blob([data]));
		const link = document.createElement('a');
		link.href = url;
		link.setAttribute('download', filename);
		document.body.appendChild(link);
		link.click();
		window.URL.revokeObjectURL(url);
		link.remove();
	}	 
	
	paginate (page:number,rows:number, filters:object, getData:any){
		filters = Object.assign(filters, {
			page,rows
		}); 
		getData();
	}
}

export default new ReportService();