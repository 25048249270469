<template>
    <user-layout
        hasBack
        backLink="/settings/form-options/index"
    >
        <template #lowerRight>
            <a 
                @click="router.replace(`/settings/form-options/view/${option}/create`)"
            >
                <button-component

                >
                    <PlusIcon class="w-4 h-4 text-primary-600 stroke-[3px] -ml-2 mr-1 icon"/>
                    <span>Create</span>
                </button-component>
            </a>
        </template>
        <div class="my-6 border rounded-lg border-neutral-100" v-if="!loading">
            <div class="flex items-center justify-between p-6 border-b tabs border-neutral-100">
                <div class="flex items-center space-x-4">
                    <tab-component
                        :tabs="tabs"
                        @update:tab="(value: string) => updateTab(value)" 
                    />
                </div>
            </div>

            <div v-if="filters.tab != 'activity_logs'">
                <DataTable 
                    :headers="headers" 
                    :no-action="false"
                    :count="items.total"
                    :hasSearch="true"
                    :search="filters.query"
                    searchPlaceholder="Search Options" 
                    tableClass="header-frm-table"
                    dataTableClass="formOption border-0"
                    @update:searchText="(value: string) => (filters.query = value)"
                >
                    <template v-slot:body>
                        <template 
                            v-for="item in items.data" 
                            :key="item"
                        >
                            <tr>
                                <td class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
                                    {{ item.id }}
                                </td>
                                <td class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
                                    {{ item.name }}
                                </td>
                                <td class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
                                    {{ item.created_at_formatted }}
                                </td>
                                <td 
                                    class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap"
                                >
                                    <template v-if="!filters.tab">     
                                        <div 
                                            class="flex items-center justify-center mx-auto"
                                        >
                                            <edit-button
                                                @click="router.replace(`/settings/form-options/view/${option}/edit/${item.id}`)"
                                            />
                                            <delete-button 
                                                @click="showDeleteModal(item)" 
                                            />
                                        </div>
                                    </template>

                                    <template v-if="filters.tab == 'archived'">
                                        <restore-button 
                                            @click="showRestoreModal(item)" 
                                        />
                                    </template>
                                </td>
                            </tr>
                        </template>
                    </template>
                </DataTable>
            </div>
            <ActivityLogsTable
                v-if="filters.tab == 'activity_logs'"
                :items="items"
            />

            <div class="w-full px-6 border-t py-7">
                <simple-pagination 
                    :numRows="filters.rows" 
                    :currentPage="items.current_page" 
                    :last-page="items.last_page" 
                    @update="(value: any) => paginate(value)"
                />
            </div>
        </div>

        <!-- DELETE MODAL -->
        <confirmation-modal :typeTwo="true" :show="showConfirmation" @cancel="showConfirmation = false"
            @confirm="deleteRecord()" cancel-text="Cancel" action-text="Confirm" title="Delete Record"
            content="Are you sure you wish to delete this option?" />
        <success-modal :show="showSuccess" @confirm="closeDeleteModal()" action-text="Close"
            title="Record Deleted!" content="Selected option has been successfully deleted!" />
        <!-- RESTORE MODAL -->
        <confirmation-modal :typeTwo="true" :show="showRestoreConfirmation"
            @cancel="showRestoreConfirmation = false" @confirm="restoreRecord()" cancel-text="Cancel"
            action-text="Confirm" title="Restore Record"
            content="Are you sure you wish to restore this option?" />
        <success-modal :show="showRestoreSuccess" @confirm="closeRestoreModal()" action-text="Close"
            title="Record Restored!" content="Selected option has been successfully option!" />
    </user-layout>
</template>
<script setup lang="ts">
import { ref, onMounted, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import UserLayout from '@/layouts/UserLayout.vue';
import EditButton from '@/components/buttons/EditButton.vue';
import DeleteButton from '@/components/buttons/DeleteButton.vue';
import DataTable from '@/components/partials/DataTable.vue';
import ConfirmationModal from "@/components/modals/ConfirmationModal.vue";
import SuccessModal from "@/components/modals/SuccessModal.vue";
// import MinifyPagination from '@/components/partials/MinifyPagination.vue';
import SimplePagination from "@/components/partials/SimplePagination.vue";
import ButtonComponent from '@/components/buttons/ButtonComponent.vue';
import TabComponent from '@/components/partials/TabComponent.vue';
import { PlusIcon } from '@heroicons/vue/24/outline';
import RestoreButton from "@/components/buttons/RestoreButton.vue";
import { BasePageInterface } from "@/interfaces/pages/BasePageInterface";
import debounce from "lodash/debounce";
import pickBy from "lodash/pickBy";
import ActivityLogsTable from "@/views/activity-logs/ActivityLogsTable.vue"

import { filters, items } from "@/interfaces/pages/FilterPageInterface";

import FormOptions from '@/classes/Settings/FormOptions'

import assignValues from "@/helpers/assignValues"

const router = useRouter();
const route = useRoute();

const showConfirmation = ref<boolean>(false);
const showSuccess = ref<boolean>(false);
const showRestoreConfirmation = ref<boolean>(false);
const showRestoreSuccess = ref<boolean>(false);
const showExportSuccess = ref<boolean>(false);

const archived_count = ref(15);
const loading = ref<boolean>(false);
const showFilter = ref<boolean>(false);
const option: string = route.params.option;

const selectedId = ref<number | null>(null);

const tabs = ref();

const headers: { text: string, haveSort?: boolean }[] = [
    { text: '' },
    { text: 'Options' },
    { text: 'Date Created', haveSort: true },
];

const init = (): void => {
    loading.value = true;

    FormOptions.show(option, pickBy(filters.value))
        .then(({ data }) => {

            items.value = data.items;
            filters.value = assignValues(filters.value, data);
            loading.value = false;   

            tabs.value = [
                {
                    name: "All",
                    count: filters.value.allItemsCount,
                    tab: null,
                    allowed: true,
                },
                {
                    name: 'Archived',
                    count: filters.value.archivedItemsCount,
                    tab: "archived",
                    allowed: true
                },
            ] 
        })
}

const paginate = ({ page, rows }): void => {
    filters.value = Object.assign(filters.value, {
        page, rows
    })
    init();
};

const deleteRecord = (): void => {
    FormOptions.delete(option, selectedId.value)
        .then(() => {
            showSuccess.value = true;
            showConfirmation.value = false;
            applyFilters();
        })
        .catch((error) => console.log(error));
};

const restoreRecord = (): void => {
    FormOptions.restore(option, selectedId.value)
        .then(() => {
            showRestoreSuccess.value = true;
            showRestoreConfirmation.value = false;
            applyFilters();
        })
        .catch((error) => console.log(error));
};

const closeRestoreModal = (): void => {
    showRestoreSuccess.value = false;
};

const showRestoreModal = (item: any): void => {
    showRestoreConfirmation.value = true;
    selectedId.value = item.id;
};

const showDeleteModal = (item: any): void => {
    showConfirmation.value = true;
    selectedId.value = item.id;
};

const closeDeleteModal = (): void => {
    showSuccess.value = false;
};

const applyFilters = (search: any = null) => {
    showFilter.value = false;
    filters.value.query = search;
    init();
};

const updateTab = (currentTab: string) => {
    filters.value.tab = currentTab;
    filters.value.page = 1;
    applyFilters();
};

/**
 * WATCHERS
 */
watch(
    () => filters.value.query,
    debounce(() => {
        init();
    }, 1000)
);

onMounted(() => {
    
    init()
})


</script>