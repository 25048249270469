import http from "@/axios";

class ForgotPassword {

	async instantiate(payload: object): Promise<any> {
		return await http.post("forgot-password/instantiate", payload);
    }
    
	async verifyOtp(payload: object): Promise<any> {
		return await http.post("forgot-password/verifyOtp", payload);
	}
    
	async setPassword(payload: object): Promise<any> {
		return await http.post("forgot-password/setPassword", payload);
	}
}
export default new ForgotPassword();
