<template>
    <div :class="disabled ? 'cursor-not-allowed' : 'cursor-pointer'">
        <div
            class="flex items-center rounded transition"
            :class="[
                noPadding ? 'justify-start !py-0 !px-0 w-min' : 'justify-between py-5 px-6 border',
                error ? 'border-red-500 focus:border-red-500 focus:ring-red-500' : 'border-gray-100 focus:border-primary-600 focus:ring-primary-600',
                disabled ? 'pointer-events-none bg-gray-50' : '',
            ]"
            @click="$emit('change', !value)"
        >
            <div
                v-if="label || description"
                class="select-none pr-4"
            >
                <h5 class="font-bold text-sm leading-6">{{ label }}</h5>
                <p class="mt-1 text-sm psi-input-text">
                    {{ description }}
                </p>
            </div>
            <div class="flex items-center">
                <Switch
                    v-model="switchValue"
                    class="relative inline-flex h-6 w-11 items-center rounded-full transition pointer-events-none"
                    :class="[
                        switchValue ? 'bg-primary-600' : 'bg-gray-300',
                        disabled ? 'text-gray-200' : '',
                    ]"
                >
                    <span class="sr-only">{{ label }}</span>
                    <span
                        :class="switchValue ? 'translate-x-6' : 'translate-x-1'"
                        class="inline-block h-4 w-4 transform rounded-full bg-white transition"
                    />
                </Switch>
                <slot />
            </div>
        </div>
        <p v-if="error" class="text-sm text-red-500 mt-2">
            {{ error }}
        </p>
    </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { Switch } from "@headlessui/vue";

const props = defineProps({
    value: {
        default: null,
        required: true,
    },
    label: {
        type: String,
        default: null,
    },
    description: {
        type: String,
        default: null,
    },
    noPadding: {
        type: Boolean,
        default: false
    },
    error: {
        type: String,
        default: null,
    },
    disabled: {
        type: Boolean,
        default: false,
    },
})

const emit = defineEmits(['change'])
const switchValue = computed ({
    get() {
        return props.value;
    },
    set(value) {
        emit('change', value);
    }
});
</script>
