import http from "@/axios";
import { ContactNumber } from "@/interfaces/Cases/IntakeForm/ContactNumber";

class ContactNumberService implements ContactNumber {
	async index(patient: number, patientCase: number, payload: any, networkStatus = true): Promise<any> {
		if (networkStatus) {
			return await http.get(`patients/${patient}/cases/${patientCase}/intakeForm/contactNumber`, { params: payload });
		}
	}

	async store(patient: number, patientCase: number, payload: any, networkStatus = true): Promise<any> {
		if (networkStatus) {
			return await http.post(`patients/${patient}/cases/${patientCase}/intakeForm/contactNumber`, payload);
		}
	}

	async update(
		patient: number,
		patientCase: number,
		patientContactNumber: number,
		payload: any,
		networkStatus = true
	): Promise<any> {
		if (networkStatus) {
			return await http.put(
				`patients/${patient}/cases/${patientCase}/intakeForm/contactNumber/${patientContactNumber}/update`,
				payload
			);
		}
	}

	async delete(
		patient: number,
		patientCase: number,
		patientContactNumber: number,
		payload: any,
	): Promise<any> {
        return await http.delete(
            `patients/${patient}/cases/${patientCase}/intakeForm/contactNumber/${patientContactNumber}/delete`,
            payload
        );
	}
}

export default new ContactNumberService();
