const CaseIntakes = {
	name: "case_intakes",
	schema: [
		{ column: "id", value: "INTEGER  PRIMARY KEY AUTOINCREMENT" },
		{ column: "offline_id", value: "INTEGER UNSIGNED" },
		{ column: "cases_id", value: "INTEGER UNSIGNED" },
        { column: "offline_cases_id", value: "INTEGER UNSIGNED" },
        { column: "content", value: "TEXT NOT NULL" },
		{ column: "encoded_by", value: "INTEGER UNSIGNED" },
		{ column: "action", value: "INT UNSIGNED" },
		{ column: "is_sync", value: "BOOLEAN DEFAULT FALSE" },
        { column: "is_created_offline", value: "BOOLEAN DEFAULT FALSE" },
        { column: "is_updated_offline", value: "BOOLEAN DEFAULT FALSE" },
        { column: "is_deleted_offline", value: "BOOLEAN DEFAULT FALSE" },
        { column: "created_at", value: "TIMESTAMP" },
		{ column: "updated_at", value: "TIMESTAMP" },
		{ column: "deleted_at", value: "TIMESTAMP" },
	],
};

export default CaseIntakes;
