const Provinces = {
    name: 'provinces',
    schema: [
        { column: 'id', value: 'INTEGER UNSIGNED NOT NULL' },
        { column: 'region_id', value: 'INTEGER UNSIGNED NOT NULL' },
        { column: 'name', value: 'VARCHAR(255) NOT NULL' },
    ]
}

export default Provinces
