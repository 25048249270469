<template>
	<user-layout
		hasBack
		:backLink="`/patient/${patientId}/cases/${caseId}/edit${offlineParams ? '?offline=true' : ''}`"
		hasBackClass="flex md:justify-between md:items-center md:flex-row flex-col">
		<template #lowerRight>
			<div class="flex flex-col items-center space-y-3 md:flex-row md:space-x-3 md:space-y-0">
				<a
					@click="
						router.replace(
							`/patient/${patientId}/cases/${caseId}/edit${offlineParams ? '?offline=true' : ''}`
						)
					"
					class="w-full mt-6 md:w-auto md:mt-0">
					<button-component btnWidth="quinary" customClass="quinary md:w-auto w-full">
						Cancel
					</button-component>
				</a>
				<button-component
					customClass="primary md:w-auto w-full md:mt-0 mt-6"
					form="form" 
					@click="save()"
				>
					<span>Save Changes</span>
				</button-component>
			</div>
		</template>
		<p class="mt-6 text-lg font-medium md:text-2xl text-neutral-600">Patient's Name:
			<span class="block font-semibold md:inline-block text-neutral-900">{{ patient }}</span>
		</p>
		<div class="w-full p-4 mx-auto my-6 border border-gray-200 rounded lg:p-6">
			<div class="flex items-center justify-between">
				<section-title title="Perpetrator Details" />
			</div>
			<form-section
				v-if="loaded"
				:form="form"
				:errors="errors"
				:genders="genders"
				:relationships="relationships"
				:nationalities="nationalities"
				:religions="religions"
				:currentLocations="currentLocations"
				:identities="identities"
				:item="item" 
				:isEdit="true"
			/>
		</div>
		<success-modal
			:show="showSuccess"
			@confirm="redirectSuccess()"
			action-text="Close"
			title="Record Save!"
			:content="successMessage" />
	</user-layout>
</template>
<script setup lang="ts">
import { onMounted, ref, computed, watch } from "vue";
import UserLayout from "@/layouts/UserLayout.vue";
import SectionTitle from "@/components/partials/SectionTitle.vue";
import ButtonComponent from "@/components/buttons/ButtonComponent.vue";
import SuccessModal from "@/components/modals/SuccessModal.vue";
import FormSection from "./FormSection.vue";
import { useRouter, useRoute } from "vue-router";
import PerpetratorService from "@/classes/Cases/PerpetratorService";
import { renderSelect, renderSelectV2 } from "@/interfaces/DropdownInterface";
import { PerpetratorCreate } from "@/interfaces/Cases/Perpetrator";
import PerpetratorForm from "@/classes/Forms/Cases/PerpetratorForm";
import { errorResponse } from "@/helpers/errorMessage";
import { useNetworkStore } from "@/store/network";
import DB from "@/classes/Databases/Crud";
import pickBy from "lodash/pickBy";
import FormOptions from "@/classes/Forms/FormOptions";
import Gender from "@/enums/Gender";
import PerpetratorIdentity from "@/enums/PerpetratorIdentity";
import CurrentLocation from "@/enums/CurrentLocation";
import { useRecordStatusChecker } from "@/store/recordStatusChecker";
import Relationships from "@/classes/Forms/Relationships";
import CasePerpetratorOfflineForm from "@/classes/Forms/Cases/CasePerpetratorOfflineForm";
import { onIonViewWillEnter } from "@ionic/vue";
import OfflineCasePerpetrator from "@/classes/Forms/Cases/OfflineCasePerpetrator";

const networkStore = useNetworkStore();
const isNetworkAvailable = computed(() => networkStore.isNetworkAvailable);
const db = new DB("perpetrators");
const dbLinkPerpetrator = new DB("case_perpetrators");
const dbCase = new DB("cases");
const dbPatient = new DB("patients");
const router = useRouter();
const route = useRoute();
const showSuccess = ref<boolean>(false);
const patientId = ref<number | null>(route.params.patient);
const caseId = ref<number | null>(route.params.case);
const perpetratorId = ref<number | null>(route.params.perpetrator);
const genders = ref<renderSelectV2[]>([]);
const identities = ref<renderSelectV2[]>([]);
const nationalities = ref<renderSelect[]>([]);
const religions = ref<renderSelect[]>([]);
const relationships = ref<renderSelect[]>([]);
const currentLocations = ref<renderSelect[]>([]);
const form = ref<PerpetratorCreate>(new PerpetratorForm());
const errors = ref<object>({});
const successMessage = ref<string>("Case perpetrator has beed updated!");
const loaded = ref<boolean>(false);
const item = ref<PerpetratorCreate>(new PerpetratorForm());
const offlineParams = ref<string>(route.query.offline);
const options = new FormOptions();
const patient = ref<string>('');
const recordStatusChecker = useRecordStatusChecker();
const relationship = new Relationships()

const save = async (): Promise<any> => {
	errors.value = {};
	if (!isNetworkAvailable.value) {
		const validate = validateSaving(form.value);
		errors.value = validate?.errors;

		if (validate.hasOfflineError) {
			showSuccess.value = false;
			return;
		}
	}
    
    const response = await  PerpetratorService.update(
		patientId.value,
		caseId.value,
		perpetratorId.value,
		{ ...form.value },
		isNetworkAvailable.value
	);

    if (response?.status === 200 || response?.data != undefined) {
        await saveToOffline(response);
        showSuccess.value = true;
        successMessage.value = response.data.message;
    } else {
        const error = response;
        errors.value = errorResponse(error.response?.data?.errors);
    }
};

const validateSaving = (data) => {
    const errors = {};
    let hasOfflineError = false;

    if (!data.perpetrator_relationship_id) {
        hasOfflineError = true;
        Object.assign(errors, { perpetrator_relationship_id: "The relationship field is required." });
    }

    if (data.gender == undefined) {
        hasOfflineError = true;
        Object.assign(errors, { gender: "The gender field is required." });
    }

    if (!data.birthdate && !data.approximate_age_month) {
		hasOfflineError = true;
		Object.assign(errors, { approximate_age_month: "The approximate age month field is required when birthdate is not present." });
	}

	if (!data.birthdate && !data.approximate_age_year) {
		hasOfflineError = true;
		Object.assign(errors, { approximate_age_year: "The approximate age year field is required when birthdate is not present." });
	}

	if (!data.approximate_age_month && !data.birthdate) {
		hasOfflineError = true;
		Object.assign(errors, { birthdate: "The birthdate field is required when approximate age month is not present." });
	}

	if (!data.approximate_age_year && !data.birthdate) {
		hasOfflineError = true;
		Object.assign(errors, { birthdate: "The birthdate field is required when approximate age year is not present." });
	}

    if (data.identity != 1) {
        if (!data.first_name) {
            hasOfflineError = true;
            Object.assign(errors, { first_name: "The first name field is required." });
        }

        if (!data.last_name) {
            hasOfflineError = true;
            Object.assign(errors, { last_name: "The last name field is required." });
        }
    }

    if (data.contacts && data.contacts.length < 10) {
        hasOfflineError = true;
        Object.assign(errors, { contacts: "The contacts field must have at least 10 digits." });
    }

    if (data.identity == 1) {
        if (!data.tag) {
            hasOfflineError = true;
            Object.assign(errors, { tag: "The tag field is required." });
        }
    }

    return {
        hasOfflineError: hasOfflineError,
        errors: errors,
    };
}

const redirectSuccess = async (): void => {
	showSuccess.value = false;
	const checkFromOnlineRecord = await dbCase.edit(caseId.value, false, 'cases');
	if (checkFromOnlineRecord.data.item.length != undefined || !checkFromOnlineRecord.data.item.patient_id) {
		const checkFromOfflineRecord = await dbCase.edit(caseId.value, false, 'cases');
		router.replace(
			`/patient/${patientId.value}/cases/${caseId.value}/edit${
				checkFromOfflineRecord.data.item.patient_id ? "" : "?offline=true"
			}`
		);
	} else {
		router.replace(`/patient/${patientId.value}/cases/${caseId.value}/edit`);
	}
};

const getData = async () => {
    const response = await PerpetratorService.edit(
		patientId.value,
		caseId.value,
		perpetratorId.value,
		pickBy({}),
		isNetworkAvailable.value
	);

    if (response?.status === 200 || response?.data != undefined) {
        if (isNetworkAvailable.value) {
            genders.value = response.data.genders;
            identities.value = response.data.identities;
            nationalities.value = response.data.nationalities;
            religions.value = response.data.religions;
            relationships.value = response.data.perpetratorRelationships;
            currentLocations.value = response.data.currentLocations;
            form.value.updatePerpetrator(response.data.item);
			patient.value = response.data.patient;
            item.value = response.data.item;
        } else {
            genders.value = Gender;
            identities.value = PerpetratorIdentity;
            nationalities.value = await options.nationalities();
            religions.value = await options.religions();
            relationships.value = await options.perpetratorRelationships();
            currentLocations.value = CurrentLocation;

            const data = JSON.parse(response.data.item.content);
            form.value.updatePerpetrator(data);
            item.value = data;
        }

        loaded.value = true;
    } else {
        console.log(response);
    }
};

const saveToOffline = async (response: any) => {
    if (!isNetworkAvailable.value) {
        return;
    }
    await OfflineCasePerpetrator.onlinePerpetratorSaveToOffline(response, caseId.value);
}

const renderPatient = async (): Promise<any> => {
    if (!isNetworkAvailable.value) {
        const response = await dbPatient.edit(patientId.value, false, 'patients');
        const item = response.data.item;
        if (item.length === undefined) {
            patient.value = `${relationship.capitalFirstLetter(item.last_name)}, ${relationship.capitalFirstLetter(item.first_name)}`;
        }
    }

}

// watch(
// 	() => isNetworkAvailable.value,
// 	(val) => {
// 		console.log("Watcher - Network: " + val);
// 		getData();
// 	}
// );

onIonViewWillEnter(async () => {
    recordStatusChecker.isCreatedGuardian = false;
    await renderPatient();
    await getData();    
});

// onMounted(async () => {
//     recordStatusChecker.isCreatedGuardian = false;
//     await renderPatient();
//     await getData();
// });
</script>
