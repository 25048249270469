<template>
	<user-layout hasBack backLink="/personnel/index">
		<div class="max-w-[500px] mx-auto py-6">
			<form-section
				v-if="loaded"
				:form="form"
				:errors="errors"
				:departments="departmentOptions"
				:positions="positionOptions"
				:userGroups="userGroupOptions"
				:offices="officeOptions"
				:canChangeRole="canChangeRole"
			>
				<div class="col-span-2">
					<div class="flex items-center justify-end w-full space-x-3">
						<a @click="router.replace('/personnel/index/')">
							<button-component btnWidth="quinary">Cancel</button-component>
						</a>

						<button-component btnWidth="primary" @click="save()">Save Changes</button-component>
					</div>
				</div>
				<confirmation-modal
					:typeTwo="true"
					:show="showConfirmation"
					@cancel="showConfirmation = false"
					@confirm="
						showSuccess = true;
						showConfirmation = false;
					"
					cancel-text="Cancel"
					action-text="Confirm"
					title="Update Record"
					content="Are you sure you wish to update this record?" />
				<success-modal
					:show="showSuccess"
					@confirm="redirectSuccess()"
					action-text="Close"
					title="Record Updated!"
					:content="successMessage" />
				<page-loader :show="loading"/>
			</form-section>
		</div>
	</user-layout>
</template>
<script setup lang="ts">
import { onMounted, ref } from "vue";
import UserLayout from "@/layouts/UserLayout.vue";
import FormSection from "./FormSection.vue";
import ButtonComponent from "@/components/buttons/ButtonComponent.vue";
import ConfirmationModal from "@/components/modals/ConfirmationModal.vue";
import SuccessModal from "@/components/modals/SuccessModal.vue";
import { useRoute, useRouter } from "vue-router";
import Personnels from "@/classes/Personnels";
import { errorResponse } from "@/helpers/errorMessage";

import assignValues from "@/helpers/assignValues"
import PageLoader from "@/components/loaders/PageLoader.vue"

import { SelectInterface } from "@/interfaces/pages/BasePageInterface"

const route = useRoute();
const router = useRouter();

const showConfirmation = ref<boolean>(false);
const showSuccess = ref<boolean>(false);
const successMessage = ref<string>("Personel has been successfully updated!");

const form = ref<{
	email: string;
	last_name: string;
	first_name: string;
	middle_name: string;
	mobile_number: string;
	home_address: string;
	is_temporary: boolean;
	department_id: any;
	office_id: any;
	role_id: any;
	position_id: any;
}>({
	email: "",
	last_name: "",
	first_name: "",
	middle_name: "",
	mobile_number: "",
	home_address: "",
	is_temporary: false,
	department_id: "",
	office_id: "",
	position_id: "",
	role_id: "",
});
const errors = ref<any>();
const loading = ref<boolean>(false);
const loaded = ref<boolean>(false);
const canChangeRole = ref<boolean>(false);

const officeOptions = ref<SelectInterface[]>([]);
const userGroupOptions = ref<SelectInterface[]>([]);
const positionOptions = ref<SelectInterface[]>([]);
const departmentOptions = ref<SelectInterface[]>([]);

const save = (): void => {
	loading.value = true;
	Personnels.update(route.params.personnel, form.value)
		.then((response: any) => {
			loading.value = false;
			showConfirmation.value = true;
			successMessage.value = response.data.message;
		})
		.catch((error: any) => {
			loading.value = false
			errors.value = errorResponse(error.response?.data?.errors)
		});
};

const redirectSuccess = (): void => {
	showSuccess.value = false;
	router.replace("/personnel/index");
};

onMounted(() => {
	loading.value = true;
	Personnels.edit(route.params.personnel, [])
		.then(({ data }) => {
			
			form.value = assignValues(form.value, data.item);

			officeOptions.value = data.offices;
			positionOptions.value = data.positions;
			userGroupOptions.value = data.userGroups;
			departmentOptions.value = data.departments;
			canChangeRole.value = data.canChangeRole;

			loading.value = false
			loaded.value = true;
		})
		.catch((error: any) => {
			loading.value = false
			errors.value = errorResponse(error.response?.data?.errors)
		});
});
</script>
