<template>
    <div class="flex items-center ml-auto relative">
        <button 
        @click="decrement()" 
        class="">
            <MinusIcon 
            class="w-6 h-6"/>
        </button>

        <input
        type="text"
        class="w-10 border-0 text-center p-0 focus:ring-0 bg-transparent" 
        :value="count">

        <button 
        @click="increment()" 
        class="">
            <PlusIcon 
            class="w-6 h-6"/>
        </button>
    </div>
</template>
<script setup lang="ts">
import { MinusIcon, PlusIcon } from "@heroicons/vue/24/solid";
import { ref } from 'vue';
const count = ref<number>(0)

function increment() {
  count.value++
}

function decrement() {
  count.value--
}

</script>
