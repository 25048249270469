import { OfflineAction } from "@/views/enums/OfflineAction";
import Storage from "@/helpers/storage";
import Relationships from "../Relationships";

const storage = new Storage();
const relationship = new Relationships();

const setUpdateForm = async (item: any, content: any) => {
    const birthdate = item.birthdate ? relationship.dateFormatFromIso(item.birthdate) : null;
	content.age = birthdate ? relationship.computeAge(birthdate) : relationship.computeApproximateAge(item.approximate_age_year, item.approximate_age_month);
    content.computed_age = birthdate ? relationship.computeAge(birthdate) : relationship.computeApproximateAge(item.approximate_age_year, item.approximate_age_month);
	content.birthdate = birthdate;
    content.birthdate_formatted = birthdate ? relationship.dateFormat(birthdate) : null;
	content.contacts = item.contacts || content.contacts;
	content.encoded_by = storage.get("user");
	content.first_name = item.first_name || content.first_name;
	content.last_name = item.last_name || content.last_name;
	content.middle_name = item.middle_name || content.middle_name;
	content.name = `${item.first_name || content.first_name} ${item.last_name || content.last_name}`;
	content.other_relationship = item.other_relationship || content.other_relationship;
	content.guardian_relationship_id = item.guardian_relationship_id;
	content.gender = item.gender;
	content.gender_meta = relationship.gender(item.gender ? item : content);
	content.relationship = await relationship.guardianRelationship(item.guardian_relationship_id ? item : content);
    content.approximate_age_month = item.approximate_age_month;
    content.approximate_age_year = item.approximate_age_year;
    content.birthday_classification = item.birthday_classification;

    const date = new Date().toJSON();
    content.updated_at = relationship.dateFormatFromIso(date);
    content.updated_at_formatted = relationship.dateFormat(date);
	return JSON.stringify(content);
};

const setNewForm = async (item: any, content: any) => {
    const data = <any>{};
	data.age = item.birthdate ? relationship.computeAge(item.birthdate) : relationship.computeApproximateAge(item.approximate_age_year, item.approximate_age_month);
	data.birthdate = item.birthdate ? relationship.dateFormatFromIso(item.birthdate) : null;
    data.birthdate_formatted = item.birthdate ? relationship.dateFormat(item.birthdate) : null;
	data.contacts = item.contacts || null;
	data.encoded_by = storage.get("user");
	data.first_name = item.first_name || null;
	data.last_name = item.last_name || null;
	data.middle_name = item.middle_name || null;
	data.name = `${item.first_name || null} ${item.last_name || null}`;
	data.other_relationship = item.other_relationship || null;
	data.guardian_relationship_id = item.guardian_relationship_id || null;
	data.gender = item.gender;
	data.gender_meta = relationship.gender(item) || null;
	data.relationship = await relationship.guardianRelationship(item) || null;
    data.approximate_age_year = item.approximate_age_year;
    data.approximate_age_month = item.approximate_age_month;
    data.birthday_classification = item.birthday_classification;
    data.computed_age = item.birthdate ? relationship.computeAge(item.birthdate) : relationship.computeApproximateAge(item.approximate_age_year, item.approximate_age_month);
    data.birthday_classification = item?.birthday_classification;

    const date = new Date().toJSON();
    data.created_at = relationship.dateFormatFromIso(date);
    data.created_at_formatted = relationship.dateFormat(date);
	return JSON.stringify(data);
};

export default class CaseGuardianOfflineForm {
	content: string | null;
	encoded_by: number | null;
	action: number | null;
	is_sync: boolean;
	created_at: string | null;
	updated_at: string | null;
	deleted_at: string | null;
	id: string | null;
	cases_id: string | null;
	offline_cases_id: string | null;
	constructor(item: any, res: any) {
		this.id = item.id;
		this.cases_id = res?.id;
		this.offline_cases_id = "";
		this.content = JSON.stringify(item);
		this.encoded_by = res.encoded_by;
		this.action = OfflineAction.DEFAULT;
		this.is_sync = false;
		this.created_at = "";
		this.updated_at = "";
		this.deleted_at = "";
	}

	async updateForm(item: any, res: any): Promise<any> {
		const data = <any>{};
		data.id = res.id;
		data.cases_id = res?.cases_id;
		data.offline_cases_id = null;
		data.content = await setUpdateForm(item, JSON.parse(res.content));
		data.encoded_by = 0;
		data.action = OfflineAction.UPDATE_RECORD;
		data.is_sync = false;
		data.created_at = res.created_at;
		data.updated_at = relationship.dateFormatFromIso(new Date());
		data.deleted_at = "";
        data.is_updated_offline = res.is_created_offline ? false : true;
        data.is_created_offline = res.is_created_offline;

		return data;
	}

    async createForm(item: any, res: any): Promise<any> {
		const data = <any>{};
		data.cases_id = res?.id;
		data.offline_cases_id = null;
		data.content = await setNewForm(item, JSON.parse(res.content));
		data.encoded_by = 0;
		data.action = OfflineAction.NEW_RECORD;
		data.is_sync = false;
		data.created_at = relationship.dateFormatFromIso(new Date());
		data.updated_at = relationship.dateFormatFromIso(new Date());
		data.deleted_at = "";
        data.is_created_offline =  res.is_created_offline ? res.is_created_offline : true;
		return data;
	}

	async deleteForm(item: any, res: any): Promise<any> {
		const data = <any>{};
		data.id = res.id;
		data.cases_id = res?.cases_id;
		data.offline_cases_id = null;
		data.content = await setUpdateForm(item, JSON.parse(res.content));
		data.encoded_by = 0;
		data.action = OfflineAction.DELETE_RECORD;
		data.is_sync = false;
		data.created_at = res.created_at;
		data.updated_at = res.updated_at;
		data.deleted_at = relationship.dateFormatFromIso(new Date());
        data.is_created_offline = res.is_created_offline;
        data.is_updated_offline = res.is_created_offline ? false : res.is_updated_offline;
        data.is_deleted_offline = true;
		return data;
	}
}
