import http from "@/axios";

class ProvinceService {

	async index(payload?: object): Promise<any> {
		return await http.get("settings/provinces", { params: payload });
	}

	async store(payload: object): Promise<any> {
		return await http.post(`settings/provinces/store`, payload);
	}

	async edit(province: string, payload?: object): Promise<any> {
		return await http.get(`settings/provinces/${province}/edit`, payload);
	}
	
	async update(province: string, payload?: object): Promise<any> {
		return await http.put(`settings/provinces/${province}/update`, payload);
	}

	async delete(province: string, payload?: object): Promise<any> {
		return await http.delete(`settings/provinces/${province}/delete`, payload);
	}

	async restore(province: string): Promise<any> {
		return await http.patch(`settings/provinces/${province}/restore`);	
	}
}
export default new ProvinceService();
