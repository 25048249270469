import http from "@/axios";

class Verification {
    async checkPassword(payload?: object): Promise<any> {
        return await http.post("checkPassword", payload);
    }

    async verify(payload?: object): Promise<any> {
        return await http.post("verification", payload);
    }

    async isVerified(payload?: object): Promise<any> {
        return await http.post("isVerified", payload);
    }
}

export default new Verification();