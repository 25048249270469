import { EnumsInterface } from "@/interfaces/EnumsInterface";

const TeleconsultationStatus:EnumsInterface[] = [
    {id: 0, value: "Done", label: "Done"},
    {id: 1, value: "Cancelled and Rescheduled", label: "Cancelled and Rescheduled"},
    {id: 2, value: "No show and Rescheduled", label: "No show and Rescheduled"},
    {id: 3, value: "Terminated", label: "Terminated"},
    {id: 4, value: "Other (Specify in the remarks)", label: "Other (Specify in the remarks)"},
]

export default TeleconsultationStatus