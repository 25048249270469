<template>
	<user-layout>
		<div class="max-w-[500px] w-full mx-auto py-16">
			<div class="bg-primary-400 rounded-lg px-6 py-6">
				<h1 class="text-xl mb-6 font-bold">Search Patient</h1>
				<h2 class="text-sm font-bold mb-6">Patient Name</h2>
				<form class="flex flex-col space-y-6" @submit.prevent="submit">
					<text-input
						type="text"
						label="First Name"
						placeholder="First name"
						name="first_name"
						id="first_name"
						v-model="form.first_name"
						:error="errors?.first_name" />

					<text-input
						type="text"
						label="Last Name"
						placeholder="Last name"
						name="last_name"
						id="last_name"
						v-model="form.last_name"
						:error="errors?.last_name" />

					<Disclosure v-slot="{ open }">
						<DisclosureButton class="text-left cursor-default w-full md:pt-0 pt-3">
							<button-component
								customClass="secondary w-full"
								@click="addFilter = true"
							>
								<PlusIcon v-if="!open" class="w-4 h-4 text-primary-600 stroke-[3px] -ml-2 mr-1 icon" />
								<MinusIcon v-if="open" class="w-4 h-4 text-primary-600 stroke-[3px] -ml-2 mr-1 icon" />
								<span>Add Filter Search</span>
							</button-component>
						</DisclosureButton>
						<DisclosurePanel>
							<date-picker
								id="birthdate"
								name="birthdate"
								label="Birthdate"
								v-model="form.birthdate"
								:error="errors?.birthdate" />
						</DisclosurePanel>
					</Disclosure>

					<div>
						<button-component type="submit" size="large" btnWidth="w-full"> Search </button-component>
					</div>
				</form>
			</div>
		</div>
		<page-loader :show="loading" />
	</user-layout>
</template>
<script setup lang="ts">
import { ref, computed } from "vue";
import UserLayout from "@/layouts/UserLayout.vue";
import TextInput from "@/components/inputs/TextInput.vue";
import ButtonComponent from "@/components/buttons/ButtonComponent.vue";
import DatePicker from "@/components/inputs/DatePicker.vue";
import { PlusIcon, MinusIcon } from "@heroicons/vue/24/outline";
import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/vue";
import { errorResponse } from "@/helpers/errorMessage";
import PatientService from "@/classes/PatientService";
import { useRouter } from "vue-router";
import { onIonViewWillEnter, onIonViewWillLeave } from "@ionic/vue";
import Storage from "@/helpers/storage";
import PageLoader from "@/components/loaders/PageLoader.vue";
import { useNetworkStore } from "@/store/network";
import DB from "@/classes/Databases/Crud";
const networkStore = useNetworkStore();
const isNetworkAvailable = computed(() => networkStore.isNetworkAvailable);

const db = new DB('patient');
const storage = new Storage();
const router = useRouter();
const form = ref<{ last_name: string; first_name: string; birthdate: any }>({
	last_name: "",
	first_name: "",
	birthdate: "",
});
const addFilter = ref<boolean>(false);
const errors = ref<object>({});
const loading = ref<boolean>(false);

const submit = async (): void => {
	loading.value = true;

	if (!isNetworkAvailable.value) {
		if (!form.value.last_name && !form.value.first_name) {
			errors.value = {
				first_name: "The first name field is required when last name is empty.",
				last_name: "The last name field is required when last name is empty.",
			};

			return (loading.value = false);
		}
	}

    const response = await PatientService.patients({
			last_name: form.value.last_name,
			first_name: form.value.first_name,
			birthdate: form.value.birthdate,
		},
		isNetworkAvailable.value);

    if (response?.status === 200 || response?.data != undefined) {
        errors.value = null;
        storage.set(
            "search",
            JSON.stringify({
                last_name: form.value.last_name,
                first_name: form.value.first_name,
                birthdate: form.value.birthdate,
            })
        );
        loading.value = false;
        router.push({ path: "/patient/index", query: form.value });
    } else {
        const error = response;
        loading.value = false;
        errors.value = errorResponse(error.response?.data?.errors);
    }
};

const preloadSQLITE = async () => {
    await db.select(`SELECT * FROM patients LIMIT 1`);
}

onIonViewWillEnter(async () => {
    await preloadSQLITE();
});

onIonViewWillLeave(async () => {
	form.value.first_name = "";
	form.value.last_name = "";
	form.value.birthdate = "";
});
</script>
