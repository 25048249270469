import http from "@/axios";
import { TeleCpuLocation } from "@/interfaces/Settings/TeleCpuLocation";

class TeleCpuLocationService implements TeleCpuLocation {
	async index(params: any = {}): Promise<any> {
		return await http.get("settings/tele-cpu-locations", { params: params });
	}

	async delete(scopeBinding: any): Promise<any> {
		return await http.delete(`settings/tele-cpu-locations/${scopeBinding.location}/delete`);
	}

	async restore(scopeBinding: any): Promise<any> {
		return await http.patch(`settings/tele-cpu-locations/${scopeBinding.location}/restore`);
	}

	async edit(scopeBinding: any, params: any = {}): Promise<any> {
		return await http.get(`settings/tele-cpu-locations/${scopeBinding.location}/edit`, { params: params });
	}

	async update(scopeBinding: any, params: any = {}): Promise<any> {
		return await http.put(`settings/tele-cpu-locations/${scopeBinding.location}/update`, params);
	}

	async store(params: any = {}): Promise<any> {
		return await http.post(`settings/tele-cpu-locations`, params);
	}

	async create(params: any = {}): Promise<any> {
		return await http.get(`settings/tele-cpu-locations/create`, params);
	}
}

export default new TeleCpuLocationService();
