<template>
	<user-layout mainCustomClass="intervention-generator">
		<div class="-mx-6 w-[calc(100%+48px)]">
			<div
				class="fixed top-[72px] left-0 z-10 bg-white px-6 py-4 shadow-[0_1px_0px_0px_rgba(0,0,0,0.08)] flex justify-between items-center w-full">
				<a @click="router.replace('/settings/intervention-types/index')">
					<button-component customClass="tertiary text-black hover:text-primary-600 transition">
						<!-- <img src="assets/icon/ic-arrow-left.svg" alt="back-arrow" class="object-contain w-6 h-6 mr-2" /> -->
						<arrow-left-icon class="object-contain w-6 h-6 mr-2" />
						<span class="text-sm font-semibold">Back</span>
					</button-component>
				</a>

				<tab-component :tabs="tabs" />

				<div class="w-[220px] justify-end flex space-x-2">
					<button-component
						v-if="newForm"
						customClass="secondary"
					>
						<span>Save & Display</span>
					</button-component>
					
					<button-component
						v-if="!newForm"
						:disabled="formOpen"
						@click="save()"
						customClass="secondary"
					>
						<span>Update Form</span>
					</button-component>
				</div>

			</div>
			<div 
				v-if="!loading"
				class="mt-[72px]" 
			>
				<div v-if="route.query.tab != 'activity_logs'" class="bg-primary-100">
					<FormSection
						:form="form"
						:formFieldTypes="formFieldTypes"
						:existingDropdowns="existingDropdowns"
						@open="formOpen = true"
						@close="formOpen = false"
					/>
				</div>
				<div v-if="route.query.tab === 'activity_logs'" class="p-6">
					<div class="border rounded-lg border-neutral-100">
						<div v-if="filters.tab != 'activity_logs'">
							<ActivityLogsTable
								:items="activityLogs"
							/>
						</div>

						<div class="w-full px-6 border-t py-7">
							<simple-pagination 
								:numRows="filters.rows" 
								:currentPage="items.current_page" 
								:last-page="items.last_page" 
								@update="(value: any) => paginate(value)"
							/>
						</div>
					</div>
				</div>
			</div>
		</div>

		<error-modal
		:show="showError"
		@confirm="showError = false"
		action-text="Close"
		title="Error!"
		:content="errorMessage"
	/>

		<page-loader :show="loading"/>
		<page-loader :show="saving"/>

	</user-layout>
</template>
<script setup lang="ts">
import { onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import UserLayout from "@/layouts/UserLayout.vue";
import ButtonComponent from "@/components/buttons/ButtonComponent.vue";
import TabComponent from "@/components/partials/TabComponent.vue";
import FormSection from "./FormSection.vue";
import ErrorModal from "@/components/modals/ErrorModal.vue";

import { errorResponse } from "@/helpers/errorMessage";
import throttle from "lodash/throttle";
import pickBy from "lodash/pickBy";

import PageLoader from "@/components/loaders/PageLoader.vue"
import SimplePagination from "@/components/partials/SimplePagination.vue";
import ActivityLogsTable from "@/views/activity-logs/ActivityLogsTable.vue"

import { filters, items } from "@/interfaces/pages/FilterPageInterface";

import InterventionTypeService from "@/classes/Settings/InterventionTypeService.ts"
import ArrowLeftIcon from "@/components/icons/arrowLeftIcon.vue";

const route = useRoute();
const router = useRouter();

const interventionForm = ref<number>(route?.params?.form);
const form = ref<any>({});

const formFieldTypes = ref<any>({});
const existingDropdowns = ref<any>({});

const showFilter = ref<boolean>(false);
const loading = ref<boolean>(false);
const saving = ref<boolean>(false);

const tab = ref<any>(route.query.tab);
const datatab = ref<any>(route.query.datatab);
const errors = ref<any>();
const filterSearch = ref<any>(route.query.query);
const page = ref<any>(route.query.page);
const rows = ref<any>(route.query.rows || 5);
const showSuccess = ref<boolean>(false);
const filterDate = ref<any>(route.query.date);

const newForm = ref<boolean>(false);
const formOpen = ref<boolean>(false);

const activityLogs = ref<any>([]);

const errorMessage = ref<string>('');
const showError = ref<boolean>(false);


/**
 * METHODS
 */
const tabs = ref<{ name: string; count: number; tab: string|null; href: string; allowed: boolean }[]>([
	{
		name: "Form Builder",
		count: 0,
		tab: null,
		href: `/settings/intervention-types/edit/${interventionForm.value}`,
		allowed: true,
	},
	{
		name: "Activity logs",
		count: 0,
		tab: "activity_logs",
		href: `/settings/intervention-types/edit/${interventionForm.value}?tab=activity_logs`,
		allowed: true,
	},
]);


const save = (): void => {
	saving.value = true;
	
	InterventionTypeService
		.update(interventionForm.value, {
			form: form.value
		})
		.then((response: any) => {
			redirectSuccess()
		})
		.catch((error: any) => {
			showError.value = true;
			errors.value = error.response.data.errors;

			errorMessage.value = Object.values(errors.value)[0]
		})
		.finally(() => saving.value = false);
};

const redirectSuccess = (): void => {
	showSuccess.value = false;
	router.replace("/settings/intervention-types/index");
};

/**
 * METHODS
 */
const getData = (): void => {

	loading.value = true;
	
	InterventionTypeService
		.edit(interventionForm.value)
		.then((response: any) => {
			form.value = response.data.item;
			formFieldTypes.value = response.data.formFieldTypes;
			activityLogs.value = response.data.activityLogs;
			existingDropdowns.value = response.data.existingDropdowns
		})
		.catch((error: any) => {
			console.log(error);
		})
		.finally(() => loading.value = false);
};

const reloadPage = async () => {
	return await router.push({
		path: `/settings/intervention-types/view`,
		query: pickBy({
			query: filterSearch.value,
			page: page.value,
			rows: rows.value,
			tab: tab.value,
			datatab: datatab.value,
			date: filterDate.value,
		}),
	});
};

const applyFilters = (search: any = null) => {
	filters.value.query = search;
	showFilter.value = false;

	getData();
};

const paginate = ({ page, rows }): void => {
	filters.value = Object.assign(filters.value, {
		page, rows
	})
	getData();
};

/**
 * WATCHERS
 */
watch(
	filterSearch,
	throttle((val: string) => {
		applyFilters(val);
	}, 1000)
);

onMounted(() => {
	getData();
});
</script>
