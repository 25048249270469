import http from "@/axios";

class Notifications {
	async index(payload?: object): Promise<any> {
		return await http.get("notifications/index", { params: payload });
	}

	async hasNotification(payload?: object): Promise<any> {
		return await http.get("notifications/hasNotification", { params: payload });
	}

	async markAsRead(notification: any): Promise<any> {
		return await http.post(`notifications/markAsRead/${notification}`, { notification: notification });
	}

	async markAllAsRead(): Promise<any> {
		return await http.post(`notifications/markAllAsRead`);
	}
}
export default new Notifications();
