import DB from "@/classes/Databases/Crud";
import { OfflineAction } from "@/views/enums/OfflineAction";
import Relationships from "../../Relationships";
import LegalStatus from "@/enums/LegalStatus";
import FormOptions from "../../FormOptions";
import OfflineSyncPersonRelatedToCase from "./OfflineSyncPersonRelatedToCase";
import OfflineSyncIncestCase from "./OfflineSyncIncestCase";
import OfflineSyncContactNumber from "./OfflineSyncContactNumber";
import OfflineSyncEmail from "./OfflineSyncEmail";

const db = new DB("intake_personnal_informations");
const dbCases = new DB("cases");
const dbPatient = new DB("patients");
const dbGuardian = new DB('case_guardians');
const dbContactNumber = new DB('intake_contact_numbers');
const dbPersonalInfo = new DB('intake_personnal_informations');
const dbPatientAddress = new DB('case_patient_addresses');
const dbPersonRelationToCase = new DB('intake_person_related_to_cases');
const relationship = new Relationships();
const options = new FormOptions();
class OfflineSyncPersonnalInformation {
    async syncToOffline(payload: any, caseId: any): Promise<any>{
        await db.insert(
            this.contentFormat(payload, caseId),
            payload.id,
            true,
            'intake_personnal_informations'
        );
    }

    async save(payload: any, patientId: any, caseId: any) {
        if (payload.id) {
            await this.update(payload, caseId);
        } else {
            await this.create(payload, caseId);
        }
    }
    
    async create(payload: any, caseId: any) {
        await db.delete({cases_id: caseId}, 'intake_personnal_informations')
        await db.insert({
            cases_id: caseId,
            content: JSON.stringify(payload),
            encoded_by: 0,
            action: OfflineAction.NEW_RECORD,
            is_sync: false,
            created_at: relationship.dateFormatFromIso(new Date()),
            updated_at: relationship.dateFormatFromIso(new Date()),
            deleted_at: "",
            is_created_offline: true,
        }, null, false, 'intake_personnal_informations');
    }

    async update(payload: any, caseId: any) {
        const response = await db.select(`SELECT * FROM intake_personnal_informations WHERE cases_id=${caseId} LIMIT 1`);
        if (response.length > 0 ) {
            const item = response[0];
            await db.delete({id: item.id}, 'intake_personnal_informations')
            await db.insert({
                id: payload.id,
                cases_id: caseId,
                content: JSON.stringify(payload),
                encoded_by: 0,
                action: OfflineAction.UPDATE_RECORD,
                is_sync: false,
                created_at: item.created_at,
                updated_at: relationship.dateFormatFromIso(new Date()),
                deleted_at: "",
                is_created_offline: item.is_created_offline,
                is_updated_offline: item.is_created_offline ? false : true,
            }, null, false, 'intake_personnal_informations');
        }
    }

    async show(patientId: any, caseId: any) {
        const response = await db.select(`SELECT * FROM intake_personnal_informations WHERE cases_id=${caseId}`);
        let item = null;
        let content = null;
        if (response.length > 0) {
            content = JSON.parse(response[0].content);
            const cases = await dbCases.edit(caseId);
            if (cases.data.item.length === undefined) {
                const caseContent = await this.transformCaseContent(JSON.parse(cases.data.item.content), caseId);
                content = {
                    ...content, 
                    ...{
                        cases_information: caseContent, 
                        id: content.id
                    }
                };
            }            
        } else {
            const cases = await dbCases.edit(caseId);
            if (cases.data.item.length === undefined) {
                const caseContent = await this.transformCaseContent(JSON.parse(cases.data.item.content), caseId);
                content = {
                    cases_information: caseContent,
                };
            }        
        }
        
        item = content;
        const patient = await dbPatient.edit(patientId);
        let patientName = null;
        let patientNameWithBirthday = null;
        if (patient.data.item.length === undefined) {
            const patientItem = JSON.parse(patient.data.item.content);
            patientName = patientItem.name;
            patientNameWithBirthday = patientItem.name + ' ' + patientItem.birthdate;
        }

        return {
            housingClassifications: await options.housingClassifications(),
            item: item,
            legalStatus: LegalStatus,
            socioEconomics: await options.socioEconomicStatuses(),
            patient: patientName,
            patient_with_birthday: patientNameWithBirthday
        }
    }

    async transformCaseContent(content: any, caseId: any) {
        content.date_initiated = content.initiated_at;
        content.birthday_type = content.birthday_classification;
        content.primary_diagnosis = content.diagnoses?.primary_diagnoses?.name;
        content.sex_at_birth = content.sex_at_birth_meta?.description;
        content.gender = content.gender_meta?.description;
        content.external_referral = content.referral_service?.referral_source?.name || '-';
        content.intra_referral = content.referral_service?.second_referral_source?.name || '-';
        content.if_ward_referred = content.hospital_area ? `Yes, ${content.hospital_area?.name}` : "No";
        content.social_worker = content.referral_service?.intake_social_worker?.name || '-';
        content.services_sought = content.referral_service?.sought_service?.name || '-';
        
        // const guardian = await dbGuardian.select(`SELECT * FROM case_guardians WHERE cases_id=${caseId} AND (deleted_at = "" OR deleted_at IS NULL) ORDER BY ID DESC`);
        // // content.other_relationship 
        // if (guardian.length > 0) {
        //     const item = JSON.parse(guardian[0].content);
        //     // content.informant_relation_child
        //     // content.informant = "";
        // }
        
        return content;
    }

    async transformIndexContent(content: any, caseId: any) {
        const contentFormatted = await this.transformCaseContent(content, caseId);
        content = {...contentFormatted, ...content };
        content.age = relationship.computeAge(content.birthdate);
        content.name = `${content.last_name}, ${content.first_name}`;
        content.sex = content.gender_meta?.description;
        content.approximate_age = content.birthdate ? relationship.computeAge(content.birthdate) : relationship.computeApproximateAge(content.approximate_age_year, content.approximate_age_month);
        content.birthdate = relationship.birthdateDetails(content);
        const civilStatus: any = await relationship.civil_status(content);
        content.civil_status = civilStatus?.label || 'Did not specify';
        content.is_inpatient = content.hospital_area ? "Yes" : "No";
        content.inpatient_referred = content.hospital_area ? `Yes, ${content.hospital_area?.name}` : "No";

        const contact = await dbContactNumber.select(`SELECT * FROM intake_contact_numbers WHERE cases_id=${caseId} AND (deleted_at = "" OR deleted_at IS NULL) ORDER BY ID DESC`);
        if (contact.length > 0) {
            const item = JSON.parse(contact[0].content);
            content.contacts = contact.length > 0 ? item.number : '';
        }
        content.date = relationship.dateFormat(new Date(), 'DDD');
        // content.legal_status;
        const personalInfo = await dbPersonalInfo.select(`SELECT * FROM intake_personnal_informations WHERE cases_id=${caseId} AND (deleted_at = "" OR deleted_at IS NULL) LIMIT 1`);
        if (personalInfo.length > 0) {
            const item = JSON.parse(personalInfo[0].content);
            const socioEconomicStatus = await relationship.socioEconomic(item);
            content.socio_economic_status = socioEconomicStatus?.label;
            content.no_children_in_family = item.no_children_in_family;
            content.no_household_members = item.no_household_members;
            content.no_family_in_household = item.no_family_in_household;
            const legalStatus: any = relationship.legalStatus(item);
            content.legal_status = legalStatus?.description;
            const housingClassification = await relationship.housingClassification(item);
            content.housing_classification = housingClassification?.label;
        }


        content.address = await this.renderAddresses(content, caseId);
        content.familyComposition = await this.renderFamilyComposition(content, caseId);
        content.incest = await this.renderIncest(content, caseId);
        content.contactNumbers = await this.renderContactNumbers(content, caseId);
        content.emailAdresses = await this.renderEmailAddresses(content, caseId);
        content.lives_w_child = (await this.renderLivesWithChild(content, caseId)).toLocaleString();
        content.family_background = []; // TODO

        return content;
    }

    async renderAddresses(content: any, caseId: any) {
        const response = await dbPatient.index({cases_id: caseId}, ` WHERE cases_id=${caseId}`, false, 'case_patient_addresses');
        const currentArr = [];
        const permanentArr = []
        if (response.data.items.length > 0) {
            const items = response.data.items;
            for (const index in items) {
                const item = JSON.parse(items[index].content);
                item.id = items[index].id;

                // permanent address
                if (item.residence_type == 2) {
                    permanentArr.push(item);
                }
                // current address
                if (item.residence_type == 0) {
                    currentArr.push(item);
                }
            }
        }

        let permanentItem = null;
        let currentItem = null
        if (permanentArr.length > 0) {
            const latestPermanentId = permanentArr.map((item) => item.id).reduce((a, b) => Math.max(a, b), -Infinity);
            permanentItem = await this.setAddressOtherDetails(
                permanentArr.find((item) => item.id === latestPermanentId)
            );
        }

        if (currentArr.length > 0) {
            const lastestCurrentId = currentArr.map((item) => item.id).reduce((a, b) => Math.max(a, b), -Infinity);
            currentItem = await this.setAddressOtherDetails(
                currentArr.find((item) => item.id === lastestCurrentId)
            );
        }

        return await this.generateAddres(permanentItem, currentItem, caseId);
    }

    async generateAddres(permanentItem: any, currentItem: any, caseId: any) {

        let fullCurrent = null;
        let fullPermanent = null;
        if (currentItem) {
            fullCurrent = `${currentItem.address} ${currentItem.barangay} ${currentItem.city} ${currentItem.region?.name}`;
        }

        if (permanentItem) {
            fullPermanent = `${permanentItem.address} ${permanentItem.barangay} ${permanentItem.city} ${permanentItem.region?.name}`;
        } else {
            fullPermanent = fullCurrent;
        }

        const permanentRegion = permanentItem ? await relationship.regions(permanentItem) : null;
        const currentRegion = currentItem ? await relationship.regions(currentItem) : null; 
        return {
            permanent: fullPermanent || 'NA',
            address_type: currentItem?.residence_type_meta?.description,
            permanent_directions: permanentItem?.direction,
            current: fullCurrent || 'NA',
            current_directions: currentItem?.direction,
            cur_barangay: currentItem?.barangay,
            cur_province: currentItem?.province,
            cur_region: currentItem?.region?.name,
            cur_city: currentItem?.city,
            perm_barangay: permanentItem?.barangay,
            perm_province: permanentItem?.province,
            perm_region: permanentItem?.region?.name,
            cur_ncr_municipality: currentRegion?.code === 'car-cordillera-autonomous-region' ? currentItem?.city : '-',
            perm_ncr_municipality: permanentRegion?.code === 'car-cordillera-autonomous-region' ? permanentItem?.city : '-'
        };
    }

    async setAddressOtherDetails(content: any) {
        if (content) {
            const brgy = await relationship.barangays(content)
            const city = await relationship.cities(content)
            const province = await relationship.provinces(content);
            return {...content, ...{ 
                id: content.id, 
                cases_id: content.cases_id,
                barangay: brgy?.name,
                city: city?.name,
                province: province?.name,
            }}
        }

    }

    async renderFamilyComposition(content: any, caseId: any) {
        const response = await OfflineSyncPersonRelatedToCase.index({
            cases_id: caseId
        }, caseId, false);
        const arr = [];
        if (response.data.items.length > 0) {
            const records = response.data.items;
            for (const index in records) {
                const item = records[index];
                const data =  await OfflineSyncPersonRelatedToCase.transformContent(JSON.parse(item.content), item);
                const gender:any = relationship.gender(data);
                data.gender = gender?.description;
                data.relationship = data.companion_relationship;
                data.birthdate = data.birthdate || 'Unknown';
                
                arr.push(data);
            }
        }
        return arr;
    }

    async renderIncest(content: any, caseId: any) {
        const cases = await dbCases.select(`SELECT * FROM cases WHERE id=${caseId}`);
        if (cases.length > 0) {
            const item = cases[0];
            const response = await OfflineSyncIncestCase.show(item.patient_id, caseId);
            const responseItem:any = response.item;
            if (responseItem) {
                const sleepingArrangement: any = relationship.sleepingArrangement(responseItem.sleeping_arrangement);
                responseItem.sleeping_arrangement = sleepingArrangement?.description;
                responseItem.same_bed_with = responseItem.same_bed_with.map(
                    (item: any) => {
                        const response: any = relationship.sleepingArrangementWith(item)
                        return response?.description;
                    }
                ).toLocaleString();
                responseItem.same_room_with = responseItem.same_room_with.map(
                    (item: any) => {
                        const response: any = relationship.sleepingArrangementWith(item);
                        return response?.description;
                    }
                ).toLocaleString();
                responseItem.abused = {
                    form: responseItem.abused_living_arrangement.map(
                        (item: any) => {
                            const response: any = relationship.livingArrangement(item);
                            return response?.description;
                        }
                    )
                };
                responseItem.present = {
                    form: responseItem.present_living_arrangement.map(
                        (item: any) => {
                            const response: any = relationship.livingArrangement(item);
                            return response?.description;
                        }
                    )
                };
            }
            return responseItem;
        }
        
        return null;
    }

    async renderContactNumbers(content: any, caseId: any) {
        const response = await OfflineSyncContactNumber.index({ cases_id: caseId}, caseId, false);
        const arr = [];
        if (response.data.items.length > 0) {
            const records = response.data.items;
            for (const index in records) {
                const item = records[index];
                arr.push(OfflineSyncContactNumber.transformContent(JSON.parse(item.content), item));
            }
        }
        return arr;
    }

    async renderEmailAddresses(content: any, caseId: any) {
        const response = await OfflineSyncEmail.index({ cases_id: caseId}, caseId, false);
        const arr = [];
        if (response.data.items.length > 0) {
            const records = response.data.items;
            for (const index in records) {
                const item = records[index];
                arr.push(OfflineSyncEmail.transformContent(JSON.parse(item.content), item));
            }
        }
        return arr;
    }

    async renderLivesWithChild(content: any, caseId: any) {
        const response = await OfflineSyncPersonRelatedToCase.index({
            cases_id: caseId
        }, caseId, false);
        const arr = [];
        if (response.data.items.length > 0) {
            const records = response.data.items;
            for (const index in records) {
                const item = records[index];
                const setItem = JSON.parse(item.content);
                // get only the person related to Live with Child
                if (setItem?.person_related_types?.includes(0)) {
                    arr.push(`${setItem.first_name} ${setItem.middle_name} ${setItem.last_name}`)
                }
            }
        }
        return arr;
    }


    async view(patientId: any, caseId: any) {
        const response = await db.select(`SELECT * FROM cases WHERE id=${caseId}`);
        let item = null;
        if (response.length > 0) {
            const content = JSON.parse(response[0].content);
            const cases = await dbCases.edit(caseId);
            if (cases.data.item.length === undefined) {
                item = content;
            }            
        }
        return await this.transformIndexContent(item, caseId);
    }

    contentFormat(payload: any, caseId: any) {
        return {
            id: payload.id,
            cases_id: caseId,
            content: JSON.stringify(payload),
            encoded_by: 0,
            action: OfflineAction.DEFAULT,
            is_sync: false,
            created_at: "",
            updated_at: "",
            deleted_at: "",
        }
    }
    
}

export default new OfflineSyncPersonnalInformation();