<template>
	<ion-header class="w-full frm-header">
		<div class="flex items-center justify-between px-4 py-4 flex-items lg:px-6 lg:py-5 bg-primary-600">

			<!-- MOBILE ONLY -->
			<div class="flex items-center 2xl:hidden">
				<!-- <a v-if="hasBack" :href="backLink" class="h-6">
					<button-component
						size="small"
						customClass="bg-transparent py-0 px-0"
					>
						<ChevronLeftIcon class="w-6 h-6 text-white" aria-hidden="true"/>
					</button-component>
				</a> -->
				<button-component
					size="small"
					customClass="bg-transparent py-0 px-0"
					@click="showMenu = true"
				>
					<Bars3Icon class="w-6 h-6 text-white" aria-hidden="true"/>
				</button-component>
				<div class="flex items-center ml-1.5">
					<img
						class="object-contain w-7 h-7"
						src="assets/logo-picture.png"
						alt="logo"
					/>
					<p class="text-white ml-2.5 mr-4 font-semibold text-xs">Web-based WCPMIS <br/> Administrative Data</p>
				</div>
				<!-- <p class="block ml-3 text-xl font-bold lg:hidden">{{ pageTitle }}</p> -->
			</div>

			<!-- DESKTOP ONLY -->
			<div class="items-center hidden 2xl:flex">
				<div class="flex items-center">
					<img
						class="object-contain w-7 h-7"
						src="assets/logo-picture.png"
						alt="logo"
					/>
					<p class="text-white ml-2.5 mr-4 font-semibold text-xs">Web-based WCPMIS <br/> Administrative Data</p>
					<div class="w-px h-5 mr-3 border-r border-white opacity-30"></div>
				</div>
				<!-- <div class="w-5 h-5 my-2.5 pl-10 pr-6 mr-6 border-r-[2px] border-white opacity-30">
					<span class="bg-white opacity-30 h-5 w-[1px]"></span>
				</div> -->
				<navigation-link 
					:status="online"
				/>
			</div>
			<div class="flex items-center">
				<div class="mr-4" >
					<draft-link :count="draft.count" />
				</div>
				<Menu as="div" class="relative inline-block text-left">
					<div>
						<MenuButton class="flex items-center min-w-0 space-x-3">
							<!-- <img
								class="object-cover w-8 h-8 bg-gray-300 border-white rounded-full"
								src="https://via.placeholder.com/40x40"
								alt=""
							/> -->
							<div class="w-8 h-8 bg-[#D2851B] rounded-full object-cover text-center relative">
								<!-- <span class="text-sm font-bold leading-8 text-white">JC</span> -->
								<img class="text-sm font-bold leading-8 text-white rounded-full" :src="user.profile_photo_url"/>
								<div
									class="notif-badge border border-primary-600 w-3 h-3 rounded-full bg-cstmgreen absolute right-[2px] bottom-[-3px]"></div>
							</div>
							<span class="flex-col flex-1 hidden min-w-0 text-left lg:flex">
								<span class="text-xs font-semibold text-white truncate">
									{{ user.first_name }} {{ user.last_name }}
								</span>
							</span>
							<ChevronDownIcon class="hidden w-5 h-5 ml-2 text-white lg:inline-block" aria-hidden="true" v-if="isNetworkAvailable" />
						</MenuButton>
					</div>
					<transition
						v-if="isNetworkAvailable"
						enter-active-class="transition duration-100 ease-out"
						enter-from-class="transform scale-95 opacity-0"
						enter-to-class="transform scale-100 opacity-100"
						leave-active-class="transition duration-75 ease-in"
						leave-from-class="transform scale-100 opacity-100"
						leave-to-class="transform scale-95 opacity-0">
						<MenuItems
							class="absolute right-0 z-[1] mt-2 origin-top-right min-w-[175px] rounded border border-gray-200 bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none overflow-hidden">
							<MenuItem>
								<div
									class="flex items-center px-3 py-3 text-gray-600 transition bg-white cursor-pointer hover:bg-primary-300">
									<a href="/profile" class="relative flex">
										<!-- <img
											class="w-6 h-6 object-contain inline-block mr-2.5"
											src="/assets/icon/ic-nav-profile.svg"
											alt="icon" /> -->
										<nav-profile-icon class="w-6 h-6 object-contain inline-block mr-2.5" />
										<span class="font-semibold"> Profile </span>
									</a>
								</div>
							</MenuItem>
							<MenuItem>
								<div
									class="flex items-center px-3 py-3 text-gray-600 transition bg-white cursor-pointer hover:bg-primary-300">
									<a href="/notification" class="relative flex">
										<!-- <img
											class="w-6 h-6 object-contain inline-block mr-2.5"
											src="/assets/icon/ic-nav-notification.svg"
											alt="icon" /> -->
										<nav-notification-icon class="w-6 h-6 object-contain inline-block mr-2.5" />
										<span class="font-semibold"> Notifications </span>
										<div
										v-if="hasNotifications"
										class="notif-badge border border-white w-3 h-3 rounded-full bg-primary-600 absolute left-[13px] top-[2px]"></div>
									</a>
								</div>
							</MenuItem>
							<MenuItem>
								<div
									class="flex items-center px-3 py-3 text-gray-600 transition bg-white cursor-pointer hover:bg-primary-300"
									@click="logoutUser">
									<!-- <img
										class="w-6 h-6 object-contain inline-block mr-2.5"
										src="/assets/icon/ic-nav-logout.svg"
										alt="Logout" /> -->
									<nav-logout-icon class="w-6 h-6 object-contain inline-block mr-2.5" />
									<span class="font-semibold"> Logout </span>
								</div>
							</MenuItem>
						</MenuItems>
					</transition>
				</Menu>
			</div>
		</div>

		<div
			v-if="hasBack"
			class="bg-white lg:px-6 px-4 py-4 shadow-[0_1px_0px_0px_rgba(0,0,0,0.08)]"
			:class="hasBackClass">
			<a @click="redirect()">
				<button-component customClass="tertiary text-black hover:text-primary-600 transition lg:pl-4 pl-0 md:py-0 py-0">
					<!-- <img src="assets/icon/ic-arrow-left.svg" alt="back-arrow" class="object-contain w-6 h-6 mr-2" /> -->
					<arrow-left-icon class="object-contain w-6 h-6 mr-2" />
					<span class="text-sm font-semibold">{{ backTitle }}</span>
				</button-component>
			</a>
			<slot name="lowerMiddle"></slot>
			<slot name="lowerRight"></slot>
		</div>
		<div>
			<TransitionRoot as="template" :show="showMenu">
				<Dialog as="div" class="relative z-10 hdr-menu" @close="showMenu = false">
					<div class="fixed inset-0" />

					<div class="fixed inset-0 overflow-hidden">
						<div class="absolute inset-0 overflow-hidden bg-[#0000014d]">
							<div class="fixed inset-y-0 left-0 flex max-w-full pr-10 pointer-events-none">
								<TransitionChild
									as="template"
									enter="transform transition ease-in-out duration-300"
									enter-from="translate-x-[-100%]"
									enter-to="translate-x-0"
									leave="transform transition ease-in-out duration-300"
									leave-from="translate-x-0"
									leave-to="translate-x-[-100%]"
								>
									<DialogPanel class="w-screen max-w-sm pointer-events-auto">
										<XMarkIcon
											class="w-6 h-6 text-white absolute right-14 top-4 z-[1] cursor-pointer"
											aria-hidden="true"
											@click="showMenu = false"
										/>
										<div
											class="flex flex-col h-full py-2 overflow-y-scroll shadow-xl bg-primary-600"
										>
											<div class="relative flex-1 pl-6 pr-4">
												<img src="/assets/CPN-Logo-white.png" alt="logo" class="w-[200px] h-[92px] object-contain mb-10" >
												<p class="text-gray-200 text-xs font-medium mb-2.5">MAIN MENU</p>
												<div class="flex flex-col">
													<navigation-link
														:status="online"
														class="flex-col"
													></navigation-link>
												</div>
											</div>
										</div>
									</DialogPanel>
								</TransitionChild>
							</div>
						</div>
					</div>
				</Dialog>
			</TransitionRoot>
		</div>

		<!-- <SyncSplashModal /> -->

		<LockUserModal />
		<NewPinModal />

		<confirmation-modal
			:typeTwo="true"
			:show="showConfirmation"
			@cancel="showConfirmation = false"
			@confirm="triggerLogout(); showConfirmation = false"
			cancel-text="Cancel"
			action-text="Logout"
			title="Confirmation"
			content="Are your sure you want to logout? Any Unsync Drafts will be deleted."
		/>
	</ion-header>
</template>
<script setup lang="ts">
import http from "@/axios";

import { Network } from '@capacitor/network';

import { ref, onMounted, computed, watch } from 'vue';
import { IonHeader } from "@ionic/vue";
import { ChevronDownIcon, ChevronLeftIcon, Bars3Icon, ArrowPathIcon, XMarkIcon } from "@heroicons/vue/24/solid";
import { Menu, MenuButton, MenuItems, MenuItem, Dialog, DialogPanel, TransitionChild, TransitionRoot, } from "@headlessui/vue";
import { useRouter } from "vue-router";
import ButtonComponent from "@/components/buttons/ButtonComponent.vue";
import NavigationLink from './Components/NavigationLink.vue';
import DraftLink from './Components/DraftLink.vue';
import ConfirmationModal from '@/components/modals/ConfirmationModal.vue';
import SyncSplashModal from '@/components/modals/SyncSplashModal.vue';
import { logout } from "@/classes/AuthService";
import Notifications from "@/classes/Notifications";
import Storage from "@/helpers/storage";
import NewPinModal from '@/components/modals/NewPinModal.vue';
import LockUserModal from '@/components/modals/LockUserModal.vue';
import DraftService from "@/classes/Drafts/DraftService";
import { useNetworkStore } from "@/store/network";
import { useDraftLogger } from "@/store/draftlogger"
import DB from "@/classes/Databases/Crud";
import NavProfileIcon from "@/components/icons/navProfileIcon.vue";
import NavNotificationIcon from "@/components/icons/navNotificationIcon.vue";
import NavLogoutIcon from "@/components/icons/navLogoutIcon.vue";
import ArrowLeftIcon from "@/components/icons/arrowLeftIcon.vue";

const networkStore = useNetworkStore();
const isNetworkAvailable = computed(() => networkStore.isNetworkAvailable);
const draft = useDraftLogger();
const hasNewDraft = computed<boolean>(() => draft.hasDraft);
const dbDraft = new DB("drafts");
const hasNotifications = ref<boolean>(false);
const online = ref<boolean>(true)
const showConfirmation = ref<boolean>(false)
const draftCount = ref<number>(0);
const router = useRouter();
const currentRouteName = router.currentRoute.value.name;
const storage = new Storage();
const user = computed(() => JSON.parse(storage.get('user')));
const showMenu = ref<boolean>(false);
const dbPatient = new DB("patients");
const dbCases = new DB("cases");
const dbPerpetrators = new DB("perpetrators");
const dbCasePerpetrators = new DB("case_perpetrators");
const dbPatientAddress = new DB("case_patient_addresses");
const dbGuardians = new DB("case_guardians");
const dbCompanions = new DB("case_companions");
const dbIntervention = new DB("intervention_data");

const props = defineProps({
	hasBack: {
		type: Boolean,
		default: false,
	},
	backLink: {
		type: String,
		default: null,
	},
	hasBackClass: {
		type: String,
		default: "flex justify-between items-center",
	},
	backTitle: {
		type: String,
		default: "Back",
	},
	pageTitle: {
		type: String,
		default: null
	}
});

const redirect = (): void => {
	return router.replace(props.backLink);
};

const checkNotifications = (): void => {
	Notifications.hasNotification()
		.then(({ data }) => {
			hasNotifications.value = data.success;
		})
		.catch((error) => (console.log(error)))
}

const logoutUser = (): void => {
	showConfirmation.value = true;
}

Network.addListener('networkStatusChange', status => {
	// console.log('Network status changeds', status);
	
	online.value = status.connected
});


const logCurrentNetworkStatus = async ():Promise<any> => {
	const status = await Network.getStatus();

	online.value = status.connected	

	// if (status.connected) {
	// 	DraftService.sync()
	// }
};

const renderDraftLogs = async (): Promise<any> => {
    // const patients = await dbPatient.select('SELECT * FROM patients WHERE is_sync=false AND (is_created_offline=true OR is_updated_offline=true OR is_deleted_offline=true)');
    // const cases = await dbCases.select('SELECT * FROM cases WHERE is_sync=false AND (is_created_offline=true OR is_updated_offline=true OR is_deleted_offline=true)');
    // const perpetrators = await dbPerpetrators.select('SELECT * FROM perpetrators WHERE is_sync=false AND (is_created_offline=true OR is_updated_offline=true OR is_deleted_offline=true)');
    // const casePerpetrators = await dbCasePerpetrators.select('SELECT * FROM case_perpetrators WHERE is_sync=false AND (is_created_offline=true OR is_updated_offline=true OR is_deleted_offline=true)');
    // const caseGuardians = await dbGuardians.select('SELECT * FROM case_guardians WHERE is_sync=false AND (is_created_offline=true OR is_updated_offline=true OR is_deleted_offline=true)');
    // const caseInformant = await dbCompanions.select('SELECT * FROM case_companions WHERE is_sync=false AND (is_created_offline=true OR is_updated_offline=true OR is_deleted_offline=true)');
    // const casePatienAddress = await dbPatientAddress.select('SELECT * FROM case_patient_addresses WHERE is_sync=false AND (is_created_offline=true OR is_updated_offline=true OR is_deleted_offline=true)');
    // const interventions = await dbIntervention.select('SELECT * FROM intervention_data WHERE is_sync=false AND (is_created_offline=true OR is_updated_offline=true OR is_deleted_offline=true)');
	
    // draftCount.value = patients.length + cases.length + perpetrators.length + casePerpetrators.length + caseGuardians.length + caseInformant.length + casePatienAddress.length + interventions.length;
    // draft.count = draftCount.value;
}

const triggerLogout = (): void => {
	logout()
}

const redirectOffline = () => {
    if (!isNetworkAvailable.value) {
        if (location.href.match('/dashboard') || 
            location.href.match('/personnel') || 
            location.href.match('/office') ||
            location.href.match('/reports') ||
            location.href.match('/settings') ||
            location.href.match('/patient-logs')) {
                router.replace('/patient/search');
        }
    }
}

const renderDraftCount = () => {
    draft.count = localStorage.getItem('cpn-offline-draft-count') || 0;
}

// watch(
// 	() => hasNewDraft.value,
// 	(val) => {
// 		console.log("Watcher - Draft: " + val);
// 		if (val) {
//             draftCount.value = draft.count;
// 			// renderDraftLogs()
// 		}
// 	}
// );

watch(
    () => isNetworkAvailable.value,
    () => {
        // if (val) {
            // localStorage.removeItem('cpn-online-sync-attemp');
            // router.push('/draft/index');
        // } else {
            // router.push('/patient/search');
        // }
    }
);

onMounted(() => {
	if (isNetworkAvailable.value) {
		checkNotifications()
	}
	renderDraftLogs();
	logCurrentNetworkStatus();
    redirectOffline();
    renderDraftCount();
})
</script>