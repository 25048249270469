import { OfflineAction } from "@/views/enums/OfflineAction";
import Storage from "@/helpers/storage";
import Relationships from "../Relationships";

const storage = new Storage();
const relationship = new Relationships();

const setNewForm = async (item: any) => {
    const birthdate = item.birthdate ? relationship.dateFormatFromIso(item.birthdate) : null;
    const data = {
        computed_age: birthdate ? relationship.computeAge(birthdate) : relationship.computeApproximateAge(item.approximate_age_year, item.approximate_age_month),
        age: birthdate ? relationship.computeAge(birthdate) : relationship.computeApproximateAge(item.approximate_age_year, item.approximate_age_month),
		approximate_age: birthdate ? relationship.computeAge(birthdate) : relationship.computeApproximateAge(item.approximate_age_year, item.approximate_age_month),
        approximate_age_year: item?.approximate_age_year,
        approximate_age_month: item?.approximate_age_month,
		birthdate: relationship.dateFormatFromIso(item?.birthdate) || "",
        birthdate_formatted: relationship.dateFormat(item.birthdate, 'MM/dd/yyyy') || "Unknown",
        birthday_classification: item.birthday_classification,
		civil_status_id: item?.civil_status_id,
        civil_status: await relationship.civil_status(item),
		encoded_by: storage.get('user'),
        first_name: relationship.capitalFirstLetter(item?.first_name) || "",
        last_name: relationship.capitalFirstLetter(item?.last_name) || "",
        middle_name: relationship.capitalFirstLetter(item?.middle_name) || "",
        name_format: relationship.fullname(item),
        name: relationship.fullname(item),
        gender: item?.gender,
        gender_meta: relationship.gender(item),
        indigeneous_people_id: item?.indigeneous_people_id,
        indigeneous: await relationship.indigeneous(item),
        nationality_id: item?.nationality_id,
        nationality: await relationship.nationality(item.nationality_id),
        nickname: item?.nickname,
        registered_at: relationship.dateFormatFromIso(item?.registered_at) || "",
        registered_at_formatted: relationship.dateFormat(item?.registered_at, 'MM/dd/yyyy') || "",
        religion_id: item?.religion_id,
        religion: await relationship.religion(item),
        sex_at_birth: item?.sex_at_birth,        
        sex_at_birth_meta: relationship.sexAtBirth(item),
        pwd: item?.pwd,
        pwd_specify: item?.pwd_specify,
        other_indigeneous_people: item?.other_indigeneous_people,
        abbr: relationship.fullnameAbbr(item),
	};

	return JSON.stringify(data);
};

export default class CreatePatientCaseOfflineForm {
	last_name: string | null;
	first_name: string | null;
	middle_name: string | null;
	birthdate: string | null;
	content: string | null;
	encoded_by: number | null;
	action: number | null;
	is_sync: boolean;
	created_at: any | null;
	updated_at: any | null;
	deleted_at: any | null;
	// id: string | null;
    is_created_offline: boolean;
	constructor(item: any) {
		this.last_name = relationship.capitalFirstLetter(item?.last_name) || "";
		this.first_name = relationship.capitalFirstLetter(item?.first_name) || "";
		this.middle_name = relationship.capitalFirstLetter(item?.middle_name) || "";
		this.birthdate = item?.birthdate == "0000-00-00" ? "" : relationship.dateFormatFromIso(item?.birthdate);
        this.content = null;
		// this.content = await setNewForm(item);
		this.encoded_by = 0;
		this.action = OfflineAction.NEW_RECORD;
		this.is_sync = false;
		this.created_at = relationship.dateFormatFromIso(new Date());
		this.updated_at = null;
		this.deleted_at = null;
        this.is_created_offline = true;

        return this;
	}

    async renderCreatePatientForm(item: any, form: any) {
		form.content = await setNewForm(item);
        return form;
    }
}
