<template>
	<user-layout :hasBack="true" backLink="/settings/primary-diagnosis/index">
		<div class="max-w-[500px] mx-auto py-6">
			<form-section :form="form" :errors="errors">
				<div class="col-span-2">
					<div class="w-full flex items-center justify-end space-x-3">
						<a @click="$router.replace('/settings/primary-diagnosis/index')">
							<button-component btnWidth="quinary">Cancel</button-component>
						</a>

						<button-component btnWidth="primary" @click="save()">Save Changes</button-component>
					</div>
					<confirmation-modal
						:typeTwo="true"
						:show="showConfirmation"
						@cancel="showConfirmation = false"
						@confirm="
							showSuccess = true;
							showConfirmation = false;
						"
						cancel-text="Cancel"
						action-text="Confirm"
						title="Save Record"
						content="Are you sure you wish to save this record?" />
					<success-modal
						:show="showSuccess"
						@confirm="redirectSuccess()"
						action-text="Close"
						title="Record Saved!"
						:content="successMessage" />
				</div>
			</form-section>
		</div>
	</user-layout>
</template>
<script setup lang="ts">
import { ref } from "vue";
import UserLayout from "@/layouts/UserLayout.vue";
import FormSection from "./FormSection.vue";
import ButtonComponent from "@/components/buttons/ButtonComponent.vue";
import ConfirmationModal from "@/components/modals/ConfirmationModal.vue";
import SuccessModal from "@/components/modals/SuccessModal.vue";
import { useRouter } from "vue-router";
import PrimaryDiagnosisService from "@/classes/Settings/PrimaryDiagnosisService";
import { errorResponse } from "@/helpers/errorMessage";

const router = useRouter();

const showConfirmation = ref<boolean>(false);
const showSuccess = ref<boolean>(false);
const successMessage = ref<string>("Data has been successfully saved!");
const form = ref<{ name: string }>({
	name: "",
});
const errors = ref<any>();

const save = (): void => {
	PrimaryDiagnosisService.store(form.value)
		.then((response: any) => {
			showConfirmation.value = true;
			successMessage.value = response.data.message;
		})
		.catch((error: any) => (errors.value = errorResponse(error.response?.data?.errors)));
};

const redirectSuccess = (): void => {
	showSuccess.value = false;
	router.replace("/settings/primary-diagnosis/index");
};
</script>
