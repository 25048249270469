import DB from "@/classes/Databases/Crud";
import { OfflineAction } from "@/views/enums/OfflineAction";
import { ref } from "vue";

const db = new DB("patients");
const data = ref();

const setNewForm = (content: any, item: any) => {
	content = JSON.parse(content);

	content.registered_at = item.registered_at;
	content.sex_at_birth = item.sex_at_birth;
	content.last_name = item.last_name;
	content.first_name = item.first_name;
	content.middle_name = item.middle_name;
	content.birthdate = item.birthdate;
	content.approximate_age = item.approximate_age;
	content.civil_status_id = item.civil_status_id;
	content.nationality_id = item.nationality_id;
	content.religion_id = item.religion_id;
	content.gender = item.gender;
	content.indigeneous_people_id = item.indigeneous_people_id;
	
	return JSON.stringify(content);
};

export default class PatientOfflineForm {
	last_name: string | null;
	first_name: string | null;
	middle_name: string | null;
	birthdate: string | null;
	content: string | null;
	encoded_by: number | null;
	action: number | null;
	is_sync: boolean;
	updated_at: any | null;
	deleted_at: any | null;
	id: any | null;
    offline_id: any;
	constructor(id: any, item: any, content: any) {
		this.id = item.isOfflineData ? "" : id;
        if (item.isOfflineData) {
            this.offline_id = parseInt(id);
        }
		this.last_name = item?.last_name;
		this.first_name = item?.first_name;
		this.middle_name = item?.middle_name;
		this.birthdate = item?.birthdate == "0000-00-00" ? "" : item?.birthdate;
		this.content = setNewForm(content, item);
		this.encoded_by = item?.encoded_by || 0;
		this.action = OfflineAction.UPDATE_RECORD;
		this.is_sync = false;
		this.updated_at = null;
		this.deleted_at = null;
	}
}
