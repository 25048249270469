import http from "@/axios";
import DB from "@/classes/Databases/Crud";

const dbPatient = new DB("patients");
const dbPatientAddress = new DB("case_patient_addresses");
export default class SyncPatientAddresses {
    
    resetItemData(item: any) {
        item.synching_remarks = null;
        item.is_sync_failed = false;
        item.is_sync = true;
        item.is_created_offline = false;
        item.is_updated_offline = false;
        item.is_deleted_offline = false;

        return item;
    }

    async rollback(item: any, response: any) {
        const error = response?.response?.data;
        item.synching_remarks = error.message || 'System Error';
        item.is_sync_failed = true;
        await dbPatient.insert(item, item.id, true, 'case_patient_addresses');

        return true;
    }

    async processForm(item: any, form: any) {
        let hasError = false;

        if (item.is_updated_offline) {
            const response = await http.post('/attemp-synching-patient-address-update', form).catch(error => error);
            if (response?.status === 200) {
                item = this.resetItemData(item);
                await dbPatientAddress.insert(item, item.id, true, 'case_patient_addresses');
                hasError = false;
            } else {
                hasError = await this.rollback(item, response);
            }
        }

        if (item.is_created_offline && !item.is_updated_offline) {
            const response = await http.post('/attemp-synching-patient-address-create', form).catch(error => error);
            if (response?.status === 200) {
                const oldPatientId = item.id;
                item = this.resetItemData(item);
                item.id = response.data.data.item.id;
                await dbPatientAddress.delete({id: response.data.data.item.id}, 'case_patient_addresses');
                await dbPatientAddress.insert(item, oldPatientId, true, 'case_patient_addresses');
                hasError = false;
            } else {
                hasError = await this.rollback(item, response);
            }
        }

        if (item.is_deleted_offline) {
            const response = await http.post('/attemp-synching-patient-address-delete', form).catch(error => error);
            if (response?.status === 200) {
                await dbPatientAddress.delete({id: item.id}, 'case_patient_addresses');
                hasError = false;
            } else {
                hasError = await this.rollback(item, response);
            }
        }

        return hasError;
    }
}