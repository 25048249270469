<!-- This example requires Tailwind CSS v2.0+ -->
<template>
    <Listbox
        as="div"
        :modelValue="modelValue"
        :disabled="disabled"
        @update:model-value="$emit('update:modelValue', $event.id)"
    >
        <ListboxLabel class="block text-xs font-semibold text-neutral-600">
            {{ label }} <span v-if="required" class="text-red-600">*</span>
        </ListboxLabel>

        <div
            class="relative rounded-md"
            :class="label ? 'mt-2' : ''"
        >
            <ListboxButton
                class="
                    relative
                    w-full
                    bg-white
                    border
                    border-solid
                    border-neutral-100
                    rounded
                    text-left
                    focus:outline-none 
                    focus:ring-0
                    selectOptions
                "
                :id="id"
                :name="name"
                :class="[
                    disabled ? '!bg-gray-100' : '',
                    error
                    ? 'focus:ring-red-500 focus:border-red-500 border-red-500'
                    : 'focus:ring-neutral-900 focus:border-neutral-900 border-neutral-100',
                    customClass ? customClass : 'pl-4 pr-10 py-3 h-12',
                    readonly ? 'pointer-events-none cursor-default' : ''
                ]"
            >
                <span
                    v-if="selected"
                    class="block truncate text-neutral-600"
                >
                    {{ selected }}
                </span>
                <span
                    v-else
                    class="block"
                >
                    {{ placeholder }}
                </span>
            </ListboxButton>
            
            <template v-if="modelValue && !disabled">
                <span
                    @click="clearValue"
                    class="
                        absolute
                        inset-y-0
                        right-0
                        flex
                        items-center
                        pr-2
                        z-10
                        cursor-pointer
                    "
                    title="Clear Filter"
                >
                    <XMarkIcon class="h-5 w-5 text-neutral-400" aria-hidden="true" />
                </span>
            </template>
            <template v-else>
                <span
                    class="
                        absolute
                        inset-y-0
                        right-0
                        flex
                        items-center
                        pr-2
                        z-10
                        cursor-pointer
                        pointer-events-none
                    "
                >
                    <ChevronDownIcon class="h-5 w-5 text-neutral-400" aria-hidden="true" />
                </span>
            </template>
            
            <transition
                leave-active-class="transition ease-in duration-100"
                leave-from-class="opacity-100"
                leave-to-class="opacity-0"
            >
                <ListboxOptions
                    class="
                        absolute
                        z-20
                        mt-1
                        w-full
                        bg-white
                        shadow-lg
                        max-h-60
                        rounded
                        py-1
                        ring-1 ring-black ring-opacity-5
                        overflow-auto
                        focus:outline-none
                    "
                >
                    <ListboxOption
                        as="template"
                        v-for="item in options"
                        :key="item.id"
                        :value="item"
                        :disabled="item.unavailable"
                        :hidden="item.hidden"
                    >
                        <li
                            :class="[
                                modelValue == item.id
                                ? 'bg-neutral-100'
                                : item.unavailable
                                ? 'text-neutral-600'
                                : 'text-neutral-600 hover:bg-[#eff2f0]',
                                'cursor-default select-none relative py-2 pl-4 pr-4',
                            ]"
                        >
                            <span
                                :class="[
                                modelValue == item.id ? 'font-medium' : 'font-normal',
                                'block truncate',
                                ]"
                                :title="item.label"
                            >
                                {{ item.label }}
                            </span>
                        </li>
                    </ListboxOption>
                </ListboxOptions>
            </transition>
        </div>
        <p
            v-if="error"
            class="mt-2 text-sm"
            :class="error ? 'text-red-600' : 'text-neutral-900'"
            id="email-error"
        >
            {{ error }}
        </p>
    </Listbox>
</template>

<script>
import { computed } from "vue";
import {
    Listbox,
    ListboxButton,
    ListboxLabel,
    ListboxOption,
    ListboxOptions,
} from "@headlessui/vue";
import { ChevronDownIcon, XMarkIcon } from "@heroicons/vue/24/solid";
import { defineComponent } from "vue";

export default defineComponent({
    name: 'select-options',
    components: {
        Listbox,
        ListboxButton,
        ListboxLabel,
        ListboxOption,
        ListboxOptions,
        ChevronDownIcon,
        XMarkIcon,
    },
    props: {
        modelValue: {
            default: null,
            required: true,
        },
        options: {
            type: Array,
            required: true,
        },
        label: {
            type: String,
            default: "",
        },
        id: {
            type: String,
            default: null,
        },
        name: {
            type: String,
            default: null,
        },
        error: {
            type: String,
            default: "",
        },
        placeholder: {
            type: String,
            default: "",
        },
        customClass: {
            type: String,
            default: null,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        required: {
            type: Boolean,
            default: false,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
    },
    setup(props, { emit }) {
        const selected = computed(() => {
            const result = Object.values(props.options).filter(
                (option) => option.id == props.modelValue
            )[0];

            if (typeof result !== "undefined") {
                return result.value;
            } else {
                return props.placeholder;
            }
        });

        function clearValue() {
            emit("update:modelValue", null);
        }

        return {
            selected,
            clearValue,
        };
    },
});
</script>
