<template>
    <div class="w-full">

        <!-- Create and Edit Mode -->
        <div class="grid grid-cols-12 gap-6">
            <div class="lg:col-span-6 col-span-12 border rounded-2xl border-neutral-100 flex flex-col min-h-[250px] space-y-5 md:p-6 p-4"
            :class="isView ? 'cursor-not-allowed' : ''">
                <p class="font-semibold text-gray-900">Aggravating Factors</p>
                <div class="w-full relative">
                    <!-- <div v-if="isView" class="absolute top-0 left-0 w-full h-full bg-white bg-opacity-75 z-10"></div> -->
                    <div class="flex flex-col space-y-3"
                    :class="isView ? 'pointer-events-none' : ''">
                        <template v-for="(item, index) in aggravatingFactors" :key="index">
                            <checkbox-input
                                :checked="form.aggravatingFactors.includes(item.id)"
                                :id="`aggravating-${item.id}`"
                                :name="`aggravating-${item.id}`"
                                :label="item.label"
                                @update:modelValue="(value) => updateAggravatingIds(item.id)"
                                :readonly="isView"
                            />
                        </template>
                    </div>
                </div>
                <div class="w-full relative"
                :class="isView ? 'pointer-events-none' : ''">
                    <!-- <div v-if="isView" class="absolute top-0 left-0 w-full h-full bg-white bg-opacity-75 z-10"></div> -->
                    <text-input
                        label="Others, describe below"
                        placeholder="Describe here"
                        name="others_aggrevating_factors"
                        id="others_aggrevating_factors"
                        :textAreaRows="5"
                        textarea 
                        v-model="form.other_aggravating"
                        :disabled="isView"
                    />
                </div>
            </div>
            <div class="lg:col-span-6 col-span-12 border rounded-2xl border-neutral-100 flex flex-col min-h-[250px] space-y-5 md:p-6 p-4"
            :class="isView ? 'cursor-not-allowed' : ''">
                <p class="font-semibold text-gray-900">Mitigating Factors</p>
                <div class="w-full relative">
                    <!-- <div v-if="isView" class="absolute top-0 left-0 w-full h-full bg-white bg-opacity-75 z-10"></div> -->
                    <div class="flex flex-col space-y-3"
                    :class="isView ? 'pointer-events-none' : ''">
                        <template v-for="(item, index) in mitigatingFactors" :key="index">
                            <checkbox-input
                                :checked="form.mitigatingFactors.includes(item.id)"
                                :id="`mitigating-${item.id}`"
                                :name="`mitigating-${item.id}`"
                                :label="item.label"
                                @update:modelValue="(value) => updateMitigatingIds(item.id)"
                                :readonly="isView"
                            />
                        </template>
                    </div>
                </div>
                <div class="w-full relative"
                :class="isView ? 'pointer-events-none' : ''">
                    <!-- <div v-if="isView" class="absolute top-0 left-0 w-full h-full bg-white bg-opacity-75 z-10"></div> -->
                    <text-input
                        label="Others, describe below"
                        placeholder="Describe here"
                        name="others_mitigating_factors"
                        id="others_mitigating_factors"
                        :textAreaRows="5"
                        textarea 
                        v-model="form.other_mitigating"
                        :disabled="isView"
                    />
                </div>
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
import { ref } from "vue";
import TextInput from "@/components/inputs/TextInput.vue";
import CheckboxInput from "@/components/inputs/CheckboxInput.vue";

const props = defineProps({
    aggravatingFactors: {
        type: Object,
        required: true
    },
    mitigatingFactors: {
        type: Object,
        required: true
    },
    form: {
        type: Object,
        required: true
    },
    isView: {
        type: Boolean,
        default: false
    }
});

const form = ref(props.form)

const updateAggravatingIds = (id: number):void => {
    if (!form.value.aggravatingFactors.includes(id)) {
        form.value.aggravatingFactors.push(id)
    } else {
        form.value.aggravatingFactors.splice(form.value.aggravatingFactors.indexOf(id), 1)
    }
}

const updateMitigatingIds = (id: number):void => {
    if (!form.value.mitigatingFactors.includes(id)) {
        form.value.mitigatingFactors.push(id)
    } else {
        form.value.mitigatingFactors.splice(form.value.mitigatingFactors.indexOf(id), 1)
    }
}

</script>