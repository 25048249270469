<template>
	<user-layout hasBack :backLink="`/patient/${patientId}/view`">
		<div class="w-full lg:p-6 p-4 mx-auto my-6 border border-gray-200 rounded">
			<div class="flex items-center justify-between">
				<section-title title="Patient Profile Details" />
			</div>
			<form-section
				v-if="loaded"
				:form="form"
				:disabled="true"
				:sex-at-birth="sexAtBirthOptions"
				:civil-statuses="civilStatusOptions"
				:nationalities="nationalityOptions"
				:religions="religionOptions"
				:indigeneous="indigeneousOptions"
				:errors="errors" 
			/>
		</div>
		<success-modal
			:show="showSuccess"
			@confirm="redirectSuccess()"
			action-text="Close"
			title="Record Save!"
			:content="successMessage" />
		<page-loader :show="loading" />
	</user-layout>
</template>
<script setup lang="ts">
import { onMounted, ref, computed, watch } from "vue";
import UserLayout from "@/layouts/UserLayout.vue";
import SectionTitle from "@/components/partials/SectionTitle.vue";
import ButtonComponent from "@/components/buttons/ButtonComponent.vue";
import SuccessModal from "@/components/modals/SuccessModal.vue";
import FormSection from "./FormSection.vue";
import PatientService from "@/classes/PatientService";
import { EditPatient } from "@/interfaces/PatientInterface";
import { useRouter, useRoute } from "vue-router";
import { renderSelectV2, renderSelect } from "@/interfaces/DropdownInterface";
import { errorResponse } from "@/helpers/errorMessage";
import EditPatientForm from "@/classes/Forms/Patients/EditPatientForm";
import PageLoader from "@/components/loaders/PageLoader.vue";
import DB from "@/classes/Databases/Crud";
import { OfflineAction } from "@/views/enums/OfflineAction";
import FormOptions from "@/classes/Forms/FormOptions";
import SexAtBirth from "@/enums/SexAtBirth";
import { useNetworkStore } from "@/store/network";
import pickBy from "lodash/pickBy";
import { onIonViewWillEnter } from "@ionic/vue";
const networkStore = useNetworkStore();
const isNetworkAvailable = computed(() => networkStore.isNetworkAvailable);
const db = new DB("patients");

const router = useRouter();
const route = useRoute();
const patientId = ref<number | null>(route.params.patient);
const showConfirmation = ref<boolean>(false);
const showSuccess = ref<boolean>(false);
const successMessage = ref<string>("Data has been successfully updated!");
const sexAtBirthOptions = ref<renderSelectV2[]>([]);
const religionOptions = ref<renderSelect[]>([]);
const civilStatusOptions = ref<renderSelect[]>([]);
const nationalityOptions = ref<renderSelect[]>([]);
const form = ref<EditPatient>(new EditPatientForm());
const errors = ref<object>({});
const loaded = ref<boolean>(false);
const loading = ref<boolean>(false);
const indigeneousOptions = ref<renderSelect[]>([]);
const offlineParams = ref<string>(route.query.offline);

const save = (): void => {
	loading.value = true;
    errors.value = {};

	if (!isNetworkAvailable.value) {
		const validate = form.value.offlineUpdateValidation(form.value);
		errors.value = validate?.errors;

		if (validate.hasOfflineError) {
			showSuccess.value = false;
			return;
		}
	}

	PatientService.update(
		patientId.value,
		{ ...form.value, ...{ isOfflineData: offlineParams.value } },
		isNetworkAvailable.value
	)
		.then((response: object) => {
			showSuccess.value = true;
			successMessage.value = response.data.message;
			loading.value = false;
		})
		.catch((error: object) => {
			loading.value = false;
			errors.value = errorResponse(error.response?.data?.errors);
		});
};

const redirectSuccess = (): void => {
	showSuccess.value = false;
    router.push(`/patient/${patientId.value}/view`);
};

const getData = async (): Promise<any> => {
	loading.value = true;

    const response = await PatientService.edit(patientId.value, pickBy({}), isNetworkAvailable.value);

    if (response?.status === 200 || response?.data != undefined) {
        // Fetch patient information offline
        if (isNetworkAvailable.value) {
            form.value = new EditPatientForm(response.data.item);
            sexAtBirthOptions.value = response.data.sexAtBirth;
            civilStatusOptions.value = response.data.civilStatuses;
            indigeneousOptions.value = response.data.indigeneous;
        } else if (!isNetworkAvailable.value) {
            form.value = new EditPatientForm(JSON.parse(response.data.item.content));
            const options = new FormOptions();
            sexAtBirthOptions.value = SexAtBirth || [];
            civilStatusOptions.value = await options.civilStatusOptions();
            indigeneousOptions.value = await options.indigeneous();
        }

        loaded.value = true;
        loading.value = false;
    } else {
        const error = response;
        loading.value = false;
        console.log(error);
    }
};

// watch(
// 	() => isNetworkAvailable.value,
// 	async (val) => {
// 		console.log("Watcher - Network: " + val);
// 		await getData();
// 	}
// );

onIonViewWillEnter(async () => await getData());
// onMounted(async () => await getData());
</script>
