import BaseIndexFilters from "@/classes/General/BaseIndexFilters";
import BaseIndexItems from "@/classes/General/BaseIndexItems";

import { BasePageInterface, BaseItemsInterface } from "@/interfaces/pages/BasePageInterface";

import { ref } from 'vue';

const filters = ref<BasePageInterface>(new BaseIndexFilters());
const items = ref<BaseItemsInterface>(new BaseIndexItems());

export {filters, items};

