import { createRouter, createWebHistory } from "@ionic/vue-router";
import { RouteRecordRaw } from "vue-router";
import CryptoJS from 'crypto-js'
import http from "@/axios";

// import { hasPermission } from "@/classes/AuthService";

import HomePage from "../views/HomePage.vue";
import StyleSheet from "@/views/StyleSheet.vue";

// Auth pages
import LoginPage from "@/views/auth/LoginPage.vue";
import ForgotPassword from "@/views/auth/password/ForgotPassword.vue";
import ForgotPin from "@/views/auth/password/ForgotPin.vue";
import ResetPassword from "@/views/auth/password/ResetPassword.vue";
import ResetPin from "@/views/auth/password/ResetPin.vue";
import VerificationCode from "@/views/auth/password/VerificationCode.vue";
import VerificationPage from "@/views/auth/password/VerificationPage.vue";

// Profile
import ProfileIndexPage from "@/views/profiles/IndexPage.vue";

// Notification
import NotificationIndexPage from "@/views/notifications/IndexPage.vue";

// Dashboard
import DashboardIndexPage from "@/views/dashboards/IndexPage.vue";
import DashboardIndexPageWcpu from "@/views/dashboards/IndexPageWcpu.vue";

// Drafts
import DraftIndexPage from "@/views/drafts/IndexPage.vue";

// Patient
import PatientSearchForm from "@/views/patients/SearchForm.vue";
import PatientIndex from "@/views/patients/IndexPage.vue";
import PatientEdit from "@/views/patients/EditPage.vue";
import PatientView from "@/views/patients/ViewPage.vue";
import PatientCreate from "@/views/patients/CreatePage.vue";
import PatientProfileEdit from "@/views/patients/profiles/EditPage.vue";

import PatientGuardianCreate from "@/views/patients/otherInformations/guardians/CreatePage.vue";
import PatientGuardianEdit from "@/views/patients/otherInformations/guardians/EditPage.vue";
import PatientCompanionCreate from "@/views/patients/otherInformations/companions/CreatePage.vue";
import PatientCompanionEdit from "@/views/patients/otherInformations/companions/EditPage.vue";
import PatientAddressCreate from "@/views/patients/otherInformations/patientAddress/CreatePage.vue";
import PatientAddressEdit from "@/views/patients/otherInformations/patientAddress/EditPage.vue";
import PatientPerpetratorSearch from "@/views/patients/otherInformations/perpetrators/SearchPage.vue";
import PatientPerpetratorIndex from "@/views/patients/otherInformations/perpetrators/IndexPage.vue";
import PatientPerpetratorCreate from "@/views/patients/otherInformations/perpetrators/CreatePage.vue";
import PatientPerpetratorEdit from "@/views/patients/otherInformations/perpetrators/EditPage.vue";
import PatientSafetyAssessmentCreate from "@/views/patients/otherInformations/safetyAssessments/CreatePage.vue";
import PatientSafetyAssessmentView from "@/views/patients/otherInformations/safetyAssessments/ViewPage.vue";

//Intake
import PatientIntakeView from "@/views/patients/otherInformations/intakes/ViewPage.vue";
import PatientIntakeEdit from "@/views/patients/otherInformations/intakes/EditPage.vue";
import PersonalInformation from '@/views/patients/otherInformations/intakes/Pages/PersonalInformation/IndexPage.vue';
import AddressPresentLocation from '@/views/patients/otherInformations/intakes/Pages/AddressPresentLocation/IndexPage.vue';
import PersonsRelatedCase from '@/views/patients/otherInformations/intakes/Pages/PersonsRelatedCase/IndexPage.vue';
import ContactNumbers from '@/views/patients/otherInformations/intakes/Pages/ContactNumbers/IndexPage.vue';
import EmailAddresses from '@/views/patients/otherInformations/intakes/Pages/EmailAddresses/IndexPage.vue';
import IncestCases from '@/views/patients/otherInformations/intakes/Pages/IncestCases/IndexPage.vue';

// Case Conference
import CreateCaseConferenceInformation from "@/views/patients/otherInformations/caseConferences/Pages/Information/CreatePage.vue";
import EditCaseConferenceInformation from "@/views/patients/otherInformations/caseConferences/Pages/Information/EditPage.vue";
import CaseConferenceFactors from "@/views/patients/otherInformations/caseConferences/Pages/Factors/EditPage.vue";
import CaseConferenceRecommendation from "@/views/patients/otherInformations/caseConferences/Pages/Recommendation/EditPage.vue";
import CaseConferenceMinutesMeeting from "@/views/patients/otherInformations/caseConferences/Pages/MinutesMeeting/EditPage.vue";

// Intervention
import InterventionSearchForm from "@/views/interventions/SearchForm.vue";
import InterventionIndex from "@/views/interventions/IndexPage.vue";
import InterventionView from "@/views/interventions/ViewPage.vue";

import InterventionCreate from "@/views/interventions/CreatePage.vue";
import InterventionEdit from "@/views/interventions/EditPage.vue";

// Perpetrator
import PerpetratorIndex from "@/views/perpetrators/IndexPage.vue";
import PerpetratorView from "@/views/perpetrators/ViewPage.vue";
import PerpetratorEdit from "@/views/perpetrators/EditPage.vue";

// Personnel
import PersonnelIndex from "@/views/personnels/IndexPage.vue";
import PersonnelView from "@/views/personnels/ViewPage.vue";
import PersonnelEdit from "@/views/personnels/EditPage.vue";
import PersonnelCreate from "@/views/personnels/CreatePage.vue";

// Office
import OfficeIndex from "@/views/offices/IndexPage.vue";
import OfficeView from "@/views/offices/ViewPage.vue";
import OfficeCreate from "@/views/offices/CreatePage.vue";
import OfficeEdit from "@/views/offices/EditPage.vue";

// Data Dictionary
import DataDictionaryIndex from "@/views/data-dictionaries/IndexPage.vue";
import DataDictionaryView from "@/views/data-dictionaries/ViewPage.vue";

// Reports
import ReportsIndex from "@/views/reports/IndexPage.vue";
import ReportPatientRelated from "@/views/reports/ViewPage/ReportPatientRelated.vue"; 
import ReportIntervention from "@/views/reports/ViewPage/ReportIntervention.vue";
import ReportOffice from "@/views/reports/ViewPage/ReportOffice.vue";
import ReportCaseload from "@/views/reports/ViewPage/ReportCaseload.vue";
import ReportWorkload from "@/views/reports/ViewPage/ReportWorkload.vue";
import ReportPatientPersonnel from "@/views/reports/ViewPage/ReportPatientPersonnel.vue";
import ReportSystemLogs from "@/views/reports/ViewPage/ReportSystemLogs.vue";
import ReportCourtSchedule from "@/views/reports/ViewPage/ReportCourtSchedule.vue";
import ReportSafetyAssessment from "@/views/reports/ViewPage/ReportSafetyAssessment.vue";
import ReportIntake from "@/views/reports/ViewPage/ReportIntake.vue";
import ReportInterventionRecord from "@/views/reports/ViewPage/ReportInterventionRecord.vue";
import ReportComorbid from "@/views/reports/ViewPage/ReportComorbid.vue"; 
// Settings
import SettingsRolesIndex from "@/views/settings/Roles/IndexPage.vue";
import SettingsRolesView from "@/views/settings/Roles/ViewPage.vue";

import SettingsInterventionTypesIndex from "@/views/settings/InterventionTypes/IndexPage.vue";
import SettingsInterventionTypesView from "@/views/settings/InterventionTypes/ViewPage.vue";
import SettingsInterventionTypesCreate from "@/views/settings/InterventionTypes/CreatePage.vue";
import SettingsInterventionTypesEdit from "@/views/settings/InterventionTypes/EditPage.vue";

import SettingsPrimaryDiagnosisIndex from "@/views/settings/PrimaryDiagnosis/IndexPage.vue";
import SettingsPrimaryDiagnosisView from "@/views/settings/PrimaryDiagnosis/ViewPage.vue";
import SettingsPrimaryDiagnosisCreate from "@/views/settings/PrimaryDiagnosis/CreatePage.vue";

import SettingsFormOptionsIndex from "@/views/settings/FormOptions/IndexPage.vue";
import SettingsFormOptionsView from "@/views/settings/FormOptions/ViewPage.vue";
import SettingsFormOptionsCreate from "@/views/settings/FormOptions/CreatePage.vue";
import SettingsFormOptionsEdit from "@/views/settings/FormOptions/EditPage.vue";

import SettingsDepartmentIndex from "@/views/settings/Departments/IndexPage.vue";
import SettingsDepartmentView from "@/views/settings/Departments/ViewPage.vue";
import SettingsDepartmentCreate from "@/views/settings/Departments/CreatePage.vue";

import SettingsTelecpuLocationsIndex from "@/views/settings/TelecpuLocations/IndexPage.vue";
import SettingsTelecpuLocationsView from "@/views/settings/TelecpuLocations/ViewPage.vue";
import SettingsTelecpuLocationsCreate from "@/views/settings/TelecpuLocations/CreatePage.vue";
import SettingsTelecpuLocationsOfficeEdit from "@/views/settings/TelecpuLocations/Offices/EditPage.vue";
import SettingsTelecpuLocationsOfficeCreate from "@/views/settings/TelecpuLocations/Offices/CreatePage.vue";

import SettingsDataDictionaryIndex from "@/views/settings/DataDictionaries/IndexPage.vue";
import SettingsDataDictionaryView from "@/views/settings/DataDictionaries/ViewPage.vue";
import SettingsDataDictionaryCreate from "@/views/settings/DataDictionaries/CreatePage.vue";

import SettingsNotificationsIndex from "@/views/settings/Notifications/IndexPage.vue";
import SettingsNotificationsView from "@/views/settings/Notifications/ViewPage.vue";

import RegionIndex from "@/views/settings/Locations/Region/IndexPage.vue";
import RegionCreate from "@/views/settings/Locations/Region/CreatePage.vue";
import RegionEdit from "@/views/settings/Locations/Region/EditPage.vue";

import ProvinceIndex from "@/views/settings/Locations/Province/IndexPage.vue";
import ProvinceCreate from "@/views/settings/Locations/Province/CreatePage.vue";
import ProvinceEdit from "@/views/settings/Locations/Province/EditPage.vue";

import CityIndex from "@/views/settings/Locations/City/IndexPage.vue";
import CityCreate from "@/views/settings/Locations/City/CreatePage.vue";
import CityEdit from "@/views/settings/Locations/City/EditPage.vue";


import BarangayIndex from "@/views/settings/Locations/Barangay/IndexPage.vue";
import BarangayCreate from "@/views/settings/Locations/Barangay/CreatePage.vue";
import BarangayEdit from "@/views/settings/Locations/Barangay/EditPage.vue";

// Patient Logs
import PatientLogsIndex from "@/views/patient-logs/IndexPage.vue";

import Storage from "@/helpers/storage";
import { RoleTypes } from "@/views/enums/RoleTypes";
import DropdownService from "@/classes/Dropdowns/DropdownService";
import ReportCaseRelatedAgeGroup from "@/views/reports/ViewPage/ReportCaseRelatedAgeGroup.vue";
import ReportCaseRelatedSpecificAge from "@/views/reports/ViewPage/ReportCaseRelatedSpecificAge .vue";
import ReportCormorbid from "@/views/reports/ViewPage/ReportComorbid.vue";

const tokens = new Storage();

const user = JSON.parse(tokens.get("user"));

const hasPermission = (permission: string): boolean => {
	const checker = tokens.get("permissions");

	if (checker) {
		const decryptedPermissions = CryptoJS.AES.decrypt(tokens.get("permissions"), process.env.VUE_APP_SECRET_KEY).toString(CryptoJS.enc.Utf8);
		const permissions = JSON.parse(decryptedPermissions);

		if (permissions) {
			return permissions.includes(permission);
		}
	}

	return false;
};


const routes: Array<RouteRecordRaw> = [
	{
		path: "/",
		redirect: "/login",
	},
	{
		path: "/login",
		name: "Login",
		component: LoginPage,
	},
	{
		path: "/forgot-password",
		name: "ForgotPassword",
		component: ForgotPassword,
	},
	{
		path: "/forgot-pin",
		name: "ForgotPin",
		component: ForgotPin,
	},
	{
		path: "/reset-password",
		name: "ResetPassword",
		component: ResetPassword,
	},
	{
		path: "/reset-pin",
		name: "ResetPin",
		component: ResetPin,
	},
	{
		path: "/verification-code",
		name: "VerificationCode",
		component: VerificationCode,
	},
	{
		path: "/verification",
		name: "VerificationPage",
		component: VerificationPage,
	},
	{
		path: "/home",
		name: "Home",
		component: HomePage,
		meta: {
			needsAuth: true,
			permission:true,
		},
	},
	{
		path: "/profile",
		name: "ProfileIndexPage",
		component: ProfileIndexPage,
		meta: {
			needsAuth: true,
			permission: true
		},
	},
	{
		path: "/notification",
		name: "NotificationIndexPage",
		component: NotificationIndexPage,
		meta: {
			needsAuth: true,
			permission: true,
		},
	},
	{
		path: "/dashboard",
		name: "DashboardIndexPage",
		component: DashboardIndexPage,
		meta: {
			needsAuth: true,
			permission: true,
		},
	},
	{
		path: "/dashboard/wcpu",
		name: "DashboardIndexPageWcpu",
		component: DashboardIndexPageWcpu,
		meta: {
			needsAuth: true,
			permission: true,
		},
	},
	{
		path: "/draft/index",
		name: "DraftIndexPage",
		component: DraftIndexPage,
		meta: {
			needsAuth: true,
			permission: true,
		},
	},
	{
		path: "/stylesheet",
		name: "Stylesheet",
		component: StyleSheet,
		meta: {
			needsAuth: true,
			permission: true
		},
	},
	{
		path: "/patient/search",
		name: "PatientSearchForm",
		component: PatientSearchForm,
		meta: {
			needsAuth: true,
			permission: hasPermission('can-search-patients'),
		},
	},
	{
		path: "/patient/index",
		name: "PatientIndex",
		component: PatientIndex,
		meta: {
			needsAuth: true,
			permission: hasPermission('can-view-patients-information'),
		},
	},
	{
		path: "/patient/::patient/cases/::case/edit",
		name: "PatientEdit",
		component: PatientEdit,
		meta: {
			needsAuth: true,
			permission: hasPermission('can-view-patients-information'),
		},
	},
	{
		path: "/patient/::patient/view",
		name: "PatientView",
		component: PatientView,
		meta: {
			needsAuth: true,
			permission: hasPermission('can-view-patients-information'),
		},
	},
	{
		path: "/patient/create",
		name: "PatientCreate",
		component: PatientCreate,
		meta: {
			needsAuth: true,
			permission: hasPermission('can-create-patient-information'),
		},
	},
	{
		path: "/patient/::patient/case/create",
		name: "PatientCasereate",
		component: PatientCreate,
		meta: {
			needsAuth: true,
			permission: hasPermission('can-create-patient-information'),
		},
	},
	{
		path: "/patient/::patient/case/create",
		name: "PatientCasereate",
		component: PatientCreate,
		meta: {
			needsAuth: true,
			permission: hasPermission('can-create-patient-information'),
		},
	},
	{
		path: "/patient/profile/::patient/edit",
		name: "PatientProfileEdit",
		component: PatientProfileEdit,
		meta: {
			needsAuth: true,
			permission: hasPermission('can-view-patients-information'),
		},
	},
	{
		path: "/patient/::patient/cases/::case/guardians/create",
		name: "PatientGuardianCreate",
		component: PatientGuardianCreate,
		meta: {
			needsAuth: true,
			permission: hasPermission('can-manage-patient-guardian-companion-peretrator'),
		},
	},
	{
		path: "/patient/::patient/cases/::case/guardian/::guardian/edit",
		name: "PatientGuardianEdit",
		component: PatientGuardianEdit,
		meta: {
			needsAuth: true,
			permission: hasPermission('can-manage-patient-guardian-companion-peretrator'),
		},
	},
	{
		path: "/patient/::patient/cases/::case/companions/create",
		name: "PatientCompanionCreate",
		component: PatientCompanionCreate,
		meta: {
			needsAuth: true,
			permission: hasPermission('can-manage-patient-guardian-companion-peretrator'),
		},
	},
	{
		path: "/patient/::patient/cases/::case/companions/::companion/edit",
		name: "PatientCompanionEdit",
		component: PatientCompanionEdit,
		meta: {
			needsAuth: true,
			permission: hasPermission('can-manage-patient-guardian-companion-peretrator'),
		},
	},
	{
		path: "/patient/::patient/cases/::case/patient-addresses/create",
		name: "PatientAddressCreate",
		component: PatientAddressCreate,
		meta: {
			needsAuth: true,
			permission: hasPermission('can-view-patients-information'),
		},
	},
	{
		path: "/patient/::patient/cases/::case/patient-addresses/::patientAddress/edit",
		name: "PatientAddressEdit",
		component: PatientAddressEdit,
		meta: {
			needsAuth: true,
			permission: hasPermission('can-view-patients-information'),
		},
	},
	{
		path: "/patient/::patient/cases/::case/perpetrators/search",
		name: "PatientPerpetratorSearch",
		component: PatientPerpetratorSearch,
		meta: {
			needsAuth: true,
			permission: hasPermission('can-search-perpetrator'),
		},
	},
	{
		path: "/patient/::patient/cases/::case/perpetrators/index",
		name: "PatientPerpetratorIndex",
		component: PatientPerpetratorIndex,
		meta: {
			needsAuth: true,
			permission: hasPermission('can-view-perpetrator'),
		},
	},
	{
		path: "/patient/::patient/cases/::case/perpetrators/create",
		name: "PatientPerpetratorCreate",
		component: PatientPerpetratorCreate,
		meta: {
			needsAuth: true,
			permission: hasPermission('can-create-perpetrator'),
		},
	},
	{
		path: "/patient/::patient/cases/::case/perpetrators/::perpetrator/edit",
		name: "PatientPerpetratorEdit",
		component: PatientPerpetratorEdit,
		meta: {
			needsAuth: true,
			permission: hasPermission('can-update-perpetrator'),
		},
	},
    {
		path: "/patient/::patient/cases/::case/perpetrators/::perpetrator/view",
		name: "PatientPerpetratorView",
		component: PatientPerpetratorEdit,
		meta: {
			needsAuth: true,
			permission: hasPermission('can-update-perpetrator'),
		},
	},
	{
		path: "/patient/::patient/cases/::case/intake/personal-information",
		name: "PersonalInformation",
		component: PersonalInformation,
		meta: {
			needsAuth: true,
			permission: hasPermission('can-view-patients-information'),
		},
	},
	{
		path: "/patient/::patient/cases/::case/intake/address-present-location",
		name: "AddressPresentLocation",
		component: AddressPresentLocation,
		meta: {
			needsAuth: true,
			permission: hasPermission('can-view-patients-information'),
		},
	},
	{
		path: "/patient/::patient/cases/::case/intake/persons-related-case",
		name: "PersonsRelatedCase",
		component: PersonsRelatedCase,
		meta: {
			needsAuth: true,
			permission: hasPermission('can-view-patients-information'),
		},
	},
	{
		path: "/patient/::patient/cases/::case/intake/contact-numbers",
		name: "ContactNumbers",
		component: ContactNumbers,
		meta: {
			needsAuth: true,
			permission: hasPermission('can-view-patients-information'),
		},
	},
	{
		path: "/patient/::patient/cases/::case/intake/email-addresses",
		name: "EmailAddresses",
		component: EmailAddresses,
		meta: {
			needsAuth: true,
			permission: hasPermission('can-view-patients-information'),
		},
	},
	{
		path: "/patient/::patient/cases/::case/intake/incest-cases",
		name: "IncestCases",
		component: IncestCases,
		meta: {
			needsAuth: true,
			permission: hasPermission('can-view-patients-information'),
		},
	},
	{
		path: "/patient/::patient/cases/::case/intake/view",
		name: "PatientIntakeView",
		component: PatientIntakeView,
		meta: {
			needsAuth: true,
			permission: hasPermission('can-view-patients-information'),
		},
	},
	// {
	// 	path: "/patient/::patient/cases/::case/intake/::intake/view",
	// 	name: "PatientIntakeView",
	// 	component: PatientIntakeView,
	// },
	{
		path: "/patient/::patient/cases/::case/intake/::intake/edit",
		name: "PatientIntakeEdit",
		component: PatientIntakeEdit,
		meta: {
			needsAuth: true,
			permission: hasPermission('can-view-patients-information'),
		},
	},
	{
		path: "/patient/::patient/cases/::case/safety-assessment/create",
		name: "PatientSafetyAssessmentCreate",
		component: PatientSafetyAssessmentCreate,
		meta: {
			needsAuth: true,
			permission: hasPermission('can-view-patients-information'),
		},
	},
	{
		path: "/patient/::patient/cases/::case/safety-assessment/::assessment/view",
		name: "PatientSafetyAssessmentView",
		component: PatientSafetyAssessmentView,
		meta: {
			needsAuth: true,
			permission: hasPermission('can-view-patients-information'),
		},
	},
    {
		path: "/patient/::patient/cases/::case/safety-assessment/::assessment/create",
		name: "PatientUpdateSafetyAssessmentCreate",
		component: PatientSafetyAssessmentCreate,
		meta: {
			needsAuth: true,
			permission: hasPermission('can-view-patients-information'),
		},
	},

	
	{
		path: "/patient/::patient/cases/::case/case-conference/information",
		name: "CreateCaseConferenceInformation",
		component: CreateCaseConferenceInformation,
	},
	{
		path: "/patient/::patient/cases/::case/case-conference/information/::caseConference/edit",
		name: "EditCaseConferenceInformation",
		component: EditCaseConferenceInformation,
	},
	{
		path: "/patient/::patient/cases/::case/case-conference/factors/::caseConference/edit",
		name: "CaseConferenceFactors",
		component: CaseConferenceFactors,
	},
	{
		path: "/patient/::patient/cases/::case/case-conference/recommendation/::caseConference/edit",
		name: "CaseConferenceRecommendation",
		component: CaseConferenceRecommendation,
	},
	{
		path: "/patient/::patient/cases/::case/case-conference/minutes-of-the-meeting/::caseConference/edit",
		name: "CaseConferenceMinutesMeeting",
		component: CaseConferenceMinutesMeeting,
	},
	{
		path: "/intervention/search",
		name: "InterventionSearchForm",
		component: InterventionSearchForm,
		meta: {
			needsAuth: true,
			permission: hasPermission('can-search-patients-intervations'),
		},
	},
	// Start Interventions
	{
		path: "/intervention/index",
		name: "InterventionIndex",
		component: InterventionIndex,
		meta: {
			needsAuth: true,
			permission: hasPermission('can-view-intervations'),
		},
	},
	{
		path: "/intervention/::patient/view",
		name: "InterventionView",
		component: InterventionView,
		meta: {
			needsAuth: true,
			permission: hasPermission('can-view-intervations'),
		},
	},
	// Go hear
	{
		path: "/patient/::patient/cases/::case/interventions",
		name: "InterventionCaseView",
		component: InterventionView,
		meta: {
			needsAuth: true,
			permission: hasPermission('can-view-intervations'),
		},
	},
	{
		path: "/patient/::patient/cases/::case/intervention/::interventionType/create",
		name: "InterventionCaseCreate",
		component: InterventionCreate,
		meta: {
			needsAuth: true,
			permission: hasPermission('can-create-intervation'),
		},
	},
	{
		path: "/patient/::patient/intervention/::interventionType/create",
		name: "InterventionCreate",
		component: InterventionCreate,
		meta: {
			needsAuth: true,
			permission: hasPermission('can-create-intervation'),
		},
	},
	{
		path: "/patient/::patient/cases/::case/intervention/::interventionType/edit/::intervention",
		name: "InterventionCaseEdit",
		component: InterventionEdit,
		meta: {
			needsAuth: true,
			permission: hasPermission('can-update-intervation'),
		},
	},
	{
		path: "/patient/::patient/intervention/::interventionType/edit/::intervention",
		name: "InterventionEdit",
		component: InterventionEdit,
		meta: {
			needsAuth: true,
			permission: hasPermission('can-create-intervation'),
		},
	},

	// End Interventions

	// Start Perpetrator
	{
		path: "/perpetrator/index",
		name: "PerpetratorIndex",
		component: PerpetratorIndex,
		meta: {
			needsAuth: true,
			permission: hasPermission('can-view-perpetrator'),
		},
	},
	{
		path: "/perpetrator/::perpetrator/view",
		name: "PerpetratorView",
		component: PerpetratorView,
		meta: {
			needsAuth: true,
			permission: hasPermission('can-view-perpetrator'),
		},
	},
	{
		path: "/perpetrator/::perpetrator/edit",
		name: "PerpetratorEdit",
		component: PerpetratorEdit,
		meta: {
			needsAuth: true,
			permission: hasPermission('can-update-perpetrator'),
		},
	},
	// End Perpetrator
	{
		path: "/personnel/index",
		name: "PersonnelIndex",
		component: PersonnelIndex,
		meta: {
			needsAuth: true,
			permission: hasPermission('can-view-admins'),
		},
	},
	{
		path: "/personnel/view/::personnel",
		name: "PersonnelView",
		component: PersonnelView,
		meta: {
			needsAuth: true,
			permission: hasPermission('can-create-admin'),
		},
	},
	{
		path: "/personnel/edit/:personnel",
		name: "PersonnelEdit",
		component: PersonnelEdit,
		meta: {
			needsAuth: true,
			permission: hasPermission('can-update-admin'),
		},
	},
	{
		path: "/personnel/create",
		name: "PersonnelCreate",
		component: PersonnelCreate,
		meta: {
			needsAuth: true,
			permission: hasPermission('can-create-admin'),
		},
	},
	{
		path: "/office/index",
		name: "OfficeIndex",
		component: OfficeIndex,
		meta: {
			needsAuth: true,
			permission: hasPermission('can-view-department'),
		},
	},
	{
		path: "/office/view",
		name: "OfficeView",
		component: OfficeView,
		meta: {
			needsAuth: true,
			permission: hasPermission('can-view-department'),
		},
	},
	{
		path: "/office/create",
		name: "OfficeCreate",
		component: OfficeCreate,
		meta: {
			needsAuth: true,
			permission: hasPermission('can-create-department'),
		},
	},
	{
		path: "/office/edit/::office",
		name: "OfficeEdit",
		component: OfficeEdit,
		meta: {
			needsAuth: true,
			permission: hasPermission('can-update-department'),
		},
	},
	{
		path: "/data-dictionary/index",
		name: "DataDictionaryIndex",
		component: DataDictionaryIndex,
		meta: {
			needsAuth: true,
			permission: hasPermission('manage-data-dictionary'),
		},
	},
	{
		path: "/data-dictionary/::dataDictionary/view",
		name: "DataDictionaryView",
		component: DataDictionaryView,
		meta: {
			needsAuth: true,
			permission: hasPermission('manage-data-dictionary'),
		},
	},
	{
		path: "/reports",
		name: "ReportsIndex",
		component: ReportsIndex,
		meta: {
			needsAuth: true,
			permission: hasPermission('can-view-reports'),
		},
	},
	{
		path: "/reports/patient-related-report",
		name: "ReportPatientRelated",
		component: ReportPatientRelated,
		meta: {
			needsAuth: true,
			permission: hasPermission('can-view-reports'),
		},
	},
	{
		path: "/reports/case-related-age-group-report",
		name: "ReportCaseRelatedAgeGroup",
		component: ReportCaseRelatedAgeGroup,
		meta: {
			needsAuth: true,
			permission: hasPermission('can-view-reports'),
		},
	},
	{
		path: "/reports/case-related-specific-age",
		name: "ReportCaseRelatedSpecificAge",
		component: ReportCaseRelatedSpecificAge,
		meta: {
			needsAuth: true,
			permission: hasPermission('can-view-reports'),
		},
	},
	{
		path: "/reports/intervention-report",
		name: "ReportIntervention",
		component: ReportIntervention,
		meta: {
			needsAuth: true,
			permission: hasPermission('can-view-reports'),
		},
	},
	{
		path: "/reports/office-report",
		name: "ReportOffice",
		component: ReportOffice,
		meta: {
			needsAuth: true,
			permission: hasPermission('can-view-reports'),
		},
	},
	{
		path: "/reports/caseload-report",
		name: "ReportCaseload",
		component: ReportCaseload,
		meta: {
			needsAuth: true,
			permission: hasPermission('can-view-reports'),
		},
	},
	{
		path: "/reports/workload-report",
		name: "ReportWorkload",
		component: ReportWorkload,
		meta: {
			needsAuth: true,
			permission: hasPermission('can-view-reports'),
		},
	},
	{
		path: "/reports/patient-personnel-report",
		name: "ReportPatientPersonnel",
		component: ReportPatientPersonnel,
		meta: {
			needsAuth: true,
			permission: hasPermission('can-view-reports'),
		},
	},
	{
		path: "/reports/system-logs-report",
		name: "ReportSystemLogs",
		component: ReportSystemLogs,
		meta: {
			needsAuth: true,
			permission: hasPermission('can-view-reports'),
		},
	},
	{
		path: "/reports/court-schedule-report",
		name: "ReportCourtSchedule",
		component: ReportCourtSchedule,
		meta: {
			needsAuth: true,
			permission: hasPermission('can-view-reports'),
		},
	},
	{
		path: "/reports/safety-assessment-report",
		name: "ReportSafetyAssessment",
		component: ReportSafetyAssessment,
		meta: {
			needsAuth: true,
			permission: hasPermission('can-view-reports'),
		},
	},
	{
		path: "/reports/intake-report",
		name: "ReportIntake",
		component: ReportIntake,
		meta: {
			needsAuth: true,
			permission: hasPermission('can-view-reports'),
		},
	},
	{
		path: "/reports/intervention-records-report",
		name: "ReportInterventionRecord",
		component: ReportInterventionRecord,
		meta: {
			needsAuth: true,
			permission: hasPermission('can-view-reports'),
		},
	},
	{
		path: "/reports/comorbid-report",
		name: "ReportComorbid",
		component: ReportComorbid,
		meta: {
			needsAuth: true,
			permission: hasPermission('can-view-reports'),
		},
	},
	{
		path: "/settings/roles/index",
		name: "SettingsRolesIndex",
		component: SettingsRolesIndex,
		meta: {
			needsAuth: true,
			permission: hasPermission('can-view-role'),
		},
	},
	{
		path: "/settings/roles/view/::role",
		name: "SettingsRolesView",
		component: SettingsRolesView,
		meta: {
			needsAuth: true,
			permission: hasPermission('can-view-role'),
		},
	},
	{
		path: "/settings/intervention-types/index",
		name: "SettingsInterventionTypesIndex",
		component: SettingsInterventionTypesIndex,
	},
	{
		path: "/settings/intervention-types/view",
		name: "SettingsInterventionTypesView",
		component: SettingsInterventionTypesView,
	},
	{
		path: "/settings/intervention-types/create/::form?",
		name: "SettingsInterventionTypesCreate",
		component: SettingsInterventionTypesCreate,
	},
	{
		path: "/settings/intervention-types/edit/::form",
		name: "SettingsInterventionTypesEdit",
		component: SettingsInterventionTypesEdit,
	},
	{
		path: "/settings/primary-diagnosis/index",
		name: "SettingsPrimaryDiagnosisIndex",
		component: SettingsPrimaryDiagnosisIndex,
		meta: {
			needsAuth: true,
			permission: hasPermission('manage-primary-diagnoses'),
		},
	},
	{
		path: "/settings/primary-diagnosis/::primaryDiagnosis/view",
		name: "SettingsPrimaryDiagnosisView",
		component: SettingsPrimaryDiagnosisView,
		meta: {
			needsAuth: true,
			permission: hasPermission('manage-primary-diagnoses'),
		},
	},
	{
		path: "/settings/primary-diagnosis/create",
		name: "SettingsPrimaryDiagnosisCreate",
		component: SettingsPrimaryDiagnosisCreate,
		meta: {
			needsAuth: true,
			permission: hasPermission('manage-primary-diagnoses'),
		},
	},
	{
		path: "/settings/form-options/index",
		name: "SettingsFormOptionsIndex",
		component: SettingsFormOptionsIndex,
		meta: {
			needsAuth: true,
			permission: hasPermission('manage-form-options'),
		},
	},
	{
		path: "/settings/form-options/view/::option",
		name: "SettingsFormOptionsView",
		component: SettingsFormOptionsView,
		meta: {
			needsAuth: true,
			permission: hasPermission('manage-form-options'),
		},
	},
	{
		path: "/settings/form-options/view/::option/create",
		name: "SettingsFormOptionsCreate",
		component: SettingsFormOptionsCreate,
		meta: {
			needsAuth: true,
			permission: hasPermission('manage-form-options'),
		},
	},
	{
		path: "/settings/form-options/view/::option/edit/::id",
		name: "SettingsFormOptionsEdit",
		component: SettingsFormOptionsEdit,
		meta: {
			needsAuth: true,
			permission: hasPermission('manage-form-options'),
		},
	},
	// {
	// 	path: "/settings/form-options/edit",
	// 	name: "SettingsFormOptionsEdit",
	// 	component: SettingsFormOptionsEdit,
	// 	meta: {
	// 		needsAuth: true,
	//permission: hasPermission(''),
	// 	},
	// },
	{
		path: "/settings/department/index",
		name: "SettingsDepartmentIndex",
		component: SettingsDepartmentIndex,
		meta: {
			needsAuth: true,
			permission: hasPermission('can-view-department'),
		},
	},
	{
		path: "/settings/department/::department/view",
		name: "SettingsDepartmentView",
		component: SettingsDepartmentView,
		meta: {
			needsAuth: true,
			permission: hasPermission('can-view-department'),
		},
	},
	{
		path: "/settings/department/create",
		name: "SettingsDepartmentCreate",
		component: SettingsDepartmentCreate,
		meta: {
			needsAuth: true,
			permission: hasPermission('can-create-department'),
		},
	},
	{
		path: "/settings/telecpu-locations/index",
		name: "SettingsTelecpuLocationsIndex",
		component: SettingsTelecpuLocationsIndex,
		meta: {
			needsAuth: true,
			permission: hasPermission('manage-telecpu-locations'),
		},
	},
	{
		path: "/settings/telecpu-locations/::location/view",
		name: "SettingsTelecpuLocationsView",
		component: SettingsTelecpuLocationsView,
		meta: {
			needsAuth: true,
			permission: hasPermission('manage-telecpu-locations'),
		},
	},
	{
		path: "/settings/telecpu-locations/create",
		name: "SettingsTelecpuLocationsCreate",
		component: SettingsTelecpuLocationsCreate,
		meta: {
			needsAuth: true,
			permission: hasPermission('manage-telecpu-locations'),
		},
	},
	{
		path: "/settings/telecpu-locations/::location/office/::telcpu/edit",
		name: "SettingsTelecpuLocationsOfficeEdit",
		component: SettingsTelecpuLocationsOfficeEdit,
		meta: {
			needsAuth: true,
			permission: hasPermission('manage-telecpu-locations'),
		},
	},
	{
		path: "/settings/telecpu-locations/::location/office/create",
		name: "SettingsTelecpuLocationsOfficeCreate",
		component: SettingsTelecpuLocationsOfficeCreate,
		meta: {
			needsAuth: true,
			permission: hasPermission('manage-telecpu-locations'),
		},
	},
	{
		path: "/settings/data-dictionary/index",
		name: "SettingsDataDictionaryIndex",
		component: SettingsDataDictionaryIndex,
		meta: {
			needsAuth: true,
			permission: hasPermission('manage-data-dictionary'),
		},
	},
	{
		path: "/settings/data-dictionary/::dataDictionary/view",
		name: "SettingsDataDictionaryView",
		component: SettingsDataDictionaryView,
		meta: {
			needsAuth: true,
			permission: hasPermission('manage-data-dictionary'),
		},
	},
	{
		path: "/settings/data-dictionary/create",
		name: "SettingsDataDictionaryCreate",
		component: SettingsDataDictionaryCreate,
		meta: {
			needsAuth: true,
			permission: hasPermission('manage-data-dictionary'),
		},
	},
	{
		path: "/settings/notifications/index",
		name: "SettingsNotificationsIndex",
		component: SettingsNotificationsIndex,
		meta: {
			needsAuth: true,
			permission: hasPermission('notification-content'),
		},
	},
	{
		path: "/settings/notifications/view",
		name: "SettingsNotificationsView",
		component: SettingsNotificationsView,
		meta: {
			needsAuth: true,
			permission: hasPermission('notification-content'),
		},
	},

	{
		path: "/settings/regions/index",
		name: "RegionIndex",
		component: RegionIndex,
		meta: {
			needsAuth: true,
			permission: hasPermission('can-view-department'),
		},
	},
	{
		path: "/settings/regions/create",
		name: "RegionCreate",
		component: RegionCreate,
		meta: {
			needsAuth: true,
			permission: hasPermission('can-view-department'),
		},
	},
	{
		path: "/settings/regions/edit/::region",
		name: "RegionEdit",
		component: RegionEdit,
		meta: {
			needsAuth: true,
			permission: hasPermission('can-create-department'),
		},
	},

	{
		path: "/settings/provinces/index",
		name: "ProvinceIndex",
		component: ProvinceIndex,
		meta: {
			needsAuth: true,
			permission: hasPermission('can-view-department'),
		},
	},
	{
		path: "/settings/provinces/create",
		name: "ProvinceCreate",
		component: ProvinceCreate,
		meta: {
			needsAuth: true,
			permission: hasPermission('can-view-department'),
		},
	},
	{
		path: "/settings/provinces/edit/::province",
		name: "ProvinceEdit",
		component: ProvinceEdit,
		meta: {
			needsAuth: true,
			permission: hasPermission('can-create-department'),
		},
	},


	{
		path: "/settings/city/index",
		name: "CityIndex",
		component: CityIndex,
		meta: {
			needsAuth: true,
			permission: hasPermission('can-view-department'),
		},
	},
	{
		path: "/settings/city/create",
		name: "CityCreate",
		component: CityCreate,
		meta: {
			needsAuth: true,
			permission: hasPermission('can-view-department'),
		},
	},
	{
		path: "/settings/city/edit/::city",
		name: "CityEdit",
		component: CityEdit,
		meta: {
			needsAuth: true,
			permission: hasPermission('can-create-department'),
		},
	},

	{
		path: "/settings/barangay/index",
		name: "BarangayIndex",
		component: BarangayIndex,
		meta: {
			needsAuth: true,
			permission: hasPermission('can-view-department'),
		},
	},
	{
		path: "/settings/barangay/create",
		name: "BarangayCreate",
		component: BarangayCreate,
		meta: {
			needsAuth: true,
			permission: hasPermission('can-view-department'),
		},
	},
	{
		path: "/settings/barangay/edit/::barangay",
		name: "BarangayEdit",
		component: BarangayEdit,
		meta: {
			needsAuth: true,
			permission: hasPermission('can-create-department'),
		},
	},

	{
		path: "/patient-logs/index",
		name: "PatientLogsIndex",
		component: PatientLogsIndex,
		meta: {
			needsAuth: true,
			permission: true,
		},
	},
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
});


router.beforeEach(async (to) => {


	if (to.meta.needsAuth && !tokens.get("access_token")) {
		return { name: "Login" };
	}

	if (to.meta.permission == false) {
		gotoDashboard( user.role_id);
	}

	if (tokens.get("access_token")) {
		DropdownService.onlineStatus()
			.then(() => console.log("Authentication is still active "))
			.catch((error: any) => {
				if (error?.response?.status === 401) {
					tokens.clearItems(["access_token", "permissions", "user", "isSessionLock", "search"]);
					window.location.href = "/login";
				}
			});
	}

	if (to.path === "/login" && tokens.get("access_token")) {
		return { name: "DashboardIndexPage" };
	}
});

const gotoDashboard = (role:number):void =>{
    const wcpuRoles = [
        RoleTypes.OFFICE_ADMIN,
        RoleTypes.OFFICE_NORMAL,
        RoleTypes.NURSE,
        RoleTypes.WCPU,
        RoleTypes.OFFICE_TRAINEE,
    ]

    if(wcpuRoles.includes(role)){
		router.push({ name: "DashboardIndexPageWcpu" })
        // window.location.href = "/dashboard/wcpu";
    } else {
		router.push({ name: "DashboardIndexPage" })
        // window.location.href = "/dashboard";
    }
}

export default router;
