
import { defineStore } from 'pinia'

interface RecordStatusState {
    isDeleted: boolean,
    isCreated: boolean
    isCreatedPatientAddress: boolean,
    isDeletedPatientAddress: boolean,
    isCreatedGuardian: boolean,
    isDeletedGuardian: boolean,
    isCreatedInformant: boolean,
    isDeletedInformant: boolean,
    isCreatedPerpetrator: boolean,
    isDeletedPerpetrator: boolean,
    isCreatedIntake: boolean,
    isDeletedIntake: boolean,
    isCreatedSafety: boolean,
    isDeletedSafety: boolean,
    isCreatedCaseConference: boolean,
    isDeletedCaseConference: boolean,
}

export const useRecordStatusChecker = defineStore({
    id: 'recordStatusChecker',
    state: (): RecordStatusState => {
        return {
            isDeleted: false,
            isCreated: false,
            isCreatedPatientAddress: false,
            isDeletedPatientAddress: false,
            isCreatedGuardian: false,
            isDeletedGuardian: false,
            isCreatedInformant: false,
            isDeletedInformant: false,
            isCreatedPerpetrator: false,
            isDeletedPerpetrator: false,
            isCreatedIntake: false,
            isDeletedIntake: false,
            isCreatedSafety: false,
            isDeletedSafety: false,
            isCreatedCaseConference: false,
            isDeletedCaseConference: false,
        }
    },
    getters: {},
    actions: {},
})