import http from "@/axios";
import BaseCrudlnterface from "@/interfaces/classes/BaseCrudlnterface";

class Offices implements BaseCrudlnterface {
	async index(payload?: object): Promise<any> {
		return await http.get("office/index", { params: payload });
	}

	async create(payload?: object): Promise<any> {
		return await http.get("office/create", { params: payload });
	}

	async store(payload: object): Promise<any> {
		return await http.post("office/store", payload, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
	}

	async edit(office: number, payload?: object): Promise<any> {
		return await http.get(`office/edit/${office}`, payload);
	}
	
	async update(office: number, payload?: object): Promise<any> {
		return await http.post(`office/update/${office}`, payload, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
        });
	}

	async delete(office: number, payload?: object): Promise<any> {
		return await http.post(`office/delete/${office}`, payload);
	}

	async restore(office: number): Promise<any> {
		return await http.post(`office/restore/${office}`);
	}

	async export(payload?: object): Promise<any> {
		return await http.get(`office/export`, payload);
	}
}
export default new Offices();
