<template>
	<div class="flex items-center justify-between gap-4">
		<Menu as="div" class="relative inline-block text-left">
			<div>
				<MenuButton class="flex items-center">
					<span class="text-xs">Rows: {{ numRows }}</span>
					<ChevronDownIcon class="w-4 h-4 ml-1 cursor-pointer stroke-2 text-neutral-900" />
				</MenuButton>
			</div>

			<transition
				enter-active-class="transition duration-100 ease-out"
				enter-from-class="transform scale-95 opacity-0"
				enter-to-class="transform scale-100 opacity-100"
				leave-active-class="transition duration-75 ease-in"
				leave-from-class="transform scale-100 opacity-100"
				leave-to-class="transform scale-95 opacity-0">
				<MenuItems
					class="absolute left-0 bottom-8 z-10 w-56 mt-2 origin-bottom-left bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
					<div class="py-4">
						<MenuItem v-slot="{ active }">
							<a
								@click="changeRows('')"
								:class="[
									active ? 'bg-gray-100 text-neutral-900 font-semibold' : 'text-gray-700',
									'block px-4 py-2 text-sm cursor-pointer',
								]"
								>All</a
							>
						</MenuItem>
						<MenuItem v-slot="{ active }">
							<a
								@click="changeRows(5)"
								:class="[
									active ? 'bg-gray-100 text-neutral-900 font-semibold' : 'text-gray-700',
									'block px-4 py-2 text-sm cursor-pointer',
								]"
								>5 Rows</a
							>
						</MenuItem>
						<MenuItem v-slot="{ active }">
							<a
								@click="changeRows(10)"
								:class="[
									active ? 'bg-gray-100 text-neutral-900 font-semibold' : 'text-gray-700',
									'block px-4 py-2 text-sm cursor-pointer',
								]"
								>10 Rows</a
							>
						</MenuItem>
						<MenuItem v-slot="{ active }">
							<a
								@click="changeRows(20)"
								:class="[
									active ? 'bg-gray-100 text-neutral-900 font-semibold' : 'text-gray-700',
									'block px-4 py-2 text-sm cursor-pointer',
								]"
								>20 Rows</a
							>
						</MenuItem>
						<MenuItem v-slot="{ active }">
							<a
								@click="changeRows(50)"
								:class="[
									active ? 'bg-gray-100 text-neutral-900 font-semibold' : 'text-gray-700',
									'block px-4 py-2 text-sm cursor-pointer',
								]"
								>50 Rows</a
							>
						</MenuItem>
						<MenuItem v-slot="{ active }">
							<a
								@click="changeRows(100)"
								:class="[
									active ? 'bg-gray-100 text-neutral-900 font-semibold' : 'text-gray-700',
									'block px-4 py-2 text-sm cursor-pointer',
								]"
								>100 Rows</a
							>
						</MenuItem>
					</div>
				</MenuItems>
			</transition>
		</Menu>
		<div class="flex items-center gap-4">
			<button type="button" :class="currentPage > 1 ? '' : 'opacity-25'">
				<ChevronLeftIcon class="w-4 h-4 cursor-pointer stroke-2 text-neutral-900" @click="previousPage()" />
			</button>
			<span class="text-xs">{{ currentPage }} of {{ lastPage }}</span>
			<button type="button" :class="currentPage === lastPage ? 'opacity-25' : ''">
				<ChevronRightIcon class="w-4 h-4 cursor-pointer stroke-2 text-neutral-900" @click="nextPage()" />
			</button>
		</div>
	</div>
</template>

<script setup lang="ts">
import { type PropType } from "vue";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import { ChevronLeftIcon, ChevronRightIcon, ChevronDownIcon } from "@heroicons/vue/24/outline";

type Rows = "All" | "5 Rows" | "10 Rows" | "20 rows";

const emit = defineEmits(["update:page", "update:rows"]);

const props = defineProps({
	numRows: {
		type: String as PropType<Rows>,
		required: true,
	},
	currentPage: {
		type: Number,
		default: 1,
		required: true,
	},
	lastPage: {
		type: Number,
		default: 1,
		required: true,
	},
});

const nextPage = () => {
	if (props.lastPage === props.currentPage) {
		return false;
	}
	emit("update:page", props.currentPage + 1);
};

const previousPage = () => {
	if (props.currentPage <= 1) {
		return false;
	}
	emit("update:page", props.currentPage - 1);
};

const changeRows = (row: any): void => {
	emit("update:rows", row);
};
</script>
