<template>
    <div class="flex space-x-2">
        <toggle-switch
            :value="switchData"
            noPadding
            @change="
                switchData = !switchData
            "
        />
        <p class="pt-1 text-xs font-semibold text-neutral-600">Is field required?</p>
    </div>
</template>
<script setup lang="ts">
import { ref } from "vue";
import ToggleSwitch from "@/components/inputs/ToggleSwitch.vue";

const props = defineProps({
	form: {
		type: Object,
	},
    value: {
        default: null,
        required: true,
    },
    label: {
        type: String,
        default: null,
    },
    description: {
        type: String,
        default: null,
    },
    noPadding: {
        type: Boolean,
        default: false
    },
    error: {
        type: String,
        default: null,
    },
    disabled: {
        type: Boolean,
        default: false,
    },
});
const form = ref<object>(props.form);
const switchData = ref<boolean>(false);


</script>
