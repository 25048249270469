const Cities = {
    name: 'cities',
    schema: [
        { column: 'id', value: 'INTEGER UNSIGNED NOT NULL' },
        { column: 'province_id', value: 'VARCHAR(255) NOT NULL' },
        { column: 'name', value: 'VARCHAR(255) NOT NULL' },
    ]
}

export default Cities
