import { OfflineAction } from "@/views/enums/OfflineAction";
import Storage from "@/helpers/storage";
import Relationships from "../Relationships";

const storage = new Storage();
const relationship = new Relationships();

const setUpdateForm = (item: any, content: any) => {
    content.appointment = item.appointment || content.appointment;
    content.assessment_date = item.assessment_date || content.assessment_date;
    content.brief_family_background = item.brief_family_background || content.brief_family_background;
    content.case_number = item.case_number || content.case_number;
    content.family_background = item.family_background || content.family_background;
    content.first_name = item.first_name || content.first_name;
    content.last_name = item.last_name || content.last_name;
    content.middle_name = item.middle_name || content.middle_name;
    content.name = `${item.first_name || content.first_name} ${item.last_name || content.last_name}`;
    content.immediate_safety_plan = item.immediate_safety_plan || content.immediate_safety_plan;
    content.initiated_at = item.assessment_date || content.assessment_date;
    content.is_assessment_patient_sibling = item.is_assessment_patient_sibling;
    content.is_court_hearing_included = item.is_court_hearing_included || content.is_court_hearing_included;
    content.no_count = item.no_count || content.no_count;
    content.overall_risk_assessment = item.overall_risk_assessment || content.overall_risk_assessment;
    content.risk_code_id = item.risk_code_id || content.risk_code_id;
    content.unknown_count = item.unknown_count || content.unknown_count;
    content.yes_count = item.yes_count || content.yes_count;
    content.safety_assessment_questions = item.questions.map((item: any) => {
        return {
            question_id: item.question_id || content.question_id,
            selected_answer: item.selected_answer || content.selected_answer,
            status: item.status,
            status_meta: relationship.safetyAssessmentStatus(item) || content.status_meta,
            supporting_information: item.supporting_information || content.supporting_information,
        }
    });

    const date = new Date().toJSON();
    content.updated_at = date;
    content.updated_at_formatted = date;

	return JSON.stringify(content);
};

const setNewForm = (item: any, content: any) => {
	const data = <any>{};

    data.appointment = item.appointment || null;
    data.assessment_date = item.assessment_date || null;
    data.brief_family_background = item.brief_family_background || null;
    data.case_number = item.case_number || null;
    data.family_background = item.family_background || null;
    data.first_name = item.first_name || null;
    data.last_name = item.last_name || null;
    data.middle_name = item.middle_name || null;
    data.name = `${item.first_name || null} ${item.last_name || null}`;
    data.immediate_safety_plan = item.immediate_safety_plan || null;
    data.initiated_at = item.assessment_date || null;
    data.is_assessment_patient_sibling = item.is_assessment_patient_siblin;
    data.is_court_hearing_included = item.is_court_hearing_included;
    data.no_count = item.no_count || null;
    data.overall_risk_assessment = item.overall_risk_assessment || null;
    data.risk_code_id = item.risk_code_id || null;
    data.unknown_count = item.unknown_count || null;
    data.yes_count = item.yes_count || null;
    data.safety_assessment_questions = item.questions.map((item: any) => {
        return {
            question_id: item.question_id || null,
            selected_answer: item.selected_answer || null,
            status: item.status,
            status_meta: null,
            supporting_information: item.supporting_information || null
        }
    });

    const date = new Date().toJSON();
    data.created_at = date;
    data.created_at_formatted = date;

	return JSON.stringify(data);
};

export default class CaseSafetyAssessmentForm {
	content: string | null;
	encoded_by: number | null;
	action: number | null;
	is_sync: boolean;
	created_at: string | null;
	updated_at: string | null;
	deleted_at: string | null;
	id: string | null;
	cases_id: string | null;
	offline_cases_id: string | null;
	constructor(item: any, res: any) {
		this.id = item.id;
		this.cases_id = res?.id;
		this.offline_cases_id = "";
		this.content = JSON.stringify(item);
		this.encoded_by = res.encoded_by;
		this.action = OfflineAction.DEFAULT;
		this.is_sync = false;
		this.created_at = "";
		this.updated_at = "";
		this.deleted_at = "";
	}

	updateForm(item: any, res: any): object {
		const date = new Date().toJSON();
		const data = <any>{};
		data.id = res.id;
		if (!res.id) {
			data.offline_id = res.offline_id;
		}
		data.cases_id = res?.cases_id;
		data.offline_cases_id = res?.offline_cases_id;
		data.content = setUpdateForm(item, JSON.parse(res.content));
		data.encoded_by = 0;
		data.action = OfflineAction.UPDATE_RECORD;
		data.is_sync = false;
		data.created_at = res.created_at;
		data.updated_at = relationship.dateFormatFromIso(new Date());
		data.deleted_at = "";
        data.is_updated_offline = res.is_created_offline ? false : true;
        data.is_created_offline = res.is_created_offline;

		return data;
	}

	createForm(item: any, res: any): object {
		const data = <any>{};
		data.cases_id = res?.id;
		data.offline_cases_id = res?.offline_id;
		data.content = setNewForm(item, JSON.parse(res.content));
		data.encoded_by = 0;
		data.action = OfflineAction.NEW_RECORD;
		data.is_sync = false;
		data.created_at = relationship.dateFormatFromIso(new Date());
		data.updated_at = "";
		data.deleted_at = "";
        data.is_created_offline = res.is_created_offline ? res.is_created_offline : true;

		return data;
	}
}
