<template>
	<user-layout>
		<div 
			class="w-full md:p-6 p-4 mx-auto my-6 border border-gray-200 rounded"
			v-if="viewSearch"
		>
			<div class="flex items-center justify-between">
				<section-title title="Search Perpetrator" />
			</div>
			<form class="grid grid-cols-6 gap-6 mt-6">
				<div class="col-span-6 border-t border-gray-200"></div>

				<div class="col-span-6">
					<div class="flex flex-1 md:space-x-6 md:space-y-0 space-y-3 md:flex-row flex-col">
						<div class="md:w-1/3 w-full">
							<text-input
								type="text"
								label="First Name"
								placeholder="First name"
								name="first_name"
								id="first_name"
								v-model="form.first_name"
								:error="errors?.first_name" />
						</div>
						<div class="md:w-1/3 w-full">
							<text-input
								type="text"
								label="Last Name"
								placeholder="Last name"
								name="last_name"
								id="last_name"
								v-model="form.last_name"
								:error="errors?.last_name" />
						</div>
						<div class="md:w-1/3 w-full">
							<date-picker
								id="birthdate"
								name="birthdate"
								label="Birthdate"
								v-model="form.birthdate"
								:error="errors?.birthdate" />
						</div>
						<div class="shrink-0 md:pt-[26px] pt-3">
							<button-component 
								customClass="quaternary md:w-auto w-full"
								size="large"
								@click="search()"
							>
								<!-- <img
									src="/assets/icon/ic-search-2.svg"
									alt="search_icon"
									class="object-contain w-5 h-5 mr-1 icon" /> -->
								<search-two-icon class="object-contain w-5 h-5 mr-1 icon" />
								<span>Search</span>
							</button-component>
						</div>
					</div>
				</div>
			</form>
		</div>

		<div 
			v-else
			class="w-full md:p-6 p-4 mx-auto my-6 border border-gray-200 rounded"
		>
			<div class="flex items-center justify-between mb-6 md:flex-row flex-col md:space-y-0 space-y-6">
				<section-title
					title="Search Perpetrator"
					description="View the data analytics in the system."
					class="md:w-auto w-full"
				/>
				<div class="flex items-center space-x-3 md:w-auto w-full">
					<button-component 
						@click="viewSearch = true"
						customClass="quaternary md:w-auto w-full"
						size="large"
					>
						<!-- <img
							src="/assets/icon/ic-search-2.svg"
							alt="search_icon"
							class="object-contain w-5 h-5 mr-1 icon" /> -->
						<search-two-icon class="object-contain w-5 h-5 mr-1 icon" />
						<span>Search</span>
					</button-component>
				</div>
			</div>

			<div>
				<data-table
					:headers="headers"
					:no-action="false"
					:count="items?.length"
					:hasSearch="false"
					:search="filterSearch"
					@update:searchText="(value: string) => (filterSearch = value)"
					searchPlaceholder="Search perpetrator name"
					tableClass="header-frm-table five"
				>
					<template #topContent>
						<div>
							<button
								type="button"
								class="flex items-center px-3 py-2 space-x-1 transition border border-solid rounded border-neutral-100 hover:bg-primary-100 w-max"
								@click="showFilter = true">
								<span class="text-xs font-medium text-neutral-600">Showing:</span>
								<span class="text-xs font-semibold">All Records</span>
							</button>
							<FilterPanel
								:show="showFilter"
								@close="showFilter = false"
								@apply:reset="applyReset()"
								@apply:filters="applyFilters()">
								<template #fields>
									<div class="space-y-5">
										<date-picker
											id="date_created"
											name="date_created"
											label="Date Created"
											:range="true"
											v-model="filterDate" />
										<select-options
											:options="genderOptions"
											label="Gender"
											id="gender"
											name="gender"
											placeholder="Select.."
											:modelValue="filterGender"
											@update:modelValue="(newValue) => (filterGender = newValue)" />
									</div>
								</template>
							</FilterPanel>
						</div>
					</template>
					<template #body>
						<template v-for="item in items" :key="item">
							<tr>
								<td class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
									{{ item.name }}
								</td>
								<td class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
									{{ item.birthdate_formatted }}
								</td>
								<td class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
									{{ item.gender_meta?.description }}
								</td>
								<td class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
									{{ item.identity_meta?.description }}
								</td>
								<td class="px-6 py-6 text-sm text-center text-gray-900 whitespace-nowrap">
									{{ item.created_at_formatted }}
								</td>
								<td class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
									<div class="flex items-center justify-center mx-auto">
										<view-button
											v-if="hasPermission('can-view-perpetrator')"
											@click="
												router.replace(
													`/perpetrator/${item.id}/view`
												)
											"></view-button>
										<!-- <delete-button /> -->
									</div>
								</td>
							</tr>
						</template>
					</template>
					<template #tfoot>
						<div class="w-full px-6 border-t py-7">
							<minify-pagination
								:numRows="numRows"
								@update:rows="(value: any) => changeRows(value)"
								@update:page="(value: number) => paginate(value)"
								:current-page="page"
								:last-page="lastPage"></minify-pagination>
						</div>
					</template>
				</data-table>
			</div>
		</div>
		
		<page-loader :show="loading"/>

	</user-layout>
</template>
<script setup lang="ts">
import { onMounted, ref, watch, computed } from "vue";
import UserLayout from "@/layouts/UserLayout.vue";
import SectionTitle from "@/components/partials/SectionTitle.vue";
import ViewButton from "@/components/buttons/ViewButton.vue";
import DataTable from "@/components/partials/DataTable.vue";
import FilterPanel from "@/components/partials/FilterPanel.vue";
import MinifyPagination from "@/components/partials/MinifyPagination.vue";
import SelectOptions from "@/components/inputs/SelectOptions.vue";
import DatePicker from "@/components/inputs/DatePicker.vue";
import { useRouter, useRoute } from "vue-router";
import PerpetratorService from "@/classes/PerpetratorService";
import throttle from "lodash/throttle";
import pickBy from "lodash/pickBy";
import PageLoader from "@/components/loaders/PageLoader.vue"
import { hasPermission } from "@/classes/AuthService";
import { useNetworkStore } from "@/store/network";
import DB from "@/classes/Databases/Crud";
import Gender from "@/enums/Gender";
import { PerpetratorSearch } from "@/interfaces/Cases/Perpetrator";
import TextInput from "@/components/inputs/TextInput.vue";
import ButtonComponent from "@/components/buttons/ButtonComponent.vue";
import { errorResponse } from "@/helpers/errorMessage";
import { onIonViewWillEnter } from "@ionic/vue";
import SearchTwoIcon from "@/components/icons/searchTwoIcon.vue";

const networkStore = useNetworkStore();
const isNetworkAvailable = computed(() => networkStore.isNetworkAvailable);

const loading = ref<boolean>(false);
const showFilter = ref<boolean>(false);
const router = useRouter();
const route = useRoute();

/**
 * VARIABLES
 */
const items = ref<any>(null);
const filterSearch = ref<any>(route.query.query);
const filterDate = ref<any>(route.query.date);
const filterGender = ref<any>(route.query.gender);
const page = ref<any>(route.query.page);
const lastPage = ref<any>(null);
const rows = ref<any>(route.query.rows);
const numRows = computed(() => (rows.value ? rows.value + " Rows" : "All"));
const genderOptions = ref([]);
const offlineParams = ref<string>(route.query.offline);
const errors = ref<any>({});
const viewSearch = ref<boolean>(true);

const headers: { text: string; haveSort?: boolean }[] = [
	{ text: "Perpetrator Name", haveSort: true },
	{ text: "Birthday", haveSort: true },
	{ text: "Gender", haveSort: true },
	{ text: "Type", haveSort: true },
	{ text: "Date Created", haveSort: true },
];

const form = ref<PerpetratorSearch>({
	last_name: null,
	first_name: null,
	birthdate: null,
});

/**
 * METHODS
 */
const getData = async (search: any = null): Promise<any> => {
	loading.value = true

    const response = await PerpetratorService.index(
		pickBy({
			...{
				...form.value,
				query: search ?? filterSearch.value,
				date: filterDate.value,
				gender: filterGender.value?.toString(),
				page: page.value,
				rows: rows.value,
			},
		}),
		isNetworkAvailable.value
	);

    if (response?.status === 200 || response?.data != undefined) {
        if (isNetworkAvailable.value) {
            items.value = response.data.items.data;
            genderOptions.value = response.data.genders;
            page.value = response.data.items.current_page;
            lastPage.value = response.data.items.last_page;
        } else {
            genderOptions.value = Gender;
            response.data.items.data = response.data.items.data?.map((item) => {
                return {
                    ...JSON.parse(item.content),
                    ...{ id: item.id, cases_id: item.cases_id }
                    // ...{ offlineData: { id: item.id, offline_id: item.offline_id } },
                };
            });
            items.value = response.data.items.data;
            page.value = response.data.item.current_page;
            lastPage.value = response.data.item.last_page;
        }

        loading.value = false;
        viewSearch.value = false;
    } else {
        const error = response;
        loading.value = false;
        errors.value = errorResponse(error.response?.data?.errors)
    }
};

const reloadPage = async () => {
	return await router.push({
		path: "/perpetrator/index",
		query: pickBy({
			query: filterSearch.value,
			date: filterDate.value,
			gender: filterGender.value?.toString(),
			page: page.value,
			rows: rows.value,
		}),
	});
};

const applyFilters = () => {
	reloadPage().then(() => {
		showFilter.value = false;
		getData();
	});
};

const applyReset = () => {
	filterDate.value = "";
	filterGender.value = "";
	reloadPage().then(() => {
		getData();
		showFilter.value = false;
	});
};

const paginate = (data: number): void => {
	page.value = data;
	applyFilters();
};

const changeRows = (row: any): void => {
	rows.value = row;
	applyFilters();
};

const search = async (): Promise<any> => {
	await getData();
}

const resetForm = () => {
    form.value.last_name = null;
    form.value.first_name = null;
    form.value.birthdate = null;
}

/**
 * WATCHERS
 */
watch(
	filterSearch,
	throttle((val: string) => {
		reloadPage().then(() => {
			getData(val);
		});
	}, 1500)
);

// watch(
// 	() => isNetworkAvailable.value,
// 	(val) => {
// 		console.log("Watcher - Network: " + val);
// 		getData();
// 	}
// );


onIonViewWillEnter(() => {
    resetForm();
});
</script>
