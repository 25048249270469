<template>
    <div class="flex flex-col space-y-3 md:flex-row md:space-x-2 md:space-y-0" :class="customClass" id="birthday_classification">
        <div class="w-full md:w-1/2">
            <date-picker
                ref="datePickerData"
                id="birthdate"
                name="birthdate"
                label="Birthdate"
                v-model="form.birthdate"
                :error="errors?.birthdate"
                :disabled="disabledBirthday || disabled"
                :maxDate="maxDate" 
                @update:modelValue="value => (form.birthdate = value)"
            />
        </div>
        <div class="w-full md:w-1/2">
            <text-input
                type="number"
                label="Age"
                placeholder="Age"
                name="approximate_age"
                id="approximate_age"
                v-model="computedAge"
                :disabled="true"
            />
        </div>
    </div>

    <div :class="customClass">
        <div class="flex flex-col w-full md:flex-row md:space-x-2">
            <text-input
                type="number"
                label="Approximate Age"
                placeholder="Years"
                name="approximate_age"
                id="approximate_age"
                v-model="form.approximate_age_year"
                :error="errors?.approximate_age_year || errorMessageYear"
                @input="handleInputYear"
                :disabled="disabledApproximateAge || disabled" />
            <div class="relative w-full -mt-4 md:mt-0">
                <text-input
                    type="number"
                    label="‎"
                    placeholder="Months"
                    @input="handleInputMonth"
                    name="approximate_age_months"
                    id="approximate_age_months"
                    v-model="form.approximate_age_month"
                    :error="errors?.approximate_age_month || errorMessage "
                    :disabled="disabledApproximateAge || disabled"
                />
                <!-- <span v-if="errorMessage && !disabledApproximateAge" class="absolute left-0 z-10 text-xs text-red-600 -bottom-5">
                    {{ errorMessage }}
                </span> -->
            </div>
        </div>
    </div>

</template>

<script setup lang="ts">
import { ref, computed, type PropType, onMounted} from "vue";
import { onIonViewWillEnter } from "@ionic/vue";

import DatePicker from "@/components/inputs/DatePicker.vue";
import TextInput from "@/components/inputs/TextInput.vue";
import { errorResponse } from "@/helpers/errorMessage";
import { BirthdayClassification } from "@/views/enums/BirthdayClassification";

interface FormInterface {
    birthdate: string|null;
    birthday_classification: number|null;
    approximate_age_month: string|null;
    approximate_age_year: string|null;
}

const props = defineProps({
    form: {
        type: Object,
        required: true,
    },
    errors: {
        type: Object,
        required: true,
    },
    customClass: {
        type: String,
        default: 'col-span-3',
    },
    disabled: {
        type: Boolean,
        default: false,
    },
});

const form = ref<FormInterface>(props.form);
const datePickerData = ref<any>(null);

const computedAge = ref<number|null>(null);

const maxDate = () => {
	return new Date();
};

const calculateAge = ():void => { 
    // Calculate the difference in milliseconds between the current date and the provided date of birth
    const diff_ms = Date.now() - (new Date(form.value.birthdate)).getTime();
    // Create a new Date object representing the difference in milliseconds and store it in the variable age_dt (age Date object)
    const age_dt = new Date(diff_ms); 
	
    // Calculate the absolute value of the difference in years between the age Date object and the year 1970 (UNIX epoch)
    computedAge.value = Math.abs(age_dt.getUTCFullYear() - 1970);
    form.value.approximate_age = computedAge.value;
}

const processBirthdate = ():void => {
	form.value.birthday_classification = BirthdayClassification.BIRTHDATE
	form.value.approximate_age_month = null;
	form.value.approximate_age_year = null;
	calculateAge();
}

const processApproximateAge = ():void => {
	form.value.birthday_classification = BirthdayClassification.APPROXIMATE_AGE
	form.value.birthdate = null;
	computedAge.value = null;
    form.value.approximate_age = null;
}

const backToDefaultAge = ():void => {
	form.value.approximate_age_year = null
	form.value.approximate_age_month = null
	computedAge.value = null;
    form.value.approximate_age = null;
}

const approxMonthMaxLength = ref(2); // Adjust this to your desired maximum length
const errorMessage = ref('');
const handleInputMonth = (event: Event) => {
    const inputEl = event.target as HTMLInputElement;
    if (inputEl.value.length > approxMonthMaxLength.value) {
        form.value.approximate_age_month = inputEl.value.slice(0, approxMonthMaxLength.value);
    }

    errorMessage.value = validateNumber(form.value.approximate_age_month);
};

const validateNumber = (value: string): string => {
    const parsedValue = parseInt(value, 10);
    if ( parsedValue < 0 || parsedValue > 12) {
        form.value.approximate_age_month = ''
        return 'Invalid input: Must be a number between 0 - 12.';
    }

    return ''; // Clear error message if valid
};

const approxYearMaxLength = ref(4); // Adjust this to your desired maximum length
const errorMessageYear = ref('')
const handleInputYear = (event: Event) => {
    const inputEl = event.target as HTMLInputElement;
    
    if (inputEl.value.length > approxYearMaxLength.value) {
        form.value.approximate_age_year = inputEl.value.slice(0, approxYearMaxLength.value);
    }

    if (inputEl.value.includes('.')) {
        errorMessageYear.value = 'Invalid input: Must be a whole number.';
        form.value.approximate_age_year = ''
    }
};

const disabledApproximateAge= computed<boolean>(():boolean => {
	if(form.value.birthdate){
		processBirthdate()
		return true;
	} else if ( 
        (form.value.approximate_age_month === "" && form.value.approximate_age_year === "") ||
        (form.value.approximate_age_month == null && form.value.approximate_age_year == null)
    ) { 
		backToDefaultAge()
		return false;
	}

	return false;
})

const disabledBirthday = computed<boolean>(():boolean => {
	if(
        (form.value.approximate_age_month === "" && form.value.approximate_age_year === "") ||
        (form.value.approximate_age_month == null && form.value.approximate_age_year == null)
    ) { 
		backToDefaultAge()
		return false;
	} else {
		processApproximateAge()
		return true;
	}
})

const clearForm = ():void => {
    // console.log(form.value);
    // form.value.birthdate = null;
    // form.value.approximate_age_year = null;
    // form.value.approximate_age_month = null;
}

defineExpose({
    datePickerData
})

onIonViewWillEnter(async () => {
    clearForm()
});

onMounted(async () => {
    clearForm()
});

</script>