const CaseCompanions = {
	name: "intake_personnal_informations",
	schema: [
		{ column: "id", value: "INTEGER  PRIMARY KEY AUTOINCREMENT" },
		{ column: "cases_id", value: "INTEGER UNSIGNED" },
        { column: "content", value: "LONGTEXT NOT NULL" },
		{ column: "encoded_by", value: "INTEGER UNSIGNED" },
		{ column: "action", value: "INT UNSIGNED" },
		{ column: "is_sync", value: "BOOLEAN DEFAULT FALSE" },
        { column: "is_sync_failed", value: "BOOLEAN DEFAULT FALSE"},
        { column: "is_created_offline", value: "BOOLEAN DEFAULT FALSE" },
        { column: "is_updated_offline", value: "BOOLEAN DEFAULT FALSE" },
        { column: "is_deleted_offline", value: "BOOLEAN DEFAULT FALSE" },
        { column: "created_at", value: "TIMESTAMP" },
		{ column: "updated_at", value: "TIMESTAMP" },
		{ column: "deleted_at", value: "TIMESTAMP" },
        { column: "synching_remarks", value: "TEXT DEFAULT NULL" },
	],
};

export default CaseCompanions;
