<template>
    <user-layout
        :hasBack="true"
        backLink="/settings/form-options/view"
    >
        <div class="max-w-[500px] mx-auto py-6">
            <form-section
                :form="form"
                :errors="errors"
            >
                <div class="col-span-2">
                    <div class="flex items-center justify-end w-full space-x-3">
                        <a  @click="back()">
                            <button-component
                                btnWidth="quinary"
                               
                            >Cancel</button-component>
                        </a>

                        <button-component
                            btnWidth="primary"
                            @click="save"
                        >Save Changes</button-component>
                    </div>
                    <confirmation-modal
                        :typeTwo="true"
                        :show="showConfirmation"
                        @cancel="showConfirmation = false"
                        @confirm="showSuccess = true; showConfirmation = false"
                        cancel-text="Cancel"
                        action-text="Confirm"
                        title="Save Record"
                        content="Are you sure you wish to save this record?"
                    />
                    <success-modal
                        :show="showSuccess"
                        @confirm="back()"
                        action-text="Close"
                        title="Record Save!"
                        content="Data has been successfully created"
                    />
                </div>
            </form-section>
        </div>
    </user-layout>
</template>
<script setup lang="ts">
import { ref } from 'vue';
import UserLayout from '@/layouts/UserLayout.vue';
import FormSection from './FormSection.vue';
import ButtonComponent from '@/components/buttons/ButtonComponent.vue';

import ConfirmationModal from '@/components/modals/ConfirmationModal.vue';
import SuccessModal from '@/components/modals/SuccessModal.vue';

import { useRoute, useRouter } from "vue-router";

import { errorResponse } from "@/helpers/errorMessage";

import pickBy from "lodash/pickBy";
import assignValues from "@/helpers/assignValues"

import FormOptions from '@/classes/Settings/FormOptions'

const route = useRoute();
const router = useRouter();

const loading = ref<boolean>(false);
const errors = ref<any>();

const showConfirmation = ref<boolean>(false);
const showSuccess = ref<boolean>(false);


const form = ref<{
    name: string|null,
    type: string,
}>({
    name: null,
    type: route.params.option
});

const back = (): void => {
    router.replace(`/settings/form-options/view/${form.value.type}`)
}

const save = (): void => {
    loading.value = true;

    FormOptions.store(form.value.type, pickBy(form.value))
        .then((response: any) => {
            loading.value = false;
            showSuccess.value = true;
            showConfirmation.value = false
        })
        .catch((error: any) => {
            loading.value = false;
            showConfirmation.value = false
            errors.value = errorResponse(error.response?.data?.errors)
        });
}

</script>