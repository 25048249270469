<template>
    <data-table 
        :headers="headers" 
        noAction
        :count="items.total"
        :hasSearch="true"
        searchPlaceholder="Search Activity"
        tableClass="header-frm-table"
        dataTableClass="border-0"
    >
        <template #topContent>
            <div>
                <button type="button" class="flex items-center px-3 py-2 space-x-1 transition border border-solid rounded border-neutral-100 hover:bg-primary-100 w-max"
                @click="showFilter = true">
                    <span class="text-xs font-medium text-neutral-600">Showing:</span>
                    <span class="text-xs font-semibold">All Records</span>
                </button>
                <FilterPanel
                    :show="showFilter"
                    @close="showFilter = false"
                    @apply:reset="applyReset()"
                    @apply:filters="applyFilters()">
                >
                    <template #fields>
                        <div class="space-y-5">
                            <date-picker 
                                id="date_created"
                                name="date_created"
                                label="Date Created"
                                :range=true
                            />
                        </div>
                    </template>
                </FilterPanel>
            </div>
        </template>
        <template v-slot:body>
            <template 
                v-for="item in items.data" :key="item"
            >
                <tr>
                    <td class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
                        {{ item.id }}
                    </td>
                    <td class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
                        {{ item.event }}
                    </td>
                    <td class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
                        {{ item.description }}
                    </td>
                    <td class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
                        {{ item.causer_name }}
                    </td>
                    <td class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
                        {{ item.created_at_formatted }}
                    </td>
                </tr>
            </template>
        </template>
        <!-- <template #tfoot>
            <div class="w-full px-6 border-t py-7">
                <simple-pagination 
                    :numRows="filters.rows" 
                    :currentPage="items.current_page" 
                    :last-page="items.last_page" 
                    @update="(value: any) => paginate(value)"
                />
            </div>
        </template> -->
    </data-table>
</template>

<script setup lang="ts">
import { ref } from "vue";

import DataTable from '@/components/partials/DataTable.vue';
import FilterPanel from '@/components/partials/FilterPanel.vue';
import SimplePagination from "@/components/partials/SimplePagination.vue";
import DatePicker from "@/components/inputs/DatePicker.vue";
import SelectOptions from "@/components/inputs/SelectOptions.vue";

const props = defineProps({
    items: {
        type: Object,
        defualt: [],
        required: true,
    },
});


const headers: { text: string, haveSort?: boolean }[] = [
    { text: 'ID' },
    { text: 'Subject'},
    { text: 'Description' },
    { text: 'Caused By' },
    { text: 'Date Created' },
];
</script>