import { defineStore } from 'pinia'
import Storage from '@/helpers/storage';

interface INetworkState {
    isNetworkAvailable: boolean
}

const storage = new Storage();

export const useNetworkStore = defineStore({
    id: 'network',
    state: (): INetworkState => {
        console.log('hello');
        return {
            isNetworkAvailable: storage.get('isNetworkAvailable') == 'true' ? true : false,
        }
    },
    getters: {},
    actions: {},
})