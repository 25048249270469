import http from "@/axios";
import { Perpetrator } from "@/interfaces/Cases/Perpetrator";
import CasePerpetratorOfflineForm from "../Forms/Cases/CasePerpetratorOfflineForm";
import DB from "@/classes/Databases/Crud";
import SqliteRecordChecker from "../Forms/SqliteRecordChecker";
import PaginationPerpetrator from "../Databases/PaginationPerpetrator";
import { items } from "@/interfaces/pages/FilterPageInterface";
import OfflineCasePerpetrator from "../Forms/Cases/OfflineCasePerpetrator";

const db = new DB("case_perpetrators");
const dbCase = new DB("cases");
const dbPerpetrator = new DB("perpetrators", new PaginationPerpetrator("perpetrators"));
const checkRecord = new SqliteRecordChecker();
const dbDraft = new DB("drafts");

class PerpetratorService implements Perpetrator {
	async getPatient(patient: number, patientCase: number, networkStatus = true): Promise<any> {
		if (networkStatus) {
			return await http.get(`patients/${patient}/cases/${patientCase}/perpetrators/get-patient`).catch(error => error);
		}

		return;
	}

	async index(patient: number, patientCase: number, payload: object, networkStatus = true): Promise<any> {
		if (networkStatus) {
			return await http.get(`patients/${patient}/cases/${patientCase}/perpetrators`, { params: payload }).catch(error => error);
		}

		return await dbPerpetrator.index(payload, '', true, 'perpetrators');
	}

	async lists(patient: number, patientCase: number, payload: any, networkStatus = true): Promise<any> {
		if (networkStatus) {
			return await http.get(`patients/${patient}/cases/${patientCase}/perpetrators/lists`, { params: payload }).catch(error => error);
		}
        const response = await db.select(`SELECT * FROM case_perpetrators WHERE cases_id=${patientCase} AND (deleted_at = "" OR deleted_at IS NULL)`);
		return await dbPerpetrator.index(payload, ` WHERE id IN(${response.map((item: any) => item.perpetrator_id)})`, true, 'perpetrators');
	}

	async link(patient: number, patientCase: any, payload: any, networkStatus = true): Promise<any> {
		if (networkStatus) {
			return await http.post(`patients/${patient}/cases/${patientCase}/perpetrators/link`, payload).catch(error => error);
		}

        return await OfflineCasePerpetrator.linkPerpetratorToCase(patient, patientCase, payload);
	}

	async create(patient: number, patientCase: number, payload: object, networkStatus = true): Promise<any> {
		if (networkStatus) {
			return await http.get(`patients/${patient}/cases/${patientCase}/perpetrators/create`, { params: payload }).catch(error => error);
		}
	}

	async store(patient: number, patientCase: any, payload: any, networkStatus = true): Promise<any> {
        patientCase = parseInt(patientCase);
		if (networkStatus) {
			return await http.post(`patients/${patient}/cases/${patientCase}/perpetrators`, payload).catch(error => error);
		}
		return await OfflineCasePerpetrator.createCasePerpetrator(patient, patientCase, payload);
	}

	async edit(
		patient: number,
		patientCase: number,
		perpetrator: number,
		payload: any,
		networkStatus = true
	): Promise<any> {
		if (networkStatus) {
			return await http.get(`patients/${patient}/cases/${patientCase}/perpetrators/${perpetrator}/edit`, {
				params: payload,
			}).catch(error => error);
		}

        return await dbPerpetrator.edit(perpetrator, false, 'perpetrators');
	}

	async update(
		patient: number,
		patientCase: any,
		perpetrator: number,
		payload: any,
		networkStatus = true
	): Promise<any> {
		if (networkStatus) {
			return await http.put(
				`patients/${patient}/cases/${patientCase}/perpetrators/${perpetrator}/update`,
				payload
			).catch(error => error);
		}

        return await OfflineCasePerpetrator.updateCasePerpetrator(patient, patientCase, perpetrator, payload);
	}

	async delete(
		patient: number,
		patientCase: any,
		perpetrator: number,
		payload: any,
		networkStatus = true
	): Promise<any> {
		if (networkStatus) {
			return await http.delete(
				`patients/${patient}/cases/${patientCase}/perpetrators/${perpetrator}/delete`,
				payload
			).catch(error => error);
		}

        return await OfflineCasePerpetrator.deleteCasePerpetrator(patient, patientCase, perpetrator, payload);
	}
}

export default new PerpetratorService();
