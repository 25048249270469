
import { BasePageInterface } from "@/interfaces/pages/BasePageInterface";

export default class BaseIndexFilters implements BasePageInterface {
    tab: string | null
    query: string
    current_page: number
    activityLogsCount: number
    archiveItemsCount?: number
    allItemsCount:  number
    page: number
    rows: number
    selectedTab:  string| null
    filterQuery: string
    filterDate: string
    filterLogEvent: string
    filterLogQuery: string
    items?: Array<any>

    constructor(public item?: BasePageInterface) {
        this.tab = item?.tab || null
        this.query = item?.query || ''
        this.current_page = item?.current_page || 1
        this.activityLogsCount = item?.activityLogsCount || 0
        this.archiveItemsCount = item?.archiveItemsCount || 0
        this.allItemsCount = item?.allItemsCount|| 0
        this.page = item?.page || 1
        this.rows = item?.rows || 5
        this.selectedTab = item?.selectedTab || ''
        this.filterQuery = item?.filterQuery || ''
        this.filterDate = item?.filterDate || ''
        this.filterLogEvent = item?.filterLogEvent || ''
        this.filterLogQuery = item?.filterLogQuery || ''
    }
}