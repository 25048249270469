<template>
	<form id="form" class="grid grid-cols-6 gap-6 mt-6">
		<div class="col-span-6 border-t border-gray-200"></div>
		<!-- <div class="col-span-6">
			<checkbox-input
				id="no_guardian"
				name="no_guardian"
				label="No Guardian"
			/>
		</div> -->
		<div class="lg:col-span-2 md:col-span-3 col-span-6">
			<text-input
				type="text"
				label="Last Name"
				placeholder="Last name"
				name="last_name"
				id="last_name"
				required
				v-model="form.last_name"
				:error="errors?.last_name" />
		</div>
		<div class="lg:col-span-2 md:col-span-3 col-span-6">
			<text-input
				type="text"
				label="First Name"
				placeholder="First name"
				name="first_name"
				id="first_name"
				required
				v-model="form.first_name"
				:error="errors?.first_name" />
		</div>
		<div class="lg:col-span-2 md:col-span-3 col-span-6">
			<text-input
				type="text"
				label="Middle Name"
				placeholder="Middle name"
				name="middle_name"
				id="middle_name"
				v-model="form.middle_name"
				:error="errors?.middle_name" />
		</div>
		<div class="lg:col-span-2 md:col-span-3 col-span-6">
			<label class="block text-xs font-semibold text-neutral-600"
				>Sex <span class="text-red-600">*</span></label
			>
			<div class="flex items-center md:pt-5 pt-2 space-x-6">
				<radio-group :options="genders" name="gender" required v-model="form.gender" :error="errors?.gender" />
			</div>
			<p
				v-if="errors?.gender"
				class="text-[10px] mt-1"
				:class="errors?.gender ? 'text-red-600' : 'text-gray-800'"
				id="email-error">
				{{ errors?.gender }}
			</p>
		</div>

		<UserBirthdateAge 
			:form="form"
			:errors="errors"
			customClass="lg:col-span-2 md:col-span-3 col-span-6"
		/>

		<div class="lg:col-span-2 md:col-span-3 col-span-6">
			<multi-select searchable
				:options="guardianRelationships"
				id="relationship"
				name="relationship"
				label="Relationship"
				placeholder="Select.."
				required
				v-model="form.guardian_relationship_id"
				:error="errors?.guardian_relationship_id" />
		</div>
		<div class="lg:col-span-2 md:col-span-3 col-span-6">
			<text-input
				type="text"
				label="Other Relationship"
				placeholder="Other Relationship"
				name="other_relationship"
				id="other_relationship"
				v-model="form.other_relationship"
				:error="errors?.other_relationship" />
		</div>
		<div class="lg:col-span-2 md:col-span-3 col-span-6">
			<text-input
				type="text"
				label="Contacts"
				placeholder="Contacts"
				name="contacts"
				id="contacts"
				:add_on_left="true"
				add_on_text="+63"
				:maxlength="10"
				@keypress="numberonly"
				v-model="form.contacts"
				:error="errors?.contacts" />
		</div>
	</form>
</template>
<script setup lang="ts">
import TextInput from "@/components/inputs/TextInput.vue";
import MultiSelect from "@/components/inputs/MultiSelect.vue";
import CheckboxInput from "@/components/inputs/CheckboxInput.vue";
import RadioGroup from "@/components/inputs/RadioGroup.vue";
import DatePicker from "@/components/inputs/DatePicker.vue";
import { ref, type PropType, computed } from "vue";
import { renderSelectV2, renderSelect } from "@/interfaces/DropdownInterface";
import { GuardianCreate } from "@/interfaces/Cases/Guardian";
import { numberonly } from "@/components/composables/useNumberOnly";

import UserBirthdateAge from '@/components/forms/UserBirthdateAge.vue'

const props = defineProps({
	form: {
		type: Object as PropType<GuardianCreate>,
		required: true,
	},
	errors: {
		type: Object,
		required: true,
	},
	genders: {
		type: [] as PropType<renderSelectV2>,
		default: [],
	},
	guardianRelationships: {
		type: [] as PropType<renderSelect>,
		default: [],
	},
});

const form = ref<GuardianCreate>(props.form);
</script>
