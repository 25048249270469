import http from "@/axios";
import DB from "@/classes/Databases/Crud";

const dbPatient = new DB("patients");
const dbCasePerpetrators = new DB("case_perpetrators");
export default class SyncCasePerpetrators {
    
    resetItemData(item: any) {
        item.synching_remarks = null;
        item.is_sync_failed = false;
        item.is_sync = true;
        item.is_created_offline = false;
        item.is_updated_offline = false;
        item.is_deleted_offline = false;

        return item;
    }

    async rollback(item: any, response: any) {
        const error = response?.response?.data;
        item.synching_remarks = error.message || 'System Error';
        item.is_sync_failed = true;
        await dbPatient.insert(item, item.id, true, 'case_perpetrators');

        return true;
    }

    async processForm(item: any, form: any) {
        let hasError = false;

        if (!item.is_deleted_offline) {
            const response = await http.post('/attemp-synching-case-perpetrator-create', form).catch(error => error);
            if (response?.status === 200) {
                const oldPatientId = item.id;
                item = this.resetItemData(item);
                await dbCasePerpetrators.insert(item, oldPatientId, true, 'case_perpetrators');
                hasError = false;
            } else {
                hasError = await this.rollback(item, response);
            }
        }

        if (item.is_deleted_offline) {
            const response = await http.post('/attemp-synching-case-perpetrator-delete', form).catch(error => error);
            if (response?.status === 200) {
                await dbCasePerpetrators.delete({id: item.id}, 'case_perpetrators');
                hasError = false;
            } else {
                hasError = await this.rollback(item, response);
            }
        }

        return hasError;
    }
}