<template>
    <form class="grid grid-cols-6 gap-6">
        <!-- <div v-if="!enableEdit" class="flex items-center col-span-3 space-x-1">
            <text-input
                label="Password"
                placeholder="**********"
                name="password"
                id="password"
                readonly
            />
            <button-component
                type="button"
                size="large"
                btnWidth="rounded mt-6 py-[13px]"
                @click="enableEdit = true"
            >
                Edit
            </button-component>
        </div> -->
        
        <!-- <div v-if="enableEdit" class="col-span-6"> -->
        <div class="col-span-6">
            <div class="grid grid-cols-6 gap-6">
                <div class="md:col-span-3 col-span-6">
                    <password-input
                        label="Current Password"
                        placeholder="Current Password"
                        name="current-password"
                        id="current-password"
                        v-model="form.current_password"
                        :error="errors?.current_password"
                    />
                </div>
                <div class="col-span-3 md:block hidden" />
                <div class="md:col-span-3 col-span-6">
                    <password-input
                        label="New Password"
                        placeholder="New Password"
                        name="new-password"
                        id="new-password"
                        v-model="form.new_password"
                        :error="errors?.new_password"
                    />
                    <!-- <p class="mt-1 text-xs text-neutral-600">
                        Password Strength: 
                        <span class="font-semibold" 
                            :class="[
                                passwordStrength == 1 
                                ? 'text-green-600' 
                                : passwordStrength == 2
                                ? 'text-yellow-600' 
                                : passwordStrength == 3
                                ? 'text-red-600' : '', 
                            ]"
                        >Strong</span>
                    </p> -->
                </div>
                <div class="col-span-3 md:block hidden" />
                <div class="md:col-span-3 col-span-6">
                    <password-input
                        label="Retype New Password"
                        placeholder="Retype New Password"
                        name="confirm-password"
                        id="confirm-password"
                        v-model="form.new_password_confirmation"
                        :error="errors?.new_password_confirmation"
                    />
                    <!-- <p class="mt-1 text-xs font-semibold"
                        :class="[
                            passwordMatch == 1 
                            ? 'text-green-600'
                            : passwordMatch == 2
                            ? 'text-red-600' : '', 
                        ]"
                    >
                        Password Match
                    </p> -->
                    <!-- <a href="/forgot-password" class="inline-block mt-3 text-xs transition text-primary-600 hover:text-primary-800" >
                        Forgotten your Password?
                    </a> -->
                </div>
        
                <div class="col-span-3 md:block hidden" />
                <div class="md:col-span-3 col-span-6 border-t border-gray-200"></div>
                <div class="col-span-3 md:block hidden" />
                <div class="md:col-span-3 col-span-6">
                    <div class="flex items-center justify-end w-full space-x-3">
                        <a href="/personnel/index/">
                            <button-component
                                btnWidth="quinary"
                            >Cancel</button-component>
                        </a>

                        <button-component
                            btnWidth="primary"
                            @click="showConfirmation = true"
                        >Save Changes</button-component>
                    </div>
                </div>
            </div>
        </div>

        <confirmation-modal
            :typeTwo="true"
            :show="showConfirmation"
            @cancel="showConfirmation = false"
            @confirm="submit()"
            cancel-text="Cancel"
            action-text="Confirm"
            title="Save Details"
            content="Are you sure you wish to save this detail?"
        />
        <success-modal
            :show="showSuccess"
            @confirm="confirm() "
            action-text="Close"
            title="Password Updated!"
            content="Password has been successfully updated"
        />
        <page-loader :show="loading"/>
    </form>
</template>
<script setup lang="ts">
import { ref } from 'vue';
import TextInput from '@/components/inputs/TextInput.vue';
import PasswordInput from '@/components/inputs/PasswordInput.vue';
import ButtonComponent from '@/components/buttons/ButtonComponent.vue';

import ConfirmationModal from '@/components/modals/ConfirmationModal.vue';
import SuccessModal from '@/components/modals/SuccessModal.vue';


import Profile from "@/classes/Profile";
import { errorResponse } from "@/helpers/errorMessage";

import PageLoader from "@/components/loaders/PageLoader.vue"

import { useRouter } from 'vue-router';
const router = useRouter();

const showConfirmation = ref<boolean>(false);
const showSuccess = ref<boolean>(false);
const loading = ref<boolean>(false);

const errors = ref<any>();

const form = ref<{
    current_password: string | null;
    new_password: string | null;
    new_password_confirmation: string | null;
}>({
    current_password: null,
    new_password: null,
    new_password_confirmation: null,
});


const submit = (): void => {
    showConfirmation.value = false
    loading.value = true
    Profile.editPassword(form.value).then(() => {
        showSuccess.value = true;
        loading.value = false;
    })
    .catch((error: any) => {
        errors.value = errorResponse(error.response?.data?.errors)
    })
}

const confirm = (): void => {
    location.reload()
    
    showSuccess.value = false;
    form.value.current_password = null;
}

</script>