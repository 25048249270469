import { OfflineAction } from "@/views/enums/OfflineAction";
import Storage from "@/helpers/storage";
import Relationships from "../Relationships";

const storage = new Storage();
const relationship = new Relationships();

const setNewForm = async (item: any, data: any) => {
    const comorbidities = [];
    for (const index in item.comorbidities) {
        const comorbidity = item.comorbidities[index];
        if (comorbidity.comorbidity_id) {
            const renderComorbidities = await relationship.comorbidities(comorbidity.comorbidity_id);
			comorbidities.push({ ...renderComorbidities, ...{ checked: true } });
		} else {
			comorbidities.push({ comorbidity_id: "", checked: false });
		}
    }

	data.child_type = item.child_type;
    data.child_type_meta = relationship.child_type(item.child_type);
	data.company_address = item.company_address;
	data.company_contact_number = item.company_contact_number;
	data.company_name = item.company_name;
	data.disability_id = item.disability_id;
	data.educational_attainment = await relationship.educational_attainment(item);
	data.educational_attainment_id = item.educational_attainment_id;
	data.employment_status_id = item.employment_status_id;
	data.employment_status = await relationship.employment_status(item);
	data.hospital_area_id = item.hospital_area_id;
    data.hospital_area = await relationship.hospitalAreas(item.hospital_area_id)
	data.hospital_number = item.hospital_number;
	data.incident_time = item.incident_time;
	data.incident_time_meta = relationship.incident_time(item.incident_time);
	data.indigeneous_people_id = item.indigeneous_people_id;
	data.is_followup = item.is_followup;
	data.is_hama = item.is_hama;
	data.is_inpatient = item.is_inpatient;
	data.is_internally_displaced = item.is_internally_displaced;
	data.is_reabuse = item.is_reabuse;
	data.nationality_id = item.nationality_id;
    data.other_nationality = item.other_nationality;
	data.pwd = item.pwd;
	data.pwd_meta = relationship.pwd(item);
	data.religion_id = item.religion_id;
    data.other_religion = item.other_religion;
	data.religion = await relationship.religion(item);
	data.diagnoses.high_suicidal_risk = item.high_suicidal_risk;
	data.diagnoses.high_suicidal_risk_meta = relationship.high_suicidal_risk(item.high_suicidal_risk);
	data.diagnoses.is_hama = item.is_hama;
	data.diagnoses.other_diagnoses = item.other_diagnoses;
	data.diagnoses.primary_diagnoses_id = item.primary_diagnoses_id;
	data.diagnoses.primary_diagnoses = await relationship.primary_diagnoses(item);
	data.comorbidities = await comorbidities.length > 0 ? comorbidities : data.comorbidities;

    const mentalHealthDiagnoses = [];
    for (const index in item.mental_health) {
        const mental_health = item.mental_health[index];
        if (mental_health.mental_health_diagnoses_id) {
            const behaviorType = await relationship.behavior_types(mental_health);
            const mentalHealth = await relationship.mental_health_diagnoses(mental_health.mental_health_diagnoses_id);

            mentalHealthDiagnoses.push({
                behavior_type: behaviorType,
				behavior_type_id: mental_health.behavior_type_id,
				mental_health: mentalHealth,
				mental_health_diagnoses_id: mental_health.mental_health_diagnoses_id,
				specify: mental_health.specify,
				type: mental_health.type,
				type_meta: typeof mental_health.type == 'number' ? relationship.behavior(mental_health.type) : null,
            });
        }
    }
	data.mental_health_diagnoses = mentalHealthDiagnoses;
	data.nationality = await relationship.nationality(item.nationality_id);
	data.referral_service.intake_social_worker = await relationship.social_worker(item.intake_social_worker_id);
	data.referral_service.intake_social_worker_id = item.intake_social_worker_id;
	data.referral_service.mental_health_personnel = await relationship.mental_health_personnel(item);
	data.referral_service.mental_health_personnel_id = item.mental_health_personnel_id;
	data.referral_service.mms = await relationship.mms(item);
	data.referral_service.mms_id = item.mms_id;
	data.referral_service.physician = await relationship.physician(item);
	data.referral_service.physician_id = item.physician_id;
	data.referral_service.referral_source = await relationship.referral_source(item.referral_source_id);
	data.referral_service.referral_source_id = item.referral_source_id;
    data.referral_service.referral_specify = item.referral_specify || "";
	data.referral_service.second_referral_source = await relationship.referral_source(item.second_referral_source_id);
	data.referral_service.second_referral_source_id = item.second_referral_source_id;
	data.referral_service.social_worker = await relationship.social_worker(item.social_worker_id);
	data.referral_service.social_worker_id = item.social_worker_id;
	data.referral_service.sought_service = await relationship.sought_service(item);
	data.referral_service.sought_service_id = item.sought_service_id;
	data.referral_service.trainee = await relationship.trainee(item);
	data.referral_service.trainee_id = item.trainee_id;
	data.remarks = item.remarks;
	data.encoded_by = storage.get("user");
    const primaryDiagnoses = await relationship.primary_diagnoses(item);
    data.primary_diagnoses = primaryDiagnoses?.label
    data.initiated_at = relationship.dateFormatFromIso(item.initiated_at);
    data.initiated_at_formatted = relationship.dateFormat(item.initiated_at);
	return JSON.stringify(data);
};

export default class UpdateCaseOfflineForm {
	content: string | null;
	encoded_by: number | null;
	action: number | null;
	is_sync: boolean;
	created_at: string | null;
	updated_at: string | null;
	deleted_at: string | null;
	// id: string | null;
	patient_id: number | null;
	case_number: string | null;
	initiated_at: string | null;
    offline_patient_id: string | null;
    offline_id: string | null;
	constructor(item: any, data: any) {
		const isItemEmpty = Object.keys(data).length > 0;

        this.offline_id = "";
        this.offline_patient_id = "";
		this.patient_id = isItemEmpty ? data?.patient_id : "";
		this.case_number = isItemEmpty ? data?.case_number : "Offline(No Case Number)";
		this.initiated_at = isItemEmpty ? relationship.dateFormatFromIso(data?.initiated_at) : "";
        this.content = "";
		// this.content = isItemEmpty ? setNewForm(item, JSON.parse(data?.content)) : "";
		this.encoded_by = isItemEmpty ? data?.encoded_by || 0 : "";
		this.action = OfflineAction.UPDATE_RECORD;
		this.is_sync = false;
		this.created_at = "";
		this.updated_at = "";
		this.deleted_at = "";

	}

    async renderCreateCaseForm(form: any, item: any, data: any): Promise<any> {
        const isItemEmpty = Object.keys(item).length > 0;
        if (isItemEmpty) {
            form.id = isItemEmpty ? data?.id : "";
        }
        form.content = isItemEmpty ? await setNewForm(item, JSON.parse(data?.content)) : "";
        form.is_created_offline = true;
        form.action = OfflineAction.NEW_RECORD;
        form.created_at = relationship.dateFormatFromIso(new Date());
        return form;
    }

	async createNewForm(item: any, data: any, onlineCases: any = null): Promise<any> {
        item.patient_id = data?.id,
        data = JSON.parse(data.content);
		item.diagnoses = {};
		item.referral_service = {};
        item.case_number = '';
        item.registered_at = relationship.dateFormatFromIso(item.registered_at);
        item.birthdate = relationship.dateFormatFromIso(item.birthdate);
        item.birthdate_formatted = relationship.dateFormat(item?.birthdate_formatted, 'MM/dd/yyyy') || "";
        item.registered_at_formatted = relationship.dateFormat(item?.registered_at, 'MM/dd/yyyy') || "";
        item.name = `${item.first_name} ${item.last_name}`;
        item.approximate_age = data.birthdate ? relationship.computeAge(data.birthdate) : relationship.computeApproximateAge(data.approximate_age_year, data.approximate_age_month);
        item.computed_age = data.birthdate ? relationship.computeAge(data.birthdate) : relationship.computeApproximateAge(data.approximate_age_year, data.approximate_age_month);
        item.gender_meta = relationship.gender(item);
        item.sex_at_birth_meta = relationship.sexAtBirth(item);
        item.civil_status = await relationship.civil_status(item);
        item.indigeneous = await relationship.indigeneous(item);
        // item.sex_at_birth = data.sex_at_birth;
        // item.gender = data.gender;
        // item.civil_status_id = item.civil_status_id || data.civil_status_id;
        // item.indigeneous_people_id = data.indigeneous_people_id;

        if (onlineCases?.case_number) {
            item.case_number = onlineCases.case_number;
        }
        
		return await setNewForm(item, item);
	}
}
