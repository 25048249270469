import { OfflineAction } from "@/views/enums/OfflineAction";
import Storage from "@/helpers/storage";
import Relationships from "../Relationships";

const storage = new Storage();
const relationship = new Relationships();

const setNewForm = (item: any) => {
    const data = {
        registered_at: item.registered_at || "",
        patient_id: item.patient_id || "",
        first_name: item.first_name || "",
        last_name: item.last_name || "",
        middle_name: item.middle_name || "",
        birthdate: item.birthdate || "",
        gender: item.gender || "",
        computed_age: item.computed_age,
        approximate_age: item.approximate_age || "",
        civil_status_id: item.civil_status_id || "",
        nationality_id: item.nationality_id || "",
        religion_id: item.religion_id || "",
        nickname: item.nickname || "",
        sex_at_birth: item.sex_at_birth || "",
        initiated_at: item.initiated_at || "",
        is_followup: item.is_followup || false,
        is_reabuse: item.is_reabuse || false,
        incident_time: item.incident_time || "",
        hospital_number: item.hospital_number || "",
        primary_diagnoses_id: item.primary_diagnoses_id || "",
        other_diagnoses: item.other_diagnoses || "",
        high_suicidal_risk: item.high_suicidal_risk || "",
        is_hama: item.is_hama || false,
        mental_health: item.mental_health || "",
        comorbidities: item.comorbidities || "",
        referral_source_id: item.referral_source_id || "",
        referral_specify: item.referral_specify || "",
        second_referral_source_id: item.second_referral_source_id || "",
        sought_service_id: item.sought_service_id || "",
        intake_social_worker_id: item.intake_social_worker_id || "",
        social_worker_id: item.social_worker_id || "",
        physician_id: item.physician_id || "",
        mental_health_personnel_id: item.mental_health_personnel_id || "",
        mms_id: item.mms_id || "",
        trainee_id: item.trainee_id || "",
        child_type: item.child_type || "",
        is_internally_displaced: item.is_internally_displaced || false,
        pwd: item.pwd || "",
        disability_id: item.disability_id || "",
        educational_attainment_id: item.educational_attainment_id || "",
        employment_status_id: item.employment_status_id || "",
        company_name: item.company_name || "",
        company_contact_number: item.company_contact_number || "",
        company_address: item.company_address || "",
        is_inpatient: item.is_inpatient || false,
        hospital_area_id: item.hospital_area_id || "",
        remarks: item.remarks || "",
        indigeneous_people_id: item.indigeneous_people_id || "",
        encoded_by: storage.get('user'),
        birthday_classification: item.birthday_classification,
	};
	return JSON.stringify(data);
};

export default class CreateCaseOfflineForm {
	content: string | null;
	encoded_by: number | null;
	action: number | null;
	is_sync: boolean;
    created_at: string | null;
	updated_at: string | null;
	deleted_at: string | null;
	// id: string | null;
    patient_id: string | null;
    offline_patient_id: string | null;
    case_number: string | null;
    initiated_at: string | null;
    is_created_offline: boolean;
	constructor(item: any, res: any, canCreatePatientCase: any = null) {
        this.patient_id = res?.id;
        this.offline_patient_id = res?.offline_id;
        this.case_number = item.case_number || "Offline(No Case Number)";
        this.initiated_at = item.initiated_at || "";
		this.content = canCreatePatientCase ? canCreatePatientCase : setNewForm(item);
		this.encoded_by = 0;
		this.action = OfflineAction.NEW_RECORD;
		this.is_sync = false;
        this.created_at = relationship.dateFormatFromIso(new Date());
		this.updated_at = "";
		this.deleted_at = "";
        this.is_created_offline = res.is_created_offline ? res.is_created_offline : true;
	}
}
