<template>
	<user-layout
		hasBack
		:backLink="`/patient/${patientId}/cases/${caseId}/edit${offlineParams ? '?offline=true' : ''}`"
		hasBackClass="flex md:justify-between md:items-center md:flex-row flex-col">
		<template #lowerRight>
			<div class="flex md:flex-row flex-col items-center md:space-x-3 md:space-y-0 space-y-3">
				<a
					@click="
						router.replace(
							`/patient/${patientId}/cases/${caseId}/edit${offlineParams ? '?offline=true' : ''}`
						)
					"
					class="md:w-auto w-full md:mt-0 mt-6">
					<button-component btnWidth="quinary" customClass="quinary md:w-auto w-full">Cancel</button-component>
				</a>
				<button-component @click="save()" customClass="primary md:w-auto w-full md:mt-0 mt-6">
					<span>Save Changes</span>
				</button-component>
			</div>
		</template>
		<p class="mt-6 md:text-2xl text-lg font-medium text-neutral-600">Patient's Name:
			<span class="md:inline-block block font-semibold text-neutral-900">{{ `${form.last_name}, ${form.first_name}` }}</span>
		</p>
		<div class="w-full lg:p-6 p-4 mx-auto my-6 border border-gray-200 rounded">
			<div class="flex items-center justify-between">
				<section-title title="Immediate Safety Assessment	" />
			</div>
			<form class="grid grid-cols-6 md:gap-6 gap-3 mt-6" v-if="loaded">
				<div class="col-span-6 border-t border-gray-200"></div>

				<div class="md:col-span-3 col-span-6">
					<text-input
						:disabled="true"
						type="text"
						label="Case Number"
						placeholder="Case Number"
						name="case_number"
						id="case_number"
						v-model="form.case_number" 
					/>
				</div>
				<div class="md:col-span-3 col-span-6">
					<date-picker
						:disabled="true"
						id="assessment_date"
						name="date_initiated"
						label="Date Initiated"
						v-model="form.assessment_date"
						:error="errors?.assessment_date"
						:max-date="new Date()" />
				</div>
				<div class="md:col-span-2 col-span-6">
					<text-input
						type="text"
						label="Last Name"
						placeholder="Last name"
						name="last_name"
						id="last_name"
						required
						v-model="form.last_name"
						:error="errors?.last_name" />
				</div>
				<div class="md:col-span-2 col-span-6">
					<text-input
						type="text"
						label="First Name"
						placeholder="First name"
						name="first_name"
						id="first_name"
						required
						v-model="form.first_name"
						:error="errors?.first_name" />
				</div>
				<div class="md:col-span-2 col-span-6">
					<text-input
						type="text"
						label="Middle Name"
						placeholder="Middle name"
						name="middle_name"
						id="middle_name"
						v-model="form.middle_name"
						:error="errors?.middle_name" />
				</div>
				<div class="col-span-6">
					<checkbox-input
						:checked="form.is_assessment_patient_sibling"
						id="is_assessment_patient_sibling"
						name="assessment_sibling"
						label="Assessment is for patient's sibling"
						v-model="form.is_assessment_patient_sibling" 
					/>
				</div>
				<div class="col-span-6">
					<text-input
						textarea
						label="Appointments"
						placeholder="Appointments"
						name="appointments"
						id="appointment"
						:textAreaRows="1"
						v-model="form.appointment"
						:error="errors?.appointment" />
				</div>
				<div class="grid grid-cols-6 col-span-6 gap-6">
					<div class="col-span-6">
						<p class="text-sm font-bold">Safety Factor</p>
					</div>
					<template v-for="(question, index) in questions" :key="question">
						<div class="md:col-span-3 col-span-6">
							<p class="text-xs font-medium text-neutral-600">
								{{ question.description }}
							</p>
						</div>
						<div class="md:col-span-3 col-span-6 md:mt-0 -mt-3">
							<div class="flex items-center space-x-6">
								<radio-group
									:options="safetyFactorOptions"
									:name="`caretaker_${index}`"
									:id="``"
									v-model="form.questions[index]['status']" 
								/>
							</div>
						</div>
						<div class="col-span-6">
							<text-input
								textarea
								label="Information supporting safety factor:"
								placeholder="Information supporting safety factor"
								:name="`caretaker_safety_factor_${index}`"
								:id="`caretaker_safety_factor_${index}`"
								v-model="form.questions[index]['supporting_information']" />
						</div>
					</template>
				</div>
				<div class="col-span-6">
					<text-input
						textarea
						label="Brief Family Background"
						placeholder="Brief Family Background"
						name="brief_family_background"
						id="brief_family_background"
						:textAreaRows="3"
						v-model="form.brief_family_background"
						:error="errors?.brief_family_background" />
				</div>
				<div class="col-span-6">
					<text-input
						textarea
						label="Family Background"
						placeholder="Family Background"
						name="family_background"
						id="family_background"
						:textAreaRows="3"
						v-model="form.family_background"
						:error="errors?.family_background" />
				</div>

				<div class="col-span-6">
					<p class="text-sm font-bold">Safety Decision Plan</p>
				</div>
				<div class="col-span-6">
					<p class="text-sm font-bold">Risk Codes</p>
				</div>
				<div class="md:col-span-2 col-span-6">
					<text-input
						:disabled="true"
						type="number"
						label="Total number of “Yes”"
						placeholder="#"
						name="number_yes"
						id="number_yes"
						v-model="form.yes_count"
						:error="errors?.yes_count" />
				</div>
				<div class="md:col-span-2 col-span-6">
					<text-input
						:disabled="true"
						type="number"
						label="Total number of “No”"
						placeholder="#"
						name="number_no"
						id="number_no"
						v-model="form.no_count"
						:error="errors?.no_count" />
				</div>
				<div class="md:col-span-2 col-span-6">
					<text-input
						:disabled="true"
						type="number"
						label="Total number of “Unknown”"
						placeholder="#"
						name="number_unknown"
						id="number_unknown"
						v-model="form.unknown_count"
						:error="errors?.unknown_count" />
				</div>
                <div class="col-span-6" id="risk_code_id">
					<p class="text-sm font-bold" >Safety Decisions:</p>
				</div>
				<div class="col-span-6" v-for="riskCode in riskCodes" :key="riskCode">
					<label
						for="action_plan-1"
						class="relative flex items-center h-full py-3 pr-3 pl-3 border rounded cursor-pointer border-neutral-100 min-h-[98px]"
					>
                        <radio-group
                            :options="[{ id: riskCode.id, value: riskCode.id, label: '' }]"
                            :name="`caretaker_${index}`"
                            v-model="form.risk_code_id" 
						/>
						<p class="text-xs font-medium text-neutral-600">
							{{ riskCode.description }}
						</p>
					</label>
				</div>
				<p
					v-if="errors?.risk_code_id"
					class="text-[10px] mt-1"
					:class="errors?.risk_code_id ? 'text-red-600' : 'text-gray-800'"
					id="email-error">
					{{ errors?.risk_code_id }}
				</p>
				<div class="col-span-6">
					<text-input
						textarea
						label="Immediate Safety Plan"
						placeholder="Immediate Safety Plan"
						name="immediate_safety_plan"
						id="immediate_safety_plan"
						:textAreaRows="3"
						v-model="form.immediate_safety_plan" />
				</div>
				<div class="col-span-6">
					<ck-editor
						label="Rationale of Overall Immediate Safety Assessment"
						v-model="form.overall_risk_assessment"
					></ck-editor>
				</div>
			</form>
		</div>
		<success-modal
			:show="showSuccess"
			@confirm="redirectSuccess()"
			action-text="Close"
			title="Record Save!"
			:content="successMessage" />
	</user-layout>
</template>
<script setup lang="ts">
import { ref, onMounted, computed, watch } from "vue";
import UserLayout from "@/layouts/UserLayout.vue";
import SectionTitle from "@/components/partials/SectionTitle.vue";
import ButtonComponent from "@/components/buttons/ButtonComponent.vue";
import SuccessModal from "@/components/modals/SuccessModal.vue";
import TextInput from "@/components/inputs/TextInput.vue";
import CheckboxInput from "@/components/inputs/CheckboxInput.vue";
import RadioGroup from "@/components/inputs/RadioGroup.vue";
import DatePicker from "@/components/inputs/DatePicker.vue";
import { useRouter, useRoute } from "vue-router";
import SafetyAssessment from "@/classes/Cases/SafetyAssessment";
import { renderSelect, renderSelectV2 } from "@/interfaces/DropdownInterface";
import { SafetyAssessmentCreate } from "@/interfaces/Cases/SafetyAssessment";
import SafetyAssessmentForm from "@/classes/Forms/Cases/SafetyAssessmentForm";
import { errorResponse } from "@/helpers/errorMessage";
import { SafetyAssessmentStatus } from "@/views/enums/SafetyAssessmentStatus";
import { items } from "@/interfaces/pages/FilterPageInterface";
import { useNetworkStore } from "@/store/network";
import pickBy from "lodash/pickBy";
import DB from "@/classes/Databases/Crud";
import FormOptions from "@/classes/Forms/FormOptions";
import CaseAssessmentStatus from "@/enums/CaseAssessmentStatus";
import SqliteRecordChecker from "@/classes/Forms/SqliteRecordChecker";
import { onIonViewWillEnter } from "@ionic/vue";
import CkEditor from '@/components/inputs/CKEditor.vue';

const networkStore = useNetworkStore();
const isNetworkAvailable = computed(() => networkStore.isNetworkAvailable);
const db = new DB("case_immediate_safety_assessments");
const dbCase = new DB("cases");
const dbPatient = new DB("patients");
const checkRecord = new SqliteRecordChecker();

const router = useRouter();
const route = useRoute();
const loaded = ref<boolean>(false);
const showSuccess = ref<boolean>(false);
const patientId = ref<number | null>(route.params.patient);
const caseId = ref<number | null>(route.params.case);
const assessmentId = ref<number | null>(route.params.assessment);
const questions = ref<renderSelect[]>([]);
const riskCodes = ref<renderSelect[]>([]);
const safetyFactorOptions = ref<renderSelectV2[]>([]);
const form = ref<SafetyAssessmentCreate>(new SafetyAssessmentForm());
const errors = ref<object>({});
const successMessage = ref<string>("Safety Assessment has been updated!");
const offlineParams = ref<string>(route.query.offline);
const patient = ref<string>('');
const options = new FormOptions();

const save = (): void => {
	errors.value = {};
	if (!isNetworkAvailable.value) {
		const validate = form.value.offlineCreateValidation(form.value);
		errors.value = validate?.errors;

		if (validate.hasOfflineError) {
			showSuccess.value = false;
			return;
		}
	}

	if (assessmentId.value) {
		SafetyAssessment.update(
			patientId.value,
			caseId.value,
			assessmentId.value,
			{ ...form.value, ...{ isOfflineData: offlineParams.value } },
			isNetworkAvailable.value
		)
			.then((response: object) => {
				showSuccess.value = true;
				successMessage.value = response.data.message;
			})
			.catch((error: object) => {
				errors.value = errorResponse(error.response?.data?.errors);
			});
	} else {
		SafetyAssessment.store(
			patientId.value,
			caseId.value,
			{ ...form.value, ...{ isOfflineData: offlineParams.value } },
			isNetworkAvailable.value
		)
			.then((response: object) => {
				showSuccess.value = true;
				successMessage.value = response.data.message;
			})
			.catch((error: object) => {
				errors.value = errorResponse(error.response?.data?.errors);
			});
	}
};

const redirectSuccess = async (): void => {
	router.replace(`/patient/${patientId.value}/cases/${caseId.value}/edit`);
	showSuccess.value = false;
};

const template = (items: object): void => {
	form.value.questions = questions.value.map((item: object) => {
		const newForm = items.filter((question: object) => {
			if (question.question_id == item.id) {
				return {
					question_id: question.question_id,
					supporting_information: question.supporting_information,
					status: question.status,
				};
			}
		});
		return newForm.length > 0
			? newForm[0]
			: { question_id: item.id, supporting_information: null, status: SafetyAssessmentStatus.UNKNOWN };
	});
};

const renderCount = (data: [], type: number): number => {
	return data.filter((item: object) => {
		if (item.status == type) {
			return item;
		}
	}).length;
};

const maxDate = () => {
	return new Date().toISOString().split("T")[0];
};

const date = ref(new Date());

watch(form.value, (val: object) => {
	form.value.yes_count = renderCount(val.questions, SafetyAssessmentStatus.YES);
	form.value.no_count = renderCount(val.questions, SafetyAssessmentStatus.NO);
	form.value.unknown_count = renderCount(val.questions, SafetyAssessmentStatus.UNKNOWN);
});

const checkIfAssessmentIsCreated = async () => {
    if (!assessmentId.value && !isNetworkAvailable.value) {
        const response = await db.select(`SELECT * FROM case_immediate_safety_assessments WHERE cases_id=${caseId.value} LIMIT 1`);
        if (response.length > 0) {
            router.push(`/patient/${patientId.value}/cases/${caseId.value}/safety-assessment/${response[0].id}/view`);
        }
    }
}

const getData = async (): void => {
	if (assessmentId.value) {
		await SafetyAssessment.edit(
			patientId.value,
			caseId.value,
			assessmentId.value,
			pickBy({ isOfflineData: offlineParams.value ? true : false }),
			isNetworkAvailable.value
		)
			.then(async (response: object) => {
				if (isNetworkAvailable.value) {
					safetyFactorOptions.value = response.data.status;
					questions.value = response.data.questions;
					riskCodes.value = response.data.riskCodes;
					form.value.updateSafetyAssessment(response.data.item);
					template(response.data.item.safety_assessment_questions);
				} else {
					safetyFactorOptions.value = CaseAssessmentStatus;
					questions.value = await options.safetyAssessmentQuestions();
					riskCodes.value = await options.riskCodes();
					const item = JSON.parse(response.data.item.content);

					form.value.updateSafetyAssessment(item);
					template(item.safety_assessment_questions);
				}
				loaded.value = true;
			})
			.catch((error: object) => console.log(error));
	} else {
		await SafetyAssessment.create(
			patientId.value,
			caseId.value,
			pickBy({ isOfflineData: offlineParams.value ? true : false }),
			isNetworkAvailable.value
		)
			.then(async (response: object) => {
				if (isNetworkAvailable.value) {
					safetyFactorOptions.value = response.data.status;
					questions.value = response.data.questions;
					riskCodes.value = response.data.riskCodes;
					form.value.updateAutoFill(response.data.case);
				} else {
					safetyFactorOptions.value = CaseAssessmentStatus;
					questions.value = await options.safetyAssessmentQuestions();
					riskCodes.value = await options.riskCodes();
					await fetchOfflinePatient();
				}
                template(form.value.questions)
                loaded.value = true;
			})
			.catch((error: object) => console.log(error));
	}
};

const fetchOfflinePatient = async () => {
    const fetchPatient = await checkRecord.init(dbPatient, patientId.value, false, 'patients');
    const checkPatientIfExists = fetchPatient.result;

    const fetchCase = await checkRecord.init(dbCase, caseId.value, false, 'cases');
    const checkCaseIfExists = fetchCase.result;

	const patient = JSON.parse(checkPatientIfExists.data.item?.content);
	const cases = JSON.parse(checkCaseIfExists.data.item?.content);
	patient.case_number = cases?.case_number;
    patient.assessment_date = cases?.initiated_at;
	form.value.updateAutoFill(patient);
};

// watch(
// 	() => isNetworkAvailable.value,
// 	(val) => {
// 		console.log("Watcher - Network: " + val);
// 		getData();
// 	}
// );

onIonViewWillEnter(async () => {
    await checkIfAssessmentIsCreated();
    await getData()    
});
// onMounted(async () => {
//     await checkIfAssessmentIsCreated();
//     await getData()
// });
</script>
