import DB from "@/classes/Databases/Crud";
import { useDraftLogger } from "@/store/draftlogger"
export default class SqliteRecordChecker {
	async init(db: any, id: number, isOfflineData = false, table = ''): Promise<any> {
		let result = await db.edit(id, isOfflineData, table);
		if (result.data.item.length != undefined) {
			isOfflineData = true;
			result = await db.edit(id, isOfflineData, table);
		}
		return { result: result, isOfflineData: isOfflineData };
	}

	async deleteCasePerpetrator(db: any, perpetrator: any, patientCase: any, table = ''): Promise<any> {
		const caseCondition = patientCase.id
			? { cases_id: patientCase.id }
			: { offline_cases_id: patientCase.offline_id };
		const perpetratorCondidition = perpetrator.id
			? { perpetrator_id: perpetrator.id }
			: { offline_perpetrator_id: perpetrator.offline_id };

		return await db.delete({ ...caseCondition, ...perpetratorCondidition }, table);
	}

	async saveToDraft(table: string, params: any, isRecordDeleted = false): Promise<any> {
		// const date = new Date().toJSON();
		// const dbDraft = new DB("drafts");
        // const dbPatient = new DB("patients");
        // const dbCases = new DB("cases");
        // const dbPerpetrators = new DB("perpetrators");
        // const dbCasePerpetrators = new DB("case_perpetrators");
        // const dbPatientAddress = new DB("case_patient_addresses");
        // const dbGuardians = new DB("case_guardians");
        // const dbCompanions = new DB("case_companions");
        // const dbIntakePersonnalInformation = new DB('intake_personnal_informations');
        // const dbIntakePersonToCase = new DB('intake_person_related_to_cases');
        // const dbIntakeContactNumber = new DB('intake_contact_numbers');
        // const dbIntakeEmail = new DB('intake_email_address');
        // const dbIntakeIncest = new DB('intake_incest_cases');
        // const dbSafetyAssessment = new DB('case_immediate_safety_assessments');
        // const dbCaseConferenceInformation = new DB('case_conferences');
        // const dbCaseConferenceFactor = new DB('aggravating_factors');
        // const dbCaseConferenceRecommendation = new DB('case_conference_recommendations');
        // const dbCaseConferenceMinutes = new DB('minutes');
		// const dbIntervention = new DB("intervention_data");

        const draft = useDraftLogger();
        draft.hasDraft = false;

        // const patients = await dbPatient.select('SELECT count(id) as count FROM patients WHERE is_sync=false AND (is_created_offline=true OR is_updated_offline=true OR is_deleted_offline=true)');
        // const cases = await dbCases.select('SELECT count(id) as count FROM cases WHERE is_sync=false AND (is_created_offline=true OR is_updated_offline=true OR is_deleted_offline=true)');
        // const perpetrators = await dbPerpetrators.select('SELECT count(id) as count FROM perpetrators WHERE is_sync=false AND (is_created_offline=true OR is_updated_offline=true OR is_deleted_offline=true)');
        // const casePerpetrators = await dbCasePerpetrators.select('SELECT count(id) as count FROM case_perpetrators WHERE is_sync=false AND (is_created_offline=true OR is_updated_offline=true OR is_deleted_offline=true)');
        // const caseGuardians = await dbGuardians.select('SELECT count(id) as count FROM case_guardians WHERE is_sync=false AND (is_created_offline=true OR is_updated_offline=true OR is_deleted_offline=true)');
        // const caseInformant = await dbCompanions.select('SELECT count(id) as count FROM case_companions WHERE is_sync=false AND (is_created_offline=true OR is_updated_offline=true OR is_deleted_offline=true)');
        // const casePatienAddress = await dbPatientAddress.select('SELECT count(id) as count FROM case_patient_addresses WHERE is_sync=false AND (is_created_offline=true OR is_updated_offline=true OR is_deleted_offline=true)');
        // const intakePersonnalInformation = await dbIntakePersonnalInformation.select('SELECT count(id) as count FROM case_patient_addresses WHERE is_sync=false AND (is_created_offline=true OR is_updated_offline=true OR is_deleted_offline=true)');
        // const intakePersonToCase = await dbIntakePersonToCase.select('SELECT count(id) as count FROM case_patient_addresses WHERE is_sync=false AND (is_created_offline=true OR is_updated_offline=true OR is_deleted_offline=true)');
        // const intakeContactNumber = await dbIntakeContactNumber.select('SELECT count(id) as count FROM case_patient_addresses WHERE is_sync=false AND (is_created_offline=true OR is_updated_offline=true OR is_deleted_offline=true)');
        // const intakeEmail = await dbIntakeEmail.select('SELECT count(id) as count FROM case_patient_addresses WHERE is_sync=false AND (is_created_offline=true OR is_updated_offline=true OR is_deleted_offline=true)');
        // const intakeIncest = await dbIntakeIncest.select('SELECT count(id) as count FROM case_patient_addresses WHERE is_sync=false AND (is_created_offline=true OR is_updated_offline=true OR is_deleted_offline=true)');
        // const caseSafetyAssessment = await dbSafetyAssessment.select('SELECT count(id) as count FROM case_patient_addresses WHERE is_sync=false AND (is_created_offline=true OR is_updated_offline=true OR is_deleted_offline=true)');
        // const caseConferenceInformation = await dbCaseConferenceInformation.select('SELECT count(id) as count FROM case_patient_addresses WHERE is_sync=false AND (is_created_offline=true OR is_updated_offline=true OR is_deleted_offline=true)');
        // const caseConferenceFactpr = await dbCaseConferenceFactor.select('SELECT count(id) as count FROM case_patient_addresses WHERE is_sync=false AND (is_created_offline=true OR is_updated_offline=true OR is_deleted_offline=true)');
        // const caseConferenceRecommendation = await dbCaseConferenceRecommendation.select('SELECT count(id) as count FROM case_patient_addresses WHERE is_sync=false AND (is_created_offline=true OR is_updated_offline=true OR is_deleted_offline=true)');
        // const caseConferenceMinutes = await dbCaseConferenceMinutes.select('SELECT count(id) as count FROM case_patient_addresses WHERE is_sync=false AND (is_created_offline=true OR is_updated_offline=true OR is_deleted_offline=true)');
		// const interventions = await dbIntervention.select('SELECT count(id) as count FROM intervention_data WHERE is_sync=false AND (is_created_offline=true OR is_updated_offline=true OR is_deleted_offline=true)');
        
        // draft.count = patients[0].count + cases[0].count + perpetrators[0].count + casePerpetrators[0].count + caseGuardians[0].count + caseInformant[0].count + casePatienAddress[0].count;
        //  + intakePersonnalInformation[0].count + intakePersonToCase[0].count 
        //     + intakeContactNumber[0].count + intakeEmail[0].count +  intakeIncest[0].count + caseSafetyAssessment[0].count + caseConferenceInformation[0].count + caseConferenceFactpr[0].count + caseConferenceRecommendation[0].count + caseConferenceMinutes[0].count
		// 	+ interventions[0].count;
        // const renderCount: any = localStorage.getItem('cpn-offline-draft-count')
        
		// const count: number = 
        // console.log(count)
        // localStorage.setItem('cpn-offline-draft-count', count.toLocaleString());	
        draft.hasDraft = true;
        
        

        // const id = parseInt(params?.id) || 0;

		// const template = {
		// 	table_source: `${table}`,
		// 	table_id: id,
		// 	table_offline_id: parseInt(params?.offline_id) || 0,
		// 	action: params?.action,
		// 	is_sync: false,
		// 	created_at: date,
		// };

        // await dbDraft.select(`DELETE FROM drafts WHERE table_source="${table}" AND table_id=${id}`);
		// await dbDraft.insert(template, null, true, 'drafts');
		// const draftLogs = await dbDraft.select(`SELECT * FROM drafts`);
		// console.log(draftLogs, "draft logs");
	}
}
