<template>
	<user-layout>
		<div class="w-full py-6 mx-auto">
			<div class="flex items-center justify-between">
				<section-title title="Settings" description="View the relevant data and other details" />
				<!-- <a @click="router.replace('/settings/intervention-types/create')">
					<button-component>
						<PlusIcon class="w-4 h-4 text-primary-600 stroke-[3px] -ml-2 mr-1 icon" />
						<span>Create</span>
					</button-component>
				</a> -->
			</div>
			<div class="mt-6">
				<div class="w-full border rounded-lg border-neutral-100">
					<div class="relative overflow-auto">
						<div class="absolute left-0 top-0 h-full whitespace-nowrap position w-[181px] shadow-[2px_0_16px_0px_rgb(0,0,0,0.08)]">
							<div class="px-6 py-[26px] bg-primary-100 text-sm font-semibold relative">Settings</div>
							<side-tab interventionTypesSelected />
						</div>
						<div class="pl-[181px] w-[calc(100vw-224px)]">
							<div class="flex items-center justify-between p-6 border-b border-neutral-100">
								<div class="flex items-center space-x-4">
									<tab-component :tabs="tabs" @update:tab="(value: string) => updateTab(value)" />
								</div>
							</div>
							<div>
								<div
									v-if="route.query.tab != 'activity_logs' && route.query.tab != 'archived'"
									class="flex items-start p-3 space-x-3 overflow-auto side-scroll"
								>
	
									<div class="flex items-start space-x-3">
										<!-- Parent -->
										
										<div 
											v-if="items"
											class="border border-neutral-100 rounded-lg w-[320px]"
										>
											<div class="p-6 border-b border-neutral-100">
												<p class="text-sm text-neutral-400">Intervention Type</p>
											</div>
											
											<template v-for="(item) in items.data" :key="item.id">
												<div 
													class="flex items-center py-6 pl-6 pr-4 border-b last:border-0"
													:class="activeForm.id == item.id ? 'bg-primary-300 ' : ''"
												>
													<div class="flex-1 pr-6 overflow-hidden whitespace-nowrap text-ellipsis">
														<p 
															class="overflow-hidden text-sm whitespace-nowrap text-ellipsis"
															:class="activeForm.id == item.id ? 'text-primary-600' : 'text-neutral-900'"
														>
															{{ item.label }}
														</p>
													</div>
													<div class="flex items-center space-x-2 shrink-0">
														<EyeIcon 
															@click="router.replace(`/settings/intervention-types/edit/${item.id}`)"
															class="w-5 h-5 transition cursor-pointer hover:text-secondary-500"
															aria-hidden="true"
															:class="activeForm?.id == item?.id ? 'text-primary-600' : 'text-gray-500'"
														/>
	
														<ChevronRightIcon 
															class="w-5 h-5 transition cursor-pointer"
															:class="activeForm.id == item.id ? 'text-primary-600' : 'text-gray-500 hover:text-primary-500'"
															aria-hidden="true"
															@click="getSubForm(item)"
														/>
													</div>
												</div>
	
											</template>
										</div>
	
										<FormsCard
											v-if="loaded"
											:form="activeForm"
											:subForms="subForms"
										/>
									</div>
								</div>
							</div>
	
							<div v-if="route.query.tab == 'archived'">
	
								<data-table
									:headers="headers"
									:no-action="false"
									:count="items?.total"
									@update:searchText="(value: string) => (filterSearch = value)"
									tableClass="header-frm-table">
									<template v-slot:body>
										<template v-for="item in items.data" :key="item">
											<tr>
												<td class="px-6 py-[18px] whitespace-nowrap text-sm text-gray-900">
													{{ item.label }}
												</td>
												<td class="px-6 py-[18px] whitespace-nowrap text-sm text-gray-900">
													{{ DateFormatter.defaultTimeStamp(item?.deleted_at) }}
												</td>
												<td class="px-6 py-[18px] whitespace-nowrap text-sm text-gray-900">
													<div class="flex items-center justify-center mx-auto">
														<restore-button
															@click="showRestoreModal(item)" />
													</div>
												</td>
											</tr>
										</template>
									</template>
								</data-table>
	
							</div>
	
							<ActivityLogsTable
								v-if="route.query.tab == 'activity_logs'"
								:items="items"
							/>
	
							<div v-if="route.query.tab == 'archived' || route.query.tab == 'activity_logs'"
							class="w-full px-6 border-t py-7">
								<minify-pagination
									:numRows="numRows"
									@update:rows="(value: any) => changeRows(value)"
									@update:page="(value: number) => paginate(value)"
									:current-page="page"
									:last-page="lastPage"></minify-pagination>
							</div>
						</div>
					</div>

				
					<!-- RESTORE MODAL -->
					<confirmation-modal
						:typeTwo="true"
						:show="showRestoreConfirmation"
						@cancel="showRestoreConfirmation = false"
						@confirm="restoreRecord()"
						cancel-text="Cancel"
						action-text="Confirm"
						title="Restore Record"
						content="Are you sure you wish to restore this record?" />
					<success-modal
						:show="showRestoreSuccess"
						@confirm="closeRestoreModal()"
						action-text="Close"
						title="Record Restored!"
						content="Selected record has been successfully restored!" />
				</div>
			</div>
		</div>
	</user-layout>
</template>
<script setup lang="ts">
import { ref, computed, watch, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import UserLayout from "@/layouts/UserLayout.vue";
import SectionTitle from "@/components/partials/SectionTitle.vue";
import RestoreButton from "@/components/buttons/RestoreButton.vue";
import DataTable from "../Components/DataTable.vue";
import SideTab from "../Components/SideTab.vue";
import MinifyPagination from "@/components/partials/MinifyPagination.vue";
import TabComponent from "@/components/partials/TabComponent.vue";
import ButtonComponent from "@/components/buttons/ButtonComponent.vue";
import { PlusIcon } from "@heroicons/vue/24/outline";
import throttle from "lodash/throttle";
import pickBy from "lodash/pickBy";
import PrimaryDiagnosisService from "@/classes/Settings/PrimaryDiagnosisService";
import ConfirmationModal from "@/components/modals/ConfirmationModal.vue";
import SuccessModal from "@/components/modals/SuccessModal.vue";
import ActivityLogsTable from "@/views/activity-logs/ActivityLogsTable.vue";
import { ChevronRightIcon, EyeIcon } from "@heroicons/vue/24/outline";
import DateFormatter from "@/helpers/DateFormatter";

import InterventionTypeService from "@/classes/Settings/InterventionTypeService.ts"

import FormsCard from "@/views/settings/InterventionTypes/Components/Index/FormsCard.vue";

import { FormInterface } from "@/interfaces/pages/Settings/InterventionFormInterface.ts";


const route = useRoute();
const router = useRouter();
/**
 * VARIABLES
 */
const tab = ref<any>(route.query.tab);
const items = ref<any>(null);
const filterSearch = ref<any>(route.query.query);
const page = ref<any>(route.query.page);
const lastPage = ref<any>(null);
const rows = ref<any>(route.query.rows || 10);
const numRows = computed(() => (rows.value ? rows.value + " Rows" : "All"));
const showRestoreConfirmation = ref<boolean>(false);
const showRestoreSuccess = ref<boolean>(false);
const selectedForm = ref<number>(0);
const activityLogs = ref<any>({});

const loaded = ref<boolean>(false);

const activeForm = ref<FormInterface>({
	id: 0,
	label: '',
	order: 0,
});

const subForms = ref<FormInterface[]>([]);

const tabs = ref<{ name: string; count: number; tab: string|null; href: string; allowed: boolean }[]>([
	{
		name: "All",
		count: 0,
		tab: null,
		href: "/settings/intervention-types/index",
		allowed: true,
	},
	{
		name: "Archived",
		count: 0,
		tab: "archived",
		href: "/settings/intervention-types/index?tab=archived",
		allowed: true,
	},
	{
		name: "Activity logs",
		count: 0,
		tab: "activity_logs",
		href: "/settings/intervention-types/index?tab=activity_logs",
		allowed: true,
	},
]);

const headers: { text: string; haveSort?: boolean }[] = [{ text: "Form Title" }, { text: "Date Deleted" }];

/**
 * METHODS
 */
const getData = (search: any = null): void => {
	InterventionTypeService
		.index(
			pickBy({
				query: search ?? filterSearch.value,
				page: page.value,
				rows: rows.value,
				tab: tab.value,
			})
		)
		.then((response: any) => {
			items.value = response.data.items;

			tabs.value = [
				{
					name: "All",
					count: response.data.allItemsCount,
					tab: null,
					href: "/settings/intervention-types/index",
					allowed: true,
				},
				{
					name: "Archived",
					count: response.data.archiveItemsCount,
					tab: "archived",
					href: "/settings/intervention-types/index?tab=archived",
					allowed: true,
				},
				{
					name: "Activity logs",
					count: response.data.activityLogsCount,
					tab: "activity_logs",
					href: "/settings/intervention-types/index?tab=activity_logs",
					allowed: true,
				},
            ]
		})
		.catch((error: any) => {
			console.log(error);
		});
};

const getSubForm = (form: FormInterface):void => {

	activeForm.value = form
	loaded.value = false

	InterventionTypeService
		.getChildForms(form.id)
		.then(({data}) => {
			subForms.value = data.children;
			loaded.value = true
		})
		.catch((error: any) => {
			console.log(error);
		});
}

const reloadPage = async () => {
	return await router.push({
		path: "/settings/intervention-types/index",
		query: pickBy({
			query: filterSearch.value,
			page: page.value,
			rows: rows.value,
			tab: tab.value,
		}),
	});
};

const applyFilters = (search: any = null) => {
	reloadPage().then(() => {
		getData(search);
	});
};

const changeRows = (row: any): void => {
	rows.value = row;
	applyFilters();
};

const paginate = (data: number): void => {
	page.value = data;
	applyFilters();
};

const updateTab = (currentTab: string) => {
	tab.value = currentTab;
	page.value = 1;
	applyFilters();
};

/**
 * MODALS
 */
const showRestoreModal = (item: any): void => {
	showRestoreConfirmation.value = true;
	selectedForm.value = item.id;
};

const closeRestoreModal = (): void => {
	showRestoreSuccess.value = false;
};

const restoreRecord = (): void => {

	InterventionTypeService.restore( selectedForm.value)
		.then(() => {
			showRestoreSuccess.value = true;
			showRestoreConfirmation.value = false;
			applyFilters();
		})
		.catch((error) => console.log(error));
};

/**
 * WATCHERS
 */
watch(
	filterSearch,
	throttle((val: string) => {
		applyFilters(val);
	}, 1000)
);

watch(
	() => tab.value,
	throttle(() => {
		getData();
	}, 1000)
);

onMounted(() => {
	getData();
});
</script>
