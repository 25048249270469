import { useDraftLogger } from "@/store/draftlogger";
import http from "@/axios";
import DB from "@/classes/Databases/Crud";
import Relationships from "@/classes/Forms/Relationships";
import PerpetratorForm from "@/classes/Forms/Cases/PerpetratorForm";
import GuardianForm from "@/classes/Forms/Cases/GuardianForm";
import CompanionForm from "@/classes/Forms/Cases/CompanionForm";
import PatientAddressForm from "@/classes/Forms/Cases/PatientAddressForm";
import CreatePatientForm from "@/classes/Forms/Patients/CreatePatientForm";
import { SyncStatus } from "@/views/enums/SyncStatus";
import SyncPatients from "./SyncPatients";
import SyncCases from "./SyncCases";
import SyncPerpetrators from "./SyncPerpetrators";
import SyncCasePerpetrators from "./SyncCasePerpetrators";
import SyncGuardians from "./SyncGuardians";
import SyncInformants from "./SyncInformants";
import SyncPatientAddresses from "./SyncPatientAddresses";
import SyncInterventions from "./SyncInterventions";
import SyncIntakes from "./SyncIntakes";
import SafetyAssessmentForm from "../Forms/Cases/SafetyAssessmentForm";
import SyncCaseConference from "./SyncCaseConference";
import SyncCaseConferenceFactors from "./SyncCaseConferenceFactors";
import SyncCaseConferenceRecommendations from "./SyncCaseConferenceRecommendations";

const relationship = new Relationships();
const dbPatient = new DB("patients");
const dbCases = new DB("cases");
const dbPerpetrators = new DB("perpetrators");
const dbCasePerpetrators = new DB("case_perpetrators");
const dbPatientAddress = new DB("case_patient_addresses");
const dbGuardians = new DB("case_guardians");
const dbCompanions = new DB("case_companions");
const dbIntakePersonnalInformation = new DB('intake_personnal_informations');
const dbIntakePersonToCase = new DB('intake_person_related_to_cases');
const dbIntakeContactNumber = new DB('intake_contact_numbers');
const dbIntakeEmail = new DB('intake_email_address');
const dbIntakeIncest = new DB('intake_incest_cases');
const dbSafetyAssessment = new DB('case_immediate_safety_assessments');


const syncPatient = new SyncPatients;
const syncCases = new SyncCases;
const syncPerpetrators = new SyncPerpetrators;
const syncCasePerpetrators = new SyncCasePerpetrators;
const syncGuardians = new SyncGuardians;
const syncInformants = new SyncInformants;
const syncPatientAddress = new SyncPatientAddresses;
const syncInterventions = new SyncInterventions;
const syncIntake = new SyncIntakes;
const syncCaseConference = new SyncCaseConference;
const syncCaseConferenceFactors = new SyncCaseConferenceFactors;
const syncCaseConferenceRecommendations = new SyncCaseConferenceRecommendations;
export default class OnlineSynching {

    renderDefaultForm(form: any, item: any, data: any) {
        form.id = item.id;
        form.created_at = item.created_at;
        form.created_at_formatted = relationship.dateFormat(item.created_at, 'yyyy-LL-dd hh:mm:ss a');
        form.updated_at = item.updated_at;
        form.deleted_at = item.deleted_at;
        form.is_created_offline = item.is_created_offline;
        form.is_updated_offline = item.is_updated_offline;
        form.is_deleted_offline = item.is_deleted_offline;
        form.patient_name = `${data?.first_name} ${data?.last_name}`;
        form.birthdate_formatted = data?.birthdate_formatted;
        form.synching_remarks = item.synching_remarks;
        return form;
    }

    async renderPatients(isRenderData = false): Promise<any> {
        const items = [];
        let hasError = false;
        const patients = await dbPatient.select('SELECT * FROM patients WHERE is_sync=false AND (is_created_offline=true OR is_updated_offline=true OR is_deleted_offline=true) ORDER BY updated_at DESC');
        if (patients.length > 0) {
            for (const index in patients) {
                const item = patients[index];
                const data = JSON.parse(item.content);
                const setForm = new CreatePatientForm(data);
                const form = this.renderDefaultForm(setForm, item, data);
                // unique object value per table
                form.table = "patients";
                form.type = "Patients"
                form.status = item.is_sync_failed == false || item.is_sync_failed == undefined 
                    ? (item.is_sync ? SyncStatus.SYNC : SyncStatus.NOT_SYNC) 
                    : SyncStatus.RECORD_CONFLICT;
                form.url_view = `/patient/${item.id}/view`;
                form.can_view = item.id ? true : false;

                if (isRenderData) {
                    items.push(form);
                }

                // do something for online syching
                if (!isRenderData) {
                    hasError = await syncPatient.processForm(item, form);
                }
            }
        }

        return {
            items: items,
            hasError: hasError,
        }
    }

    async renderCases(isRenderData = false): Promise<any> {
        const items = [];
        let hasError = false;
        const cases = await dbCases.select('SELECT * FROM cases WHERE is_sync=false AND (is_created_offline=true OR is_updated_offline=true OR is_deleted_offline=true) ORDER BY updated_at DESC');
        if (cases.length > 0) {
            for (const index in cases) {
                const item = cases[index];
                const data = JSON.parse(item.content);
                const setForm = new CreatePatientForm(data);
                const form = this.renderDefaultForm(setForm, item, data);
                // unique object value per table
                form.table = "cases";
                form.type = "Cases"
                form.status = item.is_sync_failed == false || item.is_sync_failed == undefined 
                    ? (item.is_sync ? SyncStatus.SYNC : SyncStatus.NOT_SYNC) 
                    : SyncStatus.RECORD_CONFLICT;
                form.url_view = `/patient/${item.patient_id}/cases/${item.id}/edit`;
                form.birthdate_formatted = relationship.dateFormat(data.birthdate);
                form.patient_id = item.patient_id;
                form.can_view = item.patient_id ? true : false;
                form.remarks = data.remarks;
                form.mental_health = data.mental_health;
                form.comorbidities = data.comorbidities;

                if (isRenderData) {
                    items.push(form);
                }
                
                // do something for online syching
                if (!isRenderData) {
                    hasError = await syncCases.processForm(item, form);
                }
            }
        }

        return {
            items: items,
            hasError: hasError,
        }
    }

    async renderPerpetrators(isRenderData = false): Promise<any> {
        const items = [];
        let hasError = false;
        const perpetrators = await dbPerpetrators.select('SELECT * FROM perpetrators WHERE is_sync=false AND (is_created_offline=true OR is_updated_offline=true OR is_deleted_offline=true) ORDER BY updated_at DESC');
        if (perpetrators.length > 0) {
            for (const index in perpetrators) {
                const item = perpetrators[index];
                const data = JSON.parse(item.content);
                const setForm = new PerpetratorForm(data);
                const form = this.renderDefaultForm(setForm, item, data);
                // unique object value per table
                form.table = "perpetrators";
                form.type = "Perpetrators"
                form.patient_name = data.identity == 1 ? data.tag : `${data.first_name} ${data.last_name}`;
                form.birthdate_formatted = data.birthdate_formatted;
                form.status = item.is_sync_failed == false || item.is_sync_failed == undefined 
                    ? (item.is_sync ? SyncStatus.SYNC : SyncStatus.NOT_SYNC) 
                    : SyncStatus.RECORD_CONFLICT;
                form.url_view = `/perpetrator/${item.id}/view`;
                form.birthdate_formatted = relationship.dateFormat(data.birthdate);
                form.can_view = true;

                if (isRenderData) {
                    items.push(form);
                }

                // do something for online syching
                if (!isRenderData) {
                    hasError = await syncPerpetrators.processForm(item, form);
                }
            }
        }

        return {
            items: items,
            hasError: hasError,
        }
    }

    async renderCasePerpetrator(isRenderData = false): Promise<any> {
        const items = [];
        let hasError = false;
        const perpetrators = await dbCasePerpetrators.select('SELECT * FROM case_perpetrators WHERE is_sync=false AND (is_created_offline=true OR is_updated_offline=true OR is_deleted_offline=true) ORDER BY updated_at DESC');
        if (perpetrators.length > 0) {
            for (const index in perpetrators) {
                const item = perpetrators[index];
                let data:any = {};
                const form = this.renderDefaultForm({}, item, data);
                const perpetrator = await dbPerpetrators.select(`SELECT * FROM perpetrators WHERE id=${item.perpetrator_id}`);
                if (perpetrator.length > 0) {
                    data = JSON.parse(perpetrator[0].content);
                } else {
                    data = {};
                }
                const cases = await dbCases.select(`SELECT * FROM cases WHERE id=${item.cases_id}`);
                const casesData = cases.length > 0 ? cases[0] : {};

                // unique object value per table
                form.cases_id = item.cases_id;
                form.id = item.id;                
                form.perpetrator_id = item.perpetrator_id;
                form.patient_name = data.identity == 1 ? data.tag : `${data.first_name} ${data.last_name}`;
                form.birthdate_formatted = data.birthdate_formatted;
                form.table = "case_perpetrators";
                form.type = "Linked Perpetrators to Cases"
                form.status = item.is_sync_failed == false || item.is_sync_failed == undefined 
                    ? (item.is_sync ? SyncStatus.SYNC : SyncStatus.NOT_SYNC) 
                    : SyncStatus.RECORD_CONFLICT;
                form.url_view = `/patient/${casesData.patient_id}/cases/${item.cases_id}/perpetrators/${item.perpetrator_id}/view`;
                form.birthdate_formatted = relationship.dateFormat(data.birthdate);
                form.can_view = item.cases_id && item.perpetrator_id ? true : false;

                if (isRenderData) {
                    items.push(form);
                }

                // do something for online syching
                if (!isRenderData) {                
                    hasError = await syncCasePerpetrators.processForm(item, form);
                }
            }
        }

        return {
            items: items,
            hasError: hasError,
        }
    }

    async renderCaseGuardians(isRenderData = false): Promise<any> {
        const items = [];
        let hasError = false;
        const guardians = await dbGuardians.select('SELECT * FROM case_guardians WHERE is_sync=false AND (is_created_offline=true OR is_updated_offline=true OR is_deleted_offline=true) ORDER BY updated_at DESC');
        if (guardians.length > 0) {
            for (const index in guardians) {
                const item = guardians[index];
                const data = JSON.parse(item.content);
                const setForm = new GuardianForm(data);
                const form = this.renderDefaultForm(setForm, item, data);
                const cases = await dbCases.select(`SELECT * FROM cases WHERE id=${item.cases_id}`);
                const casesData = cases.length > 0 ? cases[0] : {};
                // unique object value per table
                form.cases_id = item.cases_id;
                form.table = "case_guardians";
                form.type = "Case Guardians"
                form.status = item.is_sync_failed == false || item.is_sync_failed == undefined 
                    ? (item.is_sync ? SyncStatus.SYNC : SyncStatus.NOT_SYNC) 
                    : SyncStatus.RECORD_CONFLICT;
                form.url_view = `/patient/${casesData.patient_id}/cases/${item.cases_id}/guardian/${item.id}/edit`;
                form.birthdate_formatted = relationship.dateFormat(data.birthdate);
                form.can_view = item.cases_id ? true : false;
                form.person_related_types = data.person_related_types;
                form.companion_relationship_id = data.companion_relationship_id;
                form.civil_status_id = data.civil_status_id;
                form.is_employed = data.is_employed;
                form.is_ofw = data.is_ofw;
                form.occupation = data.occupation;
                form.company = data.company;
                form.weekly_income = data.weekly_income;
                form.other_source_of_income = data.other_source_of_income;
                form.is_enrolled_in_school = data.is_enrolled_in_school;
                form.educational_attainment_id = data.educational_attainment_id;
                form.school = data.school;
                form.housing_classification_id = data.housing_classification_id;
                form.no_children_in_family = data.no_children_in_family;
                form.no_household_members = data.no_household_members;
                form.no_family_in_household = data.no_family_in_household;
                form.other_information = data.other_information;

                if (isRenderData) {
                    items.push(form);
                }

                // do something for online syching
                if (!isRenderData) {
                    hasError = await syncGuardians.processForm(item, form);
                }
            }
        }

        return {
            items: items,
            hasError: hasError,
        }
    }

    async renderCaseInformant(isRenderData = false): Promise<any> {
        const items = [];
        let hasError = false;
        const informants = await dbCompanions.select('SELECT * FROM case_companions WHERE is_sync=false AND (is_created_offline=true OR is_updated_offline=true OR is_deleted_offline=true) ORDER BY updated_at DESC');
        if (informants.length > 0) {
            for (const index in informants) {
                const item = informants[index];
                const data = JSON.parse(item.content);
                const setForm = new CompanionForm(data);
                const form = this.renderDefaultForm(setForm, item, data);
                const cases = await dbCases.select(`SELECT * FROM cases WHERE id=${item.cases_id}`);
                const casesData = cases.length > 0 ? cases[0] : {};
                // unique object value per table
                form.cases_id = item.cases_id;
                form.table = "case_companions";
                form.type = "Case Informant"
                form.status = item.is_sync_failed == false || item.is_sync_failed == undefined 
                    ? (item.is_sync ? SyncStatus.SYNC : SyncStatus.NOT_SYNC) 
                    : SyncStatus.RECORD_CONFLICT;
                form.url_view = `/patient/${casesData.patient_id}/cases/${item.cases_id}/companions/${item.id}/edit`;
                form.birthdate_formatted = relationship.dateFormat(data.birthdate); 
                form.can_view = item.cases_id ? true : false;

                if (isRenderData) {
                    items.push(form);
                }

                // do something for online syching
                if (!isRenderData) {
                    hasError = await syncInformants.processForm(item, form);
                }
            }
        }

        return {
            items: items,
            hasError: hasError,
        }
    }

    async renderCasePatientAddress(isRenderData = false): Promise<any> {
        const items = [];
        let hasError = false;
        const addresses = await dbPatientAddress.select('SELECT * FROM case_patient_addresses WHERE is_sync=false AND (is_created_offline=true OR is_updated_offline=true OR is_deleted_offline=true) ORDER BY updated_at DESC');
        if (addresses.length > 0) {
            for (const index in addresses) {
                const item = addresses[index];
                const data = JSON.parse(item.content);
                const setForm = new PatientAddressForm(data);
                const form = this.renderDefaultForm(setForm, item, data);
                const cases = await dbCases.select(`SELECT * FROM cases WHERE id=${item.cases_id}`);
                const casesData = cases.length > 0 ? cases[0] : {};
                const casesContent = cases.length > 0 ? JSON.parse(casesData.content) : {};
                // unique object value per table
                form.cases_id = item.cases_id;
                form.table = "case_patient_addresses";
                form.type = "Case Patient Address"
                form.patient_name = `${casesContent.first_name} ${casesContent.last_name}`;
                form.birthdate_formatted = casesContent.birthdate_formatted;
                form.status = item.is_sync_failed == false || item.is_sync_failed == undefined 
                    ? (item.is_sync ? SyncStatus.SYNC : SyncStatus.NOT_SYNC) 
                    : SyncStatus.RECORD_CONFLICT;
                form.url_view = `/patient/${casesData.patient_id}/cases/${item.cases_id}/patient-addresses/${item.id}/edit`;
                form.birthdate_formatted = relationship.dateFormat(casesContent.birthdate);
                form.can_view = item.cases_id ? true : false;

                if (isRenderData) {
                    items.push(form);
                }

                // do something for online syching
                if (!isRenderData) {
                    hasError = await syncPatientAddress.processForm(item, form);
                }
            }
        }

        return {
            items: items,
            hasError: hasError,
        }
    }

    async processSynching(selectedForm: any): Promise<any> {
        return syncPatient.processSynching(selectedForm);
    }

    async processCreateSynching(selectedForm: any): Promise<any> {
        return syncPatient.processCreateSynching(selectedForm);
    }

    async renderInterventions(selectedForm: any): Promise<any> {
        return syncInterventions.renderInterventions(selectedForm);
    }

    async renderIntakePersonnalInformation(isRenderData = false): Promise<any> {
        const items = [];
        let hasError = false;
        const patients = await dbIntakePersonnalInformation.select('SELECT * FROM intake_personnal_informations WHERE is_sync=false AND (is_created_offline=true OR is_updated_offline=true OR is_deleted_offline=true) ORDER BY updated_at DESC');
        if (patients.length > 0) {
            for (const index in patients) {
                const item = patients[index];
                const data = JSON.parse(item.content);
                const form = this.renderDefaultForm(data, item, data);
                const cases = await dbCases.edit(item.cases_id, false, 'cases');
                let caseId = null;
                let patientId = null;
                if (cases.data.item.length === undefined) {
                    caseId = cases.data.item.id;
                    patientId = cases.data.item.patient_id;
                    const patient = await dbPatient.edit(cases.data.item.patient_id, false, 'patients');
                    if (patient.data.item.length === undefined) {
                        form.patient_name = `${patient.data.item.first_name} ${patient.data.item.last_name}`;
                        form.birthdate_formatted = patient.data.item.birthdate;
                    }
                }
                // unique object value per table
                form.cases_id = item.cases_id;
                form.table = "intake_personnal_informations";
                form.type = "Intake Personnal Information"
                form.status = item.is_sync_failed == false || item.is_sync_failed == undefined 
                    ? (item.is_sync ? SyncStatus.SYNC : SyncStatus.NOT_SYNC) 
                    : SyncStatus.RECORD_CONFLICT;
                form.url_view = `/patient/${patientId}/cases/${caseId}/intake/personal-information`;
                form.can_view = item.id ? true : false;
                if (isRenderData) {
                    items.push(form);
                }

                // // do something for online syching
                if (!isRenderData) {
                    hasError = await syncIntake.processPersonnalInformation(item, form);
                }
            }
        }
        return {
            items: items,
            hasError: hasError,
        }
    }

    async renderIntakePersonRelatedToCase(isRenderData = false): Promise<any> {
        const items = [];
        let hasError = false;
        const patients = await dbIntakePersonToCase.select('SELECT * FROM intake_person_related_to_cases WHERE is_sync=false AND (is_created_offline=true OR is_updated_offline=true OR is_deleted_offline=true) ORDER BY updated_at DESC');
        if (patients.length > 0) {
            for (const index in patients) {
                const item = patients[index];
                const data = JSON.parse(item.content);
                const form = this.renderDefaultForm(data, item, data);
                const cases = await dbCases.edit(item.cases_id, false, 'cases');
                let caseId = null;
                let patientId = null;
                if (cases.data.item.length === undefined) {
                    caseId = cases.data.item.id;
                    patientId = cases.data.item.patient_id;
                }
                // unique object value per table
                form.cases_id = item.cases_id;
                form.birthdate_formatted = relationship.dateFormat(form.birthdate);
                form.table = "intake_person_related_to_cases";
                form.type = "Intake Person Related To Case"
                form.status = item.is_sync_failed == false || item.is_sync_failed == undefined 
                    ? (item.is_sync ? SyncStatus.SYNC : SyncStatus.NOT_SYNC) 
                    : SyncStatus.RECORD_CONFLICT;
                form.url_view = `/patient/${patientId}/cases/${caseId}/intake/persons-related-case`;
                form.can_view = item.id ? true : false;
                if (isRenderData) {
                    items.push(form);
                }

                // // do something for online syching
                if (!isRenderData) {
                    hasError = await syncIntake.processPersonRelatedToCase(item, form);
                }
            }
        }
        return {
            items: items,
            hasError: hasError,
        }
    }

    async renderIntakeContactNumber(isRenderData = false): Promise<any> {
        const items = [];
        let hasError = false;
        const patients = await dbIntakeContactNumber.select('SELECT * FROM intake_contact_numbers WHERE is_sync=false AND (is_created_offline=true OR is_updated_offline=true OR is_deleted_offline=true) ORDER BY updated_at DESC');
        if (patients.length > 0) {
            for (const index in patients) {
                const item = patients[index];
                const data = JSON.parse(item.content);
                const form = this.renderDefaultForm(data, item, data);
                const cases = await dbCases.edit(item.cases_id, false, 'cases');
                let caseId = null;
                let patientId = null;
                if (cases.data.item.length === undefined) {
                    caseId = cases.data.item.id;
                    patientId = cases.data.item.patient_id;
                    const patient = await dbPatient.edit(cases.data.item.patient_id, false, 'patients');
                    if (patient.data.item.length === undefined) {
                        form.patient_name = `${patient.data.item.first_name} ${patient.data.item.last_name}`;
                        form.birthdate_formatted = patient.data.item.birthdate;
                    }
                }
                // unique object value per table
                form.cases_id = item.cases_id;
                form.table = "intake_contact_numbers";
                form.type = "Intake Contact Number"
                form.status = item.is_sync_failed == false || item.is_sync_failed == undefined 
                    ? (item.is_sync ? SyncStatus.SYNC : SyncStatus.NOT_SYNC) 
                    : SyncStatus.RECORD_CONFLICT;
                form.url_view = `/patient/${patientId}/cases/${caseId}/intake/contact-numbers`;
                form.can_view = item.id ? true : false;
                if (isRenderData) {
                    items.push(form);
                }

                // // do something for online syching
                if (!isRenderData) {
                    hasError = await syncIntake.processContactNumber(item, form);
                }
            }
        }
        return {
            items: items,
            hasError: hasError,
        }
    }

    async renderIntakeEmail(isRenderData = false): Promise<any> {
        const items = [];
        let hasError = false;
        const patients = await dbIntakeEmail.select('SELECT * FROM intake_email_address WHERE is_sync=false AND (is_created_offline=true OR is_updated_offline=true OR is_deleted_offline=true) ORDER BY updated_at DESC');
        if (patients.length > 0) {
            for (const index in patients) {
                const item = patients[index];
                const data = JSON.parse(item.content);
                const form = this.renderDefaultForm(data, item, data);
                const cases = await dbCases.edit(item.cases_id, false, 'cases');
                let caseId = null;
                let patientId = null;
                if (cases.data.item.length === undefined) {
                    caseId = cases.data.item.id;
                    patientId = cases.data.item.patient_id;
                    const patient = await dbPatient.edit(cases.data.item.patient_id, false, 'patients');
                    if (patient.data.item.length === undefined) {
                        form.patient_name = `${patient.data.item.first_name} ${patient.data.item.last_name}`;
                        form.birthdate_formatted = patient.data.item.birthdate;
                    }
                }
                // unique object value per table
                form.cases_id = item.cases_id;
                form.table = "intake_email_address";
                form.type = "Intake Email Address"
                form.status = item.is_sync_failed == false || item.is_sync_failed == undefined 
                    ? (item.is_sync ? SyncStatus.SYNC : SyncStatus.NOT_SYNC) 
                    : SyncStatus.RECORD_CONFLICT;
                form.url_view = `/patient/${patientId}/cases/${caseId}/intake/email-addresses`;
                form.can_view = item.id ? true : false;
                if (isRenderData) {
                    items.push(form);
                }

                // // do something for online syching
                if (!isRenderData) {
                    hasError = await syncIntake.processEmail(item, form);
                }
            }
        }
        return {
            items: items,
            hasError: hasError,
        }
    }

    async renderIntakeIncestCase(isRenderData = false): Promise<any> {
        const items = [];
        let hasError = false;
        const patients = await dbIntakeIncest.select('SELECT * FROM intake_incest_cases WHERE is_sync=false AND (is_created_offline=true OR is_updated_offline=true OR is_deleted_offline=true) ORDER BY updated_at DESC');
        
        if (patients.length > 0) {
            for (const index in patients) {
                const item = patients[index];
                const data = JSON.parse(item.content);
                const form = this.renderDefaultForm(data, item, data);
                const cases = await dbCases.edit(item.cases_id, false, 'cases');
                let caseId = null;
                let patientId = null;
                if (cases.data.item.length === undefined) {
                    caseId = cases.data.item.id;
                    patientId = cases.data.item.patient_id;
                    const patient = await dbPatient.edit(cases.data.item.patient_id, false, 'patients');
                    if (patient.data.item.length === undefined) {
                        form.patient_name = `${patient.data.item.first_name} ${patient.data.item.last_name}`;
                        form.birthdate_formatted = patient.data.item.birthdate;
                    }
                }
                // unique object value per table
                form.cases_id = item.cases_id;
                form.table = "intake_incest_cases";
                form.type = "Intake Incest Cases"
                form.status = item.is_sync_failed == false || item.is_sync_failed == undefined 
                    ? (item.is_sync ? SyncStatus.SYNC : SyncStatus.NOT_SYNC) 
                    : SyncStatus.RECORD_CONFLICT;
                form.url_view = `/patient/${patientId}/cases/${caseId}/intake/incest-cases`;
                form.can_view = item.id ? true : false;
                if (isRenderData) {
                    items.push(form);
                }

                // // do something for online syching
                if (!isRenderData) {
                    hasError = await syncIntake.processIncest(item, form);
                }
            }
        }
        return {
            items: items,
            hasError: hasError,
        }
    }

    async renderCaseSafeAssessment(isRenderData = false): Promise<any> {
        const items = [];
        let hasError = false;
        const patients = await dbSafetyAssessment.select('SELECT * FROM case_immediate_safety_assessments WHERE is_sync=false AND (is_created_offline=true OR is_updated_offline=true OR is_deleted_offline=true) ORDER BY updated_at DESC');

        if (patients.length > 0) {
            for (const index in patients) {
                const item = patients[index];
                const data = JSON.parse(item.content);
                const setForm = new SafetyAssessmentForm(data);
                const form = this.renderDefaultForm(setForm, item, data);
                const cases = await dbCases.edit(item.cases_id, false, 'cases');
                let caseId = null;
                let patientId = null;
                if (cases.data.item.length === undefined) {
                    caseId = cases.data.item.id;
                    patientId = cases.data.item.patient_id;
                    const patient = await dbPatient.edit(cases.data.item.patient_id, false, 'patients');
                    if (patient.data.item.length === undefined) {
                        form.patient_name = `${patient.data.item.first_name} ${patient.data.item.last_name}`;
                        form.birthdate_formatted = patient.data.item.birthdate;
                    }
                }
                // unique object value per table
                form.no_count = form.no_count == null ? 0 : form.no_count;
                form.unknown_count = form.unknown_count == null ? 0 : form.unknown_count;
                form.yes_count = form.yes_count == null ? 0 : form.yes_count;
                form.questions = data.safety_assessment_questions;
                form.cases_id = item.cases_id;
                form.table = "case_immediate_safety_assessments";
                form.type = "Case Immediate Safety Assessment"
                form.status = item.is_sync_failed == false || item.is_sync_failed == undefined 
                    ? (item.is_sync ? SyncStatus.SYNC : SyncStatus.NOT_SYNC) 
                    : SyncStatus.RECORD_CONFLICT;
                form.url_view = `/patient/${patientId}/cases/${caseId}/safety-assessment/create`;
                form.can_view = item.id ? true : false;
                if (isRenderData) {
                    items.push(form);
                }

                // // do something for online syching
                if (!isRenderData) {
                    hasError = await syncIntake.processSafetyAssessment(item, form);
                }
            }
        }
        return {
            items: items,
            hasError: hasError,
        }
    }

    async renderCaseConferenceInformation(isRenderData = false): Promise<any> {
        return await syncCaseConference.render(isRenderData)
    }

    async renderCaseConferenceFactor(isRenderData = false): Promise<any> {
        return await syncCaseConferenceFactors.render(isRenderData)
    }

    async renderCaseConferenceRecommendation(isRenderData = false): Promise<any> {
        return await syncCaseConferenceRecommendations.render(isRenderData);
    }
}