import http from "@/axios";

class OnlineService {
	async index(patient: number, patientCase: number): Promise<any> {
		return await http.get(`patients/${patient}/cases/${patientCase}/case-conference`);
	}

	async create(patient: number, patientCase: number): Promise<any> {
		return await http.get(`patients/${patient}/cases/${patientCase}/case-conference/create`);
	}

	async store(patient: number, patientCase: number, payload: any): Promise<any> {
		return await http.post(`patients/${patient}/cases/${patientCase}/case-conference`, payload);
	}

	async edit(patient: number, patientCase: number, caseConference: number): Promise<any> {
		return await http.get(`patients/${patient}/cases/${patientCase}/case-conference/${caseConference}/edit`);
	}

	async update(patient: number, patientCase: number, caseConference: number, payload: any): Promise<any> {
		return await http.post(`patients/${patient}/cases/${patientCase}/case-conference/${caseConference}/update`, payload);
	}

	async delete(patient: number, patientCase: number, caseConference: number): Promise<any> {
		return await http.delete(`patients/${patient}/cases/${patientCase}/case-conference/${caseConference}/delete`);
	}

	async indexFactors(patient: number, patientCase: number, caseConference: number): Promise<any> {
		return await http.get(`patients/${patient}/cases/${patientCase}/case-conference/factors/${caseConference}`);
	}

	async storeFactors(patient: number, patientCase: number, caseConference: number, payload: any): Promise<any> {
		return await http.post(`patients/${patient}/cases/${patientCase}/case-conference/factors/${caseConference}`, payload);
	}

	async indexRecommendations(patient: number, patientCase: number, caseConference: number): Promise<any> {
		return await http.get(`patients/${patient}/cases/${patientCase}/case-conference/recommendations/${caseConference}`);
	}

	async storeRecommendations(patient: number, patientCase: number, caseConference: number, payload: any): Promise<any> {
		return await http.post(`patients/${patient}/cases/${patientCase}/case-conference/recommendations/${caseConference}`, payload);
	}

	async indexMinutes(patient: number, patientCase: number, caseConference: number): Promise<any> {
		return await http.get(`patients/${patient}/cases/${patientCase}/case-conference/minutes/${caseConference}`);
	}

	async storeMinutes(patient: number, patientCase: number, caseConference: number, payload: any): Promise<any> {
		return await http.post(`patients/${patient}/cases/${patientCase}/case-conference/minutes/${caseConference}`, payload);
	}

	public async generatePDFAccess(patient:string, cases:string, caseConference: number,) : Promise<JSON> {
        return await http.post(`patients/${patient}/cases/${cases}/case-conference/${caseConference}/generatePDFAccess`);
    }

    public async downloadPDF(caseConference:number, token:string): Promise<void> {		
		window.open(`${process.env.VUE_APP_URL}pdf/${caseConference}/downloadCaseConference/${token}`);
	}
}

export default new OnlineService();
