
import { BaseItemsInterface, BaseDataInterface } from "@/interfaces/pages/BasePageInterface";

export default class BaseIndexItems implements BaseItemsInterface {
    current_page : number
    data: BaseDataInterface[]
    first_page_url : string
    from: string
    last_page: string
    last_page_url: string
    next_page_url: string
    path: string
    per_page: string
    to: string
    total: number

    constructor(public item?: BaseItemsInterface) {
        this.current_page = item?.current_page || 1
        this.data = item?.data || []
        this.first_page_url = item?.first_page_url || '' 
        this.from = item?.from || '' 
        this.last_page = item?.last_page || '' 
        this.last_page_url = item?.last_page_url || '' 
        this.next_page_url = item?.next_page_url || '' 
        this.path = item?.path || '' 
        this.per_page = item?.per_page || '' 
        this.to = item?.to || '' 
        this.total = item?.total || 0
    }
}