import http from "@/axios";
import { Case } from "@/interfaces/Cases/Case";
import DB from "@/classes/Databases/Crud";
import CreateCaseOfflineForm from "../Forms/Patients/CreateCaseOfflineForm";
import UpdateCaseOfflineForm from "../Forms/Patients/UpdateCaseOfflineForm";
import CreatePatientCaseOfflineForm from "../Forms/Patients/CreatePatientCaseOfflineForm";
import OfflinePatientCases from "../Forms/Patients/OfflinePatientCases";

const dbCase = new DB("cases");
const dbPatient = new DB("patients");
class CaseService implements Case {
	async store(patient: number, payload: any, networkStatus = true): Promise<any> {
		if (networkStatus) {
			return await http.post(`patients/${patient}/cases/`, payload).catch(error => error);
		}

        return await OfflinePatientCases.storeNewPatientCase(patient, payload);
	}

	async create(patient: number, payload: any, networkStatus = true): Promise<any> {
		if (networkStatus) {
			return await http.get(`patients/${patient}/cases/create`, { params: payload }).catch(error => error);
		}
        return await dbPatient.edit(patient, false, 'patients');
	}

	async edit(patient: number, patientCase: number, payload: any, networkStatus = true): Promise<any> {
		if (networkStatus) {
			return await http.get(`patients/${patient}/cases/${patientCase}/edit`, { params: payload }).catch(error => error);
		}

		return await dbCase.edit(patientCase, false, 'cases');
	}

	async update(patient: any, patientCase: any, payload: any, networkStatus = true): Promise<any> {
        patient = parseInt(patient);
        patientCase = parseInt(patientCase);
		if (networkStatus) {
			return await http.put(`patients/${patient}/cases/${patientCase}/update`, payload).catch(error => error);
		}
        return await OfflinePatientCases.updatePatientCase(patient, patientCase, payload);
	}

	async deleteDraft(draft: any): Promise<void> {
		if (draft) {
			const condition = draft.id ? { id: draft.id } : { offline_id: draft.offline_id };			
			dbCase.delete(condition, 'cases').catch((error) => (console.error(error) ))
		}
	}

	async generatePDFAccess(patient:number, patientCase:number, networkStatus:boolean, payload:object): Promise<void> {
		if (networkStatus) {
			return await http.post(`patients/${patient}/cases/${patientCase}/generatePDFAccess`, payload);
		}
	}

	async downloadPatientRecord(patient:number, patientCase:number, token:string, networkStatus:boolean): Promise<void> {		
		if (networkStatus) {
			window.open(`${process.env.VUE_APP_URL}pdf/${patientCase}/downloadPatientRecord/${token}`);
		}
	}

	async downloadScar(patient:number, patientCase:number, token:string, networkStatus:boolean, payload:any): Promise<void> {		
		if (networkStatus) {
			window.open(`${process.env.VUE_APP_URL}pdf/${patientCase}/downloadScar/${token}?${new URLSearchParams(payload).toString()}`);
		}
	}
}

export default new CaseService();
