<template>
    <ion-page>
        <ion-content :fullscreen="true">
            <div class="flex flex-wrap lg:flex-row flex-col lg:items-center h-screen lg:justify-between">
                <auth-logo />

                <div class="w-full px-4 lg:w-1/2 sm:px-20 lg:py-0 py-6">
                    <div class="sm:max-w-[360px] mx-auto">
                        <auth-title
                            title="New Password"
                            description="Kindly provide your new password"
                        />
                        <form class="">
        
                            <div class="mb-6">
                                <password-input
                                    label="New Password"
                                    placeholder="New Password"
                                    name="new-password"
                                    id="new-password"
                                    v-model="form.password"
                                    :error="errors?.password"
                                />
                            </div>
                            <div class="mb-6">
                                <password-input
                                    label="Confirm Password"
                                    placeholder="Confirm Password"
                                    name="confirm-password"
                                    id="confirm-password"
                                    v-model="form.password_confirmation"
                                    :error="errors?.password_confirmation"
                                />
                            </div>
                            <button-component
                                type="button"
                                size="large"
                                btnWidth="w-full rounded-lg"
                                @click="submit"
                            >
                                Reset Password
                            </button-component>
                            <div class="mt-6 text-center">
                                <a href="/login" class="m-0 text-sm transition text-primary-600 hover:text-primary-800">Return to Login</a>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <success-modal
                :show="showSuccess"
                @confirm="confirm"
                action-text="Confirm"
                title="Password Updated!"
                content="Your password has been successfully updated"
            />

            <page-loader :show="loading"/>

        </ion-content>
    </ion-page>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue';
import {
    IonPage,
    IonContent 
} from '@ionic/vue';
import ButtonComponent from '@/components/buttons/ButtonComponent.vue';
import PasswordInput from '@/components/inputs/PasswordInput.vue';
import ConfirmationModal from '@/components/modals/ConfirmationModal.vue';

import { useRoute, useRouter } from 'vue-router';

import PageLoader from "@/components/loaders/PageLoader.vue"
import SuccessModal from '@/components/modals/SuccessModal.vue';

import ForgotPassword from '@/classes/ForgotPassword';

import { errorResponse } from "@/helpers/errorMessage";

import AuthLogo from "../Components/AuthLogo.vue";
import AuthTitle from "../Components/AuthTitle.vue";

const router = useRouter();
const route = useRoute();


const loading = ref<boolean>(false);
const showSuccess = ref<boolean>(false);
const successMessage = ref<string>('');
const errors = ref<any>();

const form = ref<{
    email: string
    token: string
    password: string | null
    password_confirmation: string | null
}>({
    email: route.query.email,
    token: route.query.token,
    password: null,
    password_confirmation: null,
})

const submit = (): void => {
    loading.value = true;

    ForgotPassword.setPassword(form.value)
        .then(({ data }) => {
            console.log(data);
            loading.value = false;
            showSuccess.value = true;

        }).catch((error) => {
            loading.value = false;
            errors.value = errorResponse(error.response?.data?.errors)
        });

}
    
const confirm = (): void => {
    router.replace({ path: '/login' });
}


onMounted(() => {
    if (typeof route.query.email == 'undefined' && typeof route.query.token == 'undefined') {
        router.replace({ path: '/login' })
    }
})
</script>