import { defineStore } from 'pinia'

interface hasDraft {
    hasDraft: boolean,
    count: number,
}

export const useDraftLogger = defineStore({
    id: 'network',
    state: (): hasDraft => {
        return {
            hasDraft: false,
            count: 0,
        }
    },
    getters: {},
    actions: {},
})