<template>
   <a @click="router.replace('/draft/index')" >
        <button-component
            size="small"
            customClass="bg-green-600 hover:bg-green-700 text-white transition border border-green-600 hover:border-green-700">
            <span>Drafts</span>
            <!-- <span class="ml-1 font-bold text-[0.563rem] rounded-full bg-[#D1FAE5] text-green-500 w-4 h-4 leading-[16px]">{{ count }}</span> -->
        </button-component>
    </a>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue';

import ButtonComponent from "@/components/buttons/ButtonComponent.vue";

import DraftService from "@/classes/Drafts/DraftService";
import { useRouter } from "vue-router";
import { number } from 'zod';

defineProps({
    count: {
        type: number,
        default: 0
    }
});

const draftCount = ref<any>(0);
const router = useRouter();
// const init = async (): Promise<void> => {
    // console.log('init draflink')
    // await DraftService.hasDrafts().then((res: any) => ( draftCount.value = res ));
// }

// onMounted(() => {
    // init()
// })
    
</script>
