<template>
    <div>
        <label class="block text-xs font-semibold text-neutral-600 mb-3">{{ label }}</label>
        <div class="flex flex-col space-y-2">
            <radio-group
                :options="field_width_options"
                name="field_width"
            />
        </div>
    </div>
</template>
<script setup lang="ts">
import { ref } from "vue";
import RadioGroup from "@/components/inputs/RadioGroup.vue";

const props = defineProps({
	form: {
		type: Object,
	},
    value: {
        default: null,
        required: true,
    },
    label: {
        type: String,
        default: 'Choose field width',
    },
    error: {
        type: String,
        default: null,
    },
    disabled: {
        type: Boolean,
        default: false,
    },
});
const form = ref<object>(props.form);
const field_width_options = [
    { id: '1', label: 'Whole', value: 'col-span-3', radioSelected: null},
    { id: '2', label: 'Half', value: 'col-span-6', radioSelected: null},
]


</script>
