import { EditPatient } from "@/interfaces/PatientInterface";
import { z } from "zod";
export default class EditPatientForm implements EditPatient {
	registered_at: string | null;
	sex_at_birth: number | null;
	last_name: string | null;
	first_name: string | null;
	middle_name: string | null;
	birthdate: string | null;
	birthday_classification: number | null;
	approximate_age_year: number;
	approximate_age_month: number | null;
	civil_status_id: number | null;
	nationality_id: number | null;
	religion_id: number| string | null;
	other_religion: string | null;
	gender: number | null;
	indigeneous_people_id: number | null;
	constructor(item: EditPatient) {
		this.registered_at = item?.registered_at;
		this.sex_at_birth = item?.sex_at_birth;
		this.last_name = item?.last_name;
		this.first_name = item?.first_name;
		this.middle_name = item?.middle_name;
		this.birthday_classification = item?.birthday_classification;
		this.birthdate = item?.birthdate == "0000-00-00" ? "" : item?.birthdate;
		this.approximate_age_year = item?.approximate_age_year;
		this.approximate_age_month = item?.approximate_age_month;
		this.civil_status_id = item?.civil_status_id;
		this.nationality_id = item?.nationality_id;
		this.religion_id = item?.religion_id || null;
		this.other_religion = item?.other_religion || null;
		this.gender = item?.gender;
		this.indigeneous_people_id = item?.indigeneous_people_id || null;
	}

    offlineUpdateValidation(form: any) {
		const errors = <any>{};
		let hasOfflineError = false;

		if (form.registered_at == undefined) {
			hasOfflineError = true;
			Object.assign(errors, { registered_at: "The date registered field is required." });
		}

		if (form.sex_at_birth == undefined) {
			hasOfflineError = true;
			Object.assign(errors, { sex_at_birth: "The sex at birth field is required." });
		}

		// if (!form.indigeneous_people_id) {
		// 	hasOfflineError = true;
		// 	Object.assign(errors, { indigeneous_people_id: "The Ethnicity field is required." });
		// }

		if (!form.last_name) {
			hasOfflineError = true;
			Object.assign(errors, { last_name: "The last name field is required." });
		}

        if (!form.first_name) {
			hasOfflineError = true;
			Object.assign(errors, { first_name: "The first name field is required." });
		}

		if (!form.civil_status_id) {
			hasOfflineError = true;
			Object.assign(errors, { civil_status_id: "The civil status field is required." });
		}

		return {
			hasOfflineError: hasOfflineError,
			errors: errors,
		};
	}
}
