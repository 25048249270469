import http from "@/axios";
import DB from "@/classes/Databases/Crud";

const dbIntakePersonnalInformation = new DB('intake_personnal_informations');
const dbIntakePersonToCase = new DB('intake_person_related_to_cases');
const dbIntakeContactNumber = new DB('intake_contact_numbers');
const dbIntakeEmail = new DB('intake_email_address');
const dbIntakeIncest = new DB('intake_incest_cases');
const dbSafetyAssessment = new DB('case_immediate_safety_assessments');
export default class SyncIntakes {
    resetItemData(item: any) {
        item.synching_remarks = null;
        item.is_sync_failed = false;
        item.is_sync = true;
        item.is_created_offline = false;
        item.is_updated_offline = false;
        item.is_deleted_offline = false;

        return item;
    }

    async rollback(item: any, response: any) {
        const error = response?.response?.data;
        item.synching_remarks = error.message || 'System Error';
        item.is_sync_failed = true;

        return item;
    }

    async processPersonnalInformation(item: any, form: any) {
        let hasError = false;
        if (item.is_updated_offline) {
            const response = await http.post('/attemp-synching-intake-personnal-info-update', form).catch(error => error);
            if (response?.status === 200) {
                item = this.resetItemData(item);
                await dbIntakePersonnalInformation.insert(item, item.id, true, 'intake_personnal_informations');
                hasError = false;
            } else {
                hasError = await this.rollback(item, response);
                await dbIntakePersonToCase.insert(item, item.id, true, 'intake_personnal_informations');
                hasError = true;
            }
        }

        if (item.is_created_offline && !item.is_updated_offline) {
            const response = await http.post('/attemp-synching-intake-personnal-info-create', form).catch(error => error);
            if (response?.status === 200) {
                const oldPatientId = item.id;
                item = this.resetItemData(item);
                item.id = response.data.id;
                await dbIntakePersonnalInformation.delete({id: response.data.id}, 'intake_personnal_informations');
                await dbIntakePersonnalInformation.insert(item, oldPatientId, true, 'intake_personnal_informations');
                hasError = false;
            } else {
                hasError = await this.rollback(item, response);
                await dbIntakePersonToCase.insert(item, item.id, true, 'intake_personnal_informations');
                hasError = true;
            }
        }

        if (item.is_deleted_offline) {
            const response = await http.post('/attemp-synching-intake-personnal-info-delete', form).catch(error => error);
            if (response?.status === 200) {
                await dbIntakePersonnalInformation.delete({id: item.id}, 'intake_personnal_informations');
                hasError = false;
            } else {
                await this.rollback(item, response);
                await dbIntakePersonnalInformation.insert(item, item.id, true, 'intake_personnal_informations');
                hasError = true;
            }
        }

        return hasError;
    }

    async processPersonRelatedToCase(item: any, form: any) {
        let hasError = false;
        if (item.is_updated_offline) {
            const response = await http.post('/attemp-synching-intake-person-to-case-update', form).catch(error => error);
            if (response?.status === 200) {
                item = this.resetItemData(item);
                await dbIntakePersonToCase.insert(item, item.id, true, 'intake_person_related_to_cases');
                hasError = false;
            } else {
                hasError = await this.rollback(item, response);
                await dbIntakeContactNumber.insert(item, item.id, true, 'intake_person_related_to_cases');
                hasError = true;
            }
        }

        if (item.is_created_offline && !item.is_updated_offline) {
            const response = await http.post('/attemp-synching-intake-person-to-case-create', form).catch(error => error);
            if (response?.status === 200) {
                const oldPatientId = item.id;
                item = this.resetItemData(item);
                item.id = response.data.id;
                await dbIntakePersonToCase.delete({id: response.data.id}, 'intake_person_related_to_cases');
                await dbIntakePersonToCase.insert(item, oldPatientId, true, 'intake_person_related_to_cases');
                hasError = false;
            } else {
                hasError = await this.rollback(item, response);
                await dbIntakeContactNumber.insert(item, item.id, true, 'intake_person_related_to_cases');
                hasError = true;
            }
        }

        if (item.is_deleted_offline) {
            const response = await http.post('/attemp-synching-intake-person-to-case-delete', form).catch(error => error);
            if (response?.status === 200) {
                await dbIntakePersonToCase.delete({id: item.id}, 'intake_person_related_to_cases');
                hasError = false;
            } else {
                await this.rollback(item, response);
                await dbIntakePersonToCase.insert(item, item.id, true, 'intake_person_related_to_cases');
                hasError = true;
            }
        }

        return hasError;
    }
    
    async processContactNumber(item: any, form: any) {
        let hasError = false;
        if (item.is_updated_offline) {
            const response = await http.post('/attemp-synching-intake-contact-number-update', form).catch(error => error);
            if (response?.status === 200) {
                item = this.resetItemData(item);
                await dbIntakeContactNumber.insert(item, item.id, true, 'intake_contact_numbers');
                hasError = false;
            } else {
                hasError = await this.rollback(item, response);
                await dbIntakeEmail.insert(item, item.id, true, 'intake_contact_numbers');
                hasError = true;
            }
        }

        if (item.is_created_offline && !item.is_updated_offline) {
            const response = await http.post('/attemp-synching-intake-contact-number-create', form).catch(error => error);
            if (response?.status === 200) {
                const oldPatientId = item.id;
                item = this.resetItemData(item);
                item.id = response.data.id;
                await dbIntakeContactNumber.delete({id: response.data.id}, 'intake_contact_numbers');
                await dbIntakeContactNumber.insert(item, oldPatientId, true, 'intake_contact_numbers');
                hasError = false;
            } else {
                hasError = await this.rollback(item, response);
                await dbIntakeEmail.insert(item, item.id, true, 'intake_contact_numbers');
                hasError = true;
            }
        }

        if (item.is_deleted_offline) {
            const response = await http.post('/attemp-synching-intake-contact-number-delete', form).catch(error => error);
            if (response?.status === 200) {
                await dbIntakeContactNumber.delete({id: item.id}, 'intake_contact_numbers');
                hasError = false;
            } else {
                await this.rollback(item, response);
                await dbIntakeContactNumber.insert(item, item.id, true, 'intake_contact_numbers');
                hasError = true;
            }
        }

        return hasError;
    }

    async processEmail(item: any, form: any) {
        let hasError = false;
        if (item.is_updated_offline) {
            const response = await http.post('/attemp-synching-intake-email-update', form).catch(error => error);
            if (response?.status === 200) {
                item = this.resetItemData(item);
                await dbIntakeEmail.insert(item, item.id, true, 'intake_email_address');
                hasError = false;
            } else {
                hasError = await this.rollback(item, response);
                await dbIntakeIncest.insert(item, item.id, true, 'intake_email_address');
                hasError = true;
            }
        }

        if (item.is_created_offline && !item.is_updated_offline) {
            const response = await http.post('/attemp-synching-intake-email-create', form).catch(error => error);
            if (response?.status === 200) {
                const oldPatientId = item.id;
                item = this.resetItemData(item);
                item.id = response.data.id;
                await dbIntakeEmail.delete({id: response.data.id}, 'intake_email_address');
                await dbIntakeEmail.insert(item, oldPatientId, true, 'intake_email_address');
                hasError = false;
            } else {
                hasError = await this.rollback(item, response);
                await dbIntakeIncest.insert(item, item.id, true, 'intake_email_address');
                hasError = true;
            }
        }

        if (item.is_deleted_offline) {
            const response = await http.post('/attemp-synching-intake-email-delete', form).catch(error => error);
            if (response?.status === 200) {
                await dbIntakeEmail.delete({id: item.id}, 'intake_email_address');
                hasError = false;
            } else {
                await this.rollback(item, response);
                await dbIntakeEmail.insert(item, item.id, true, 'intake_email_address');
                hasError = true;
            }
        }

        return hasError;
    }

    async processIncest(item: any, form: any) {
        let hasError = false;
        if (item.is_updated_offline) {
            const response = await http.post('/attemp-synching-intake-incest-update', form).catch(error => error);
            if (response?.status === 200) {
                item = this.resetItemData(item);
                await dbIntakeIncest.insert(item, item.id, true, 'intake_incest_cases');
                hasError = false;
            } else {
                hasError = await this.rollback(item, response);
                await dbIntakeIncest.insert(item, item.id, true, 'intake_incest_cases');
                hasError = true;
            }
        }

        if (item.is_created_offline && !item.is_updated_offline) {
            const response = await http.post('/attemp-synching-intake-incest-create', form).catch(error => error);
            if (response?.status === 200) {
                const oldPatientId = item.id;
                item = this.resetItemData(item);
                item.id = response.data.id;
                await dbIntakeIncest.delete({id: response.data.id}, 'intake_incest_cases');
                await dbIntakeIncest.insert(item, oldPatientId, true, 'intake_incest_cases');
                hasError = false;
            } else {
                hasError = await this.rollback(item, response);
                await dbIntakeIncest.insert(item, item.id, true, 'intake_incest_cases');
                hasError = true;
            }
        }

        if (item.is_deleted_offline) {
            const response = await http.post('/attemp-synching-intake-incest-delete', form).catch(error => error);
            if (response?.status === 200) {
                await dbIntakeIncest.delete({id: item.id}, 'intake_incest_cases');
                hasError = false;
            } else {
                await this.rollback(item, response);
                await dbIntakeIncest.insert(item, item.id, true, 'intake_incest_cases');
                hasError = true;
            }
        }

        return hasError;
    }

    async processSafetyAssessment(item: any, form: any) {
        let hasError = false;
        if (item.is_updated_offline) {
            const response = await http.post('/attemp-synching-safety-assessment-update', form).catch(error => error);
            if (response?.status === 200) {
                item = this.resetItemData(item);
                await dbSafetyAssessment.insert(item, item.id, true, 'case_immediate_safety_assessments');
                hasError = false;
            } else {
                hasError = await this.rollback(item, response);
                await dbIntakeIncest.insert(item, item.id, true, 'case_immediate_safety_assessments');
                hasError = true;
            }
        }

        if (item.is_created_offline && !item.is_updated_offline) {
            const response = await http.post('/attemp-synching-safety-assessment-create', form).catch(error => error);
            if (response?.status === 200) {
                const oldPatientId = item.id;
                item = this.resetItemData(item);
                item.id = response.data.id;
                await dbSafetyAssessment.delete({id: response.data.id}, 'case_immediate_safety_assessments');
                await dbSafetyAssessment.insert(item, oldPatientId, true, 'case_immediate_safety_assessments');
                hasError = false;
            } else {
                hasError = await this.rollback(item, response);
                await dbIntakeIncest.insert(item, item.id, true, 'case_immediate_safety_assessments');
                hasError = true;
            }
        }
        
        hasError = false;
        return hasError;
    }
}