import { OfflineAction } from "@/views/enums/OfflineAction";
import Storage from "@/helpers/storage";
import Relationships from "../Relationships";

const storage = new Storage();
const relationship = new Relationships();

const setUpdateForm = (item: any, content: any) => {
	content.approximate_age = item.approximate_age || content.approximate_age;
	content.birthdate = item.birthdate || content.birthdate;
	content.civil_status = relationship.civil_status(item.civil_status_id ? item : content);
	content.civil_status_id = item.civil_status_id || content.civil_status_id;
	content.contacts = item.contacts || content.contacts;
	content.family_background = item.family_background || content.family_background;
	content.family_children_count = item.family_children_count || content.family_children_count;
	content.first_name = item.first_name || content.first_name;
	content.last_name = item.last_name || content.last_name;
	content.middle_name = item.middle_name || content.middle_name;
	content.name = `${item.first_name || content.first_name} ${item.last_name || content.last_name}`;
	content.formatted_employed = item.is_employed ? "Yes" : content.is_employed ? "Yes" : "No";
	content.is_employed = item.is_employed || content.is_employed;
	content.formatted_live_with_child = item.is_living_with_child ? "Yes" : content.is_living_with_child ? "Yes" : "No";
	content.is_living_with_child = item.is_living_with_child || content.is_living_with_child;
	content.formatted_ofw = item.is_ofw ? "Yes" : content.is_ofw ? "Yes" : "No";
	content.is_ofw = item.is_ofw || content.is_ofw;
	content.formatted_weekly_income = item.weekly_income || content.weekly_income;
	content.weekly_income = item.weekly_income || content.weekly_income;
	content.gender = item.gender;
	content.gender_meta = relationship.gender(item.gender ? item : content);
	content.house_family_count = item.house_family_count || content.house_family_count;
	content.house_member_count = item.house_member_count || content.house_member_count;
	content.housing_classification_id = item.housing_classification_id || content.housing_classification_id;
	content.housing_classification = relationship.housingClassification(
		item.housing_classification_id ? item : content
	);
	content.legal_status = item.legal_status || content.legal_status;
	content.legal_status_meta = relationship.legalStatus(item?.legal_status ? item : content);
	content.occupation = item.occupation || content.occupation;
	content.other_relationship = item.other_relationship || content.other_relationship;
	content.relationship_id = item.relationship_id || content.relationship_id;
	content.relationship = relationship.relationship(item.relationship_id ? item : content);
	content.socio_economic_status_id = item.socio_economic_status_id || content.socio_economic_status_id;
	content.socio_economic_status = relationship.socioEconomic(item.socio_economic_status_id ? item : content);
    
    const date = new Date().toJSON();
    content.updated_at = date;
    content.updated_at_formatted = date;

	return JSON.stringify(content);
};

const setNewForm = (item: any, content: any) => {
	const data = <any>{};
    data.approximate_age = item.approximate_age || null;
	data.birthdate = item.birthdate || null;
	data.civil_status = relationship.civil_status(item.civil_status_id ? item : content) || null;
	data.civil_status_id = item.civil_status_id || null;
	data.contacts = item.contacts || null;
	data.family_background = item.family_background || null;
	data.family_children_count = item.family_children_count || null;
	data.first_name = item.first_name || null;
	data.last_name = item.last_name || null;
	data.middle_name = item.middle_name || null;
	data.name = `${item.first_name || null} ${item.last_name || null}`;
	data.formatted_employed = item.is_employed ? "Yes" : content.is_employed ? "Yes" : "No";
	data.is_employed = item.is_employed;
	data.formatted_live_with_child = item.is_living_with_child ? "Yes" : content.is_living_with_child ? "Yes" : "No";
	data.is_living_with_child = item.is_living_with_child;
	data.formatted_ofw = item.is_ofw ? "Yes" : content.is_ofw ? "Yes" : "No";
	data.is_ofw = item.is_ofw;
	data.formatted_weekly_income = item.weekly_income || null;
	data.weekly_income = item.weekly_income || null;
	data.gender = item.gender;
	data.gender_meta = relationship.gender(item.gender ? item : content) || null;
	data.house_family_count = item.house_family_count || null;
	data.house_member_count = item.house_member_count || null;
	data.housing_classification_id = item.housing_classification_id || null;
	data.housing_classification = relationship.housingClassification(
		item.housing_classification_id ? item : content
	) || null;
	data.legal_status = item.legal_status;
	data.legal_status_meta = relationship.legalStatus(item?.legal_status ? item : content) || null;
	data.occupation = item.occupation || null;
	data.other_relationship = item.other_relationship || null;
	data.relationship_id = item.relationship_id || null;
	data.relationship = relationship.relationship(item.relationship_id ? item : content) || null;
	data.socio_economic_status_id = item.socio_economic_status_id || null;
	data.socio_economic_status = relationship.socioEconomic(item.socio_economic_status_id ? item : content) || null;

    const date = new Date().toJSON();
    data.created_at = date;
    data.created_at_formatted = date;

	return JSON.stringify(data);
};

export default class CaseIntakeForm {
	content: string | null;
	encoded_by: number | null;
	action: number | null;
	is_sync: boolean;
	created_at: string | null;
	updated_at: string | null;
	deleted_at: string | null;
	id: string | null;
	cases_id: string | null;
	offline_cases_id: string | null;
	constructor(item: any, res: any) {
		this.id = item.id;
		this.cases_id = res?.id;
		this.offline_cases_id = "";
		this.content = JSON.stringify(item);
		this.encoded_by = res.encoded_by;
		this.action = OfflineAction.DEFAULT;
		this.is_sync = false;
		this.created_at = "";
		this.updated_at = "";
		this.deleted_at = "";
	}

	updateForm(item: any, res: any): object {
		const date = new Date().toJSON();
		const data = <any>{};
		data.id = res.id;
		if (!res.id) {
			data.offline_id = res.offline_id;
		}
		data.cases_id = res?.cases_id;
		data.offline_cases_id = res?.offline_cases_id;
		data.content = setUpdateForm(item, JSON.parse(res.content));
		data.encoded_by = 0;
		data.action = OfflineAction.UPDATE_RECORD;
		data.is_sync = false;
		data.created_at = "";
		data.updated_at = date;
		data.deleted_at = "";

		return data;
	}

	createForm(item: any, res: any): object {
		const date = new Date().toJSON();
		const data = <any>{};
		data.id = "";
		data.cases_id = res?.id;
		data.offline_cases_id = res?.offline_id;
		data.content = setNewForm(item, JSON.parse(res.content));
		data.encoded_by = 0;
		data.action = OfflineAction.NEW_RECORD;
		data.is_sync = false;
		data.created_at = date;
		data.updated_at = "";
		data.deleted_at = "";

		return data;
	}
}
