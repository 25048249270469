export enum RoleTypes {
	SUPERADMIN = 1,
    SUPERADMIN_IT = 2,
    AUDITOR = 3,
    OFFICE_ADMIN = 4,
    OFFICE_NORMAL = 5,
    NURSE = 6,
    WCPU = 7,
    OFFICE_TRAINEE = 8,
}
