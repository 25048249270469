<template>
	<user-layout
		hasBack
		:backLink="`/patient/${patientId}/cases/${caseId}/edit`"
		backTitle="Add New Patient Address"
		hasBackClass="flex md:justify-between md:items-center md:flex-row flex-col">
		<template #lowerRight>
			<div class="flex md:flex-row flex-col items-center md:space-x-3 md:space-y-0 space-y-3">
				<a
					@click="
						router.push(
							`/patient/${patientId}/cases/${caseId}/edit`
						)
					"
					class="md:w-auto w-full md:mt-0 mt-6">
					<button-component btnWidth="quinary" customClass="quinary md:w-auto w-full">
						Cancel
					</button-component>
				</a>
				<button-component
					customClass="primary md:w-auto w-full md:mt-0 mt-6"
					form="form" 
					@click="save()"
				>
					<span>Save Changes</span>
				</button-component>
			</div>
		</template>
		<p class="mt-6 md:text-2xl text-lg font-medium text-neutral-600">Patient's Name:
			<span class="md:inline-block block font-semibold text-neutral-900">{{ patient }}</span>
		</p>
		<div class="w-full lg:p-6 p-4 mx-auto my-6 border border-gray-200 rounded">
			<div class="flex items-center justify-between">
				<section-title title="Address Details" />
			</div>
			<form-section
				v-if="loaded"
				:form="form"
				:errors="errors"
				:regions="regions"
				:provinces="provinces"
				:cities="cities"
				:barangays="barangays"
				:residenceTypes="residenceTypes" />
		</div>
		<success-modal
			:show="showSuccess"
			@confirm="redirectSuccess()"
			action-text="Close"
			title="Record Save!"
			:content="successMessage"
		/>

         <page-loader :show="loading"/>

	</user-layout>
</template>
<script setup lang="ts">
import { onMounted, ref, computed, watch, onUnmounted } from "vue";
import UserLayout from "@/layouts/UserLayout.vue";
import SectionTitle from "@/components/partials/SectionTitle.vue";
import ButtonComponent from "@/components/buttons/ButtonComponent.vue";
import SuccessModal from "@/components/modals/SuccessModal.vue";
import FormSection from "./FormSection.vue";
import { useRouter, useRoute } from "vue-router";
import PatientAddressService from "@/classes/Cases/PatientAddressService";
import { renderSelect, renderSelectV2 } from "@/interfaces/DropdownInterface";
import { PatientAddressCreate } from "@/interfaces/Cases/PatientAddress";
import PatientAddressForm from "@/classes/Forms/Cases/PatientAddressForm";
import { errorResponse } from "@/helpers/errorMessage";
import { useNetworkStore } from "@/store/network";
import pickBy from "lodash/pickBy";
import DB from "@/classes/Databases/Crud";
import FormOptions from "@/classes/Forms/FormOptions";
import ResidenceType from "@/enums/ResidenceType";
import PageLoader from "@/components/loaders/PageLoader.vue"
import { useRecordStatusChecker } from "@/store/recordStatusChecker";
import Relationships from "@/classes/Forms/Relationships";
import CasePatientAddressForm from "@/classes/Forms/Cases/CasePatientAddressForm";
import { onIonViewWillEnter, onIonViewWillLeave } from "@ionic/vue";
import OfflineCasePatientAddress from "@/classes/Forms/Cases/OfflineCasePatientAddress";

const networkStore = useNetworkStore();
const isNetworkAvailable = computed(() => networkStore.isNetworkAvailable);
const db = new DB("case_patient_addresses");
const dbPatient = new DB("patients");
const router = useRouter();
const route = useRoute();
const loading = ref<boolean>(false);
const showSuccess = ref<boolean>(false);
const patientId = ref<number | null>(route.params.patient);
const caseId = ref<number | null>(route.params.case);
const regions = ref<renderSelect[]>([]);
const cities = ref<renderSelect[]>([]);
const provinces = ref<renderSelect[]>([]);
const barangays = ref<renderSelect[]>([]);
const residenceTypes = ref<renderSelectV2[]>([]);
const form = ref<PatientAddressCreate>(new PatientAddressForm());
const errors = ref<object>({});
const successMessage = ref<string>("New guardian has been added!");
const offlineParams = ref<string>(route.query.offline);
const options = new FormOptions();
const loaded = ref<boolean>(false);
const patient = ref<string>('');
const intakeParams = ref<string>(route.query.intake);
const recordStatusChecker = useRecordStatusChecker();
const relationship = new Relationships()

const save = async (): void => {
	loading.value = true;
	
	errors.value = {};
	if (!isNetworkAvailable.value) {
		const validate = form.value.offlineCreateValidation(form.value);
		errors.value = validate?.errors;

		if (validate.hasOfflineError) {
			showSuccess.value = false;
            loading.value = false;
			return;
		}
	}

    const response = await PatientAddressService.store(
		patientId.value,
		caseId.value,
		{ ...form.value },
		isNetworkAvailable.value
	);
    
    if (response?.status === 200 || response?.data != undefined) {
        await saveToOffline(response);
        showSuccess.value = true;
        successMessage.value = response.data.message;
        recordStatusChecker.isCreatedPatientAddress = true;
        loading.value = false;
    } else {
        const error = response;
        errors.value = errorResponse(error.response?.data?.errors);
        loading.value = false;
    }
};

const saveToOffline = async (response: any) => {
    if (!isNetworkAvailable.value) {
        return;
    }
    await OfflineCasePatientAddress.onlinePatientAddressSaveToOffline(response);
}

const redirectSuccess = (): void => {
	showSuccess.value = false;
	if (intakeParams.value) {
		router.replace(
			`/patient/${patientId.value}/cases/${caseId.value}/intake/address-present-location`
		);
	} else {
		router.replace(
			`/patient/${patientId.value}/cases/${caseId.value}/edit`
		);

	}
};

const getData = async (): Promise<any> => {
	loading.value = true;

    const response = await PatientAddressService.create(
		patientId.value,
		caseId.value,
		pickBy({}),
		isNetworkAvailable.value
	);

    if (response?.status === 200 || response == undefined) {
        if (isNetworkAvailable.value) {
            patient.value = response.data.patient;
            regions.value = response.data.regions;
            provinces.value = response.data.provinces;
            cities.value = response.data.cities;
            barangays.value = response.data.barangays;
            residenceTypes.value = response.data.residenceTypes;
        } else {
            regions.value = await options.regions();
            provinces.value = await options.provinces();
            cities.value = await options.cities();
            barangays.value = await options.barangays();
            residenceTypes.value = ResidenceType;
        }

        loaded.value = true;
        loading.value = false;
    } else {
        console.log(response);
        loaded.value = false;
    }
};

const renderPatient = async (): Promise<any> => {
    if (!isNetworkAvailable.value) {
        const response = await dbPatient.edit(patientId.value, false, 'patients');
        const item = response.data.item;
        if (item.length === undefined) {
            patient.value = `${relationship.capitalFirstLetter(item.last_name)}, ${relationship.capitalFirstLetter(item.first_name)}`;
        }
    }

}

const resetForm = () => {
	form.value.region_id = null;
	form.value.province_id = null;
	form.value.city_id = null;
	form.value.barangay_id = null;
	form.value.residence_type = null;
	form.value.age = null;
	form.value.address = null;
	form.value.direction = null;
}

// watch(
// 	() => isNetworkAvailable.value,
// 	(val) => {
// 		console.log("Watcher - Network: " + val);
// 		getData();
// 	}
// );

onIonViewWillLeave(() => resetForm());

onIonViewWillEnter(async () => {
    recordStatusChecker.isCreatedPatientAddress = false;
    resetForm();
    await renderPatient();
    await getData();    
});

// onUnmounted(() => resetForm());

// onMounted(async () => {
//     recordStatusChecker.isCreatedPatientAddress = false;
//     resetForm();
//     await renderPatient();
//     await getData();
// });
</script>
