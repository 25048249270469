import http from "@/axios";
import { PatientLog } from "@/interfaces/Settings/PatientLog";

class PatientLogService implements PatientLog {
	async index(payload: any = {}): Promise<any> {
		return await http.get("patient-logs", { params: payload });
	}

	async login(scopeBinding: any, payload: any = {}): Promise<any> {
		return await http.post(`patient-logs/${scopeBinding.patient}/login`, payload);
	}

	async logout(scopeBinding: any, payload: any = {}): Promise<any> {
		return await http.post(`patient-logs/${scopeBinding.patient}/logout`, payload);
	}

	async checkStatus(scopeBinding: any): Promise<any> {
		return await http.post(`patient-logs/${scopeBinding.patient}/check-status`);
	}
}

export default new PatientLogService();
