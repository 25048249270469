import { format } from "date-fns"
import { formatInTimeZone } from 'date-fns-tz'

class DateFormatter {

    defaultTimeStamp( dateTime: string ) : string {

      const date = new Date(dateTime);

      if (isNaN(date.getTime())) {
        return "invalid";
      }
      
      return formatInTimeZone(date, 'Asia/Manila', "MMM dd, yyy - hh:mm:ss");
    }

    customDateFormat( dateTime: string, customeFormat: string) : string {

      const date = new Date(dateTime);

      if (isNaN(date.getTime())) {
        return "invalid";
      }
      
      return formatInTimeZone(date, 'Asia/Manila', customeFormat);
  }
}

export default new DateFormatter()