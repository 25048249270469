import { EnumsInterface } from "@/interfaces/EnumsInterface";

const InterventionType:EnumsInterface[] = [
    {id: 0, value: "Social Work", label: "Social Work", description: "Social Work"},
    {id: 1, value: "Medical", label: "Medical", description: "Medical"},
    {id: 2, value: "Mental", label: "Mental", description: "Mental"},
    {id: 3, value: "Development", label: "Development", description: "Development"},
    {id: 4, value: "Legal", label: "Legal", description: "Legal"},
    {id: 5, value: "Teleconsultation", label: "Teleconsultation", description: "Teleconsultation"},
    {id: 6, value: "Telepsychiatry", label: "Telepsychiatry", description: "Telepsychiatry"},
    {id: 7, value: "Admin", label: "Admin", description: "Admin"},
]

export default InterventionType