import Pagination from "./Pagination";

export default class PaginationDataDictionary extends Pagination {

    search(params: any): string {
        let query = '';

        if (params.query) {
            query += ` WHERE ( name LIKE '%${params.query}%' OR content LIKE '%${params.query}%' )`
        }
    
        return query;
    }

}