import http from "@/axios";
import { SafetyAssessment } from "@/interfaces/Cases/SafetyAssessment";
import CaseSafetyAssessmentForm from "../Forms/Cases/CaseSafetyAssessmentForm";
import DB from "@/classes/Databases/Crud";
import SqliteRecordChecker from "../Forms/SqliteRecordChecker";

const db = new DB("case_immediate_safety_assessments");
const dbCase = new DB("cases");
const dbPatient = new DB("patients");
const checkRecord = new SqliteRecordChecker();
class IntakeService implements SafetyAssessment {
	async show(
		patient: number,
		patientCase: number,
		assessment: number,
		payload: any,
		networkStatus = true
	): Promise<any> {
		if (networkStatus) {
			return await http.get(`patients/${patient}/cases/${patientCase}/safety-assessment`, { params: payload });
		}

		const fetch = await checkRecord.init(db, assessment, false, 'case_immediate_safety_assessments');

		return fetch.result;
	}

	async create(patient: number, patientCase: number, payload: object, networkStatus = true): Promise<any> {
		if (networkStatus) {
			return await http.get(`patients/${patient}/cases/${patientCase}/safety-assessment/create`, {
				params: payload,
			});
		}

	}

	async store(patient: number, patientCase: number, payload: any, networkStatus = true): Promise<any> {
		if (networkStatus) {
			return await http.post(`patients/${patient}/cases/${patientCase}/safety-assessment`, payload);
		}
        
        const fetch = await checkRecord.init(dbCase, patientCase, false, 'cases');
		const result = fetch.result;
        payload.isOfflineData = fetch.isOfflineData;

		if (result.data.item.length == undefined) {
			const form = new CaseSafetyAssessmentForm({}, {});
			const createForm = form.createForm(payload, result.data.item);
            await db.delete({cases_id: patientCase}, 'case_immediate_safety_assessments');
            return await db.insert(createForm, null, false, 'case_immediate_safety_assessments');
		}
	}

	async edit(
		patient: number,
		patientCase: number,
		assessment: number,
		payload: any,
		networkStatus = true
	): Promise<any> {
		if (networkStatus) {
			return await http.get(`patients/${patient}/cases/${patientCase}/safety-assessment/${assessment}/edit`, {
				params: payload,
			});
		}
		const fetch = await checkRecord.init(db, assessment, false, 'case_immediate_safety_assessments');
        if (fetch.result.data.item.length == 0) {
            const response = await dbCase.edit(patientCase, false, 'cases');
            if (response.data.item.length === undefined) {
                const item = JSON.parse(response.data.item.content);
                return {
                    data: {
                        item: {
                            content: JSON.stringify({
                                case_number: item.case_number,
                                initiated_at: item.initiated_at,
                                name: `${item.last_name}, ${item.first_name}`,
                                first_name: item.first_name,
                                last_name: item.last_name,
                                middle_name: item.middle_name,
                                assessment_date: item.initiated_at,
                                safety_assessment_questions: [],
                            }),
                        }
                    }
                }
            }
        }

		return fetch.result;
	}

	async update(
		patient: number,
		patientCase: number,
		assessment: number,
		payload: any,
		networkStatus = true
	): Promise<any> {
		if (networkStatus) {
			return await http.put(
				`patients/${patient}/cases/${patientCase}/safety-assessment/${assessment}/update`,
				payload
			);
		}
        const response = await db.edit(assessment, false, 'case_immediate_safety_assessments');
        if (response.data.item.length === undefined) {
            const form = new CaseSafetyAssessmentForm({}, {});
			const updateForm = form.updateForm(payload, response.data.item);
            await db.delete({id: assessment}, 'case_immediate_safety_assessments');
            return await db.insert(updateForm, null, false, 'case_immediate_safety_assessments');
        }
	}

	public async generatePDFAccess(patient:string, cases:string, assessment: number,) : Promise<JSON> {
        return await http.post(`patients/${patient}/cases/${cases}/safety-assessment/${assessment}/generatePDFAccess`);
    }

    public async downloadPDF(assessment:number, token:string, networkStatus:boolean): Promise<void> {		
		if (networkStatus) {
			window.open(`${process.env.VUE_APP_URL}pdf/${assessment}/downloadSafetyAssessment/${token}`);
		}
	}

}

export default new IntakeService();
