<template>
	<div v-if="!typeTwo && !typeThree" class="flex justify-between">
		<TabGroup>
			<TabList class="flex space-x-4">
				<div v-for="tab in tabs" :key="tab.name">
					<Tab v-if="tab.allowed" as="template" @click="visitPage(tab)">
						
						<button
							:class="[
								route.fullPath == tab.href ? 'text-primary-600' : 'text-gray-600 hover:text-primary-600',
								'text-sm focus:outline-none w-max relative cursor-pointer relative ring-0 outline-none transition',
							]">
							<span>{{ tab.name }}</span>
							<span
								v-if="tab.count"
								:class="[
									route.fullPath == tab.href
										? 'bg-primary-300 text-primary-600'
										: 'bg-[#F3F4F6] text-gray-600',
									'py-0.5 px-2 ml-2 rounded-[10px] inline-block text-xs',
								]"
								>{{ tab.count }}</span
							>
							<span
								class="absolute bg-primary-600 bottom-[-25px] w-full h-1 rounded-t-lg block transition"
								v-if="route.fullPath == tab.href" />
						</button>
					</Tab>
				</div>
			</TabList>

			<!-- <TabPanels class="mt-2">
				<TabPanel> </TabPanel>
			</TabPanels> -->
		</TabGroup>

		<!-- <div class="flex items-center space-x-2 px-7">
			<template v-if="buttonItems">
				<slot name="buttons" />
			</template>
		</div> -->
	</div>
	<div v-if="typeTwo" class="flex lg:flex-col flex-row gap-2">
		<button
			type="button"
			v-for="tab in tabs"
			:key="tab.name"
			@click="visitPage(tab)"
			class="px-3 py-2 text-xs font-bold focus:outline-none w-max"
			:class="route.fullPath == tab.href ? 'bg-primary-300 text-primary-600' : 'bg-transparent text-neutral-600'">
			{{ tab.name }}
		</button>
	</div>
	<div v-if="typeThree" class="flex gap-2">
		<button
			type="button"
			v-for="tab in tabs"
			:key="tab.name"
			@click="visitPage(tab)"
			class="md:px-1 px-1.5 md:py-0.5 py-1.5 md:text-xs text-sm font-bold focus:outline-none w-max md:rounded-lg rounded-full relative after:content-[''] after:border-r after:border-neutral-100 after:top-0 after:-right-1 after:absolute after:w-[1px] after:h-full"
			:class="isSelectedTab(tab.tab) ? 'bg-primary-600 text-white' : 'bg-transparent text-neutral-600'"
			>{{ tab.name }}
			<span
				v-if="tab.count"
				:class="[
					isSelectedTab(tab.tab)
						? 'md:bg-primary-300 bg-white text-primary-600'
						: 'bg-primary-600 bg-opacity-20 text-primary-600',
					'md:py-0 py-0.5 md:px-1 px-2 md:ml-1 ml-2 rounded-full font-bold inline-block text-xs',
				]"
			>{{ tab.count }}</span
			>
		</button>
	</div>
</template>

<script setup lang="ts">
// import { ref } from 'vue';
import { TabGroup, TabList, Tab, TabPanels, TabPanel } from "@headlessui/vue";
import { useRouter, useRoute } from "vue-router";

const emit = defineEmits(["update:tab"]);
const props = defineProps({
	tabs: {
		type: Object,
		default: () => ({}),
	},
	buttonItems: {
		type: Boolean,
		default: false,
	},
	href: {
		type: String,
		default: null,
	},
	typeTwo: {
		type: Boolean,
		default: false,
	},
	typeThree: {
		type: Boolean,
		default: false,
	},
	returnObject: {
		type: Boolean,
		default: false,
	},
	activeTab: {
		type: String,
		default: null,
	},
});

const router = useRouter();
const route = useRoute();

const isSelectedTab = (value:any) => {
	return props.activeTab == value;
};

const visitPage = (data: any) => {
	emit("update:tab", props.returnObject ? data : data.tab); // callback emit for axios fetching
	if (data?.href) {
		router.push(data.href); // push on a web browser
	}
};
</script>
