import { OfflineAction } from "@/views/enums/OfflineAction";
import Storage from "@/helpers/storage";
import Relationships from "../../Relationships";

const storage = new Storage();
const relationship = new Relationships();

const setUpdateForm = (item: any, content: any) => {

	// General
	content.encoded_by = storage.get("user");

	// Social Work
	content.intervention_type_list_id = checkHasContent(item.intervention_type_list_id, content.intervention_type_list_id);
	content.intervention_type_list = item.intervention_type_list_id ? relationship.interventionTypeLists(item.intervention_type_list_id) : content.intervention_type_list;
	content.intervention_type_meta = item.intervention_type ? relationship.interventionTypes(item.intervention_type) : content.intervention_type_meta;
	content.schedule_date = checkHasContent(item.schedule_date, content.schedule_date);
	content.scheduled_date_formatted = item.schedule_date ? formatDate(item.schedule_date, 'short') : content.schedule_date;
	content.assigned_group = checkHasContent(item.assigned_group, content.assigned_group);
	content.done_by = relationship.personnels(item.done_by);
	content.done_on = checkHasContent(item.done_on, content.done_on);
	content.done_on_formatted = item.done_on ? formatDate(item.done_on).replace(/\//g, '-') : content.done_on;
	content.attachment = checkHasContent(item.attachment, content.attachment);
	content.remarks = checkHasContent(item.remarks, content.remarks);
    content.personnel_id = checkHasContent(item.personnel_id, content.personnel_id);
    content.court = checkHasContent(item.court, content.court);
    content.court_hearing_date = checkHasContent(item.court_hearing_date, content.court_hearing_date);
    content.lbc_trucking_number = checkHasContent(item.lbc_trucking_number, content.lbc_trucking_number);
    content.decisions = checkHasContent(item.decisions, content.decisions);
    content.case_conference_sub_type = checkHasContent(item.case_conference_sub_type, content.case_conference_sub_type);
    content.coordination_testimony_sub_type = checkHasContent(item.coordination_testimony_sub_type, content.coordination_testimony_sub_type);
    content.ptaq_scoring = checkHasContent(item.ptaq_scoring, content.ptaq_scoring);
    content.refer_to = checkHasContent(item.refer_to, content.refer_to);
    content.item_or_part = checkHasContent(item.item_or_part, content.item_or_part);
    content.response_id = checkHasContent(item.response_id, content.response_id);
    content.is_administer_cssrs = checkHasContent(item.is_administer_cssrs, content.is_administer_cssrs);
    content.level = checkHasContent(item.level, content.level);
    content.referral_sub_type_id = checkHasContent(item.referral_sub_type_id, content.referral_sub_type_id);
    content.teleconsult_sub_type = checkHasContent(item.teleconsult_sub_type, content.teleconsult_sub_type);
    content.testimony_sub_type = checkHasContent(item.testimony_sub_type, content.testimony_sub_type);
    content.follow_up_sub_type = checkHasContent(item.follow_up_sub_type, content.follow_up_sub_type);
    content.cases_id = checkHasContent(item.cases_id, content.cases_id);
	content.cases = item.cases_id ? relationship.cases(item.cases_id) : content.cases;
    content.ptaq_response = checkHasContent(item.ptaq_response, content.ptaq_response);

	// Admin
    content.description = checkHasContent(item.description, content.description);

	// Medical
	content.labolatories = checkHasContent(item.labolatories, content.labolatories);
	content.medical_certificate_sub_type = checkHasContent(item.medical_certificate_sub_type, content.medical_certificate_sub_type);
	content.medication_sub_type = checkHasContent(item.medication_sub_type, content.medication_sub_type);
	content.radiological_exam_sub_type = checkHasContent(item.radiological_exam_sub_type, content.radiological_exam_sub_type);
	content.telemedicine_sub_type = checkHasContent(item.telemedicine_sub_type, content.telemedicine_sub_type);
    content.lab_schedule_date = checkHasContent(item.lab_schedule_date, content.lab_schedule_date);
	content.lab_assigned_group = checkHasContent(item.lab_assigned_group, content.lab_assigned_group);
	content.lab_done_by = checkHasContent(item.lab_done_by, content.lab_done_by);
	content.lab_done_on = checkHasContent(item.lab_done_on, content.lab_done_on);
	content.lab_remarks = checkHasContent(item.lab_remarks, content.lab_remarks);
	content.lab_result = checkHasContent(item.lab_result, content.lab_result);

	// Mental Health
    content.psych_screening_sub_type = checkHasContent(item.psych_screening_sub_type, content.psych_screening_sub_type);
    content.therapy_sub_type = checkHasContent(item.therapy_sub_type, content.therapy_sub_type);

	// Development Pedia 

	// Legal
	content.docket_no = checkHasContent(item.docket_no, content.docket_no);
	content.protection_order_sub_type = checkHasContent(item.protection_order_sub_type, content.protection_order_sub_type);
    content.case_follow_up_sub_type = checkHasContent(item.case_follow_up_sub_type, content.case_follow_up_sub_type);
    content.affidavit_sub_type = checkHasContent(item.affidavit_sub_type, content.affidavit_sub_type);
    content.date = checkHasContent(item.date, content.date);

	// Teleconsult
	content.telcpu_location_id = checkHasContent(item.telcpu_location_id, content.telcpu_location_id);
	content.telcpu_id = checkHasContent(item.telcpu_id, content.telcpu_id);
	content.teleconsultation_intervention_type = checkHasContent(item.teleconsultation_intervention_type, content.teleconsultation_intervention_type);
	content.specific_intervention_id = checkHasContent(item.specific_intervention_id, content.specific_intervention_id);
	content.use_mode = checkHasContent(item.use_mode, content.use_mode);
	content.use_platform = checkHasContent(item.use_platform, content.use_platform);
	content.teleconsultation_status = checkHasContent(item.teleconsultation_status, content.teleconsultation_status);
	content.disposition = checkHasContent(item.disposition, content.disposition);

	// Telepsychiatry
	content.age = checkHasContent(item.age, content.age);
	content.birthday = checkHasContent(item.birthday, content.birthday);
	content.assessment_date = checkHasContent(item.assessment_date, content.assessment_date);
	content.educational_attainment_id = checkHasContent(item.educational_attainment_id, content.educational_attainment_id);
	content.case_guardian_id = checkHasContent(item.case_guardian_id, content.case_guardian_id);
	content.office_id = checkHasContent(item.office_id, content.office_id);
	content.assessment = checkHasContent(item.assessment, content.assessment);
	content.psychiatric_evaluation = checkHasContent(item.psychiatric_evaluation, content.psychiatric_evaluation);
	content.circumtance_refferal = checkHasContent(item.circumtance_refferal, content.circumtance_refferal);
	content.background_history = checkHasContent(item.background_history, content.background_history);
	content.behavioral_observation = checkHasContent(item.behavioral_observation, content.behavioral_observation);
	content.test_administered = checkHasContent(item.test_administered, content.test_administered);
	content.details = checkHasContent(item.details, content.details);
	content.test_result = checkHasContent(item.test_result, content.test_result);
	content.diagnoses = checkHasContent(item.diagnoses, content.diagnoses);
	content.recommendation = checkHasContent(item.recommendation, content.recommendation);
	content.telepsychiatry_remarks = checkHasContent(item.telepsychiatry_remarks, content.telepsychiatry_remarks);
    content.guardian = checkHasContent(item.guardian, content.guardian);
	content.wcpu = checkHasContent(item.wcpu, content.wcpu);
	
	// With Source
	if(content.source) {

		content.source.personnel_id = checkHasContent(item.personnel_id, content.personnel_id);
		content.source.court = checkHasContent(item.court, content.court);
		content.source.court_hearing_date = checkHasContent(item.court_hearing_date, content.court_hearing_date);
		content.source.lbc_trucking_number = checkHasContent(item.lbc_trucking_number, content.lbc_trucking_number);
		content.source.decisions = checkHasContent(item.decisions, content.decisions);
		content.source.case_conference_sub_type = checkHasContent(item.case_conference_sub_type, content.case_conference_sub_type);
		content.source.coordination_testimony_sub_type = checkHasContent(item.coordination_testimony_sub_type, content.coordination_testimony_sub_type);
		content.source.ptaq_scoring = checkHasContent(item.ptaq_scoring, content.ptaq_scoring);
		content.source.refer_to = checkHasContent(item.refer_to, content.refer_to);
		content.source.item_or_part = checkHasContent(item.item_or_part, content.item_or_part);
		content.source.response_id = checkHasContent(item.response_id, content.response_id);
		content.source.is_administer_cssrs = checkHasContent(item.is_administer_cssrs, content.is_administer_cssrs);
		content.source.level = checkHasContent(item.level, content.level);
		content.source.referral_sub_type_id = checkHasContent(item.referral_sub_type_id, content.referral_sub_type_id);
		content.source.teleconsult_sub_type = checkHasContent(item.teleconsult_sub_type, content.teleconsult_sub_type);
		content.source.testimony_sub_type = checkHasContent(item.testimony_sub_type, content.testimony_sub_type);
		content.source.follow_up_sub_type = checkHasContent(item.follow_up_sub_type, content.follow_up_sub_type);
		content.source.ptaq_response = typeof item.ptaq_response == 'string' ? item.ptaq_response.split(',') : content.ptaq_response;

        content.source.age = checkHasContent(item.age, content.age);
        content.source.birthday = checkHasContent(item.birthday, content.birthday);
        content.source.assessment_date = checkHasContent(item.assessment_date, content.assessment_date);
        content.source.educational_attainment_id = checkHasContent(item.educational_attainment_id, content.educational_attainment_id);
        content.source.case_guardian_id = checkHasContent(item.case_guardian_id, content.case_guardian_id);
        content.source.office_id = checkHasContent(item.office_id, content.office_id);
        content.source.assessment = checkHasContent(item.assessment, content.assessment);
        content.source.psychiatric_evaluation = checkHasContent(item.psychiatric_evaluation, content.psychiatric_evaluation);
        content.source.circumtance_refferal = checkHasContent(item.circumtance_refferal, content.circumtance_refferal);
        content.source.background_history = checkHasContent(item.background_history, content.background_history);
        content.source.behavioral_observation = checkHasContent(item.behavioral_observation, content.behavioral_observation);
        content.source.test_administered = checkHasContent(item.test_administered, content.test_administered) ;
        content.source.details = checkHasContent(item.details, content.details);
        content.source.test_result = checkHasContent(item.test_result, content.test_result);
        content.source.cases_id = checkHasContent(item.cases_id, content.cases_id);
        content.source.diagnoses = checkHasContent(item.diagnoses, content.diagnoses);
        content.source.recommendation = checkHasContent(item.recommendation, content.recommendation);
        content.source.telepsychiatry_remarks = checkHasContent(item.telepsychiatry_remarks, content.telepsychiatry_remarks);

        content.source.telcpu_location_id = checkHasContent(item.telcpu_location_id, content.telcpu_location_id);
        content.source.telcpu_id = checkHasContent(item.telcpu_id, content.telcpu_id);
        content.source.teleconsultation_intervention_type = checkHasContent(item.teleconsultation_intervention_type, content.teleconsultation_intervention_type);
        content.source.specific_intervention_id = checkHasContent(item.specific_intervention_id, content.specific_intervention_id);
        content.source.use_mode = checkHasContent(item.use_mode, content.use_mode);
        content.source.use_platform = checkHasContent(item.use_platform, content.use_platform);
        content.source.teleconsultation_status = checkHasContent(item.teleconsultation_status, content.teleconsultation_status);
        content.source.disposition = checkHasContent(item.disposition, content.disposition);

        content.source.docket_no = checkHasContent(item.docket_no, content.docket_no);
        content.source.protection_order_sub_type = checkHasContent(item.protection_order_sub_type, content.protection_order_sub_type);
        content.source.case_follow_up_sub_type = checkHasContent(item.case_follow_up_sub_type, content.case_follow_up_sub_type);
        content.source.affidavit_sub_type = checkHasContent(item.affidavit_sub_type, content.affidavit_sub_type);
        content.source.date = checkHasContent(item.date, content.date);

        content.source.telemedicine_sub_type = checkHasContent(item.telemedicine_sub_type, content.telemedicine_sub_type);

        content.source.psych_screening_sub_type = checkHasContent(item.psych_screening_sub_type, content.psych_screening_sub_type);
        content.source.therapy_sub_type = checkHasContent(item.therapy_sub_type, content.therapy_sub_type);

        content.source.labolatories = checkHasContent(item.labolatories, content.labolatories);
        content.source.medical_certificate_sub_type = checkHasContent(item.medical_certificate_sub_type, content.medical_certificate_sub_type);
        content.source.medication_sub_type = checkHasContent(item.medication_sub_type, content.medication_sub_type);
        content.source.radiological_exam_sub_type = checkHasContent(item.radiological_exam_sub_type, content.radiological_exam_sub_type);
        content.source.lab_schedule_date = checkHasContent(item.lab_schedule_date, content.lab_schedule_date);
        content.source.lab_assigned_group = checkHasContent(item.lab_assigned_group, content.lab_assigned_group);
        content.source.lab_done_by = checkHasContent(item.lab_done_by, content.lab_done_by);
        content.source.lab_done_on = checkHasContent(item.lab_done_on, content.lab_done_on);
        content.source.lab_remarks = checkHasContent(item.lab_remarks, content.lab_remarks);
        content.source.lab_result = checkHasContent(item.lab_result, content.lab_result);
	}
	
	return JSON.stringify(content);
};

const setNewForm = (item: any, content: any) => {
    const data = item;

	// General
	data.encoded_by = storage.get("user");

	// Social Work
	data.intervention_type_list_id = item.intervention_type_list_id
	data.intervention_type_list = relationship.interventionTypeLists(item.intervention_type_list_id)
	data.intervention_type_meta = relationship.interventionTypes(item.intervention_type)
	data.schedule_date = item.schedule_date
	data.scheduled_date_formatted	 = item.schedule_date ? formatDate(item.schedule_date, 'short') : null;
	data.assigned_group = item.assigned_group
	data.done_by = relationship.personnels(item.done_by)
	data.done_on = item.done_on
	data.done_on_formatted = item.done_on ? formatDate(item.done_on).replace(/\//g, '-') : null;
	data.attachment = item.attachment
	data.remarks = item.remarks
    data.personnel_id = item.personnel_id
    data.court = item.court
    data.court_hearing_date = item.court_hearing_date
    data.lbc_trucking_number = item.lbc_trucking_number
    data.decisions = item.decisions
    data.case_conference_sub_type = item.case_conference_sub_type
    data.coordination_testimony_sub_type = item.coordination_testimony_sub_type
    data.ptaq_scoring = item.ptaq_scoring
    data.refer_to = item.refer_to
    data.item_or_part = item.item_or_part
    data.response_id = item.response_id
    data.is_administer_cssrs = item.is_administer_cssrs
    data.level = item.level
    data.referral_sub_type_id = item.referral_sub_type_id
    data.teleconsult_sub_type = item.teleconsult_sub_type
    data.testimony_sub_type = item.testimony_sub_type
    data.follow_up_sub_type = item.follow_up_sub_type
    data.cases_id = checkHasContent(item.cases_id, content.id)
    data.cases = content
    data.ptaq_response = item.ptaq_response

	// Admin
    data.description = item.description;

	// Medical
	data.labolatories = item.labolatories;
	data.medical_certificate_sub_type = item.medical_certificate_sub_type;
	data.medication_sub_type = item.medication_sub_type;
	data.radiological_exam_sub_type = item.radiological_exam_sub_type;
	data.telemedicine_sub_type = item.telemedicine_sub_type;
    data.lab_schedule_date = item.lab_schedule_date;
	data.lab_assigned_group = item.lab_assigned_group;
	data.lab_done_by = item.lab_done_by;
	data.lab_done_on = item.lab_done_on;
	data.lab_remarks = item.lab_remarks;
	data.lab_result = item.lab_result;

	// Mental Health
    data.psych_screening_sub_type = item.psych_screening_sub_type;
    data.therapy_sub_type = item.therapy_sub_type;

	// Development Pedia 

	// Legal
	data.docket_no = item.docket_no;
	data.protection_order_sub_type = item.protection_order_sub_type;
    data.case_follow_up_sub_type = item.case_follow_up_sub_type;
    data.affidavit_sub_type = item.affidavit_sub_type;
    data.date = item.date;

	// Teleconsult
	data.telcpu_location_id = item.telcpu_location_id;
	data.telcpu_id = item.telcpu_id;
	data.teleconsultation_intervention_type = item.teleconsultation_intervention_type;
	data.specific_intervention_id = item.specific_intervention_id;
	data.use_mode = item.use_mode;
	data.use_platform = item.use_platform;
	data.teleconsultation_status = item.teleconsultation_status;
	data.disposition = item.disposition;

	// Telepsychiatry
	data.age = item.age;
	data.birthday = item.birthday;
	data.assessment_date = item.assessment_date;
	data.educational_attainment_id = item.educational_attainment_id;
	data.case_guardian_id = item.case_guardian_id;
	data.office_id = item.office_id;
	data.assessment = item.assessment;
	data.psychiatric_evaluation = item.psychiatric_evaluation;
	data.circumtance_refferal = item.circumtance_refferal;
	data.background_history = item.background_history;
	data.behavioral_observation = item.behavioral_observation;
	data.test_administered = item.test_administered;
	data.details = item.details;
	data.test_result = item.test_result;
	data.diagnoses = item.diagnoses;
	data.recommendation = item.recommendation;
	data.telepsychiatry_remarks = item.telepsychiatry_remarks;
    data.guardian = item.guardian;
	data.wcpu = item.wcpu;

	// With Source
	if(data.source) {
		
		data.source.personnel_id = item.personnel_id;
		data.source.court = item.court;
		data.source.court_hearing_date = item.court_hearing_date;
		data.source.lbc_trucking_number = item.lbc_trucking_number;
		data.source.decisions = item.decisions;
		data.source.case_conference_sub_type = item.case_conference_sub_type;
		data.source.coordination_testimony_sub_type = item.coordination_testimony_sub_type;
		data.source.ptaq_scoring = item.ptaq_scoring;
		data.source.refer_to = item.refer_to;
		data.source.item_or_part = item.item_or_part;
		data.source.response_id = item.response_id;
		data.source.is_administer_cssrs = item.is_administer_cssrs;
		data.source.level = item.level;
		data.source.referral_sub_type_id = item.referral_sub_type_id;
		data.source.teleconsult_sub_type = item.teleconsult_sub_type;
		data.source.testimony_sub_type = item.testimony_sub_type;
		data.source.follow_up_sub_type = item.follow_up_sub_type;
		data.source.ptaq_response = item.ptaq_response?.split(',');
	
		data.source.age = item.age;
		data.source.birthday = item.birthday;
		data.source.assessment_date = item.assessment_date;
		data.source.educational_attainment_id = item.educational_attainment_id;
		data.source.case_guardian_id = item.case_guardian_id;
		data.source.office_id = item.office_id;
		data.source.assessment = item.assessment;
		data.source.psychiatric_evaluation = item.psychiatric_evaluation;
		data.source.circumtance_refferal = item.circumtance_refferal;
		data.source.background_history = item.background_history;
		data.source.behavioral_observation = item.behavioral_observation;
		data.source.test_administered = item.test_administered ;
		data.source.details = item.details;
		data.source.test_result = item.test_result;
		data.source.cases_id = item.cases_id;
		data.source.diagnoses = item.diagnoses;
		data.source.recommendation = item.recommendation;
		data.source.telepsychiatry_remarks = item.telepsychiatry_remarks;
	
		data.source.telcpu_location_id = item.telcpu_location_id;
		data.source.telcpu_id = item.telcpu_id;
		data.source.teleconsultation_intervention_type = item.teleconsultation_intervention_type;
		data.source.specific_intervention_id = item.specific_intervention_id;
		data.source.use_mode = item.use_mode;
		data.source.use_platform = item.use_platform;
		data.source.teleconsultation_status = item.teleconsultation_status;
		data.source.disposition = item.disposition;
	
		data.source.docket_no = item.docket_no;
		data.source.protection_order_sub_type = item.protection_order_sub_type;
		data.source.case_follow_up_sub_type = item.case_follow_up_sub_type;
		data.source.affidavit_sub_type = item.affidavit_sub_type;
		data.source.date = item.date;
	
		data.source.telemedicine_sub_type = item.telemedicine_sub_type;
	
		data.source.psych_screening_sub_type = item.psych_screening_sub_type;
		data.source.therapy_sub_type = item.therapy_sub_type;
	
		data.source.labolatories = item.labolatories;
		data.source.medical_certificate_sub_type = item.medical_certificate_sub_type;
		data.source.medication_sub_type = item.medication_sub_type;
		data.source.radiological_exam_sub_type = item.radiological_exam_sub_type;
		data.source.lab_schedule_date = item.lab_schedule_date;
		data.source.lab_assigned_group = item.lab_assigned_group;
		data.source.lab_done_by = item.lab_done_by;
		data.source.lab_done_on = item.lab_done_on;
		data.source.lab_remarks = item.lab_remarks;
		data.source.lab_result = item.lab_result;
	}

	return JSON.stringify(data);
};

const checkHasContent = (item: any, content: any) => {
	// console.log(typeof item + ': ' + item)
	// console.log(item)
	if(typeof item == 'undefined') {
		return item != undefined ? item : content;
	} else if(typeof item == 'string') {
		return item != "" ? item : content;
	}  else {
		return item != null ? item : content;
	}
}

const formatDate = (item: any, month: any = '2-digit', day: any = '2-digit', year: any = 'numeric', hour: any = 'numeric', minute: any = 'numeric') => {
	return new Date(item).toLocaleDateString('en-US', { month: month, day: day, year: year, hour: hour, minute: minute });
}
export default class InterventionOfflineForm {
	id: number | null;
	patient_id: number | null;
	cases_id: number | null;
	offline_cases_id: string | null;
	intervention_type_list_id: number | null;
	intervention_type: number | null;
	content: string | null;
	encoded_by: number | null;
	action: number | null;
	is_sync: boolean;
	created_at: any | null;
	updated_at: any | null;
	deleted_at: any | null;

	constructor(item: any, res: any) {
		this.id = item.id;
		this.patient_id = item.cases?.patient_id;
		this.cases_id = res?.id || item.cases_id;
		this.offline_cases_id = "";
		this.intervention_type_list_id = item.intervention_type_list_id;
		this.intervention_type = item.intervention_type;
		this.content = JSON.stringify(item);
		this.encoded_by = res?.encoded_by || item.encoded_by;
		this.action = OfflineAction.UPDATE_RECORD;
		this.is_sync = false;
		this.created_at = "";
		this.updated_at = null;
		this.deleted_at = null;
	}

	updateForm(item: any, res: any): object {
		const date = new Date().toJSON();
		const data = <any>{};
		data.id = res.id;
        if (!res.id) {
            data.offline_id = res.offline_id;
        }
		data.cases_id = res?.cases_id;
		data.offline_cases_id = res?.offline_cases_id;
		data.intervention_type_list_id = item?.intervention_type_list_id;
		data.intervention_type = item?.intervention_type;
		data.content = setUpdateForm(item, JSON.parse(res.content));
		data.encoded_by = 0;
		data.action = OfflineAction.UPDATE_RECORD;
		data.is_sync = false;
		data.created_at = "";
		data.updated_at = date;
		data.deleted_at = "";

		return data;
	}

    createForm(item: any, res: any): object {
		const date = new Date().toJSON();
		const data = <any>{};
		data.id = "";
		data.cases_id = res?.id;
		data.patient_id = res?.patient_id;
		data.offline_cases_id = res?.offline_id;
		data.intervention_type_list_id = item?.intervention_type_list_id;
		data.intervention_type = item?.intervention_type;
		data.content = setNewForm(item, Object.keys(res).length > 0 ? JSON.parse(res.content) : {});
		data.encoded_by = 0;
		data.action = OfflineAction.NEW_RECORD;
		data.is_sync = false;
		data.created_at = "";
		data.updated_at = date;
		data.deleted_at = "";

		return data;
	}

	deleteForm(item: any, res: any): object {
		const date = new Date().toJSON();

		res.action = OfflineAction.DELETE_RECORD;
		res.deleted_at = date;
		res.is_deleted_offline = true;
		
		return res;
	}
}
