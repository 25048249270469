<template>
    <intake-layout :patient="patient" v-if="loaded" :isDirty="isDirty">
        <template #actionButtons>
            <button-component 
                btnWidth="quinary"
                @click="router.replace(`/patient/${patientId}/cases/${caseId}/edit`)"
                customClass="quinary md:w-auto w-full"
            >
                Cancel
            </button-component>
            <button-component 
                customClass="primary md:w-auto w-full"
                @click="save"
            >
                Save Changes
            </button-component>
        </template>

        <div class="col-span-12 lg:col-span-4 md:col-span-6">
            <text-input
                label="Last Name"
                placeholder="Last Name"
                name="last_name"
                id="last_name"
                required
                v-model="casesInformation.last_name"
                disabled
            />
        </div>

        <div class="col-span-12 lg:col-span-4 md:col-span-6">
            <text-input
                label="First Name"
                placeholder="First Name"
                name="first_name"
                id="first_name"
                required
                v-model="casesInformation.first_name"
                disabled
            />
        </div>

        <div class="col-span-12 lg:col-span-4 md:col-span-6">
            <div class="flex space-x-3">
                <text-input
                    label="Sex"
                    placeholder="Sex"
                    name="Sex"
                    id="sex"
                    required
                    v-model="casesInformation.sex_at_birth"
                    disabled
                />
                <text-input
                    label="Gender"
                    placeholder="Gender"
                    name="Gender"
                    id="gender"
                    required
                    v-model="casesInformation.gender"
                    disabled
                />
            </div>
        </div>

        <div 
            :class="[
                casesInformation.birthday_type === 2 ? 'col-span-8':  
                casesInformation.birthday_type === 0 ? 'lg:col-span-4 md:col-span-6 col-span-12':  
                'col-span-7'
            ]"
        >
            <date-picker
                v-if="!loading"
                id="date_initiated"
                name="date_initiated_date_time"
                label="Date initiated"
                required
                v-model="casesInformation.date_initiated"
                disabled
            />
        </div>
        
        <UserBirthdateAge 
            v-if="!loading"
            :form="casesInformation"
            :errors="errors"
            disabled
            customClass="lg:col-span-4 md:col-span-6 col-span-12"
        />

        <!-- <div 
            class="flex flex-wrap justify-between gap-3 mt-5 lg:flex-nowrap"
            :class="[
                casesInformation.birthday_type === 0 ? 'lg:col-span-4 md:col-span-6 col-span-12':  
                casesInformation.birthday_type === 1 ? 'col-span-3':  
                'col-span-3'
            ]"
        >
            <radio-group 
                :options="optionSelected.data" 
                name="option_selected" 
                required 
                v-model="casesInformation.birthday_type" 
                readonly
            />
        </div>
        <template v-if="casesInformation.birthday_type === 0">
            <div class="col-span-3">
                <date-picker
                    v-if="!loading"
                    id="birthdate"
                    name="date_birth_date_time"
                    label="Birth Date"
                    v-model="casesInformation.birthdate"
                    disabled
                />
            </div>

            <div class="col-span-2">
                <p class="text-xs font-semibold text-neutral-600">Calculated Age</p>

                <div class="flex mt-2 space-x-2">
                    <text-input
                        label=""
                        placeholder="Year"
                        name="year"
                        id="calculated_year"
                        disabled
                        v-model="casesInformation.calculated_year"
                    />
                    <text-input
                        label=""
                        placeholder="Month"
                        name="month"
                        id="calculated_month"
                        disabled
                        v-model="casesInformation.calculated_month"
                    />
                </div>
            </div>
        </template>

        <template v-if="casesInformation.birthday_type === 1">
            <div class="col-span-2">
                <text-input
                    type="number"
                    label="Age"
                    placeholder="Age"
                    name="approximate_age"
                    id="approximate_age"
                    v-model="casesInformation.approximate_age"
                    disabled
                />
            </div>
        </template> -->

        <div class="col-span-12 lg:col-span-4 md:col-span-6">
            <text-input
                label="Case Number"
                placeholder="Case Number"
                name="case_number"
                id="case_number"
                v-model="casesInformation.case_number"
                disabled
            />
        </div>

        <div class="col-span-12 lg:col-span-4 md:col-span-6">
            <text-input
                label="Primary Diagnosis"
                placeholder="Primary Diagnosis"
                name="primary_diagnosis"
                id="primary_diagnosis"
                v-model="casesInformation.primary_diagnosis"
                disabled
            />
        </div>

        <div class="col-span-12 lg:col-span-4 md:col-span-6">
            <text-input
                label="Informant relation to child"
                placeholder="Informant relation to child"
                name="informant_relation_child"
                id="informant_relation_child"
                v-model="casesInformation.informant_relation_child"
                disabled
            />
        </div>

        <div class="col-span-12 lg:col-span-4 md:col-span-6">
            <text-input
                label="Other Relationship"
                placeholder="Other Relationship"
                name="other_relationship"
                id="other_relationship"
                v-model="casesInformation.other_relationship"
                disabled
            />
        </div>

        <div class="col-span-12 lg:col-span-4 md:col-span-6">
            <text-input
                label="Services sought"
                placeholder="Services sought"
                name="services_sought"
                id="services_sought"
                v-model="casesInformation.services_sought"
                disabled
            />
        </div>

        <div class="col-span-12 lg:col-span-4 md:col-span-6">
            <text-input
                label="Social worker"
                placeholder="Social worker"
                name="social_worker"
                id="social_worker"
                v-model="casesInformation.social_worker"
                disabled
            />
        </div>

        <div class="col-span-12 lg:col-span-4 md:col-span-6">
            <text-input
                label="External referral from"
                placeholder="External referral from"
                name="external_referral_from"
                id="external_referral_from"
                v-model="casesInformation.external_referral_from"
                disabled
            />
        </div>

        <div class="col-span-12 lg:col-span-4 md:col-span-6">
            <text-input
                label="Intra-PGH referral from"
                placeholder="Intra-PGH referral from"
                name="intra_referral_from"
                id="intra_referral_from"
                v-model="casesInformation.intra_referral_from"
                disabled
            />
        </div>

        <div class="col-span-12 lg:col-span-4 md:col-span-6">
            <text-input
                label=" If inpatient, which ward referred"
                placeholder="If inpatient, which ward referred"
                name="if_ward_referred"
                id="if_ward_referred"
                labelClass="!text-neutral-400"
                v-model="casesInformation.if_ward_referred"
                disabled
            />
        </div>

        <div class="col-span-12 lg:col-span-4 md:col-span-6">
            <multi-select searchable
                :options="legalStatus"
                label="Legal Status"
                id="legal_status"
                name="legal_status"
                placeholder="Select.."
                v-model="form.legal_status" 
                :error="errors?.legal_status" 
                required
                @update:modelValue="hasChange"
            />
        </div>

        <div class="col-span-12 lg:col-span-4 md:col-span-6">
            <multi-select searchable
                :options="housingClassifications"
                label="Housing Classification"
                id="housing_classification_id"
                name="housing_classification"
                placeholder="Select.."
                v-model="form.housing_classification_id" 
                :error="errors?.housing_classification_id" 
                required
                @update:modelValue="hasChange"
            />
        </div>

        <div class="col-span-12 lg:col-span-4 md:col-span-6">
            <multi-select searchable
                :options="socioEconomics"
                label="Socio Economic Status"
                id="socio_economic_status_id"
                name="socio_economic_status"
                placeholder="Select.."
                v-model="form.socio_economic_status_id" 
                :error="errors?.socio_economic_status_id" 
                required
                @update:modelValue="hasChange"
            />
        </div>

        <div class="col-span-12 lg:col-span-4 md:col-span-6">
            <text-input
                type="number"
                label="Number of Children in the Family"
                placeholder="Number of Children in the Family"
                name="number_children_family"
                id="no_children_in_family"
                v-model="form.no_children_in_family"
                :error="errors?.no_children_in_family"
                required
                @update:modelValue="hasChange"
            />
        </div>

        <div class="col-span-12 lg:col-span-4 md:col-span-6">
            <text-input
                type="number"
                label="Number of Household Members"
                placeholder="Number of Household Members"
                name="number_household_members"
                id="no_children_in_family"
                v-model="form.no_household_members"
                :error="errors?.no_children_in_family"
                required
                @update:modelValue="hasChange"
            />
        </div>

        <div class="col-span-12 lg:col-span-4 md:col-span-6">
            <text-input
                type="number"
                label="Number of Families in Household"
                placeholder="Number of Families in Household"
                name="number_families_household"
                id="no_family_in_household"
                v-model="form.no_family_in_household"
                :error="errors?.no_family_in_household"
                required
                @update:modelValue="hasChange"
            />
        </div>

        <!-- MODAL -->
        <success-modal
            noBtn
            :show="showSuccess"
            action-text="Close"
            title="Record Save"
            content="Intake has been updated" 
            @close="showSuccess = false"
        />
        <page-loader :show="loading"/>
        
    </intake-layout>
</template>
<script setup lang="ts">
import PageLoader from "@/components/loaders/PageLoader.vue"
import IntakeLayout from '../../Component/IntakeLayout.vue';
import ActionButton from "../../Component/ActionButton.vue";
import TextInput from "@/components/inputs/TextInput.vue";
import RadioGroup from "@/components/inputs/RadioGroup.vue";
import DatePicker from "@/components/inputs/DatePicker.vue";
import MultiSelect from "@/components/inputs/MultiSelect.vue";
import SuccessModal from "@/components/modals/SuccessModal.vue";
import ButtonComponent from "@/components/buttons/ButtonComponent.vue";
import { ref, onMounted, computed, watch } from 'vue';
import { useRouter, useRoute } from "vue-router";

import PersonalInformationService from "@/classes/Cases/IntakeForms/PersonalInformationService";
import { OptionInterface } from '@/interfaces/OptionInterface';
import { IntakeCaseInformation } from '@/interfaces/Cases/Intake';

import { errorResponse } from "@/helpers/errorMessage";
import UserBirthdateAge from '@/components/forms/UserBirthdateAge.vue'
import { useNetworkStore } from "@/store/network";
import OfflineSyncPersonnalInformation from "@/classes/Forms/Cases/Intakes/OfflineSyncPersonnalInformation";
import { onIonViewWillEnter } from "@ionic/vue";
import IntakerForm from "@/classes/Forms/Cases/IntakeForm";

const router = useRouter();
const route = useRoute();
const loading = ref(false);
const loaded = ref(false);
const showSuccess = ref(false);
const route_params = route.params;
const errors = ref<any>();
const patient = ref<string>('');
const isDirty = ref<boolean>(false)

const form = ref({
    id: null,
    legal_status: null,
    housing_classification_id: null,
    socio_economic_status_id: null,
    no_children_in_family: null,
    no_household_members: null,
    no_family_in_household: null,
})

const formValidation = new IntakerForm({});

const casesInformation = ref<IntakeCaseInformation>({
    last_name: '',
    first_name: '',
    gender: '',
    sex_at_birth: '',
    birthday_type: 0,
    date_initiated: '',
    birthdate: '',
    calculated_year: '',
    calculated_month: '',
    approximate_age: '',
    case_number: '',
    primary_diagnosis: '',
    social_worker: '',
    services_sought: '',
    informant_relation_child: '',
    other_relationship: '',
    external_referral_from: '',
    intra_referral_from: '',
    if_ward_referred: '',
    patient_id: 0,
    case_id: 0,
})

const housingClassifications = ref<OptionInterface[]>([])
const socioEconomics = ref<OptionInterface[]>([])
const legalStatus = ref<OptionInterface[]>([])
const networkStore = useNetworkStore();
const isNetworkAvailable = computed(() => networkStore.isNetworkAvailable);
const patientId = computed(() => route_params?.patient);
const caseId = computed(() => route_params?.case);
const isIonWillEnter = ref<boolean>(false);

const fetch = async (): Promise<any> => {
    loading.value = true;
    if (isNetworkAvailable.value) {
        await PersonalInformationService
        .show(route_params?.patient, route_params?.case)
        .then(async ({data}) => {
            housingClassifications.value = data.housingClassifications;
            socioEconomics.value = data.socioEconomics;
            legalStatus.value = data.legalStatus;
            patient.value = data.patient;
            
            Object.assign(form.value, data.item)
            Object.assign(casesInformation.value, data.item.cases_information)
            
            await OfflineSyncPersonnalInformation.syncToOffline(form.value, route_params?.case);

            loading.value = false;
            loaded.value = true;
        })
        .catch(errors => {
            loading.value = false;
            console.log(errors)
        }) 
    } else {
        const data:any = await OfflineSyncPersonnalInformation.show(route_params?.patient, route_params?.case);
        housingClassifications.value = data.housingClassifications;
        socioEconomics.value = data.socioEconomics;
        legalStatus.value = data.legalStatus;
        patient.value = data.patient;
        
        if (data.item) {
            Object.assign(form.value, data.item)
            Object.assign(casesInformation.value, data.item.cases_information)
        }

    }

    loading.value = false;
    loaded.value = true;    
}

const save = async () => {
    loading.value = true;

    if (!isNetworkAvailable.value) {
        const validate = formValidation.offlineCreateValidation(form.value);
		errors.value = validate?.errors;
		if (validate.hasOfflineError) {
			showSuccess.value = false;
            loading.value = false;
			return;
		}
    }
    if (isNetworkAvailable.value) {
        await PersonalInformationService
        .update(route_params?.patient, route_params?.case, form.value)
        .then(({data}) => {
            loading.value = false;
            showSuccess.value = true;
            isDirty.value = false

            setTimeout(() => {
                fetch();
                // location.reload()
            }, 1000);
        })
        .catch(error => {
            loading.value = false;
            errors.value = errorResponse(error.response?.data?.errors)
        }) 
    } else {
        await OfflineSyncPersonnalInformation.save(form.value, route_params?.patient, route_params?.case);
        await fetch();
        isDirty.value = false
        loading.value = false;
        showSuccess.value = true;
    }
}

const optionSelected = {
    data: [
        {
            id: 0, value: 0, label: 'Birthday'
        },
        {
            id: 1, value: 1, label: 'Approximate Age'
        },
        {
            id: 2, value: 2, label: 'Unknown'
        },
    ]
}

const hasChange = () => {
    isDirty.value = true
}

// watch(
// 	() => isNetworkAvailable.value,
// 	async (val) => {
// 		console.log("Watcher - Network: " + val);
//         await fetch();
// 	}
// );

onIonViewWillEnter(async () => {
    if (isIonWillEnter.value) {
        await fetch();
    }
});

onMounted(async () => {
    await fetch();
    isIonWillEnter.value = true;
})

</script>

