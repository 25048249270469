import http from '@/axios'

class IncestCaseService {

    public async show(patient:string, cases:string) : Promise<JSON> {
        return await http.get(`patients/${patient}/cases/${cases}/intakeForm/incestCase/show`);
    }

    public async update(patient:string, cases:string, payload: any) : Promise<JSON> {
        return await http.post(`patients/${patient}/cases/${cases}/intakeForm/incestCase/update`, payload);
    }

}
export default new IncestCaseService();
