import http from "@/axios";
import { TeleCpuOffice } from "@/interfaces/Settings/TeleCpuOffice";

class TeleCpuOfficeService implements TeleCpuOffice {
	async delete(scopeBinding: any): Promise<any> {
		return await http.delete(
			`settings/tele-cpu-locations/${scopeBinding.location}/tele-cpu-offices/${scopeBinding.telcpu}/delete`
		);
	}

	async restore(scopeBinding: any): Promise<any> {
		return await http.patch(
			`settings/tele-cpu-locations/${scopeBinding.location}/tele-cpu-offices/${scopeBinding.telcpu}/restore`
		);
	}

	async edit(scopeBinding: any, params: any = {}): Promise<any> {
		return await http.get(
			`settings/tele-cpu-locations/${scopeBinding.location}/tele-cpu-offices/${scopeBinding.telcpu}/edit`,
			{ params: params }
		);
	}

	async update(scopeBinding: any, params: any = {}): Promise<any> {
		return await http.put(
			`settings/tele-cpu-locations/${scopeBinding.location}/tele-cpu-offices/${scopeBinding.telcpu}/update`,
			params
		);
	}

	async store(scopeBinding: any, params: any = {}): Promise<any> {
		return await http.post(`settings/tele-cpu-locations/${scopeBinding.location}/tele-cpu-offices`, params);
	}
}

export default new TeleCpuOfficeService();
