<template>
	<div class="w-full">
		<section-title fontSmall title="Data Dictionary" description="Relevant data and other details" />
		<form class="grid grid-cols-2 gap-6 mt-6">
			<div class="col-span-2 border-t border-gray-200"></div>

			<div class="col-span-2">
				<text-input
					type="text"
					label="Name"
					placeholder="Name"
					name="name"
					id="name"
					:readonly="isReadOnly"
					v-model="form.name"/>
			</div>
			<div class="col-span-2">
				<text-input
					textarea
					label="Content"
					placeholder="Content"
					name="content"
					id="content"
					:textAreaRows="5"
					:readonly="isReadOnly"
					v-model="form.content"/>
			</div>
			<slot></slot>
		</form>
	</div>
</template>
<script setup lang="ts">
import { ref } from "vue";
import SectionTitle from "@/components/partials/SectionTitle.vue";
import TextInput from "@/components/inputs/TextInput.vue";

const props = defineProps({
	form: {
		type: Object,
		required: true,
	},
	isReadOnly: {
		type: Boolean,
		default: false,
	},
});

const form = ref<object>(props.form);
</script>
