<template>
    <div>
        <label class="block text-xs font-semibold text-neutral-600 mb-3">{{ label }}</label>
        <div class="flex flex-col space-y-2">
            <radio-group
                :options="checkbox_options"
                name="checkbox"
                v-model="target"
            />
        </div>

        
        <!-- Manual -->
        <div v-if="target == 'multiple'" class="mt-6">
            <slot></slot>
        </div>
    </div>
</template>
<script setup lang="ts">
import { ref } from "vue";
import RadioGroup from "@/components/inputs/RadioGroup.vue";

const props = defineProps({
	form: {
		type: Object,
	},
    value: {
        default: null,
        required: true,
    },
    label: {
        type: String,
        default: 'What type of checkbox field?',
    },
    error: {
        type: String,
        default: null,
    },
    disabled: {
        type: Boolean,
        default: false,
    },
});
const form = ref<object>(props.form);
const target = ref<string>('');

const checkbox_options = [
    { id: '1', label: 'As a Simple Binary Response (Yes/No)', value: 'binary', radioSelected: null},
    { id: '2', label: 'As Multiple Selection Options', value: 'multiple', radioSelected: null},
]


</script>
