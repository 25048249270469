<template>
	<div class="w-full">
		<section-title fontSmall title="Primary Diagnosis Information" description="Relevant data and other details" />
		<form class="grid grid-cols-2 gap-6 mt-6">
			<div class="col-span-2 border-t border-gray-200"></div>

			<div class="col-span-2">
				<text-input
					type="text"
					label="Primary Diagnosis"
					placeholder="Primary Diagnosis"
					name="primary_diagnosis"
					id="primary_diagnosis"
					v-model="form.name"
					:error="errors?.name" />
			</div>
			<slot></slot>
		</form>
	</div>
</template>
<script setup lang="ts">
import SectionTitle from "@/components/partials/SectionTitle.vue";
import TextInput from "@/components/inputs/TextInput.vue";
import { ref } from "vue";

const props = defineProps({
	form: {
		type: Object,
		required: true,
	},
	errors: {
		type: Object,
		required: true,
	},
});

const form = ref<object>(props.form);
</script>
