import http from "@/axios";

import DB from "@/classes/Databases/Crud";
import Pagination from "@/classes/Databases/Pagination";
import PaginationPatient from "@/classes/Databases/PaginationPatient";

import FormOptions from "@/classes/Forms/FormOptions";

import DateFormatter from "@/helpers/DateFormatter";
import Storage from "@/helpers/storage";


const db = new DB('intervention_data', new Pagination('intervention_data'));
const dbPatient = new DB("patients", new PaginationPatient("patients"));

const dbFormStructure = new DB('intervention_form_structure', new Pagination('intervention_form_structure'));
const options  = new FormOptions();
const storage = new Storage();

const currentUser = JSON.parse(storage.get("user"));

class OfflineService {

	async getFormStructure(): Promise<any> {
		const forms = await dbFormStructure.select(`SELECT * FROM intervention_form_structure`)
		
		if(forms.length > 0){
			return forms;
		} else {
			return await http
				.get(`/get-form-structure`)
				.then(({data}) => {
					data = data.map((item:any) => {
						item.form = JSON.stringify(item.form);
						return item;
					});
					dbFormStructure.set(data)
				});
		}
	}

	async patientSearch(payload: object): Promise<any> {
		const patients =  await dbPatient.paginateIndex(payload, `SELECT * FROM patients`);
	
		patients.data.items.data = patients.data.items.data.map((item:any) => {
			item.registered_at = JSON.parse(item.content).created_at;
			item.patient_id = item.id;

			return item;
		});

		return patients;
	}

	async storeOffline(items: object): Promise<void> {
		items = Object.values(items).map((item:any) => {
			item.data = JSON.stringify(item.data);
			return item;
		});

		await db.set(items, true, 'intervention_data');
	}

	async index(patient: number, case_id: number|null = null, payload: object|null = null): Promise<any> {
		const interventionTypes = await options.interventionTypes();
		const patientData = await db.select(`SELECT * FROM patients WHERE id = ${patient}`, 'patients');

		let condition = ` WHERE patient_id = ${patient}`;

        if( case_id != null){
            condition += ` AND cases_id = ${case_id}`;
        }
		
		const data = await db.index(payload, condition);

		return {
			data: {
				...data.data,
				interventionType: interventionTypes,
				patient: `${patientData[0].last_name}, ${patientData[0].first_name}` ,
			}
		}
	}

	async create(patient: number, form: number, case_id: number|null = null,  payload: object|null = null): Promise<string> {
		return this.createFormatter(patient, form, case_id)
	}

	async store(patient: number, form: number, payload: any,): Promise<void> {
		
		const data = {
			patient_id: patient,
			cases_id: payload.cases_id,
			intervention_type: payload.interventionType,
			data: JSON.stringify(payload.params),
			case_number: await this.getPatientCaseNumber(payload.cases_id),
			intervention_type_name: await this.getInterventionTypeName(payload.interventionType),
			is_created_offline: true, 
			created_at:  DateFormatter.customDateFormat(new Date().toDateString(), 'yyyy-MM-dd hh:mm:ss'),
			updated_at:  DateFormatter.customDateFormat(new Date().toDateString(), 'yyyy-MM-dd hh:mm:ss'),
			...this.setFormParamsData(payload?.params),
		}
		
		data.status = this.getStatus(data);

		db.insert(data, null, false, 'intervention_data');
	}

	async edit(interventionType:number, intervention:number, patient: number, payload: object|null = null): Promise<any> {
		const result = await db.edit(intervention, false);

		return this.editFormatter(interventionType, result)
	}
	
	async update(intervention:number, patient: number, payload: any): Promise<any> {

		const interventionDetails = await dbFormStructure.select(`SELECT * FROM intervention_data where id = ${intervention}`, 'intervention_data');

		const data = Object.assign(
				interventionDetails[0],
				{
					patient_id: patient,
					cases_id: payload.cases_id,
					intervention_type: parseInt(payload.interventionType),
					data: JSON.stringify(payload.params),
					is_updated_offline: payload.is_created_offline ? false : true, 
					updated_at:  DateFormatter.customDateFormat(new Date().toDateString(), 'yyyy-MM-dd hh:mm:ss'),
					...this.setFormParamsData(payload?.params)
				}
			);

		return db.insert(data, intervention, false, 'intervention_data');
	}

	private async createFormatter(patient:number, interventionType:number, case_id:number|null = null): Promise<any> 
	{
		return {
			data : {
				patient: await this.getPatientDetails(patient, case_id),
				form: await this.storedFormStructure(interventionType),
				cases: case_id ? [] : await this.getPatientCases(patient)
			}
		}
	}

	private async editFormatter(interventionType:number, result:any): Promise<any> 
	{
		const item = result.data.item;

		item.data = Object.values(JSON.parse(item.data));
		item.status = this.getStatus(item);

		return {
			data : {
				item: item,
				patient: await this.getPatientDetails(item.patient_id, item.cases_id),
				form: await this.storedFormStructure(interventionType),
				isCourtHearing: false,
			}
		}
	}

	private async getPatientDetails(patient:number, case_id:number|null = null): Promise<any>{

		let patient_age = '';
		let patient_birthday = '';

		let detials = await dbFormStructure.select(`SELECT * FROM patients where id = ${patient}`, 'patients');
		detials = detials[0];

		const content = JSON.parse(detials.content);

		if(case_id){
			const cases = await dbFormStructure.select(`SELECT * FROM cases where id = ${case_id}`, 'cases')
			const casesContent = JSON.parse(cases[0].content);
		
			patient_age = casesContent.computed_age 
			patient_birthday = casesContent.birthday
		}
		
		return {
			name: `${detials.first_name} ${detials.last_name}`,
			patient_name: `${detials.first_name} ${detials.last_name}`,
			patient_age: patient_age,
			patient_birthday: patient_birthday,
			patient_education: content.educational_attainment_id,
			patient_guardian: "",
			office: currentUser.office_id
		};
	}

	private async storedFormStructure(interventionType:number): Promise<JSON>{
		const form = await dbFormStructure.select(`SELECT * FROM intervention_form_structure where intervention_type = ${interventionType}`)

		return JSON.parse(form[0].form);
	}

	private async getPatientCases(patient:number): Promise<any>{
		const cases = await dbFormStructure.select(`SELECT * FROM cases where patient_id = ${patient}`)

		return cases.map((item:any) => { 
			const content = JSON.parse(cases[0].content);

			return {
				id:item.id, 
				value: item.id, 
				label: item.case_number, 
				computed_age: content.computed_age,
				birthday: content.birthday, 
			}
		});
	}

	private async getPatientCaseNumber(cases_id:number): Promise<string>{
		const cases = await dbFormStructure.select(`SELECT * FROM cases where id = ${cases_id}`)

		return cases[0].case_number;
	}
	private async getInterventionTypeName(intervention_type:string): Promise<string>{
		const interventionTypes = await options.interventionTypes();

		const index = interventionTypes.map((item:any) => item.id).indexOf(parseInt(intervention_type));

		return interventionTypes[index].label;
	}

	private setFormParamsData(data: any[]): any
	{
		const params:any = [];

		Object.values(data).forEach((value:any) => {
			switch (value.form_tag) {
				case 'type':
					params['type_type'] = value.source_type;
					params['type_id'] = value.value;
					params['type'] = value.value_label;
					break;
				case 'sub_type':
					params['subtype_type'] = value.source_type;
					params['subtype_id'] = value.value;
					params['subtype'] = value.value_label;
					break;
				case 'schedule_date_time':
					params['schedule_date'] = DateFormatter.customDateFormat(value.value, 'yyyy-MM-dd hh:mm:ss');
					break;
				case 'done_date_time':
					if(value.value){
						params['done_on'] = DateFormatter.customDateFormat(value.value, 'yyyy-MM-dd hh:mm:ss');
					} else {
						params['done_on'] = null;
					}
					break;
				case 'assigned_group':
					if(value.value != null){
						params['position_id'] = value.value;
					} else {
						params['position_id'] = currentUser.position_id;
					}
					break;
				case 'done_by':
					if(value.value != null){
						params['done_by_id'] = value.value;
						
					} else {
						params['done_by_id'] = currentUser.id;
						params['done_by'] = currentUser.name;

					}
					break;
			}

			if(value.form_tag == 'assigned_group' && value.value != null){
                params['position_id'] = value.value;
            } else {
                params['position_id'] = currentUser.position_id;
            }
			
			if(value.form_tag == 'done_by' && value.value != null){
                params['done_by_id'] = value.value;
            } else {
                params['done_by_id'] = currentUser.id;
                params['done_by'] = currentUser.name;
            }
		})

		return params;
	}

	public getStatus(intervention:any): string{
		let status = "Past Due";

		if(intervention?.done_on){
			status = "Done";
		} else if((intervention?.done_on == undefined || intervention?.done_on == null) && this.isDateGreaterOrEqual(intervention.schedule_date) ){ 
			status = 'Scheduled';
		} 

		return status;
	}

	public isDateGreaterOrEqual(dateString: string): boolean {
		const date = new Date(dateString);
		const now = new Date();
		return date >= now;
	}

}
export default new OfflineService();
