import { CreatePatient } from "@/interfaces/PatientInterface";
import numberChecker from '@/helpers/numberChecker';
export default class CreatePatientForm implements CreatePatient {
	case_number: string | null;
	registered_at: string | null;
	patient_id: number | null;
	first_name: string | null;
	last_name: string | null;
	middle_name: string | null;
	birthday_classification: number | null;
	birthdate: string | null;
	approximate_age_year: number | null;
	approximate_age_month: number | null;
	gender: number | null;
	approximate_age: number | null;
	civil_status_id: number | null;
	nationality_id: number | null;
	religion_id: number| string | null;
	other_religion: string | null;
    other_nationality: string | null;
	nickname: string | null;
	sex_at_birth: number | null;
	initiated_at: string | null;
	is_followup: boolean | null;
	is_reabuse: boolean | null;
	incident_time: number | null;
	hospital_number: string | null;
	primary_diagnoses_id: number | null;
	other_diagnoses: string | null;
	high_suicidal_risk: number | null;
	is_hama: boolean | null;
	mental_health: [];
	comorbidities: [];
	referral_source_id: number | null;
	referral_specify: string | null;
	second_referral_source_id: number | null;
	sought_service_id: number | null;
	intake_social_worker_id: number | null;
	social_worker_id: number | null;
	assigned_mss: string | null;
	physician_id: number | null;
	mental_health_personnel_id: number | null;
	mms_id: number | null;
	trainee_id: number | null;
	child_type: number | null;
	is_internally_displaced: boolean | null;
	pwd: number | null;
	pwd_specify: number | null;
	disability_id: number | null;
	educational_attainment_id: number | null;
	employment_status_id: number | null;
	company_name: string | null;
	company_contact_number: string | null;
	company_address: string | null;
	is_inpatient: boolean | null;
	hospital_area_id: number | null;
	remarks: [];
	indigeneous_people_id: number | null;
	endorsed_to_id: number | null;
    other_indigeneous_people: string | null;
	constructor(item: CreatePatient) {
		this.case_number = item?.case_number || null;
		this.registered_at = item?.registered_at || null;
		this.patient_id = item?.patient_id || null;
		this.first_name = item?.first_name || null;
		this.last_name = item?.last_name || null;
		this.middle_name = item?.middle_name || null;
		this.birthday_classification = item?.birthday_classification;
		this.birthdate = item?.birthdate;
		this.approximate_age_year = item?.approximate_age_year || null;
		this.approximate_age_month = item?.approximate_age_month || null;
		this.gender = item?.gender;
		this.approximate_age = item?.approximate_age || null;
		this.civil_status_id = item?.civil_status_id || null;
		this.nationality_id = item?.nationality_id || null;
		this.religion_id = item?.religion_id || null;
		this.other_religion = item?.other_religion || null;
        this.other_nationality = item?.other_nationality || null;
		this.nickname = item?.nickname || null;
		this.sex_at_birth = item?.sex_at_birth;
		this.initiated_at = item?.initiated_at || null;
		this.is_followup = item?.is_followup || false;
		this.is_reabuse = item?.is_reabuse || false;
		this.incident_time = item?.incident_time;
		this.hospital_number = item?.hospital_number || null;
		this.primary_diagnoses_id = item?.primary_diagnoses_id || null;
		this.other_diagnoses = item?.other_diagnoses || null;
		this.high_suicidal_risk = item?.high_suicidal_risk || null;
		this.is_hama = item?.is_hama || false;
		this.mental_health = [];
		this.comorbidities = [];
		this.referral_source_id = item?.referral_source_id || null;
		this.referral_specify = item?.referral_specify || null; 
		this.second_referral_source_id = item?.second_referral_source_id || null;
		this.sought_service_id = item?.sought_service_id || null;
		this.intake_social_worker_id = item?.intake_social_worker_id || null;
		this.social_worker_id = item?.social_worker_id || null;
		this.assigned_mss = item?.assigned_mss || null;
		this.physician_id = item?.physician_id || null;
		this.mental_health_personnel_id = item?.mental_health_personnel_id || null;
		this.mms_id = item?.mms_id || null;
		this.trainee_id = item?.trainee_id || null;
		this.child_type = item?.child_type;
		this.is_internally_displaced = item?.is_internally_displaced || false;
		this.pwd = numberChecker(item?.pwd) ? item?.pwd : null;
		this.pwd_specify = item?.pwd_specify || null;
		this.disability_id = item?.disability_id || null;
		this.educational_attainment_id = item?.educational_attainment_id || null;
		this.employment_status_id = item?.employment_status_id || null;
		this.company_name = item?.company_name || null;
		this.company_contact_number = item?.company_contact_number || null;
		this.company_address = item?.company_address || null;
		this.is_inpatient = item?.is_inpatient || false;
		this.hospital_area_id = item?.hospital_area_id || null;
		this.remarks = [];
		this.indigeneous_people_id = item?.indigeneous_people_id || null;
		this.endorsed_to_id = item?.endorsed_to_id || null;
        this.other_indigeneous_people = item?.other_indigeneous_people;
	}

	createCaseService(item: CreatePatient) {
		this.registered_at = item?.registered_at;
		this.first_name = item?.first_name || null;
		this.last_name = item?.last_name || null;
		this.middle_name = item?.middle_name || null;
		this.birthday_classification = item?.birthday_classification;
		this.birthdate = item?.birthdate;
		this.approximate_age_year = item?.approximate_age_year || null;
		this.approximate_age_month = item?.approximate_age_month || null;
		this.gender = item?.gender;
		this.sex_at_birth = item?.sex_at_birth;
		this.civil_status_id = item?.civil_status_id;
		this.nationality_id = item?.nationality_id;
		this.religion_id = item?.religion_id;
		this.other_religion = item?.other_religion;
        this.other_nationality = item?.other_nationality;
		this.indigeneous_people_id = item?.indigeneous_people_id || null;
        this.pwd = item?.pwd;
        this.pwd_specify = item?.pwd_specify;
        this.other_indigeneous_people = item?.other_indigeneous_people;
	}

	updateCase(item: any) {
		this.registered_at = item.patient?.registered_at || "";
		this.first_name = item.patient?.first_name || "";
		this.last_name = item.patient?.last_name || "";
		this.middle_name = item.patient?.middle_name || "";
		this.birthday_classification = item?.birthday_classification || "";
		this.birthdate = item?.birthdate || "";
		this.approximate_age_year = item?.approximate_age_year || null;
		this.approximate_age_month = item?.approximate_age_month || null;
		this.civil_status_id = item?.civil_status_id || "";
		this.nationality_id = item?.nationality_id || "";
		this.religion_id = item?.religion_id || "";
		this.other_religion = item?.other_religion || "";
        this.other_nationality = item?.other_nationality || "";
		this.case_number = item?.case_number || "";
		this.assigned_mss = item?.assigned_mss || "";
		this.initiated_at = item?.initiated_at || "";
		this.is_followup = item?.is_followup || false;
        this.incident_time = item?.incident_time;
		this.is_reabuse = item?.is_reabuse || false;
		this.primary_diagnoses_id = item?.diagnoses?.primary_diagnoses_id;
		this.other_diagnoses = item?.diagnoses?.other_diagnoses;
		this.mental_health = item?.mental_health_diagnoses?.map((mental_health: any) => {
			return {
				mental_health_diagnoses_id: mental_health.mental_health_diagnoses_id,
				type: mental_health.type,
				specify: mental_health.specify,
				behavior_type_id: mental_health.behavior_type_id,
				checked: true,
			};
		});
		this.comorbidities = item?.comorbidities?.map((comorbidity: any) => {
			return {
				comorbidity_id: comorbidity.id,
				checked: true,
			};
		});
		this.high_suicidal_risk = item?.diagnoses?.high_suicidal_risk;
		this.is_hama = item?.diagnoses?.is_hama || false;
		this.referral_source_id = item?.referral_service?.referral_source_id;
		this.referral_specify = item?.referral_service?.referral_specify || null; 
		this.second_referral_source_id = item?.referral_service?.second_referral_source_id;
		this.sought_service_id = item?.referral_service?.sought_service_id;
		this.intake_social_worker_id = item?.referral_service?.intake_social_worker_id;
		this.social_worker_id = item?.referral_service?.social_worker_id;
		this.physician_id = item?.referral_service?.physician_id;
		this.mental_health_personnel_id = item?.referral_service?.mental_health_personnel_id;
		this.mms_id = item?.referral_service?.mms_id;
		this.trainee_id = item?.referral_service?.trainee_id;
		this.child_type = item?.child_type;
		this.is_internally_displaced = item?.is_internally_displaced;
		this.pwd = item?.pwd || null;
		this.pwd_specify = item?.patient?.pwd_specify || null;
		this.disability_id = item?.disability_id;
		this.educational_attainment_id = item?.educational_attainment_id;
		this.employment_status_id = item?.employment_status_id;
		this.company_name = item?.company_name;
		this.company_contact_number = item?.company_contact_number;
		this.company_address = item?.company_address;
		this.is_inpatient = item?.is_inpatient || false;
		this.hospital_area_id = item?.hospital_area_id;
		this.remarks = item?.remarks?.map((remark: any) => {
			return { remarks: remark.remarks };
		});
		this.hospital_number = item?.hospital_number;
		this.indigeneous_people_id = item?.indigeneous_people_id || 'none'
		this.endorsed_to_id = item?.endorsed_to_id;
        this.sex_at_birth = item?.patient?.sex_at_birth;
        this.gender = item?.patient?.gender;
        this.pwd_specify = item?.patient?.pwd_specify;
        this.other_indigeneous_people = item?.patient?.other_indigeneous_people;
	}

	defaultPatientValue() {
		this.is_followup = false;
		this.is_hama = false;
		this.is_reabuse = false;
	}

	offlineCreateValidation(form: any, patientId = null) {
		const errors = <any>{};
		let hasOfflineError = false;

		if (!patientId) {
			if (!form.registered_at) {
				hasOfflineError = true;
				Object.assign(errors, { registered_at: "The Patient Registered field is required." });
			}

			if (!form.first_name) {
				hasOfflineError = true;
				Object.assign(errors, { first_name: "The last name field is required." });
			}

			if (!form.last_name) {
				hasOfflineError = true;
				Object.assign(errors, { last_name: "The first name field is required." });
			}

			if (form.sex_at_birth == undefined) {
				hasOfflineError = true;
				Object.assign(errors, { sex_at_birth: "The sex at birth field is required." });
			}

			// if (!form.civil_status_id) {
			// 	hasOfflineError = true;
			// 	Object.assign(errors, { civil_status_id: "The civil status field is required." });
			// }

			// if (!form.indigeneous_people_id) {
			// 	hasOfflineError = true;
			// 	Object.assign(errors, { indigeneous_people_id: "The Ethnicity field is required." });
			// }

			if (!form.birthdate && !form.approximate_age_month) {
				hasOfflineError = true;
				Object.assign(errors, { approximate_age_month: "The approximate age month field is required when birthdate is not present." });
			}
	
			if (!form.birthdate && !form.approximate_age_year) {
				hasOfflineError = true;
				Object.assign(errors, { approximate_age_year: "The approximate age year field is required when birthdate is not present." });
			}
	
			if (!form.approximate_age_month && !form.birthdate) {
				hasOfflineError = true;
				Object.assign(errors, { birthdate: "The birthdate field is required when approximate age month is not present." });
			}
	
			if (!form.approximate_age_year && !form.birthdate) {
				hasOfflineError = true;
				Object.assign(errors, { birthdate: "The birthdate field is required when approximate age year is not present." });
			}
		}

		// if (form.incident_time == undefined) {
		// 	hasOfflineError = true;
		// 	Object.assign(errors, { incident_time: "The Time of Incident field is required." });
		// }

		if (!form.referral_source_id) {
			hasOfflineError = true;
			Object.assign(errors, { referral_source_id: "The referral source field is required." });
		}

        if (form.pwd != null && form.pwd !== 7 && !form.pwd_specify) { // if pwd is not None
            hasOfflineError = true;
            Object.assign(errors, { pwd_specify: "The pwd specify field is required." });
        }

		if (!form.intake_social_worker_id && !form.social_worker_id && !form.physician_id && !form.mental_health_personnel_id) {
			hasOfflineError = true;
			Object.assign(errors, { intake_social_worker_id: "The Intake social worker field is required when none of Social worker / Physician / Mental health personnel are present." });
		}

		if (!form.social_worker_id && !form.intake_social_worker_id && !form.physician_id && !form.mental_health_personnel_id) {
			hasOfflineError = true;
			Object.assign(errors, { social_worker_id: "The Social worker field is required when none of Intake social worker / Physician / Mental health personnel are present." });
		}

		if (!form.physician_id && !form.intake_social_worker_id && !form.social_worker_id && !form.mental_health_personnel_id) {
			hasOfflineError = true;
			Object.assign(errors, { physician_id: "The Physician field is required when none of Social worker / Intake social worker / Mental health personnel are present." });
		}

		if (!form.mental_health_personnel_id && !form.intake_social_worker_id && !form.social_worker_id && !form.physician_id) {
			hasOfflineError = true;
			Object.assign(errors, {
				mental_health_personnel_id: "The Mental health personnel field is required when none of Social worker / Intake social worker / Physician are present.",
			});
		}

		if (!form.sought_service_id) {
			hasOfflineError = true;
			Object.assign(errors, {
				sought_service_id: "The sought service field is required.",
			});
		}

        if (form.is_inpatient == true && form.hospital_area_id == null) {
            hasOfflineError = true;
			Object.assign(errors, { hospital_area_id: "The hospital area field is required." });
        }

        if (form.is_inpatient == true && form.hospital_area_id != null && !form.hospital_number) {
            hasOfflineError = true;
			Object.assign(errors, { hospital_number: "The hospital number field is required." });
        }

        if (form.nationality_id == 'others' && !form.other_nationality) {
            hasOfflineError = true;
			Object.assign(errors, { other_nationality: "The other nationality field is required." });
        }

        if (form.religion_id == 'others' && !form.other_religion) {
            hasOfflineError = true;
			Object.assign(errors, { other_religion: "The other religion field is required." });
        }

        if (form.indigeneous_people_id == 'others' && !form.other_indigeneous_people) {
            hasOfflineError = true;
			Object.assign(errors, { other_indigeneous_people: "The specify field is required." });
        }

		// if (!form.mms_id) {
		// 	hasOfflineError = true;
		// 	Object.assign(errors, { mms_id: "The MMS field is required." });
		// }

		return {
			hasOfflineError: hasOfflineError,
			errors: errors,
		};
	}

	offlineUpdateValidation(form: any) {
		const errors = <any>{};
		let hasOfflineError = false;

        if (!form.registered_at) {
            hasOfflineError = true;
            Object.assign(errors, { registered_at: "The Patient Registered field is required." });
        }

        if (!form.first_name) {
            hasOfflineError = true;
            Object.assign(errors, { first_name: "The last name field is required." });
        }

        if (!form.last_name) {
            hasOfflineError = true;
            Object.assign(errors, { last_name: "The first name field is required." });
        }

        if (form.sex_at_birth == undefined) {
            hasOfflineError = true;
            Object.assign(errors, { sex_at_birth: "The sex at birth field is required." });
        }

		if (!form.birthdate && !form.approximate_age_month) {
			hasOfflineError = true;
			Object.assign(errors, { approximate_age_month: "The approximate age month field is required when birthdate is not present." });
		}

		if (!form.birthdate && !form.approximate_age_year) {
			hasOfflineError = true;
			Object.assign(errors, { approximate_age_year: "The approximate age year field is required when birthdate is not present." });
		}

		if (!form.approximate_age_month && !form.birthdate) {
			hasOfflineError = true;
			Object.assign(errors, { birthdate: "The birthdate field is required when approximate age month is not present." });
		}

		if (!form.approximate_age_year && !form.birthdate) {
			hasOfflineError = true;
			Object.assign(errors, { birthdate: "The birthdate field is required when approximate age year is not present." });
		}

        if (form.pwd != null && form.pwd !== 7 && !form.pwd_specify) { // if pwd is not None
            hasOfflineError = true;
            Object.assign(errors, { pwd_specify: "The pwd specify field is required." });
        }

        if (!form.intake_social_worker_id && !form.social_worker_id && !form.physician_id && !form.mental_health_personnel_id) {
			hasOfflineError = true;
			Object.assign(errors, { intake_social_worker_id: "The Intake social worker field is required when none of Social worker / Physician / Mental health personnel are present." });
		}

		if (!form.social_worker_id && !form.intake_social_worker_id && !form.physician_id && !form.mental_health_personnel_id) {
			hasOfflineError = true;
			Object.assign(errors, { social_worker_id: "The Social worker field is required when none of Intake social worker / Physician / Mental health personnel are present." });
		}

		if (!form.physician_id && !form.intake_social_worker_id && !form.social_worker_id && !form.mental_health_personnel_id) {
			hasOfflineError = true;
			Object.assign(errors, { physician_id: "The Physician field is required when none of Social worker / Intake social worker / Mental health personnel are present." });
		}

		if (!form.mental_health_personnel_id && !form.intake_social_worker_id && !form.social_worker_id && !form.physician_id) {
			hasOfflineError = true;
			Object.assign(errors, {
				mental_health_personnel_id: "The Mental health personnel field is required when none of Social worker / Intake social worker / Physician are present.",
			});
		}

        // if (!form.indigeneous_people_id) {
        //     hasOfflineError = true;
        //     Object.assign(errors, { indigeneous_people_id: "The Ethnicity field is required." });
        // }

		// if (form.incident_time == undefined) {
		// 	hasOfflineError = true;
		// 	Object.assign(errors, { incident_time: "The Time of Incident field is required." });
		// }

		if (!form.referral_source_id) {
			hasOfflineError = true;
			Object.assign(errors, { referral_source_id: "The referral source field is required." });
		}

		if (!form.sought_service_id) {
			hasOfflineError = true;
			Object.assign(errors, {
				sought_service_id: "The sought service field is required.",
			});
		}
        
        if (form.is_inpatient == true && form.hospital_area_id == null) {
            hasOfflineError = true;
			Object.assign(errors, { hospital_area_id: "The hospital area field is required." });
        }

        if (form.is_inpatient == true && form.hospital_area_id != null && !form.hospital_number) {
            hasOfflineError = true;
			Object.assign(errors, { hospital_number: "The hospital number field is required." });
        }

        if (form.nationality_id == 'others' && !form.other_nationality) {
            hasOfflineError = true;
			Object.assign(errors, { other_nationality: "The other nationality field is required." });
        }

        if (form.religion_id == 'others' && !form.other_religion) {
            hasOfflineError = true;
			Object.assign(errors, { other_religion: "The other religion field is required." });
        }

        if (form.indigeneous_people_id == 'others' && !form.other_indigeneous_people) {
            hasOfflineError = true;
			Object.assign(errors, { other_indigeneous_people: "The specify field is required." });
        }

		// if (!form.mms_id) {
		// 	hasOfflineError = true;
		// 	Object.assign(errors, { mms_id: "The MMS field is required." });
		// }

		return {
			hasOfflineError: hasOfflineError,
			errors: errors,
		};
	}
}
