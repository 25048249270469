import http from "@/axios";
import DB from "@/classes/Databases/Crud";

const dbPatient = new DB("patients");
const dbPerpetrators = new DB("perpetrators");
const dbCasePerpetrators = new DB("case_perpetrators");
export default class SyncPerpetrators {
    
    resetItemData(item: any) {
        item.synching_remarks = null;
        item.is_sync_failed = false;
        item.is_sync = true;
        item.is_created_offline = false;
        item.is_updated_offline = false;
        item.is_deleted_offline = false;

        return item;
    }

    async rollback(item: any, response: any) {
        const error = response?.response?.data;
        item.synching_remarks = error.message || 'System Error';
        item.is_sync_failed = true;
        await dbPatient.insert(item, item.id, true, 'perpetrators');

        return true;
    }

    async processForm(item: any, form: any) {
        let hasError = false;
        if (item.is_updated_offline) {
            const response = await http.post('/attemp-synching-perpetrator-update', form).catch(error => error);
            if (response?.status === 200) {
                item = this.resetItemData(item);
                await dbPerpetrators.insert(item, item.id, true, 'perpetrators');
                hasError = false;
            } else {
                hasError = await this.rollback(item, response);
            }
        }

        if (item.is_created_offline && !item.is_updated_offline) {
            const response = await http.post('/attemp-synching-perpetrator-create', form).catch(error => error);
            if (response?.status === 200) {
                const oldID = item.id;
                item = this.resetItemData(item);
                item.id = response.data.id;                
                await dbPerpetrators.delete({id: response.data.id}, 'perpetrators');
                await dbPerpetrators.insert(item, oldID, true, 'perpetrators');
                const casePerpetrators = await dbCasePerpetrators.select(`SELECT * FROM case_perpetrators WHERE perpetrator_id=${oldID}`);
                for (const casePerpetratorIndex in casePerpetrators) {
                    const casePerpetratorItem = casePerpetrators[casePerpetratorIndex];
                    casePerpetratorItem.perpetrator_id = response.data.id;
                    await dbCasePerpetrators.delete({id: casePerpetratorItem.id}, 'case_perpetrators');
                    await dbCasePerpetrators.insert(casePerpetratorItem, null, true, 'case_perpetrators')
                }
                hasError = false;
            } else {
                hasError = await this.rollback(item, response);
            }
        }

        if (item.is_deleted_offline) {
            const response = await http.post('/attemp-synching-perpetrator-delete', form).catch(error => error);
            if (response?.status === 200) {
                await dbPerpetrators.delete({id: item.id}, 'perpetrators');
                hasError = false;
            } else {
                hasError = await this.rollback(item, response);
            }
        }

        return hasError;
    }
}