<template>
	<user-layout
		hasBack
		:backLink="`/patient/${patientId}/cases/${caseId}/edit`"
		hasBackClass="flex md:justify-between md:items-center md:flex-row flex-col">
		<template #lowerRight>
			<div class="flex md:flex-row flex-col items-center md:space-x-3 md:space-y-0 space-y-3">
				<a
					@click="
						router.push(
							`/patient/${patientId}/cases/${caseId}/edit`
						)
					"
					class="md:w-auto w-full md:mt-0 mt-6">
					<button-component btnWidth="quinary" customClass="quinary md:w-auto w-full">
						Cancel
					</button-component>
				</a>
				<button-component
					customClass="primary md:w-auto w-full md:mt-0 mt-6"
					form="form" 
					@click="save()"
				>
					<span>Save Changes</span>
				</button-component>
			</div>
		</template>
		<p class="mt-6 md:text-2xl text-lg font-medium text-neutral-600">Patient's Name:
			<span class="md:inline-block block font-semibold text-neutral-900">{{ patient }}</span>
		</p>
		<div class="w-full lg:p-6 p-4 mx-auto my-6 border border-gray-200 rounded">
			<div class="flex items-center justify-between">
				<section-title title="Informant Details" />
			</div>
			<form-section
				v-if="loaded"
				:form="form"
				:errors="errors"
				:genders="genders"
				:companionRelationships="companionRelationships" />
		</div>
		<success-modal
			:show="showSuccess"
			@confirm="redirectSuccess()"
			action-text="Close"
			title="Record Save!"
			:content="successMessage" />
	</user-layout>
</template>
<script setup lang="ts">
import { onMounted, ref, computed, watch } from "vue";
import UserLayout from "@/layouts/UserLayout.vue";
import SectionTitle from "@/components/partials/SectionTitle.vue";
import ButtonComponent from "@/components/buttons/ButtonComponent.vue";
import SuccessModal from "@/components/modals/SuccessModal.vue";
import FormSection from "./FormSection.vue";
import { useRouter, useRoute } from "vue-router";
import { renderSelect, renderSelectV2 } from "@/interfaces/DropdownInterface";
import CompanionService from "@/classes/Cases/CompanionService";
import { CompanionCreate } from "@/interfaces/Cases/Companion";
import CompanionForm from "@/classes/Forms/Cases/CompanionForm";
import { errorResponse } from "@/helpers/errorMessage";
import { useNetworkStore } from "@/store/network";
import DB from "@/classes/Databases/Crud";
import pickBy from "lodash/pickBy";
import FormOptions from "@/classes/Forms/FormOptions";
import Gender from "@/enums/Gender";
import { useRecordStatusChecker } from "@/store/recordStatusChecker";
import Relationships from "@/classes/Forms/Relationships";
import CaseCompaionOfflineForm from "@/classes/Forms/Cases/CaseCompaionOfflineForm";
import { onIonViewWillEnter } from "@ionic/vue";
import OfflineCaseInformant from "@/classes/Forms/Cases/OfflineCaseInformant";

const networkStore = useNetworkStore();
const isNetworkAvailable = computed(() => networkStore.isNetworkAvailable);
const db = new DB("case_companions");
const dbCase = new DB("cases");
const dbPatient = new DB("patients");
const router = useRouter();
const route = useRoute();
const showSuccess = ref<boolean>(false);
const patientId = ref<number | null>(route.params.patient);
const caseId = ref<number | null>(route.params.case);
const guardianId = ref<number | null>(route.params.companion);
const genders = ref<renderSelectV2[]>([]);
const companionRelationships = ref<renderSelect[]>([]);
const form = ref<CompanionCreate>(new CompanionForm());
const errors = ref<object>({});
const successMessage = ref<string>("Companions details has been updated!");
const loaded = ref<boolean>(false);
const offlineParams = ref<string>(route.query.offline);
const patient = ref<string>('');
const options = new FormOptions();
const recordStatusChecker = useRecordStatusChecker();
const relationship = new Relationships()

const save = async (): Promise<any> => {
	errors.value = {};
	if (!isNetworkAvailable.value) {
		const validate = form.value.offlineCreateValidation(form.value);
		errors.value = validate?.errors;

		if (validate.hasOfflineError) {
			showSuccess.value = false;
			return;
		}
	}

    const response = await CompanionService.update(
		patientId.value,
		caseId.value,
		guardianId.value,
		{ ...form.value },
		isNetworkAvailable.value
	);

    if (response?.status === 200 || response?.data != undefined) {
        await saveToOffline(response)
        setTimeout(() => {
            recordStatusChecker.isCreatedInformant = true;
            showSuccess.value = true;
            successMessage.value = response.data.message;
        }, 500);
    } else {
        const error = response;
        errors.value = errorResponse(error.response?.data?.errors);
    }
};

const saveToOffline = async (response: any) => {
    if (!isNetworkAvailable.value) {
        return;
    }
    await OfflineCaseInformant.onlineInformantSaveToOffline(response);
}

const redirectSuccess = async (): Promise<any> => {
	showSuccess.value = false;
    setTimeout(() => {
        router.push(`/patient/${patientId.value}/cases/${caseId.value}/edit`);
    }, 500);
};

const getData = async () => {
    const response = await CompanionService.edit(
		patientId.value,
		caseId.value,
		guardianId.value,
		pickBy({}),
		isNetworkAvailable.value
	);

    if (response?.status === 200 || response?.data != undefined) {
        if (isNetworkAvailable.value) {
            genders.value = response.data.genders;
            patient.value = response.data.patient;
            companionRelationships.value = response.data.companionRelationships;
            form.value.updateCompanion(response.data.item);
        } else {
            genders.value = Gender;
            companionRelationships.value = await options.guardianRelationships();
            form.value.updateCompanion(JSON.parse(response.data.item?.content));
        }
        loaded.value = true;
    } else {
        console.log(response);
    }
};

const renderPatient = async (): Promise<any> => {
    if (!isNetworkAvailable.value) {
        const response = await dbPatient.edit(patientId.value, false, 'patients');
        const item = response.data.item;
        if (item.length === undefined) {
            patient.value = `${relationship.capitalFirstLetter(item.last_name)}, ${relationship.capitalFirstLetter(item.first_name)}`;
        }
    }

}

// watch(
// 	() => isNetworkAvailable.value,
// 	(val) => {
// 		console.log("Watcher - Network: " + val);
// 		getData();
// 	}
// );

onIonViewWillEnter(async () => {
    recordStatusChecker.isCreatedInformant = false;
    await renderPatient();
    await getData();    
});

// onMounted(async () => {
//     recordStatusChecker.isCreatedInformant = false;
//     await renderPatient();
//     await getData();
// });
</script>
