<template>
    <div>
        <label v-if="label" class="block text-xs font-semibold text-neutral-600 mb-3">{{ label }}</label>
        <div class="flex flex-col space-y-2">
            <radio-group
                :options="date_time_options"
                name="data"
            />
        </div>
    </div>
</template>
<script setup lang="ts">
import { ref } from "vue";
import RadioGroup from "@/components/inputs/RadioGroup.vue";

const props = defineProps({
	form: {
		type: Object,
	},
    value: {
        default: null,
        required: true,
    },
    label: {
        type: String,
        default: '',
    },
    error: {
        type: String,
        default: null,
    },
    disabled: {
        type: Boolean,
        default: false,
    },
});
const form = ref<object>(props.form);

const date_time_options = [
    { id: '1', label: 'Date Only', value: 'date_only', radioSelected: null},
    { id: '2', label: 'Date & Time', value: 'date_time', radioSelected: null},
]

</script>
