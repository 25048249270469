<template>
    <!-- <ion-tab-bar slot="bottom" class="bottom-nav h-[58px]">
        <ion-tab-button class="" tab="home" href="/home">
            <ion-icon class="w-6 h-6 m-0 mb-1" :src="homeOutline" />
            <ion-label class="uppercase m-0">Home</ion-label>
        </ion-tab-button>
        <ion-tab-button class="" tab="about" href="/page">
            <ion-icon class="w-6 h-6 m-0 mb-1" :src="gridOutline" />
            <ion-label class="uppercase m-0">Page 1</ion-label>
        </ion-tab-button>
        <ion-tab-button class="" tab="contact" href="/page">
            <ion-icon class="w-6 h-6 m-0 mb-1" :src="gridOutline" />
            <ion-label class="uppercase m-0">Page 2</ion-label>
        </ion-tab-button>
        <ion-tab-button class="" tab="profile" href="/profile">
            <ion-icon class="w-6 h-6 m-0 mb-1" :src="personOutline" />
            <ion-label class="uppercase m-0">Profile</ion-label>
        </ion-tab-button>
    </ion-tab-bar> -->
    <div class="px-6 py-4 bg-primary-600">
        <p class="text-xs text-white font-semibold">Child Protection Network Foundation, Inc.</p>
    </div>
</template>

<script setup lang="ts">


</script>
