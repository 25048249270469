import CryptoJS from 'crypto-js'
import Storage from "@/helpers/storage";
import http from "@/axios";

const tokens = new Storage();		

class PinCodeProcessor {
	
	noPinCode(): boolean {
		const userPin = JSON.parse(tokens.get('user')).pin_code;

		return typeof userPin == 'undefined' || userPin == null
	}

	encrypt(pin: string): string {
		return CryptoJS.AES.encrypt(pin, process.env.VUE_APP_SECRET_KEY).toString();
	}

	validate(pin: string): boolean {
		let userPin = JSON.parse(tokens.get('user')).pin_code;
		console.log(userPin)
		userPin = CryptoJS.AES.decrypt(userPin, process.env.VUE_APP_SECRET_KEY).toString(CryptoJS.enc.Utf8);
		console.log(userPin == pin);
		return userPin === pin;
	}

	async setPin(pin: string): Promise<any> {
		const newPin = this.encrypt(pin);

		return await http.post("profile/setPin", { pin_code: newPin }).then(() => { 
			const user = JSON.parse(tokens.get('user'));
			console.log(user.pin_code, newPin);
			user.pin_code = newPin
			
			tokens.set('user', JSON.stringify(user));
		});
	}
}

export default new PinCodeProcessor();