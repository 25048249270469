import DB from "@/classes/Databases/Crud";
import CaseCompaionOfflineForm from "./CaseCompaionOfflineForm";

const dbPatient = new DB('patients');
const dbCases = new DB('cases');
const dbCaseCompanion = new DB('case_companions');
class OfflineCaseInformant {
    async storeInformantIndexOffline(response: any, caseId: any) {
        const result = await dbCases.edit(caseId, false, 'cases');

        if (result.data.item.length == undefined) {
            const items = response.data;
            for (const index in items) {
                const companionForm = new CaseCompaionOfflineForm(items[index], result.data.item);
                await dbCaseCompanion.insert(companionForm, items[index].id, true, 'case_companions');
            }
        }
    }

    async onlineInformantSaveToOffline(response: any) {
        const data = response.data.data;
        const checkIfRecordIsEditedOffline = await dbCaseCompanion.select(`SELECT * FROM case_companions WHERE id=${data.item.id} AND (is_created_offline=true OR is_updated_offline=true OR is_deleted_offline=true)`);
        if (checkIfRecordIsEditedOffline.length > 0) {
            return;
        }
        const form = new CaseCompaionOfflineForm(data.item, data.item);
        await dbCaseCompanion.insert(form, data.item.id, true, 'case_companions');
    }

    async createCaseInformant(patient: any, patientCase: any, payload: any) {
        const result = await dbCases.edit(patientCase, false, 'cases');

        if (result.data.item.length === undefined) {
            const form = new CaseCompaionOfflineForm({}, {});
			const createForm = await form.createForm(payload, result.data.item);
            const response = await dbCaseCompanion.insert(createForm, null, false, 'case_companions');

            return {
                data: {
                    item: response,
                }
            }
        }
    }

    async updateCaseInformant(patient: any, patientCase: any, companion: any, payload: any) {
        const result = await dbCaseCompanion.edit(companion, false, 'case_companions');
        if (result.data.item.length === undefined) {
            const item = result.data.item;
			const form = new CaseCompaionOfflineForm({}, {});
			const updateForm = await form.updateForm(payload, result.data.item);
            const response = await dbCaseCompanion.insert(updateForm, item.id, false, 'case_companions');

            return {
                data: {
                    item: response,
                }
            }
        }
    }

    async deleteCaseInformant(patient: any, patientCase: any, companion: any, payload: any) {
        const result = await dbCaseCompanion.edit(companion, false, 'case_companions');
        if (result.data.item.length === undefined) {
            const item = result.data.item;
            if (item.is_created_offline) {
                await dbCaseCompanion.delete({id: item.id}, 'case_companions');
                return {
                    data: {
                        item: null,
                    }
                }
            } else {
                const form = new CaseCompaionOfflineForm({}, {});
                const deleteForm = await form.deleteForm(payload, result.data.item);
                const response = await dbCaseCompanion.insert(deleteForm, companion, false, 'case_companions');

                return {
                    data: {
                        item: response,
                    }
                }
            }
        }
    }
}

export default new OfflineCaseInformant();