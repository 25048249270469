<template>
    <svg width="17" height="14" viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="8.32324" cy="3" r="2.5" stroke="white" stroke-linecap="round"/>
        <path d="M10.0912 1.5C10.289 1.15743 10.5839 0.881207 10.9387 0.706255C11.2934 0.531302 11.6921 0.465479 12.0843 0.51711C12.4765 0.568742 12.8445 0.735508 13.1419 0.99632C13.4393 1.25713 13.6527 1.60028 13.7551 1.98236C13.8575 2.36445 13.8443 2.76831 13.7171 3.14288C13.59 3.51745 13.3546 3.8459 13.0408 4.08671C12.7269 4.32751 12.3488 4.46985 11.954 4.49572C11.5593 4.52159 11.1658 4.42983 10.8232 4.23205" stroke="white"/>
        <path d="M6.55529 1.5C6.35751 1.15743 6.06259 0.881207 5.70782 0.706255C5.35305 0.531302 4.95437 0.465479 4.56219 0.51711C4.17001 0.568742 3.80195 0.735508 3.50455 0.99632C3.20715 1.25713 2.99377 1.60028 2.89139 1.98236C2.78901 2.36445 2.80223 2.76831 2.92938 3.14288C3.05653 3.51745 3.2919 3.8459 3.60572 4.08671C3.91954 4.32751 4.29772 4.46985 4.69244 4.49572C5.08715 4.52159 5.48068 4.42983 5.82324 4.23205" stroke="white"/>
        <path d="M8.32324 7.5C12.4035 7.5 13.4567 10.8022 13.7286 12.507C13.8156 13.0524 13.3755 13.5 12.8232 13.5H3.82324C3.27096 13.5 2.83087 13.0524 2.91785 12.507C3.18974 10.8022 4.243 7.5 8.32324 7.5Z" stroke="white" stroke-linecap="round"/>
        <path d="M15.6196 10.4162L15.1346 10.5377L15.6196 10.4162ZM9.41016 7.58591L9.04091 7.24878L8.42048 7.9283L9.32819 8.07915L9.41016 7.58591ZM13.5044 11.5L13.0241 11.639L13.1285 12H13.5044V11.5ZM11.8231 7C12.8508 7 13.5726 7.50265 14.1014 8.20693C14.6408 8.92529 14.9575 9.83057 15.1346 10.5377L16.1046 10.2948C15.9135 9.5315 15.5559 8.47866 14.9011 7.6065C14.2356 6.72026 13.2432 6 11.8231 6V7ZM9.7794 7.92304C10.2798 7.37501 10.9337 7 11.8231 7V6C10.6058 6 9.69661 6.53062 9.04091 7.24878L9.7794 7.92304ZM9.32819 8.07915C11.6287 8.46145 12.602 10.1801 13.0241 11.639L13.9847 11.361C13.5135 9.7326 12.342 7.56628 9.49212 7.09267L9.32819 8.07915ZM14.7181 11H13.5044V12H14.7181V11ZM15.1346 10.5377C15.1884 10.7526 15.0306 11 14.7181 11V12C15.5888 12 16.3383 11.2277 16.1046 10.2948L15.1346 10.5377Z" fill="white"/>
        <path d="M7.23625 7.58591L7.31822 8.07915L8.22592 7.9283L7.6055 7.24878L7.23625 7.58591ZM1.02679 10.4162L1.51182 10.5377L1.02679 10.4162ZM3.14204 11.5V12H3.51789L3.62235 11.639L3.14204 11.5ZM4.82335 7C5.71268 7 6.36664 7.37501 6.86701 7.92304L7.6055 7.24878C6.9498 6.53062 6.04059 6 4.82335 6V7ZM1.51182 10.5377C1.6889 9.83057 2.00565 8.92529 2.54503 8.20693C3.07384 7.50265 3.79563 7 4.82335 7V6C3.40317 6 2.41079 6.72026 1.74536 7.6065C1.0905 8.47865 0.732917 9.5315 0.541772 10.2948L1.51182 10.5377ZM1.92834 11C1.61584 11 1.45801 10.7526 1.51182 10.5377L0.541772 10.2948C0.308138 11.2277 1.0576 12 1.92834 12V11ZM3.14204 11H1.92834V12H3.14204V11ZM3.62235 11.639C4.04441 10.1801 5.01772 8.46145 7.31822 8.07915L7.15429 7.09268C4.30437 7.56628 3.13287 9.73261 2.66174 11.361L3.62235 11.639Z" fill="white"/>
    </svg>
</template>
<script setup lang="ts">
</script>