
import DB from "@/classes/Databases/Crud";

import Pagination from "@/classes/Databases/Pagination";
import FormOptions from "@/classes/Forms/FormOptions";
import DateFormatter from "@/helpers/DateFormatter";

import { OfflineAction } from "@/views/enums/OfflineAction";

const options  = new FormOptions();
const db = new DB('case_conferences', new Pagination('case_conferences'));
const dbRecommendation = new DB('case_conference_recommendations', new Pagination('case_conference_recommendations'));

class RecommendationsService {
	async index(patient: number, patientCase: number, caseConference: number): Promise<any> {
		const patientData = await db.select(`SELECT * FROM patients WHERE id = ${patient}`, 'patients');
		const recommendations = await db.select(`SELECT * FROM case_conference_recommendations where case_conference_id = ${caseConference} AND deleted_at IS NULL`, 'case_conference_recommendations');
		const recommendationOptions = await options.recommendationOptions();

		recommendationOptions.map((item:any) => {
			const index = recommendations.map((x:any) => x.recommendation_option_id).indexOf(item.id);

			let remarks = null;
			let checked = false;

			if(recommendations[index]?.remarks != null){
				remarks = recommendations[index].remarks;
				checked = true;			
			}

			item.remarks = remarks;
			item.checked = checked;

			return item;
		})

		return {
			data: {
				recommendationOptions: recommendationOptions,
				patient_name: `${patientData[0].first_name} ${patientData[0].last_name}`,
				item: {
					case_conference_id: caseConference,
					recommendations: recommendations,
				}
			}
		}
	}

	async storeRecommendationsOffline(items: any): Promise<void> {
		await dbRecommendation.set(items.recommendations, true, 'case_conference_recommendations');
	}

	async store(patient: number, patientCase: number, caseConference: any, payload: any): Promise<any> {
		const data:any = [];
		const notChecked:number[] = [];

		const recommendations = await db.select(`SELECT * FROM case_conference_recommendations where case_conference_id = ${caseConference}  AND deleted_at IS NULL`, 'case_conference_recommendations');
		
		await payload.recommendations.forEach(async(item:any) => {
			const index = recommendations.map((item:any) => item.recommendation_option_id).indexOf(item.id)

			if(index != -1 ){
				if(item.checked){
					data.push(this.updateProcess(recommendations[index], item));
				} else {
					data.push(this.deleteProcess(recommendations[index]));
				}
			} else {
				if(item.checked){
					data.push(this.storeProcess(caseConference, item));
				}
			}

		});
		
		await db.set(data, true, 'case_conference_recommendations');
	}


	private storeProcess(caseConference:any, payload: any): any  {
		return {
			id:null,
			case_conference_id: parseInt(caseConference),
			recommendation_option_id: payload.id,
			remarks: payload.remarks,
			is_created_offline: true, 
			created_at: DateFormatter.customDateFormat(new Date().toDateString(), 'yyyy-MM-dd hh:mm:ss'),
			updated_at: DateFormatter.customDateFormat(new Date().toDateString(), 'yyyy-MM-dd hh:mm:ss'),
		}
	}

	private updateProcess(recommendation: any, payload: any): any{

		return Object.assign(
			recommendation, 
			{ 
				remarks: payload.remarks, 
				is_updated_offline: true, 
				updated_at:  DateFormatter.customDateFormat(new Date().toDateString(), 'yyyy-MM-dd hh:mm:ss')
			}
		);	
	}

	private deleteProcess(recommendation: any): any {

		return Object.assign(
			recommendation, 
			{ 
				remarks: null, 
				action: OfflineAction.DELETE_RECORD, 
				is_deleted_offline: true, 
				deleted_at:  DateFormatter.customDateFormat(new Date().toDateString(), 'yyyy-MM-dd hh:mm:ss')
			}
		);	
	}
}

export default new RecommendationsService();