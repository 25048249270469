<template>
    <user-layout
        hasBack
        backLink="/settings/provinces/index"
    >
        <div class="max-w-[500px] mx-auto py-6" >
            <form-section
                v-if="!loading"
                :form="form"
                :errors="errors"
            >
                <div class="col-span-2">
                    <div class="flex items-center justify-end w-full space-x-3">
                        <a href="/settings/provinces/index/">
                            <button-component
                                btnWidth="quinary"
                            >Cancel</button-component>
                        </a>

                        <button-component
                            btnWidth="primary"
                            @click="showConfirmation = true"
                        >Save Changes</button-component>
                    </div>
                    <confirmation-modal
                        :typeTwo="true"
                        :show="showConfirmation"
                        @cancel="showConfirmation = false"
                        @confirm="save()"
                        cancel-text="Cancel"
                        action-text="Confirm"
                        title="Save Record"
                        content="Are you sure you wish to save this record?"
                    />
                    <success-modal
                        :show="showSuccess"
                        @confirm="showSuccess = false, $router.replace('/settings/provinces/index')"
                        action-text="Close"
                        title="Succes!"
                       :content="successMessage"
                    />
                </div>
            </form-section>
        </div>

        <page-loader :show="loading"/>
    </user-layout>
</template>
<script setup lang="ts">
import { ref, onMounted } from 'vue';
import UserLayout from '@/layouts/UserLayout.vue';
import FormSection from './FormSection.vue';
import ButtonComponent from '@/components/buttons/ButtonComponent.vue';
import PageLoader from "@/components/loaders/PageLoader.vue"
import ConfirmationModal from '@/components/modals/ConfirmationModal.vue';
import SuccessModal from '@/components/modals/SuccessModal.vue';

import { errorResponse } from "@/helpers/errorMessage";

import ProvinceService from "@/classes/Settings/Locations/ProvinceService";

import {
    LocationDataInterface,
    SelectInterface
} from "@/interfaces/Settings/LocationInterface"

import pickBy from "lodash/pickBy";

import assignValues from "@/helpers/assignValues"

import { useRoute, useRouter } from "vue-router";
const route = useRoute();
const router = useRouter();

const loading = ref<boolean>(false);
const showConfirmation = ref<boolean>(false);
const showSuccess = ref<boolean>(false);
const loaded = ref<boolean>(false);
const successMessage = ref<string>('');
const errors = ref<any>();

const form = ref<LocationDataInterface>({
    id: parseInt(route.params.province),
    region_id: null,
    name: '',
    code: '',
});

// const regionOptions = ref<SelectInterface[]>([]);
// const provinceOptions = ref<SelectInterface[]>([]);
// const cityOptions = ref<SelectInterface[]>([]);
// const barangayOptions = ref<SelectInterface[]>([]);

const init = (): void => {
    loading.value = true; 

    ProvinceService.edit(form.value.id, pickBy(form.value))
        .then(({ data }) => { 
            form.value = assignValues(form.value, data.item);
            loading.value = false;
            loaded.value = true;
        })
}

const save = (): void => {
    loading.value = true; 

    ProvinceService.update(form.value.id, form.value)
        .then((response: any) => {
            loading.value = false;
            successMessage.value = response.data.message;
            showSuccess.value = true;
            showConfirmation.value = false
        })
        .catch((error: any) => {
            loading.value = false;
            showConfirmation.value = false
            errors.value = errorResponse(error.response?.data?.errors)
        });
}

onMounted(() => {
    init()
});

</script>