<template>
    <user-layout
        :hasBack="true"
        backLink="/settings/notifications/index"
    >
        <div class="max-w-[500px] mx-auto py-6">
            <form-section>
                <div class="col-span-2">
                    <div class="w-full flex items-center justify-end space-x-3">
                        <a href="/settings/notifications/index">
                            <button-component
                                btnWidth="quinary"
                            >Cancel</button-component>
                        </a>

                        <button-component
                            btnWidth="primary"
                            @click="showConfirmation = true"
                        >Save Changes</button-component>
                    </div>
                    <confirmation-modal
                        :typeTwo="true"
                        :show="showConfirmation"
                        @cancel="showConfirmation = false"
                        @confirm="showSuccess = true; showConfirmation = false"
                        cancel-text="Cancel"
                        action-text="Confirm"
                        title="Save Record"
                        content="Are you sure you wish to save this record?"
                    />
                    <success-modal
                        :show="showSuccess"
                        @confirm="showSuccess = false"
                        action-text="Close"
                        title="Record Updated!"
                        content="Data has been successfully updated"
                    />
                </div>
            </form-section>
        </div>
    </user-layout>
</template>
<script setup lang="ts">
import { ref } from 'vue';
import UserLayout from '@/layouts/UserLayout.vue';
import FormSection from './FormSection.vue';
import ButtonComponent from '@/components/buttons/ButtonComponent.vue';

import ConfirmationModal from '@/components/modals/ConfirmationModal.vue';
import SuccessModal from '@/components/modals/SuccessModal.vue';

const showConfirmation = ref<boolean>(false);
const showSuccess = ref<boolean>(false);
</script>