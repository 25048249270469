<template>
	<intake-layout
		:isShowNav="isShowNav"
		:patient="patient"
	>

		<template #header>
			<p class="text-xl font-semibold">Edit Persons Related to Case</p>
		</template>

		<template #actionButtons>

			<!-- Edit -->
			<template v-if="!isShowNav">
				<button-component 
					btnWidth="quinary"
					fill="outline"
					@click="isShowNav = true"
				>
					Cancel
				</button-component>
				<button-component
					@click="save()"
				>
					Save Changes
				</button-component>
			</template>
		</template>

		<!-- Persons Case Information -->
		<template v-if="isShowNav">
			<div class="col-span-12">
				<data-table
					:headers="headers"
					:no-action="false"
					:count="items.data.length"
					dataTableClass="border-t-0 border-x-0 "
				>
					<template #body>
						<template v-for="item in items.data" :key="item">
							<tr v-if="item.id > 0">
								<td class="p-6 text-sm font-normal text-gray-900 ">
									{{ item.name }}
								</td>
								<td class="text-sm font-normal text-gray-900">
									{{ item.gender_formatted }}
								</td>
								<td class="text-sm font-normal text-gray-900">
									{{ item.companion_relationship }}
								</td>
								<td class="text-sm font-normal text-gray-900">
									<p v-if="BirthdayClassification.BIRTHDATE == item.birthday_classification">  {{DateFormatter.customDateFormat(item?.birthdate, 'MM/dd/yyy')}} </p>
									<p v-else-if="BirthdayClassification.APPROXIMATE_AGE == item.birthday_classification"> Approximate age </p>
									<p v-else> Unknown </p>
								</td>
								<td class="text-sm font-normal text-gray-900">
									{{ item?.computed_age }}
								</td>
								<td class="text-sm font-normal text-gray-900">
									{{ item?.contacts ? "+63"+item?.contacts : '-' }}
								</td>
								<td class="flex justify-center p-6 space-x-2">
									<view-button 
										:isEyeIcon="false" 
										@click="showForm(item)"
										class="shrink-0"
									/>
									<delete-button 
										class="shrink-0"
										@click="showConfirmationModal(item.id)"
									/>
								</td>
							</tr>
						</template>
					</template>
				</data-table>
			</div>

			<div class="flex justify-end col-span-12">
				<button-component 
					customClass="primary sm:w-auto w-full"
					@click="addNew()"
				>
					<PlusIcon class="w-3.5 h-3.5 "/>
					<p>Add New</p>
				</button-component>
			</div>
		</template>

		<!-- Edit Page -->
		<template v-else>
			<div class="col-span-12">
				<edit-page 
					ref="editForm"
					:form="form" 
					:errors="errors" 
					:optionRelationChild="companionRelationship"
					:optionBirthday="birthdayClassification"
					:optionSelected="personRelatedType"
					:optionCivilStatus="civilStatus"
					:optionYerOrNo="optionYerOrNo"
					:optionEducationAttain="educationalAttainment"
					:optionHousingClass="housingClassification"
					:optionGenders="genders"
				/>
			</div>
		</template>
		
		<!-- MODAL -->
		<success-modal
			noBtn
			:show="showSuccess"
			action-text="Close"
			title="Record Save"
			content="Intake has been updated" 
            @close="showSuccess = false"
		/>

		<confirmation-modal 
			:show="showConfirmation" 
			@cancel="showConfirmation = false"
			@confirm="archive()" 
			cancel-text="Cancel" 
			action-text="Confirm" 
			title="Delete Record"
			content="Are you sure you wish to delete this Person?" 
		/>

		<page-loader :show="loading"/>
	</intake-layout>

</template>

<script setup lang="ts">
import PageLoader from "@/components/loaders/PageLoader.vue"
import IntakeLayout from '../../Component/IntakeLayout.vue';
import ViewButton from "@/components/buttons/ViewButton.vue";
import DeleteButton from "@/components/buttons/DeleteButton.vue";
import DataTable from "@/components/partials/DataTable.vue";
import EditPage from './EditPage.vue';
import ButtonComponent from "@/components/buttons/ButtonComponent.vue";
import ConfirmationModal from "@/components/modals/ConfirmationModal.vue";

import { ref, onMounted, computed, watch } from 'vue'; 
import { PlusIcon } from "@heroicons/vue/24/solid"; 
import SuccessModal from "@/components/modals/SuccessModal.vue";
import { useRouter, useRoute } from "vue-router";
import PersonRelatedToCaseService from '@/classes/Cases/IntakeForms/PersonRelatedToCaseService';
import { OptionInterface } from '@/interfaces/OptionInterface';
import { BirthdayClassification } from "@/views/enums/BirthdayClassification";
import { errorResponse } from "@/helpers/errorMessage";
import DateFormatter from "@/helpers/DateFormatter";
import { useNetworkStore } from "@/store/network";
import OfflineSyncPersonRelatedToCase from "@/classes/Forms/Cases/Intakes/OfflineSyncPersonRelatedToCase";
import Gender from "@/enums/Gender";
import FormOptions from "@/classes/Forms/FormOptions";
import { onIonViewWillEnter } from "@ionic/vue";
import IntakerForm from "@/classes/Forms/Cases/IntakeForm";
import Relationships from '@/classes/Forms/Relationships';
import DB from "@/classes/Databases/Crud";
import GuardianService from "@/classes/Cases/GuardianService";

const dbPatient = new DB("patients");
const router = useRouter();
const route = useRoute();

const form = ref({})
const errors = ref<any>();

const isShowNav = ref<boolean>(true);
const showSuccess = ref<boolean>(false);
const loading = ref<boolean>(false);
const showConfirmation = ref<boolean>(false);
const editForm = ref<any>({});

const selectedItem = ref<number>(0);

const route_params = route.params;

const headers = [
	{ text: 'Full Name' },
	{ text: 'Gender' },
	{ text: 'Relationship' },
	{ text: 'Birthdate' },
	{ text: 'Age' },
	{ text: 'Contact Number' }
]

const birthdayClassification = ref<OptionInterface[]>([]);
const personRelatedType = ref<OptionInterface[]>([]);
const companionRelationship = ref<OptionInterface[]>([]);
const civilStatus = ref<OptionInterface[]>([]);
const educationalAttainment = ref<OptionInterface[]>([]);
const housingClassification = ref<OptionInterface[]>([]);
const genders = ref<OptionInterface[]>([]);
const patient = ref<string>('');
const networkStore = useNetworkStore();
const isNetworkAvailable = computed(() => networkStore.isNetworkAvailable);
const options = new FormOptions();
const isIonWillEnter = ref<boolean>(false);
const formValidation = new IntakerForm({})
const relationship = new Relationships();

const optionYerOrNo = [
	{ id: 0, value: 0, label: 'No' },
	{ id: 1, value: 1, label: 'Yes' },
]

const data = {
	id:  0,
	name: '-',
	gender_formatted: '-',
	companion_relationship: '-',
	birthdate: null,
	computed_age: '-',
	contacts: '-'
}

const items = ref({
	data: [ data ]
})

const addNew = () => {
	isShowNav.value = false;
    errors.value = null;
	Object.assign(form.value, clearForm());
}

const save = async () => {
	loading.value = true;
	form.value = editForm.value.formData;

	let method = null;
    if (!isNetworkAvailable.value) {
        const validate = formValidation.offlinePersonRelatedToCaseValidation(form.value);
		errors.value = validate?.errors;
		if (validate.hasOfflineError) {
			showSuccess.value = false;
            loading.value = false;
			return;
		}
    }

    if (isNetworkAvailable.value) {
        if(form.value.id){
            method = PersonRelatedToCaseService.update(route_params?.patient, route_params?.case, form.value)
        } else {
            method = PersonRelatedToCaseService.store(route_params?.patient, route_params?.case, form.value)
        }	

        method
            .then(({data}) => {
                loading.value = false;

                items.value = data.items;

                showSuccess.value = true;
                setTimeout(() => {
                    location.reload()
                }, 1000);
                clearForm()
            })
            .catch(error => {
                loading.value = false;

                errors.value = errorResponse(error.response?.data?.errors)
            })
    } else {
        await OfflineSyncPersonRelatedToCase.save(form.value, route_params?.patient, route_params?.case);
        await fetchOfflineRecord()
        loading.value = false;
        showSuccess.value = true;
        isShowNav.value = true;
        clearForm();
    }
    
	
}

const fetch = async ():void => {
	loading.value = true;

    if (!isNetworkAvailable.value) {        
        return;
    }

	await PersonRelatedToCaseService
		.index(route_params?.patient, route_params?.case)
		.then(async ({data}) => {
			items.value = data.items;

			birthdayClassification.value = data.birthdayClassification
			personRelatedType.value = data.personRelatedType
			companionRelationship.value = data.companionRelationship
			civilStatus.value = data.civilStatus
			educationalAttainment.value = data.educationalAttainment
			housingClassification.value = data.housingClassification
			patient.value = data.patient
			genders.value = data.genders
			loading.value = false;

            // await OfflineSyncPersonRelatedToCase.syncToOffline(data.items, route_params?.case);

		})
		.catch(errors => {
			console.log(errors)
		})
}

const fetchOfflineRecord = async () => {
    if (isNetworkAvailable.value) {
        return;
    }
    const response = await GuardianService.index(
        route_params?.patient,
        route_params?.case,
        { rows: 100 },
        isNetworkAvailable.value
    );
    // const response = await OfflineSyncPersonRelatedToCase.index({
    //     cases_id: route_params?.case
    // }, route_params?.case);

    if (response.data.items.data.length > 0) {
        const records = response.data.items;
        items.value = records;
        for (const index in records.data) {
            const item = records.data[index];
            items.value.data[index] = await OfflineSyncPersonRelatedToCase.transformContent(JSON.parse(item.content), item);
        }
    }

    birthdayClassification.value = options.birthdayClassification();
    civilStatus.value = await options.civilStatusOptions();
    housingClassification.value = await options.housingClassifications();
    educationalAttainment.value = await options.educationalAttainments();
    companionRelationship.value = await options.companionRelationships();
    personRelatedType.value = options.personRelatedTypes();
    genders.value = Gender;
    await renderPatient();
    loading.value = false;
}

const renderPatient = async (): Promise<any> => {
    if (!isNetworkAvailable.value) {
        const response = await dbPatient.edit(route_params?.patient, false, 'patients');
        const item = response.data.item;
        if (item.length === undefined) {
            patient.value = `${relationship.capitalFirstLetter(item.last_name)}, ${relationship.capitalFirstLetter(item.first_name)}`;
        }
    }
}

const showConfirmationModal = (item:number):void => {
	showConfirmation.value = true; 
	selectedItem.value = item
}

const archive = async ():void => {
	loading.value = true;
	showConfirmation.value = false; 

    if (isNetworkAvailable.value) {
        await PersonRelatedToCaseService
		.archive(route_params?.patient, route_params?.case, selectedItem.value)
		.then(async ({data}) => {
			await fetch()
			loading.value = false;
		})
		.catch(errors => {
			console.log(errors)
			loading.value = false;
		})
    } else {
        await OfflineSyncPersonRelatedToCase.delete(selectedItem.value, route_params?.case);
        await fetchOfflineRecord();
        loading.value = false;
    }

}


const showForm = (item:any) => {
    errors.value = null;
	Object.assign(form.value, item)
	isShowNav.value = false;
}

const clearForm = ():void => {
	form.value = {
		id: null,
		cases_id: route_params?.case,
		first_name: null,
		middle_name: null,
		last_name: null,
		gender: null,
		birthday_classification: BirthdayClassification.BIRTHDATE,
		birthdate: null,
		age: null,
		contacts: null,
		person_related_types: [],
		companion_relationship_id: null,
		civil_status_id: null,
		is_employed: 0,
		is_ofw: 0,
		occupation: null,
		company: null,
		weekly_income: null,
		other_source_of_income: null,
		is_enrolled_in_school: 0,
		educational_attainment_id: null,
		school: null,
		housing_classification_id: null,
		no_children_in_family: null,
		no_household_members: null,
		no_family_in_household: null,
		other_information: null,
	}
}

// watch(
// 	() => isNetworkAvailable.value,
// 	async (val) => {
// 		console.log("Watcher - Network: " + val);
//         await fetch()
//         await fetchOfflineRecord();
// 	}
// );

onIonViewWillEnter(async () => {
    if (isIonWillEnter.value) {
        await fetch();
        await fetchOfflineRecord();
        clearForm();
    }
});

onMounted(async () => {
	await fetch()
    await fetchOfflineRecord();
	clearForm()
    isIonWillEnter.value = true;
})

</script>