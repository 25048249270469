import { IntakeCreate } from "@/interfaces/Cases/Intake";
import { number } from "zod";
export default class IntakerForm implements IntakeCreate {
	first_name: string | null;
	last_name: string | null;
	middle_name: string | null;
	gender: number | null;
	birthday_classification: number | null;
	birthdate: string | null;
	approximate_age_year: number | null;
	approximate_age_month: number | null;
	civil_status_id: number | null;
	contacts: string | null;
	is_living_with_child: boolean;
	is_employed: boolean;
	is_ofw: boolean;
	occupation: string | null;
	weekly_income: number | null;
	housing_classification_id: number | null;
	socio_economic_status_id: number | null;
	family_children_count: number | null;
	house_member_count: number | null;
	house_family_count: number | null;
	family_background: string | null;
    legal_status: number | null;
    other_relationship: string | null;
    relationship_id: number | null;
	constructor(item: IntakeCreate) {
		this.first_name = item?.first_name;
		this.last_name = item?.last_name;
		this.middle_name = item?.middle_name;
		this.gender = item?.gender;
		this.birthday_classification = item?.birthday_classification;
		this.birthdate = item?.birthdate == "0000-00-00" ? "" : item?.birthdate;
		this.approximate_age_year = item?.approximate_age_year;
		this.approximate_age_month = item?.approximate_age_month;
		this.civil_status_id = item?.civil_status_id;
		this.contacts = item?.contacts;
		this.is_living_with_child = item?.is_living_with_child || false;
		this.is_employed = item?.is_employed || false;
		this.is_ofw = item?.is_ofw || false;
		this.occupation = item?.occupation;
		this.weekly_income = item?.weekly_income;
		this.housing_classification_id = item?.housing_classification_id;
		this.socio_economic_status_id = item?.socio_economic_status_id;
		this.family_children_count = item?.family_children_count;
		this.house_member_count = item?.house_member_count;
		this.house_family_count = item?.house_family_count;
		this.family_background = item?.family_background;
        this.legal_status = item?.legal_status;
        this.other_relationship = item?.other_relationship;
        this.relationship_id = item?.relationship_id;
	}

	updateIntake(item: IntakeCreate) {
		this.first_name = item?.first_name;
		this.last_name = item?.last_name;
		this.middle_name = item?.middle_name;
		this.gender = item?.gender;
		this.birthday_classification = item?.birthday_classification;
		this.birthdate = item?.birthdate == "0000-00-00" ? "" : item?.birthdate;
		this.approximate_age_year = item?.approximate_age_year;
		this.approximate_age_month = item?.approximate_age_month;
		this.civil_status_id = item?.civil_status_id;
		this.contacts = item?.contacts;
		this.is_living_with_child = item?.is_living_with_child;
		this.is_employed = item?.is_employed;
		this.is_ofw = item?.is_ofw;
		this.occupation = item?.occupation;
		this.weekly_income = item?.weekly_income;
		this.housing_classification_id = item?.housing_classification_id;
		this.socio_economic_status_id = item?.socio_economic_status_id;
		this.family_children_count = item?.family_children_count;
		this.house_member_count = item?.house_member_count;
		this.house_family_count = item?.house_family_count;
		this.family_background = item?.family_background;
        this.legal_status = item?.legal_status;
        this.other_relationship = item?.other_relationship;
        this.relationship_id = item?.relationship_id;
	}

    offlineCreateValidation(form: any) {
		const errors = <any>{};
		let hasOfflineError = false;

		// if (!form.first_name) {
		// 	hasOfflineError = true;
		// 	Object.assign(errors, { first_name: "The first name field is required." });
		// }

		// if (!form.last_name) {
		// 	hasOfflineError = true;
		// 	Object.assign(errors, { last_name: "The last name field is required." });
		// }

		// if (form.gender == undefined) {
		// 	hasOfflineError = true;
		// 	Object.assign(errors, { gender: "The gender field is required." });
		// }

		if (!form.housing_classification_id) {
			hasOfflineError = true;
			Object.assign(errors, { housing_classification_id: "The housing classification field is required." });
		}

		if (form.legal_status == undefined) {
			hasOfflineError = true;
			Object.assign(errors, { legal_status: "The legal status field is required." });
		}

		if (!form.no_children_in_family) {
			hasOfflineError = true;
			Object.assign(errors, { no_children_in_family: "The number of children in the family field is required." });
		}

		if (!form.no_family_in_household) {
			hasOfflineError = true;
			Object.assign(errors, { no_family_in_household: "The number of families in household field is required." });
		}

		if (!form.no_household_members) {
			hasOfflineError = true;
			Object.assign(errors, { no_household_members: "The number of household members field is required." });
		}

		if (!form.socio_economic_status_id) {
			hasOfflineError = true;
			Object.assign(errors, { socio_economic_status_id: "The socio economic status field is required." });
		}

		return {
			hasOfflineError: hasOfflineError,
			errors: errors,
		};
	}

    offlinePersonRelatedToCaseValidation(form: any) {
        const errors = <any>{};
		let hasOfflineError = false;

        if (!form.first_name) {
			hasOfflineError = true;
			Object.assign(errors, { first_name: "The first name field is required." });
		}

		if (!form.last_name) {
			hasOfflineError = true;
			Object.assign(errors, { last_name: "The last name field is required." });
		}

		if (form.gender == undefined) {
			hasOfflineError = true;
			Object.assign(errors, { gender: "The gender field is required." });
		}

        if (form.companion_relationship_id == null) {
            hasOfflineError = true;
			Object.assign(errors, { companion_relationship_id: "The companion relationship id field is required." });
        }

        if (!form.birthdate && !form.approximate_age_month) {
            hasOfflineError = true;
            Object.assign(errors, { approximate_age_month: "The approximate age month field is required when birthdate is not present." });
        }

        if (!form.birthdate && !form.approximate_age_year) {
            hasOfflineError = true;
            Object.assign(errors, { approximate_age_year: "The approximate age year field is required when birthdate is not present." });
        }

        if (!form.approximate_age_month && !form.birthdate) {
            hasOfflineError = true;
            Object.assign(errors, { birthdate: "The birthdate field is required when approximate age month is not present." });
        }

        if (!form.approximate_age_year && !form.birthdate) {
            hasOfflineError = true;
            Object.assign(errors, { birthdate: "The birthdate field is required when approximate age year is not present." });
        }

        if (form.contacts && form.contacts.length < 10) {
            hasOfflineError = true;
            Object.assign(errors, { contacts: "The contacts field must have at least 10 digits." });
        }

        return {
			hasOfflineError: hasOfflineError,
			errors: errors,
		};
    }

    offlineContactNumberValidation(form: any) {
        const errors = <any>{};
		let hasOfflineError = false;

        if (!form.label) {
			hasOfflineError = true;
			Object.assign(errors, { label: "The label field is required." });
		}

        if (!form.number) {
			hasOfflineError = true;
			Object.assign(errors, { number: "The contact number field is required." });
		}

        if (form.number && form.number.length < 10) {
            hasOfflineError = true;
            Object.assign(errors, { number: "The contacts number field must have at least 10 digits." });
        }

        return {
			hasOfflineError: hasOfflineError,
			errors: errors,
		};
    }

    offlineEmailValidation(form: any) {
        const errors = <any>{};
		let hasOfflineError = false;

        if (!form.label) {
			hasOfflineError = true;
			Object.assign(errors, { label: "The label field is required." });
		}

        if (!form.email) {
			hasOfflineError = true;
			Object.assign(errors, { email: "The email address field is required." });
		}

        return {
			hasOfflineError: hasOfflineError,
			errors: errors,
		};
    }

    offlineIncestValidation(form: any) {
        const errors = <any>{};
		let hasOfflineError = false;

        if (form.same_bed_with.length === 0) {
			hasOfflineError = true;
			Object.assign(errors, { same_bed_with: "The same bed with field is required." });
		}

        if (form.same_room_with.length === 0) {
			hasOfflineError = true;
			Object.assign(errors, { same_room_with: "The same room with field is required." });
		}

        return {
			hasOfflineError: hasOfflineError,
			errors: errors,
		};
    }
}
