<template>
	<user-layout
		hasBack
		:backLink="`/patient/${patientId}/cases/${caseId}/edit`"
		backTitle="Add New Guardian"
		hasBackClass="flex md:justify-between md:items-center md:flex-row flex-col">
		<template #lowerRight>
			<div class="flex md:flex-row flex-col items-center md:space-x-3 md:space-y-0 space-y-3">
				<a
					@click="
						router.replace(
							`/patient/${patientId}/cases/${caseId}/edit`
						)
					"
					class="md:w-auto w-full md:mt-0 mt-6">
					<button-component btnWidth="quinary" customClass="quinary md:w-auto w-full">
						Cancel
					</button-component>
				</a>
				<button-component
					customClass="primary md:w-auto w-full md:mt-0 mt-6"
					form="form" 
					@click="save()"
				>
					<span>Save Changes</span>
				</button-component>
			</div>
		</template>
		<p class="mt-6 md:text-2xl text-lg font-medium text-neutral-600">Patient's Name:
			<span class="md:inline-block block font-semibold text-neutral-900">{{ patient }}</span>
		</p>
		<div class="w-full lg:p-6 p-4 mx-auto my-6 border border-gray-200 rounded">
			<div class="flex items-center justify-between">
				<section-title title="Guardian Details" />
			</div>
			<form-section
				:form="form"
				:errors="errors"
				:genders="genders"
				:guardianRelationships="guardianRelationships" />
		</div>
		<success-modal
			:show="showSuccess"
			@confirm="redirectSuccess()"
			action-text="Close"
			title="Record Save!"
			:content="successMessage" />
	</user-layout>
</template>
<script setup lang="ts">
import { onMounted, ref, computed, watch, onUnmounted } from "vue";
import UserLayout from "@/layouts/UserLayout.vue";
import SectionTitle from "@/components/partials/SectionTitle.vue";
import ButtonComponent from "@/components/buttons/ButtonComponent.vue";
import SuccessModal from "@/components/modals/SuccessModal.vue";
import FormSection from "./FormSection.vue";
import { useRouter, useRoute } from "vue-router";
import GuardianService from "@/classes/Cases/GuardianService";
import { renderSelect, renderSelectV2 } from "@/interfaces/DropdownInterface";
import { GuardianCreate } from "@/interfaces/Cases/Guardian";
import GuardianForm from "@/classes/Forms/Cases/GuardianForm";
import { errorResponse } from "@/helpers/errorMessage";
import { useNetworkStore } from "@/store/network";
import pickBy from "lodash/pickBy";
import DB from "@/classes/Databases/Crud";
import FormOptions from "@/classes/Forms/FormOptions";
import Gender from "@/enums/Gender";
import { useRecordStatusChecker } from "@/store/recordStatusChecker";
import Relationships from "@/classes/Forms/Relationships";
import CaseGuardianOfflineForm from "@/classes/Forms/Cases/CaseGuardianOfflineForm";
import { onIonViewWillEnter, onIonViewWillLeave } from "@ionic/vue";
import OfflineCaseGuardian from "@/classes/Forms/Cases/OfflineCaseGuardian";

const networkStore = useNetworkStore();
const isNetworkAvailable = computed(() => networkStore.isNetworkAvailable);
const db = new DB("case_guardians");
const dbPatient = new DB("patients");
const router = useRouter();
const route = useRoute();
const showSuccess = ref<boolean>(false);
const patient = ref<string>('');
const patientId = ref<number | null>(route.params.patient);
const caseId = ref<number | null>(route.params.case);
const genders = ref<renderSelectV2[]>([]);
const guardianRelationships = ref<renderSelect[]>([]);
const form = ref<GuardianCreate>(new GuardianForm());
const errors = ref<object>({});
const successMessage = ref<string>("New guardian has been added!");
const offlineParams = ref<string>(route.query.offline);
const options = new FormOptions();
const recordStatusChecker = useRecordStatusChecker();
const relationship = new Relationships()

const save = async (): void => {
	errors.value = {};
	if (!isNetworkAvailable.value) {
		const validate = form.value.offlineCreateValidation(form.value);
		errors.value = validate?.errors;

		if (validate.hasOfflineError) {
			showSuccess.value = false;
			return;
		}
	}

    const response = await GuardianService.store(
		patientId.value,
		caseId.value,
		{ ...form.value },
		isNetworkAvailable.value
	);

    if (response?.status === 200 || response?.data != undefined) {
        await saveToOffline(response);
        showSuccess.value = true;
        successMessage.value = response.data.message;
        recordStatusChecker.isCreatedGuardian = true;
    } else {
        const error = response;
        errors.value = errorResponse(error.response?.data?.errors);
    }
};


const redirectSuccess = (): void => {
	showSuccess.value = false;
	router.push(`/patient/${patientId.value}/cases/${caseId.value}/edit`);
};

const getData = async () => {
    const response = await GuardianService.create(
        patientId.value,
		caseId.value,
		pickBy({}),
		isNetworkAvailable.value
	);

    if (response?.status === 200 || response == undefined) {
            form.value.is_no_guardian = false;
			if (isNetworkAvailable.value) {
				genders.value = response.data.genders;
				patient.value = response.data.patient
				guardianRelationships.value = response.data.guardianRelationships;
			} else {
				genders.value = Gender;
				guardianRelationships.value = await options.guardianRelationships();
			}
    }
};

const saveToOffline = async (response: any) => {
    if (!isNetworkAvailable.value) {
        return;
    }
    await OfflineCaseGuardian.onlineGuardianSaveToOffline(response);
}

const resetForm = () => {
    form.value.is_no_guardian = false;
    form.value.first_name = null;
    form.value.last_name = null;
    form.value.middle_name = null;
    form.value.gender = null;
    form.value.birthday_classification = null;
    form.value.birthdate = null;
    form.value.approximate_age_month = null;
    form.value.approximate_age_year = null;
    form.value.guardian_relationship_id = null;
    form.value.other_relationship = null;
    form.value.contacts = null;
}

const renderPatient = async (): Promise<any> => {
    if (!isNetworkAvailable.value) {
        const response = await dbPatient.edit(patientId.value, false, 'patients');
        const item = response?.data?.item;
        if (item?.length === undefined) {
            patient.value = `${relationship.capitalFirstLetter(item.last_name)}, ${relationship.capitalFirstLetter(item.first_name)}`;
        }
    }

}

// watch(
// 	() => isNetworkAvailable.value,
// 	async (val) => {
// 		console.log("Watcher - Network: " + val);
// 		await getData();
// 	}
// );

onIonViewWillLeave(() => resetForm());

onIonViewWillEnter(async () => {
    recordStatusChecker.isCreatedGuardian = false;
    resetForm();
    await renderPatient();
	await getData();
});

// onMounted(async () => {
//     recordStatusChecker.isCreatedGuardian = false;
//     resetForm();
//     await renderPatient();
// 	await getData();
// });
</script>
