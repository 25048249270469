<template>
	<user-layout>
		<div class="-mx-6 w-[calc(100%+48px)] flex flex-col">
			<div
				class="md:sticky relative top-0 z-10 bg-white px-6 py-4 shadow-[0_1px_0px_0px_rgba(0,0,0,0.08)] flex lg:justify-between lg:items-center w-full lg:flex-row flex-col">
                <div>
                    <a @click="redirectBack()">
                        <button-component customClass="tertiary text-black hover:text-primary-600 transition pl-0">
                            <!-- <img src="assets/icon/ic-arrow-left.svg" alt="back-arrow" class="object-contain w-6 h-6 mr-2" /> -->
							<arrow-left-icon class="object-contain w-6 h-6 mr-2" />
                            <span class="text-sm font-semibold">View Interventions</span>
                        </button-component>
                    </a>
                    <p class="text-lg font-medium md:text-2xl text-neutral-600">Patient's Name:
                        <span class="block font-semibold md:inline-block text-neutral-900">{{ patient }}</span>
                    </p>
                </div>

				<div class="lg:w-[600px] w-full lg:mt-0 mt-6">
					<div class="flex flex-wrap items-end lg:justify-end lg:space-x-2">
						<template v-for="(item, index) in interventionTypes" :key="index">
							<div
								@click="createIntervention(item.id)"
								class="col-span-1 cursor-pointer md:justify-center md:w-fit w-full flex md:py-[7px] py-[14px] px-4 rounded bg-primary-800 hover:bg-primary-700 font-semibold text-xs text-white transition btn my-1 lg:mr-0 md:mr-2"
							>
								<component
									v-if="fetchInterventionIcon(item.id)"
									:is="fetchInterventionIcon(item.id)"
									class="object-contain w-4 h-4 mr-1 -ml-2 icon"
								/>
								<PlusIcon v-else class="w-4 h-4 stroke-[3px] -ml-2 mr-1 icon" />

								<span class="whitespace-nowrap">
									{{ item.label }}
								</span>
							</div>
						</template>
					</div>
				</div>
			</div>
			<div class="flex-1 w-full px-6 mt-6 mb-6">
				<intervention-view-page
					@update:patientName="(value) => (patient = value)"
					@update:interventionType="(value) => (interventionTypes = value)"
				/>
			</div>
		</div>
	</user-layout>
</template>
<script setup lang="ts">
import { ref, Component } from "vue";
import UserLayout from "@/layouts/UserLayout.vue";
import ButtonComponent from "@/components/buttons/ButtonComponent.vue";
import InterventionViewPage from "./Components/InterventionViewPage.vue";
import { PlusIcon } from "@heroicons/vue/24/outline";
import { useRouter, useRoute } from "vue-router";
import { SelectInterface } from "@/interfaces/pages/BasePageInterface";
import { InterventionType } from "@/views/enums/InterventionType";
import ArrowLeftIcon from "@/components/icons/arrowLeftIcon.vue";
import SocialWorkIcon from "@/components/icons/socialWorkIcon.vue";
import MentalHealthIcon from "@/components/icons/mentalHealthIcon.vue";
import DevelopmentalPediaIcon from "@/components/icons/developmentalPediaIcon.vue";
import LegalIcon from "@/components/icons/legalIcon.vue";

const router = useRouter();
const route = useRoute();
const patientId = ref<number>(route.params.patient);
const caseId = ref<number | null>(route.params.case);

const patient = ref<string>('');
const interventionTypes = ref<SelectInterface[]>([]);

const redirectBack = () => {
	let url = { path: `intervention/search` }

	if(caseId?.value) {
		url = { path: `/patient/${patientId.value}/cases/${caseId.value}/edit` }
	}

	router.push(url); 
};

const createIntervention = (intervention:number):void => {
	let url = `/patient/${patientId.value}/intervention/${intervention}/create`

	if(caseId?.value) {
		url = `/patient/${patientId.value}/cases/${caseId.value}/intervention/${intervention}/create`
	}
	
	router.replace(url);
};

const fetchInterventionIcon = (intervention:number): Component|null => {
    switch (intervention) {
        case InterventionType.SOCIAL_WORK:
            // return  "/assets/icon/intervention/ic-social-work.svg"
			return  SocialWorkIcon;
        case InterventionType.MENTAL_HEALTH:
            // return  "/assets/icon/intervention/ic-mental-health.svg"
			return  MentalHealthIcon
        case InterventionType.DEVELOPMENT_PEDIA:
            // return  "/assets/icon/intervention/ic-developmental-pedia.svg"
			return  DevelopmentalPediaIcon
        case InterventionType.LEGAL:
            // return  "/assets/icon/intervention/ic-legal.svg"
			return  LegalIcon
        default:
			return null;
    }
}
</script>
