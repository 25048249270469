<template>
    <user-layout hasBack backTitle="Intake Report" backLink="/reports">

        <template #lowerRight>
            <export-button v-if="item" @click="generatePDFAccess()" />
        </template>

        <div class="-mx-6 w-[calc(100%+48px)] pb-6">
            <div class="grid grid-cols-12 gap-6 py-6 mx-6">
                <div class="col-span-4">
                    <multi-select
                        :options="cases"
                        id="case_id"
                        name="case_id"
                        label="Case Number"
                        placeholder="Select.." 
                        searchable
                        v-model="filters.case_id"
                    />
                </div>
                <div class="col-span-4">
                    <text-input
                        placeholder="Patient last name" 
                        id="patient_last_name"
                        name="patient_last_name"
                        label="Patient Last Name"
                        disabled
                        v-model="last_name"
                    />
                </div>
                <div class="col-span-4">
                    <text-input
                        placeholder="Patient first name" 
                        id="patient_first_name"
                        name="patient_first_name"
                        label="Patient First Name"
                        disabled
                        v-model="first_name"
                    />
                </div>
            </div>
            <div class="p-6 mx-6 border rounded-lg border-neutral-100">
                <div class="flex items-center justify-between">
                    <section-title title="Intake" />
                </div>
                <div class="grid items-start grid-cols-6 gap-6">
                    <div class="col-span-6"></div>
                    <p class="col-span-6 text-sm font-medium text-neutral-600"> Personal Information </p>
                    <div class="grid grid-cols-3 col-span-3 gap-3">
                        <div class="col-span-1 pb-2 border-b border-gray-100">
                            <p class="font-medium">Full Name:</p>
                        </div>
                        <div class="col-span-2 pb-2 pl-3 border-b border-gray-100">
                            <p>{{ item?.name }}</p>
                        </div>
                        <div class="col-span-1 pb-2 border-b border-gray-100">
                            <p class="font-medium">Sex:</p>
                        </div>
                        <div class="col-span-2 pb-2 pl-3 border-b border-gray-100">
                            <p>{{ item?.sex_form }}</p>
                        </div>
                        <div class="col-span-1 pb-2 border-b border-gray-100">
                            <p class="font-medium">Gender:</p>
                        </div>
                        <div class="col-span-2 pb-2 pl-3 border-b border-gray-100">
                            <p>{{ item?.gender }}</p>
                        </div>
                        <div class="col-span-1 pb-2 border-b border-gray-100">
                            <p class="font-medium">Birthdate:</p>
                        </div>
                        <div class="col-span-2 pb-2 pl-3 border-b border-gray-100">
                            <p>{{ item?.birthdate || "Unknown" }}</p>
                        </div>
                        <div class="col-span-1 pb-2 border-b border-gray-100">
                            <!-- <p v-if="BirthdayClassification.APPROXIMATE_AGE == item?.birthday_classification"> Approximate Age: </p> -->
                            <p > Age: </p>
                        </div>
                        <div class="col-span-2 pb-2 pl-3 border-b border-gray-100">
                            <p >{{ item?.age }} &nbsp;</p>
                        </div>
                        <div class="col-span-1 pb-2 border-b border-gray-100">
                            <p class="font-medium">Civil Status:</p>
                        </div>
                        <div class="col-span-2 pb-2 pl-3 border-b border-gray-100">
                            <p>{{ item?.civil_status }}</p>
                        </div>
                        <div class="col-span-1 pb-2 border-b border-gray-100">
                            <p class="font-medium">Contact Number:</p>
                        </div>
                        <div class="col-span-2 pb-2 pl-3 border-b border-gray-100">
                            <p>{{ item?.contacts }}</p>
                        </div>
                        <div class="col-span-1 pb-2 border-b border-gray-100">
                            <p class="font-medium">Lives w/ Child:</p>
                        </div>
                        <div class="col-span-2 pb-2 pl-3 border-b border-gray-100">
                            <p>{{ item?.lives_w_child }}</p>
                        </div>
                        <div class="col-span-1 pb-2 border-b border-gray-100">
                            <p class="font-medium">Case Number:</p>
                        </div>
                        <div class="col-span-2 pb-2 pl-3 border-b border-gray-100">
                            <p>{{ item?.case_number }}</p>
                        </div>
                        <div class="col-span-1 pb-2 border-b border-gray-100">
                            <p class="font-medium">Primary Diagnosis:</p>
                        </div>
                        <div class="col-span-2 pb-2 pl-3 border-b border-gray-100">
                            <p>{{ item?.primary_diagnosis }}</p>
                        </div>
                        <div class="col-span-1 pb-2 border-b border-gray-100">
                            <p class="font-medium">Informant relation to child:</p>
                        </div>
                        <div class="col-span-2 pb-2 pl-3 border-b border-gray-100">
                            <p>{{ item?.informant_relation_child }}</p>
                        </div>
                        <div class="col-span-1">
                            <p class="font-medium">Other Relationship:</p>
                        </div>
                        <div class="col-span-2">
                            <p>{{ item?.other_relationship }}</p>
                        </div>
                    </div>
                    <div class="grid grid-cols-3 col-span-3 gap-3">
                        <div class="col-span-1 pb-2 border-b border-gray-100">
                            <p class="font-medium">Services sought:</p>
                        </div>
                        <div class="col-span-2 pb-2 pl-3 border-b border-gray-100">
                            <p>{{ item?.services_sought }}</p>
                        </div>
                        <div class="col-span-1 pb-2 border-b border-gray-100">
                            <p class="font-medium">Social worker:</p>
                        </div>
                        <div class="col-span-2 pb-2 pl-3 border-b border-gray-100">
                            <p>{{ item?.social_worker }}</p>
                        </div>
                        <div class="col-span-1 pb-2 border-b border-gray-100">
                            <p class="font-medium">External referral from:</p>
                        </div>
                        <div class="col-span-2 pb-2 pl-3 border-b border-gray-100">
                            <p>{{ item?.external_referral }}</p>
                        </div>
                        <div class="col-span-1 pb-2 border-b border-gray-100">
                            <p class="font-medium">Intra-PGH referral from:</p>
                        </div>
                        <div class="col-span-2 pb-2 pl-3 border-b border-gray-100">
                            <p>{{ item?.intra_referral }}</p>
                        </div>
                        <div class="col-span-1 pb-2 border-b border-gray-100">
                            <p class="font-medium">If inpatient, which ward referred:</p>
                        </div>
                        <div class="col-span-2 pb-2 pl-3 border-b border-gray-100">
                            <p>{{ item?.inpatient_referred }}</p>
                        </div>
                        <div class="col-span-1 pb-2 border-b border-gray-100">
                            <p class="font-medium">Legal Status:</p>
                        </div>
                        <div class="col-span-2 pb-2 pl-3 border-b border-gray-100">
                            <p>{{ item?.legal_status }}</p>
                        </div>
                        <div class="col-span-1 pb-2 border-b border-gray-100">
                            <p class="font-medium">Housing Classification:</p>
                        </div>
                        <div class="col-span-2 pb-2 pl-3 border-b border-gray-100">
                            <p>{{ item?.housing_classification }}</p>
                        </div>
                        <div class="col-span-1 pb-2 border-b border-gray-100">
                            <p class="font-medium">Socio Eco Status:</p>
                        </div>
                        <div class="col-span-2 pb-2 pl-3 border-b border-gray-100">
                            <p>{{ item?.socio_economic_status }}</p>
                        </div>
                        <div class="col-span-1 pb-2 border-b border-gray-100">
                            <p class="font-medium">No. of Children in Family:</p>
                        </div>
                        <div class="col-span-2 pb-2 pl-3 border-b border-gray-100">
                            <p>{{ item?.no_children_in_family }}</p>
                        </div>
                        <div class="col-span-1 pb-2 border-b border-gray-100">
                            <p class="font-medium">No. of Household Members:</p>
                        </div>
                        <div class="col-span-2 pb-2 pl-3 border-b border-gray-100">
                            <p>{{ item?.no_household_members }}</p>
                        </div>
                        <div class="col-span-1">
                            <p class="font-medium">No. of Families in Household:</p>
                        </div>
                        <div class="col-span-2">
                            <p>{{ item?.no_family_in_household }}</p>
                        </div>
                    </div>
                </div>

                <!-- Address and Present Location -->
                <div class="mt-12 border-t border-gray-200"></div>
                <div class="grid items-start grid-cols-6 gap-6">
                    <div class="col-span-6"></div>
                    <p class="col-span-6 text-sm font-medium text-neutral-600"> Address and Present Location </p>
                    <div class="relative w-full col-span-3 p-4 pr-8 border border-gray-200 rounded">
                        <table class="w-full">
                            <tbody >
                                <tr class="border-b border-gray-100 border-solid">
                                    <td class="w-[200px] py-2">
                                        <p class="font-medium">Address Type:</p>
                                    </td>
                                    <td class="py-2">
                                        <p class="font-normal">{{ item?.address?.address_type }}</p>
                                    </td>
                                </tr>
                                <tr class="border-b border-gray-100 border-solid">
                                    <td class="w-[200px] py-2">
                                        <p class="font-medium">Address:</p>
                                    </td>
                                    <td class="py-2">
                                        <p class="font-normal">{{ item?.address?.current }}</p> 
                                    </td>
                                </tr>
                                <tr class="border-b border-gray-100 border-solid">
                                    <td class="w-[200px] py-2">
                                        <p class="font-medium">Barangay:</p>
                                    </td>
                                    <td class="py-2">
                                        <p class="font-normal">{{ item?.address?.cur_barangay }}</p> 
                                    </td>
                                </tr>
                                <tr class="border-b border-gray-100 border-solid">
                                    <td class="w-[200px] py-2">
                                        <p class="font-medium">City/Municipality/District:</p>
                                    </td>
                                    <td class="py-2">
                                        <p class="font-normal">{{ item?.address?.cur_city }}</p> 
                                    </td>
                                </tr>
                                <tr class="border-b border-gray-100 border-solid">
                                    <td class="w-[200px] py-2">
                                        <p class="font-medium">Province:</p>
                                    </td>
                                    <td class="py-2">
                                        <p class="font-normal">{{ item?.address?.cur_province }}</p> 
                                    </td>
                                </tr>
                                <tr class="border-b border-gray-100 border-solid">
                                    <td class="w-[200px] py-2">
                                        <p class="font-medium">Region:</p>
                                    </td>
                                    <td class="py-2">
                                        <p class="font-normal">{{ item?.address?.cur_region }}</p> 
                                    </td>
                                </tr>
                                <tr>
                                    <td class="w-[200px] py-2">
                                        <p class="font-medium">Direction:</p>
                                    </td>
                                    <td class="py-2">
                                        <p class="font-normal">{{ item?.address?.current_directions }}</p> 
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <!-- Persons Related to Case -->
                <div class="mt-12 border-t border-gray-200"></div>
                <div class="grid items-start grid-cols-6 gap-6">
                    <div class="col-span-6"></div>
                    <p class="col-span-6 text-sm font-medium text-neutral-600"> Persons Related to Case </p>
                    <div class="relative w-full col-span-3 p-4 pr-8 border border-gray-200 rounded">
                        <table class="w-full">
                            <tbody v-for="li in item?.familyComposition" :key="li.name">
                                <tr class="border-b border-gray-100 border-solid">
                                    <td class="w-[200px] py-2">
                                        <p class="font-medium">Full Name:</p>
                                    </td>
                                    <td class="py-2">
                                        <p class="font-normal">{{ li?.name }}</p>
                                    </td>
                                </tr>
                                <tr class="border-b border-gray-100 border-solid">
                                    <td class="w-[200px] py-2">
                                        <p class="font-medium">Gender:</p>
                                    </td>
                                    <td class="py-2">
                                        <p class="font-normal">{{ li?.gender }}</p>
                                    </td>
                                </tr>
                                <tr class="border-b border-gray-100 border-solid">
                                    <td class="w-[200px] py-2">
                                        <p class="font-medium">Relationship:</p>
                                    </td>
                                    <td class="py-2">
                                        <p class="font-normal">{{ li?.relationship }}</p>
                                    </td>
                                </tr>
                                <tr class="border-b border-gray-100 border-solid">
                                    <td class="w-[200px] py-2">
                                        <p class="font-medium">Birthdate:</p>
                                    </td>
                                    <td class="py-2">
                                        <p class="font-normal">{{ li.birthdate }}</p>
                                    </td>
                                </tr>
                                <tr class="border-b border-gray-100 border-solid">
                                    <td class="w-[200px] py-2">
                                        <p class="font-medium">Age:</p>
                                    </td>
                                    <td class="py-2">
                                        <p class="font-normal">{{ li?.age }}</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="w-[200px] py-2">
                                        <p class="font-medium">Contact Number:</p>
                                    </td>
                                    <td class="py-2">
                                        <p class="font-normal">{{ li.contact }}</p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <!-- Contact Numbers -->
                <div class="mt-12 border-t border-gray-200"></div>
                <div class="grid items-start grid-cols-6 gap-6">
                    <div class="col-span-6"></div>
                    <p class="col-span-6 text-sm font-medium text-neutral-600"> Contact Numbers </p>
                    <div class="relative w-full col-span-3 p-4 pr-8 border border-gray-200 rounded">
                        <table class="w-full">
                            <div v-if="!item?.contactNumbers">  
                                <tbody>
                                    <tr class="border-b border-gray-100 border-solid">
                                        <td class="w-[200px] py-2">
                                            <p class="font-medium">Label:</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="w-[200px] py-2">
                                            <p class="font-medium">	Contact Numbers:</p>
                                        </td>
                                    </tr>
                                </tbody>
                            </div>
                            <div v-else> 
                                <tbody v-for="li in item?.contactNumbers" :key="li.label">
                                    <tr class="border-b border-gray-100 border-solid">
                                        <td class="w-[200px] py-2">
                                            <p class="font-medium">Label:</p>
                                        </td>
                                        <td class="py-2">
                                            <p class="font-normal">{{ li?.label }}</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="w-[200px] py-2">
                                            <p class="font-medium">	Contact Numbers:</p>
                                        </td>
                                        <td class="py-2">
                                            <p class="font-normal">{{ li?.number }}</p>
                                        </td>
                                    </tr>
                                </tbody>
                            </div>
                        </table> 
                    </div>
                </div>

                <!-- Email Addresses -->
                <div class="mt-12 border-t border-gray-200"></div>
                <div class="grid items-start grid-cols-6 gap-6">
                    <div class="col-span-6"></div>
                    <p class="col-span-6 text-sm font-medium text-neutral-600"> Email Addresses </p>
                    <div class="relative w-full col-span-3 p-4 pr-8 border border-gray-200 rounded">
                        <table class="w-full">
                            <div v-if="!item?.emailAdresses">
                                <tbody>
                                    <tr class="border-b border-gray-100 border-solid">
                                        <td class="w-[200px] py-2">
                                            <p class="font-medium">Label:</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="w-[200px] py-2">
                                            <p class="font-medium">	Email Address:</p>
                                        </td>
                                    </tr>
                                </tbody>
                            </div>
                            <div v-else>
                                <tbody v-for="li in item?.emailAdresses" :key="li.label">
                                    <tr class="border-b border-gray-100 border-solid">
                                        <td class="w-[200px] py-2">
                                            <p class="font-medium">Label:</p>
                                        </td>
                                        <td class="py-2">
                                            <p class="font-normal">{{ li?.label }}</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="w-[200px] py-2">
                                            <p class="font-medium">	Email Address:</p>
                                        </td>
                                        <td class="py-2">
                                            <p class="font-normal">{{ li?.email }}</p>
                                        </td>
                                    </tr>
                                </tbody>
                            </div>
                        </table>
                    </div>
                </div>


                <!-- Incest Cases -->
                <div class="mt-12 border-t border-gray-200"></div>
                <div class="grid items-start grid-cols-6 gap-6">
                    <div class="col-span-6"></div>
                    <p class="col-span-6 text-sm font-medium text-neutral-600"> Incest Cases </p>
                    <div class="col-span-6">
                        <table class="w-full">
                            <tbody>
                                <tr class="border-b border-gray-100 border-solid">
                                    <td class="w-[200px] py-2">
                                        <p class="font-medium">Regular sleeping arrangement:</p>
                                    </td>
                                    <td class="py-2">
                                        <p class="font-normal">{{ item?.incest?.sleeping_arrangement }}</p>
                                    </td>
                                </tr>
                                <tr class="align-top border-b border-gray-100 border-solid">
                                    <td class="w-[200px] py-2">
                                        <p class="font-medium">Specify:</p>
                                    </td>
                                    <td class="py-2">
                                        <p class="font-normal">{{ item?.incest?.others }}</p>
                                    </td>
                                </tr>
                                <tr class="align-top border-b border-gray-100 border-solid">
                                    <td class="w-[200px] py-2">
                                        <p class="font-medium">	Same bed / mat with:</p>
                                    </td>
                                    <td class="py-2 frm-description">
                                        <ul>
                                            <li class="font-normal">{{ item?.incest?.same_bed_with}}</li>
                                        </ul>
                                    </td>
                                </tr>
                                <tr class="align-top border-b border-gray-100 border-solid">
                                    <td class="w-[200px] py-2">
                                        <p class="font-medium">	Same room with:</p>
                                    </td>
                                    <td class="py-2 frm-description">
                                        <ul>
                                            <li class="font-normal">{{ item?.incest?.same_room_with}}</li>
                                        </ul>
                                    </td>
                                </tr>
                                <tr class="align-top border-b border-gray-100 border-solid">
                                    <td class="w-[200px] py-2">
                                        <p class="font-medium">	Living arrangement at time of abuse:</p>
                                    </td>
                                    <td class="py-2 frm-description">
                                        <ul v-for="li in item?.incest?.abused.form" :key="li" >
                                            <li class="font-normal">{{ li }}</li>
                                        </ul>
                                    </td>
                                </tr>
                                <tr class="align-top">
                                    <td class="w-[200px] py-2">
                                        <p class="font-medium">	Living arrangement at present:</p>
                                    </td>
                                    <td class="py-2 frm-description">
                                        <ul v-for="li in item?.incest?.present.form" :key="li" >
                                            <li class="font-normal">{{ li }}</li>
                                        </ul>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <loading-modal
            :show="showSync"
            noBtn
            title="Please wait.."
            content="System is currently loading the report based on the selected filter."
        />
    </user-layout>
</template>
<script setup lang="ts">
import { onMounted, ref, computed, watch } from 'vue';
import UserLayout from "@/layouts/UserLayout.vue";
import SectionTitle from "@/components/partials/SectionTitle.vue";
import ButtonComponent from "@/components/buttons/ButtonComponent.vue";
import ExportButton from '@/components/buttons/ExportButton.vue';
import MultiSelect from "@/components/inputs/MultiSelect.vue";
import TextInput from "@/components/inputs/TextInput.vue";
import LoadingModal from '@/components/modals/LoadingModal.vue';
import { useNetworkStore } from "@/store/network";
import ReportService from "@/classes/Reports/ReportService";
import IntakeFormService from "@/classes/Cases/IntakeForms/IntakeFormService";

const networkStore = useNetworkStore();
const isNetworkAvailable = computed(() => networkStore.isNetworkAvailable);
const cases = ref<any>([]);
const item = ref();
const showSync = ref<boolean>(false);


const filters = ref({
    case_id: null,
})

const first_name = computed(() => item?.value?.first_name ?? '')
const last_name = computed(() => item?.value?.last_name ?? '')

const fetch = () => {
    showSync.value = true

    ReportService.index('intake-form', filters.value)
        .then(({ data }) => {
            console.log(data)
            cases.value = data?.cases
            item.value = data?.item;

            showSync.value = false
        })
        .catch(errors => {
            showSync.value = false
            console.log(errors)
        });
}

const generatePDFAccess = ():void => {
	IntakeFormService
		.generatePDFAccess(
			item.value.patient_id,
			item.value.case_id,
            isNetworkAvailable.value
		)
		.then(({data}) => {
			if(data.token){
				downloadPDF(data.token)
			}
		}).catch((errors) => {
			console.log(errors);
		})

}
const downloadPDF = (token:string):void => {
	IntakeFormService
		.downloadPDF(
			item.value.patient_id,
			item.value.case_id,
			token,
			isNetworkAvailable.value
		)
		.then(({data}) => {
			console.log(data);
		}).catch((errors) => {
			console.log(errors);
		})

}

watch(
() => filters.value.case_id,
	() => {
		fetch();
	}
);

onMounted(() => {
    fetch()
})

</script>
