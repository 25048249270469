<template>
	<a
		@click="router.replace(routeLink)"
		class="flex flex-col lg:p-6 p-4 space-y-2 border border-gray-200 cursor-pointer rounded-2xl">
		<div class="flex items-center justify-between w-full">
			<p class="p-2 font-bold text-xs rounded-[6px] bg-primary-400 text-primary-600 leading-[18px]">
				{{ caseNumber || 'Offline(No Case Number)' }}
			</p>
			<date-detail :date="date" />
		</div>
		<div>
			<p class="text-neutral-500 text-[0.625rem]">Primary Diagnosis</p>
			<p class="mt-1 text-sm font-bold">{{ primaryDiagnosis }}</p>
		</div>
		<div>
			<p class="text-neutral-500 text-[0.625rem]">Comorbid</p>
			<div class="flex flex-wrap items-center">
				<template v-for="list in comorbidList" :key="list">
					<span v-if="list" class="px-2 py-1 mt-1 mr-1 text-xs italic bg-neutral-200 text-neutral-600 rounded-2xl">
						{{ list }}
					</span>
				</template>
			</div>
		</div>
		<div>
			<p class="text-neutral-500 text-[0.625rem]">Perpetrator</p>
			<div class="flex flex-wrap items-center">
				<span
					v-for="list in perpetratorList"
					:key="list"
					class="px-2 py-1 mt-1 mr-1 text-xs italic bg-neutral-200 text-neutral-600 rounded-2xl">
					{{ list }}
				</span>
			</div>
		</div>
	</a>
</template>
<script setup lang="ts">
import { PropType } from "vue";
import DateDetail from "@/components/partials/DateDetail.vue";
import { useRouter } from "vue-router";

const router = useRouter();

defineProps({
	routeLink: {
		type: String,
		default: "#",
	},
	caseNumber: {
		type: String,
		default: null,
	},
	primaryDiagnosis: {
		type: String,
		default: null,
	},
	comorbidList: {
		type: Array as PropType<string[]>,
		default: () => [],
	},
	perpetratorList: {
		type: Array as PropType<string[]>,
		default: () => [],
	},
	date: {
		type: String,
		default: null,
	},
});
</script>
