<template>
    <user-layout>
        <div class="w-full mx-auto py-6">
            <div class="flex items-center justify-between">
                <section-title 
                    title="Settings"
                    description="View the relevant data and other details"
                />
            </div>
            <div class="mt-6">
                <div class="w-full border rounded-lg border-neutral-100">
                    <div class="relative overflow-auto">
						<div class="absolute left-0 top-0 h-full whitespace-nowrap position w-[181px] shadow-[2px_0_16px_0px_rgb(0,0,0,0.08)]">
                            <div class="px-6 py-[26px] bg-primary-100 text-sm font-semibold relative">
                                Settings
                            </div>
                            <side-tab notificationsSelected />
                        </div>
                        <div class="pl-[181px] min-h-[500px]">
                            <div class="flex items-center justify-between p-6 border-b border-neutral-100">
                                <div class="flex items-center space-x-4">
                                    <tab-component
                                        :tabs="tabs"
                                    />
                                </div>
                            </div>
                            <div v-if="route.query.tab != 'activity_logs'">
                                <data-table 
                                    :headers="headers" 
                                    :no-action="false"
                                    :count="sampleData.length"
                                    tableClass="header-frm-table"
                                >
                                    <template v-slot:body>
                                        <template 
                                            v-for="item in sampleData" 
                                            :key="item"
                                        >
                                            <tr>
                                                <td class="px-6 py-[18px] whitespace-nowrap text-sm text-gray-900">
                                                    {{ item.module }}
                                                </td>
                                                <td class="px-6 py-[18px] whitespace-nowrap text-sm text-gray-900">
                                                    {{ item.subject }}
                                                </td>
                                                <td class="px-6 py-6 max-w-[400px] text-ellipsis overflow-hidden line-clamp-1 h-5 text-sm text-gray-900">
                                                    {{ item.content }}
                                                </td>
                                                <td class="px-6 py-[18px] whitespace-nowrap text-sm text-gray-900">
                                                    {{ item.dateUpdated }}
                                                </td>
                                                <td 
                                                    class="
                                                        px-6
                                                        py-[18px]
                                                        whitespace-nowrap
                                                        text-sm 
                                                        text-gray-900
                                                    "
                                                >
                                                    <div 
                                                        class="flex items-center justify-center mx-auto"
                                                    >
                                                        <view-button
                                                            routeLink="/settings/notifications/view"
                                                        ></view-button>
                                                    </div>
                                                </td>
                                            </tr>
                                        </template>
                                    </template>
                                </data-table>
                            </div>
                        </div>
                    </div>
                    <div class="px-6 py-7 border-t w-full">
                        <minify-pagination num-rows="All"></minify-pagination>
                    </div>
                </div>
    
            </div>
        </div>
    </user-layout>
</template>
<script setup lang="ts">
import { ref } from 'vue';
import { useRoute } from 'vue-router';
import UserLayout from '@/layouts/UserLayout.vue';
import SectionTitle from '@/components/partials/SectionTitle.vue';
import ViewButton from '@/components/buttons/ViewButton.vue';
import DataTable from '../Components/DataTable.vue';
import TabComponent from '@/components/partials/TabComponent.vue';
import SideTab from '../Components/SideTab.vue';
import MinifyPagination from '@/components/partials/MinifyPagination.vue';

const route = useRoute();
const active_count = ref(15);

const tabs: { name: string, count: number, href: string, allowed: boolean }[] = [
    {
        name: 'All',
        count: 0,
        href: "/settings/notifications/index",
        allowed: true
    },
    {
        name: 'Activity logs',
        count: active_count.value,
        href: "/settings/notifications/index?tab=activity_logs",
        allowed: true
    }
];

const headers: { text: string, haveSort?: boolean }[] = [
    { text: 'Module' },
    { text: 'Subject' },
    { text: 'Content' },
    { text: 'Date Updated' },
];

interface sampleDataInfo {
    module: string,
    subject: string,
    content: string,
    dateUpdated: string,
}

const sampleData = ref<sampleDataInfo[]> ([
    { 
        module: 'Patient',
        subject: 'New Patient Case: Action Required',
        content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Et sequi possimus earum inventore quo.',
        dateUpdated: 'Aug 26, 2022 (00:00:00)'
    },
    { 
        module: 'Patient',
        subject: 'Updated Patient Case',
        content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Et sequi possimus earum inventore quo.',
        dateUpdated: 'Aug 26, 2022 (00:00:00)'
    },
    { 
        module: 'Intervention',
        subject: 'New Patient Intervention',
        content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Et sequi possimus earum inventore quo.',
        dateUpdated: 'Aug 26, 2022 (00:00:00)'
    },
    { 
        module: 'Intervention',
        subject: 'Scheduled Intervention: Action Required',
        content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Et sequi possimus earum inventore quo.',
        dateUpdated: 'Aug 26, 2022 (00:00:00)'
    },
    { 
        module: 'Intervention',
        subject: 'Past Due Intervention: Action Required',
        content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Et sequi possimus earum inventore quo.',
        dateUpdated: 'Aug 26, 2022 (00:00:00)'
    },
]);
</script>