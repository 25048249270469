<template>
	<div
		v-if="!editMode"
		class="col-span-1 rounded bg-gray-50 hover:bg-primary-300 flex flex-col items-center justify-center text-center group p-6 h-[100px] cursor-pointer"
		@click="openModal()"
	>
		<p class="mb-2 text-sm text-neutral-900 group-hover:text-primary-600">{{ item.label }}</p>
		<img :src="getFieldIcon(item.value)" class="object-contain h-6 opacity-50 grayscale group-hover:grayscale-0 group-hover:opacity-100" alt="icon">
	</div>

	<TransitionRoot as="template" :show="show">
		<div class="absolute right-0 inset-y-0 w-full overflow-hidden h-[calc(100vh-148px)] z-[9]" @close="close()">
			<TransitionChild
				as="template"
				enter="transform transition ease-in-out duration-300"
				enter-from="translate-x-full"
				enter-to="translate-x-0"
				leave="transform transition ease-in-out duration-300"
				leave-from="translate-x-0"
				leave-to="translate-x-full"
			>
				<div class="flex flex-col h-full overflow-y-scroll bg-white rounded-l-lg shadow-xl">
					<div class="relative flex flex-col h-full">
						<div class="relative flex items-center px-6 pt-6 pb-2 space-x-2 shrink-0">
							<ArrowLeftIcon
								class="w-6 h-6 transition cursor-pointer text-neutral-500 hover:text-primary-600" 
								@click="close()"
							/>
							<p class="text-sm text-neutral-900">{{item.label}}</p>
							<div class="group">
								<InformationCircleIcon class="w-5 h-5 cursor-pointer text-primary-600" />
								<div class="absolute top-[50px] left-[5%] w-[90%] z-10 rounded bg-gray-500 p-3 text-white text-xs group-hover:block hidden">
									<div v-if="item.label == 'Input Field'">
										<p>This type allows a response of one or a few words.</p>
										<br>
										<p><b>Tip:</b> If you wish to add another input field beside this one, simply set the width to half.</p>
									</div>
									<div v-if="item.label == 'Text Area'">
										<p>This type allows multiple lines of text as a response.</p>
										<br>
										<p><b>Tip:</b> Use this type for fields that requires long/paragraph response.</p>
									</div>
									<div v-if="item.label == 'Dropdown'">
										<p>This type allows users to select from a list of predefined options to ensure standardized responses.</p>
										<br>
										<p><b>Tip:</b> Use this type for fields with fixed set of choices to reduce the the chances of errors. <i>(This type is allowed to have sub-forms.)</i></p>
									</div>
									<div v-if="item.label == 'Multi Select'">
										<p>This type allows users to select multiple answer from a list of predefined options.</p>
										<br>
										<p><b>Tip:</b> Use this type to limit/control all the users response.</p>
									</div>
									<div v-if="item.label == 'Radio Button'">
										<p>This type allows users to choose one from a predefined options.</p>
										<br>
										<p><b>Tip:</b> Use this type to limit/control all the users response. <i>(This type is allowed to have sub-forms.)</i></p>
									</div>
									<div v-if="item.label == 'Toggle Button'">
										<p>This type allows users to answer by switching between two states.</p>
										<br>
										<p><b>Tip:</b> Use this type to collect yes/no or true/false responses. (This type is allowed to have sub-forms.)</p>
									</div>
									<div v-if="item.label == 'Regular Checkbox' || item.label == 'Checkbox with multiple options'">
										<p>This type allows users to choose one from a predefined options.</p>
										<br>
										<p><b>Tip:</b> Use this type to limit/control all the users response. <i>(This type is allowed to have sub-forms.)</i></p>
									</div>
									<div v-if="item.label == 'Link'">
										<p>This type allows users to input external URLs or hyperlinks.</p>
										<br>
										<p><b>Tip:</b> Use this type to include clickable links from external websites, files, images, etc.</p>
									</div>
									<div v-if="item.label == 'Date and Time' || item.label == 'Date' || item.label == 'Time'">
										<p>This type allows users to specify the date & time by selecting from a calendar.</p>
										<br>
										<p><b>Tip:</b> Use this type to ensure accurate data entry for event timestamps.</p>
									</div>
									<div v-else>
										<p>Relevant data and other details</p>
									</div>
								</div>
							</div>
						</div>

						<div class="flex flex-col flex-1 px-6 pt-5 pb-6">
							<div class="flex flex-col flex-1 h-full space-y-5">
								<div>
									<text-input
										type="text"
										label="Field Label"
										placeholder="Field Label"
										id="field_label"
										name="field_label"
										v-model="formField.label"
										:error="errors?.label"
									/>
								</div>
								
								<div v-if="inDateTimeOptions.includes(fieldType)">
									<label class="block mb-3 text-xs font-semibold text-neutral-600">Choose field date format </label>
									<div class="flex flex-col space-y-2">
										<radio-group
											id="formField.input_type"
											v-model="formField.input_type"
											:options="dateTimeOptions"
											name="input_type"
										/>
									</div>
								</div>

								<div class="flex space-x-2" v-if="!hasOneOption.includes(formField.input_type)">
									<toggle-switch
										:value="formField.is_required"
										noPadding
										@change="formField.is_required = !formField.is_required"
									/>
									<p class="pt-1 text-xs font-semibold text-neutral-600">Is field required?</p>
								</div>
								
								<div v-if="!fixedWidth.includes(formField.input_type)">
									<label class="block mb-3 text-xs font-semibold text-neutral-600">Choose field width</label>
									<div class="flex flex-col space-y-2">
										<radio-group
											id="formField.is_half_width"
											v-model="formField.is_half_width"
											:options="field_width_options"
											name="field_width"
										/>
									</div>
								</div>
								<div v-if="!hasExitingOptions.includes(fieldType) && item?.has_options" class="mt-3 ">
									<label class="block mb-3 text-xs font-semibold text-neutral-600">Field options</label>
									<div class="overflow-x-hidden overflow-y-scroll h-96">
										<table class="min-w-full border-none divide-y divide-gray-200">
											<thead class="border-b border-gray-100 bg-gray-50">
												<tr>
													<th class="px-2 py-3 text-xs font-semibold text-center text-gray-500 whitespace-nowrap">
														<span>Order</span>
													</th>
													<th class="px-2 py-3 text-xs font-semibold text-left text-gray-500 whitespace-nowrap">
														<span>Option Name</span>
													</th>
													<th class="px-2 py-3 text-xs font-semibold text-left text-gray-500 whitespace-nowrap"></th>
												</tr>
											</thead>
											<tbody>
												<template v-for="(option, index) in formField.options" :key="index">
													<tr class="relative align-top">
														<td class="px-1 py-2">
															<text-input
																type="number"
																:id="'sort_' + index"
																:name="'sort_' + index"
																customClass="intervention-sortorder border text-center w-9 h-9 rounded text-xs text-neutral-600 border-neutral-100 bg-transparent focus:border-neutral-900 focus:outline-none focus:ring-0"
																v-model="option.order"
																:class="errors[`options.${index}.order`] ? 'intervention-sortorder-parent' : ''"
																:error="errors[`options.${index}.order`]"
															/>
														</td>
														<td class="w-full px-1 py-2">
															<text-input
																:id="'input_' + index"
																:name="'input_' + index"
																placeholder="Type options name"
																customClass="h-9 !pr-2 !pl-2 text-xs"
																v-model="option.label"
																:disabled="hasOneOption.includes(fieldType)"
																:error="errors[`options.${index}.label`]"
																/>
														</td>
														<td class="flex px-1 py-4 space-x-1">
															<button
																v-if="option.form"
																class="flex items-center text-sm transition text-secondary-500 hover:text-secondary-700 active:text-secondary-300"
																@click="viewSubForm(option.form)"
															>
																<EyeIcon class="w-5 h-5" aria-hidden="true" />
															</button>
															<div v-else class="w-5 h-5"></div>
	
															<button
																v-if="(formField.options?.length != 1 && !hasOneOption.includes(props.fieldType)) || option.form_tag || option.report_code"
																class="flex items-center text-sm text-red-500 transition hover:text-red-700 active:text-red-300"
																@click="deleteOptions(index)"
															>
																<TrashIcon class="w-5 h-5" aria-hidden="true" />
															</button>
														</td>
													</tr>
												</template>
											</tbody>
										</table>
									</div>
									<div v-if="!hasOneOption.includes(fieldType)">
										<div class="flex items-center justify-end mt-3 text-sm" >
											<p 
												class="flex italic font-medium transition cursor-pointer text-primary-500 hover:text-primary-700 active:text-primary-300"
												@click="addOptions"
											>
												<PlusIcon class="-ml-0.5 mr-2 h-5 w-5" aria-hidden="true" /> Add Another Option
											</p>
										</div>
									</div>
								</div>
								
								<div v-else-if="hasExitingOptions.includes(fieldType)" class="mt-3">
									<label class="block mb-3 text-xs font-semibold text-neutral-600">Select Exiting Dropdown</label>
		
									<div class="grid grid-cols-6 space-x-1 ">
										<div class="col-span-6 pt-1">
											<multi-select
												:options="existingDropdowns"
												label="Module"
												id="module"
												name="module"
												placeholder="Select.."
												searchable
												v-model="formField.option_class"
												:error="errors?.option_class"
												@update:modelValue="setExistingDropdown"
											/>
										</div>
										<div 
											v-if="hasChildOption"
											class="col-span-6 pt-1" 
										>
											<p class="pt-1 text-xs font-semibold text-neutral-600">Enable Partner Dropdown</p>
											<toggle-switch
												:value="enableChildOption"
												noPadding
												@change="enableChildOption = !enableChildOption"
											/>
										</div>
									</div>
		
									<div 
										v-if="enableChildOption"
										class="flex flex-col pt-2 space-y-5"
									>
										<div>
											<text-input
												type="text"
												label="Field Label"
												placeholder="Field Label"
												id="field_label"
												name="field_label"
												v-model="formField.option_child.label"
												:error="errors[`option_child.label`]"
											/>
										</div>
		
										<multi-select
											:options="childOptions"
											label="Partner Module"
											id="module"
											name="module"
											placeholder="Select.."
											searchable
											v-model="formField.option_child.option_class"
											@update:modelValue="setChildDropDown"
											:error="errors[`option_child.option_class`]"
										/>
									
										<div class="flex space-x-2">
											<toggle-switch
												:value="formField.option_child.is_required"
												noPadding
												@change="formField.option_child.is_required = !formField.option_child.is_required"
											/>
											<p class="pt-1 text-xs font-semibold text-neutral-600">Is field required?</p>
										</div>
		
										<div
											v-if="!fixedWidth.includes(formField.option_child.input_type)"
										>
											<label class="block mb-3 text-xs font-semibold text-neutral-600">Choose field width</label>
											<div class="flex flex-col space-y-2">
												<radio-group
													id="formField.option_child.is_half_width"
													v-model="formField.option_child.is_half_width"
													:options="field_width_options"
													name="field_width_child"
												/>
											</div>
										</div>
		
									</div>
		
								</div>
							</div>
							<div class="flex justify-between mt-6 space-x-2 shrink-0">
								<div>
									
									<button-component
										v-if="formField?.has_sub_form && !hasExitingOptions.includes(fieldType) && editMode"
										@click="showSubFromModal = true"
									>
										<PlusIcon class="w-4 h-4 text-primary-600 stroke-[3px] -ml-2 mr-1 icon" />
										<span>Add Sub-Form</span>
									</button-component>
								</div>
								<div>
									<button-component 
										@click="add()"
									>
										<span>{{ editMode ? 'Update' : 'Add' }} Field</span>
									</button-component>
								</div>
							</div>
						</div>
					</div>
				</div>
			</TransitionChild>
		</div>
	</TransitionRoot>

	<content-modal
		:show="showSubFromModal" 
		title="Add Conditional Form Trigger"
	>
		<div class="flex space-x-6">
			<div class="w-full">
				<multi-select
					:options="subFormOptions"
					label="Option Value"
					id="option_value"
					name="option_value"
					placeholder="Select.."
					searchable
					v-model="selectedOption"
				/>
			</div>
		</div>

		<template #button>
			<button-component  
				btnWidth="quinary"
				@click="showSubFromModal = false"
			>
				Cancel
			</button-component >
			<button-component 
				customClass="primary" 
				@click="addSubForm()"
			>
				Submit
			</button-component>
		</template>
	</content-modal>

</template>
<script setup lang="ts">
import { ref, type PropType, onMounted, computed, watch } from "vue";

import { useVuelidate } from '@vuelidate/core'
import { required, requiredIf, minLength, helpers, integer, maxLength} from '@vuelidate/validators'

import ButtonComponent from "@/components/buttons/ButtonComponent.vue";

import TextInput from "@/components/inputs/TextInput.vue";
import ToggleSwitch from "@/components/inputs/ToggleSwitch.vue";
import RadioGroup from "@/components/inputs/RadioGroup.vue";
import MultiSelect from "@/components/inputs/MultiSelect.vue";
import ContentModal from "@//views/settings/InterventionTypes/Components/ContentModal.vue";

import { InformationCircleIcon, ArrowLeftIcon, PlusIcon, TrashIcon, EyeIcon } from "@heroicons/vue/24/outline";

import {
	TransitionChild,
	TransitionRoot,
} from "@headlessui/vue";

import { FormFieldType } from "@/views/enums/FormFieldType";

import { 
    FieldInterface,
    FormInterface,
    OptionInterface,
} from "@/interfaces/pages/InterventionForm/InterventionFormInterface";

const props = defineProps({
	form: {
		type: Object as PropType<FormInterface>,
		required: true,
	},
	formField: {
		type: Object as PropType<FieldInterface>,
		required: true,
	},
	item: {
		type: Object as PropType<FieldInterface>,
		required: true,
	},
	existingDropdowns: {
        type: Object,
        required: true,
    },
	fieldType: {
		type: Number,
		required: true,
	},
	show: {
		type: Boolean,
		default: false,
	},
	editMode: {
		type: Boolean,
		default: false,
	},
});

const emit = defineEmits([ 'open', 'close', 'load:form']);

const show = ref<boolean>(props.show);
const showSubFromModal = ref<boolean>(false);

// const target = ref<string>('manual');
const hasChildOption = ref<boolean>(props.formField?.option_child ?? false);
const enableChildOption = ref<boolean>(props.formField?.option_child ?? false);
const childOptions = ref<string[]>([]);

const form = ref<FormInterface>(props.form);
const formField = ref<FieldInterface>({...props.formField})
const originalFormField = ref<FieldInterface>(props.formField)
const selectedOption = ref<any>();

const field_width_options = [
    { id: false, label: 'Whole', value: false, radioSelected: null},
    { id: true, label: 'Half', value: true, radioSelected: null},
];

const dateTimeOptions = [
    { id: FormFieldType.DATE_TIME, value: FormFieldType.DATE_TIME, label: 'Date and Time',},
    { id: FormFieldType.DATE, value: FormFieldType.DATE, label: 'Date Only',},
    { id: FormFieldType.TIME, value: FormFieldType.TIME,  label: 'Time Only',},
];

const fixedWidth = [
	FormFieldType.TEXT,
	FormFieldType.LINK,
	FormFieldType.CHECKBOX_OPTION,
	FormFieldType.CHECKBOX_SINGLE
];

const hasOneOption = [
	FormFieldType.CHECKBOX_SINGLE,
	FormFieldType.TOGGLE,
];

const hasExitingOptions = [
	FormFieldType.DROPDOWN_EXISTING,
];

const inDateTimeOptions = [
	FormFieldType.DATE_TIME,
	FormFieldType.DATE,
	FormFieldType.TIME,
];

const distinct = (value:any, vm:any):boolean => {
	const array = formField.value.options.map((item) => parseInt(item.order));

	const duplicates = array.filter((item, index) => array.indexOf(item) !== index);

	return !duplicates.includes(parseInt(value));
}

//Validations
const rules = computed(() => {

	const rules = {
		is_half_width : {
			required,
		},
		is_required : {
			required,
		},
		label : {
			required,
		}
	}; 

	if(props.formField?.has_options && !hasExitingOptions.includes(props.fieldType)) {
		Object.assign(
			rules,
			{ 
				options:{
					requiredIfOptions: required,
					minLength: minLength(1),

					$each: helpers.forEach({
						label: { required },
						order: { 
							required, 
							integer,  
							maxLength: maxLength(formField.value.options?.length ?? 1),
							distinct: helpers.withMessage('Duplicate order value', distinct) 
						},
					})
				}
			}
		)
	}

	if(props.formField?.has_options && hasExitingOptions.includes(props.fieldType)) {
		Object.assign(
			rules,
			{ 
				option_class: { required },
				option_child:{
					label: { requiredIf: requiredIf(enableChildOption.value) },
					input_type: { requiredIf: requiredIf(enableChildOption.value) },
					is_half_width: { requiredIf: requiredIf(enableChildOption.value) },
					is_required: { requiredIf: requiredIf(enableChildOption.value) },
					option_class: { requiredIf: requiredIf(enableChildOption.value) },
					option_trigger_column: { requiredIf: requiredIf(enableChildOption.value) },
				}
			}
		)
	}


	return rules;
});

const vuelidate = useVuelidate(rules, formField);

const errors =  ref<any>([]);

const subFormOptions = computed(():any => {
	const options = [];

	formField.value?.options.forEach(item => {
		if(!item.form){
			options.push({
				id: item.order,
				value: item.order,
				label: item.label,
			})
		}
	});

	return options;
});

const getFieldIcon = (field: number): string => {
    switch (field) {
        case FormFieldType.INPUT:
            return '/assets/icon/intervention/ic-text.svg';
        case FormFieldType.TEXT:
            return'/assets/icon/intervention/ic-textarea.svg';
        case FormFieldType.DROPDOWN:
        case FormFieldType.DROPDOWN_EXISTING:
            return'/assets/icon/intervention/ic-dropdown.svg';
        case FormFieldType.MULTI_SELECT:
            return'/assets/icon/intervention/ic-multiselect.svg';
        case FormFieldType.RADIO:
            return'/assets/icon/intervention/ic-radio-button.svg';
        case FormFieldType.TOGGLE:
            return'/assets/icon/intervention/ic-toggle.svg';
        case FormFieldType.CHECKBOX_SINGLE:
        case FormFieldType.CHECKBOX_OPTION:
            return'/assets/icon/intervention/ic-checkbox.svg';
        case FormFieldType.LINK:
            return'/assets/icon/intervention/ic-link.svg';
        case FormFieldType.TIME:
        case FormFieldType.DATE:
        case FormFieldType.DATE_TIME:
            return'/assets/icon/intervention/ic-datepicker.svg';
        default:
            return '';
    }
}

const openModal = ():void => {
	show.value = true;
}

const close = ():void => {
	show.value = false
	emit('close');
}

const addOptions = () => {
	formField.value.options.push({
		label: hasOneOption.includes(props.fieldType) ? "Yes/No" : null,
		input_type: FormFieldType.OPTION,
		order: (formField.value?.options?.length ?? 0) + 1
	});
};

const deleteOptions = (index: number) => {
    formField.value?.options.splice(index, 1);
};

const setExistingDropdown = (value:any) => {

	const index = props.existingDropdowns.map((item:any) => item.id).indexOf(value);
	
	if(props.existingDropdowns[index]?.child != undefined) {
		hasChildOption.value = true;
		childOptions.value = [ props.existingDropdowns[index]?.child ]

		formField.value.option_child = {
			label: null,
			input_type: FormFieldType.DROPDOWN,
			order: 1,
			is_half_width: true,
			is_required: false,
			option_class: null,
			option_trigger_column: null,
		}
	} else {
		hasChildOption.value = false;

		formField.value.option_child = null;
	}
}

const setChildDropDown = (value: any) => {
	const index = childOptions.value.map((item:any) => item.id).indexOf(value);
	const option = childOptions.value[index];

	formField.value.option_child.option_class = option.value;
	formField.value.option_child.option_trigger_column = option.column;
}

const add = ():void => {
	const result = vuelidate.value.$validate();

	result
		.then((res) => {
			if(res) {
				submit()
			} else {
				getErrors(vuelidate.value);
			}
		}).catch((err) => {
			console.log(err);
		})
}

const submit = ():void => {

	if(!formField.value.order){
		if(fixedWidth.includes(formField.value.input_type)){	
			formField.value.is_half_width = false;
		}

		if(hasOneOption.includes(formField.value.input_type)){
			formField.value.is_required = false;
		}

		form.value.fields.push({
			...formField.value,
			input_type: props.fieldType,
			order: form.value.fields.length
		});
	} else {
		const temp: any = form.value.fields.findIndex((field: any) => {
			return field.order == formField.value.order
		})
		form.value.fields[temp] = {...formField.value}
	}


	// const index = form.value.fields.map((item:FieldInterface) => item.order).indexOf(formField.value.order)
	
	// if(fixedWidth.includes(formField.value.input_type)){	
	// 	formField.value.is_half_width = false;
	// } 
	
	// if(hasOneOption.includes(formField.value.input_type)){
	// 	formField.value.is_required = false;
	// }

	// if(index == -1){
	// 	form.value.fields.push({
	// 		...formField.value,
	// 		input_type: props.fieldType,
	// 		order: form.value.fields.length
	// 	});
	// } else {
	// 	form.value.fields[index] = formField.value
	// }

	show.value = false;
	emit('close');	
}

const getErrors = (validationErrors:any):void => {

	errors.value = []
	validationErrors.$errors.forEach((error:any) => {
		console.log(error);
		if(error.$response){
			const property = error.$property;

			error.$response.$errors.forEach((error:any, index:number) => {
			
				Object.values(error).forEach((error:any) => {
					if(error[0]?.$property != undefined){
						errors.value[`${property}.${index}.${error[0].$property}`] = error[0].$message
					}
				});   

			});
		} else {
			errors.value[error.$propertyPath] = error.$message
		}
	});
}

const viewSubForm = (form:FormInterface):void => {
	emit('load:form', form);
}


const addSubForm = ():void => {
	showSubFromModal.value = false
	
	const index = formField.value?.options?.map((item) => item.order).indexOf(selectedOption.value)

	try {
		const option = formField.value?.options[index];
		
		if(option){
			Object.assign(
				formField.value?.options[index], 
				{ 
					form:  {
						label: `${option.label} Form`,
						fields: [],
					} 
				}
			);
			emit('load:form', formField.value?.options[index].form);
		}
	
		
	} catch (error) {
		console.log(error)
	}
}

const init = ():void => {
	if(
		props.item?.has_options && 
		(formField.value.options?.length == 0 || formField.value.options == null) &&
		!hasExitingOptions.includes(props.fieldType) 
	)
	{
		formField.value.options = []
		addOptions()
	}
}

onMounted(() => {
	init()
})

</script>
