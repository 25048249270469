import { EnumsInterface } from "@/interfaces/EnumsInterface";

const name:EnumsInterface[] = [
    {id: 0, value: "1", label: "1"},
    {id: 1, value: "2", label: "2"},
    {id: 2, value: "3", label: "3"},
    {id: 3, value: "4", label: "4"},
    {id: 4, value: "Yes", label: "Yes"},
    {id: 5, value: "NO", label: "NO"},
]

export default name