<template>
	<user-layout
		hasBack
		hasRightCon
		:backLink="`/patient/${route.params.patient}/view`"
		backTitle="Patient and Case Details"
		hasBackClass="flex md:justify-between md:items-center md:flex-row flex-col">
		<template #lowerRight>

			<button-component
				v-if="newRecord && hasPermission('can-update-patient-incident')"
				customClass="primary md:w-auto w-full md:mt-0 mt-6"
				form="form"
				@click="showConfirmation = true">
				<!-- <img src="/assets/icon/ic-save.svg" alt="save_icon" class="object-contain w-5 h-5 mr-1 icon" /> -->
				<save-icon class="object-contain w-5 h-5 mr-1 icon" />
				<span>Save Record</span>
			</button-component>

			<div v-if="!newRecord" class="flex md:items-center md:space-x-3 md:space-y-0 space-y-3 md:w-auto w-full md:flex-row flex-col md:mt-0 mt-6">
				<button-component
					v-if="hasPermission('can-update-patient-incident')"
					@click="newRecord = true"
					customClass="primary md:w-auto w-full"
				>
					<!-- <img
						src="/assets/icon/ic-pencil-underline.svg"
						alt="edit_icon"
						class="object-contain w-4 h-4 mr-1 icon" /> -->
					<pencil-underline-icon class="object-contain w-4 h-4 mr-1 icon" />
					<span>Edit</span>
				</button-component>
				<a v-if="hasPermission('can-view-patient-case-inpdf') && isNetworkAvailable">
					<button-component
						@click="generatePDFAccess('profile')"
						size="large"
						customClass="bg-green-600 hover:bg-green-700 text-white transition border border-green-600 hover:border-green-700 md:w-auto w-full"
					>
						<EyeIcon class="w-5 h-5 mr-2 -ml-1 icon" />
						<span>View Patient Record</span>
					</button-component>

					<p
						v-if="errors?.to"
						class="text-[10px] mt-1 text-red-600"
						id="email-error"
					>
						{{ errors?.to }}
					</p>
				</a>
				<button-component
                    v-if="isNetworkAvailable"
					size="large"
					@click="showContent = true"
					customClass="bg-green-600 hover:bg-green-700 text-white transition border border-green-600 hover:border-green-700 md:w-auto w-full">
					<EyeIcon class="w-5 h-5 mr-2 -ml-1 icon" />
					<span>View SCAR</span>
				</button-component>
			</div>
		</template>
		<div class="mt-6">
			<form-section
				v-if="loaded"
				:newRecord="newRecord"
				:form="form"
				:errors="errors"
				:additional-data="additionalData"
				:item="item">
				<confirmation-modal
					:typeTwo="true"
					:show="showConfirmation"
					@cancel="showConfirmation = false"
					@confirm="save()"
					cancel-text="Cancel"
					action-text="Confirm"
					title="Update Record"
					content="Are you sure you wish to update this record?" />
				<success-modal
					:show="showSuccess"
					@confirm="redirectSuccess()"
					action-text="Close"
					title="Record Updated!"
					:content="successMessage" />
			</form-section>
		</div>
		<template #rightContent>
			<div class="lg:sticky static lg:my-10 top-10 lg:h-[70dvh] lg:pb-10 overflow-auto lg:mt-0 mt-6">
				<side-nav :hasRecord="newRecord" :item="item" :other-details-count="otherDetailsCount"/>
			</div>
		</template>

		<content-modal
			:show="showContent" 
			title="Add Recipient & Attention"
		>
			<div>
				<text-input
					label="To:"
					placeholder="To"
					name="to"
					id="to"
					required
					v-model="formContent.to"
					:error="errors.to"
				/>
			</div>

			<div>
				<text-input
					label="Attn:"
					placeholder="Attn"
					name="attn"
					id="attn"
					required
					v-model="formContent.attn"
					:error="errors.attn"
				/>
			</div>

			<template #button>
				<button-component  
					btnWidth="quinary"
					@click="showContent = false"
				>
					Cancel
				</button-component>
				<a
					v-if="hasPermission('can-view-patient-case-inpdf')"
					@click="generatePDFAccess('scar')"
				>
					<button-component customClass="bg-green-600 hover:bg-green-700 text-white transition border border-green-600 hover:border-green-700">
						<EyeIcon class="w-5 h-5 mr-2 -ml-1 icon" />
						<span>View PDF</span>
					</button-component>
				</a>
			</template>
		</content-modal>

		<page-loader :show="loading" />
	</user-layout>
</template>
<script setup lang="ts">
import { onMounted, ref, computed, watch } from "vue";
import UserLayout from "@/layouts/UserLayout.vue";
import ButtonComponent from "@/components/buttons/ButtonComponent.vue";
import FormSection from "./FormSection.vue";
import SideNav from "./Components/SideNav.vue";
import ConfirmationModal from "@/components/modals/ConfirmationModal.vue";
import SuccessModal from "@/components/modals/SuccessModal.vue";
import { EyeIcon } from "@heroicons/vue/24/solid";
import { useRouter, useRoute } from "vue-router";
import { CreatePatient } from "@/interfaces/PatientInterface";
import CaseService from "@/classes/Cases/CaseService";
import { errorResponse } from "@/helpers/errorMessage";
import CreatePatientForm from "@/classes/Forms/Patients/CreatePatientForm";
import { hasPermission } from "@/classes/AuthService";
import PageLoader from "@/components/loaders/PageLoader.vue";
import FormOptions from "@/classes/Forms/FormOptions";
import { useNetworkStore } from "@/store/network";
import pickBy from "lodash/pickBy";
import DB from "@/classes/Databases/Crud";
import ContentModal from "./otherInformations/intakes/Component/ContentModal.vue";
import TextInput from "@/components/inputs/TextInput.vue";
import PatientService from "@/classes/PatientService";
import { useRecordStatusChecker } from "@/store/recordStatusChecker";
import { onIonViewWillEnter } from "@ionic/vue";
import SqliteRecordChecker from "@/classes/Forms/SqliteRecordChecker";
import SaveIcon from "@/components/icons/saveIcon.vue";
import PencilUnderlineIcon from "@/components/icons/pencilUnderlineIcon.vue";
import OfflinePatientCases from "@/classes/Forms/Patients/OfflinePatientCases";

const networkStore = useNetworkStore();
const isNetworkAvailable = computed(() => networkStore.isNetworkAvailable);

const router = useRouter();
const route = useRoute();
const options = new FormOptions();
const db = new DB("patients");
const dbCase = new DB("cases");
const checkRecord = new SqliteRecordChecker();
// Set to False to enable create other details
const newRecord = ref<boolean>(false);
const showConfirmation = ref<boolean>(false);
const showSuccess = ref<boolean>(false);
const successMessage = ref<string>("Data has been successfully saved!");
const additionalData = ref<object>({});
const createNewPatient = ref<boolean>(route.params.patient ? false : true);
const patientId = ref<number | null>(route.params.patient);
const caseId = ref<number | null>(route.params.case);
const item = ref<object>({});
const loaded = ref<boolean>(false);
const form = ref<CreatePatient>(new CreatePatientForm());
const errors = ref<object>({});
const loading = ref<boolean>(false);
const offlineParams = ref<string>(route.query.offline);
const showContent = ref(false);
const hasCaseConference = ref(null)
const recordStatusChecker = useRecordStatusChecker();
const isRecordDeleted = computed(() => recordStatusChecker.isDeletedGuardian);
const isInformantDeleted = computed(() => recordStatusChecker.isDeletedInformant);
const isPatientAddressDeleted = computed(() => recordStatusChecker.isDeletedPatientAddress);
const isPerpetratorDeleted = computed(() => recordStatusChecker.isDeletedPerpetrator);
const otherDetailsCount = ref<{
	guardians: number;
	companions: number;
	patientAddresses: number;
	perpetrators: number;
	interventions: number;
	caseConferences: number;
}>({
    guardians: 0,
    companions: 0,
    patientAddresses: 0,
    perpetrators: 0,
    interventions: 0,
    caseConferences: 0,
});

const formContent = ref({
	to: null,
	attn: null
})

const save = async (): Promise<any> => {
	loading.value = true;
	errors.value = {};

	if (!isNetworkAvailable.value) {
		const validate = form.value.offlineUpdateValidation(form.value);
		errors.value = validate?.errors;
        
		if (validate.hasOfflineError) {
			showSuccess.value = false;
            showConfirmation.value = false;
            loading.value = false;
			return;
		}
	}

    const response = await CaseService.update(
		patientId.value,
		caseId.value,
		{ ...form.value },
		isNetworkAvailable.value
	);

    if (response?.status === 200 || response?.data != undefined) {
        await getData();
        loading.value = false;
        showSuccess.value = true;
        showConfirmation.value = false;
        successMessage.value = response.data.message;
    } else {
        const error = response;
        errors.value = errorResponse(error.response?.data?.errors);
        showConfirmation.value = false;
        loading.value = false;
    }

	// CaseService.update(
	// 	patientId.value,
	// 	caseId.value,
	// 	{ ...form.value, ...{ isOfflineData: offlineParams.value } },
	// 	isNetworkAvailable.value
	// )
	// 	.then((response: object) => {
	// 		showSuccess.value = true;
	// 		showConfirmation.value = false;
	// 		successMessage.value = response.data.message;
	// 		loading.value = false;
	// 		getData();
	// 	})
	// 	.catch((error: object) => {
			
	// 	});
};

const redirectSuccess = (): void => {
	showSuccess.value = false;
	newRecord.value = false;
    router.push(`/patient/${patientId.value}/cases/${caseId.value}/edit`);
};

const template = (): void => {
	form.value.mental_health = additionalData.value.mentalHealthDiagnoses?.map((item) => {
		const newForm = form.value.mental_health?.filter((mental_health) => {
			if (mental_health.mental_health_diagnoses_id == item.id) {
				return {
					mental_health_diagnoses_id: item.id,
					type: mental_health.type,
					specify: mental_health.specify,
					behavior_type_id: mental_health.behavior_type_id,
					checked: true,
				};
			}
		});
		return newForm.length > 0
			? newForm[0]
			: { mental_health_diagnoses_id: "", type: "", specify: "", behavior_type_id: "", checked: false };
	});
	form.value.comorbidities = additionalData.value.comorbidities?.map((item) => {
		const newForm = form.value.comorbidities.filter((comorbidity) => {
			if (comorbidity.comorbidity_id == item.id) {
				return {
					comorbidity_id: item.id,
					checked: true,
				};
			}
		});
		return newForm.length > 0 ? newForm[0] : { comorbidity_id: null, checked: false };
	});
};

const fillUpForm = (item: object): void => {
	form.value.updateCase(item);
	form.value.is_reabuse = form.value.is_reabuse ? 1 : 0;
};

const getData = async (): Promise<any> => {
	loading.value = true;
    const response = await CaseService.edit(
        patientId.value, 
        caseId.value,
        pickBy({}),
        isNetworkAvailable.value,
    );
    if (response?.status === 200 || response?.data != undefined) {
        if (isNetworkAvailable.value) {
				additionalData.value = response.data;

            item.value = {
                ...response.data.item,
                patient: response.data.patient,
                hasCaseConference: response.data?.hasCaseConference,
            };

            if (response.data?.downloadables?.patient) {
                await PatientService.saveOnlineToOfflinePatient(response.data?.downloadables?.patient);
            }

            if (response.data?.downloadables?.cases) {
                await PatientService.saveOnlineToOfflineCase(response.data?.downloadables?.cases);
            }
        } else {
            item.value = response.data.item.content ? JSON.parse(response.data.item.content) : null;
            Object.assign(item.value, { patient: OfflinePatientCases.renderEditPatientCase(item.value) })
            const renderOptions = await options.createPatientOptions();
            additionalData.value = { 
                ...renderOptions, 
                ...{ patient: OfflinePatientCases.renderEditPatientCase(item.value) } 
            };
        }

        fillUpForm(item.value);
        template();

        newRecord.value = route.query?.viewForm ? route.query.viewForm == 'true' : false;
        loaded.value = true;
        loading.value = false;
    } else {
        const error = response;
        loading.value = false;
        console.log(error);
    }
};

const generatePDFAccess = (type:string):void => {
	CaseService
		.generatePDFAccess(
			patientId.value,
			caseId.value,
			isNetworkAvailable.value,
			{
				...formContent.value,
				type: type
			}
		)
		.then(({data}) => {
			if(data.token){
				if(type == 'scar'){
					downloadScar(data.token)
					showContent.value = false
				} else {
					downloadPatientRecord(data.token)
				}
			}
		}).catch((error) => {
			errors.value = errorResponse(error.response?.data?.errors)
		})

}
const downloadPatientRecord = (token:string):void => {
	CaseService
		.downloadPatientRecord(
			patientId.value,
			caseId.value,
			token,
			isNetworkAvailable.value
		)
		.then(({data}) => {
			// console.log(data);
		}).catch((error) => {
			console.log(error);
			
		})
}

const downloadScar = (token:string):void => {
	CaseService
		.downloadScar(
			patientId.value,
			caseId.value,
			token,
			isNetworkAvailable.value,
			{
				...formContent.value,
				type: 'scar'
			}
		)
		.then(({data}) => {
			console.log(data);
		}).catch((error) => {
			errors.value = errorResponse(error.response?.data?.errors)
		})
}

const renderSideNavCounts = async () => {
    if (!isNetworkAvailable.value) {
        await renderGuardianCount();
        await renderCompanionCount();
        await renderPatientAddressCount();
        await renderPerpetratorCount();
		await renderInterventionCount();
		await renderCaseConferences();
        recordStatusChecker.isDeleted = false;
        recordStatusChecker.isCreated = false;
    }
}

const renderGuardianCount = async (canManuallyUpdate = false) => {
    if (isNetworkAvailable.value) {
        return;
    }
    const guardians = await db.select(
        `SELECT count(*) as total FROM case_guardians WHERE cases_id=${caseId.value} AND (deleted_at = "" OR deleted_at IS NULL)`
    );
    otherDetailsCount.value.guardians = guardians.length > 0 ? guardians[0].total : 0;
}

const renderCompanionCount = async (canManuallyUpdate = false) => {
    if (isNetworkAvailable.value) {
        return;
    }
    const companions = await db.select(
        `SELECT count(*) as total FROM case_companions  WHERE cases_id=${caseId.value} AND (deleted_at = "" OR deleted_at IS NULL)`
    );
    otherDetailsCount.value.companions = companions.length > 0 ? companions[0].total : 0;
    if (canManuallyUpdate) {
        recordStatusChecker.isDeleted = false;
        recordStatusChecker.isCreated = false;
    }
}

const renderPatientAddressCount = async (canManuallyUpdate = false) => {
    if (isNetworkAvailable.value) {
        return;
    }
    const patientAddress = await db.select(
        `SELECT count(*) as total FROM case_patient_addresses WHERE cases_id=${caseId.value} AND (deleted_at = "" OR deleted_at IS NULL)`
    );
    otherDetailsCount.value.patientAddresses = patientAddress.length > 0 ? patientAddress[0].total : 0;
    if (canManuallyUpdate) {
        recordStatusChecker.isDeleted = false;
        recordStatusChecker.isCreated = false;
    }
}

const renderPerpetratorCount = async (canManuallyUpdate = false) => {
    if (isNetworkAvailable.value) {
        return;
    }
    const response = await db.select(`SELECT * FROM case_perpetrators WHERE cases_id=${caseId.value} AND (deleted_at = "" OR deleted_at IS NULL)`);
    const perpetrators = await db.select(
        `SELECT count(*) as total FROM perpetrators WHERE id IN (${response.map((item: any) => item.perpetrator_id)}) AND (deleted_at = "" OR deleted_at IS NULL)`
    );
    otherDetailsCount.value.perpetrators = perpetrators.length > 0 ? perpetrators[0].total : 0;
}


const renderInterventionCount = async (canManuallyUpdate = false) => {
    if (isNetworkAvailable.value) {
        return;
    }
    const interventions = await db.select(`SELECT * FROM intervention_data WHERE cases_id=${caseId.value} AND (deleted_at = "" OR deleted_at IS NULL)`);

	otherDetailsCount.value.interventions = interventions.length;
    if (canManuallyUpdate) {
        recordStatusChecker.isDeleted = false;
        recordStatusChecker.isCreated = false;
    }
}

const renderCaseConferences = async (canManuallyUpdate = false) => {
    if (isNetworkAvailable.value) {
        return;
    }
    const interventions = await db.select(`SELECT * FROM case_conferences WHERE cases_id=${caseId.value} AND (deleted_at = "" OR deleted_at IS NULL)`);

	otherDetailsCount.value.caseConferences = interventions.length;
    if (canManuallyUpdate) {
        recordStatusChecker.isDeleted = false;
        recordStatusChecker.isCreated = false;
    }
}


// watch(
// 	() => isNetworkAvailable.value,
// 	async () => {
// 		await getData();
//         if (isRecordDeleted.value) {
//             await renderGuardianCount(true);
//         }

//         if (isInformantDeleted.value) {
//             await renderCompanionCount(true);
//         }

//         if (isPatientAddressDeleted.value) {
//             await renderPatientAddressCount(true);
//         }

//         if (isPerpetratorDeleted.value) {
//             await renderPerpetratorCount(true);
//         }
// 	}
// );

watch(
    () => isRecordDeleted.value,
    async (val) => {
        if (val) {
            await renderGuardianCount(true);
        }
    }
);

watch(
    () => isInformantDeleted.value,
    async (val) => {
        if (val) {
            await renderCompanionCount(true);
        }
    }
);

watch(
    () => isPatientAddressDeleted.value,
    async (val) => {
        if (val) {
            await renderPatientAddressCount(true);
        }
    }
);

watch(
    () => isPerpetratorDeleted.value,
    async (val) => {
        if (val) {
            await renderPerpetratorCount(true);
        }
    }
);

onIonViewWillEnter(async () => {
    await getData();
    await renderSideNavCounts();
});

// onMounted(async () => {
// 	await getData();
// });
</script>
