<template>
	<intake-layout :patient="patient">
		<!-- <template #actionButtons>
			<button-component btnWidth="quinary">Cancel</button-component>
			<button-component 
				customClass="primary" 
				@click="save"
			>
				Save Changes
			</button-component>
		</template> -->

		<div class="col-span-12">
			<data-table
				:headers="headers"
				:no-action="false"
				:count="items?.data?.length"
				dataTableClass="border-t-0 border-x-0 "
			>
				<template #body>
					<template v-for="item in items.data" :key="item">
						<tr>
							<td class="p-6 text-sm font-normal text-gray-900 ">
								+63{{ item.number }}
							</td>
							<td class="text-sm font-normal text-gray-900">
								{{ item.label }}
							</td>
							<td class="text-sm font-normal text-gray-900">
								{{ item.updated_at_formatted }}
							</td>
							<td class="flex justify-center p-6 space-x-2">
								<view-button
									:isEyeIcon="false"
									@click="showModal(item)"
									class="shrink-0"
								/>
								<delete-button
									@click="showDeleteModal(item.id)"
									class="shrink-0"
								/>
							</td>
						</tr>
					</template>
				</template>
			</data-table>
		</div>

		<div class="flex justify-end col-span-12">
			<button-component 
				customClass="primary sm:w-auto w-full"
				@click="showContent = true"
			>
				<PlusIcon class="w-3.5 h-3.5"/>
				<p>Add New</p>
			</button-component>
		</div>

		<!-- MODAL -->
		<success-modal
			noBtn
			:show="showSuccess"
			action-text="Close"
			title="Record Save"
			content="Intake has been updated" 
            @close="showSuccess = false"
		/>

		<!-- MODAL -->
		<confirmation-modal
			:typeTwo="true"
			:show="showConfirmation"
			@cancel="showConfirmation = false"
			@confirm="deleteItem()"
			cancel-text="Cancel"
			action-text="Confirm"
			title="Delete Record?"
			content="Are you sure you wish to delete this record?" 
		/>

		<!-- Delete Record Success -->
		<success-modal	
			noBtn
			:show="showDeleteSuccess"
			action-text="Close"
			title="Record Deleted!"
			content="Selected record has been successfully deleted." 
            @close="showDeleteSuccess = false"
		/>

		<content-modal
			:show="showContent" 
			title="Add Contact Number"
		>
			<div>
				<text-input
					label="Label"
					placeholder="Label"
					name="label"
					id="label"
					v-model="form.label"
					:error="errors?.label"
				/>
			</div>

			<div>
				<text-input
                    type="tel"
                    label="Contact Number"
                    placeholder="contact number"
                    name="contact_number"
                    id="contact_number"
                    :add_on_left="true"
                    add_on_text="+63"
                    :maxlength="10"
                    v-model="form.number"
					:error="errors?.number"
                    @keypress="numberonly"
                    required
                />
			</div>

			<template #button>
				<button-component  
					btnWidth="quinary"
					@click="showContent = false"
				>
					Cancel
				</button-component >
				<button-component 
					customClass="primary" 
					@click="addNew"
				>
					Save Changes
				</button-component>
			</template>
		</content-modal>

		<page-loader :show="loading"/>
	</intake-layout>
</template>

<script setup lang="ts">
import IntakeLayout from '../../Component/IntakeLayout.vue';
import ViewButton from "@/components/buttons/ViewButton.vue";
import DeleteButton from "@/components/buttons/DeleteButton.vue";
import DataTable from "@/components/partials/DataTable.vue";
import ContentModal from "../../Component/ContentModal.vue";
import ButtonComponent from "@/components/buttons/ButtonComponent.vue";
import { ref, onMounted, computed, watch } from 'vue'; 
import { PlusIcon } from "@heroicons/vue/24/solid"; 
import SuccessModal from "@/components/modals/SuccessModal.vue";
import TextInput from "@/components/inputs/TextInput.vue";
import ContactNumberService from '@/classes/Cases/IntakeForm/ContactNumberService';
import { ContactNumberIndex } from '@/interfaces/Cases/IntakeForm/ContactNumber';
import { useRouter, useRoute } from "vue-router";
import pickBy from "lodash/pickBy";
import PageLoader from "@/components/loaders/PageLoader.vue"
import ConfirmationModal from "@/components/modals/ConfirmationModal.vue";
import { errorResponse } from "@/helpers/errorMessage";
import { numberonly } from "@/components/composables/useNumberOnly";
import { useNetworkStore } from '@/store/network';
import OfflineSyncContactNumber from '@/classes/Forms/Cases/Intakes/OfflineSyncContactNumber';
import DB from '@/classes/Databases/Crud';
import { onIonViewWillEnter } from '@ionic/vue';
import IntakerForm from '@/classes/Forms/Cases/IntakeForm';

const db = new DB("patients");
const router = useRouter();
const route = useRoute();
const showSuccess = ref<boolean>(false);
const showContent = ref<boolean>(false);
const items = ref<ContactNumberIndex[]>([]);
const patientId = ref<number | null>(route.params.patient);
const caseId = ref<number | null>(route.params.case);
const loading = ref<boolean>(false);
const isUpdate = ref<boolean>(false)
const showConfirmation = ref<boolean>(false)
const selectedItemId = ref<number | null>(null)
const showDeleteSuccess = ref<boolean>(false)
const errors = ref<object | null>(null);
const patient = ref<string>('');
const networkStore = useNetworkStore();
const isNetworkAvailable = computed(() => networkStore.isNetworkAvailable);
const isIonWillEnter = ref<boolean>(false);
const formValidation = new IntakerForm({});

const headers = [
	{ text: 'Contact Numbers' },
	{ text: 'Label' },
	{ text: 'Date Updated' },
]

const form = {
	id: null,
	label: null,
	number: null,
}

const clearForm = () => {
    form.id = null;
	form.label = null
	form.number = null
}

const showDeleteModal = (id) => {
	showConfirmation.value = true
	selectedItemId.value = id
}

const addNew = async () => {
	loading.value = true;
	
	let method = null
    errors.value = null;
    if (!isNetworkAvailable.value) {
        const validate = formValidation.offlineContactNumberValidation(form);
		errors.value = validate?.errors;
		if (validate.hasOfflineError) {
			showSuccess.value = false;
            loading.value = false;
			return;
		}
    }

    if (isNetworkAvailable.value) {
        if (isUpdate.value) {
            method = ContactNumberService.update(patientId.value, caseId.value, form.id, form)
        } else {
            method = ContactNumberService.store(patientId.value, caseId.value, form)
        }

        method
            .then((response: object) => {
                showSuccess.value = true;
                setTimeout(() => {
                    showSuccess.value = false;
                }, 2000)
                
                loading.value = false;
                showContent.value = false;
                
                clearForm()
                getData()
            })
            .catch((error: object) => {
                loading.value = false;
                errors.value = errorResponse(error.response?.data?.errors);
            });
    } else {
        await OfflineSyncContactNumber.save(form, patientId.value, caseId.value);
        await fetchOfflineRecord();
        showSuccess.value = true;
        loading.value = false;
        showContent.value = false;
        clearForm();
    }


}

const showModal = (item) => {
	form.id = item.id
	form.label = item.label
	form.number = item.number
	showContent.value = true
	isUpdate.value = true
    errors.value = null;
}


const getData = async () => {
	loading.value = true;
	if (caseId.value && isNetworkAvailable.value) {
		await ContactNumberService.index(
			patientId.value,
			caseId.value,
			pickBy({ ...{ rows: 10 } }),
		)
			.then(async (response: object) => {
				items.value = response.data.items;
				patient.value = response.data.patient;
				// hasContent.value = response.data.items.total > 0 ? true : false;
                await OfflineSyncContactNumber.syncToOffline(response.data.items, caseId.value);
				loading.value = false;
			})
			.catch((error: object) => {
				errors.value = errorResponse(error.response?.data?.errors);
				loading.value = false;
			});
	}
};

const fetchOfflineRecord = async () => {
    if (isNetworkAvailable.value) {
        return;
    }
    items.value.data = [];
    const response = await OfflineSyncContactNumber.index({ cases_id: caseId.value}, caseId.value);
    if (response.data.items.data.length > 0) {
        const records = response.data.items;
        items.value = records;
        for (const index in records.data) {
            const item = records.data[index];
            items.value.data[index] = OfflineSyncContactNumber.transformContent(JSON.parse(item.content), item);
        }
    }

    const patientRecord = await db.edit(patientId.value, false, 'patients');
    if (patientRecord.data.item.length === undefined) {
        patient.value = `${patientRecord.data.item?.last_name}, ${patientRecord.data.item?.first_name}`;
    }

    loading.value = false;
}

const deleteItem = async () => {
	loading.value = true;
    if (isNetworkAvailable.value) {
        await ContactNumberService.delete(
            patientId.value,
            caseId.value,
            selectedItemId.value,
        )
            .then(() => {
                loading.value = false;
                showDeleteSuccess.value = true;
                setTimeout(() => {
                    showDeleteSuccess.value = false;
                }, 2000)
                showConfirmation.value = false;
                getData()
            })
            .catch((error: object) => {
                errors.value = errorResponse(error.response?.data?.errors);
                loading.value = false;
            });
    } else {
        await OfflineSyncContactNumber.delete(selectedItemId.value, caseId.value);
        await fetchOfflineRecord();
        showDeleteSuccess.value = true;
        showConfirmation.value = false;
        loading.value = false;
    }

};

// watch(
// 	() => isNetworkAvailable.value,
// 	async (val) => {
// 		console.log("Watcher - Network: " + val);
//         await getData()
//         await fetchOfflineRecord();
// 	}
// );

onIonViewWillEnter(async () => {
    if (isIonWillEnter.value) {
        await getData();
        await fetchOfflineRecord();
    }
});

onMounted(async () => {
    await getData()
    await fetchOfflineRecord();
    isIonWillEnter.value = true;
});
</script>