<template>
    <user-layout hasBack backTitle="Court Schedule Report" backLink="/reports">

        <template #lowerRight>
            <export-button @click="exporting()"/>
        </template>

        <div class="-mx-6 w-[calc(100%+32px)] pb-6" v-if="!loading">
            <div class="mx-6 mt-8 border rounded-lg border-neutral-100">
                <div class="">
                    <data-table 
                        :hasSearch="true"
                        searchPlaceholder="Search Name of Personnel, Court, Case No., Tracking No."
                        :headers="headers" 
                        :no-action="true"
                        :count="items.total"
                        tableClass="header-frm-table"
                        dataTableClass="border-0"
                        :search="filters.query"
                        @update:searchText="(value:string) => (filters.query = value)"
                    >
                        <template #topContent>
                            <div class="flex space-x-2">
                                <div class="flex px-4 py-3 space-x-2 transition bg-white border rounded cursor-pointer border-neutral-100 hover:bg-primary-100"
                                @click="showFilter = true">
                                    <FunnelIcon class="inline-block w-5 h-5 text-neutral-900" />
                                    <p class="text-sm font-semibold text-neutral-900">Filter</p>
                                </div>
                                
                                <Popover class="relative">
                                    <PopoverButton>
                                        <div class="flex px-4 py-3 space-x-2 transition bg-white border rounded cursor-pointer border-neutral-100 hover:bg-primary-100">
                                            <ArrowsUpDownIcon class="inline-block w-5 h-5 text-neutral-900" />
                                            <p class="text-sm font-semibold text-neutral-900">Sort</p>
                                        </div>
                                    </PopoverButton>

                                    <transition
                                        enter-active-class="transition duration-200 ease-out"
                                        enter-from-class="translate-y-1 opacity-0"
                                        enter-to-class="translate-y-0 opacity-100"
                                        leave-active-class="transition duration-150 ease-in"
                                        leave-from-class="translate-y-0 opacity-100"
                                        leave-to-class="translate-y-1 opacity-0"
                                    >
                                        <PopoverPanel class="absolute right-0 z-10 mt-3 w-fit">
                                        <div class="p-6 overflow-hidden bg-white rounded-lg shadow-md ring-1 ring-black/5 sort">
                                            <div class="flex flex-col w-auto space-y-2 bg-white">
                                                <radio-group
                                                    :options="sortOptions"
                                                    name="sortOptions"
                                                    v-model="filters.column"
                                                    @update:modelValue="getData()"
                                                />
                                            </div>
                                            <hr class="my-4">
                                            <div class="flex flex-col w-auto space-y-2 bg-white">
                                                <radio-group
                                                    :options="orderOptions"
                                                    name="orderOptions"
                                                    v-model="filters.order"
                                                    @update:modelValue="getData()"
                                                />
                                            </div>
                                        </div>
                                        </PopoverPanel>
                                    </transition>
                                </Popover>

                                <FilterPanel
                                    :show="showFilter"
                                    @close="showFilter = false"
                                    @apply:reset="applyReset()"
                                    @apply:filters="applyFilters()"
                                    customClass="!overflow-visible overflow-x-visible"
                                >
                                    <template #fields>
                                        <div class="space-y-5">
                                            <date-picker
                                                id="date_court_hearing"
                                                name="date_court_hearing"
                                                label="Date of Court Hearing"
                                                v-model="filters.court_hearing_date"
                                                range
                                                multiCalendars
                                                class="filter-range"
                                            />
                                            <multi-select
                                                :options="interventionTypes"
                                                label="Intervention type"
                                                id="type_filter"
                                                name="type_filter"
                                                placeholder="Select.."
                                                v-model="filters.intervention_type"
                                                searchable
                                            />
                                        </div>
                                    </template>
                                </FilterPanel>
                            </div>
                        </template>
                        <template #body>
                            <template 
                                v-for="item in items.data" 
                                :key="item"
                            >
                                <tr>
                                    <td class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
                                        {{ item.case_number }}
                                    </td>
                                    <td class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
                                        {{ item.personnel }}
                                    </td>
                                    <td class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
                                        {{ item.court }}
                                    </td>
                                    <td class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
                                        <p v-html="item.docket_number" />
                                    </td>
                                    <td class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
                                        {{ DateFormatter.customDateFormat(item.date_and_time, "MMM dd, yyy - hh:mm a") }}
                                    </td>
                                    <td class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
                                        <p v-html="item.tacking_number" />
                                    </td>
                                    <td class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
                                        <p v-html="item.remarks" />
                                    </td>
                                </tr>
                            </template>
                        </template>
                    </data-table>
                    <div class="w-full px-6 border-t py-7">
                        <div class="w-full px-6 border-t py-7">
                            <simple-pagination 
                                :numRows="filters?.rows ?? 10" 
                                :currentPage="items?.current_page" 
                                :last-page="items?.last_page"
                                @update="(value: any) => paginate(value.page, value.rows)" 
                            />
                        </div>
					</div>
                </div>
            </div>
        </div>

        <loading-modal
            :show="loading"
            noBtn
            title="Please wait.."
            content="System is currently loading the report based on the selected filter."
        />
    </user-layout>
</template>
<script setup lang="ts">
import { ref, onMounted, watch } from 'vue';
import UserLayout from "@/layouts/UserLayout.vue";
import DataTable from '@/components/partials/DataTable.vue';
import ExportButton from '@/components/buttons/ExportButton.vue';
import SimplePagination from "@/components/partials/SimplePagination.vue";
import DatePicker from "@/components/inputs/DatePicker.vue";
import RadioGroup from "@/components/inputs/RadioGroup.vue";
import FilterPanel from "@/components/partials/FilterPanel.vue";
import LoadingModal from '@/components/modals/LoadingModal.vue';
import MultiSelect from "@/components/inputs/MultiSelect.vue";
import { FunnelIcon, ArrowsUpDownIcon } from "@heroicons/vue/24/outline";
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue';
import ReportService from '@/classes/Reports/ReportService';

import isNil from "lodash/isNil";
import omitBy from "lodash/omitBy";
import debounce from "lodash/debounce";

import DateFormatter from "@/helpers/DateFormatter";

const showFilter = ref<boolean>(false);
const loading = ref<boolean>(false);
const items = ref<any>([]);
const interventionTypes = ref<any>([]);

const sortOptions = [
    { id: '1', label: 'Create At', value: 'created_at'},
]

const orderOptions = [
    { id: 'asc', label: 'Ascending', value: 'asc'},
    { id: 'desc', label: 'Descending', value: 'desc'},
]

const headers: { text: string }[] = [
    { text: 'Case No.' },
    { text: 'Personnel' },
    { text: 'Court' },
    { text: 'Docket Number' },
    { text: 'Date and Time of Court Hearing' },
    { text: 'Courier Tracking Number' },
    { text: 'Remarks' },
];

const filters = ref({
    page: 1,
    rows: 10,
    created_at: null,
    query: null,
    intervention_type: null, 
    column: 'created_at',
    order: 'asc',
    court_hearing_date: null,
});

const paginate = ( page:number, rows:number )=>{
    ReportService.paginate(page, rows,filters.value, getData)
}

const applyFilters = () => {
    showFilter.value = false;   
    paginate( 1, filters.value.rows);
};

const applyReset = () => {
    showFilter.value = false; 
    filters.value.created_at = null;
    filters.value.intervention_type = null;
    paginate( 1, filters.value.rows);
};

const getData =  (): void =>{
    loading.value = true; 
    
    ReportService.index('court-schedule', omitBy(filters.value, isNil))
        .then(({data})=>{ 
            items.value = data.items;
            interventionTypes.value = data.interventionTypes;
        })
        .catch((error: any)=>{
            console.log(error)
        })
        .finally(() => loading.value = false)
} 

const exporting = (): void => {
    loading.value = true;
    
    ReportService
        .export('court-schedule', omitBy(filters.value, isNil))
        .then(({data})=>{
            ReportService.downloadExport("court-schedule-export.csv",data);  
        })
        .finally(() => loading.value = false)
}

watch(
    () => filters.value.query,
    debounce(() => {
        paginate( 1, filters.value.rows);
        applyReset();
    }, 500)
);

onMounted(()=> {
    getData();
})

</script>