<template>
    <div class="user-input">
        <label v-if="label" class="block mb-2 text-xs font-semibold text-neutral-600">{{
            label
        }} <span v-if="required" class="text-red-600">*</span></label>
        <Multiselect
            :mode="mode"
            :id="id"
            :name="name"
            :searchable="searchable"
            :placeholder="placeholder"
            :disabled="disabled"
            :options="options"
            v-model="value"
            @clear="(value) => $emit('update:modelValue', null)"
            class="frm-multiselect py-[3px] bg-white"
            :class="(readonly ? 'pointer-events-none cursor-default' : '') || (disabled ? 'bg-gray-100' : 'bg-white')"
            :required="required"
            :can-clear="canClear"
            :create-option="createOption"
        />
            <!-- @change="(value) => $emit('update:modelValue', value)"
            @select="(value) => $emit('update:modelValue', value)" -->
        <p
            v-if="error"
            class="text-[10px] mt-1"
            :class="error ? 'text-red-600' : 'text-gray-800'"
            id="form-error"
        >
            {{ error }}
        </p>
    </div>
</template>
<script setup lang="ts">
import { computed } from 'vue';
import Multiselect from '@vueform/multiselect';

const props = defineProps({
    modelValue: {
        default: null,
        required: true
    },
    error: {
        type: String,
        default: null,
    },
    mode: {
        type: String,
        default: 'single' // single | multiple | tags
    },
    searchable: {
        type: Boolean,
        default: false
    },
    id: {
        type: String,
    },
    label: {
        type: String,
    },
    placeholder: {
        type: String,
        default: 'Placeholder'
    },
    disabled: {
        type: Boolean,
        default: false
    },
    canClear: {
        type: Boolean,
        default: false
    },
    required: {
        type: Boolean,
        default: false,
    },
    createOption: {
        type: Boolean,
        default: false,
    },
    readonly: {
        type: Boolean,
        default: false,
    },
    options: {
        type: Object,
        default: () => ({
            1: 'Option 1',
            2: 'Option 2',
            3: 'Option 3'
        })
    }
});

const emit = defineEmits<{
    ( e: 'update:modelValue', val: object): void
}>()

const value = computed<object>({
    get() {
        return props.modelValue;
    },
    set(value) {
        emit('update:modelValue', value);
    }
})
</script>