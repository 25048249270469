<template>
	<user-layout
		hasBack
		:backLink="`/patient/${patientId}/cases/${caseId}/edit`"
		hasBackClass="flex md:justify-between md:items-center md:flex-row flex-col">
		<template #lowerRight>
			<div class="flex flex-col items-center space-y-3 md:flex-row md:space-x-3 md:space-y-0">
				<a
					@click="
						router.push(
							`/patient/${patientId}/cases/${caseId}/edit`
						)
					"
					class="w-full mt-6 md:w-auto md:mt-0">
					<button-component btnWidth="quinary" customClass="quinary md:w-auto w-full">
						Cancel
					</button-component>
				</a>
				<button-component
					customClass="primary md:w-auto w-full md:mt-0 mt-6"
					form="form" 
					@click="save()"
				>
					<span>Save Changes</span>
				</button-component>
			</div>
		</template>
		<p class="mt-6 text-lg font-medium md:text-2xl text-neutral-600">Patient's Name:
			<span class="block font-semibold md:inline-block text-neutral-900">{{ patient }}</span>
		</p>
		<div class="w-full p-4 mx-auto my-6 border border-gray-200 rounded lg:p-6">
			<div class="flex items-center justify-between">
				<section-title title="Perpetrator Details" />
			</div>
			<form-section
				ref="formRef"
				:item="item"
				:form="form"
				:errors="errors"
				:genders="genders"
				:relationships="relationships"
				:nationalities="nationalities"
				:religions="religions"
				:currentLocations="currentLocations"
				:identities="identities" 
			/>
		</div>
		
		<success-modal
			:show="showSuccess"
			@confirm="redirectSuccess()"
			action-text="Close"
			title="Record Save!"
			:content="successMessage"
		/>

		<page-loader :show="loading"/>
	</user-layout>

</template>
<script setup lang="ts">
import { onMounted, ref, computed, watch } from "vue";
import UserLayout from "@/layouts/UserLayout.vue";
import SectionTitle from "@/components/partials/SectionTitle.vue";
import ButtonComponent from "@/components/buttons/ButtonComponent.vue";
import SuccessModal from "@/components/modals/SuccessModal.vue";
import FormSection from "./FormSection.vue";
import { useRouter, useRoute } from "vue-router";
import PerpetratorService from "@/classes/Cases/PerpetratorService";
import { renderSelect, renderSelectV2 } from "@/interfaces/DropdownInterface";
import { PerpetratorCreate } from "@/interfaces/Cases/Perpetrator";
import PerpetratorForm from "@/classes/Forms/Cases/PerpetratorForm";
import { errorResponse } from "@/helpers/errorMessage";
import { useNetworkStore } from "@/store/network";
import pickBy from "lodash/pickBy";
import DB from "@/classes/Databases/Crud";
import FormOptions from "@/classes/Forms/FormOptions";
import Gender from "@/enums/Gender";
import PerpetratorIdentity from "@/enums/PerpetratorIdentity";
import CurrentLocation from "@/enums/CurrentLocation";
import SqliteRecordChecker from "@/classes/Forms/SqliteRecordChecker";
import PageLoader from "@/components/loaders/PageLoader.vue"
import { useRecordStatusChecker } from "@/store/recordStatusChecker";
import Relationships from "@/classes/Forms/Relationships";
import CasePerpetratorOfflineForm from "@/classes/Forms/Cases/CasePerpetratorOfflineForm";
import { onIonViewWillEnter } from "@ionic/vue";
import OfflineCasePerpetrator from "@/classes/Forms/Cases/OfflineCasePerpetrator";

const networkStore = useNetworkStore();
const isNetworkAvailable = computed(() => networkStore.isNetworkAvailable);
const checkRecord = new SqliteRecordChecker();
const db = new DB("perpetrators");
const dbLinkPerpetrator = new DB("case_perpetrators");
const dbPatient = new DB("patients");
const router = useRouter();
const route = useRoute();
const showSuccess = ref<boolean>(false);
const loading = ref<boolean>(false);
const loaded = ref<boolean>(true);
const patient = ref<string>('');
const patientId = ref<number | null>(route.params.patient);
const caseId = ref<number | null>(route.params.case);
const genders = ref<renderSelectV2[]>([]);
const identities = ref<renderSelectV2[]>([]);
const nationalities = ref<renderSelect[]>([]);
const religions = ref<renderSelect[]>([]);
const relationships = ref<renderSelect[]>([]);
const currentLocations = ref<renderSelect[]>([]);
const formRef = ref<any>([]);
// const form = ref<PerpetratorCreate>(new PerpetratorForm());
const form = ref<PerpetratorCreate>({
	tag: null,
	details: 2,
	identity: null,
	is_minor: false,
	last_name: null,
	first_name: null,
	middle_name: null,
	gender: null,
	birthday_classification: null,
	birthdate: null,
	approximate_age_year: null,
	approximate_age_month: null,
	age: null,
	perpetrator_relationship_id: null,
	other_relationship: null,
	nickname: null,
	occupation: null,
	nationality_id: null,
	other_nationality: null,
	religion_id: null,
	current_location: null,
	perpetrator_location: null,
	about_perpetrator: null,
	contacts: null,
});

const errors = ref<object>({});
const successMessage = ref<string>("New perpetrator has been added");
const item = ref<PerpetratorCreate>(new PerpetratorForm());
const offlineParams = ref<string>(route.query.offline);
const options = new FormOptions();
const recordStatusChecker = useRecordStatusChecker();
const relationship = new Relationships()

const save = async (): Promise<any> => {
	loading.value = true;
	errors.value = {};
	if (!isNetworkAvailable.value) {
		const validate = validateSaving(form.value);
		errors.value = validate?.errors;
		
		if (validate.hasOfflineError) {
			showSuccess.value = false;
            loading.value = false;
			return;
		}
	}

    const response = await PerpetratorService.store(
		patientId.value,
		caseId.value,
		{ ...form.value },
		isNetworkAvailable.value
	);

    if (response?.status === 200 || response?.data != undefined) {
        await saveToOffline(response);
		resetForm()
        loading.value = false;
        showSuccess.value = true;
        successMessage.value = response.data.message;
        recordStatusChecker.isCreatedPerpetrator = true;
    } else {
        loading.value = false;
        const error = response;
        errors.value = errorResponse(error.response?.data?.errors);
    }
};

const validateSaving = (data) => {
    const errors = {};
    let hasOfflineError = false;

    if (!data.perpetrator_relationship_id) {
        hasOfflineError = true;
        Object.assign(errors, { perpetrator_relationship_id: "The relationship field is required." });
    }

    if (data.gender == undefined) {
        hasOfflineError = true;
        Object.assign(errors, { gender: "The gender field is required." });
    }

	if (!data.birthdate && !data.approximate_age_month) {
		hasOfflineError = true;
		Object.assign(errors, { approximate_age_month: "The approximate age month field is required when birthdate is not present." });
	}

	if (!data.birthdate && !data.approximate_age_year) {
		hasOfflineError = true;
		Object.assign(errors, { approximate_age_year: "The approximate age year field is required when birthdate is not present." });
	}

	if (!data.approximate_age_month && !data.birthdate) {
		hasOfflineError = true;
		Object.assign(errors, { birthdate: "The birthdate field is required when approximate age month is not present." });
	}

	if (!data.approximate_age_year && !data.birthdate) {
		hasOfflineError = true;
		Object.assign(errors, { birthdate: "The birthdate field is required when approximate age year is not present." });
	}

    if (data.identity != 1) {
        if (!data.first_name) {
            hasOfflineError = true;
            Object.assign(errors, { first_name: "The first name field is required." });
        }

        if (!data.last_name) {
            hasOfflineError = true;
            Object.assign(errors, { last_name: "The last name field is required." });
        }
    }

    if (data.contacts && data.contacts.length < 10) {
        hasOfflineError = true;
        Object.assign(errors, { contacts: "The contacts field must have at least 10 digits." });
    }

    if (data.identity == 1) {
        if (!data.tag) {
            hasOfflineError = true;
            Object.assign(errors, { tag: "The tag field is required." });
        }
    }

    return {
        hasOfflineError: hasOfflineError,
        errors: errors,
    };
}

const saveToOffline = async (response: any) => {
    if (!isNetworkAvailable.value) {
        return;
    }
    await OfflineCasePerpetrator.onlinePerpetratorSaveToOffline(response, caseId.value);
}

const redirectSuccess = (): void => {
	showSuccess.value = false;
	router.push(
		`/patient/${patientId.value}/cases/${caseId.value}/edit`
	);
};

const getData = async (): Promise<any> => {
    const response = await PerpetratorService.create(
		patientId.value,
		caseId.value,
		pickBy({}),
		isNetworkAvailable.value
	);

    if (response?.status === 200 || response == undefined) {
        if (isNetworkAvailable.value) {
            genders.value = response.data.genders;
            identities.value = response.data.identities;
            nationalities.value = response.data.nationalities;
            religions.value = response.data.religions;
            relationships.value = response.data.perpetratorRelationships;
            currentLocations.value = response.data.currentLocations;
            form.value.is_minor = false;
            form.value.tag = response.data.unknownTag;
            patient.value = response.data.patient;
            item.value = { tag: form.value.tag };
        } else {
            const fetch = await fetchPatient();
            genders.value = Gender;
            identities.value = PerpetratorIdentity;
            nationalities.value = await options.nationalities();
            religions.value = await options.religions();
            relationships.value = await options.perpetratorRelationships();
            currentLocations.value = CurrentLocation;
            form.value.is_minor = false;
        }
    } else {
        console.log(response);
    }
};

const fetchPatient = async (): Promise<any> => {
	const fetch = await checkRecord.init(dbPatient, patientId.value, false, 'patients');
	if (fetch.result.data.item.length == undefined) {
		const patientData = JSON.parse(fetch.result.data.item.content);
		form.value.tag = `000(Offline) - Unknown Perpetrator of ${patientData.first_name} ${patientData.last_name}`;
		item.value = { tag: form.value.tag };
	}
};

const renderPatient = async (): Promise<any> => {
    if (!isNetworkAvailable.value) {
        const response = await dbPatient.edit(patientId.value, false, 'patients');
        const item = response.data.item;
        if (item.length === undefined) {
            patient.value = `${relationship.capitalFirstLetter(item.last_name)}, ${relationship.capitalFirstLetter(item.first_name)}`;
        }
    }

}

const resetForm = () => {
    form.value.tag = null;
	form.value.details = null;
	form.value.identity = null;
	form.value.is_minor = null;
	form.value.last_name = null;
	form.value.first_name = null;
	form.value.middle_name = null;
	form.value.gender = null;
	form.value.birthday_classification = null;
	form.value.birthdate = null;
	form.value.approximate_age_year = null;
	form.value.approximate_age_month = null;
	form.value.perpetrator_relationship_id = null;
	form.value.other_relationship = null;
	form.value.nickname = null;
	form.value.occupation = null;
	form.value.nationality_id = null;
	form.value.other_nationality = null;
	form.value.religion_id = null;
	form.value.current_location = null;
	form.value.perpetrator_location = null;
	form.value.about_perpetrator = null;
	form.value.contacts = null;

	formRef.value.birthdateForm.datePickerData.picker.clearValue()

	// loading.value = true

	// setTimeout(() => {
	// 	loading.value = false
	// 	loaded.value = true
	// }, 300);
}

// watch(
// 	() => isNetworkAvailable.value,
// 	(val) => {
// 		console.log("Watcher - Network: " + val);
// 		getData();
// 	}
// );

onIonViewWillEnter(async () => {
    recordStatusChecker.isCreatedPerpetrator = false;
    // resetForm();
    await renderPatient();
    await getData();
});

onMounted(async () => {
	recordStatusChecker.isCreatedPerpetrator = false;
	// resetForm();
    await renderPatient();
    await getData();
});
</script>
