<template>
    <div>
        <label class="block text-xs font-semibold text-neutral-600 mb-3">{{ label }}</label>
        <div class="flex flex-col space-y-2">
            <radio-group
                :options="data_options"
                name="data"
                v-model="target"
            />
        </div>

        <!-- Existing -->
        <content-modal
            :show="showExistingModal" 
        >
            <div class="flex space-x-6">
                <div class="w-1/2">
                    <multi-select
                        :options="changeMeOptions"
                        label="Module"
                        id="module"
                        name="module"
                        placeholder="Select.."
                        searchable
                    />
                </div>
                <div class="w-1/2">
                    <multi-select
                        :options="changeMeOptions"
                        label="Fields (w/ options only)"
                        id="fields"
                        name="fields"
                        placeholder="Select.."
                        searchable
                    />
                </div>
            </div>

            <template #button>
                <button-component  
                    btnWidth="quinary"
                    @click="showExistingModal = false"
                >
                    Cancel
                </button-component >
                <button-component 
                    customClass="primary" 
                >
                    Submit
                </button-component>
            </template>
        </content-modal>

        <!-- Manual -->
        <div v-if="target == 'manual'" class="mt-3">
            <table class="min-w-full divide-y border-none divide-gray-200">
                <thead class="bg-gray-50 border-b border-gray-100">
                    <tr>
                        <th class="px-2 py-3 text-center text-xs text-gray-500 font-semibold whitespace-nowrap">
                            <span>Order</span>
                        </th>
                        <th class="px-2 py-3 text-left text-xs text-gray-500 font-semibold whitespace-nowrap">
                            <span>Option Name</span>
                        </th>
                        <th class="px-2 py-3 text-left text-xs text-gray-500 font-semibold whitespace-nowrap"></th>
                    </tr>
                </thead>
                <tbody>
                    <template v-for="(container, index) in fieldOptions" :key="index">
                        <tr>
                            <td class="p-2 ss">
                                <text-input
                                    type="number"
                                    :id="'sort_' + index"
                                    :name="'sort_' + index"
                                    customClass="intervention-sortorder border text-center w-9 h-9 rounded text-xs text-neutral-600 border-neutral-100 bg-transparent focus:border-neutral-900 focus:outline-none focus:ring-0"
                                />
                            </td>
                            <td class="p-2 w-full">
                                <text-input
                                    type="number"
                                    :id="'input_' + index"
                                    :name="'input_' + index"
                                    placeholder="Type options name"
                                    customClass="h-9 !pr-2 !pl-2 text-xs"
                                />
                            </td>
                            <td class="p-2">
                                <button class="flex text-sm items-center text-red-500 hover:text-red-700 active:text-red-300 transition"
                                @click="deleteOptions(index)">
                                    <TrashIcon class="h-5 w-5" aria-hidden="true" />
                                </button>
                            </td>
                        </tr>
                    </template>
                    <tr>
                        <td colspan="3">
                            <div class="flex text-sm justify-end items-center mt-3">
                                <p class="flex text-primary-500 hover:text-primary-700 active:text-primary-300 font-medium transition italic cursor-pointer"
                                @click="addOptions">
                                    <PlusIcon class="-ml-0.5 mr-2 h-5 w-5" aria-hidden="true" /> Add Another Option
                                </p>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>
<script setup lang="ts">
import { ref } from "vue";
import RadioGroup from "@/components/inputs/RadioGroup.vue";
import TextInput from "@/components/inputs/TextInput.vue";
import MultiSelect from "@/components/inputs/MultiSelect.vue";
import ButtonComponent from "@/components/buttons/ButtonComponent.vue";
import ContentModal from "../ContentModal.vue";
import { PlusIcon, TrashIcon } from "@heroicons/vue/24/outline";

const props = defineProps({
	form: {
		type: Object,
	},
    value: {
        default: null,
        required: true,
    },
    label: {
        type: String,
        default: 'Data is coming from?',
    },
    error: {
        type: String,
        default: null,
    },
    disabled: {
        type: Boolean,
        default: false,
    },
});
const form = ref<object>(props.form);
const showExistingModal = ref<boolean>(true);
const target = ref<string>('');
const fieldOptions = ref<string[]>([]);

const data_options = [
    { id: '1', label: 'Existing', value: 'existing', radioSelected: null},
    { id: '2', label: 'Manual', value: 'manual', radioSelected: null},
]

const changeMeOptions = [
    { id: '1', label: 'Change', value: 'Change'},
    { id: '2', label: 'Me', value: 'Me'},
]

const addOptions = () => {
    fieldOptions.value.push(`Container ${fieldOptions.value.length + 1}`);
};

const deleteOptions = (index: number) => {
    fieldOptions.value.splice(index, 1);
};


</script>
