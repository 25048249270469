import http from "@/axios";
import { PatientAddress } from "@/interfaces/Cases/PatientAddress";
import CasePatientAddressForm from "../Forms/Cases/CasePatientAddressForm";
import DB from "@/classes/Databases/Crud";
import SqliteRecordChecker from "../Forms/SqliteRecordChecker";
import OfflineCasePatientAddress from "../Forms/Cases/OfflineCasePatientAddress";

const db = new DB("case_patient_addresses");
const dbCase = new DB("cases");
const checkRecord = new SqliteRecordChecker();
const dbDraft = new DB("drafts");

class PatientAddressService implements PatientAddress {
	async index(patient: number, patientCase: number, payload: any, networkStatus = true): Promise<any> {
		if (networkStatus) {
			return await http.get(`patients/${patient}/cases/${patientCase}/patient-addresses`, { params: payload }).catch(error => error);
		}

		return await db.index(payload, ` WHERE cases_id=${patientCase}`, true, 'case_patient_addresses');
	}

	async create(patient: number, patientCase: number, payload: object, networkStatus = true): Promise<any> {
		if (networkStatus) {
			return await http.get(`patients/${patient}/cases/${patientCase}/patient-addresses/create`, {
				params: payload,
			}).catch(error => error);
		}
	}

	async store(patient: number, patientCase: number, payload: any, networkStatus = true): Promise<any> {
		if (networkStatus) {
			return await http.post(`patients/${patient}/cases/${patientCase}/patient-addresses`, payload).catch(error => error);
		}

        return await OfflineCasePatientAddress.createCasePatientAddress(patient, patientCase, payload);
	}

	async edit(
		patient: number,
		patientCase: number,
		patientAddress: number,
		payload: any,
		networkStatus = true
	): Promise<any> {
		if (networkStatus) {
			return await http.get(`patients/${patient}/cases/${patientCase}/patient-addresses/${patientAddress}/edit`, {
				params: payload,
			}).catch(error => error);
		}

        return await db.edit(patientAddress, false, 'case_patient_addresses');
	}

	async update(
		patient: number,
		patientCase: number,
		patientAddress: number,
		payload: any,
		networkStatus = true
	): Promise<any> {
		if (networkStatus) {
			return await http.put(
				`patients/${patient}/cases/${patientCase}/patient-addresses/${patientAddress}/update`,
				payload
			).catch(error => error);
		}

        return await OfflineCasePatientAddress.updateCaseInformant(patient, patientCase, patientAddress, payload);
	}

	async delete(
		patient: number,
		patientCase: number,
		patientAddress: number,
		payload: any,
		networkStatus = true
	): Promise<any> {
		if (networkStatus) {
			return await http.delete(
				`patients/${patient}/cases/${patientCase}/patient-addresses/${patientAddress}/delete`,
				payload
			).catch(error => error);
		}

        return await OfflineCasePatientAddress.deleteCasePatientAddress(patient, patientCase, patientAddress, payload);
	}
}

export default new PatientAddressService();
