import http from "@/axios";
import DB from "@/classes/Databases/Crud";
import { SyncStatus } from "@/views/enums/SyncStatus";
import Relationships from "../Forms/Relationships";
import Interventions from '@/database/migrations/InterventionMigration'

const relationship = new Relationships();
const db = new DB("intervention_data");
const dbCases = new DB("cases");

export default class SyncInterventions {
    
    resetItemData(item: any) {
        const data:any = [];

        Interventions.schema.forEach((schema:any) => data[schema.column] = item[schema.column]);

        data.synching_remarks = null;
        data.is_sync_failed = false;
        data.is_sync = true;
        data.is_created_offline = false;
        data.is_updated_offline = false;
        data.is_deleted_offline = false;
        data.data = JSON.stringify(data.data);

        return data;
    }

    async rollback(item: any, response: any) {
        const error = response?.response?.data;
        item.synching_remarks = error?.message || 'System Error';
        item.is_sync_failed = true;
        await db.insert(item, item.id, true, 'intervention_data');

        return true;
    }


    async renderInterventions(isRenderData:boolean): Promise<any> {
        const items:any = [];
        let hasError = false;

        const interventions = await db.select('SELECT * FROM intervention_data WHERE is_sync=false AND (is_created_offline=true OR is_updated_offline=true OR is_deleted_offline=true) ORDER BY updated_at DESC');
        
        if (interventions.length > 0) {
            for (const index in interventions) {

                const intervention = interventions[index];

                const cases = await dbCases.select(`SELECT * FROM cases WHERE id=${intervention.cases_id}`);
                const casesData = cases.length > 0 ? JSON.parse(cases[0].content) : {};

                const form:any = intervention;

                form.data =  JSON.parse(form.data)
                form.table = "intervention_data";
                form.type = "Interventions"
                form.patient_name = casesData.name;
                form.url_view = `/patient/${intervention.patient_id}/cases/${intervention.cases_id}/intervention/${intervention.intervention_type}/edit/${intervention.id}`;
                form.can_view = true;
                form.birthdate_formatted = relationship.dateFormat(casesData.birthdate);
                form.created_at_formatted = relationship.dateFormat(intervention.updated_at);

                form.status = intervention.is_sync_failed == false || intervention.is_sync_failed == undefined 
                    ? (intervention.is_sync ? SyncStatus.SYNC : SyncStatus.NOT_SYNC) 
                    : SyncStatus.RECORD_CONFLICT;

                if (isRenderData) {
                    items.push(form);
                }

                // do something for online syncing
                if (!isRenderData) {
                    hasError = await this.processForm(intervention, form);
                }

            }
        }

        return {
            items: items,
            hasError: hasError,
        }
    }

    async processForm(item: any, form: any): Promise<any> {

        if (item.is_deleted_offline) {
            return await this.processDelete(item, form);
        }

        if (item.is_created_offline) {
            return await this.processCreate(item, form);
        }

        if (item.is_updated_offline) {
            return await this.processUpdate(item, form);
        }

        return false;
    }

    async processUpdate(item: any, form:any): Promise<any>
    {
        const response = await http.post(`/sync/interventions/update/${form.patient_id}/${item.id}`, {
            cases_id: form.cases_id,
            interventionType: form.intervention_type,
            params: form.data,
        }).catch(error => error);

        if (response?.status === 200) {
            item = this.resetItemData(item);
            await db.insert(item, item.id, true, 'intervention_data');
            return false;
        } else {
            return await this.rollback(item, response);
        }
    }

    async processCreate(item: any, form:any): Promise<any>
    {
        try {     
            const response = await http.post(`/sync/interventions/create/${form.patient_id}`, {
                cases_id: form.cases_id,
                interventionType: form.intervention_type,
                params: form.data,
            }).catch(error => error);
    
            if (response?.status === 200) {
                const oldPatientId = item.id;
                item = this.resetItemData(item);
                item.id = response.data.data.item.id;
                await db.delete({id: response.data.data.item.id}, 'intervention_data');
                await db.insert(item, oldPatientId, true, 'intervention_data');
                return false;
            } else {
                console.log('power');
                return await this.rollback(item, response);
            }
        } catch (error) {
            console.log(error);
        }

    }

    async processDelete(item: any, form:any): Promise<any>
    {
        const response = await http.post(`/sync/interventions/delete/${item.id}`, form).catch(error => error);
        if (response?.status === 200) {
            await db.delete({id: item.id}, 'intervention_data');
            return false;
        } else {
            return await this.rollback(item, response);
        }
    }
}