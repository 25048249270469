import http from '@/axios'

class PersonRelatedToCaseService {

    public async index(patient:string, cases:string) : Promise<JSON> {
        return await http.get(`patients/${patient}/cases/${cases}/intakeForm/personnelCase/index`);
    }

    public async create(patient:string, cases:string) : Promise<JSON> {
        return await http.get(`patients/${patient}/cases/${cases}/intakeForm/personnelCase/create`);
    }

    public async store(patient:string, cases:string, payload: object) : Promise<JSON> {
        return await http.post(`patients/${patient}/cases/${cases}/intakeForm/personnelCase/store`, payload);
    }

    public async show(patient:string, cases:string, person:string) : Promise<JSON> {
        return await http.get(`patients/${patient}/cases/${cases}/intakeForm/personnelCase/show/${person}`);
    }

    public async update(patient:string, cases:string, payload: any) : Promise<JSON> {
        return await http.post(`patients/${patient}/cases/${cases}/intakeForm/personnelCase/update/${payload?.id}`, payload);
    }

    public async archive(patient:string, cases:string, person: number) : Promise<JSON> {
        return await http.delete(`patients/${patient}/cases/${cases}/intakeForm/personnelCase/${person}/delete`);
    }

}
export default new PersonRelatedToCaseService();
