<template>
	<user-layout>
		<div class="w-full py-6 mx-auto">
			<div class="flex items-center justify-between">
				<section-title title="Settings" description="View the relevant data and other details" />
				<a @click="router.replace('/settings/telecpu-locations/create')">
					<button-component>
						<PlusIcon class="w-4 h-4 text-primary-600 stroke-[3px] -ml-2 mr-1 icon" />
						<span>Create</span>
					</button-component>
				</a>
			</div>
			<div class="mt-6">
				<div class="w-full border rounded-lg border-neutral-100">
					<div class="relative overflow-auto">
						<div class="absolute left-0 top-0 h-full whitespace-nowrap position w-[181px] shadow-[2px_0_16px_0px_rgb(0,0,0,0.08)]">
							<div class="px-6 py-[26px] bg-primary-100 text-sm font-semibold relative">Settings</div>
							<side-tab telecpuLocationsSelected />
						</div>
						<div class="pl-[181px] min-h-[500px]">
							<div class="flex items-center justify-between p-6 border-b border-neutral-100">
								<div class="flex items-center space-x-4">
									<tab-component :tabs="tabs" @update:tab="(value: string) => updateTab(value)" />
								</div>
							</div>
							<div v-if="filters.tab != 'activity_logs'">
								<data-table
									:headers="headers"
									:no-action="false"
									:count="items.total"
									@update:searchText="(value: string) => (filterSearch = value)"
									tableClass="header-frm-table">
									<template v-slot:body>
										<template v-for="item in items.data" :key="item">
											<tr>
												<td class="px-6 py-[18px] whitespace-nowrap text-sm text-gray-900">
													{{ item.name }}
												</td>
												<td class="px-6 py-[18px] whitespace-nowrap text-sm text-gray-900">
													{{ item.telcpus_count > 0 ? item.telcpus_count + " Offices" : "" }}
												</td>
												<td class="px-6 py-[18px] whitespace-nowrap text-sm text-gray-900">
													{{ item.created_at_formatted }}
												</td>
												<td class="px-6 py-[18px] whitespace-nowrap text-sm text-gray-900">
													<div class="flex items-center space-x-2 justify-center mx-auto">
														<template v-if="!filters.tab">
															<view-button
																@click="
																	router.replace(
																		`/settings/telecpu-locations/${item.id}/view`
																	)
																"></view-button>
															<delete-button @click="showDeleteModal(item)" />
														</template>
														<template v-if="filters.tab === 'archived'">
															<restore-button @click="showRestoreModal(item)"/>
														</template>
													</div>
												</td>
											</tr>
										</template>
									</template>
								</data-table>
							</div>
							<ActivityLogsTable
								v-if="filters.tab == 'activity_logs'"
								:items="items"
							/>
						</div>
					</div>

					<div class="w-full px-6 border-t py-7">
						<simple-pagination 
							:numRows="filters.rows" 
							:currentPage="items.current_page" 
							:last-page="items.last_page" 
							@update="(value: any) => paginate(value)"
						/>
					</div>

					<!-- DELETE MODAL -->
					<confirmation-modal
						:typeTwo="true"
						:show="showConfirmation"
						@cancel="showConfirmation = false"
						@confirm="deleteRecord()"
						cancel-text="Cancel"
						action-text="Confirm"
						title="Delete Record"
						content="Are you sure you wish to delete this record?" />
					<success-modal
						:show="showSuccess"
						@confirm="closeDeleteModal()"
						action-text="Close"
						title="Record Deleted!"
						content="Selected record has been successfully deleted!" />
					<!-- RESTORE MODAL -->
					<confirmation-modal
						:typeTwo="true"
						:show="showRestoreConfirmation"
						@cancel="showRestoreConfirmation = false"
						@confirm="restoreRecord()"
						cancel-text="Cancel"
						action-text="Confirm"
						title="Restore Record"
						content="Are you sure you wish to restore this record?" />
					<success-modal
						:show="showRestoreSuccess"
						@confirm="closeRestoreModal()"
						action-text="Close"
						title="Record Restored!"
						content="Selected record has been successfully restored!" />
				</div>
			</div>
		</div>

        <page-loader :show="loading"/>

	</user-layout>
</template>
<script setup lang="ts">
import { ref, computed, watch, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import UserLayout from "@/layouts/UserLayout.vue";
import SectionTitle from "@/components/partials/SectionTitle.vue";
import ViewButton from "@/components/buttons/ViewButton.vue";
import DeleteButton from "@/components/buttons/DeleteButton.vue";
import RestoreButton from "@/components/buttons/RestoreButton.vue";
import DataTable from "../Components/DataTable.vue";
import SideTab from "../Components/SideTab.vue";
// import MinifyPagination from "@/components/partials/MinifyPagination.vue";
import SimplePagination from "@/components/partials/SimplePagination.vue";
import TabComponent from "@/components/partials/TabComponent.vue";
import ButtonComponent from "@/components/buttons/ButtonComponent.vue";
import { PlusIcon } from "@heroicons/vue/24/outline";
import throttle from "lodash/throttle";
import pickBy from "lodash/pickBy";
import TeleCpuLocationService from "@/classes/Settings/TeleCpuLocationService";
import ConfirmationModal from "@/components/modals/ConfirmationModal.vue";
import SuccessModal from "@/components/modals/SuccessModal.vue";

import PageLoader from "@/components/loaders/PageLoader.vue"

import ActivityLogsTable from "@/views/activity-logs/ActivityLogsTable.vue"

import { filters, items } from "@/interfaces/pages/FilterPageInterface";
import assignValues from "@/helpers/assignValues"

const route = useRoute();
const router = useRouter();
/**
 * VARIABLES
 */

const filterSearch = ref<any>(route.query.query);
const loading = ref<boolean>(false);
const showConfirmation = ref<boolean>(false);
const showSuccess = ref<boolean>(false);
const showRestoreConfirmation = ref<boolean>(false);
const showRestoreSuccess = ref<boolean>(false);
const locationId = ref<number>(0);

const tabs = ref<{ name: string; count: number; tab:  string | null, allowed: boolean }[]>([
	{
		name: "All",
		count: filters.value.allItemsCount,
		tab: null,
		allowed: true,
	},
	{
		name: "Archived",
		count: 0,
		tab: "archived",
		allowed: true,
	},
	{
		name: "Activity logs",
		count: filters.value.activityLogsCount,
		tab: "activity_logs",
		allowed: true,
	},
]);

const headers: { text: string; haveSort?: boolean }[] = [
	{ text: "TeleCPU Location" },
	{ text: "No. of Offices" },
	{ text: "Date Created" },
];

/**
 * METHODS
 */
const getData = (): void => {
	loading.value = true
	TeleCpuLocationService.index(pickBy(filters.value))
		.then(({ data }) => {
			items.value = data.items;
			filters.value = assignValues(filters.value, data);
			loading.value = false
		})
		.catch((error: any) => {
			console.log(error);
			loading.value = false
		});
};

const applyFilters = (search: any = null) => {
    filters.value.query = search;
    getData();

};

const paginate = ({ page, rows }): void => {
    filters.value = Object.assign(filters.value, {
        page, rows
    })
    getData();
};

const updateTab = (currentTab: string) => {
	filters.value.tab = currentTab;
	filters.value.page = 1;
	applyFilters();
};

/**
 * MODALS
 */
const showDeleteModal = (item: any): void => {
	showConfirmation.value = true;
	locationId.value = item.id;
};

const closeDeleteModal = (): void => {
	showSuccess.value = false;
};

const deleteRecord = (): void => {
	TeleCpuLocationService.delete({
		location: locationId.value,
	})
		.then(() => {
			showSuccess.value = true;
			showConfirmation.value = false;
			applyFilters();
		})
		.catch((error) => console.log(error));
};

const showRestoreModal = (item: any): void => {
	showRestoreConfirmation.value = true;
	locationId.value = item.id;
};

const closeRestoreModal = (): void => {
	showRestoreSuccess.value = false;
};

const restoreRecord = (): void => {
	TeleCpuLocationService.restore({
		location: locationId.value,
	})
		.then(() => {
			showRestoreSuccess.value = true;
			showRestoreConfirmation.value = false;
			applyFilters();
		})
		.catch((error) => console.log(error));
};

/**
 * WATCHERS
 */
watch(
	filterSearch,
	throttle((val: string) => {
		applyFilters(val);
	}, 1000)
);

onMounted(() => {
	getData();
});
</script>
