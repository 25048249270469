<template>
	<a @click="router.replace(routeLink)" target="_blank">
		<button
			type="button"
			class="bg-primary-700 hover:bg-primary-600 rounded shadow-[0_0_4px_0px_rgb(17,24,39,0.25)] p-2 border border-transparent mx-1 focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-primary-600">
			<!-- <img src="/assets/icon/ic-arrow-top-right.svg" class="h-4 w-4 object-contain" /> -->
			<arrow-top-right-icon class="h-4 w-4 object-contain" />
		</button>
	</a>
</template>

<script setup lang="ts">
import { useRouter } from "vue-router";
import ArrowTopRightIcon from "@/components/icons/arrowTopRightIcon.vue";

const router = useRouter();
defineProps({
	routeLink: {
		type: String,
		required: true,
	},
});
</script>
