<template>
    <!-- :add_on_left="true"
    add_on_text="+63" -->
    <text-input
        type="number"
        :name="name"
        :id="id"
        @keypress="numberonly"
        :placeholder="placeholder"
        :maxlength=11
        :value="modelValue"
        :error="error"
        :required="isRequired"
        :readonly="readonly"
    />
</template>

<script setup lang="ts">
import TextInput from "@/components/inputs/TextInput.vue";

defineProps({
    modelValue: {
        default: null,
        required: true,
    },
    name: {
        type: String,
        default: null,
    },
    id: {
        type: String,
        default: null,
    },
    error: {
        type: String,
        default: null,
    },
    placeholder: {
        type: String,
        default: "Mobile Number",
    },
    isRequired: {
        type: Boolean,
        default: false,
    },
    readonly: {
        type: Boolean,
        default: false,
    },
});

const numberonly = (e) => {
    e = e ? e : window.event;
    const charCode = e.which ? e.which : e.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        e.preventDefault();
    } else {
        return true;
    }
};
</script>