<template>
	<user-layout
		pageTitle="Data Dictionary"
	>
		<div class="w-full py-6 mx-auto">
			<section-title title="Data Dictionary" description="Relevant data and other details" />
			<div class="mt-6">
				<data-table
					:headers="headers"
					:no-action="false"
					:count="items.total"
					:hasSearch="true"
					:search="filterSearch"
					@update:searchText="(value: string) => (filterSearch = value)"
					searchPlaceholder="Search dictionary"
					tableClass="header-frm-table four dataDictionaryIndex_table">
					<template v-slot:body>
						<template v-for="item in items.data" :key="item">
							<tr>
								<td class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
									{{ item.name }}
								</td>
								<td class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap lg:max-w-[60vw] text-ellipsis overflow-hidden">
									{{ item.content }}
								</td>
								<td class="px-6 py-6 text-sm text-gray-900 whitespace-nowrap">
									<div class="flex items-center justify-center mx-auto">
										<view-button
											@click="router.replace(`/data-dictionary/${item.id}/view`)"></view-button>
									</div>
								</td>
							</tr>
						</template>
					</template>
					<template #tfoot>
						<div class="w-full px-6 border-t py-7">
							<!-- <minify-pagination
								:numRows="numRows"
								@update:rows="(value: any) => changeRows(value)"
								@update:page="(value: number) => paginate(value)"
								:current-page="page"
								:last-page="lastPage"></minify-pagination> -->

							<simple-pagination 
								:numRows="filters.rows" 
								:currentPage="items.current_page" 
								:last-page="items.last_page" 
								@update="(value: any) => paginate(value)"
							/>
						</div>
					</template>
				</data-table>
			</div>
		</div>
	</user-layout>
</template>
<script setup lang="ts">
import { onMounted, ref, watch, computed } from "vue";
import UserLayout from "@/layouts/UserLayout.vue";
import SectionTitle from "@/components/partials/SectionTitle.vue";
import ViewButton from "@/components/buttons/ViewButton.vue";
import DataTable from "@/components/partials/DataTable.vue";
import MinifyPagination from "@/components/partials/MinifyPagination.vue";
import SimplePagination from "@/components/partials/SimplePagination.vue";

import assignValues from "@/helpers/assignValues"
import PageLoader from "@/components/loaders/PageLoader.vue"

import { useRouter, useRoute } from "vue-router";
import DataDictionaryService from "@/classes/Settings/DataDictionaryService";
import throttle from "lodash/throttle";
import pickBy from "lodash/pickBy";

import { filters, items } from "@/interfaces/pages/FilterPageInterface";

import { useNetworkStore } from "@/store/network";
import { onIonViewWillEnter } from "@ionic/vue";

const networkStore = useNetworkStore();
const isNetworkAvailable = computed(() => networkStore.isNetworkAvailable);


const router = useRouter();
const route = useRoute();

/**
 * VARIABLES
 */
// const items = ref<any>(null);
const filterSearch = ref<any>(route.query.query);
const page = ref<any>(route.query.page);
const lastPage = ref<any>(null);
const rows = ref<any>(route.query.rows);
const numRows = computed(() => (rows.value ? rows.value + " Rows" : "All"));
const headers: { text: string; haveSort?: boolean }[] = [{ text: "Name" }, { text: "Content" }];
const isLoaded = ref<boolean>(false);

/**
 * METHODS
 */
const getData = async (): Promise<any> => {
    const response = await DataDictionaryService.index(pickBy(filters.value), false, isNetworkAvailable.value);
    if (response?.status === 200 || response?.data != undefined) {
        const data = response.data;
        items.value = data.items;
        filters.value = assignValues(filters.value, data);
    } else {
        console.log(response);
    }
};

const reloadPage = async () => {
	return await router.push({
		path: "/data-dictionary/index",
		query: pickBy({
			query: filterSearch.value,
			page: page.value,
			rows: rows.value,
		}),
	});
};

const applyFilters = (search: any = null) => {
	filters.value.query = search;
	getData();
};

const paginate = ({ page, rows }): void => {
	filters.value = Object.assign(filters.value, {
		page, rows
	})
	getData();
};

/**
 * WATCHERS
 */
watch(
	filterSearch,
	throttle((val: string) => {
		applyFilters(val);
	}, 1000)
);

watch(
    () => isNetworkAvailable.value,
    async () => {
        if (isLoaded.value) {
            await getData();
        }
    }
);

onIonViewWillEnter(async () => {
    await getData();
    isLoaded.value = true;
});
</script>
