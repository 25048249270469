import { OfflineAction } from "@/views/enums/OfflineAction";
import Storage from "@/helpers/storage";
import Relationships from "../Relationships";

const storage = new Storage();
const relationship = new Relationships();

const setUpdateForm = async (item: any, content: any) => {
	content.address = item.address || content.address;
	content.barangay_id = item.barangay_id || content.barangay_id;
	content.barangay = await relationship.barangays(item.barangay_id ? item : content);
	content.city_id = item.city_id || content.city_id;
	content.city = await relationship.cities(item.city_id ? item : content);
	content.province_id = item.province_id || content.province_id;
	content.province = await relationship.provinces(item.province_id ? item : content);
	content.region_id = item.region_id || content.region_id;
	content.region = await relationship.regions(item.region_id ? item : content);
	content.direction = item.direction || content.direction;
	content.residence_type = item.residence_type || content.residence_type;
	content.residence_type_meta = relationship.residenceTypes(item.residence_type != null ? item : content);
	content.encoded_by = storage.get("user");

    const date = new Date().toJSON();
    content.updated_at = relationship.dateFormatFromIso(date);
    content.updated_at_formatted = relationship.dateFormat(date);
	return JSON.stringify(content);
};

const setNewForm = async (item: any, content: any) => {
	const data = <any>{};
	data.address = item.address || null;
	data.barangay_id = item.barangay_id || null;
	data.barangay = await relationship.barangays(item.barangay_id ? item : content) || null;
	data.city_id = item.city_id || null;
	data.city = await relationship.cities(item.city_id ? item : content) || null;
	data.province_id = item.province_id || null;
	data.province = await relationship.provinces(item.province_id ? item : content) || null;
	data.region_id = item.region_id || null;
	data.region = await relationship.regions(item.region_id ? item : content) || null;
	data.direction = item.direction || null;
	data.residence_type = item.residence_type;
	data.residence_type_meta = relationship.residenceTypes(item.residence_type != null ? item : content) || null;
	data.encoded_by = storage.get("user");

    const date = new Date().toJSON();
    data.created_at = relationship.dateFormatFromIso(date);
    data.created_at_formatted = relationship.dateFormat(date);

	return JSON.stringify(data);
};

export default class CasePatientAddressForm {
	content: string | null;
	encoded_by: number | null;
	action: number | null;
	is_sync: boolean;
	created_at: string | null;
	updated_at: string | null;
	deleted_at: string | null;
	id: string | null;
	cases_id: string | null;
	offline_cases_id: string | null;
	constructor(item: any, res: any) {
		this.id = item.id;
		this.cases_id = res?.id;
		this.offline_cases_id = "";
		this.content = JSON.stringify(item);
		this.encoded_by = res.encoded_by;
		this.action = OfflineAction.DEFAULT;
		this.is_sync = false;
		this.created_at = "";
		this.updated_at = "";
		this.deleted_at = "";
	}

	async updateForm(item: any, res: any): Promise<any> {
		const data = <any>{};
		data.id = res.id;
		data.cases_id = res?.cases_id;
		data.offline_cases_id = null;
		data.content = await setUpdateForm(item, JSON.parse(res.content));
		data.encoded_by = 0;
		data.action = OfflineAction.UPDATE_RECORD;
		data.is_sync = false;
        data.created_at = res.created_at;
		data.updated_at = relationship.dateFormatFromIso(new Date());
		data.deleted_at = "";
        data.is_updated_offline = res.is_created_offline ? false : true;
        data.is_created_offline = res.is_created_offline;

		return data;
	}

	async createForm(item: any, res: any): Promise<any> {
		const data = <any>{};
		data.cases_id = res?.id;
		data.offline_cases_id = null;
		data.content = await setNewForm(item, JSON.parse(res.content));
		data.encoded_by = 0;
		data.action = OfflineAction.NEW_RECORD;
		data.is_sync = false;
		data.created_at = relationship.dateFormatFromIso(new Date());
		data.updated_at = relationship.dateFormatFromIso(new Date());
		data.deleted_at = "";
        data.is_created_offline =  res.is_created_offline ? res.is_created_offline : true;
		return data;
	}

	async deleteForm(item: any, res: any): Promise<any> {
		const data = <any>{};
		data.id = res.id;
		data.cases_id = res?.cases_id;
		data.offline_cases_id = null;
		data.content = await setNewForm(item, JSON.parse(res.content));
		data.encoded_by = 0;
		data.action = OfflineAction.DELETE_RECORD;
		data.is_sync = false;
        data.created_at = res.created_at;
		data.updated_at = res.updated_at;
		data.deleted_at = relationship.dateFormatFromIso(new Date());
        data.is_created_offline = res.is_created_offline;
        data.is_updated_offline = res.is_created_offline ? false : res.is_updated_offline;
        data.is_deleted_offline = true;
		return data;
	}
}
