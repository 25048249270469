<template>
    <user-layout hasBack backTitle="Safety Assessment Report" backLink="/reports">

        <template #lowerRight>
            <export-button v-if="item" @click="generatePDFAccess()" />
        </template>

        <div class="-mx-6 w-[calc(100%+48px)] pb-6">
            <div class="grid grid-cols-12 gap-6 py-6 mx-6">
                <div class="col-span-4">
                    <multi-select
                        :options="cases"
                        id="case_number"
                        name="case_number"
                        label="Case Number"
                        placeholder="Select.." 
                        searchable    
                        v-model="filters.case_id"
                    />
                </div>
                <div class="col-span-4">
                    <text-input
                        placeholder="Patient last name" 
                        id="patient_last_name"
                        name="patient_last_name"
                        label="Patient Last Name"
                        disabled
                        v-model="last_name"
                    />
                </div>
                <div class="col-span-4">
                    <text-input
                        placeholder="Patient first name" 
                        id="patient_first_name"
                        name="patient_first_name"
                        label="Patient First Name"
                        disabled
                        v-model="first_name"
                    />
                </div>
            </div>
            <div class="p-6 mx-6 border rounded-lg border-neutral-100">
                <div class="grid grid-cols-6 gap-6 mt-6">
                    <div class="grid grid-cols-6 col-span-6 gap-6 px-4 py-6 border border-gray-200 rounded-lg">
                        <div class="grid grid-cols-3 col-span-3 gap-3">
                            <div class="col-span-1">
                                <p class="font-bold">Case Number:</p>
                            </div>
                            <div class="col-span-2">
                                <p>{{ item?.case_number }}</p>
                            </div>
                            <div class="col-span-1">
                                <p class="font-bold">Full Name:</p>
                            </div>
                            <div class="col-span-2">
                                <p>{{ item?.name }}</p>
                            </div>
                        </div>
                        <div class="grid grid-cols-3 col-span-3 gap-3">
                            <div class="col-span-1">
                                <p class="font-bold">Date Initiated:</p>
                            </div>
                            <div class="col-span-2">
                                <p>{{ item?.initiated_at }}</p>
                            </div>
                            <div class="col-span-1">
                                <p class="font-bold">Assessment is for patient's sibling:</p>
                            </div>
                            <div class="col-span-2">
                                <p>{{ item?.is_assessment_patient_sibling ? "Yes" : "No" }}</p>
                            </div>
                        </div>
                        <div class="grid grid-cols-6 col-span-6 gap-3">
                            <div class="col-span-1">
                                <p class="font-bold">Appointments:</p>
                            </div>
                            <div class="col-span-5">
                                {{ item?.appointment }}
                            </div>
                        </div>
                    </div>

                    <div class="grid grid-cols-6 col-span-6 px-4 py-6 border border-gray-200 rounded-lg gap-9">
                        <div class="col-span-6 -mb-2">
                            <p class="text-sm font-bold">Safety Factor</p>
                        </div>
                        <div
                            class="grid grid-cols-6 col-span-6 gap-6"
                            v-for="(question, index) in questions"
                            :key="question">
                            <div class="col-span-3">
                                <p class="font-medium text-neutral-600">
                                    {{ question.description }}
                                </p>
                            </div>
                            <div class="flex items-center col-span-3">
                                <div v-if="item" class="flex items-center space-x-6">
                                    <radio-group
                                        :options="safetyFactorOptions"
                                        :name="`caretaker_${index}`"
                                        readonly
                                        v-model="item.safety_assessment_questions[index]['status']" />
                                </div>
                                <div v-else class="flex items-center space-x-6">
                                    <radio-group
                                        :options="safetyFactorOptions"
                                        :name="`caretaker_${index}`"
                                        readonly
                                    />
                                </div>
                            </div>
                            <div class="col-span-6">
                                <p class="block mb-3 font-semibold text-neutral-600">
                                    Information supporting saftey factor:
                                </p>
                                <p>
                                    {{ item?.safety_assessment_questions[index]["supporting_information"] }}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-span-6">
                        <p class="block mb-3 font-semibold text-neutral-600">Brief Family Background</p>
                        <p>{{ item?.brief_family_background }}</p>
                    </div>
                    <div class="col-span-6">
                        <p class="block mb-3 font-semibold text-neutral-600">Family Background</p>
                        <p>{{ item?.family_background }}</p>
                    </div>
                    <div class="col-span-6">
                        <p class="text-sm font-bold">Safety Decision Plan</p>
                    </div>
                    <div class="col-span-6">
                        <p class="text-sm font-bold">Risk Codes</p>
                    </div>
                    <div class="col-span-2">
                        <text-input
                            :disabled="true"
                            type="number"
                            label="Total number of “Yes”"
                            placeholder="#"
                            name="number_yes"
                            id="number_yes"
                            v-model="yes_count"
                        />
                    </div>
                    <div class="col-span-2">
                        <text-input
                            :disabled="true"
                            type="number"
                            label="Total number of “No”"
                            placeholder="#"
                            name="number_no"
                            v-model="no_count"
                        />
                    </div>
                    <div class="col-span-2">
                        <text-input
                            :disabled="true"
                            type="number"
                            label="Total number of “Unknown”"
                            placeholder="#"
                            name="number_unknown"
                            v-model="unknown_count"
                        />
                    </div>
                    <div class="col-span-2" v-for="(riskCode, index) in riskCodes" :key="riskCode">
                        <label
                            for="action_plan-1"
                            class="relative flex items-center h-full py-3 pl-3 pr-12 border rounded cursor-pointer border-neutral-100">
                            <p class="text-xs font-medium text-neutral-600">
                                {{ riskCode.description }}
                            </p>
                            <input
                                :checked="item?.risk_code_id == riskCode.id ? true : false"
                                disabled
                                type="radio"
                                :name="`action_plan_${index}`"
                                :id="`action_plan_${index}`"
                                class="absolute z-10 w-6 h-6 border-gray-300 cursor-pointer top-3 right-3 text-primary-600 focus:ring-0 focus:ring-offset-0 focus:outline-0 focus:outline-offset-0 checked:border-primary-600" />
                        </label>
                    </div>
                    <div class="col-span-6">
                        <p class="block mb-3 font-semibold text-neutral-600">Immediate Safety Plan</p>
                        <p>
                            {{ item?.immediate_safety_plan }}
                        </p>
                    </div>
                    <div class="col-span-6">
                        <p class="block mb-3 font-semibold text-neutral-600">
                            Rationale of Overall Immediate Safety Assessment
                        </p>
                        <div  v-html="item?.overall_risk_assessment" class="frm-description"></div>
                    </div>
                </div>
            </div>
        </div>

        <loading-modal
            :show="showSync"
            noBtn
            title="Please wait.."
            content="System is currently loading the report based on the selected filter."
        />
    </user-layout>
</template>
<script setup lang="ts">
import { onMounted, ref, computed, watch } from 'vue';
import UserLayout from "@/layouts/UserLayout.vue";
import ExportButton from '@/components/buttons/ExportButton.vue';
import MultiSelect from "@/components/inputs/MultiSelect.vue";
import TextInput from "@/components/inputs/TextInput.vue";
import LoadingModal from '@/components/modals/LoadingModal.vue';
import RadioGroup from "@/components/inputs/RadioGroup.vue";
import { renderSelect, renderSelectV2 } from "@/interfaces/DropdownInterface";
import ReportService from "@/classes/Reports/ReportService";
import SafetyAssessment from "@/classes/Cases/SafetyAssessment";
import { useNetworkStore } from "@/store/network";

const networkStore = useNetworkStore();
const isNetworkAvailable = computed(() => networkStore.isNetworkAvailable);
const caseItem = ref<any>({})
const cases = ref<any>([]);
const questions = ref<renderSelect[]>([]);
const riskCodes = ref<renderSelect[]>([]);
const safetyFactorOptions = ref<renderSelectV2[]>([]);
const item = ref();
const showSync = ref<boolean>(false);


const filters = ref({
    case_id: null,
})

const first_name = computed(() => item.value?.first_name ?? '')
const last_name = computed(() => item.value?.last_name ?? '')
const yes_count = computed(() => item.value?.yes_count ?? '')
const no_count = computed(() => item.value?.no_count ?? '')
const unknown_count = computed(() => item.value?.unknown_count ?? '')

const fetch = () => {
    showSync.value = true

    ReportService.index('safety-assessment', filters.value)
        .then(({ data }) => {
            console.log(data)
            cases.value = data?.cases
            safetyFactorOptions.value = data?.status;
            questions.value = data?.questions;
            riskCodes.value = data?.riskCodes;
            caseItem.value = data?.case;
            item.value = data?.item;

            showSync.value = false
        })
        .catch(errors => {
            showSync.value = false
            console.log(errors)
        });
}

const generatePDFAccess = ():void => {
	SafetyAssessment
		.generatePDFAccess(
			caseItem.value.patient_id,
			caseItem.value.id,
			item.value.id,
            isNetworkAvailable.value
		)
		.then(({data}) => {
			if(data.token){
				downloadPDF(data.token)
			}
		}).catch((errors) => {
			console.log(errors);
		})

}
const downloadPDF = (token:string):void => {
	SafetyAssessment
		.downloadPDF(
			item.value.id,
			token,
			isNetworkAvailable.value
		)
		.then(({data}) => {
			console.log(data);
		}).catch((errors) => {
			console.log(errors);
		})

}

watch(
() => filters.value.case_id,
	() => {
		fetch();
	}
);

onMounted(() => {
    fetch()
})

</script>