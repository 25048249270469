import { EnumsInterface } from "@/interfaces/EnumsInterface";

const MedicalCertificateSubType:EnumsInterface[] = [
    {id: 0, value: 0, label: "Medical certificate issued - Final"},
    {id: 1, value: 1, label: "Medical certificate issued - Provisional"},
    {id: 2, value: 2, label: "Medicolegal Certificate - Final"},
    {id: 3, value: 3, label: "Medicolegal Certificate - Provisional"},
    {id: 4, value: 4, label: "Medico-Legal Certificate - Temporary"},
]

export default MedicalCertificateSubType