<template>
    <div>
        <form action="#" @submit.prevent="submit">
            <div class="w-full mx-auto mb-6">
                <div class="flex space-x-3">
                    <div class="relative flex justify-center">
                        <ion-input 
                        v-model="otpNum.num1"
                        ref="otp1" 
                        @keyup="moveFocus($event, $refs.otp2.$el, '')" 
                        placeholder="_" 
                        class="otp otp-input" 
                        maxlength="1" 
                        inputmode="numeric" 
                        autocomplete="off">
                        </ion-input>
                    </div>
                    <div class="relative flex justify-center">
                        <ion-input 
                        v-model="otpNum.num2"
                        ref="otp2"
                        @keyup="moveFocus($event, $refs.otp3.$el, $refs.otp1.$el)" 
                        placeholder="_" 
                        class="otp otp-input" 
                        maxlength="1" 
                        inputmode="numeric" 
                        autocomplete="off">
                        </ion-input>
                        <span class="underline"></span>
                    </div>
                    <div class="relative flex justify-center">
                        <ion-input 
                        v-model="otpNum.num3"
                        ref="otp3"
                        @keyup="moveFocus($event, $refs.otp4.$el, $refs.otp2.$el)" 
                        placeholder="_" 
                        class="otp otp-input" 
                        maxlength="1" 
                        inputmode="numeric" 
                        autocomplete="off">
                        </ion-input>
                        <span class="underline"></span>
                    </div>
                    <div class="relative flex justify-center">
                        <ion-input 
                        v-model="otpNum.num4" 
                        ref="otp4"
                        @keyup="moveFocus($event, $refs.otp5.$el, $refs.otp3.$el)"
                        placeholder="_" 
                        class="otp otp-input" 
                        maxlength="1" 
                        inputmode="numeric" 
                        autocomplete="off">
                        </ion-input>
                        <span class="underline"></span>
                    </div>
                    <div class="relative flex justify-center">
                        <ion-input 
                        v-model="otpNum.num5" 
                        ref="otp5"
                        @keyup="moveFocus($event, $refs.otp6.$el, $refs.otp4.$el)"
                        placeholder="_" 
                        class="otp otp-input" 
                        maxlength="1" 
                        inputmode="numeric" 
                        autocomplete="off">
                        </ion-input>
                        <span class="underline"></span>
                    </div>
                    <div class="relative flex justify-center">
                        <ion-input 
                        v-model="otpNum.num6" 
                        ref="otp6"
                        placeholder="_" 
                        class="otp otp-input" 
                        maxlength="1" 
                        inputmode="numeric" 
                        autocomplete="off">
                        </ion-input>
                        <span class="underline"></span>
                    </div>
                </div>
            </div>
            <div class="text-center">
                <p class="mb-6 text-sm text-gray-500" v-if="showResend">
                    Didn't receive a code?
                    <span @click="resendOtp" class="font-bold transition text-primary-600 hover:text-primary-800">
                        Resend <span>({{ timerCount }}s)</span>
                    </span>
                </p>
    
                <a href="/reset-password">
                    <button-component
                        type="submit"
                        size="large"
                        btnWidth="w-full rounded-lg"
                    >
                        {{ bottonName }}
                    </button-component>
                </a>
                <div class="mt-6 text-center">
                    <a href="/login" class="m-0 text-sm transition text-primary-600 hover:text-primary-800">Return to Login</a>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import { 
    IonInput 
} from '@ionic/vue';

// Components
import ButtonComponent from '@/components/buttons/ButtonComponent.vue';

import { useRouter } from 'vue-router';
import { defineComponent, ref } from 'vue';

export default defineComponent({
    name: 'OTPPage',
    components: { 
        IonInput,
        ButtonComponent
    },
    emits: ['update:submit'],
    props: {
        mobile: {
            type: String,
        },
        validateUrl: {
            type: String,
        },
        resendUrl: {
            type: String,
        },
        email: {
            type: String,
        },
        value: {
            type: String,
        },
        code: {
            type: Number,
        },
        showResend: {
            default: true,
            type: Boolean,
        },
        bottonName: {
            default: 'Verify',
             type: String,
        },
        hasSubmit: {
             default: true,
            type: Boolean,
        }, 
        random: {
            default: false,
            type: Boolean,
        }
    },
    watch: {

        timerCount: {
            handler(value) {
                if (value > 0 && this.timerEnabled) {
                    setTimeout(() => {
                        this.timerCount--;
                    }, 1000);
                }

            },
            immediate: true // This ensures the watcher is triggered upon creation
        }

    },
    setup(props, { emit }) {
        const loading = ref(false);
        // const message = ref(null);
        const router = useRouter();
        // const title = ref(null);
        // const store = useUserStore();
        const timerCount = ref(3600);
        const timerEnabled = ref(true);

        const otpNum = [
            { num1: ref(null)},
            { num2: ref(null)},
            { num3: ref(null)},
            { num4: ref(null)},
            { num5: ref(null)},
            { num6: ref(null)}
        ];

        const moveFocus = (event, nextElement, previousElement) => {

            if (event.keyCode == 8 && previousElement) {
                previousElement.setFocus();
            } else if (event.keyCode >= 48 && event.keyCode <= 57 || event.keyCode >= 96 && event.keyCode <= 105) {
                if (nextElement) {
                    nextElement.setFocus();
                }
            } else if (event.path) {
                event.path[0].value = "";
            }
        };

        const submit = () => {
            if (props.hasSubmit) {
                emit('update:submit', otp());
            } else {
                console.log('do something');
            }
        }
        
        const resendOtp = () => {
            if (timerCount.value >= 0)
            {
                emit('update:resendOtp');
                timerCount.value = 3600
            }
        }

        const otp = () => {
            let otp = '';
            
            otpNum.forEach((value, key) => {
                otp += otpNum[`num${key + 1}`];
            });

            return otp;
        }
        
        return {
            loading,
            otpNum,
            router,
            // validate,
            resendOtp,
            timerCount,
            timerEnabled,
            moveFocus,
            submit
        }
    }
});
</script>
