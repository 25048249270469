import Pagination from "./Pagination";

export default class PaginationPatient extends Pagination {

    search(params: any): string {
        let query = '';

        if (params?.first_name || params?.last_name) {
            query += ` WHERE (first_name LIKE "%${params?.first_name || null}%" OR last_name LIKE "%${params?.last_name || null}%")`;
        }
        
        if (params?.birthdate) {
            query += ` AND birthdate="${params?.birthdate}"`;
        }
    
        return query;
    }

}