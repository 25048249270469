<template>
    <a @click="router.replace(routeLink)">
        <button
            type="button"
            class="bg-primary-700 hover:bg-primary-600 rounded shadow-[0_0_4px_0px_rgb(17,24,39,0.25)] p-[6px] border border-transparent mx-1
            focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-primary-600
            "
        >
            <!-- <img src="/assets/icon/ic-pencil.svg" alt="edit_icon" class="h-5 w-5 object-contain" /> -->
            <pencil-icon class="object-contain w-5 h-5" />
        </button>
    </a>
</template>

<script setup lang="ts">
import { useRouter } from "vue-router";
const router = useRouter();

import PencilIcon from "@/components/icons/pencilIcon.vue";
defineProps({
    routeLink: {
        type: String,
    },
});
</script>
