import { EnumsInterface } from "@/interfaces/EnumsInterface";

const name:EnumsInterface[] = [
    {id: 7, value: 7,  label: "None"},
    {id: 0, value: 0, label: "Psychosocial Disability"},
    {id: 1, value: 1, label: "Mental Disability (Attention-Deficit / Hyperactivity Disorder (ADHD), Down Syndrome, bipolar disorder, long-term recurring depression, schizophrenia)'"},
    {id: 2, value: 2, label: "Chronic Illness"},
    {id: 3, value: 3,  label: "Learning Disability"},
    {id: 4, value: 4,  label: "Visual Disability"},
    {id: 5, value: 5,  label: "Orthopedic Disability"},
    {id: 6, value: 6,  label: "Communication Disability"},
]

export default name